import NavbarComponent from './navbar-component';
import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import { clear } from '../../../modules/operational/device/detail/settings/redux/actions';
import { push } from 'connected-react-router';
import { changeMenu, getSubMenus } from './redux/actions';
import { toggleUserSettings } from '../../../modules/user/profile/redux/actions';
import { clearFields } from 'modules/operational/device/detail/_settings/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  settings: state.devices.detail.settings,
  actions: state.devices.detail.settings.actions,
  navbar: state.navbar.navbar,
  pathname: state.router.location.pathname,
  t: state.i18n.t,
  userSettings: state.navbar.userSettings,
  _settings: state.devices.detail._settings
});

export default connect(mapStateToProps, {
  changeMenu,
  getSubMenus,
  push,
  openModal,
  toggleUserSettings,
  closeModal,
  clear,
  clearFields
})(NavbarComponent);
