import React, { Component } from 'react';
import AdvancedSearchHistoryContainer from './history';
import TableComponent from 'core/table/table-container';
import mapper, { labelsAdvancedSearch } from './redux/mapper';
import { paginationAdvancedSearch } from 'core/pagination-optimized/redux/config';
import PaginationComponent from 'core/pagination-optimized/pagination-container';
import { PaginationState } from 'core/pagination-optimized/redux/types';
import MultipleSearchComponent from 'core/multiple-search/multiple-search-container';
import { MultipleSearchState } from 'core/multiple-search/redux/types';
import Sidebar from 'core/sidebar/sidebar-container';
import 'core/search/search-styles.scss';
import { TFunction } from 'i18next';
import { HideLastpageNumber } from 'core/pagination-optimized/redux/enum';
import SearchComponent from 'core/search/search-container';

interface AdvancedSearchComponentProps {
  fetchAdvancedSearch: any;
  initializeParameters: any;
  loadDeviceCustomFields: any;
  applicationParameters: any;
  results: any;
  resultsToShow: any;
  totalElements: number;
  lastEvaluatedData: string;
  clearCacheVod: any;
  clearCacheDvr: any;
  clearCacheSpeedTest: any;
  clearCacheSubscriptions: any;
  clearCacheProvDevices: any;
  clearCacheRFTuning: any;
  clearAllFilters: any;
  resetPageNumber: any;
  clearCacheLogsHistory: any;
  clearCacheGeneralDeviceInfo: any;
  clearCacheOverview: any;
  stopSpeedTest: any;
  stopChannelScan: any;
  stopChannelStats: any;
  onChangeAdvancedSearchPage: (startItem: number, endItem: number) => void;
  pagination: PaginationState;
  multipleSearch?: MultipleSearchState;
  t: TFunction;
  cleanChannelsResult: any;
  cleanScanResult: any;
  resetMultipleSearchState: any;
  clearAccordionCache: () => void;
  clearSettingsCache: () => void;
  sideMenuClearCache: () => void;
  clearCacheTabRFtuning: () => void;
  clearScanCache: () => void;
  stopSingleRftuning: () => void;
  clearThreshold: () => void;
}

export default class AdvancedSearchComponent extends Component<AdvancedSearchComponentProps> {
  componentDidMount() {
    const {
      clearCacheVod,
      clearCacheDvr,
      clearCacheSpeedTest,
      clearCacheSubscriptions,
      clearCacheProvDevices,
      clearCacheRFTuning,
      clearAllFilters,
      clearCacheLogsHistory,
      initializeParameters,
      loadDeviceCustomFields,
      clearCacheGeneralDeviceInfo,
      clearCacheOverview,
      stopSpeedTest,
      stopChannelScan,
      stopChannelStats,
      cleanChannelsResult,
      cleanScanResult,
      resetMultipleSearchState,
      clearAccordionCache,
      resetPageNumber,
      clearSettingsCache,
      sideMenuClearCache,
      clearCacheTabRFtuning,
      clearScanCache,
      stopSingleRftuning,
      clearThreshold
    } = this.props;

    clearCacheGeneralDeviceInfo();
    clearCacheOverview();
    stopSpeedTest();
    stopChannelScan();
    stopChannelStats();
    initializeParameters(loadDeviceCustomFields);
    clearAllFilters();
    clearCacheVod();
    clearCacheDvr();
    clearCacheSpeedTest();
    clearCacheSubscriptions();
    clearCacheProvDevices();
    clearCacheLogsHistory();
    clearCacheRFTuning();
    cleanChannelsResult();
    cleanScanResult();
    resetMultipleSearchState();
    clearAccordionCache();
    resetPageNumber();
    clearSettingsCache();
    sideMenuClearCache();
    clearCacheTabRFtuning();
    clearScanCache();
    stopSingleRftuning();
    clearThreshold();
  }

  async onChangeAdvancedSearch() {
    const {
      onChangeAdvancedSearchPage,
      results,
      lastEvaluatedData,
      fetchAdvancedSearch,
      pagination,
      totalElements
    } = this.props;

    const lastPage = Math.ceil(results.length / paginationAdvancedSearch.pageSize);
    const lastPageIndex = lastPage - 1;
    const pag = pagination.pagination.get('advanced.search');

    onChangeAdvancedSearchPage(
      paginationAdvancedSearch.pageSize * paginationAdvancedSearch.pageNumber,
      paginationAdvancedSearch.pageSize * (paginationAdvancedSearch.pageNumber + 1)
    );

    if (pag && pag.pageNumber === lastPageIndex && lastEvaluatedData) {
      await fetchAdvancedSearch(lastEvaluatedData, true, '');
    } else if (
      pag &&
      pag.pageNumber === lastPageIndex &&
      lastEvaluatedData &&
      lastEvaluatedData !== this.props.lastEvaluatedData &&
      totalElements <= paginationAdvancedSearch.pageSize * (paginationAdvancedSearch.pageNumber + 1)
    ) {
      await fetchAdvancedSearch(this.props.lastEvaluatedData, true, '');
    }
  }

  changePageSize() {
    const { onChangeAdvancedSearchPage, totalElements, fetchAdvancedSearch, lastEvaluatedData } =
      this.props;

    onChangeAdvancedSearchPage(0, paginationAdvancedSearch.pageSize);
  }

  isNotClickableRow(rowParams: any) {
    return !(rowParams && rowParams[0] && rowParams[1]);
  }

  onRowClick(rowParams: any) {
    return rowParams && rowParams[0] && rowParams[1]
      ? `${process.env.PUBLIC_URL}/operational/device/${rowParams[0]}/overview/${rowParams[1]}/${rowParams[0]}`
      : '';
  }

  hideKDSN() {
    return !Object.values(this.props.applicationParameters).includes('KDSN');
  }
  columnsToShow() {
    let columns = labelsAdvancedSearch(this.props.t);
    if (this.hideKDSN()) {
      delete columns.kdsn;
    }
    return columns;
  }
  itemsToShow(items: any) {
    return (
      items &&
      items.map((result: any) => {
        let mappedRes = mapper(result, this.props.t);
        if (this.hideKDSN()) {
          delete mappedRes.kdsn;
        }
        return mappedRes;
      })
    );
  }

  hasFilters() {
    const { multipleSearch } = this.props;

    let filtersApplied: any;
    if (multipleSearch && multipleSearch.multipleFilters.get('advanced.search')) {
      filtersApplied = multipleSearch.multipleFilters.get('advanced.search');
    }
    return !!filtersApplied.selectedFilters.length;
  }

  isInputDisabled() {
    const { multipleSearch } = this.props;
    const search = multipleSearch && multipleSearch.multipleFilters.get('advanced.search');
    if (search) {
      return search.levels[0].categories[0].fields[0].currentValue == '';
    }
    return false;
  }

  async fetchData() {
    const { fetchAdvancedSearch, lastEvaluatedData } = this.props;

    await fetchAdvancedSearch('', false, '');

    // setTimeout(() => {}, 1000);

    const lastPage = Math.ceil(this.props.results.length / paginationAdvancedSearch.pageSize);
    const lastPageIndex = lastPage - 1;
    const pag = this.props.pagination.pagination.get('advanced.search');

    if (
      pag &&
      pag.pageNumber === lastPageIndex &&
      this.props.totalElements <=
        paginationAdvancedSearch.pageSize * (paginationAdvancedSearch.pageNumber + 1)
    ) {
      if (this.props.lastEvaluatedData !== '')
        fetchAdvancedSearch(this.props.lastEvaluatedData, true, '');
    }
  }

  render() {
    const { totalElements, results, resultsToShow, fetchAdvancedSearch, t } = this.props;
    return (
      <div className="tile is-paddingless">
        <div className="tile is-paddingless is-flex">
          <Sidebar leftSide={true}>
            <div className="is-vertical has-margin-top-md is-paddingless">
              <div className="is-child">
                <AdvancedSearchHistoryContainer />
              </div>
            </div>
          </Sidebar>

          <div className="tile is-parent">
            <div className="tile is-child">
              <div>
                <MultipleSearchComponent
                  id={'advanced.search'}
                  i18nKey={'advanced_search'}
                  onMultipleSearch={() => this.fetchData()}
                  fieldsToHide={[this.hideKDSN() ? 'custom.kdSerialNumber' : '']}
                  badgesOutside={true}
                  disabledByDefault={true}
                  isSingleSearch={false}
                  inputDisabled={this.isInputDisabled()}
                />
              </div>

              <div className="has-padding-top-md">
                {this.hasFilters() || resultsToShow.length ? (
                  <TableComponent
                    id="advanced.search"
                    columns={this.columnsToShow()}
                    onChangePageSize={() => this.changePageSize()}
                    pagination={paginationAdvancedSearch}
                    items={this.itemsToShow(resultsToShow)}
                    sortableItems={this.itemsToShow(results)}
                    hiddenColumns={'deviceFamily'}
                    clickableRow={{
                      rowParams: ['udid', 'hhid'],
                      cursorDefaultFunction: this.isNotClickableRow,
                      method: this.onRowClick
                    }}
                  />
                ) : (
                  <TableComponent
                    id="advanced.search"
                    noResultSubtitle={t('advanced_search.add_filter_message')}
                  />
                )}
              </div>

              <div className="tile is-parent is-vertical has-margin-bottom-lg has-margin-right-md flex-zero">
                <PaginationComponent
                  id={'advanced.search'}
                  pageSize={paginationAdvancedSearch.pageSize}
                  totalElements={totalElements}
                  onChange={() => this.onChangeAdvancedSearch()}
                  hideLastpageNumber={HideLastpageNumber.ALL}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
