export const OVERLAY_MODAL_ESN_OPEN = 'OVERLAY_MODAL_ESN_OPEN';
export const OVERLAY_MODAL_ESN_CLOSE = 'OVERLAY_MODAL_ESN_CLOSE';
export const FILE_UPLOAD_ESN_MAPPING_REQUEST = 'FILE_UPLOAD_ESN_MAPPING_REQUEST';
export const FILE_UPLOAD_ESN_MAPPING_SUCCESS = 'FILE_UPLOAD_ESN_MAPPING_SUCCESS';
export const FILE_UPLOAD_ESN_MAPPING_FAILURE = 'FILE_UPLOAD_ESN_MAPPING_FAILURE';
export const SET_FILE_TO_UPLOAD = 'SET_ESN_FILE_TO_UPLOAD';

export interface UploadModalState {
  active: boolean;
  disabledUpload: boolean;
  title?: string;
  content?: string;
  execute?: any;
  dataUpload: FormData;
  fileNameToUpload: string;
  uploadResponse?: string;
}

export interface UploadModalAction {
  type:
    | typeof OVERLAY_MODAL_ESN_CLOSE
    | typeof OVERLAY_MODAL_ESN_OPEN
    | typeof SET_FILE_TO_UPLOAD
    | typeof FILE_UPLOAD_ESN_MAPPING_SUCCESS;
  payload: any;
}
