import { connect } from 'react-redux';
import FileUploadComponent from './file-upload-component';
import { fileSelectedHandler, fileUploadHandler, closeUpload } from './redux/actions';
import { ApplicationState } from 'application/application-redux';

const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t,
  fileUploadState: state.upload.file
});

export default connect(mapStateToProps, { fileUploadHandler, fileSelectedHandler, closeUpload })(
  FileUploadComponent
);
