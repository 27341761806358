import { ApplicationState } from 'application/application-redux';
import { TFunction } from 'i18next';
import { DeviceDetailSettingParameter } from '../types';

export default function(dataLake: any, isPrevious: boolean, t: TFunction, shadow: any) {
  const settingsMap = new Map<string, any>();

  // ******************** Audio And Subtitles ***********

  if (isPrevious) {
    settingsMap.set('audiovolume', {
      label: t('settings.label_aud_vol'),
      type: 'text',
      value: dataLake && dataLake.cal ? dataLake.cal : t('general.not_available'),
      category: 'audioAndSubtitles',
      disabled: true
    });

    // ******************** HDMI ***********

    settingsMap.set('hdcpStatus', {
      label: t('settings.label_hdcp_status'),
      type: 'text',
      value: dataLake && dataLake.hdcp ? dataLake.hdcp : t('general.not_available'),
      category: 'hdmi',
      disabled: true
    });

    // There is not this field in VIP
    /* if (dataLake.hdmimode !== null) {
      settingsMap.set('hdmimode', {
        label: 'HDMI Mode',
        type: 'list',
        value: dataLake.hdmimode,
        available: [
          { label: 'auto', value: 'auto' },
          { label: '720p', value: '720x1280p50' },
          { label: '1080p', value: '1920x1080x50p' },
          { label: '1080i', value: '1920x1080x50i' },
          { label: '4k', value: '3840x2160x50p' }
        ],
        category: 'hdmi',
        disabled: true
      });
    } */

    settingsMap.set('hdmires', {
      label: t('settings.label_hdmi_resolution'),
      type: 'text',
      value: dataLake && dataLake.hdmir ? dataLake.hdmir : t('general.not_available'),
      category: 'hdmi',
      disabled: true
    });
  } else {
    // ******************** Audio And Subtitles ***********

    settingsMap.set('audiostate', {
      label: t('settings.label_aud_state'),
      type: 'boolean',
      value: dataLake && dataLake.cal ? parseInt(dataLake.cal) === 0 : '',
      available: [
        { value: true, label: t('settings.label_aud_state_muted') },
        { value: false, label: t('settings.label_aud_state_unmuted') }
      ],
      category: 'audioAndSubtitles'
    });

    settingsMap.set('audiovolume', {
      label: t('settings.label_aud_vol'),
      type: 'list',
      value: dataLake && dataLake.cal ? convertAudioVolumeToSTB(dataLake.cal) : '',
      available: getAudioVolumeValues(5, t('settings.label_select')),
      category: 'audioAndSubtitles'
    });

    // ******************** HDMI ***********

    settingsMap.set('hdcpStatus', {
      label: t('settings.label_hdcp_status'),
      type: 'text',
      value: dataLake && dataLake.hdcp ? dataLake.hdcp : t('general.not_available'),
      category: 'hdmi',
      disabled: true
    });

    const config =
      shadow && shadow.reported && shadow.reported.config ? shadow.reported.config : null;

    settingsMap.set('hdmimode', {
      label: t('settings.label_hdmi_mode'),
      type: 'list',
      value:
        (config &&
          config.HDMIM &&
          dataLake &&
          dataLake.hdmid &&
          dataLake.hdmid.hdmiModes !== null) ||
        (config &&
          config.HDMIM &&
          dataLake &&
          dataLake.hdmid &&
          dataLake.hdmid.hdmiModes &&
          dataLake.hdmid.hdmiModes.length > 1)
          ? config.HDMIM
          : '',
      available: getAvailableHdmiModes(
        dataLake.hdmid,
        t('settings.label_select'),
        t('general.not_available')
      ),
      category: 'hdmi',
      disabled:
        (dataLake && dataLake.hdmid && dataLake.hdmid.hdmiModes === null) ||
        (dataLake &&
          dataLake.hdmid &&
          dataLake.hdmid.hdmiModes &&
          dataLake.hdmid.hdmiModes.length === 0)
    });
  }
  return settingsMap;
}

function convertAudioVolumeToSTB(volume: number) {
  if (isNaN(volume)) {
    return null;
  }

  if (volume !== 0) {
    return Math.ceil(volume / 5) * 5;
  }
  return volume;
}

function getAudioVolumeValues(iterator: number, labelDefault: string) {
  const values = [];
  values.push({ label: labelDefault, value: '', active: false });

  for (let i = 0; i <= 100; i += iterator) {
    values.push({ label: i.toString(), value: i, active: true });
  }

  return values;
}

function getAvailableHdmiModes(hdmiMode: any, labelDefault: string, labelNotAvailable: string) {
  const values = [];

  if (
    (hdmiMode && hdmiMode.hdmiModes === null) ||
    (hdmiMode && hdmiMode.hdmiModes && hdmiMode.hdmiModes.length === 0)
  ) {
    values.push({ label: labelNotAvailable, value: '', active: false });
  } else {
    values.push({ label: labelDefault, value: '', active: false });
    values.push({ label: 'Auto', value: 'auto', active: true });
  }

  if (hdmiMode && hdmiMode.hdmiModes) {
    hdmiMode.hdmiModes.map((item: any) => {
      values.push({
        label: `${item.formats}/${item.fieldRate}`,
        value: item.formats.split('x')[1],
        active: true
      });
    });
  }

  return values;
}
