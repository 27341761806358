import { DVBNode } from '../redux-nodes/types';
import { DVBItemChanged, DVBItemToShow } from '../types';

export const DVB_MANAGEMENT_REGION_SELECT = 'DVB_MANAGEMENT_REGION_SELECT';
export const DVB_MANAGEMENT_REGIONS_SUCCESS = 'DVB_MANAGEMENT_REGIONS_SUCCESS';
export const DVB_MANAGEMENT_REGIONS_FAILURE = 'DVB_MANAGEMENT_REGIONS_FAILURE';

export interface DVBManagementRegionAction {
  type:
    | typeof DVB_MANAGEMENT_REGION_SELECT
    | typeof DVB_MANAGEMENT_REGIONS_SUCCESS
    | typeof DVB_MANAGEMENT_REGIONS_FAILURE;
  payload: any;
}

export interface DVBRegion extends DVBItemToShow, DVBItemChanged {
  regionId: string;
  regionLabel: string;
  parentRegionId: string;
  nodes?: Array<DVBNode>;
}

export interface DVBManagementRegion {
  items: Array<DVBRegion>;
  itemsChanged?: Array<DVBRegion>;
  itemsSearch?: Array<DVBRegion>;
  selected?: DVBRegion;
}
