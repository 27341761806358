import { envs } from 'application/envHandler';
import apiClient from 'core/api';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, AD_GROUP_MAPPINGS } from 'core/auth/constants';
import { add, remove } from 'core/indicator/pending-tasks-redux';
import { fetchUserSettings } from 'modules/user/profile/redux/actions';
import {
  AuthTokenItem,
  AuthTokenRequestAction,
  AuthTokenSuccessAction,
  AuthTokenFailureAction,
  AUTH_TOKEN_REQUEST,
  AUTH_TOKEN_SUCCESS,
  AUTH_TOKEN_FAILURE
} from './types';

function request(): AuthTokenRequestAction {
  return {
    type: AUTH_TOKEN_REQUEST
  };
}

function success(payload: AuthTokenItem): AuthTokenSuccessAction {
  return {
    type: AUTH_TOKEN_SUCCESS,
    payload
  };
}

function failure(payload: any): AuthTokenFailureAction {
  return {
    type: AUTH_TOKEN_FAILURE,
    payload
  };
}

export function fetch(params: any) {
  return function(dispatch: any) {
    dispatch(add('LOGIN', {}));

    dispatch(request());

    apiClient
      .post(
        `${envs.REACT_APP_API_URL}/auth/code-token`,
        { code: params },
        { headers: { Accept: 'application/json' } }
      )
      .then(
        response => {
          if (
            response.data &&
            response.data.adGroupMappings &&
            Object.keys(response.data.adGroupMappings).length > 0
          ) {
            localStorage.setItem(AD_GROUP_MAPPINGS, JSON.stringify(response.data.adGroupMappings));
          } else {
            localStorage.removeItem(AD_GROUP_MAPPINGS);
          }

          localStorage.setItem(ACCESS_TOKEN_KEY, response.data.idToken);
          localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);

          dispatch(remove('LOGIN'));
          dispatch(fetchUserSettings());
          return dispatch(success(response.data));
        },
        rejection => {
          window.location.href = `${envs.REACT_APP_COGNITO_LOGIN_URL}`;
          return dispatch(failure(rejection));
        }
      );
  };
}
