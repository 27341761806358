import { connect } from 'react-redux';
import EsnMappingFormComponent from './esn-mapping-form-component';
import { open, close } from '../modal-upload/redux/actions';
import { startRefresh, stopRefresh } from '../grid/redux/action';

const mapStateToProps = (state: any) => ({
  form: state.management.idMapping.form,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  startRefresh,
  stopRefresh,
  open,
  close
})(EsnMappingFormComponent);
