import { envs } from 'application/envHandler';
import i18n, { TFunction } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

export function getLanguage(currentLanguage: any): string {
  const DEFAULT_LANGUAGE = 'en';
  return currentLanguage ? currentLanguage : DEFAULT_LANGUAGE;
}

export default function i18nInitialize(store: any, backendUrl: string, acceptedLanguage: string) {
  const promise = i18n.use(ChainedBackend).init({
    debug: envs.REACT_APP_DEBUG,
    cleanCode: true,
    keySeparator: false,
    fallbackLng: 'vipoc',
    ns: 'translation',
    defaultNS: 'translation',
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: `vipoci18n-${acceptedLanguage}-`
        },
        {
          loadPath: backendUrl,
          allowMultiLoading: false,
          crossDomain: false,
          withCredentials: false,
          overrideMimeType: false,
          customHeaders: {
            'Accept-Language': acceptedLanguage
          },

          requestOptions: {
            mode: 'cors',
            credentials: 'same-origin',
            cache: 'default'
          },
          reloadInterval: false
        }
      ]
    }
  });

  promise.then((t: TFunction) => {
    function loadI18n() {
      return {
        type: 'I18N_LOAD',
        payload: t
      };
    }

    store.dispatch(loadI18n());
  });

  return i18n;
}
