import {
  DVBManagementServiceAction,
  DVB_MANAGEMENT_SERVICE_SELECT,
  DVB_MANAGEMENT_SERVICES_SET,
  DVBService,
  DVBManagementService
} from './types';

const initialState: DVBManagementService = {
  items: [],
  itemsChanged: undefined,
  itemsSearch: undefined
};

export default function(state = initialState, action: DVBManagementServiceAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_SERVICE_SELECT: {
      if (!action.payload) {
        state.selected = undefined;
      }

      if (state.itemsChanged) {
        state.itemsChanged.forEach((service: DVBService) => {
          if (service.rootId === action.payload) {
            state.selected = service;
          }
        });
      }

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_SERVICES_SET: {
      const services: Array<DVBService> = [];
      const isToClear = action.payload && action.payload.isToClear;

      if (action.payload && action.payload.services) {
        action.payload.services.forEach((item: DVBService) => {
          if ((item.canEditChange === undefined) && (action.payload.canEditOtherEntities === undefined)) {
            item.canEditChange = true;
          }
          services.push({
            id: item.serviceId,
            rootId: item.rootId || item.serviceId || item.id,
            label: item.serviceName,
            serviceId: item.serviceId,
            serviceName: item.serviceName,
            eitPresFlwFlag: item.eitPresFlwFlag,
            lcn: item.lcn,
            lcn2: item.lcn2,
            mediaId: item.mediaId,
            scramble: item.scramble,
            serviceType: item.serviceType,
            canEditChange:
              item.canEditChange === undefined
                ? action.payload.canEditOtherEntities
                : item.canEditChange,
            changeLabel: item.changeLabel,
            changeId: item.changeId
          });
        });
      }

      return {
        ...state,
        items: isToClear || (state.items && state.items.length === 0) ? services : state.items,
        itemsSearch: action.payload && action.payload.isSearch ? services : undefined,
        itemsChanged: action.payload.services ? state.itemsChanged : []
      };
    }

    default: {
      return state;
    }
  }
}
