import React, { Component, Fragment } from 'react';
import moment from 'moment';
import authService from 'core/auth';
import CardComponent from 'core/card';
import { Bell, Close, Illustration_NoNotifications, Info, Sync, Tick } from 'assets/svg';
import { sortList } from 'core/utils/general-utils';
import { TFunction } from 'i18next';
import { REQUESTS_SET_REQUEST } from 'modules/management/spectrum/requests/redux/types';
import {
  NotificationsState,
  NotificationType,
  NOTIFICATION_SET,
  NOTIFICATION_TOGGLE,
  VipocNotification
} from './redux/types';
import './notifications-styles.scss';
import { CardPaddings } from 'core/card/enum';
import { SpectrumRequestsParam } from 'modules/management/spectrum/requests/redux/action';
import { envs } from 'application/envHandler';

interface NotificationProps {
  action: (type: any, payload?: any) => void;
  getNotifications: (index?: string, prevNotifications?: Array<VipocNotification>) => void;
  getRequests: (params?: SpectrumRequestsParam) => void;
  notification: NotificationsState;
  push: (uri: string) => void;
  readAllNotifications: () => void;
  readNotification: (id: string, read: boolean) => void;
  t: TFunction;
}

export default class NotificationComponent extends Component<NotificationProps> {
  componentDidMount() {
    const { action, getNotifications, notification } = this.props;
    if (!notification.notifications || notification.notifications.length === 0) {
      getNotifications();
    }

    this.initWebsocket();
    window.addEventListener(
      'click',
      (e: any) => {
        if (this.props.notification.isActive) {
          let targetElement = e.target;

          while (targetElement) {
            if (targetElement.getAttribute('id') === 'notifications') {
              return;
            }
            targetElement = targetElement.parentElement;
          }
          action(NOTIFICATION_TOGGLE, { isActive: false });
        }
      },
      true
    );
  }

  initWebsocket() {
    const { action, t } = this.props;
    const ENDPOINT = `${envs.REACT_APP_API_NOTIFICATION_WS}?token=${
      authService.getCurrentUser().token
    }`;
    const socket = new WebSocket(ENDPOINT);

    socket.onopen = function(e) {};

    socket.onmessage = function(event) {
      action(NOTIFICATION_SET, { data: event.data, t });
    };

    socket.onclose = function(event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log('[close] Connection died');
      }
    };
  }

  getNotificationIcon() {
    const { action, notification, t } = this.props;
    const numberReadNotification = notification.notifications.filter(
      (item: VipocNotification) => !item.read
    ).length;

    return (
      <button
        type="button"
        className="is-relative button button-action text is-underline-none"
        onClick={() => {
          !notification.isActive && action(NOTIFICATION_TOGGLE, { isActive: true });
        }}
      >
        <div className="is-relative is-inline-block icon-hovered">
          <Bell className="icon-svg icon-small" />
          {numberReadNotification > 0 && (
            <span className="is-vertical-center is-justified-center notifications-number">
              {numberReadNotification > 9 ? '9+' : numberReadNotification}
            </span>
          )}
        </div>
        <h6 className="text is-h6">{t('general.menu_alerts')}</h6>
      </button>
    );
  }

  getNotificationType(type: string) {
    switch (type) {
      case NotificationType.ERROR:
        return <Close className="icon-svg icon-danger icon-small" />;
      case NotificationType.SUCCESS:
        return <Tick className="icon-svg icon-success icon-small" />;
      case NotificationType.WARNING:
        return <Info className="icon-svg icon-info icon-small" />;
    }
  }

  getNotificationTypeColor(type: string) {
    switch (type) {
      case NotificationType.ERROR:
        return 'error';
      case NotificationType.SUCCESS:
        return 'success';
      case NotificationType.WARNING:
        return 'warning';
    }
    return ""
  }

  getNotificationDate(createDate: string) {
    const { t } = this.props;
    const now = moment().utc();
    const notificationDate = moment.utc(createDate);
    const duration = moment.duration(now.diff(notificationDate));
    let date = parseInt(duration.asMinutes().toString());
    let period = date === 1 ? t('general.min') : t('general.mins');

    if (date >= 60) {
      date = parseInt(duration.asHours().toString());
      period = date === 1 ? t('general.hr') : t('general.hrs');
      if (date >= 24) {
        date = parseInt(duration.asDays().toString());
        period = date === 1 ? t('general.day') : t('general.days');

        if (date >= 30 && parseInt(duration.asMonths().toString()) > 0) {
          date = parseInt(duration.asMonths().toString());
          period = date === 1 ? t('general.month') : t('general.months');
        }
      }
    }
    
    return t('general.time_ago')
      ?.replace('{0}', date.toString())
      .replace('{1}', period);
  }

  getNotificationExternalIds(notification: VipocNotification) {
    const { t } = this.props;

    switch (notification.source) {
      case 'Spectrum':
        return (
          <p>
            {t('spectrum.requests.request_id')}: {notification.object.id}
          </p>
        );

      case 'Devices':
        return (
          <Fragment>
            <p>Device Id: {notification.object.id}</p>
            <p>Job Id: {notification.object.externalId}</p>
          </Fragment>
        );

      default:
        break;
    }
  }

  rediretToPage(notification: VipocNotification) {
    const { action, getRequests, push, readNotification } = this.props;
    if (!notification.read) {
      readNotification(notification.id, true);
    }

    switch (notification.source) {
      case 'Spectrum':
        action(REQUESTS_SET_REQUEST, {
          data: {
            requestId: notification.object.id,
            isRequestOpen:
              notification.object.status === 'draft' || notification.object.status === 'pending',
            isRequestPending: notification.object.status === 'pending'
          }
        });
        getRequests({requestId: notification.object.id || ''});
        push('/management/spectrum/dvb_management');
        break;
      default:
        break;
    }

    action(NOTIFICATION_TOGGLE, { isActive: false });
  }

  getNotification(notification: VipocNotification, index: number) {
    const { readNotification, t } = this.props;

    return (
      <div
        className={`notifications-item  ${notification.read ? '' : 'is-highlighted'} ${this.getNotificationTypeColor(notification.type)}`}
        onClick={() => this.rediretToPage(notification)}
        key={index}
      >
        <div className="is-vertical-center">
          {this.getNotificationType(notification.type)}
          <h5 className="text is-bold has-margin-left-sm">{notification.title}</h5>
        </div>
        <p>{notification.source + ': ' + t(notification.module || '')}</p>
        {this.getNotificationExternalIds(notification)}
        <div className="is-flex is-justified-between">
          <span className="text is-size-sm is-grey">
            {this.getNotificationDate(notification.createdAt)}
          </span>
          <button
            type="button"
            className="button button-action text is-size-sm is-bold"
            onClick={e => {
              e.stopPropagation();
              readNotification(notification.id, !notification.read);
            }}
          >
            {notification.read ? t('notifications.unread') : t('notifications.read')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { notification, readAllNotifications, t, getNotifications } = this.props;
    const notifications = notification.notifications;
    const totalCount = notification.pagination?.totalCount || 0;
    return (
      <div className="height-full" id="notifications">
        {this.getNotificationIcon()}
        {notification.isActive && (
          <div className="notifications">
            <div className="notifications-container">
              <CardComponent padding={CardPaddings.NONE}>
                <div className="notifications-title">
                  <div className="columns has-margin-bottom-sm">
                    <div className="column">
                      <h5 className="text is-h5 is-bold">{t('notifications.title')}</h5>
                    </div>
                    {notifications && notifications.length > 0 && (
                      <div className="column is-narrow">
                        <button
                          type="button"
                          className="button button-action text is-size-sm is-bold"
                          onClick={() => readAllNotifications()}
                        >
                          {t('notifications.allread')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="is-flex flex-wrap is-justified-center">
                  {notifications && notifications.length > 0 ? (
                    <Fragment>
                      {sortList(notifications, 'createdAt', 'DESC').map(
                        (item: VipocNotification, index: number) => {
                          return this.getNotification(item, index);
                        }
                      )}
                      {notification.isFetching && (
                        <div className="notifications-loading">
                          <Sync className="icon-svg icon-spinner" />
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <div className="notifications-empty">
                      <Illustration_NoNotifications className="has-margin-bottom-md" />
                      <h5 className="text is-h5 is-bold has-padding-bottom-sm">
                        {t('notifications.empty.title')}
                      </h5>
                      <p className="text has-padding-top-md">
                        {t('notifications.empty.description')}
                      </p>
                    </div>
                  )}
                </div>
              </CardComponent>
              {totalCount > notification.notifications.length ?
               <div className="is-flex is-justified-end card column">
                <button type="button"
                className="button button-action text is-size-sm is-bold"
                onClick={() => getNotifications(notification.pagination?.index || "", notification.notifications)}>{t('spectrum.button.see_more')}</button>
              </div>: 
              ""}
            </div>
          </div>
        )}
      </div>
    );
  }
}
