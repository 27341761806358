import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SpectrumChannelsAssociationComponent from './channels-associations-component';
import { push } from 'connected-react-router';
import { getChannelNodes } from './redux/redux-nodes/action';
import { getChannelRegions } from './redux/redux-regions/action';
import { associate, disassociate, putRegionalLCN, getChannels, getChannel, getDownloadChannels } from '../redux/redux-channels/action';
import { editField } from 'core/table/redux/actions';
const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t,
  currentPage: ownProps.match.params.currentPage,
  regionsAssociation: state.management.spectrum.channelAssociationRegions,
  nodesAssociation: state.management.spectrum.channelAssociationNodes,
  table: state.table,
  channelsState: state.management.spectrum.channel
});
export default connect(mapStateToProps, { 
  push,
  getChannelNodes,
  getChannelRegions,
  putRegionalLCN,
  disassociate,
  associate,
  editField,
  getChannels,
  getChannel,
  getDownloadChannels
})(SpectrumChannelsAssociationComponent);
