import React, { useEffect, useState } from 'react'
import Card from 'core/card';
import NoRecords from 'core/no-records'
import { t } from 'i18next';
import ServicesHeader from './components/ServicesHeader'
import { CreateServiceFunction, DeleteHashFunction, DeleteServiceFunction, FetchAllServicesFunction, FetchHashByAidFunction, FetchHashByIdFunction, HashType, ServiceType, UpdateServiceFunction } from './types';
import AnonymizerBody from './components/AnonymizerBody';
import { resetService } from './redux/service/actions';
import { useDispatch } from 'react-redux';

interface AnonymizerProps {
  service: ServiceType | number
  services: Array<ServiceType>
  hash: HashType
  fetchAllServices: FetchAllServicesFunction
  createService: CreateServiceFunction
  updateService: UpdateServiceFunction
  deleteService: DeleteServiceFunction
  fetchHashById: FetchHashByIdFunction
  fetchHashByAid: FetchHashByAidFunction
  deleteHashById: DeleteHashFunction
}

export default function Anonymizer({ service, fetchAllServices, services, createService, deleteService, updateService, fetchHashById, fetchHashByAid, deleteHashById, hash }: AnonymizerProps) {
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAllServices()
    return () => {
      dispatch(resetService())
    }
  }, [])

  return (
    <div className="tile is-parent">
      <div className="tile is-child">
        <Card>
          <ServicesHeader selected={service} services={services} createService={createService} />
          {typeof(service) !== 'number' ?
            <AnonymizerBody
              service={service} 
              hash={hash}
              updateService={updateService} 
              deleteService={deleteService} 
              fetchHashById={fetchHashById}
              fetchHashByAid={fetchHashByAid}
              deleteHash={deleteHashById}/> : 
            <NoRecords t={t} subtitle={t('anonymizer.select_service_subtitle')}/>}
        </Card>
      </div>
    </div>
  )
}
