import {
  CLEAR,
  RecentlyDevicesSearchFormAction,
  RecentlyDevicesSearchFormState,
  SET_VALUE
} from './types';

const initialState: RecentlyDevicesSearchFormState = {
  fields: {}
};

const reducer = (
  state: RecentlyDevicesSearchFormState = initialState,
  action: RecentlyDevicesSearchFormAction
): RecentlyDevicesSearchFormState => {
  const currentFields = state.fields;

  switch (action.type) {
    case SET_VALUE:
      if (action.payload)
        currentFields[action.payload.key] =
          typeof action.payload.value === 'string' || action.payload.value instanceof String
            ? action.payload.value.trim()
            : action.payload.value;
      return { ...state, fields: currentFields };
    case CLEAR:
      return {
        ...state,
        fields: []
      };
    default:
      return state;
  }
};

export default reducer;
