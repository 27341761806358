import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import SettingsComponent from './settings-component';
import { fetchAssetValues, setFields } from './redux/actions';
import { fetchShadow, calls } from '../general/redux/actions';
import { sideMenuClearCache } from 'core/side-menu/redux/action';

const mapStateToProps = (state: ApplicationState) => ({
  general: state.devices.detail.general,
  settings: state.devices.detail._settings,
  sideMenu: state.sideMenu,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  fetchAssetValues,
  setFields,
  fetchShadow,
  sideMenuClearCache,
  calls
})(SettingsComponent);
