import { connect } from 'react-redux';
import IpGeolocationGridComponent from './ip-geolocation-grid-component';
import { execute } from './redux/action';

const mapStateToProps = (state: any) => ({
  currentFilter: state.management.ipGeolocation.grid.currentFilter,
  results: state.management.ipGeolocation.grid.results,
  t: state.i18n.t
});

export default connect(mapStateToProps, { execute })(IpGeolocationGridComponent);
