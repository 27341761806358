import AuthService from 'core/auth/auth-service';
import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import {
  RF_TUNING_FAILURE,
  RF_TUNING_SUCCESS,
  RF_TUNING_FORM_CHANGE_SCAN_TYPE,
  RF_TUNING_CHANGE_CUSTOM_FREQQUENCY,
  RF_TUNING_SCAN_WAITING_COMMAND,
  RF_TUNING_SCAN_CLEAR_CACHE,
  RF_TUNING_ADD_TO_FREQUENCY_LIST,
  RF_TUNING_DELETE_CUSTOM_FREQUENCY,
  RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST,
  RF_TUNING_CHANGE_DROPDOWN,
  RF_TUNING_SCAN_TOGGLE_ACCORDION,
  FrequencyDetailParameter,
  RF_TUNING_SCAN_SUCCESS_HISTORY
} from './types';
import { handleCommandError } from '../../../../../../../core/utils/error-utils';
import { RF_TUNING_STATUS } from '../../status/redux/types';
import { isFinal } from '../../status/redux/actions';
import { TFunction } from 'i18next';
import { envs } from 'application/envHandler';

let timerTimeout: any;
let timerInterval: any;

function waitingCommand(payload: any) {
  return {
    type: RF_TUNING_SCAN_WAITING_COMMAND,
    payload
  };
}

export function changeScanType(payload: string) {
  return {
    type: RF_TUNING_FORM_CHANGE_SCAN_TYPE,
    payload
  };
}

function success(payload: any) {
  return {
    type: RF_TUNING_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: RF_TUNING_FAILURE,
    payload
  };
}

export function clearScanCache() {
  return {
    type: RF_TUNING_SCAN_CLEAR_CACHE
  };
}

export function stopSingleRftuning() {
  return async (dispatch: any) => {
    clearInterval(timerInterval);
    clearTimeout(timerTimeout);
    dispatch(waitingCommand(false));
    dispatch(remove(RF_TUNING_STATUS));
    return dispatch(failure({}));
  };
}

export function startScan(id: string, jsonParameters: FrequencyDetailParameter) {
  return async (dispatch: any, getState: any) => {
    dispatch(add('RF_TUNING_SCAN', {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: 'getFrequencyDetails',
        deviceId: id,
        jsonParameters
      })
      .then(
        (response) => {
          if (response.data.httpCode === '400') {
            handleCommandError(
              dispatch,
              response.data.responseJson.jobId,
              'all_device_pages.menu_rf_tuning',
              getState().i18n.t,
              'RF_TUNING_SCAN',
              waitingCommand
            );
          } else {
            dispatch(remove('RF_TUNING_SCAN'));
            dispatch(waitingCommand(true));
            dispatch(fetchScanStatus(id, response.data.responseJson.jobId));
          }
        },
        (rejection) => {
          dispatch(remove('RF_TUNING_SCAN'));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_021';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_rf_tuning',
              message,
              type: 'danger'
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}

function doFetchScanStatus(dispatch: any, id: string, jobId: string) {
  apiClient
    .get(
      `${`${envs.REACT_APP_API_URL}/command/` + 'getFrequencyDetails/device/'}${id}/status`,
      {
        headers: { transactionID: jobId }
      }
    )
    .then(
      (secondResponse) => {
        let initialStatus: any = { ...secondResponse.data };
        if (isFinal(secondResponse.data.status)) {
          if (secondResponse.data.status === 'TIME_EXCEEDED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_rf_tuning',
                message: 'error_messages.VIPOC_ERROR_042',
                type: 'danger'
              })
            );
          } else if (secondResponse.data.status === 'FAILED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_rf_tuning',
                message: secondResponse.data.response.errorText,
                type: 'danger'
              })
            );

            dispatch(success(secondResponse.data));
          }

          clearInterval(timerInterval);
          clearTimeout(timerTimeout);
          dispatch(waitingCommand(false));
        }
        dispatch(remove(RF_TUNING_STATUS));
        return dispatch(success(initialStatus));
      },
      (secondRejection) => {
        dispatch(remove(RF_TUNING_STATUS));
        return dispatch(failure(secondRejection));
      }
    );
}

export function fetchScanStatus(id: string, jobId: string) {
  return async (dispatch: any) => {
    timerTimeout = setTimeout(() => {
      clearInterval(timerInterval);
      dispatch(waitingCommand(false));
      dispatch(remove(RF_TUNING_STATUS));
    }, 1900000);
    doFetchScanStatus(dispatch, id, jobId);
    timerInterval = setInterval(() => doFetchScanStatus(dispatch, id, jobId), 5000);
  };
}

export function changeCustomTypeValue(value: string, field: string) {
  return {
    type: RF_TUNING_CHANGE_CUSTOM_FREQQUENCY,
    payload: { value, field }
  };
}

export function addCustomFrequency(
  frequency: string,
  symbrate: string,
  length: number,
  t: TFunction
) {
  if (length > 9) {
    return addToaster({
      title: 'all_device_pages.menu_rf_tuning',
      message: t('alert_info_messages.VIPOC_INFO_017').replace('{0}', '10'),
      type: 'info'
    });
  } else {
    return {
      type: RF_TUNING_ADD_TO_FREQUENCY_LIST,
      payload: { frequency, symbrate }
    };
  }
}

export function deleteCustomFrequency(index: number) {
  return {
    type: RF_TUNING_DELETE_CUSTOM_FREQUENCY,
    payload: { index }
  };
}

export function clearListCustomFrequencies() {
  return {
    type: RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST
  };
}

export function changeDropdown(option: string) {
  return {
    type: RF_TUNING_CHANGE_DROPDOWN,
    payload: { option }
  };
}

export function toggleScanAccordion(index: number) {
  return {
    type: RF_TUNING_SCAN_TOGGLE_ACCORDION,
    payload: { index }
  };
}

export function successCustomResults(results: any) {
  return {
    type: RF_TUNING_SCAN_SUCCESS_HISTORY,
    payload: { results }
  };
}
