import axios from "axios"

export type EnvironmentVariables = {
  REACT_APP_API_URL: string
  REACT_APP_COGNITO_LOGIN_URL: string
  REACT_APP_COGNITO_LOGOUT_URL: string
  REACT_APP_API_NOTIFICATION: string
  REACT_APP_API_NOTIFICATION_WS: string
  REACT_APP_DEBUG: boolean
  REACT_APP_LNG_PER_OPCO: string
  REACT_APP_LNG: string
  REACT_APP_CALENDAR_LNG: string
  REACT_APP_IS_LOCAL: boolean
  REACT_APP_SHOW_LUMBERJACK_LOGS: boolean
}

export let envs: Partial<EnvironmentVariables> = {}

if(process.env.NODE_ENV === 'development') {
  envs = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL || '',
    REACT_APP_COGNITO_LOGIN_URL: process.env.REACT_APP_COGNITO_LOGIN_URL || '',
    REACT_APP_COGNITO_LOGOUT_URL: process.env.REACT_APP_COGNITO_LOGOUT_URL || '',
    REACT_APP_API_NOTIFICATION: process.env.REACT_APP_API_NOTIFICATION || '',
    REACT_APP_API_NOTIFICATION_WS: process.env.REACT_APP_API_NOTIFICATION_WS || '',
    REACT_APP_DEBUG: process.env.REACT_APP_DEBUG === 'true',
    REACT_APP_LNG_PER_OPCO: process.env.REACT_APP_LNG_PER_OPCO || '',
    REACT_APP_LNG: process.env.REACT_APP_LNG || '',
    REACT_APP_CALENDAR_LNG: process.env.REACT_APP_CALENDAR_LNG || '',
    REACT_APP_IS_LOCAL: process.env.REACT_APP_IS_LOCAL === 'true',
    REACT_APP_SHOW_LUMBERJACK_LOGS: process.env.REACT_APP_SHOW_LUMBERJACK_LOGS === 'true',
  }
}

function validateResponse(data: EnvironmentVariables) {
  const requiredVariables: Array<keyof EnvironmentVariables> = [
    'REACT_APP_API_URL',
    'REACT_APP_COGNITO_LOGIN_URL',
    'REACT_APP_COGNITO_LOGOUT_URL',
    'REACT_APP_API_NOTIFICATION',
    'REACT_APP_API_NOTIFICATION_WS',
    'REACT_APP_DEBUG',
    'REACT_APP_LNG_PER_OPCO',
    'REACT_APP_LNG',
    'REACT_APP_CALENDAR_LNG',
    'REACT_APP_IS_LOCAL',
    'REACT_APP_SHOW_LUMBERJACK_LOGS',
  ]

  requiredVariables.forEach((property) => {
    if(!data.hasOwnProperty(property)) {
      throw false
    }
  })
  
}

export async function initializeEnvs() {
  if(process.env.NODE_ENV === 'development') return true

  try {
    const { data } = await axios.get<EnvironmentVariables>('/settings.json', {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })

    validateResponse(data)
    
    envs.REACT_APP_API_URL = data.REACT_APP_API_URL
    envs.REACT_APP_COGNITO_LOGIN_URL = data.REACT_APP_COGNITO_LOGIN_URL
    envs.REACT_APP_COGNITO_LOGOUT_URL = data.REACT_APP_COGNITO_LOGOUT_URL
    envs.REACT_APP_API_NOTIFICATION = data.REACT_APP_API_NOTIFICATION
    envs.REACT_APP_API_NOTIFICATION_WS = data.REACT_APP_API_NOTIFICATION_WS
    envs.REACT_APP_DEBUG = data.REACT_APP_DEBUG || false
    envs.REACT_APP_LNG_PER_OPCO = data.REACT_APP_LNG_PER_OPCO
    envs.REACT_APP_LNG = data.REACT_APP_LNG
    envs.REACT_APP_CALENDAR_LNG = data.REACT_APP_CALENDAR_LNG
    envs.REACT_APP_IS_LOCAL = data.REACT_APP_IS_LOCAL || false
    envs.REACT_APP_SHOW_LUMBERJACK_LOGS = data.REACT_APP_SHOW_LUMBERJACK_LOGS || false

    return true
  } catch (e) {
    return false
  }
}
