import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import SettingsCardComponent from './settings-card-component';
import { updateFields } from '../../redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t
});

export default connect(mapStateToProps, { updateFields })(SettingsCardComponent);
