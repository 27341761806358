import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import ActivityLogComponent from './activity-log-component';
import { fetch, cancelLog, changePageActivityLog } from './redux/actions';
import { setListValues } from 'core/multiple-search/redux/action';
import { resetMultipleSearchState } from 'core/multiple-search/redux/action';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  activityLog: state.devices.detail.activityLog,
  general: state.devices.detail.general,
  hhid: ownProps.hhid,
  id: ownProps.id,
  overview: state.devices.detail.overview,
  t: state.i18n.t,
  pagination: state.pagination
});

export default connect(mapStateToProps, {
  fetch,
  cancelLog,
  setListValues,
  changePageActivityLog,
  resetMultipleSearchState
})(ActivityLogComponent);
