export const ESN_MAPPING_REQUEST = 'ESN_MAPPING_REQUEST';
export const ESN_MAPPING_SUCCESS = 'ESN_MAPPING_SUCCESS';
export const ESN_MAPPING_FAILURE = 'ESN_MAPPING_FAILURE';
export const ESN_MAPPING_SET_INTERVAL = 'ESN_MAPPING_SET_INTERVAL';

interface EsnMappingState {
  results: Array<EsnMappingResultRow>;
  interval: any;
}

interface EsnMappingAction {
  type: string;
  payload: any;
}

const initialState: EsnMappingState = {
  results: [],
  interval: null
};

export interface EsnMappingResult {
  content: Array<EsnMappingResultRow>;
}

export interface EsnMappingResultRow {
  id: string;
  status: string;
  message: string;
  startTime: string;
  endTime: string;
}

export default function(state = initialState, action: EsnMappingAction) {
  switch (action.type) {
    case ESN_MAPPING_SUCCESS: {
      return { ...state, results: action.payload };
    }
    case ESN_MAPPING_SET_INTERVAL: {
      return { ...state, interval: action.payload };
    }
    default: {
      return { ...state };
    }
  }
}
