import { combineReducers } from 'redux';
import dvbManagementReducer from '../dvb-management/redux';
import requestsReducer from '../requests/redux';
import channelReducer from 'modules/management/spectrum/logical-channels/channels/redux/redux-channels';
import channelAssociationsRegionsReducer from 'modules/management/spectrum/logical-channels/channels/channels-associations/redux/redux-regions/reducer';
import channelAssociationsNodesReducer from 'modules/management/spectrum/logical-channels/channels/channels-associations/redux/redux-nodes/reducer';
import regionalizationChannelsAssociationsReducer from 'modules/management/spectrum/logical-channels/regionalization/redux/reducer';
import { SpectrumState, SpectrumAction } from './types';

const initialState: SpectrumState = {
  current: {
    name: 'Homepage',
    value: 'homepage'
  },
  available: [
    { value: 'homepage', name: 'Homepage' },
    { value: 'dvb_management', name: 'DVB management' },
    { value: 'logical_channels', name: 'Logical channels' },
    { value: 'requests', name: 'Requests' }
  ]
};

const spectrumReducer = function(state = initialState, action: SpectrumAction) {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default combineReducers({
  main: spectrumReducer,
  dvbManagement: dvbManagementReducer,
  requests: requestsReducer,
  channel: channelReducer,
  channelAssociationRegions: channelAssociationsRegionsReducer,
  channelAssociationNodes: channelAssociationsNodesReducer,
  regionalizationChannelsAssociations: regionalizationChannelsAssociationsReducer
});
