import { initialServiceState, RESET_ANONYMIZER_SERVICE, SET_ANONYMIZER_SERVICE } from "../../consts";
import { ServiceAction } from "../../types";

export default function (state = initialServiceState, { type, payload }: ServiceAction) {
  switch (type) {
    case SET_ANONYMIZER_SERVICE:
      return { ...payload }
  
    case RESET_ANONYMIZER_SERVICE:
      return 0

    default:
      return state
  }
}
