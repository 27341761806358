import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import VodComponent from './vod-component';
import fetch from './redux/actions';

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    general: state.devices.detail.general,
    hhid: ownProps.hhid,
    id: ownProps.id,
    t: state.i18n.t,
    vod: state.devices.detail.vod
  };
}

export default connect(mapStateToProps, {
  fetch
})(VodComponent);
