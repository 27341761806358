export const mapData = [
  {
    dataKey: 'completed',
    stackId: 'a',
    fill: '#009900',
  },
  {
    dataKey: 'ongoing',
    stackId: 'a',
    fill: '#00B0CA',
  },
  {
    dataKey: 'scheduled',
    stackId: 'a',
    fill: '#FECB00',
  },
  {
    dataKey: 'cancelled',
    stackId: 'a',
    fill: '#AAAAAA',
  },
  {
    dataKey: 'deleted',
    stackId: 'a',
    fill: '#555555',
  },
  {
    dataKey: 'partial',
    stackId: 'a',
    fill: '#EB9700',
  },
  {
    dataKey: 'failed',
    stackId: 'a',
    fill: '#990000',
  },
]
