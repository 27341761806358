export const NOTIFICATION_GET = 'NOTIFICATION_GET';
export const NOTIFICATION_GET_FAILURE = 'NOTIFICATION_GET_FAILURE';
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTIFICATION_READ_ALL = 'NOTIFICATION_READ_ALL';
export const NOTIFICATION_READ_FAILURE = 'NOTIFICATION_READ_FAILURE';
export const NOTIFICATION_SET = 'NOTIFICATION_SET';
export const NOTIFICATION_TOGGLE = 'NOTIFICATION_TOGGLE';

export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export interface VipocNotification {
  createdAt: string;
  id: string;
  object: {
    id: string;
    externalId?: string;
    status: string;
  };
  message?: string;
  payload?:{
    result?: {
      failed: number;
      succeeded: number;
    };
    reply:{
      approved:boolean;
      canceled:boolean;
    }
   }
  read: boolean;
  source: string;
  title: string;
  type: NotificationType;
  module: string;
}

export interface NotificationsState {
  isActive: boolean;
  isFetching: boolean;
  notifications: VipocNotification[];
  pagination?: {
    index: string;
    totalCount: number;
  }
}

export interface NotificationsAction {
  payload: any;
  type:
    | typeof NOTIFICATION_GET
    | typeof NOTIFICATION_GET_FAILURE
    | typeof NOTIFICATION_GET_SUCCESS
    | typeof NOTIFICATION_READ
    | typeof NOTIFICATION_READ_ALL
    | typeof NOTIFICATION_READ_FAILURE
    | typeof NOTIFICATION_SET
    | typeof NOTIFICATION_TOGGLE;
}
