export const ID_MAPPING_REQUEST = 'ID_MAPPING_REQUEST';
export const ID_MAPPING_SUCCESS = 'ID_MAPPING_SUCCESS';
export const ID_MAPPING_FAILURE = 'ID_MAPPING_FAILURE';

interface IdMappingState {
  results: IdMappingResult;
}

interface IdMappingAction {
  type: string;
  payload: any;
}

const initialState: IdMappingState = {
  results: {
    content: [],
    totalPages: 0,
    size: 0,
    totalElement: 0
  }
};

export interface IdMappingResult {
  content: Array<IdMappingResultRow>[];
  totalPages: any;
  size: any;
  totalElement: any;
}

export interface IdMappingResultRow {
  uuid: string;
  status: string;
  message: string;
  lastUpdate: string;
  behavior: string;
  username: string;
  opco: string;
  collisions: string;
}

export default function(state = initialState, action: IdMappingAction) {
  switch (action.type) {
    case ID_MAPPING_SUCCESS: {
      return { ...state, results: action.payload };
    }
    default: {
      return { ...state };
    }
  }
}
