import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TFunction } from 'i18next';

interface TranslateProps {
  t: TFunction;
  children: string;
}

class T extends Component<TranslateProps> {
  render() {
    const { t, children } = this.props;
    return <>{t(children)}</>;
  }
}

const mapStateToProps = (state: any) => ({
  t: state.i18n.t
});

export default connect(mapStateToProps)(T);
