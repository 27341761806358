import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import {
  DVBManagementAction,
  DVB_MANAGEMENT_CHANGES_DELETE,
  DVB_MANAGEMENT_CHANGES_SAVE,
  DVB_MANAGEMENT_COPY,
  DVB_MANAGEMENT_LOADING_TOGGLE,
  DVB_MANAGEMENT_REQUEST_SUBMIT,
  DVBManagementState,
  DVBChange
} from './types';
import { DVB_ACTIONS, DVB_COLUMNS, DVB_COLUMNS_ORDER } from './enums';
import { setNetworks } from './redux-networks/action';
import { DVBRegion } from './redux-regions/types';
import { addToaster } from 'core/toaster/redux/actions';
import { TFunction } from 'i18next';
import { DVBNode, DVB_MANAGEMENT_NODES_SET } from './redux-nodes/types';
import { DVBNetwork } from './redux-networks/types';
import { DVBStream } from './redux-streams/types';
import { ApplicationState } from 'application/application-redux';
import { FieldItem } from '../popUp/redux/types';
import { getNextOptions, setChangeInfo, togglePopUp } from '../popUp/redux/action';
import { buildSearch, onSearch } from '../search/redux/action';
import { DVBService } from './redux-services/types';
import { sortList } from 'core/utils/general-utils';
import { selectRegion, setRegions } from './redux-regions/action';
import {
  RequestsMainState,
  RequestStatus,
  REQUESTS_GET_CHANGES,
  REQUESTS_SET_REQUEST,
  REQUESTS_SET_SCHEDULE_DATE
} from '../../requests/redux/types';
import DvbManagementMapper from './mapper';
import { hasFormChanged } from '../popUp/redux/reducer';
import { getRequests } from '../../requests/redux/action';
import { setNodes } from './redux-nodes/action';
import { envs } from 'application/envHandler';

function getChangeObject(change: DVBChange, column: DVB_COLUMNS): any {
  const newData = change.new;
  const oldData = change.old ? change.old : change.new;

  let node = oldData.nodes && oldData.nodes[0];
  let network = node?.networks && node.networks[0];
  let stream = network?.transportStreams && network.transportStreams[0];
  let service = stream?.services && stream.services[0];

  const newNode = newData.nodes && newData.nodes[0];
  const newNetwork = newNode?.networks && newNode.networks[0];
  const newStream = newNetwork?.transportStreams && newNetwork.transportStreams[0];
  const newService = newStream?.services && newStream.services[0];

  switch (column) {
    case DVB_COLUMNS.REGION:
      const regionObj = change.action === DVB_ACTIONS.DELETE ? oldData : newData;

      return {
        rootId: regionObj.rootId,
        oldId: regionObj.regionId,
        regionLabel: regionObj.regionLabel || regionObj.label,
        label: regionObj.regionLabel || regionObj.label,
        regionId: regionObj.regionId || regionObj.id,
        id: regionObj.regionId || regionObj.id,
        parentRegionId: regionObj.parentRegionId,
        children: regionObj.nodes || regionObj.children
      };

    case DVB_COLUMNS.NODE:
      const nodeObj = change.action === DVB_ACTIONS.DELETE ? node : newNode;

      return {
        rootId: nodeObj ? nodeObj.rootId : '',
        parentId: newData ? newData.regionId : '',
        oldId: node ? node.nodeId : '',
        label: nodeObj ? nodeObj.nodeLabel || nodeObj.label : '',
        nodeLabel: nodeObj ? nodeObj.nodeLabel || nodeObj.label : '',
        id: nodeObj ? nodeObj.nodeId || nodeObj.id : '',
        nodeId: nodeObj ? nodeObj.nodeId || nodeObj.id : '',
        childRegionId: nodeObj ? nodeObj.childRegionId : ''
      };

    case DVB_COLUMNS.NETWORK:
      const networkObj = change.action === DVB_ACTIONS.DELETE ? network : newNetwork;

      return {
        rootId: networkObj ? networkObj.rootId : '',
        parentId: newNode ? newNode.nodeId : '',
        oldId: network ? network.onId : '',
        id: networkObj ? networkObj.onId || networkObj.id : '',
        onId: networkObj ? networkObj.onId || networkObj.id : '',
        children: networkObj ? networkObj.transportStreams || networkObj.children : []
      };

    case DVB_COLUMNS.STREAM: {
      const streamObj = change.action === DVB_ACTIONS.DELETE ? stream : newStream;

      return {
        rootId: streamObj ? streamObj.rootId : '',
        parentId: newNetwork ? newNetwork.onId : '',
        oldId: stream ? stream.tsId : '',
        tsId: streamObj ? streamObj.tsId : '',
        id: streamObj ? streamObj.tsId : '',
        freq: streamObj ? streamObj.freq : '',
        mod: streamObj ? streamObj.mod : '',
        dvbType: streamObj ? streamObj.dvbType : '',
        symRate: streamObj ? streamObj.symRate : '',
        children: streamObj ? streamObj.services || streamObj.children : []
      };
    }

    case DVB_COLUMNS.SERVICE:
      const serviceObj = change.action === DVB_ACTIONS.DELETE ? service : newService;

      return {
        rootId: serviceObj ? serviceObj.rootId : '',
        parentId: newStream ? newStream.tsId : '',
        oldId: service ? service.serviceId : '',
        id: serviceObj ? serviceObj.serviceId : '',
        label: serviceObj ? serviceObj.serviceName : '',
        serviceId: serviceObj ? serviceObj.serviceId : '',
        serviceName: serviceObj ? serviceObj.serviceName : '',
        eitPresFlwFlag: serviceObj ? serviceObj.eitPresFlwFlag : '',
        lcn: serviceObj ? serviceObj.lcn : '',
        lcn2: serviceObj ? serviceObj.lcn2 : '',
        mediaId: serviceObj ? serviceObj.mediaId : '',
        scramble: serviceObj ? serviceObj.scramble : '',
        serviceType: serviceObj ? serviceObj.serviceType : ''
      };
  }
}

function getOldObject(list: any, data?: Array<FieldItem>) {
  let oldObject = data && data.find((item: FieldItem) => item.key.toLowerCase().includes('id'));
  let oldObjectId = oldObject ? oldObject.value : data && data[0].value;
  if (oldObjectId) {
    return list.find((item: any) => item.id == oldObjectId);
  }
}

function getPathVariables(data: DVBRegion, isOldData: boolean) {
  const regionId = data.regionId || data.id;
  const node = data.nodes && data.nodes[0];
  const network = node && node.networks && node.networks[0];
  const stream =
    (network && network.transportStreams && network.transportStreams[0]) ||
    (network?.children && network?.children[0]);
  const service = stream && stream.services && stream.services[0];

  return {
    regionId,
    nodeId: isOldData ? node?.rootId : node?.nodeId,
    onId: isOldData ? network?.rootId : network?.onId,
    tsId: isOldData ? stream?.rootId || stream?.tsId : stream?.tsId,
    serviceId: isOldData ? service?.rootId : service?.serviceId
  };
}

function getPopUpFields(data: Array<FieldItem>) {
  if (!data) {
    return;
  }

  const popUpData: any = {} as any;
  data.forEach((item: FieldItem) => {
    popUpData[item.key] = item.value;
  });

  return popUpData;
}

function getPropertyByColumn(column: DVB_COLUMNS) {
  switch (column) {
    case DVB_COLUMNS.REGION:
      return 'regionId';
    case DVB_COLUMNS.NODE:
      return 'nodeId';
    case DVB_COLUMNS.NETWORK:
      return 'onId';
    case DVB_COLUMNS.STREAM:
      return 'tsId';
    case DVB_COLUMNS.SERVICE:
      return 'serviceId';
    default:
      return undefined;
  }
}

function getSaveChangesData(
  dvbManagement: DVBManagementState,
  region: DVBRegion,
  networks?: DVBNetwork[]
) {
  let data: DVBRegion = {
    regionId: region.id || '',
    parentRegionId: region.id || '',
    regionLabel: region.label || ''
  };
  let oldData: DVBRegion = { ...data, nodes: [] };
  const popUpData = dvbManagement.main.popUp;
  const column = popUpData?.column || DVB_COLUMNS.NONE;
  const changeAction = popUpData?.action || DVB_ACTIONS.NONE;
  let selectedRegion: DVBRegion | undefined = Object.assign({}, dvbManagement.region.selected);
  let selectedNode: DVBNode | undefined = Object.assign({}, dvbManagement.node.selected);
  let selectedNetwork: DVBNetwork | undefined = Object.assign({}, dvbManagement.network.selected);
  let selectedStream: DVBStream | undefined = Object.assign({}, dvbManagement.stream.selected);

  switch (column) {
    case DVB_COLUMNS.REGION:
      if (changeAction === DVB_ACTIONS.CREATE || changeAction === DVB_ACTIONS.UPDATE) {
        selectedRegion = getPopUpFields(popUpData.data);
        if (selectedRegion) {
          selectedRegion.regionLabel = selectedRegion.label || 'spectrum teste teste';
        }
      }

      if (changeAction === DVB_ACTIONS.UPDATE || changeAction === DVB_ACTIONS.DELETE) {
        const oldRegion = getOldObject(dvbManagement.region.itemsChanged, popUpData.oldData);
        oldRegion.regionId = oldData.regionId;

        if (oldRegion) {
          oldData = oldRegion;
        }
      }

      break;

    case DVB_COLUMNS.NODE:
      if (changeAction === DVB_ACTIONS.CREATE || changeAction === DVB_ACTIONS.UPDATE) {
        selectedNode = getPopUpFields(popUpData.data);
      }

      if (changeAction === DVB_ACTIONS.CREATE && selectedNode) {
        selectedNode.rootId = selectedNode.nodeId;
      }

      if (changeAction === DVB_ACTIONS.UPDATE || changeAction === DVB_ACTIONS.DELETE) {
        const oldNode = getOldObject(dvbManagement.node.itemsChanged, popUpData.oldData);
        oldNode.regionId = oldData.regionId;

        if (oldNode) {
          oldData.nodes = [oldNode];

          if (selectedNode) {
            selectedNode.networks = networks;
          }
        }
      }

      if (changeAction === DVB_ACTIONS.MOVE) {
        oldData.nodes = [{ ...popUpData.item }];
        data = getOldObject(dvbManagement.region.items, popUpData.data);
        selectedNode = { ...popUpData.item };
      }

      break;

    case DVB_COLUMNS.NETWORK:
      oldData.nodes = [{ ...selectedNode, networks: [] }];
      selectedNode.networks = [];

      if (changeAction === DVB_ACTIONS.CREATE || changeAction === DVB_ACTIONS.UPDATE) {
        selectedNode.networks.push(getPopUpFields(popUpData.data));
      }

      if (changeAction === DVB_ACTIONS.CREATE) {
        selectedNode.networks[0].rootId = selectedNode.networks[0].onId;
      }

      if (changeAction === DVB_ACTIONS.UPDATE || changeAction === DVB_ACTIONS.DELETE) {
        const oldNetwork = getOldObject(dvbManagement.network.itemsChanged, popUpData.oldData);

        if (oldNetwork) {
          oldData.nodes[0].networks = [oldNetwork];

          if (selectedNode.networks[0]) {
            selectedNode.networks[0].transportStreams = oldNetwork.children;
          }
        }
      }

      if (changeAction === DVB_ACTIONS.MOVE) {
        oldData.nodes[0].networks = [{ ...popUpData.item }];
        data = getOldObject(dvbManagement.region.items, popUpData.data);
        selectedNode = getOldObject(data.children, [popUpData.data[1]]);

        if (selectedNode) {
          selectedNode.networks = [{ ...popUpData.item }];
        }
      }

      if (changeAction === DVB_ACTIONS.COPY_PASTE) {
        const copiedItems = dvbManagement.main.general.copied;

        if (copiedItems) {
          oldData = copiedItems;
          selectedNode = { ...popUpData.item, networks: [] };

          if (selectedNode) {
            selectedNode.networks = copiedItems.nodes && copiedItems.nodes[0].networks;

            if (selectedNode.networks) {
              selectedNode.networks[0].rootId = selectedNode.networks[0].onId;
              selectedNode.networks?.forEach((network: DVBNetwork) => {
                network.transportStreams = [];

                network.children?.forEach((stream: DVBStream) => {
                  network.transportStreams?.push(stream);
                });
              });
            }

            selectedNode.networks = copiedItems.nodes && copiedItems.nodes[0].networks;
          }
        }
      }
      break;

    case DVB_COLUMNS.STREAM:
      oldData.nodes = [{ ...selectedNode }];
      oldData.nodes[0].networks = [{ ...selectedNetwork, transportStreams: [] }];
      selectedNetwork.transportStreams = [];

      if (changeAction === DVB_ACTIONS.CREATE || changeAction === DVB_ACTIONS.UPDATE) {
        selectedNetwork.transportStreams.push(getPopUpFields(popUpData.data));
      }

      if (changeAction === DVB_ACTIONS.CREATE) {
        selectedNetwork.transportStreams[0].rootId = selectedNetwork.transportStreams[0].tsId;
      }

      if (changeAction === DVB_ACTIONS.UPDATE || changeAction === DVB_ACTIONS.DELETE) {
        const oldStream = getOldObject(dvbManagement.stream.itemsChanged, popUpData.oldData);

        if (oldStream) {
          oldData.nodes[0].networks[0].transportStreams = [oldStream];

          if (selectedNetwork.transportStreams[0]) {
            selectedNetwork.transportStreams[0].services = oldStream.children;
          }
        }
      }

      if (changeAction === DVB_ACTIONS.MOVE) {
        oldData.nodes[0].networks[0].transportStreams = [{ ...popUpData.item }];
        data = getOldObject(dvbManagement.region.items, popUpData.data);
        selectedNode = getOldObject(data.children, [popUpData.data[1]]);
        selectedNetwork = getOldObject(dvbManagement.network.itemsAux, [popUpData.data[2]]);

        if (selectedNetwork) {
          selectedNetwork.transportStreams = [{ ...popUpData.item }];
        }
      }

      if (changeAction === DVB_ACTIONS.COPY_PASTE) {
        const copiedItems = dvbManagement.main.general.copied;

        if (copiedItems) {
          oldData = copiedItems;
          selectedNetwork = { ...popUpData.item, transportStreams: [] };

          if (selectedNetwork) {
            selectedNetwork.transportStreams =
              copiedItems.nodes &&
              copiedItems.nodes[0].networks &&
              copiedItems.nodes[0].networks[0].transportStreams;

            if (selectedNetwork.transportStreams) {
              selectedNetwork.transportStreams[0].rootId = selectedNetwork.transportStreams[0].tsId;

              selectedNetwork.transportStreams?.forEach((stream: DVBStream) => {
                stream.services = [];

                if (stream.children) {
                  stream.children.forEach((service: DVBService) => {
                    stream.services?.push(service);
                  });
                }
              });
            }

            if (selectedNode) {
              selectedNode.networks = [selectedNetwork];
            }
          }
        }
      }

      if (selectedNode && selectedNetwork) {
        selectedNode.networks = [];
        selectedNode.networks = [selectedNetwork];
      }

      break;

    case DVB_COLUMNS.SERVICE:
      oldData.nodes = [{ ...selectedNode }];
      oldData.nodes[0].networks = [{ ...selectedNetwork }];
      oldData.nodes[0].networks[0].transportStreams = [{ ...selectedStream }];

      if (changeAction === DVB_ACTIONS.UPDATE || changeAction === DVB_ACTIONS.DELETE) {
        const oldService = getOldObject(dvbManagement.service.itemsChanged, popUpData.oldData);

        if (oldService) {
          oldData.nodes[0].networks[0].transportStreams[0].services = [oldService];
        }
      }

      selectedNode.networks = [];
      selectedNetwork.transportStreams = [];
      selectedStream.services = [];

      if (changeAction === DVB_ACTIONS.CREATE || changeAction === DVB_ACTIONS.UPDATE) {
        selectedStream.services.push(getPopUpFields(popUpData.data));
      }

      if (changeAction === DVB_ACTIONS.CREATE) {
        selectedStream.services[0].rootId = selectedStream.services[0].serviceId;
      }

      selectedNetwork.transportStreams.push(selectedStream);
      selectedNode.networks.push(selectedNetwork);

      if (changeAction === DVB_ACTIONS.MOVE) {
        oldData.nodes[0].networks[0].transportStreams[0].services = [{ ...popUpData.item }];
        data = getOldObject(dvbManagement.region.items, popUpData.data);
        selectedNode = getOldObject(data.children, [popUpData.data[1]]);
        selectedNetwork = getOldObject(dvbManagement.network.itemsAux, [popUpData.data[2]]);
        selectedStream = getOldObject(selectedNetwork?.children, [popUpData.data[3]]);

        if (selectedStream) {
          selectedStream.services = [{ ...popUpData.item }];
        }
      }

      if (changeAction === DVB_ACTIONS.COPY_PASTE) {
        const copiedItems = dvbManagement.main.general.copied;

        if (copiedItems) {
          oldData = copiedItems;
          selectedStream = { ...popUpData.item, services: [] };

          if (selectedStream) {
            selectedStream.services =
              copiedItems.nodes &&
              copiedItems.nodes[0].networks &&
              copiedItems.nodes[0].networks[0].transportStreams &&
              copiedItems.nodes[0].networks[0].transportStreams[0].services;

            if (selectedStream.services) {
              selectedStream.services[0].rootId = selectedStream.services[0].serviceId;
            }
          }
        }
      }

      if (selectedNode && selectedNetwork && selectedStream) {
        selectedNode.networks = [];
        selectedNetwork.transportStreams = [];
        selectedNetwork.transportStreams = [selectedStream];
        selectedNode.networks = [selectedNetwork];
      }

      break;

    default:
      break;
  }

  if (selectedRegion && Object.keys(selectedRegion).length && column == DVB_COLUMNS.REGION) {
    data = { ...selectedRegion };
  }

  if (selectedNode && Object.keys(selectedNode).length) {
    data.nodes = [];
    (data.nodes || []).push(selectedNode);
  }

  return {
    data,
    oldData
  };
}

function isToSendObject(
  column: DVB_COLUMNS,
  currentContext: DVB_COLUMNS,
  changeAction: DVB_ACTIONS
) {
  return (
    DVB_COLUMNS_ORDER.indexOf(currentContext) <= DVB_COLUMNS_ORDER.indexOf(column) ||
    (column === currentContext && changeAction !== DVB_ACTIONS.CREATE)
  );
}

function isUserChange(changeId: string, request: any) {
  return request.userChanges && request.userChanges.some((item: DVBChange) => item.changeId == changeId);
}

function mergeChangesList(request: any) {
  let changes = request.userChanges || [];
  const otherChanges = request.otherChanges || [];

  if (request.isRequestOpen) {
    otherChanges.forEach((userChange: any) => {
      changes = changes.concat(userChange.changes);
    });
  }

  return {
    region: changes ? changes.filter((item: DVBChange) => item.type === DVB_COLUMNS.REGION) : [],
    node: changes ? changes.filter((item: DVBChange) => item.type === DVB_COLUMNS.NODE) : [],
    network: changes ? changes.filter((item: DVBChange) => item.type === DVB_COLUMNS.NETWORK) : [],
    stream: changes ? changes.filter((item: DVBChange) => item.type === DVB_COLUMNS.STREAM) : [],
    service: changes ? changes.filter((item: DVBChange) => item.type === DVB_COLUMNS.SERVICE) : []
  };
}

function removeUnnecessaryChanges(changes: DVBChange[], column: DVB_COLUMNS, parentId: any) {
  const newchangesArray: DVBChange[] = [];
  const parentColumn = DVB_COLUMNS_ORDER[DVB_COLUMNS_ORDER.indexOf(column) - 1];
  if (parentId || column == DVB_COLUMNS.REGION) {
    changes.forEach((change: DVBChange) => {
      const changeObject = getChangeObject(change, column);
      const from: any = change.from;
      const to: any = change.to;
      const columnId = getPropertyByColumn(parentColumn);
      if (
        (changeObject.parentId && changeObject.parentId == parentId) ||
        (columnId && (from[columnId] == parentId || to[columnId] == parentId)) ||
        column == DVB_COLUMNS.REGION
      ) {
        newchangesArray.push(change);
      }
    });
  }

  return newchangesArray;
}

function setChangeData(
  changes: DVBChange[],
  list: any,
  t: TFunction,
  request: any,
  translationPrefix: string
) {
  const order = [
    DVB_ACTIONS.UPDATE,
    DVB_ACTIONS.MOVE,
    DVB_ACTIONS.CREATE,
    DVB_ACTIONS.COPY_PASTE,
    DVB_ACTIONS.DELETE
  ];

  list.forEach((item: any) => {
    let finalChangeAction: DVB_ACTIONS = DVB_ACTIONS.NONE;
    let error = undefined;
    item.parentId = item.parentId;
    const objectChanges = getChangesByItem(changes, item.rootId);

    objectChanges.forEach((change: DVBChange) => {
      if (request.results) {
        const failed = request.results.failed.find(
          (failedChange: any) => failedChange.id === change.changeId
        );
        error = failed
          ? t(DvbManagementMapper.mapErrors(failed.message)).replace('{0}', change.type)
          : undefined;
      }

      order.forEach((changeAction: DVB_ACTIONS) => {
        if (change.action === changeAction) {
          if (order.indexOf(changeAction) > order.indexOf(finalChangeAction)) {
            finalChangeAction = changeAction;
          }
        }
      });
    });

    if (finalChangeAction !== DVB_ACTIONS.NONE) {
      item.canEditChange =
        !request.selectedRequest?.canEditOtherEntities && isUserChange(item.changeId, request);
      item.canDeleteChange = item.canEditChange;
      item.error = error;

      switch (finalChangeAction) {
        case DVB_ACTIONS.COPY_PASTE:
          if (item.parentId) {
            item.changeAction = DVB_ACTIONS.COPY_PASTE;
            item.changeLabel = `${translationPrefix}.create`;
          }
          break;
        case DVB_ACTIONS.CREATE:
          item.changeAction = DVB_ACTIONS.CREATE;
          item.changeLabel = `${translationPrefix}.create`;
          break;
        case DVB_ACTIONS.UPDATE:
          item.changeAction = DVB_ACTIONS.UPDATE;
          item.changeLabel = `${translationPrefix}.update`;
          break;
        case DVB_ACTIONS.DELETE:
          item.changeAction = DVB_ACTIONS.DELETE;
          item.changeLabel = `${translationPrefix}.delete`;
          break;
        case DVB_ACTIONS.MOVE:
          item.changeAction = DVB_ACTIONS.MOVE;
          item.changeLabel = `${translationPrefix}.move`;
          break;
        default:
          break;
      }

      item.changeLabel = error ? t(item.changeLabel + '_error') : t(item.changeLabel);
    }
  });

  return list ? list : [];
}

function setChangesParameters(
  dvbManagement: DVBManagementState,
  request: RequestsMainState,
  data: DVBRegion,
  oldData: DVBRegion,
  changeId?: string
): DVBChange {
  const requestId = request.selectedRequest?.requestId;
  const popUpData = dvbManagement.main.popUp;
  const changeAction =
    popUpData.item && popUpData.item.changeAction === DVB_ACTIONS.CREATE
      ? DVB_ACTIONS.CREATE
      : popUpData?.action || DVB_ACTIONS.NONE;
  const column = popUpData?.column || DVB_COLUMNS.NONE;

  return {
    changeId: changeId,
    type: column,
    action: changeAction,
    user: AuthService.getCurrentUser().username,
    requestId: requestId,
    from: {
      regionId: isToSendObject(column, DVB_COLUMNS.REGION, changeAction)
        ? getPathVariables(oldData, true)?.regionId
        : undefined,
      nodeId: isToSendObject(column, DVB_COLUMNS.NODE, changeAction)
        ? getPathVariables(oldData, true)?.nodeId
        : undefined,
      onId: isToSendObject(column, DVB_COLUMNS.NETWORK, changeAction)
        ? getPathVariables(oldData, true)?.onId
        : undefined,
      tsId: isToSendObject(column, DVB_COLUMNS.STREAM, changeAction)
        ? getPathVariables(oldData, true)?.tsId
        : undefined,
      serviceId: isToSendObject(column, DVB_COLUMNS.SERVICE, changeAction)
        ? getPathVariables(oldData, true)?.serviceId
        : undefined
    },
    to: {
      regionId: isToSendObject(column, DVB_COLUMNS.REGION, changeAction)
        ? getPathVariables(data, false).regionId
        : undefined,
      nodeId: isToSendObject(column, DVB_COLUMNS.NODE, changeAction)
        ? getPathVariables(data, false).nodeId
        : undefined,
      onId: isToSendObject(column, DVB_COLUMNS.NETWORK, changeAction)
        ? getPathVariables(data, false).onId
        : undefined,
      tsId: isToSendObject(column, DVB_COLUMNS.STREAM, changeAction)
        ? getPathVariables(data, false).tsId
        : undefined,
      serviceId: isToSendObject(column, DVB_COLUMNS.SERVICE, changeAction)
        ? getPathVariables(data, false).serviceId
        : undefined
    },
    old: oldData,
    new: data
  };
}

export function action(type: any, payload?: any): DVBManagementAction {
  return {
    type: type,
    payload: payload
  };
}

export function buildRequestObject() {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    let changes = getState().management.spectrum.requests.selectedRequest?.userChanges;
    const regions: DVBRegion[] = [];
    changes = sortList(changes, 'createdAt');
    changes.forEach((change: DVBChange) => {
      const data = change.new;
      const existingRegion = regions.find((region) => region.regionId === data.regionId);

      if (existingRegion) {
        const nodes = existingRegion.children || existingRegion.nodes;
        const nodeToAdd = data.nodes && data.nodes[0];

        if (nodes && nodeToAdd) {
          const existingNode = nodes.find((node: any) => node.rootId === nodeToAdd.rootId);

          if (existingNode) {
            const networks = existingNode.children || existingNode.networks;
            const networkToAdd = nodeToAdd.networks && nodeToAdd.networks[0];

            if (networks && networkToAdd) {
              const existingNetwork = networks.find(
                (network: any) => network.rootId === networkToAdd.rootId
              );

              if (existingNetwork) {
                const streams = existingNetwork.children || existingNetwork.transportStreams;
                const streamToAdd =
                  networkToAdd.transportStreams && networkToAdd.transportStreams[0];

                if (streams && streamToAdd) {
                  const existingStream = streams.find(
                    (stream: any) => stream.tsId === streamToAdd.rootId
                  );

                  if (existingStream) {
                    const services = existingStream.children || existingStream.services;
                    const serviceToAdd = streamToAdd.services && streamToAdd.services[0];

                    if (services && serviceToAdd) {
                      services.push(serviceToAdd);
                    }
                  } else {
                    streams.push(streamToAdd);
                  }
                }
              } else {
                networks.push(networkToAdd);
              }
            }
          } else {
            nodes.push(nodeToAdd);
          }
        }
      } else {
        regions.push({
          parentRegionId: data.parentRegionId,
          regionId: data.regionId,
          regionLabel: data.regionLabel,
          children: data.nodes
        });
      }
    });
    dispatch(setRegions(regions));
  };
}

export function deleteChange(changeId: string, column?: DVB_COLUMNS, isLast?: boolean) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    await AuthService.refreshToken();
    dispatch(add(DVB_MANAGEMENT_CHANGES_DELETE, {}));

    const requestId = getState().management.spectrum.requests.selectedRequest?.requestId;
    const opco = AuthService.getCurrentOpco();

    return apiClient
      .delete(`${envs.REACT_APP_API_URL}/spectrum/deleteChange`, {
        data: {
          requestId,
          id: changeId,
          opco
        }
      })
      .then(
        (response) => {
          if (isLast) {
            dispatch(
              addToaster({
                title: getState().i18n.t(`spectrum.delete_change`),
                message: getState().i18n.t(`spectrum.delete_change.success`),
                type: 'success'
              })
            );

            if (column && column !== DVB_COLUMNS.NONE) {
              dispatch(getChanges(column));
            }
          }

          return dispatch(action(response.data));
        },
        () => {
          dispatch(
            addToaster({
              title: getState().i18n.t(`spectrum.delete_change`),
              message: getState().i18n.t(`spectrum.delete_change.error`),
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {
        dispatch(remove(DVB_MANAGEMENT_CHANGES_DELETE));
      });
  };
}

export function deleteChangesIfExist(rootId: string, column: DVB_COLUMNS) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const dvbManagement = getState().management.spectrum.dvbManagement as any;
    const changes = getState().management.spectrum.requests.selectedRequest?.userChanges;
    const selectedColumn = column;
    let changesByItem = getChangesByItem(changes, rootId);

    dvbManagement[column].itemsChanged.forEach((item: any) => {
      if (item.rootId == rootId) {
        item.changeLabel = undefined;
        item.canEditChange = false;

        while (DVB_COLUMNS_ORDER.indexOf(column) + 1 <= DVB_COLUMNS_ORDER.length - 1) {
          column = DVB_COLUMNS_ORDER[DVB_COLUMNS_ORDER.indexOf(column) + 1];

          if (column) {
            dvbManagement[column].itemsChanged.forEach((childItem: any) => {
              childItem.changeLabel = undefined;
              childItem.canEditChange = false;
              changesByItem = changesByItem.concat(getChangesByItem(changes, childItem.rootId));
            });
          }
        }
      }
    });

    onDeleteChangesIfExist(dispatch, changesByItem, selectedColumn);
  };
}

export function onDeleteChangesIfExist(
  dispatch: Function,
  changes: DVBChange[],
  column: DVB_COLUMNS
) {
  let isLast = false;
  changes.forEach((change: DVBChange, index: number) => {
    const changeId = change.changeId || "";
    console.log('change.id', change.changeId);
    console.log('change', change);
    isLast = index == changes.length - 1;

    dispatch(deleteChange(changeId, column, isLast));
  });
}
// New API model
export function getChanges(triggeredFrom: DVB_COLUMNS, requestId?: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const t = getState().i18n.t;
    const dvbManagement = getState().management.spectrum.dvbManagement as any;
    const isLoading =
      dvbManagement.main.general.isLoading.region || dvbManagement.main.general.isLoading.network;

    if (!isLoading) {
      dispatch(add(REQUESTS_SET_REQUEST, {}));
    }

    await AuthService.refreshToken();

    let apiUrl = `${envs.REACT_APP_API_URL}/spectrum/getChanges?user=${
      AuthService.getCurrentUser().username
    }`;

    if (requestId) {
      apiUrl += `&requestId=${requestId}`;
    }

    return apiClient
      .get(apiUrl)
      .then(
        (response) => {
          dispatch(action(REQUESTS_GET_CHANGES, response.data));
        },
        () => {
          dispatch(
            addToaster({
              title: t(`spectrum.get_changes`),
              message: t(`spectrum.get_changes.error`),
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {
        let columnIndex = DVB_COLUMNS_ORDER.indexOf(triggeredFrom);
        if (triggeredFrom && triggeredFrom !== DVB_COLUMNS.NONE) {
          while (columnIndex <= DVB_COLUMNS_ORDER.length - 1) {
            dispatch(mergeChanges(DVB_COLUMNS_ORDER[columnIndex]));
            columnIndex++;
          }

          const searchData = dvbManagement[triggeredFrom].itemsSearch;

          if (searchData) {
            dispatch(onSearch(dvbManagement));
          }
        }

        dispatch(
          action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.REGION })
        );

        dispatch(
          action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.NETWORK })
        );

        if (!isLoading) {
          dispatch(remove(REQUESTS_SET_REQUEST));
        }
      });
  };
}

export function getChangesByItem(changes: DVBChange[], rootId: string) {
  return changes.filter((change: DVBChange) => {
    const changeObjectId = getChangeObject(change, change.type).rootId;
    if (rootId === changeObjectId || change.new.rootId === '-1') {
      return change;
    }
  });
}

export function getTuningDataAux(
  dispatch: Function,
  getState: () => ApplicationState,
  nodeId?: string,
  isMove?: boolean,
  isAux?: boolean,
  pageSize?: number,
  pageIndex?: number,
  prevArray?: Array<DVBNode>
) {
  if (!isMove || !isAux) {
    dispatch(
      action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: true, column: DVB_COLUMNS.NETWORK })
    );
  }
  dispatch(action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: true, column: DVB_COLUMNS.NODE }));

  const opco = AuthService.getCurrentOpco();
  const queryPageSize = pageSize ? pageSize : 20;
  const queryPageIndex = pageIndex ? pageIndex : 1;
  const totalNodes: Array<DVBNode> = prevArray ? prevArray : [];
  let totalElements: number;
  let totalNodesCount: number;

  const isRegionalization = getState().management.spectrum.dvbManagement.node.isRegionalization;

  return apiClient
    .get(
      `${envs.REACT_APP_API_URL}/be/spectrum/tuningdata/opcos/${opco}?nodeId=${nodeId}&pageSize=${queryPageSize}&pageIndex=${queryPageIndex}`
    )

    .then(
      (response) => {
        totalNodes.push(...response.data.result);

        totalElements = response.data.totalCount;
        totalNodesCount = totalNodes.length;

        if (isRegionalization) {
          if (totalNodesCount < totalElements) {
            getTuningDataAux(
              dispatch,
              getState,
              nodeId || '',
              false,
              false,
              queryPageSize,
              queryPageIndex + 1,
              totalNodes
            );
          } else {
            return dispatch(setNodes(totalNodes));
          }
        }
        if (nodeId) {
          dispatch(setNodes(response.data.result));
          dispatch(setNetworks(response.data, false, isAux));
        }

        if (isMove) {
          getNextOptions(dispatch, getState().management.spectrum.dvbManagement, DVB_COLUMNS.NODE);
        } else {
          dispatch(buildSearch(getState().management.spectrum.dvbManagement));
        }

        return response.data;
      },
      () => {
        if (!isAux) {
          dispatch(setNetworks(undefined, true));
        }
      }
    )
    .finally(() => {
      dispatch(
        action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.NETWORK })
      );
      dispatch(
        action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.NODE })
      );
      dispatch(remove(DVB_MANAGEMENT_NODES_SET));
    });
}

export function getTuningData(nodeId?: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    await AuthService.refreshToken();
    getTuningDataAux(
      dispatch,
      getState,
      nodeId || '',
      false,
      false,
      undefined,
      undefined,
      undefined
    );
  };
}

export function mergeChanges(column: DVB_COLUMNS) {
  return async function (_dispatch: Function, getState: () => ApplicationState) {
    if (column && column !== DVB_COLUMNS.NONE) {
      const t = getState().i18n.t;
      const dvbManagement = getState().management.spectrum.dvbManagement as any;
      const request = getState().management.spectrum.requests.selectedRequest;
      const parentColumn = DVB_COLUMNS_ORDER[DVB_COLUMNS_ORDER.indexOf(column) - 1] || DVB_COLUMNS.REGION;
      const selectedParent = dvbManagement[parentColumn] && ( column == DVB_COLUMNS.REGION ? DVB_COLUMNS.REGION : dvbManagement[parentColumn].selected);
      const list = dvbManagement[column];
      list.itemsChanged = JSON.parse(JSON.stringify(list.items));
      let orderedChanges: DVBChange[] = [];
      if (request) {
        orderedChanges = orderChanges(request, column, orderedChanges);
        if (selectedParent) {
          orderedChanges = removeUnnecessaryChanges(orderedChanges, column, selectedParent.id);
          orderedChanges.forEach((change: DVBChange) => {
            const changeObject = getChangeObject(change, column);
            const duplicateObject = list.itemsChanged.find(
              (item: any) => item.id === changeObject.id || item.rootId === changeObject.rootId
            );

            if (duplicateObject) {
              duplicateObject.changeId = change.changeId;
            }

            changeObject.changeId = change.changeId;
            if (changeObject.parentId == selectedParent.id) {
              switch (change.action) {
                case DVB_ACTIONS.CREATE:
                  if (!duplicateObject) {
                    list.itemsChanged.push(changeObject);
                  }
                  break;
                case DVB_ACTIONS.UPDATE:
                  const itemToUpdateIndex = list.itemsChanged.findIndex(
                    (item: any) => item.rootId == changeObject.rootId
                  );

                  if (!duplicateObject) {
                    if (itemToUpdateIndex >= 0) {
                      list.itemsChanged.splice(itemToUpdateIndex, 1);
                    }
                    list.itemsChanged.push(changeObject);
                  }
                  break;
                case DVB_ACTIONS.DELETE:
                  const itemToDelete = list.itemsChanged.find(
                    (item: any) => item.rootId == changeObject.rootId
                  );

                  if (itemToDelete) {
                    itemToDelete.changeId = change.changeId;
                  }
                  break;
                default:
                  break;
              }
            }

            const columnId = getPropertyByColumn(column);
            const parentColumnId = getPropertyByColumn(parentColumn);

            if (columnId && parentColumnId) {
              switch (change.action) {
                case DVB_ACTIONS.MOVE:
                  if (
                    change.to[parentColumnId] &&
                    change.to[parentColumnId] == selectedParent.id &&
                    !duplicateObject
                  ) {
                    list.itemsChanged.push(changeObject);
                  } else if (
                    change.from[parentColumnId] &&
                    change.from[parentColumnId] == selectedParent.id
                  ) {
                    const itemToMoveIndex = list.itemsChanged.findIndex(
                      (item: any) => item.id == changeObject.oldId
                    );

                    if (itemToMoveIndex >= 0) {
                      list.itemsChanged.splice(itemToMoveIndex, 1);
                    }
                  }

                  break;
                case DVB_ACTIONS.COPY_PASTE:
                  if (
                    change.to[parentColumnId] &&
                    change.to[parentColumnId] == selectedParent.id &&
                    !duplicateObject
                  ) {
                    list.itemsChanged.push(changeObject);
                  }

                  break;
              }
            }
          });
        }

        let translationPrefix = 'spectrum.pending';

        if (request.requestStatus != 'pending' && request.requestStatus !== 'draft') {
          translationPrefix =
            request.requestStatus == 'approved' ? 'spectrum.saved' : 'spectrum.not_saved';
        }

        list.itemsChanged = setChangeData(
          orderedChanges,
          list.itemsChanged,
          t,
          request,
          translationPrefix
        );

        if (selectedParent) {
          const selectedParentChangeObj = dvbManagement[parentColumn].itemsChanged.find(
            (item: any) => item.id == selectedParent.id
          );

          if (selectedParentChangeObj) {
            list.itemsChanged.forEach((item: any) => {
              if (
                [DVB_ACTIONS.COPY_PASTE, DVB_ACTIONS.MOVE, DVB_ACTIONS.DELETE].some(
                  (dvbAction) => dvbAction == selectedParentChangeObj.changeAction
                )
              ) {
                const actionLabel =
                  selectedParentChangeObj.changeAction == DVB_ACTIONS.COPY_PASTE
                    ? DVB_ACTIONS.CREATE
                    : selectedParentChangeObj.changeAction;

                item.changeLabel = t(`${translationPrefix}.${actionLabel}`);
                item.changeAction = selectedParentChangeObj.changeAction;
                item.canDeleteChange = false;
              }
            });
          }
        }
      }
    }
  };
}

export function orderChanges(request: any, column: DVB_COLUMNS, orderedChanges?: DVBChange[]) {
  if (column !== DVB_COLUMNS.NONE) {
    let changes = mergeChangesList(request)[column];
    if (orderedChanges) {
      changes = changes.concat(orderedChanges);
    }
    return sortList(changes, 'createdAt');
  }
}

export function onCopy(column: DVB_COLUMNS, previousColumn: DVB_COLUMNS, item: any) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const dvbManagement = getState().management.spectrum.dvbManagement;
    const t = getState().i18n.t;

    switch (column) {
      case DVB_COLUMNS.NETWORK: {
        dispatch(
          action(DVB_MANAGEMENT_COPY, {
            region: dvbManagement.region.selected,
            node: dvbManagement.node.selected,
            network: item
          })
        );
        break;
      }

      case DVB_COLUMNS.STREAM: {
        dispatch(
          action(DVB_MANAGEMENT_COPY, {
            region: dvbManagement.region.selected,
            node: dvbManagement.node.selected,
            network: dvbManagement.network.selected,
            stream: item
          })
        );
        break;
      }

      case DVB_COLUMNS.SERVICE: {
        dispatch(
          action(DVB_MANAGEMENT_COPY, {
            region: dvbManagement.region.selected,
            node: dvbManagement.node.selected,
            network: dvbManagement.network.selected,
            stream: dvbManagement.stream.selected,
            service: item
          })
        );
        break;
      }
    }

    dispatch(
      addToaster({
        title: t(`spectrum.copy`).replace('{0}', t(`spectrum.${column}`)),
        message: t(`spectrum.copy_item`)
          .replace('{0}', t(`spectrum.${column}`))
          .replace('{1}', t(`spectrum.${previousColumn}`)),
        type: 'success'
      })
    );
  };
}

export function onPaste(column: DVB_COLUMNS, item: any) {
  return async function (dispatch: Function) {
    dispatch(setChangeInfo(DVB_ACTIONS.COPY_PASTE, column, item));
  };
}

export function saveChanges(changeId?: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    dispatch(add(DVB_MANAGEMENT_CHANGES_SAVE, {}));
    await AuthService.refreshToken();
    const t = getState().i18n.t;
    const request = getState().management.spectrum.requests;
    const dvbManagement = getState().management.spectrum.dvbManagement;
    const popUpData = dvbManagement.main.popUp;
    const newRegion: DVBRegion = {
      regionId: '',
      regionLabel: '',
      parentRegionId: ''
    };
    const column = popUpData?.column || DVB_COLUMNS.NONE;
    const changeAction = popUpData?.action || DVB_ACTIONS.NONE;

    let networks;
    if (popUpData.oldData && column === DVB_COLUMNS.NODE && popUpData.oldData[0].value) {
      const nodeId = popUpData.oldData.find((item: FieldItem) => item.key === 'nodeId')?.value;
      networks = await Promise.all([getTuningDataAux(dispatch, getState, nodeId, false, true)]);
      networks = networks && networks[0].result[0] && networks[0].result[0].networks;
    }

    let changeObj = {
      data: {} as DVBRegion,
      oldData: {} as DVBRegion
    };
    const region = dvbManagement.region.selected;

    if (region || (column === DVB_COLUMNS.REGION && changeAction === DVB_ACTIONS.CREATE)) {
      changeObj = getSaveChangesData(dvbManagement, region || newRegion, networks);
    }

    if (!changeObj || !changeObj.data || !changeObj.oldData) {
      dispatch(
        addToaster({
          title: t(`spectrum.save_changes`),
          message: t(`spectrum.save_changes.error`),
          type: 'danger'
        })
      );
    }

    const body: DVBChange = setChangesParameters(
      dvbManagement,
      request,
      changeObj.data,
      changeObj.oldData,
      changeId
    );

    return apiClient
      .post(`${envs.REACT_APP_API_URL}/spectrum/saveChanges`, body)
      .then(
        (response) => {
          dispatch(
            addToaster({
              title: t(`spectrum.${changeAction}`).replace('{0}', t(`spectrum.${column}`)),
              message: t(`spectrum.${changeAction}.success`).replace(
                '{0}',
                t(`spectrum.${column}`).toLowerCase()
              ),
              type: 'success'
            })
          );

          const requestId = request.selectedRequest?.requestId;
          if (!requestId) {
            dispatch(
              action(REQUESTS_SET_REQUEST, {
                data: { requestId: response.data, requestStatus: RequestStatus.DRAFT }
              })
            );
          }

          if (request.selectedRequest?.canEditOtherEntities) {
            dispatch(getChanges(column));
          } else {
            dispatch(getRequests({requestId: request.selectedRequest?.requestId, column, id: popUpData.item.id}));
          }
        },
        () => {
          dispatch(
            addToaster({
              title: t(`spectrum.${changeAction}`).replace('{0}', t(`spectrum.${column}`)),
              message: t(`spectrum.${changeAction}.error`).replace(
                '{0}',
                t(`spectrum.${column}`).toLowerCase()
              ),
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {
        dispatch(togglePopUp(false));
        dispatch(remove(DVB_MANAGEMENT_CHANGES_SAVE));
      });
  };
}

export function setScheduleDate(scheduleDate: any) {
  return {
    type: REQUESTS_SET_SCHEDULE_DATE,
    payload: { scheduleDate }
  };
}

export function submitRequest(requestId: string, schedulingDate?: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const t = getState().i18n.t;
    const title = schedulingDate ? t('spectrum.schedule') : t('spectrum.submit');
    const successMessage = schedulingDate
      ? t('spectrum.schedule.success')
      : t('spectrum.submit.success');
    const errorMessage = schedulingDate ? t('spectrum.schedule.error') : t('spectrum.submit.error');

    dispatch(add(DVB_MANAGEMENT_REQUEST_SUBMIT, {}));
    await AuthService.refreshToken();

    const body = {
      requestId,
      schedulingDate
    };

    return apiClient
      .post(`${envs.REACT_APP_API_URL}/spectrum/submitRequest`, body)
      .then(
        () => {
          dispatch(
            addToaster({
              title: title,
              message: successMessage,
              type: 'success'
            })
          );
        },
        () => {
          dispatch(
            addToaster({
              title: title,
              message: errorMessage,
              type: 'success'
            })
          );
        }
      )
      .finally(() => {
        dispatch(remove(DVB_MANAGEMENT_REQUEST_SUBMIT));
      });
  };
}