export const SET_VALUE = 'SET_VALUE';
export type SET_VALUE = typeof SET_VALUE;

export const CLEAR = 'CLEAR';
export type CLEAR = typeof CLEAR;

export interface RecentlyDevicesSearchFormState {
  fields: any;
}

export interface RecentlyDevicesSearchFormAction {
  type: SET_VALUE | CLEAR;
  payload?: RecentlyDevicesSearchFormActionPayload;
}

export interface RecentlyDevicesSearchFormActionPayload {
  key: string;
  value?: any;
}
