import {
  SET_FILE_INGEST_UPLOAD_TYPE,
  UploadModalAction,
  UploadModalState,
  OVERLAY_MODAL_IDMP_CLOSE,
  OVERLAY_MODAL_IDMP_OPEN,
  SET_FILE_TO_UPLOAD,
  FILE_UPLOAD_ID_MAPPING_SUCCESS,
  FILE_UPLOAD_ID_MAPPING_FAILURE
} from './types';

const initialState: UploadModalState = {
  active: false,
  disabledUpload: true,
  dataUpload: new FormData(),
  fileNameToUpload: '',
  fileType: 'XLS'
};

export default function(state = initialState, action: UploadModalAction): UploadModalState {
  switch (action.type) {
    case OVERLAY_MODAL_IDMP_CLOSE:
      return {
        ...state,
        active: false
      };
    case OVERLAY_MODAL_IDMP_OPEN:
      return {
        ...state,
        active: true,
        title: action.payload.title,
        execute: action.payload.execute,
        fileNameToUpload: 'esn_mapping.label_choose_file',
        dataUpload: new FormData(),
        disabledUpload: true,
        uploadResponse: ''
      };
    case SET_FILE_TO_UPLOAD:
      return {
        ...state,
        active: true,
        disabledUpload: !action.payload.get('file'),
        fileNameToUpload: action.payload.get('file').name
      };
    case FILE_UPLOAD_ID_MAPPING_SUCCESS:
      return {
        ...state,
        uploadResponse: action.payload
      };
    case FILE_UPLOAD_ID_MAPPING_FAILURE:
      return {
        ...state
      };

    case SET_FILE_INGEST_UPLOAD_TYPE:
      return {
        ...state,
        fileType: action.payload
      };
    default:
      return state;
  }
}
