import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { More, Search } from 'assets/svg/index';
import { TFunction } from 'i18next';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { filterMapper } from 'core/table/types';
import DatePicker from 'core/date/datepicker';
import ToggleSwitch from 'core/toggle-switch';
import Slider from 'rc-slider';
import { SearchState, SearchFilter, MoreFilters } from './redux/types';
import 'rc-slider/assets/index.css';
import './search-styles.scss';
import { props } from 'ramda';
import { formatCamelCaseToSnakeCase } from 'core/utils/string-utils';

interface SearchComponentProps {
  onChangeCurrentFilterValue: (id: string, value: string | string[]) => void;
  onChangeCurrentComparator: (id: string, value: string) => void;
  onSearch: (filter?: any, days?: number, comparator?: string) => void;
  onSelectFilter: (id: string, value: string) => void;
  toggleMoreFilters: (id: string, state: boolean) => void;
  updateDays: (id: string, day: number) => void;
  hasSlider: boolean;
  id: string;
  items: any;
  itemsFiltered: any;
  search: SearchState;
  pagination: PaginationConfig;
  t: TFunction;
  translateFilter: any;
  goToFirstPage: (id: string) => void;
  hasOnlyFilter?: boolean;
}

export default class SearchComponent extends Component<SearchComponentProps> {
  componentDidMount() {
    const { t, translateFilter } = this.props;
    setTimeout(() => {
      translateFilter(t);
    }, 1);
  }

  onSearch(filter: any, event: any, days?: number, comparator?: string) {
    const { onSearch, goToFirstPage, pagination, id, hasOnlyFilter } = this.props;
    if (event) {
      event.preventDefault();
    }
    goToFirstPage(id);

    if(hasOnlyFilter) onSearch(filterMapper(filter), days, comparator);
    else onSearch(filterMapper(filter, pagination, id), days, comparator);
  }

  updateDays(day: number) {
    const { updateDays, id } = this.props;
    updateDays(id, day);
  }

  onSearchRange(index: number, value: string) {
    const rawValue = this.props.search.filters.get(this.props.id)?.currentFilter.value;
    const currentValue = (rawValue === '' ? [] : rawValue) as string[];
    currentValue[index] = value;
    this.props.onChangeCurrentFilterValue(this.props.id, [...currentValue]);
  }

  render() {
    const {
      onChangeCurrentFilterValue,
      onChangeCurrentComparator,
      onSelectFilter,
      toggleMoreFilters,
      hasSlider,
      id,
      search,
      t
    } = this.props;

    const filters: SearchFilter = search.filters.get(id) || {
      currentFilter: {
        type: {
          name: '',
          value: '',
          type: '',
          listValues: []
        }
      },
      filterTypes: [
        {
          name: '',
          value: '',
          type: ''
        }
      ],
      moreFilters: { days: 0, state: true },
      currentComparator: 'EQ'
    };

    const { currentFilter } = filters;
  

    const moreFilters: MoreFilters = filters.moreFilters
      ? filters.moreFilters
      : { days: 0, state: true };
    const comparator: string = filters.currentComparator ? filters.currentComparator : '';

    const marks = {
      '0': 'Today',
      '1': '-1',
      '2': '-2',
      '3': '-3',
      '4': '-4',
      '5': '-5',
      '6': '-6'
    };
    
    return (
      <form
        className="search search-container"
        onSubmit={event => {
          event.preventDefault();
          this.onSearch(currentFilter, moreFilters.days);
        }}
      >
        <div className={`columns is-vcentered ${moreFilters.state ? 'has-filters' : ''}`}>
          <div className="column is-narrow is-half-mobile is-inline-block-mobile">
            <div className="select">
              <select
                value={currentFilter.type.value}
                onChange={(event: any) => {
                  onSelectFilter(id, event.target.value);
                }}
              >
                {filters.filterTypes.map((type: any) => (
                  <option key={type.name} value={type.value}>
                    {type.label ? t(type.label) : t(type.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className={
              filters.showComparators
                ? 'column is-narrow control has-padding-right-none'
                : 'column is-narrow control'
            }
          >
            {currentFilter.type && currentFilter.type.type === 'string' && (
              <Fragment>
                {filters.showComparators && (
                  <div className="select double-input">
                    <select
                      onChange={(event: any) => {
                        onChangeCurrentComparator(id, event.target.value);
                      }}
                      value={filters.currentComparator}
                    >
                      {filters.numberFilters &&
                        filters.numberFilters.map((option: any) => (
                          <option key={option.name} value={option.value}>
                            {t(option.name)}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <div className={filters.showComparators ? 'double-input' : ''}>
                  <input
                    className="input has-icon"
                    type="text"
                    placeholder={t('general.search')}
                    value={currentFilter.value}
                    onChange={(event: any) => {
                      onChangeCurrentFilterValue(id, event.target.value);
                    }}
                  />
                </div>
                <span className="icon in-input">
                  <Search className="icon icon-svg is-small icon-atlo" />
                </span>
              </Fragment>
            )}

            {currentFilter.type && currentFilter.type.type === 'range' && (
              <div className="is-flex is-justify-content-space-between">
                <input
                  className="input min-input-width"
                  type="number"
                  defaultValue={currentFilter.value[0]}
                  onChange={(event: any) => {
                    this.onSearchRange(0, event.target.value);
                  }}
                />
                <input
                  className="input min-input-width mr-0"
                  type="number"
                  defaultValue={currentFilter.value[1]}
                  onChange={(event: any) => {
                    this.onSearchRange(1, event.target.value);
                  }}
                />
              </div>
            )}

            {currentFilter.type && currentFilter.type.type === 'date' && (
              <DatePicker
                isRange
                opened
                value={
                  currentFilter.value || {
                    start: moment(),
                    end: moment()
                  }
                }
                onChangePeriod={(date: any) => {
                  onChangeCurrentFilterValue(id, date);
                }}
              />
            )}

            {currentFilter.type && currentFilter.type.type === 'boolean' && (
              <ToggleSwitch
                currentToggleValue={currentFilter.value}
                id="watchedToggle"
                disabled={false}
                onChangeToggleSwitch={(value: string) => {
                  onChangeCurrentFilterValue(id, value === 'true' ? 'false' : 'true');
                }}
                text={['Yes', 'No']}
              />
            )}

            {currentFilter.type && currentFilter.type.type === 'list' && (
              <div className="select">
                <select
                  onChange={(event: any) => {
                    onChangeCurrentFilterValue(id, event.target.value);
                  }}
                  value={currentFilter.value}
                >
                  {currentFilter.type.listValues &&
                    currentFilter.type.listValues.map((option: any) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
          <div className="column is-narrow has-padding-left-sm">
            {hasSlider && (
              <button
                type="button"
                className="button has-icon"
                onClick={() => {
                  toggleMoreFilters(id, moreFilters.state);
                }}
              >
                <More className="icon icon-svg" />
              </button>
            )}
            <button
              className="button is-secondary"
              onClick={(event: any) =>
                this.onSearch(currentFilter, event, moreFilters.days, comparator)
              }
            >
              {t('general.search')}
            </button>
          </div>
        </div>

        {hasSlider && (
          <div className="columns has-padding-left-md filters">
            <div className="column slider is-half-widescreen is-two-thirds-tablet">
              <Slider
                min={0}
                max={6}
                marks={marks}
                step={1}
                onChange={(day: number) => this.updateDays(day)}
                defaultValue={0}
                value={moreFilters.days}
              />
            </div>
          </div>
        )}
      </form>
    );
  }
}
