import { ANONYMIZER_SERVICE_LIST_SUCCESS, ANONYMIZER_SERVICE_LIST_FAILURE, ANONYMIZER_SERVICE_CREATE_SUCCESS, ANONYMIZER_SERVICE_UPDATE_SUCCESS, ANONYMIZER_SERVICE_DELETE_SUCCESS } from '../../consts'
import { ServiceType, ServiceTypeResponse } from '../../types'

export function serviceListSuccess(payload: ServiceTypeResponse) {
  return {
    type: ANONYMIZER_SERVICE_LIST_SUCCESS,
    payload,
  }
}

export function serviceListFailure(payload: any) {
  return {
    type: ANONYMIZER_SERVICE_LIST_FAILURE,
    payload,
  }
}

export function serviceCreateSuccess(payload: ServiceType) {
  return {
    type: ANONYMIZER_SERVICE_CREATE_SUCCESS,
    payload,
  }
}

export function serviceUpdateSuccess(payload: ServiceType) {
  return {
    type: ANONYMIZER_SERVICE_UPDATE_SUCCESS,
    payload,
  }
}

export function serviceDeleteSuccess(payload: string) {
  return {
    type: ANONYMIZER_SERVICE_DELETE_SUCCESS,
    payload,
  }
}
