import { RfTuningStatusResult } from './types';

export default (data: RfTuningStatusResult): RfTuningStatusResult => {
  const dbmwToDbuv = (str: string) => {
    return (parseFloat(str) + 90 + 10 * Math.log10(75)).toFixed(2);
  };

  const fixBerpo = (str: string) => {
    const parsedBerpo = parseFloat(str)
    if (parsedBerpo === 0) {
      return '1e-10';
    }

    // Apply BERPO formula according to the Ticket VGSVTVVIPOC-2774
    const padNumber = 8
    let berpoPad = str.padStart(padNumber, '0')
    const decimalBerpo = berpoPad.slice(-(padNumber - 1), -1)
    const intBerpo = berpoPad.slice(0, (berpoPad.length - (padNumber - 1)))
    
    return parseFloat(`${intBerpo}.${decimalBerpo}`).toExponential()
  };

  const khzToMhz = (str: string) => {
    return (parseInt(str, 10) / 1000).toString();
  };

  const isTSID0 = (str:string) => {
    if (str === '0'){
      return true
    }
    return false
  }

  return {
    LF: data ? khzToMhz(data.LF) : '',
    PWR: data ? (!isTSID0(data.TSID) ? dbmwToDbuv(data.PWR) : '0') : '',
    BERPO: data ? (!isTSID0(data.TSID) ? fixBerpo(data.BERPO) : '0') : '',
    LS: data ? data.LS : '',
    TSID: data ? data.TSID : '',
    modulation: data ? data.modulation : '',
    type: data ? data.type : ''
  };
};
