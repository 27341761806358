export const REGIONALIZATION_ASSOCIATIONS_GET = 'REGIONALIZATION_ASSOCIATIONS_GET';
export const REGIONALIZATION_ASSOCIATIONS_GET_FAILURE = 'REGIONALIZATION_ASSOCIATIONS_GET_FAILURE';
export const REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS = 'REGIONALIZATION_ASSOCIATIONS_SUCCESS';
export const REGIONALIZATION_GET_SUCCESS =  'REGIONALIZATION_GET_SUCCESS'
export const REGIONALIZATION_GET = 'REGIONALIZATION_GET'

export enum RegionalizationChannelsAssociationsType {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export interface RegionalizationChannelsAssociations {
    channelId: number,
    mediaId: number ,
    name: string,
    regionalLCN: number 
}

export interface RegionalizationChannelsAssociationsState {
    channelsAssociations: {
        isActive: boolean;
        totalCount: number;
        channels: RegionalizationChannelsAssociations[];
    },
    channelsEligible: {
        isActive: boolean;
        totalCount: number;
        channels: RegionalizationChannelsAssociations[];
    }
    type: string;
    isSelected: any;
}


export interface RegionalizationChannelsAssociationsAction {
    payload: any;
    type:
      | typeof REGIONALIZATION_ASSOCIATIONS_GET
      | typeof REGIONALIZATION_ASSOCIATIONS_GET_FAILURE
      | typeof REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS
      | typeof REGIONALIZATION_GET_SUCCESS
      | typeof REGIONALIZATION_GET
  }
