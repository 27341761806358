import React, { Component, createRef } from 'react';
import DateInputComponent from 'core/date/bulma-calendar-component';
import moment from 'moment';
import DatePickerPeriod from './types';
import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { TFunction } from 'i18next';
import { getLanguage } from 'core/i18n/i18n';
import authService from 'core/auth';
import { Calendar } from 'assets/svg';
import DateUtils from '../../utils/date-utils';

interface DatePickerProps {
  isMasked?: boolean;
  isRange: boolean;
  label: string;
  value: any;
  onChangePeriod: Function;
  onChangeDate: Function;
  t: TFunction;
  isToDisable?: boolean;
  isLocalTime?: boolean;
  opened: boolean;
}

export const formatDatePickerLabel = (t: TFunction, startDate: string, endDate: string) => {
  return `${t('general.from')} ${DateUtils.formatDate(startDate)} ${t(
    'general.to'
  )} ${DateUtils.formatDate(endDate)}`;
};

export const formatLocalDateLabel = (t: TFunction, startDate: string, endDate: string) => {
  return `${t('general.from')} ${moment(startDate).format('YYYY-MM-DD HH:mm:SS')} ${t(
    'general.to'
  )} ${moment(endDate).format('YYYY-MM-DD HH:mm:SS')}`;
};

class DatePickerComponent extends Component<DatePickerProps> {
  dateToShow: string = '';
  dateFormatted: string = '';
  butRef: HTMLButtonElement | null = null;
  selectLabel = this.props.t('general.select_value');
  cancelLabel = this.props.t('general.cancel');

  componentDidUpdate(prevProps: DatePickerProps) {

    if(!this.hasValidLabels()){
      this.selectLabel = this.props.t('general.select_value');
      this.cancelLabel = this.props.t('general.cancel');
    }

   
    if (this.props.opened != prevProps.opened && this.props.opened) {
      this.butRef?.click();
    }
  }
  
  private dateInputInstance = React.createRef<DateInputComponent>();

  private formatToDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  private formatToTime(time: any) {
    return moment(time).format('HH:mm');
  }

  hasValidLabels() {
    return !this.selectLabel.includes('general') && !this.cancelLabel.includes('general');
  }

  open(event: any) {
    event.preventDefault();
    if (this.dateInputInstance && this.dateInputInstance.current) {
      this.dateInputInstance.current.open();
    }
  }

  onSelect(calendar: any) {
    const { startDate, startTime, endDate, endTime } = calendar.data;
    const starDateAndHour = `${this.formatToDate(startDate)} ${this.formatToTime(startTime)}`;
    const endDateAndHour = `${this.formatToDate(endDate)} ${this.formatToTime(endTime)}`;

    this.dateToShow = formatDatePickerLabel(this.props.t, starDateAndHour, endDateAndHour);
    if (this.props.isRange) {
      const period: DatePickerPeriod = {
        start: moment(starDateAndHour).utc(true).toISOString(),
        end: moment(endDateAndHour).utc(true).toISOString()
      };
      this.props.onChangePeriod(period);
      return;
    }
    this.props.onChangeDate(moment(starDateAndHour).utc(true).toISOString());
  }

  getCurrentLanguageCalendar() {
    const langs = authService.getCurrentUser().langsCalendar;
    return langs[authService.getCurrentLanguage() || 'en'];
  }

  render() {
    if (!this.props.isLocalTime) {
      if (this.dateToShow) {
        this.dateFormatted = this.dateToShow;
      } else if (this.props.value) {
        this.dateFormatted = formatDatePickerLabel(
          this.props.t,
          this.props.value.start || moment().format('YYYY-MM-DD HH:mm'),
          this.props.value.end || moment().format('YYYY-MM-DD HH:mm')
        );
      } else {
        formatDatePickerLabel(
          this.props.t,
          moment().format('YYYY-MM-DD HH:mm'),
          moment().format('YYYY-MM-DD HH:mm')
        );
      }
    } else {
      if (this.dateToShow) {
        this.dateFormatted = this.dateToShow;
      } else if (this.props.value) {
        this.dateFormatted = formatLocalDateLabel(
          this.props.t,
          this.props.value.start || moment(moment.utc()).local().format('YYYY-MM-DD HH:mm'),
          this.props.value.end || moment(moment.utc()).local().format('YYYY-MM-DD HH:mm')
        );
      } else {
        formatLocalDateLabel(
          this.props.t,
          moment(moment.utc()).local().format('YYYY-MM-DD HH:mm'),
          moment(moment.utc()).local().format('YYYY-MM-DD HH:mm')
        );
      }
    }
    const period = this.props.value;
    const startDate = (period && period.start) || null;
    const endDate = (period && period.end) || null;
    const itHasValidLabels = this.hasValidLabels();

    if (!startDate && !endDate) {
      if (this.dateInputInstance.current) this.dateInputInstance.current.clear();
    }

    return (
      <div className="field">
        <button
          name="datepicker"
          ref={(button) => (this.butRef = button)}
          className={`button button-date-label ${this.props.isMasked ? 'is-hidden' : ''}`}
          onClick={(event: any) => this.open(event)}
          disabled={this.props.isToDisable}
        >
          {this.props.label ? this.props.label : this.dateFormatted}
          <Calendar className="icon icon-svg" />
        </button>

        {this.props.opened && this.hasValidLabels() && (
          <div className="date-filter">
            <DateInputComponent
              isRange={this.props.isRange}
              startDate={startDate}
              startTime={startDate}
              endDate={endDate}
              endTime={endDate}
              validateLabel={this.selectLabel}
              cancelLabel={this.cancelLabel}
              onSelect={(event: any) => this.onSelect(event)}
              lang={getLanguage(this.getCurrentLanguageCalendar())}
              ref={this.dateInputInstance}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  ...ownProps,
  t: state.i18n.t
});

export default connect(mapStateToProps)(DatePickerComponent);
