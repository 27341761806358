import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import changeTab from './redux/actions';
import RfTuningComponent from './rf-tuning-component';

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    currentTab: state.devices.detail.rfTuning.main.currentTab,
    currentFrequencyTab: state.devices.detail.rfTuning.status.currentFrequencyTab,
    hhid: ownProps.hhid,
    id: ownProps.id
  };
}

export default connect(mapStateToProps, { changeTab })(RfTuningComponent);
