import React, { Component, Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter, push } from 'connected-react-router';
import { Provider as ReduxProvider, connect } from 'react-redux';
import Route from 'core/router';
import { Navbar } from 'application/header';
import { ModuleRoute } from 'core/router/module-route-component';
import SpinnerComponent from 'core/spinner';
import ToasterComponent from 'core/toaster';
import T from 'core/i18n/t';
import OverlayModalContainer from '../core/modal';
import packageJson from '../../package.json';
import { navbarWithTabs } from './header/navbar/redux/config';

interface ApplicationComponentProps {
  application: any;
  modal: any;
  spinner: any;
  router: any;
  store: any;
  histor: any;
  push: any;
  routes: Array<ModuleRoute>;
}

class ApplicationComponent extends Component<ApplicationComponentProps> {
  render() {
    return (
      <ReduxProvider store={this.props.store}>
        <Fragment>
          <ConnectedRouter history={this.props.histor}>
            {this.props.spinner.isActive && <SpinnerComponent />}
            <ToasterComponent />
            {this.props.router &&
              !navbarWithTabs.some((item) =>
                this.props.router.location.pathname.includes(item)
              ) && <Navbar />}
            <Switch>
              {this.props.routes &&
                this.props.routes.map((route, key) => <Route key={key} {...route} />)}
            </Switch>
            {this.props.modal.overlay.active && <OverlayModalContainer />}
            {this.props.routes && this.props.routes.length > 2 && (
              <footer className="footer footer-style">
                <div className="content has-text-right">
                  Vodafone | <T>footer.copyright</T> {new Date().getFullYear()} -{' '}
                  {packageJson.version}
                </div>
              </footer>
            )}
          </ConnectedRouter>
        </Fragment>
      </ReduxProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    router: state.router,
    routes: state.routes.loaded,
    application: state.application,
    modal: state.modal,
    spinner: state.spinner
  };
};

export default connect(mapStateToProps, { push })(ApplicationComponent);
