import { ApplicationState } from 'application/application-redux';
import {
  onDeleteChangesIfExist,
  getTuningDataAux,
  mergeChanges,
  orderChanges,
  saveChanges,
  getChangesByItem
} from '../../redux/action';
import { DVB_ACTIONS, DVB_COLUMNS } from '../../redux/enums';
import { DVBNetwork } from '../../redux/redux-networks/types';
import { setStreamsOnMove } from '../../redux/redux-streams/action';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import {
  DVBManagementPopUpAction,
  DVB_MANAGEMENT_POPUP,
  DVB_MANAGEMENT_POPUP_CHANGE_ITEM,
  DVB_MANAGEMENT_POPUP_MOVE,
  DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN,
  DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS,
  DVB_MANAGEMENT_POPUP_OLD_OBJECT,
  DVB_MANAGEMENT_POPUP_TOGGLE
} from './types';

function onDelete(id: string, column?: DVB_COLUMNS) {
  return async function (dispatch: any, getState: () => ApplicationState) {
    const request = getState().management.spectrum.requests.selectedRequest;
    dispatch(
      openModal({
        active: true,
        content: getState().i18n.t('spectrum.delete.message'),
        execute: () => {
          if (column) {
            const changes = orderChanges(request, column);
            const objectChanges = getChangesByItem(changes, id);

            if (objectChanges && objectChanges.length) {
              onDeleteChangesIfExist(dispatch, objectChanges, column);
              dispatch(mergeChanges(column));
            } else {
              dispatch(saveChanges());
            }
          }
          dispatch(closeModal());
        },
        title: getState().i18n.t('modal.title.delete')
      })
    );
  };
}

export function action(type: any, payload?: any): DVBManagementPopUpAction {
  return {
    type: type,
    payload: payload
  };
}

export function getNextOptions(dispatch: Function, dvbManagement: any, field: string) {
  dispatch(
    action(DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS, {
      dvbManagement: {
        regions: {
          items: dvbManagement[DVB_COLUMNS.REGION].items,
          selected: dvbManagement[DVB_COLUMNS.REGION].selected
        },
        nodes: {
          selected: dvbManagement[DVB_COLUMNS.NODE].selected
        },
        networks: {
          items: dvbManagement[DVB_COLUMNS.NETWORK].itemsAux,
          selected: dvbManagement[DVB_COLUMNS.NETWORK].selected
        },
        streams: {
          items: dvbManagement[DVB_COLUMNS.STREAM].itemsAux,
          selected: dvbManagement[DVB_COLUMNS.STREAM].selected
        },
        field,
        popUp: dvbManagement.main.popUp
      }
    })
  );
}

export function selectDropdown(dvbManagement: any, field: string, id: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    if (field === DVB_COLUMNS.REGION || field === DVB_COLUMNS.NODE) {
      dispatch(action(DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN, { dropdown: DVB_COLUMNS.NETWORK }));
      dispatch(action(DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN, { dropdown: DVB_COLUMNS.STREAM }));
    }

    if (field === DVB_COLUMNS.NODE) {
      await Promise.all([getTuningDataAux(dispatch, getState, id, true, true)]);
    }

    if (field === DVB_COLUMNS.NETWORK) {
      const networks = dvbManagement.network.itemsAux;
      const selectedNetwork = networks?.find((item: DVBNetwork) => item.id == id);

      await Promise.all([dispatch(setStreamsOnMove(selectedNetwork?.children, true))]);
    }

    if (field === DVB_COLUMNS.REGION) {
      getNextOptions(dispatch, dvbManagement, field);
    }
  };
}

export function setChangeInfo(changeAction: DVB_ACTIONS, column?: DVB_COLUMNS, item?: any) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const dvbManagement = getState().management.spectrum.dvbManagement;
    dispatch(action(DVB_MANAGEMENT_POPUP, { action: changeAction, column, item }));

    if (column === DVB_COLUMNS.NODE || column === DVB_COLUMNS.REGION) {
      dispatch(
        action(DVB_MANAGEMENT_POPUP_CHANGE_ITEM, {
          field: 'regionId',
          value: dvbManagement.region.selected?.regionId
        })
      );
    }

    dispatch(action(DVB_MANAGEMENT_POPUP_OLD_OBJECT, { dvbManagement }));

    switch (changeAction) {
      case DVB_ACTIONS.DELETE:
        dispatch(onDelete(item.rootId, column));
        break;
      case DVB_ACTIONS.CREATE:
      case DVB_ACTIONS.UPDATE:
      case DVB_ACTIONS.VIEW:
      case DVB_ACTIONS.SCHEDULE:
        dispatch(togglePopUp(true));
        break;
      case DVB_ACTIONS.MOVE:
        dispatch(
          action(DVB_MANAGEMENT_POPUP_MOVE, {
            dvbManagement,
            column
          })
        );
        dispatch(togglePopUp(true));
        break;
      case DVB_ACTIONS.COPY_PASTE: {
        dispatch(saveChanges());
        break;
      }
      default:
        break;
    }
  };
}

export function togglePopUp(isActive: boolean): DVBManagementPopUpAction {
  return {
    type: DVB_MANAGEMENT_POPUP_TOGGLE,
    payload: { isActive }
  };
}
