import moment from 'moment';

export function isEmpty(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function sortList(list: any, fieldToSort?: string, order: string = 'ASC') {
  let field = fieldToSort;

  return list.sort(function(a: any, b: any) {
    if (!field) {
      field = a.label ? 'label' : 'id';
    }

    const isNumber =
      field.toLowerCase().includes('id') &&
      !Number.isNaN(Number(a[field])) &&
      !Number.isNaN(Number(b[field]));
    const isDate = moment.utc(a[field]).isValid() && moment.utc(b[field]).isValid();
    let paramA;
    let paramB;

    if (isNumber) {
      paramA = Number(a[field]);
      paramB = Number(b[field]);
    } else if (isDate) {
      if (order === 'ASC') {
        return moment.utc(a[field]).diff(moment.utc(b[field]));
      }
      return moment.utc(b[field]).diff(moment.utc(a[field]));
    } else {
      paramA = a[field].toLowerCase();
      paramB = b[field].toLowerCase();
    }

    if (order === 'ASC') {
      return paramA < paramB ? -1 : 0;
    } else {
      return paramA > paramB ? -1 : 0;
    }
  });
}
