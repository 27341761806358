import { t } from 'i18next'
import React, { useState } from 'react'
import ServicesForm from '../ServicesForm'
import { HeaderProps, ServiceType } from '../../types'
import './index.scss';
import { useDispatch } from 'react-redux';
import { setService } from '../../redux/service/actions';
import { Dispatch } from 'redux';

function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>, dispatch: Dispatch<any>) {
  dispatch(setService(JSON.parse(e.target.value)))
}

export default function ServicesHeader({ selected, services, createService }: HeaderProps) {
  const [serviceFormActive, setServiceFormActive] = useState(false)
  const dispatch = useDispatch()
  
  return (
    <div className="is-flex header-wrapper">
      <div className="header-container">
          <div className="select is-fullwidth">
            <select
              value={JSON.stringify(selected)}
              onChange={(e) => handleOnChange(e, dispatch)}
              >
              <option value={0} disabled>{t('anonymizer.select_service')}</option>
              {services.map((service) => <option key={service.id} value={JSON.stringify(service)}>{service.id}</option>)}
            </select>
          </div>
        </div>
          <button
            className="button is-larger is-primary"
            onClick={() => {
              setServiceFormActive(true)
            }}
            >
              {t('anonymizer.new_service')}
          </button>
          {serviceFormActive && <ServicesForm setActive={setServiceFormActive} serviceAction={createService}/>}
      </div>
  )
}
