export const FIRST_PAGE = 'FIRST_PAGE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const LAST_PAGE = 'LAST_PAGE';
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';
export const RESET_PAGE_NUMBER = 'RESET_PAGE_NUMBER';
export const ORDER_ITEMS = 'ORDER_ITEMS';
export const CHANGE_FIELD_ORDER = 'CHANGE_FIELD_ORDER'
export const TO_PAGE = 'TO_PAGE'

export interface PaginationConfig {
  pageSize: number;
  pageNumber: number;
  sortField?: string;
  sortOrder?: string;
  doubleArrows?: boolean;
  pageSave?: number;
}

export interface PaginationState {
  pagination: Map<string, PaginationConfig>;
}

export interface PaginationAction {
  type:
    | typeof FIRST_PAGE
    | typeof NEXT_PAGE
    | typeof PREVIOUS_PAGE
    | typeof LAST_PAGE
    | typeof CHANGE_PAGE_SIZE
    | typeof RESET_PAGE_NUMBER
    | typeof ORDER_ITEMS
    | typeof CHANGE_FIELD_ORDER
    | typeof TO_PAGE;
  payload: any;
}

export interface PaginationRequest {
  currentPage: number;
  size: number;
}
