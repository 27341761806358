import React, { Component, Fragment } from 'react';
import CardComponent from 'core/card';
import { ConstructorMap, MultipleSearchFilter, MultipleSearchState, SelectedFilters } from './redux/types';
import FieldComponent from 'core/form/field/field-container';
import { Filter } from 'assets/svg';
import './multiple-search-styles.scss';
import { TFunction } from 'i18next';
import { CardPaddings } from 'core/card/enum';
import DateUtils from 'core/utils/date-utils';
import { dvrMultipleSearchConstructor } from './redux/config';

interface MultipleSearchComponentProps {
  multipleSearch: MultipleSearchState;
  id: string;
  i18nKey: string;
  t: TFunction;
  toggleShowMoreFilters: (id: string) => void;
  clearAllFilters: (id: string) => void;
  deleteFilter: (id: string, filter: any, index: number) => void;
  fieldsToHide: Array<String>;
  onMultipleSearch: any;
  badgesOutside: boolean;
  disabledByDefault: boolean;
  removeCardPadding?: boolean;
  goToFirstPage: (id: string) => void;
  resetTable?: Function;
  inputDisabled?: boolean;
  isSearchRequestStyle?: boolean;
  persistentFilter?: Array<string>
}

export default class MultipleSearchComponent extends Component<MultipleSearchComponentProps> {
   badgeLabel(filterName: string, filterValue: any) {
    const {t} = this.props
    
    if(typeof filterValue === "object") {
      return `${t(`general.from`)} ${DateUtils.formatDate(filterValue.start)} ${t(`general.to`)} ${DateUtils.formatDate(filterValue.end)}`
    }

    const dateFilters = ["deviceShadow.reported.status.lastConnectionDate"]

    if(dateFilters.includes(filterName)) {
      const dateRange: Array<number> = []
      filterValue.split(" ").forEach((filterElement: string) => {
        const intValue = parseInt(filterElement)
        if(intValue) {
          dateRange.push(intValue * 1000)
        }
      })

      return `${t(`general.from`)} ${DateUtils.formatDate(dateRange[0])} ${t(`general.to`)} ${DateUtils.formatDate(dateRange[1])}`
    }
    
    return filterValue
  }

  isToDisplayClearFilters(selectedFilters: Array<SelectedFilters>, persistentFilters?: Array<string>) {
    const { id } = this.props
    const noPersistentFilterCondition = selectedFilters.length && !persistentFilters?.length
    const withPersistentFilterCondition = persistentFilters?.length && selectedFilters.filter((filter: any) => !persistentFilters?.includes(filter.name)).length > 0
    let persistentFilterInitialValueChangedCondition = false

    if(persistentFilters?.length) {
      const initialFilterStateMap: ConstructorMap = {
        'accordion.dvr': dvrMultipleSearchConstructor
      }
      const initialState = initialFilterStateMap[id]()

      persistentFilters.forEach(persistentFilter => {
        const selectedFilter = selectedFilters.find((current) => current.name === persistentFilter)
        const selectedInitialFilter = initialState.selectedFilters.find(current => current.name === persistentFilter)        
        
        if (selectedFilter?.value !== selectedInitialFilter?.value) {
          persistentFilterInitialValueChangedCondition = true
        }
      })
    }

    return noPersistentFilterCondition || withPersistentFilterCondition || persistentFilterInitialValueChangedCondition
  }

  displayBadges(search: any) {
    const { clearAllFilters, deleteFilter, id, t, i18nKey, persistentFilter } = this.props;
    const displayClearFiltersButton = this.isToDisplayClearFilters(search.selectedFilters, persistentFilter)
  
    return (
      <Fragment>
        {search && search.selectedFilters.length > 0 && (
          <>
            <div className="columns has-padding-md">
              <div className="column is-flex badges has-padding-bottom-none">
                {search &&
                  search.selectedFilters.map((filter: any, index: number) => (
                    <span
                      className="filter-badge is-secondary is-rounded is-small has-margin-right-md has-margin-bottom-md"
                      key={filter + index}
                    >
                      {`${t(`${i18nKey}.field_${filter.name}`)} : `}
                      {this.badgeLabel(filter.name, filter.value)}
                      {(!persistentFilter || !persistentFilter.includes(filter.name)) &&
                        <button
                        className="delete is-small"
                        onClick={() => [
                          deleteFilter(id, filter, index),
                          search.selectedFilters.length === 0 &&
                          this.props.resetTable &&
                          this.props.resetTable()
                        ]}
                        >
                          x
                        </button>
                      }
                    </span>
                  ))}
              </div>
              {displayClearFiltersButton &&
                <div className="column is-flex is-justified-end has-padding-bottom-none">
                  <button
                    className="button-link dropdown-link button-action"
                    onClick={() => [
                      clearAllFilters(id),
                      this.props.resetTable && this.props.resetTable()
                    ]}
                    >
                    {t(`general.clear_filters`)}
                  </button>
                </div>
              }
            </div>
          </>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
      toggleShowMoreFilters,
      onMultipleSearch,
      id,
      multipleSearch,
      fieldsToHide,
      i18nKey,
      badgesOutside,
      disabledByDefault,
      removeCardPadding,
      goToFirstPage, 
      isSearchRequestStyle, 
    } = this.props;
    const search = multipleSearch.multipleFilters.get(id);

    return (
      <>
        <CardComponent padding={removeCardPadding ? CardPaddings.NONE : CardPaddings.MEDIUM}>
          {search &&
            search.levels.map(
              (level: any, levelId: number) =>
                level.level === 1 && (
                  <div className="columns flex-wrap has-margin-none" key={level + levelId}>
                    {level.categories.map((category: any, categoryId: number) => (
                      <div className="category" key={category + categoryId}>
                        {category.isToShowLabel && (
                          <h4 className="text is-h5 category-title">
                            {t(`${i18nKey}.label_${category.label}`)}
                          </h4>
                        )}
                        <div className="column">
                          <div className="columns">
                            {category.fields.map((field: any, index: number) => (
                              <Fragment key={field + index}>
                                {(fieldsToHide && !fieldsToHide.includes(field.name)) ||
                                !fieldsToHide ? (
                                  <div className={`column ${field.class}`}>
                                    <FieldComponent
                                      fieldData={{
                                        type: field.type,
                                        name: field.name,
                                        listValues: fieldsToHide ? field.listValues?.filter((field: string) => !fieldsToHide.includes(field)) || [] : field.listValues,
                                        currentValue: field.currentValue,
                                        tooltipText: field.tooltipText,
                                        translateList: field.translateList,
                                        dateKey: field.dateKey,
                                        removeLabel: field.removeLabel,
                                        inputDisabled: field.disabled
                                          ? this.props.inputDisabled
                                          : false
                                      }}
                                      id={id}
                                      i18nKey={i18nKey}
                                      displayEmpty={field.hasOwnProperty('displayEmpty') ? field.displayEmpty : true}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {index === category.fields.length - 1 && (
                                  <div className="column text-align-end">
                                    <button
                                      className={isSearchRequestStyle ? "is-secondary button" : "button is-rounded is-larger is-primary"}
                                      onClick={() => [goToFirstPage(id), onMultipleSearch()]}
                                      disabled={
                                        disabledByDefault && search.selectedFilters.length === 0
                                      }
                                    >
                                      {t('general.search')}
                                    </button>
                                    <button
                                      className={`button is-circle ${
                                        search.showFilters && 'is-selected'
                                      } ${search && search.levels.length === 1 && 'is-hidden'}`}
                                      onClick={() => toggleShowMoreFilters(id)}
                                    >
                                      <Filter className="icon-svg" />
                                    </button>
                                  </div>
                                )}
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
            )}

          <div
            className={`filters ${
              search && search.showFilters ? 'filters-expanded' : 'filters-collapsed'
            }`}
          >
            {search &&
              search.levels.map(
                (level: any, levelId: number) =>
                  level.level !== 1 && (
                    <div
                      className="columns flex-wrap has-margin-none has-padding-top-sm"
                      key={level + levelId}
                    >
                      {level.categories.map((category: any, categoryId: number) => (
                        <div
                          className={`category${
                            level.categories.length === 2 ? ' column has-padding-none' : ''
                          }`}
                          key={category + categoryId}
                        >
                          {category.isToShowLabel && (
                            <h4 className="text is-h5 category-title">
                              {t(`${i18nKey}.label_${category.label}`)}
                            </h4>
                          )}
                          <div className="column">
                            <div className="columns">
                              {category.fields.map((field: any, index: number) =>
                                (fieldsToHide && !fieldsToHide.includes(field.name)) ||
                                !fieldsToHide ? (
                                  <div className={`column ${field.class}`} key={field + index}>
                                    <FieldComponent
                                      fieldData={{
                                        type: field.type,
                                        name: field.name,
                                        listValues: field.listValues,
                                        currentValue: field.currentValue,
                                        translateList: field.translateList,
                                        tooltipText: field.tooltipText,
                                        dateKey: field.dateKey
                                      }}
                                      id={id}
                                      i18nKey={i18nKey}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
              )}
            {!badgesOutside ? this.displayBadges(search) : ''}
          </div>
        </CardComponent>
        {badgesOutside ? this.displayBadges(search) : ''}
      </>
    );
  }
}
