import { Close } from 'assets/svg';
import PopUpComponent from 'core/pop-up';
import React, { Component } from 'react';
import AuthService from 'core/auth';
import { TFunction } from 'i18next';
import { NewSettings, UserSettingsState } from './redux/types';
import { CardSizes } from 'core/card/enum';

interface UserSettingsProps {
  userSettings: UserSettingsState;
  toggleUserSettings: () => void;
  onChangeDropdowns: (dropdownId: string, value: string) => void;
  fetchUserSettings: () => void;
  updateUserSettings: (newSettings: NewSettings) => void;
  t: TFunction;
}

export default class UserSettingsComponent extends Component<UserSettingsProps> {
  componentDidMount() {
    const { fetchUserSettings, userSettings } = this.props;
    const currentOpco = AuthService.getCurrentOpco();
    const currentLanguage = AuthService.getCurrentLanguage();

    if (currentLanguage && currentOpco && userSettings.actualSettings === undefined) {
      fetchUserSettings();
    }
  }
  render() {
    const { userSettings, t, toggleUserSettings, onChangeDropdowns, updateUserSettings } =
      this.props;
    const user = AuthService.getCurrentUser();

    const userOpcosHasDefaultSettingOpco =
      user.opcos.indexOf(
        userSettings.actualSettings ? `vf${userSettings.actualSettings.opco}` : ''
      ) !== -1;

    return (
      <PopUpComponent isActive={userSettings.isActive} size={CardSizes.SMALL}>
        <div className="columns is-flex">
          <div className="column is-vertical-center">
            <h5 className="text is-h5 is-bold is-marginless">
              {t('user_settings.label_default_settings')}
            </h5>
          </div>
          <div className="column is-narrow">
            <button className="button button-action" onClick={() => toggleUserSettings()}>
              <Close className="icon-svg icon-small has-margin-top-sm" />
            </button>
          </div>
        </div>

        <div className="columns flex-wrap">
          <div className="column is-12 has-padding-bottom-none">
            <label className="label text is-bold">
              {t('user_settings.label_operating_company')}
            </label>
          </div>

          <div className="column is-12">
            <div className="select is-fullwidth">
              <select
                name={t('user_settings.label_operating_company')}
                value={userSettings.newSettings && userSettings.newSettings.opco}
                onChange={(event: any) => onChangeDropdowns('opco', event.target.value.substr(-2))}
              >
                {!userOpcosHasDefaultSettingOpco ? (
                  <option key={Math.random()} value={userSettings.actualSettings?.opco.substr(-2)}>
                    {userSettings.actualSettings?.opco.substr(-2).toLocaleUpperCase()}
                  </option>
                ) : null}
                {user.opcos &&
                  user.opcos.map((opco, index) => (
                    <option key={index} value={opco.substr(-2)}>
                      {opco.substr(-2).toLocaleUpperCase()}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="column is-12 has-padding-bottom-none">
            <label className="label text is-bold">{t('user_settings.label_language')}</label>
          </div>

          <div className="column is-12">
            <div className="select is-fullwidth">
              <select
                name={t('user_settings.label_language')}
                value={userSettings.newSettings && userSettings.newSettings.language}
                onChange={(event: any) => onChangeDropdowns('language', event.target.value)}
              >
                {user.langs &&
                  user.langs.map((lang, index) => (
                    <option key={index} value={lang.substr(-2)}>
                      {lang.substr(-2).toLocaleUpperCase()}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="columns is-justified-end">
          <div className="column is-narrow">
            <button
              className="button is-primary is-larger"
              onClick={() =>
                userSettings &&
                userSettings.newSettings &&
                updateUserSettings(userSettings.newSettings)
              }
              disabled={!userSettings.hasChanged}
            >
              {t('user_settings.button_apply')}
            </button>
          </div>
        </div>
      </PopUpComponent>
    );
  }
}
