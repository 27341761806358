import {
  ADVANCED_SEARCH_HISTORY,
  ADVANCED_SEARCH_HISTORY_FAILURE,
  ADVANCED_SEARCH_HISTORY_SUCCESS,
  ADVANCED_SEARCH_HISTORY_UPDATE,
  ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE,
  ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS,
  ADVANCED_SEARCH_HISTORY_CHANGE_TAB,
  SET_CURRENT_HISTORY_FILTER_VALUE,
  ADVANCED_SEARCH_EDIT_FAVOURITE,
  EDIT_CURRENT_FAVOURITE_VALUE,
  AdvancedSearchHistoryState
} from './types';

interface AdvancedSearchHistoryAction {
  type: string;
  payload: any;
}

const initialState: AdvancedSearchHistoryState = {
  list: [],
  all: [],
  currentTab: 'favourites',
  query: ''
};

function _sortByDate(items: Array<any>) {
  items && items
    .sort(function(a, b) {
      return a.searchDate < b.searchDate ? -1 : a.searchDate > b.searchDate ? 1 : 0;
    })
    .reverse();

  return items;
}

export default function(state = initialState, action: AdvancedSearchHistoryAction) {
  switch (action.type) {
    case ADVANCED_SEARCH_HISTORY:
    case ADVANCED_SEARCH_HISTORY_FAILURE:
    case ADVANCED_SEARCH_HISTORY_UPDATE:
    case ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS:
    case ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE:
    case ADVANCED_SEARCH_HISTORY_SUCCESS: {
      const resultList = action.payload.content;
      return {
        ...state,
        all: _sortByDate(resultList),
        list: _sortByDate(
          state.currentTab === 'favourites'
            ? resultList && resultList.filter((item: any) => item.isHighlight === 'true')
            : resultList
        )
      };
    }

    case ADVANCED_SEARCH_HISTORY_CHANGE_TAB: {
      const isFavouritesTab = action.payload === 'favourites';
      return {
        ...state,
        list: _sortByDate(
          !action.payload || !isFavouritesTab
            ? state.all
            : state.all.filter((item: any) => item.isHighlight === 'true')
        ),
        currentTab: action.payload
      };
    }

    case SET_CURRENT_HISTORY_FILTER_VALUE: {
      return {
        ...state,
        query: action.payload
      };
    }
    case ADVANCED_SEARCH_EDIT_FAVOURITE: {
      return {
        ...state,
        list: state.list.map((item: any) => {
          if (item === action.payload) {
            item.isEditing = item.isEditing ? false : true;
          }
          return item;
        })
      };
    }
    case EDIT_CURRENT_FAVOURITE_VALUE: {
      return {
        ...state,
        list: state.list.map((item: any) => {
          if (item === action.payload.item) {
            item.searchName = action.payload.value;
          }
          return item;
        })
      };
    }
    default:
      return { ...state };
  }
}
