import { connect } from 'react-redux';
import IpGeolocationComponent from './ip-geolocation-component';
import { open, close } from './modal-upload/redux/actions';
import { execute, onFilter, exportData } from './grid/redux/action';

function mapStateToProps(state: any) {
  return {
    results: state.management.ipGeolocation.grid.results,
    t: state.i18n.t
  };
}

export default connect(mapStateToProps, {
  openModal: open,
  closeModal: close,
  execute,
  onFilter,
  exportData
})(IpGeolocationComponent);
