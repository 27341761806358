import React from 'react';

type Props = {
  collision: any;
  mappings: Array<any>;
  keys: Array<string>;
  selected: string;
  onChange: any;
};

export default class CollisionRowComponent extends React.Component<Props> {
  render() {
    return (
      <tbody>
        <tr>
          <td style={{ textAlign: 'center', padding: 10 }}>
            <input
              type="radio"
              checked={this.props.selected === this.props.collision.uuid}
              onChange={() => this.props.onChange(this.props.collision.uuid)}
            />
          </td>
          <td>Collision</td>
          {this.props.keys.map((key: string, index: number) => (
            <td key={index}>{this.props.collision[key]}</td>
          ))}
        </tr>

        {this.props.mappings.map((mapping: any, index: number) => (
          <tr key={index}>
            <td style={{ textAlign: 'center', padding: 10 }}>
              <input
                type="radio"
                checked={this.props.selected === mapping.uuid}
                onChange={() => this.props.onChange(mapping.uuid)}
              />
            </td>
            <td style={{ textAlign: 'center', padding: 10 }}>
              <span style={{ color: '#e60000' }}>Database</span>
            </td>
            {this.props.keys.map((key: string, index: number) => (
              <td key={index}>
                <span>{mapping[key]}</span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}
