import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import AuthService from '../../../../core/auth/auth-service';

import {
  ONNET_OFFNET_LIST_REQUEST,
  ONNET_OFFNET_LIST_SUCCESS,
  ONNET_OFFNET_LIST_FAILURE,
  ONNET_OFFNET_SELECT_ENTRY,
  ONNET_OFFNET_SAVE_ENTRY,
  ONNET_OFFNET_REMOVE_ENTRY,
  ONNET_OFFNET_SAVE_NEW_ENTRY,
  OnnetOffnetResult,
  OnnetOffnetRow,
} from './reducer';
import { envs } from 'application/envHandler';

type ResultDataRequest = {
  ipv4Entries?: ChangesDataType
  ipv6Entries?: ChangesDataType
}

type ChangesDataType = Array<{
  entry: string
  entryStatus: string
}>

export type SelectEntryFunction = (index: number, range: string) => {
  type: string
  payload: {
      index: number
      range: string
  }
}

export type RemoveEntryFunction = (index: number, range: string) => {
  type: string
  payload: {
      index: number
      range: string
  }
}

export type SaveEntryFunction = (index: number, range: string, value: string) => {
  type: string
  payload: {
      index: number
      range: string
      value: string
  }
}

export type SaveNewEntryFunction = (value: string, range: string) => {
  type: string;
  payload: {
      value: string;
      range: string;
  };
}

function request() {
  return {
    type: ONNET_OFFNET_LIST_REQUEST
  };
}

function success(payload: any) {
  return {
    type: ONNET_OFFNET_LIST_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: ONNET_OFFNET_LIST_FAILURE,
    payload
  };
}

function getUnchangedRanges(ranges: Array<OnnetOffnetRow>) {
  return ranges.filter((range: any) => !range.entryStatus || range.entryStatus === 'ADD').map((range: any) => range.entry)
}

function submitSuccess(payload: OnnetOffnetResult) {
  return {
    type: ONNET_OFFNET_LIST_SUCCESS,
    payload: [
      {
        opco: payload.opco,
        ranges: getUnchangedRanges(payload.ranges),
        'ranges-ipv6': getUnchangedRanges(payload.rangesIpv6),
      }
    ]
  }
}

export function selectEntry(index: number, range: string) {
  return {
    type: ONNET_OFFNET_SELECT_ENTRY,
    payload: {
      index,
      range,
    }
  };
}

export function saveEntry(index: number, range: string, value: string) {
  return {
    type: ONNET_OFFNET_SAVE_ENTRY,
    payload: {
      index,
      range,
      value,
    }
  };
}

export function saveNewEntry(value: string, range: string) {
  return {
    type: ONNET_OFFNET_SAVE_NEW_ENTRY,
    payload: {
      value,
      range,
    }
  };
}

export function removeEntry(index: number, range: string) {
  return {
    type: ONNET_OFFNET_REMOVE_ENTRY,
    payload: {
      index,
      range,
    }
  };
}

export function execute() {
  return async (dispatch: any) => {
    dispatch(request());
    dispatch(add('ONNET_OFFNET_LIST_REQUEST', {}));
    await AuthService.refreshToken();
    const currentOpco = AuthService.getCurrentOpco();
    /* For debugging
    dispatch(remove('ONNET_OFFNET_LIST_REQUEST'));
    return dispatch(success([
      {
        rangesIpv6: [
          '2001:db8::ff00:42:8330',
          '2001:db8::ff00:42:8329/64',
        ],
        ranges: [
          '192.168.0.0/16',
          '253.86.80.0/24',
          '83.240.190.0/24',
          '94.200.145.0/24',
        ],
        opco: 'vfde',
      }
    ]))
    */
    apiClient
      .get(`${envs.REACT_APP_API_URL}/onnetOffnet`, { headers: { opco: currentOpco } })
      .then(
        response => {
          dispatch(remove('ONNET_OFFNET_LIST_REQUEST'));
          return dispatch(success(response.data));
        },
        rejection => {
          dispatch(remove('ONNET_OFFNET_LIST_REQUEST'));
          dispatch(
            addToaster({
              title: 'home.icon_onnet_offnet',
              message: 'error_messages.VIPOC_ERROR_012',
              type: 'danger'
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}

export function getRangeEntries(ranges: Array<OnnetOffnetRow>) {
  return ranges.map((range: any) => ({ entry: range.entry, entryStatus: range.entryStatus })).filter((range: any) => range.entryStatus)
}

export function submitChanges(result: OnnetOffnetResult) {
  return async (dispatch: any) => {
    const data: ResultDataRequest = {}

    const ipv4Entries = getRangeEntries(result.ranges)
    const ipv6Entries = getRangeEntries(result.rangesIpv6)

    if(ipv4Entries.length) {
      data.ipv4Entries = ipv4Entries
    }

    if(ipv6Entries.length) {
      data.ipv6Entries = ipv6Entries
    }

    dispatch(request());
    dispatch(add('ONNET_OFFNET_LIST_REQUEST', {}));
    const currentOpco = AuthService.getCurrentOpco();
    apiClient
      .put(`${envs.REACT_APP_API_URL}/onnetOffnet`, data, { headers: { opco: currentOpco } })
      .then(
        () => {
          dispatch(submitSuccess(result))
          dispatch(remove('ONNET_OFFNET_LIST_REQUEST'));
        },
        rejection => {
          let errorMsg: string = 'error_messages.VIPOC_ERROR_041';
          if (rejection.response && rejection.response.data) {
            errorMsg = rejection.response.data;
          }
          dispatch(
            addToaster({
              title: 'home.icon_onnet_offnet',
              message: JSON.stringify(errorMsg),
              type: 'danger'
            })
          );
          dispatch(remove('ONNET_OFFNET_LIST_REQUEST'));
          return dispatch(failure(rejection));
        }
      );
  };
}
