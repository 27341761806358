import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import './regionalization-styles.scss';
import { groupRegion, groupNode, columns } from './redux/config';
import {
  paginationRegionalization,
  regionalizationAssociate,
  regionalizationDisassociate
} from 'core/pagination-optimized/redux/config';
import TableComponent from 'core/table/table-container';
import CardComponent from 'core/card';
import T from 'core/i18n/t';
import { Location, Node, Edit, View, Sync } from 'assets/svg';
import FileUploadContainer from 'core/file-upload/file-upload-container';
import { Select, Selected, TableObject, TableState } from 'core/table/redux/types';
import { DVBManagementRegion, DVBRegion } from '../../dvb-management/redux/redux-regions/types';
import { DVBManagementNode, DVBNode } from '../../dvb-management/redux/redux-nodes/types';
import { sortList } from 'core/utils/general-utils';
import { DVB_COLUMNS } from '../../dvb-management/redux/enums';
import { DVBManagementState } from '../../dvb-management/redux/types';
import { ChannelsState } from '../channels/redux/redux-channels/type';
import {
  RegionalizationChannelsAssociations,
  RegionalizationChannelsAssociationsState
} from './redux/types';
import {
  SpectrumGetChannelsAscParams,
  SprectrumActionParams,
  SprectrumPutRegionalLCN
} from './redux/action';
import { getSelected } from 'core/table/redux/reducer';
import EditComponent from 'core/edit/edit-component';
import { RegionsParams } from '../../dvb-management/redux/redux-regions/action';

interface SpectrumRegionalizationProps {
  t: TFunction;
  push: (uri: string) => void;
  getRegions: (params: RegionsParams) => void;
  getChannelRegionalization: (params: SpectrumGetChannelsAscParams) => void;
  regionalizationDisassociateChannel: (params: SprectrumActionParams) => void;
  regionalizationAssociateChannel: (params: SprectrumActionParams) => void;
  putRegLCN: (params: SprectrumPutRegionalLCN) => void;
  editField: (table?: any, data?: any, id?: number) => void;
  getDownloadChannels: () => void;
  regions: DVBManagementRegion;
  nodes: DVBManagementNode;
  dvbManagement: DVBManagementState;
  channelsAssociation: RegionalizationChannelsAssociationsState;
  table: TableState;
  getTuningData: (nodeId?: string, isRegionalization?:boolean ) => void;
  setIsRegionalization:(responseNode:boolean)=> void;
}
export default class SpectrumRegionalizationComponent extends Component<
  SpectrumRegionalizationProps,
  {
    isActive: boolean;
    showRegions: boolean;
    onEdit: boolean;
    itemEdit: { mediaId: number; name: string; regionalLCN: number; channelId: number };
    selectId: string;
  }
> {
  constructor(props: SpectrumRegionalizationProps) {
    super(props);
    this.state = {
      showRegions: true,
      isActive: false,
      onEdit: false,
      itemEdit: { mediaId: NaN, name: '', regionalLCN: NaN, channelId: NaN },
      selectId: ''
    };
  }

  componentDidMount() {

    this.props.getRegions({ changes: false });
    this.props.setIsRegionalization(true)
    this.props.getTuningData(undefined, true);
  }

  toggle = () => {
    this.setState({
      showRegions: !this.state.showRegions
    });
  };

  editAction = (item: any) => {
    this.setState({
      onEdit: !this.state.onEdit,
      itemEdit: {
        mediaId: item.mediaId,
        name: item.name,
        regionalLCN: item.regionalLCN,
        channelId: item.channelId
      }
    });
  };

  onModalClose() {
    this.setState({ isActive: false });
  }

  editSave = () => {
    this.props.putRegLCN({
      value: this.state.itemEdit.regionalLCN,
      type: this.props.channelsAssociation.type,
      channelId: this.state.itemEdit.channelId,
      select: this.props.channelsAssociation.isSelected
    });
    this.onEditClose();
  };

  handleChange = (event: any) => {
    const value = event.target.value;
    this.setState({ itemEdit: { ...this.state.itemEdit, regionalLCN: value } });
  };

  disassociateAction = () => {
    const table = getSelected(this.props.table, 'spectrum.regionalization.disassociate');
    this.props.regionalizationDisassociateChannel({
      type: this.props.channelsAssociation.type,
      select: this.props.channelsAssociation.isSelected,
      item: table
    });
  };
  associateAction = () => {
    const table = getSelected(this.props.table, 'spectrum.regionalization.associate');
    this.props.regionalizationAssociateChannel({
      type: this.props.channelsAssociation.type,
      select: this.props.channelsAssociation.isSelected,
      item: table
    });
  };

  onEditClose() {
    this.setState({ onEdit: false });
  }

  selectRegionTabAction = () => {
    this.setState({ showRegions: true });
    this.props.getRegions({ changes: false });
  };

  selectNodeTabAction = () => {
    this.setState({ showRegions: false });
    this.props.getTuningData(undefined, true);  };

  selectRegionAction = (item: DVBRegion) => {
    this.setState({ selectId: item.id || '' });
    this.props.getChannelRegionalization({ select: item, type: 'region', clearCache: true });
    this.props.getChannelRegionalization({
      select: item,
      type: 'region',
      eligible: true,
      clearCache: true
    });
  };

  selectNodeAction(item: DVBNode) {
    this.setState({ selectId: item.id || '' });
    this.props.getChannelRegionalization({ select: item, type: 'node', clearCache: true });
    this.props.getChannelRegionalization({
      select: item,
      type: 'node',
      eligible: true,
      clearCache: true
    });
  }

  handleChangeLCN = (event: any, row: RegionalizationChannelsAssociations) => {
    const value = event.target.value;
    this.props.editField('spectrum.regionalization.associate', value, row.channelId);
  };

  getIcon = (isLoading: any, isNoData: boolean, type?: DVB_COLUMNS) => {
    if (isLoading) {
      return <Sync className="icon-svg icon-spinner" />;
    }
    switch (type) {
      case DVB_COLUMNS.REGION:
        return <Location className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      case DVB_COLUMNS.NODE:
        return <Node className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      default:
        break;
    }
  };

  emptycard = (isLoading: any, column: DVB_COLUMNS) => {
    return (
      <>
        <div className="no-data">{this.getIcon(isLoading, true, column)}</div>
      </>
    );
  };

  renderRegionsSidebar = (): JSX.Element => {
    const { regions } = this.props;

    if (!this.props.dvbManagement.main.general.isLoading.region) {
      return (
        <>
          {sortList(regions.items, 'label').map((region: DVBRegion) => (
            <div key={region.id}>
              <button
                className={`card-column-item mb-1 ${
                  region.id == this.state.selectId ? 'card-column-item-selected' : ''
                }`}
                onClick={() => {
                  this.selectRegionAction(region);
                }}
              >
                <Location className="icon-svg icon-highlighted icon-medium" />
                <div className="card-column-item-info">
                  <h6 className="text is-h6 is-bold is-marginless">{region.regionLabel}</h6>
                  <p>{groupRegion + ' ' + region.regionId}</p>
                </div>
              </button>
            </div>
          ))}
        </>
      );
    }
    return this.emptycard(
      this.props.dvbManagement.main.general.isLoading.region,
      DVB_COLUMNS.REGION
    );
  };

  renderNodesSidebar = (): JSX.Element => {
    const { nodes } = this.props;

    if (!this.props.dvbManagement.main.general.isLoading.node) {
      return sortList(nodes.items, 'label').map((node: any) => (
        <div key={node.id}>
          <button
            className={`card-column-item mb-1  ${
              node.id == this.state.selectId ? 'card-column-item-selected' : ''
            } `}
            onClick={() => {
              this.selectNodeAction(node);
            }}
          >
            <Node className="icon-svg icon-highlighted icon-medium" />
            <div className="card-column-item-info">
              <h6 className="text is-h6 is-bold is-marginless">{node.nodeLabel}</h6>
              <p>{groupNode + ' ' + node.nodeId}</p>
            </div>
          </button>
        </div>
      ));
    }
    return this.emptycard(this.props.dvbManagement.main.general.isLoading.node, DVB_COLUMNS.NODE);
  };

  render() {
    const { t, channelsAssociation } = this.props;

    return (
      <>
        <FileUploadContainer
          isActive={this.state.isActive}
          title={'Upload file'}
          clue={'Please select a file to upload and await its validation'}
          confirmButton={'Confirm Upload'}
          close={this.onModalClose.bind(this)}
          formatFile={'XLSX'}
          maxMBsize={10}
          pageType={'regionalization'}
        ></FileUploadContainer>

        <EditComponent
          t={t}
          isActiveEdit={this.state.onEdit}
          onClose={this.onEditClose.bind(this)}
          method={this.editSave.bind(this)}
        >
          <div className="text">
            <h6 className="text is-h6 is-bold">{t('spectrum.regions.column_regional_lcn')}</h6>
            <input
              type="text"
              className="input  my-3"
              value={this.state.itemEdit.regionalLCN || ''}
              onChange={this.handleChange}
            />
            <p className="mb-2">
              <span className="text is-bold">{t('spectrum.channel')}: </span>
              {this.state.itemEdit.name}
            </p>
            <p className="mb-4">
              <span className="text is-bold">{t('spectrum.regions.column_region_label')}: </span>
              {channelsAssociation.isSelected?.label || ''}
            </p>
          </div>
        </EditComponent>
        <section className="section-spaced">
          <div className="is-flex is-justified-between mt-6 mb-5">
            <h4 className="text is-bold is-h4">
              <T>spectrum.regionalization</T>
            </h4>
            <div>
              <button
                className="button is-rounded"
                onClick={() => {
                  this.props.getDownloadChannels();
                }}
              >
                {t('spectrum.button.download_csv').replace('CSV', 'Excel')}
              </button>
              <button
                className="button is-rounded"
                onClick={() => {
                  this.setState({ isActive: true });
                }}
              >
                {t('spectrum.button.upload_csv').replace('CSV', 'Excel')}
              </button>
            </div>
          </div>
          <div className="has-space-between columns mt-6">
            <div className="column is-one-quarter">
              <CardComponent>
                <div className="tabs">
                  <ul className="is-marginless">
                    <li
                      className={
                        this.state.showRegions ? 'is-active column is-half' : 'column is-half'
                      }
                    >
                      <button className="button-link" onClick={this.selectRegionTabAction}>
                        {t('spectrum.regions')}
                      </button>
                    </li>
                    <li
                      className={
                        this.state.showRegions ? 'column is-half' : 'is-active column is-half'
                      }
                    >
                      <button className="button-link" onClick={this.selectNodeTabAction}>
                        {t('spectrum.nodes')}
                      </button>
                    </li>
                  </ul>
                </div>
                <div id={'card-regionalization'} className="card-column">
                  {this.state.showRegions ? this.renderRegionsSidebar() : this.renderNodesSidebar()}
                </div>
              </CardComponent>
            </div>

            <div className="column">
              <TableComponent
                id="spectrum.regionalization.disassociate"
                tableClasses="mb-5 pb-1 pt-5"
                title={t('spectrum.channels_associated_channels')}
                onSearch={(filter: any) => {
                  this.props.getChannelRegionalization({
                    filter,
                    select: channelsAssociation.isSelected,
                    type: channelsAssociation.type
                  });
                }}
                selectData={{ field: 'channelId', select: Select.MULTIPLE, selectOne: false }}
                buttonAction={{
                  buttonName: 'spectrum.button_disassociate',
                  buttonClass: 'is-primary',
                  method: this.disassociateAction.bind(this)
                }}
                columns={columns}
                items={channelsAssociation.channelsAssociations.channels}
                editButton={{
                  method: this.editAction.bind(this)
                }}
                totalElements={channelsAssociation.channelsAssociations.totalCount}
                pagination={regionalizationDisassociate}
              />

              <TableComponent
                id="spectrum.regionalization.associate"
                tableClasses="pb-1 pt-5"
                title={t('spectrum.channels_remaining_channels')}
                onSearch={(filter: any) => {
                  this.props.getChannelRegionalization({
                    eligible: true,
                    filter,
                    select: channelsAssociation.isSelected,
                    type: channelsAssociation.type
                  });
                }}
                selectData={{ field: 'channelId', select: Select.MULTIPLE, selectOne: false }}
                buttonAction={{
                  buttonName: 'spectrum.button_associate',
                  buttonClass: 'is-primary-confirm',
                  method: this.associateAction.bind(this)
                }}
                columns={columns}
                items={channelsAssociation.channelsEligible.channels}
                inputText={{
                  placeholder: '',
                  valueFn: (row: RegionalizationChannelsAssociations) => {
                    const selectedRows =
                      getSelected(this.props.table, 'spectrum.regionalization.associate')
                        ?.selectedRows || [];
                    return selectedRows.find((item: any) => item.rowId === row.channelId)?.field;
                  },
                  method: this.handleChangeLCN
                }}
                totalElements={channelsAssociation.channelsEligible.totalCount}
                pagination={regionalizationAssociate}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}
