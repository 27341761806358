import { CardSizes } from 'core/card/enum';
import { sortList } from 'core/utils/general-utils';
import { popUpData } from '../../redux/config';
import { DVB_ACTIONS, DVB_COLUMNS, DVB_FIELD_TYPES } from '../../redux/enums';
import { DVBRegion } from '../../redux/redux-regions/types';
import {
  DVBManagementPopUpAction,
  DVBPopUp,
  FieldItem,
  DVB_MANAGEMENT_POPUP,
  DVB_MANAGEMENT_POPUP_CHANGE_ITEM,
  DVB_MANAGEMENT_POPUP_MOVE,
  DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN,
  DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS,
  DVB_MANAGEMENT_POPUP_OLD_OBJECT,
  DVB_MANAGEMENT_POPUP_SWITCH_MODE,
  DVB_MANAGEMENT_POPUP_TOGGLE
} from './types';

const initialState: DVBPopUp = {
  column: DVB_COLUMNS.NONE,
  data: [],
  hasChanged: false,
  isActive: false,
  parent: DVB_COLUMNS.NONE,
  size: CardSizes.NONE
};

function buildNextOptions(children: any, newField?: FieldItem) {
  if (newField) {
    newField.options = [];
    newField.value = '';
    if (children) {
      children = sortList(children);

      children.forEach((item: any) => {
        newField.options?.push({
          key: item.id,
          value: item.label
        });
      });
    }
  }
}

function canMove(data: Array<FieldItem>) {
  if (data && data.length) {
    for (let item of data) {
      if (!item.value) {
        return false;
      }
    }

    return true;
  }

  return false;
}

function getDropdownParent(parent: DVB_COLUMNS, value: DVB_COLUMNS) {
  if (parent === DVB_COLUMNS.NONE) {
    return value;
  }

  return parent;
}

function removeContextFromDropdown(field: FieldItem, column: DVB_COLUMNS, selectedObject: any) {
  if (column === field.key) {
    const options = field.options;

    if (options && options.length) {
      options.forEach((item: { key: string; value: string }, index: number) => {
        if (item.key === selectedObject.id) {
          return options.splice(index, 1);
        }
      });
    }
  }
}

export function hasFormChanged(newData: Array<FieldItem>, oldData: Array<FieldItem>) {
  if (newData && newData.length && oldData && oldData.length) {
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].value != oldData[i].value) {
        return true;
      }
    }
  }

  return false;
}

export default function(state = initialState, action: DVBManagementPopUpAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_POPUP: {
      const popUpDataConfig = popUpData.find(
        (item: DVBPopUp) => item.column === action.payload.column
      );

      if (popUpDataConfig && action.payload.item) {
        popUpDataConfig.data.forEach((item: FieldItem) => {
          const value = action.payload.item[item.key];
          item.value = value !== null && value !== undefined ? value.toString() : undefined;

          return item;
        });
      }

      return {
        ...state,
        action: action.payload.action || DVB_ACTIONS.NONE,
        column: action.payload.column || DVB_COLUMNS.NONE,
        data: popUpDataConfig ? popUpDataConfig.data : [],
        size: popUpDataConfig ? popUpDataConfig.size : CardSizes.LARGE,
        item: action.payload.item,
        hasChanged: false
      };
    }

    case DVB_MANAGEMENT_POPUP_CHANGE_ITEM: {
      const newItem = action.payload;
      const newData = state.data;

      if (newData) {
        const field = newData.find((fieldItem: FieldItem) => fieldItem.key === newItem.field);

        if (field) {
          field.value = newItem.value;
        }
      }

      return {
        ...state,
        hasChanged:
          state.action === DVB_ACTIONS.MOVE
            ? canMove(state.data)
            : hasFormChanged(state.data, state.oldData || [])
      };
    }

    case DVB_MANAGEMENT_POPUP_MOVE: {
      const dvbManagement = action.payload.dvbManagement;
      let parent = DVB_COLUMNS.NONE;
      let moveFields: Array<FieldItem> = [];

      switch (action.payload.column) {
        case DVB_COLUMNS.SERVICE: {
          parent = getDropdownParent(parent, DVB_COLUMNS.STREAM);

          const fieldItem: FieldItem = {
            isActive: true,
            key: DVB_COLUMNS.STREAM,
            label: 'spectrum.stream',
            type: DVB_FIELD_TYPES.DROPDOWN,
            value: '',
            options: []
          };
          moveFields.push(fieldItem);
        }

        case DVB_COLUMNS.STREAM: {
          parent = getDropdownParent(parent, DVB_COLUMNS.NETWORK);

          const fieldItem: FieldItem = {
            isActive: true,
            key: DVB_COLUMNS.NETWORK,
            label: 'spectrum.network',
            type: DVB_FIELD_TYPES.DROPDOWN,
            value: '',
            options: []
          };
          moveFields.push(fieldItem);
        }

        case DVB_COLUMNS.NETWORK: {
          parent = getDropdownParent(parent, DVB_COLUMNS.NODE);

          const fieldItem: FieldItem = {
            isActive: true,
            key: DVB_COLUMNS.NODE,
            label: 'spectrum.node',
            type: DVB_FIELD_TYPES.DROPDOWN,
            value: '',
            options: []
          };
          moveFields.push(fieldItem);
        }

        case DVB_COLUMNS.NODE: {
          parent = getDropdownParent(parent, DVB_COLUMNS.REGION);

          const fieldItem: FieldItem = {
            isActive: true,
            key: DVB_COLUMNS.REGION,
            label: 'spectrum.region',
            type: DVB_FIELD_TYPES.DROPDOWN,
            value: '',
            options: []
          };
          moveFields.push(fieldItem);
        }
        default: {
          break;
        }
      }

      moveFields = moveFields.reverse();
      dvbManagement[moveFields[0].key].items.forEach((item: DVBRegion) => {
        moveFields[0].options?.push({
          key: item.id || '',
          value: item.label || ''
        });
      });

      removeContextFromDropdown(moveFields[0], parent, dvbManagement[DVB_COLUMNS.REGION].selected);

      return {
        ...state,
        data: moveFields,
        parent: parent,
        hasChanged: canMove(moveFields)
      };
    }

    case DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN: {
      const dropdown = state.data.find((item: FieldItem) => item.key === action.payload.dropdown);

      if (dropdown) {
        dropdown.options = [];
      }

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS: {
      const dvbManagement = action.payload.dvbManagement;
      const popUpField = dvbManagement.field;
      const data = dvbManagement.popUp.data;

      if (data) {
        const field: FieldItem = data.find((item: FieldItem) => item.key === popUpField);

        switch (field.key) {
          case DVB_COLUMNS.REGION: {
            const region = dvbManagement.regions.items.find(
              (item: DVBRegion) => item.id == field.value
            );

            const nodes = region.children;

            const newField = data.find((item: FieldItem) => item.key === DVB_COLUMNS.NODE);
            buildNextOptions(nodes, newField);
            if (newField && dvbManagement.popUp.parent == DVB_COLUMNS.NODE) {
              removeContextFromDropdown(
                newField,
                dvbManagement.popUp.parent,
                dvbManagement.nodes.selected
              );
            }
            break;
          }

          case DVB_COLUMNS.NODE: {
            const networks = dvbManagement.networks.items;
            const newField = data.find((item: FieldItem) => item.key == DVB_COLUMNS.NETWORK);
            buildNextOptions(networks, newField);

            if (newField && dvbManagement.popUp.parent === DVB_COLUMNS.NETWORK) {
              removeContextFromDropdown(
                newField,
                dvbManagement.popUp.parent,
                dvbManagement.networks.selected
              );
            }
            break;
          }

          case DVB_COLUMNS.NETWORK: {
            const streams = dvbManagement.streams.items;
            const newField = data.find((item: FieldItem) => item.key == DVB_COLUMNS.STREAM);
            buildNextOptions(streams, newField);

            if (newField && dvbManagement.popUp.parent === DVB_COLUMNS.STREAM) {
              removeContextFromDropdown(
                newField,
                dvbManagement.popUp.parent,
                dvbManagement.streams.selected
              );
            }
            break;
          }

          default:
            break;
        }
      }

      return {
        ...state,
        data
      };
    }

    case DVB_MANAGEMENT_POPUP_OLD_OBJECT: {
      const dvbManagement = action.payload.dvbManagement;
      const originalItem =
        state.column !== DVB_COLUMNS.NONE && state.item
          ? dvbManagement[state.column].items.find((item: any) => item.rootId === state.item.rootId)
          : null;

      const oldData = state.data ? JSON.parse(JSON.stringify(state.data)) : [];

      if (originalItem) {
        Object.keys(originalItem).forEach((key: string) => {
          let oldField = oldData.find((item: any) => item.key === key);

          if (oldField) {
            oldField.value =
              originalItem[key] !== null && originalItem[key] !== undefined
                ? originalItem[key].toString()
                : undefined;
          }
        });
      }
      return {
        ...state,
        oldData,
        hasChanged:
          state.action === DVB_ACTIONS.MOVE
            ? canMove(state.data)
            : hasFormChanged(state.data, oldData || [])
      };
    }

    case DVB_MANAGEMENT_POPUP_SWITCH_MODE: {
      state.action = DVB_ACTIONS.UPDATE;

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_POPUP_TOGGLE: {
      if (!action.payload.isActive) {
        state.data.forEach((item: FieldItem) => {
          item.value = '';
        });
      }

      return {
        ...state,
        isActive: action.payload.isActive
      };
    }

    default: {
      return state;
    }
  }
}
