export const RF_TUNING_CHANGE_TAB = 'RF_TUNING_CHANGE_TAB';
export const RF_TUNING_CLEAR_TAB_CACHE = 'RF_TUNING_CLEAR_TAB_CACHE';
export interface RfTuningMainState {
  currentTab: string;
}

export interface RfTuningMainAction {
  payload: string;
  type: any;
}

const initialState: RfTuningMainState = {
  currentTab: 'full'
};

export default function (state = initialState, { payload, type }: RfTuningMainAction) {
  switch (type) {
    case RF_TUNING_CHANGE_TAB: {
      return { ...state, currentTab: payload };
    }

    case RF_TUNING_CLEAR_TAB_CACHE: {
      return { ...initialState };
    }

    default: {
      return { ...state };
    }
  }
}
