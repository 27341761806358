import React, { Component } from 'react';
import groupBy from 'ramda/es/groupBy';
import { ShortcutGroup } from 'modules/home/shortcut';
import authService from 'core/auth';
import { VipocGroup } from 'core/auth/constants';
import {
  World,
  Devices,
  List,
  Power,
  ListNumbered,
  Alexa,
  ChannelManagement,
  Anonymizer,
} from 'assets/svg';
import { ShortcutProps } from './shortcut/shortcut-component';

const shortcuts: Array<ShortcutProps> = [
  {
    title: 'home.icon_device',
    icon: <Devices className="icon-svg icon-large" />,
    link: '/operational/device',
    category: 'home.label_operational',
    group: 'devices'
  },
  {
    title: 'home.icon_id_mapping',
    icon: <List className="icon-svg icon-large" />,
    link: '/management/id-mapping',
    category: 'home.label_management',
    group: 'id-mapping'
  },

  {
    title: 'home.icon_ip_whitelist',
    icon: <World className="icon-svg icon-large" />,
    link: '/management/ip-geolocation',
    category: 'home.label_management',
    group: 'ip-geolocation'
  },

  {
    title: 'home.icon_onnet_offnet',
    icon: <Power className="icon-svg icon-large" />,
    link: '/management/on-net-off-net',
    category: 'home.label_management',
    group: 'on-net-off-net'
  },

  {
    title: 'home.icon_esn_mapping',
    icon: <ListNumbered className="icon-svg icon-large" />,
    link: '/management/esn-mapping',
    category: 'home.label_management',
    group: 'esn-mapping'
  },

  {
    title: 'home.icon_alexa',
    icon: <Alexa className="icon-svg icon-large" />,
    link: '/management/alexa-capability',
    category: 'home.label_management',
    group: 'alexa-capability'
  },

  {
    title: 'home.icon_spectrum',
    icon: <ChannelManagement className="icon-svg icon-large" />,
    link: '/management/spectrum/homepage',
    category: 'home.label_management',
    group: 'spectrum'
  },
  {
    title: 'home.icon_anonymizer',
    icon: <Anonymizer className="icon-svg icon-large" />,
    link: '/management/anonymizer',
    category: 'home.label_management',
    group: 'anonymizer'
  },
];

const filterByPermission = (shortcut: ShortcutProps) => {
  const user = authService.getCurrentUser();
  const currentOpco = authService.getCurrentOpco();

  return (
    !shortcut.group ||
    user.groups.filter(
      (group: VipocGroup) => group.moduleName === shortcut.group && group.opco === currentOpco
    ).length > 0
  );
};

const shortcutsByCategory = groupBy(
  (shortcut) => shortcut.category,
  shortcuts.filter(filterByPermission)
);

export default class HomeComponent extends Component {
  render() {
    const user = authService.getCurrentUser();

    if (Object.keys(shortcutsByCategory).length === 0) {
      authService.switchOpco(`${user.opcos[0]}`);
      return null;
    }

    return (
      <div className="has-margin-md">
        {Object.keys(shortcutsByCategory).map((category, key) => (
          <ShortcutGroup title={category} key={key} shortcuts={shortcutsByCategory[category]} />
        ))}
      </div>
    );
  }
}
