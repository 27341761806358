import { ApplicationState, initializeParameters } from 'application/application-redux';
import { fetchShadow } from '../general/redux/actions';
import { connect } from 'react-redux';
import Topbar from './topbar-component';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  deviceCustomFields: state.application.parameters.map,
  t: state.i18n.t,
  id: ownProps.id,
  general: state.devices.detail.general,
  applicationParameters: state.application.parameters.map,
});

export default connect(mapStateToProps, {
  fetchShadow,
  initializeParameters
})(Topbar);
