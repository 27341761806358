export const DEVICE_DETAIL_LOGS_HISTORY = 'DEVICE_DETAIL_LOGS_HISTORY';
export const DEVICE_DETAIL_LOGS_HISTORY_SUCCESS = 'DEVICE_DETAIL_LOGS_HISTORY_SUCCESS';
export const DEVICE_DETAIL_LOGS_HISTORY_FAILURE = 'DEVICE_DETAIL_LOGS_HISTORY_FAILURE';
export const DEVICE_DETAIL_LOGS_CHANGE_PAGE = 'DEVICE_DETAIL_LOGS_CHANGE_PAGE';
export const DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL = 'DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL';
export const DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS =
  'DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS';
export const DEVICE_DETAIL_LOGS_RESET_URL = 'DEVICE_DETAIL_LOGS_RESET_URL';
export const DEVICE_DETAIL_LOGS_CLEAR_CACHE = 'DEVICE_DETAIL_LOGS_CLEAR_CACHE';
export interface LogsItem {
  rowId: string;
  hhid?: string;
  udid?: string;
  filename?: string;
  yearFile?: number;
  monthFile?: number;
  dayFile?: number;
  date: string;
}

export interface LogsHistoryAction {
  type: string;
  payload?: any;
}

export interface LogsHistoryState {
  totalElements?: number;
  items?: LogsItem[];
  itemsToShow?: LogsItem[];
  downloadLink?: string;
  selectedItems?: number;
}
