import TableComponent from 'core/table/table-container';
import PaginationComponent from 'core/pagination-optimized/pagination-container';
import NoRecordsComponent from 'core/no-records/no-records-container';
import React, { Fragment, Component } from 'react';
import { ChevronDown } from 'assets/svg';
import DateUtils from 'core/utils/date-utils';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { paginationChannelScan } from 'core/pagination-optimized/redux/config';
import T from 'core/i18n/t';
import { TFunction } from 'i18next';
import TaskPanelComponent from 'core/job-panel';
import mapper, { labelsChannelScan } from './redux/mapper';
import { ChannelScanState, ChannelScanResult } from './redux/types';
import Topbar from '../topbar/topbar-container';

export interface ChannelComponentProps {
  fetch: (id: string, filter?: PaginationConfig) => void;
  toggleAccordion: (index: number) => void;
  hhid: string;
  id: string;
  results: ChannelScanState;
  startScan: any;
  t: TFunction;
  cancelCommand: any;
}

export default class ChannelComponent extends Component<ChannelComponentProps> {
  componentDidMount() {
    if (
      !this.props.results ||
      !this.props.results.results ||
      !this.props.results.results[0] ||
      !this.props.results.results[0].totalElements
    ) {
      this.props.fetch(this.props.id, paginationChannelScan);
    }
  }

  render() {
    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={this.props.id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical has-margin-bottom-lg">
          <div className="columns">
            <div className="column is-narrow has-padding-bottom-none">
              <button
                className="button is-primary"
                onClick={() => this.props.startScan(this.props.id, paginationChannelScan)}
                disabled={this.props.results.waitingCommandExecution}
              >
                <T>channels.tab_channel_scan</T>
              </button>
            </div>
          </div>

          {this.props.results.activityLog && this.props.results.activityLog.status !== 'SUCCEEDED' && (
            <ul className="panel has-margin-bottom-md">
              <TaskPanelComponent
                item={this.props.results.activityLog}
                showProgressBar
                cancel={this.props.cancelCommand}
                t={this.props.t}
              />
            </ul>
          )}

          <ul className="accordion">
            {this.props.results &&
            this.props.results.results &&
            this.props.results.results.length ? (
              this.props.results.results.map((item: ChannelScanResult, index: number) => (
                <li
                  className={`accordion-item ${
                    !item.accordionActive ? 'accordion-item-collapsed' : ''
                  }`}
                  key={index}
                >
                  <button
                    className="accordion-heading"
                    onClick={() => this.props.toggleAccordion(index)}
                  >
                    <span className="chevron">
                      <span className="chevron-text">{DateUtils.formatDate(item.finishedAt)}</span>
                      <span className="chevron-container accordion-chevron">
                        <ChevronDown className="icon icon-svg" />
                      </span>
                    </span>
                  </button>
                  <div className="accordion-content">
                    <TableComponent
                      id="channel-scan"
                      items={item.channelHistories && item.channelHistories.map(mapper)}
                      columns={labelsChannelScan(this.props.t)}
                    />
                  </div>
                </li>
              )))
            :
              <NoRecordsComponent />
            }
          </ul>

        </div>
      </Fragment>
    );
  }
}
