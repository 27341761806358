import React, { Component } from 'react';
import { IdMappingCollisionResult } from './redux/reducer';
import { Link } from 'react-router-dom';
import IdMappingCollisionGridComponent from './collision-mapping-grid-component';
import { TFunction } from 'i18next';

interface IdMappingCollisionComponentProps {
  execute: any;
  selectEntry: any;
  sendResolution: any;
  isFetching: boolean;
  results: IdMappingCollisionResult;
  id: any;
  pageNumber: number;
  clearPagination: any;
  closeModal: any;
  openModal: any;
  cancelIngest: any;
  t: TFunction;
}

export default class IdMappingCollisionComponent extends Component<
  IdMappingCollisionComponentProps
> {
  private confirmAndCancelIngest(event: any, jobUid: string) {
    event.preventDefault();
    const command = () => {
      this.props.closeModal();
      this.props.cancelIngest(jobUid);
    };

    this.props.openModal({
      title: 'modal.title.confirm_cancel_ingest',
      active: false,
      content: 'modal.content.confirm_cancel_ingest',
      execute: command
    });
  }
  componentDidMount() {
    this.props.execute(this.props.id, this.props.pageNumber);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="section">
        <nav className="level">
          <div className="level-left">
            <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
              Collisions in: <strong>{this.props.id}</strong>
              <p>
                <Link
                  style={{ fontSize: '13px', color: 'grey' }}
                  className="is-small"
                  to={{ pathname: `/management/id-mapping`, state: { title: 'Id Mapping' } }}
                >
                  <span className="icon">
                    <i className="mdi mdi-keyboard-return" />
                  </span>
                  {t('general.link_back')}
                </Link>
              </p>
            </h3>
          </div>
          <div className="level-right">
            <div className="control column-rounded is-3">
              <button
                className="button is-primary"
                onClick={() => this.props.sendResolution(this.props.id)}
                disabled={this.props.results.content.length === 0}
              >
                <span className="icon">
                  <i className="mdi mdi-playlist-check" />
                </span>
                {t('id_mapping.label_submit_changes')}
              </button>
              <button
                className="button is-black"
                style={{ marginLeft: 0 }}
                onClick={(event: any) => this.confirmAndCancelIngest(event, this.props.id)}
              >
                <span className="icon">
                  <i className="mdi mdi-cancel" />
                </span>
                <span>Cancel ingest</span>
              </button>
            </div>
          </div>
        </nav>
        <IdMappingCollisionGridComponent
          execute={this.props.execute}
          selectEntry={this.props.selectEntry}
          isFetching={this.props.isFetching}
          results={this.props.results}
          id={this.props.id}
          pageNumber={this.props.pageNumber}
          clearPagination={this.props.clearPagination}
          t={this.props.t}
        />
      </div>
    );
  }
}
