import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SpectrumRegionalizationComponent from './regionalization-component';
import { getChannels, getDownloadChannels } from '../channels/redux/redux-channels/action';
import { push } from 'connected-react-router';
import { getRegions } from '../../dvb-management/redux/redux-regions/action';
import {
  getChannelRegionalization,
  regionalizationAssociateChannel,
  regionalizationDisassociateChannel,
  putRegLCN,
 
} from './redux/action';
import { editField } from 'core/table/redux/actions';
import { getTuningData } from '../../dvb-management/redux/action';
import { setIsRegionalization } from '../../dvb-management/redux/redux-nodes/action';

const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t,
  regions: state.management.spectrum.dvbManagement.region,
  nodes: state.management.spectrum.dvbManagement.node,
  dvbManagement: state.management.spectrum.dvbManagement,
  channelsAssociation: state.management.spectrum.regionalizationChannelsAssociations,
  table: state.table
});
export default connect(mapStateToProps, {
  push,
  getChannels,
  getRegions,
  getChannelRegionalization,
  regionalizationDisassociateChannel,
  putRegLCN,
  regionalizationAssociateChannel,
  editField,
  getDownloadChannels,
  getTuningData,
  setIsRegionalization
  
 
})(SpectrumRegionalizationComponent);
