import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import CardComponent from 'core/card/card-component';
import { CardPaddings } from 'core/card/enum';
import { AssetValue, CommandParameter } from '../redux/types';
import { OverlayModalState } from 'core/modal/redux/types';
import SettingsCardComponent from '../common/settings-card/settings-card-container';
import './logs.scss'

interface LogsComponentProps {
  t: TFunction;
  id: string;
  settings: any;
  updateLogs: (newValue: string, field: string) => void;
  updateFlushLogs: (newValue: string, field: string) => void;
  runCommands: (id: string, command: CommandParameter) => void;
  resetLogs: () => void;
  resetFlushLogs: () => void;
  openModal: (options: OverlayModalState) => void;
  closeModal: () => void;
  componentId: string;
}

export default class LogsComponent extends Component<LogsComponentProps> {
  turnOnLogs = () => {
    const { settings, runCommands, id } = this.props;
    let logLevelsFields = settings.logs.fields[1].currentValue;

    settings.logs.fields[0].available.map((item: AssetValue) => {
      if (item.selected) {
        logLevelsFields += item.value;
      }
    });

    const command = {
      command: 'loggingEnable',
      key: 'set_logs',
      jsonParameters: { logLevel: logLevelsFields, window: settings.logs.fields[2].currentValue }
    };

    return runCommands(id, command);
  };

  turnOnFlushLogs = () => {
    const { settings, runCommands, id } = this.props;
    let logFlush = '';

    settings.logs.flush.available.map((item: AssetValue) => {
      if (item.selected) {
        logFlush += item.label;
      }
    });

    const command = {
      command: 'loggingFlush',
      key: 'set_logs',
      jsonParameters: { mode: logFlush }
    };

    return runCommands(id, command);
  };

  turnOffLogs = () => {
    const { runCommands, id } = this.props;

    const command = {
      command: 'loggingEnable',
      key: 'set_logs',
      jsonParameters: { logLevel: 0, window: 0 }
    };

    return runCommands(id, command);
  };

  confirmActionExecution(isTurnOn: boolean) {
    const { closeModal, openModal, resetLogs } = this.props;

    openModal({
      title: 'modal.title.confirm_action',
      active: false,
      content: 'modal.content.confirm_action',
      execute: () => {
        isTurnOn
          ? [closeModal(), this.turnOnLogs(), resetLogs()]
          : [closeModal(), this.turnOffLogs()];
      }
    });
  }

  confirmActionExecutionFlush() {
    const { closeModal, openModal, resetFlushLogs } = this.props;

    openModal({
      title: 'modal.title.confirm_action',
      active: false,
      content: 'modal.content.confirm_action',
      execute: () => [closeModal(), this.turnOnFlushLogs(), resetFlushLogs()]
    });
  }

  render() {
    const { settings, t, updateLogs, resetLogs, componentId, updateFlushLogs, resetFlushLogs } =
      this.props;

    const enableTurnOn =
      settings &&
      settings.logs &&
      settings.logs.fields &&
      (settings.logs.fields[0].currentValue !== settings.logs.fields[0].value ||
        settings.logs.fields[1].currentValue !== settings.logs.fields[1].value) &&
      settings.logs.fields[2].currentValue !== settings.logs.fields[2].value;

    const isEnableFlushActions =
      settings &&
      settings.logs &&
      settings.logs.flush.available &&
      (settings.logs.flush.available[0].selected ||
        settings.logs.flush.available[1].selected ||
        settings.logs.flush.available[2].selected);

    return (
      <Fragment>
        {componentId === 'manage_logs' ? (
          <>
            <div className="columns">
              <div className="column is-8">
                <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
                  <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
                    <div className="column is-12">
                      <h6 className="text is-h6 is-highlighted">
                        {t(`settings.label_log_levels`)}
                      </h6>
                    </div>
                    <div className="column is-12 has-padding-top-none has-padding-bottom-none">
                      <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
                    </div>
                    <div className="columns flex-wrap has-padding-md">
                      {settings &&
                        settings.logs &&
                        settings.logs.fields &&
                        settings.logs.fields[0].available.map((item: any, key: number) => (
                          <div key={key} className="column has-padding-top-lg is-4">
                            <div className="has-checkbox checkbox">
                              <input
                                className="is-small"
                                type="checkbox"
                                name={t(item.label)}
                                checked={item.selected}
                                onChange={(e) => updateLogs(item.value, item.label)}
                              />

                              <label>{t(item.label)}</label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </CardComponent>
              </div>
              <div className="column">
                <div className="columns flex-wrap">
                  <div className="column is-12">
                    <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
                      <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
                        <div className="column is-12">
                          <h6 className="text is-h6 is-highlighted">
                            {t(`advanced_search.select_ui`)}
                          </h6>
                        </div>
                        <div className="column is-12 has-padding-top-none has-padding-bottom-none">
                          <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
                        </div>
                      </div>

                      <div className="column has-padding-left-xs has-padding-right-xs">
                        <div className="select is-fullwidth">
                          <select
                            name={t('user_settings.label_operating_company')}
                            value={
                              settings && settings.logs && settings.logs.fields[1].currentValue
                            }
                            onChange={(e) =>
                              updateLogs(e.target.value, settings.logs.fields[1].label)
                            }
                          >
                            {settings &&
                              settings.logs &&
                              settings.logs.fields &&
                              settings.logs.fields[1].available.map(
                                (item: AssetValue, key: number) => (
                                  <option
                                    key={key}
                                    value={item.value}
                                    disabled={item.active === false}
                                  >
                                    {t(item.label)}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                  <div className="column is-12">
                    <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
                      <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
                        <div className="column is-12">
                          <h6 className="text is-h6 is-highlighted">{t(`settings.logs_window`)}</h6>
                        </div>
                        <div className="column is-12 has-padding-top-none has-padding-bottom-none">
                          <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
                        </div>
                      </div>

                      <div className="column has-padding-left-xs has-padding-right-xs">
                        <div className="select is-fullwidth">
                          <select
                            name={t('user_settings.label_operating_company')}
                            value={
                              settings && settings.logs && settings.logs.fields[2].currentValue
                            }
                            onChange={(e) =>
                              updateLogs(e.target.value, settings.logs.fields[2].label)
                            }
                          >
                            {settings &&
                              settings.logs &&
                              settings.logs.fields &&
                              settings.logs.fields[2].available.map(
                                (item: AssetValue, key: number) => (
                                  <option
                                    key={key}
                                    value={item.value}
                                    disabled={item.active === false}
                                  >
                                    {t(item.label)}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns flex-wrap is-justified-end is-vertical-center configurations-actions">
              <button
                className={`button-link dropdown-link has-padding-right-md ${
                  settings && settings.logs && !settings.logs.hasChanged ? '' : 'button-action'
                }`}
                onClick={() => resetLogs()}
                disabled={settings && settings.logs && !settings.logs.hasChanged}
              >
                {t(`general.clear`)}
              </button>

              <button
                className="button is-rounded is-larger is-dark"
                onClick={() => this.confirmActionExecution(false)}
                disabled={settings && settings.logs && settings.logs.hasChanged}
              >
                {t('settings.logs.button_turnoff')}
              </button>

              <button
                className="button is-rounded is-larger is-primary"
                onClick={() => this.confirmActionExecution(true)}
                disabled={!enableTurnOn}
              >
                {t('settings.logs.button_turnon')}
              </button>
            </div>
          </>
        ) : (
          <>
            <CardComponent padding={CardPaddings.MEDIUM}>
              <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
                <div className="column is-12">
                  <h6 className="text is-h6 is-highlighted">{t(`settings.label_flush_logs`)}</h6>
                </div>
                <div className="column is-12 has-padding-top-none has-padding-bottom-none">
                  <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
                </div>
                <div className="columns flex-wrap has-padding-md">
                  {settings &&
                    settings.logs &&
                    settings.logs.flush &&
                    settings.logs.flush.available.map((item: any, key: number) => (
                      <div key={key} className="column has-padding-top-lg is-4">
                        <div className="has-checkbox checkbox">
                          <input
                            className="is-small"
                            type="checkbox"
                            name={t(item.label)}
                            checked={item.selected}
                            onChange={(e) => updateFlushLogs(item.selected, item.label)}
                          />

                          <label className='label_input'>{t(`settings.column_value_` + item.label)}</label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </CardComponent>
            <div className="columns flex-wrap is-justified-end is-vertical-center configurations-actions">
              <button
                className={`button-link dropdown-link has-padding-right-md ${
                  !isEnableFlushActions ? '' : 'button-action'
                }`}
                onClick={() => resetFlushLogs()}
                disabled={!isEnableFlushActions}
              >
                {t(`general.clear`)}
              </button>
              <button
                className="button is-rounded is-larger is-primary"
                onClick={() => this.confirmActionExecutionFlush()}
                disabled={!isEnableFlushActions}
              >
                {t('settings.button_apply')}
                
              </button>
            </div>
          </>
        )}
      </Fragment>
    );
  }
}
