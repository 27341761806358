import { add, remove } from 'core/spinner/redux/actions';
import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';
import DateUtils from 'core/utils/date-utils';
import { fetch } from '../history/redux/action';
import {
  ADVANCED_SEARCH_FAILURE,
  ADVANCED_SEARCH_REQUEST,
  ADVANCED_SEARCH_SUCCESS,
  ADVANCED_SEARCH_CHANGE_PAGE,
  INITIALIZE_DEVICE_CUSTOM_FIELDS
} from './types';
import { envs } from 'application/envHandler';

export function loadDeviceCustomFields() {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(INITIALIZE_DEVICE_CUSTOM_FIELDS, ''));
    dispatch({
      type: INITIALIZE_DEVICE_CUSTOM_FIELDS,
      payload: getState().application.parameters.map
    });
    dispatch(remove(INITIALIZE_DEVICE_CUSTOM_FIELDS));
  };
}

function request() {
  return {
    type: ADVANCED_SEARCH_REQUEST
  };
}

function success(
  data: any,
  startItem: number,
  endItem: number,
  lastEvaluated: string,
  isToConcat: boolean
) {
  return {
    type: ADVANCED_SEARCH_SUCCESS,
    payload: {
      data,
      startItem,
      endItem,
      lastEvaluated,
      isToConcat
    }
  };
}

function failure(payload: any) {
  return {
    type: ADVANCED_SEARCH_FAILURE,
    payload
  };
}

export function onChangeAdvancedSearchPage(startItem: number, endItem: number) {
  return {
    type: ADVANCED_SEARCH_CHANGE_PAGE,
    payload: {
      startItem,
      endItem
    }
  };
}

function mapQueries(items: any) {
  let dateStamp = '';
  return items.map((query: any) => {
    if (query.name.endsWith('lastConnectionDate') && query.value.start && query.value.end) {
      dateStamp = `[ ${DateUtils.formatFromIotTimestamp(
        query.value.start,
        'X'
      )} TO ${DateUtils.formatFromIotTimestamp(query.value.end, 'X')} ]`;

      return `${query.name}:${dateStamp}`
    }

    return `${query.name}:${query.value.replace(':', '<colon>')}`;
  });
}

export function fetchAdvancedSearch(lastUdid: string, isToConcat: boolean, historyId?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const searchItem = getState().multipleSearch.multipleFilters.get('advanced.search');
    const newFilters = searchItem && searchItem.selectedFilters;

    if (newFilters && newFilters.length === 0) {
      return dispatch(failure('queries not defined'));
    }

    const mappedQueries = mapQueries(newFilters);
    getState().devices.advancedSearch.history.current = mappedQueries.join(' AND ');
    dispatch(request());
    dispatch(add('ADVANCED_SEARCH', {}));
    await AuthService.refreshToken();

    const queryUrl = `query=${mappedQueries.join(' AND ')}`.concat(
      historyId ? `&id=${historyId}` : `&lastEvaluatedUdid=${lastUdid}&limit=`
    );

    return apiClient.get(`${envs.REACT_APP_API_URL}/device-hologram?${queryUrl}`).then(
      (response) => {
        dispatch(remove('ADVANCED_SEARCH'));
        if (!isToConcat) {
          dispatch(goToFirstPage('advanced.search'));
        }
        const pagination = getState().pagination.pagination.get('advanced.search');
        const start = (pagination && pagination.pageSize * pagination.pageNumber) || 0;
        const end = (pagination && pagination.pageSize * (pagination.pageNumber + 1)) || 20;
        dispatch(fetch());
        return dispatch(
          success(response.data.devices, start, end, response.data.lastEvaluatedUdid, isToConcat)
        );
      },
      (rejection) => {
        dispatch(remove('ADVANCED_SEARCH'));
        dispatch(
          addToaster({
            title: 'all_device_pages.link_advanced_search',
            message: 'error_messages.VIPOC_ERROR_013',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}
