import React from 'react'
import { AnonymizerBodyInterface } from '../../types'
import HashesManagement from '../HashesManagement'
import ServicesManagement from '../ServicesManagement'

export default function AnonymizerBody({ hash, service, deleteService, updateService, fetchHashByAid, fetchHashById, deleteHash }: AnonymizerBodyInterface) {
  return (
    <>
      <ServicesManagement service={service} updateService={updateService} deleteService={deleteService}/>
      <HashesManagement hash={hash} service={service} deleteHash={deleteHash} fetchHashByAId={fetchHashByAid} fetchHashById={fetchHashById}/>
    </>
  )
}
