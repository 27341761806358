import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import DVBManagementSearchComponent from './dvb-management-search-component';
import { action } from '../redux/action';
import { onClearFilters, onSearch, setColumn } from '../search/redux/action';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  search: ownProps.search,
  dvbManagement: ownProps.dvbManagement,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  action,
  onClearFilters,
  onSearch,
  setColumn
})(DVBManagementSearchComponent);
