import { connect } from 'react-redux';
import TabComponent from './tab-component';
import { getSubMenus } from 'application/header/navbar/redux/actions';
import { ApplicationState } from 'application/application-redux';

const mapStateToProps = (state: ApplicationState) => ({
  navbar: state.navbar.navbar
});

export default connect(mapStateToProps, { getSubMenus })(TabComponent);
