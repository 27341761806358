export default (key: string): string => {
  switch (key) {
    case "shadow.reported.account.HHID": {
      return "deviceShadow.reported.account.HHID";
    }
    case "shadow.reported.hardware.brn": {
      return "deviceShadow.reported.hardware.brn";
    }
    case "shadow.reported.hardware.mod": {
      return "deviceShadow.reported.hardware.mod";
    }
    case "shadow.reported.hardware.SN": {
      return "deviceShadow.reported.hardware.SN";
    }
    case "shadow.reported.status.connected": {
      return "deviceShadow.reported.status.connected";
    }
    case "shadow.reported.status.lastConnectionDate": {
      return "deviceShadow.reported.status.lastConnectionDate";
    }
    case "shadow.reported.account.regID": {
      return "deviceShadow.reported.account.regID";
    }
    default: {
      return key;
    }
  }
};
