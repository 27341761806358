import { equals } from 'ramda';
import { TFunction } from 'i18next';
import {
  DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW,
  DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW,
  DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE,
  DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS,
  DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE,
  DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS,
  DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER,
  DEVICE_DETAIL_SETTINGS_EXECUTION_REQUEST,
  DEVICE_DETAIL_SETTINGS_EXECUTION_FAILURE,
  DEVICE_DETAIL_SETTINGS_EXECUTION_SUCCESS,
  DeviceDetailSettingsState,
  DeviceDetailSettingsAction,
  DeviceDetailSettingParameter,
  DEVICE_DETAIL_SETTINGS_SELECT_ACTION,
  DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR,
  DEVICE_DETAIL_SETTINGS_CLEAR_PENDING_EXECUTIONS,
  DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS,
  DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION,
  DEVICE_DETAIL_SETTINGS_TRANSLATE,
  SETTINGS_VIP_CLEAR_CACHE,
  SETTINGS_SHADOW_CLEAR_CACHE,
  DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_ACTION,
  DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_WINDOW_ACTION,
  DEVICE_DETAIL_SETTINGS_ACTION_LOGS_REQUEST,
  DEVICE_DETAIL_SETTINGS_CLEAR_LOGS_ACTION,
  DEVICE_DETAIL_SETTINGS_LOGS_ASSETS_VALUES,
  KeyValues,
  DeviceShadow,
  LogsAsset
} from './types';
import deviceShadowMapper from './utils/device-shadow-mapper';
import deviceVipMapper from './utils/device-vip-mapper';
import {
  doPartialResetParameters,
  doFactoryResetParameters,
  rebootParameters,
  setPowerSavingModeParameters
} from './utils/models';

const initialState: DeviceDetailSettingsState = {
  logs: {
    logLevelFields: [
      { label: 'settings.logs_dvb', value: 128, selected: false },
      { label: 'settings.logs_ccl', value: 64, selected: false },
      { label: 'settings.tab_hdmi', value: 32, selected: false },
      { label: 'settings.logs_player', value: 16, selected: false },
      { label: 'settings.logs_gp', value: 8, selected: false },
      { label: 'settings.logs_mw', value: 4, selected: false },
      {
        label: 'overview.label_ui',
        value: []
      }
    ],
    window: {
      label: 'settings.logs_window',
      value: []
    },
    hasChanged: false,
    hasChangedParameters: false
  },
  executions: new Map(),
  tabs: {
    firstRow: {
      current: 'audioAndSubtitles'
    },
    secondRow: {
      current: 'accessibility'
    }
  },

  // TODO commented in VGSVTVVIPOC-428
  // {
  //  label: 'Update Firmware',
  //  value: 'updateToSpecificFirmware',
  //  parameters: updateToSpecificFirmwareParameters
  // }

  actions: {
    available: []
  },
  hasChanged: false
};

function initializeActions(t: TFunction, shadow?: DeviceShadow) {
  return [
    {
      label: t('settings.label_partial_reset'),
      value: 'doPartialReset',
      parameters: doPartialResetParameters(t)
    },
    {
      label: t('settings.label_factory_reset'),
      value: 'doFactoryReset',
      parameters: doFactoryResetParameters(t)
    },
    {
      label: t('all_device_pages.button_reboot'),
      value: 'reboot',
      parameters: rebootParameters(t)
    },
    {
      label: t('command.label.set_power_saving_mode'),
      value: 'setPowerSavingMode',
      parameters: setPowerSavingModeParameters(t, shadow)
    }
  ];
}

function handleParentalPinValidations(payloadValue: string) {
  let value: string = '';

  for (let i = 0; i < payloadValue.length; i++) {
    if (payloadValue[i] >= '0' && payloadValue[i] <= '9') {
      value += payloadValue[i];

      if (value.length > 4) {
        value = value.substr(0, 4);
      }
    }
  }
  return value;
}

function hasChangedParameters(newData: KeyValues[], oldData: KeyValues[]): boolean {
  if (newData && newData.length && oldData && oldData.length) {
    for (let i = 0; i < newData.length; i++) {
      if (Array.isArray(newData[i].value)) {
        return hasChangedParameters(
          newData[i].value as KeyValues[],
          oldData[i].value as KeyValues[]
        );
      } else if (newData[i].selected !== oldData[i].selected) {
        return true;
      }
    }
    return false;
  }
  return false;
}

export default function (state = initialState, { type, payload }: DeviceDetailSettingsAction) {
  switch (type) {
    case DEVICE_DETAIL_SETTINGS_TRANSLATE: {
      const { t } = payload;

      return {
        ...state,
        actions: {
          available: initializeActions(t, state.shadow)
        }
      };
    }

    case DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW:
      return {
        ...state,
        tabs: { ...state.tabs, firstRow: { current: payload } }
      };

    case DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW:
      return { ...state, tabs: { ...state.tabs, secondRow: { current: payload } } };

    case SETTINGS_SHADOW_CLEAR_CACHE:
    case DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE:
    case DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS: {
      const shadowParameters = deviceShadowMapper(
        payload.t,
        payload.data.deviceShadow,
        payload.assetsValues
      );
      const parameters = new Map<string, DeviceDetailSettingParameter>();
      if (state.parameters) {
        state.parameters.forEach((value: DeviceDetailSettingParameter, key: string) => {
          parameters.set(key, value);
        });
      }
      if (shadowParameters) {
        shadowParameters.forEach((value: DeviceDetailSettingParameter, key: string) => {
          parameters.set(key, value);
        });
      }
      return {
        ...state,
        parameters,
        shadow: payload.data.deviceShadow
      };
    }

    case SETTINGS_VIP_CLEAR_CACHE:
    case DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE:
    case DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS: {
      const vipParameters = deviceVipMapper(payload, false, payload.t, payload.shadow);
      const vipParametersPrevious = deviceVipMapper(
        payload.previousValues,
        true,
        payload.t,
        payload.shadow
      );

      const parameters = new Map<string, DeviceDetailSettingParameter>();

      if (state.parameters) {
        state.parameters.forEach((value: DeviceDetailSettingParameter, key: string) => {
          parameters.set(key, value);
        });
      }

      if (vipParameters) {
        vipParameters.forEach((value: DeviceDetailSettingParameter, key: string) => {
          parameters.set(key, value);
        });
      }
      return { ...state, parameters, previousParameters: vipParametersPrevious };
    }

    case DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION: {
      const { selected } = state.actions;
      if (selected && selected.parameters) {
        const parameter = selected.parameters.get(payload.key);
        parameter.value = payload.value;
        selected.parameters.set(payload.key, parameter);

        return {
          ...state,
          actions: {
            ...state.actions,
            available: initializeActions(payload.t, state.shadow),
            selected: { ...selected, parameters: selected.parameters }
          }
        };
      }
      return { ...state };
    }

    case DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER: {
      if (state.parameters) {
        const { key } = payload;

        const oldParameters = state.oldParameters || state.parameters;
        const newParameters = new Map<string, DeviceDetailSettingParameter>(
          JSON.parse(JSON.stringify(Array.from(state.parameters)))
        );

        const parameter = newParameters.get(key);
        if (parameter) {
          if (payload.option.type === 'multiSelect') {
            const newValue = [];
            for (let i = 0, l = payload.targetEventOptions.length; i < l; i++) {
              if (payload.targetEventOptions[i].selected) {
                newValue.push(payload.targetEventOptions[i].value);
              }
            }
            parameter.value = newValue;
          } else if (key === 'parentalPinValue') {
            parameter.value = handleParentalPinValidations(payload.value);
          } else {
            parameter.value = payload.value;
          }

          newParameters.set(key, parameter);

          return {
            ...state,
            oldParameters,
            parameters: newParameters,
            hasChanged: !equals(oldParameters, newParameters)
          };
        }
      }

      return { ...state };
    }
    case DEVICE_DETAIL_SETTINGS_SELECT_ACTION: {
      return {
        ...state,
        actions: {
          ...state.actions,
          available: initializeActions(payload.t, state.shadow),
          selected: payload
        }
      };
    }

    case DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR: {
      return {
        ...state,
        oldParameters: undefined,
        shadow: undefined,
        parameters: state.oldParameters
          ? new Map<string, DeviceDetailSettingParameter>(
              JSON.parse(JSON.stringify(Array.from(state.oldParameters)))
            )
          : undefined
      };
    }

    case DEVICE_DETAIL_SETTINGS_CLEAR_PENDING_EXECUTIONS: {
      state.logs = {
        logLevelFields: initialState.logs.logLevelFields,
        window: initialState.logs.window,
        hasChanged: false,
        hasChangedParameters: false
      };

      return {
        ...state,
        hasChanged: false,
        actions: {
          ...initialState.actions,
          available: initializeActions(payload.t)
        },
        executions: new Map()
      };
    }

    case DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS: {
      return {
        ...state,
        oldParameters: undefined,
        shadow: undefined,
        parameters: state.parameters
          ? new Map<string, DeviceDetailSettingParameter>(
              JSON.parse(JSON.stringify(Array.from(state.parameters)))
            )
          : state.oldParameters
          ? new Map<string, DeviceDetailSettingParameter>(
              JSON.parse(JSON.stringify(Array.from(state.oldParameters)))
            )
          : undefined
      };
    }
    case DEVICE_DETAIL_SETTINGS_EXECUTION_REQUEST: {
      let currentMap = state.executions;
      currentMap = currentMap.set(payload, 'pending');
      return { ...state, executions: currentMap };
    }

    case DEVICE_DETAIL_SETTINGS_EXECUTION_FAILURE: {
      let currentMap = state.executions;
      currentMap = currentMap.set(payload, 'failure');
      return { ...state, executions: currentMap };
    }

    case DEVICE_DETAIL_SETTINGS_EXECUTION_SUCCESS: {
      let currentMap = state.executions;
      currentMap = currentMap.set(payload, 'success');
      return { ...state, executions: currentMap };
    }

    case DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_ACTION: {
      let cloneLogLevel = JSON.parse(JSON.stringify(state.logs.logLevelFields));
      const field = cloneLogLevel.find((item: KeyValues) => item.value == payload.value);
      const selectedWindow =
        Array.isArray(state.logs.window.value) &&
        state.logs.window.value.find((element) => element.selected);

      if (field) {
        field.selected = !field.selected;
      }

      return {
        ...state,
        logs: {
          ...state.logs,
          logLevelFields: cloneLogLevel,
          hasChanged:
            hasChangedParameters(cloneLogLevel, state.oldlogs?.logLevelFields || []) &&
            hasChangedParameters(
              state.logs.window.value as KeyValues[],
              state.oldlogs?.window.value as KeyValues[]
            ) &&
            selectedWindow &&
            selectedWindow.value !== 0,
          hasChangedParameters:
            hasChangedParameters(cloneLogLevel, state.oldlogs?.logLevelFields || []) ||
            hasChangedParameters(
              state.logs.window.value as KeyValues[],
              state.oldlogs?.window.value as KeyValues[]
            )
        }
      };
    }

    case DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_WINDOW_ACTION: {
      const { value, dropdown } = payload;
      const logLevelFields =
        dropdown === 'overview.label_ui'
          ? onSelectValue(value, dropdown, state.logs.logLevelFields)
          : state.logs.logLevelFields;
      const windowFields =
        dropdown !== 'overview.label_ui'
          ? onSelectValue(value, dropdown, state.logs.window)
          : state.logs.window;
      const selectedWindow =
        Array.isArray(windowFields.value) &&
        windowFields.value.find((element: any) => element.selected);

      return {
        ...state,
        logs: {
          ...state.logs,
          hasChanged:
            hasChangedParameters(
              windowFields.value as KeyValues[],
              state.oldlogs?.window.value as KeyValues[]
            ) &&
            hasChangedParameters(logLevelFields, state.oldlogs?.logLevelFields || []) &&
            selectedWindow &&
            selectedWindow.value !== 0,
          hasChangedParameters:
            hasChangedParameters(
              windowFields.value as KeyValues[],
              state.oldlogs?.window.value as KeyValues[]
            ) || hasChangedParameters(logLevelFields, state.oldlogs?.logLevelFields || []),
          logLevelFields,
          window: windowFields
        }
      };
    }

    case DEVICE_DETAIL_SETTINGS_ACTION_LOGS_REQUEST: {
      return {
        ...state
      };
    }

    case DEVICE_DETAIL_SETTINGS_LOGS_ASSETS_VALUES:
      const assetValues = payload.assetsValues;
      const ui = assetValues.filter((asset: LogsAsset) => asset.assetName === 'lumberjack_ui');
      const window = assetValues.filter(
        (asset: LogsAsset) => asset.assetName === 'lumberjack_window'
      );

      const uiValues = assetsToKeyValues(ui);
      const windowValues = assetsToKeyValues(window);

      if (state.logs.logLevelFields) {
        state.logs.logLevelFields.forEach((element) => {
          if (Array.isArray(element.value)) {
            element.value = uiValues.keyValuesArray;
          }
        });
      }

      state.logs = {
        logLevelFields: initialState.logs.logLevelFields,
        window: {
          label: initialState.logs.window.label,
          value: windowValues.keyValuesArray
        },
        hasChanged: false,
        hasChangedParameters: false
      };

      const oldlogs = JSON.parse(JSON.stringify(state.logs));

      return {
        ...state,
        oldlogs: oldlogs
      };

    case DEVICE_DETAIL_SETTINGS_CLEAR_LOGS_ACTION: {
      if (state.oldlogs) {
        state.logs = {
          logLevelFields: JSON.parse(JSON.stringify(state.oldlogs.logLevelFields)),
          window: JSON.parse(JSON.stringify(state.oldlogs.window)),
          hasChanged: false,
          hasChangedParameters: false
        };
      }

      return {
        ...state
      };
    }

    default:
      return { ...state };
  }
}

function assetsToKeyValues(assets: LogsAsset[]) {
  const keyValuesArray: KeyValues[] = [];

  assets.forEach((item) =>
    keyValuesArray.push({ label: item.label, value: Number(item.value), selected: false })
  );

  keyValuesArray[0].selected = true;

  return { keyValuesArray };
}

function onSelectValue(value: number, dropdown: string, keyValues?: any) {
  let fields = keyValues && JSON.parse(JSON.stringify(keyValues));
  let field = undefined;

  if (fields) {
    if (dropdown === 'overview.label_ui') {
      field = fields.find((item: KeyValues) => item.label === dropdown);
    } else {
      field = fields;
    }

    if (Array.isArray(field.value)) {
      field.value.forEach((item: KeyValues) => {
        item.selected = false;
        if (item.value == value) {
          item.selected = true;
        }
      });
    }
  }

  return fields;
}
