import RequestsMapper from './mapper';
import {
  RequestsAction,
  RequestsMainState,
  RequestStatus,
  REQUESTS_GET_CHANGES,
  REQUESTS_GET_REQUESTS,
  REQUESTS_SET_REQUEST,
  REQUESTS_SET_REQUEST_INITIAL,
  REQUESTS_SET_SCHEDULE_DATE
} from './types';

const initialState: RequestsMainState = {
  pagination: {
    pageSize: 10,
    pageNumber: 0
  },
  totalCount: 0,
  index: '',
  requests: undefined,
  selectedRequest: undefined,
  listIndex:{0: ""}
};

const statusMapper = (request: any) => {
  switch (request.requestStatus) {
    case 'draft':
      return 0;
    case 'pending':
      return 1;
    case 'approved':
      if (request.schedulingDate) {
        return 2;
      }
    default:
      return 3;
  }
};

const dateMapper = (request: any) => {
  if (statusMapper(request) === 3) {
    return new Date(request.modifiedAt).getTime();
  }
  return new Date(request.schedulingDate || request.modifiedAt).getTime();
};

export default function(state = initialState, action: RequestsAction) {
  switch (action.type) {
    case REQUESTS_GET_CHANGES: {
      const changes = action.payload.changes;
      const user = action.payload.user;
      const others = action.payload.others;
      const requestStatus = user.requestStatus || RequestStatus.DRAFT;
      return {
        ...state,
        selectedRequest: {
          ...state.selectedRequest,
          canEditOtherEntities: true,
          otherChanges: others,
          userChanges: user && user.changes || changes,
          requestId: user.requestId,
          requestStatus: requestStatus || RequestStatus.DRAFT,
          isRequestOpen:
            user.isRequestOpen ||
            requestStatus === RequestStatus.DRAFT ||
            requestStatus === RequestStatus.PENDING,
          isRequestPending: user.isRequestPending || requestStatus === RequestStatus.PENDING,
          details: changes != undefined ? true : false
        }
      };
    }

    case REQUESTS_GET_REQUESTS: {
      const data = action.payload.data;
      const totalCount = action.payload.totalCount;
      const index = action.payload.index;
      const pageNumber=action.payload.pageNumber;
      return {
        ...state,
        listIndex: action.payload && action.payload.isSearch 
        ? {0: "", [pageNumber + 1] : index}
        : {...state.listIndex, [pageNumber + 1] : index},
        requests:
          action.payload && action.payload.data
            ? action.payload.data
                .sort((a: any, b: any) => dateMapper(b) - dateMapper(a))
                .sort((a: any, b: any) => statusMapper(a) - statusMapper(b))
            : [],
        totalCount: totalCount,
        index: index,
       
         pagination:
         {
          ...state.pagination,
          pageNumber:pageNumber}
          

      };
    }

    case REQUESTS_SET_REQUEST: {
      const data = action.payload.data;
      let canEditOtherEntities =
        state.selectedRequest?.canEditOtherEntities !== undefined
          ? state.selectedRequest?.canEditOtherEntities
          : true;
      canEditOtherEntities =
        data?.canEditOtherEntities !== undefined ? data.canEditOtherEntities : canEditOtherEntities;
      return {
        ...state,
        selectedRequest: {
          ...state.selectedRequest,
          canEditOtherEntities,
          userChanges: data?.changes && RequestsMapper.formatChanges(data?.changes),
          results: data?.results && RequestsMapper.formatResults(data?.results),
          requestId: data?.requestId,
          requestStatus: data?.requestStatus,
          isRequestOpen:
            data.isRequestOpen ||
            data.requestStatus === RequestStatus.DRAFT ||
            data.requestStatus === RequestStatus.PENDING,
          isRequestPending: data.isRequestPending || data.requestStatus === RequestStatus.PENDING,
          details: true
        }
      };
    }

    case REQUESTS_SET_REQUEST_INITIAL: {
      return { ...initialState };
    }

    case REQUESTS_SET_SCHEDULE_DATE: {
      return {
        ...state,
        selectedRequest: {
          ...state.selectedRequest,
          scheduleDate: action.payload.scheduleDate
        }
      };
    }
    default: {
      return state;
    }
  }
}
