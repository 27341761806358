import {
  PaginationAction,
  PaginationState,
  FIRST_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  LAST_PAGE,
  CHANGE_PAGE_SIZE,
  RESET_PAGE_NUMBER,
  ORDER_ITEMS,
  CHANGE_FIELD_ORDER,
  TO_PAGE
} from './types';
import {
  paginationProvDevices,
  paginationSpeedTest,
  paginationSubscriptions,
  paginationChannelScan,
  paginationDVR,
  paginationVOD,
  paginationActivityLog,
  paginationLogs,
  paginationChannels,
  paginationChannelsAssociationsRegions,
  paginationChannelsAssociationsNodes,
  paginationRequests,
  regionalizationDisassociate,
  regionalizationAssociate,
  paginationReprocessDetails,
  paginationAdvancedSearch,
  paginationAccordionDvr
} from './config';

const initialPagination = new Map();
initialPagination.set('account.provisioned_devices', paginationProvDevices);
initialPagination.set('account.subscriptions', paginationSubscriptions);
initialPagination.set('channel_scan', paginationChannelScan);
initialPagination.set('frequency_scan', paginationChannelScan);
initialPagination.set('dvr', paginationDVR);
initialPagination.set('vod', paginationVOD);
initialPagination.set('activity_log', paginationActivityLog);
initialPagination.set('speed_test', paginationSpeedTest);
initialPagination.set('spectrum.channels', paginationChannels);
initialPagination.set('spectrum.regions', paginationChannelsAssociationsRegions);
initialPagination.set('spectrum.nodes', paginationChannelsAssociationsNodes);
initialPagination.set('spectrum.regionalization.disassociate', regionalizationDisassociate);
initialPagination.set('spectrum.regionalization.associate', regionalizationAssociate);
initialPagination.set('spectrum.requests', paginationRequests);
initialPagination.set('logs', paginationLogs);
initialPagination.set('reprocess.details', paginationReprocessDetails);
initialPagination.set('channels', paginationChannels);
initialPagination.set('advanced.search', paginationAdvancedSearch);
initialPagination.set('accordion.dvr', paginationAccordionDvr);

const initialState: PaginationState = {
  pagination: initialPagination
};

function getPagination(state: PaginationState, id: string) {
  return state.pagination.get(id);
}

export default function (state = initialState, action: PaginationAction) {
  switch (action.type) {
    case FIRST_PAGE:
      let paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        paginationItem.pageNumber = 0;
        paginationItem.doubleArrows = false;
      }

      return {
        ...state
      };
    case NEXT_PAGE:
      paginationItem = getPagination(state, action.payload.id);
      if (paginationItem) {
        paginationItem.pageNumber++;
        paginationItem.doubleArrows = false;
      }

      return {
        ...state
      };
    case PREVIOUS_PAGE:
      paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        paginationItem.pageNumber--;
        paginationItem.doubleArrows = false;
      }
      return {
        ...state
      };

    case LAST_PAGE:
      paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        paginationItem.pageSave = paginationItem.pageNumber;
        paginationItem.pageNumber = action.payload.lastPage;
        paginationItem.doubleArrows = true;
      }
      return {
        ...state
      };

    case TO_PAGE:
      paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        paginationItem.pageNumber = action.payload.page;
        paginationItem.doubleArrows = false;
      }
      return {
        ...state
      };

    case CHANGE_PAGE_SIZE:
      paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        paginationItem.pageSize = action.payload.newSize;
        paginationItem.pageNumber = 0;
        paginationItem.doubleArrows = false;
      }
      return {
        ...state
      };

    case RESET_PAGE_NUMBER:
      state.pagination.forEach((item) => {
        item.pageNumber = 0;
        item.doubleArrows = false;
      });
      return {
        ...state
      };
    case CHANGE_FIELD_ORDER:
      paginationItem = getPagination(state, action.payload.id);
      const sortField = action.payload.sortField;
      const sortOrder = action.payload.sortOrder;
      if (paginationItem) {
        paginationItem.sortField = sortField;
        paginationItem.sortOrder = sortOrder;
      }
      return {
        ...state
      };

    case ORDER_ITEMS:
      paginationItem = getPagination(state, action.payload.id);

      if (paginationItem) {
        if (paginationItem.sortField === action.payload.field) {
          paginationItem.sortOrder = paginationItem.sortOrder === 'DESC' ? 'ASC' : 'DESC';
        } else {
          paginationItem.sortField = action.payload.field;
          paginationItem.sortOrder = 'DESC';
        }
      }
      return { ...state };

    default:
      return { ...state };
  }
}
