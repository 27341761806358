import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import Topbar from '../topbar/topbar-container';
import TableComponent from 'core/table/table-container';
import mapper, { labelsLogs } from './redux/mapper';
import { LogsHistoryState } from './redux/types';
import { paginationLogs } from 'core/pagination-optimized/redux/config';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { Select, TableState } from 'core/table/redux/types';
import PaginationComponent from 'core/pagination-optimized/pagination-container';

interface LogsHistoryProps {
  id: string;
  logsHistory: LogsHistoryState;
  table: TableState;
  t: TFunction;
  fetchLogsHistory: (id?: string, filter?: PaginationConfig) => void;
  onChangeLogsHistoryPage: (startItem: number, endItem: number) => void;
  generateURL: (id?: string) => void;
  resetURL: () => void;
  resetTableState: () => void;
}

export default class LogsHistoryComponent extends Component<LogsHistoryProps> {
  callbackFunction = (childData: any) => {
    const { resetURL, logsHistory } = this.props;
    if (childData !== logsHistory.selectedItems) {
      resetURL();
    }
  };

  render() {
    const {
      id,
      logsHistory,
      table,
      t,
      fetchLogsHistory,
      onChangeLogsHistoryPage,
      generateURL,
      resetTableState,
      resetURL
    } = this.props;

    const tableSelected = table.items.get('logs');

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>

          <div className="column is-narrow has-padding-right-none">
            <button
              className="button is-rounded is-larger"
              disabled={
                (tableSelected && !tableSelected.hasSelectedItem) ||
                logsHistory.downloadLink !== undefined
              }
              onClick={() => generateURL(id)}
            >
              {t('logs.button_enable_download')}
            </button>

            <button
              className="button is-rounded is-larger"
              disabled={logsHistory.downloadLink === undefined}
              onClick={e => {
                e.preventDefault();
                window.location.href = '' + logsHistory.downloadLink;
                resetTableState();
                resetURL();
              }}
            >
              {t('general.download')}
            </button>
          </div>
        </div>

        <div className="tile is-parent is-vertical width-full">
          <TableComponent
            id="logs"
            hasSlider={false}
            items={
              logsHistory && logsHistory.itemsToShow
                ? logsHistory.itemsToShow.map(item => {
                    return mapper(t, item);
                  })
                : []
            }
            selectData={{ field: 'rowId', select: Select.MULTIPLE, selectOne: false }}
            onSearch={(filter: any) => fetchLogsHistory(id, filter)}
            title={`${t('all_device_pages.menu_logs')}`}
            columns={labelsLogs(t)}
            parentCallback={this.callbackFunction}
          />

          <div className="tile is-parent is-vertical has-margin-bottom-lg has-margin-right-md">
            <PaginationComponent
              id={'logs'}
              pageSize={paginationLogs.pageSize}
              totalElements={logsHistory.totalElements!}
              onChange={() =>
                onChangeLogsHistoryPage(
                  paginationLogs.pageSize * paginationLogs.pageNumber,
                  paginationLogs.pageSize * (paginationLogs.pageNumber + 1)
                )
              }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
