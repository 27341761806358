import { connect } from 'react-redux';
import EsnModalMappingComponent from './esn-modal-mapping-component';
import { startRefresh, stopRefresh } from '../grid/redux/action';
import { close, uploadFile, setFileValue } from './redux/actions';

const mapStateToProps = (state: any) => ({
  isActive: state.management.esnMapping.modal.active,
  title: state.management.esnMapping.modal.title,
  content: state.management.esnMapping.modal.content,
  execute: state.management.esnMapping.modal.execute,
  disabledUpload: state.management.esnMapping.modal.disabledUpload,
  dataUpload: state.management.esnMapping.modal.dataUpload,
  fileNameToUpload: state.management.esnMapping.modal.fileNameToUpload,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  close,
  uploadFile,
  setFileValue,
  startRefresh,
  stopRefresh
})(EsnModalMappingComponent);
