import { TFunction } from 'i18next';
import { LogsItem as LogsHistoryItem } from './types';

export const labelsLogs = (t: TFunction) => ({
  filename: t('logs.column_file'),
  dayFile: t('logs.column_file_date')
});

export default (t: TFunction, data: LogsHistoryItem): LogsHistoryItem => {
  return {
    rowId: data.rowId ? data.rowId : '',
    filename: data.filename ? data.filename.split('/')[10] : '',
    date:
      data.dayFile && data.monthFile && data.yearFile
        ? data.dayFile + '-' + data.monthFile + '-' + data.yearFile
        : '-'
  };
};
