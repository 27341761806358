import React, { Component, Fragment, PureComponent } from 'react';
import T from 'core/i18n/t';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { DeviceHardDisk, DeviceHardDiskThresholdItem } from './redux/types';
import './device-hard-disk-styles.scss';
import { GeneralDeviceState } from '../general/redux/types';
import mapper from './redux/mapper';
import Topbar from '../topbar/topbar-container';
import Icon from '@mdi/react';
import { mdiCircle } from '@mdi/js';
import { hardDiskFields, defaultColor, successColor, dangerColor, iconSize } from './constants'

export function getFreeSpace(used: any, total: any) {
  if (total && used && !isNaN(total)) {
    if (isNaN(used)) {
      return '-';
    }
    return parseFloat((total - used).toString()).toFixed(2);
  }
  return '-';
}

interface DeviceDetailHardDiskProps {
  hardDisk: DeviceHardDisk;
  general: GeneralDeviceState;
  hhid: string;
  id: string;
  t: TFunction;
  getThreshhold: (callback: any) => void;
}

const DeviceDetailHardDiskComponent: React.FunctionComponent<DeviceDetailHardDiskProps> = (
  props
) => {
  const { general, id, t, hardDisk } = props;

  const [threshold, setThreshold] = React.useState<Array<DeviceHardDiskThresholdItem>>([]);

  React.useEffect(() => {
    if (
      props.hardDisk &&
      props.hardDisk.errorsThresholdInfo &&
      props.hardDisk.errorsThresholdInfo.threshold &&
      props.hardDisk.errorsThresholdInfo.threshold.length === 0 ||
      props.hardDisk &&
      props.hardDisk.errorsThresholdInfo === undefined
    )
      props.getThreshhold((response: any) => {
        setThreshold(response);
      });
  }, [JSON.stringify(props.general.shadow)]);

  React.useEffect(() => {
    if (
      props.hardDisk &&
      props.hardDisk.errorsThresholdInfo &&
      props.hardDisk.errorsThresholdInfo.threshold
    )
      setThreshold(props.hardDisk.errorsThresholdInfo.threshold);
  }, [
    JSON.stringify(
      props.hardDisk &&
        props.hardDisk.errorsThresholdInfo &&
        props.hardDisk.errorsThresholdInfo.threshold &&
        props.hardDisk.errorsThresholdInfo.threshold
    )
  ]);

  return (
    <Fragment>
      <div className="columns width-full">
        <div className="column">
          <Topbar id={id}></Topbar>
        </div>
      </div>

      <div className="tile is-parent is-vertical has-margin-bottom-lg">
        <div className="tile is-parent box">
          <InfoComponent
            hologram={general && mapper(null, t('general.not_available'), general, t, threshold)}
            t={t}
            thresh={threshold}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DeviceDetailHardDiskComponent;

interface InfoProps {
  t: TFunction;
  hologram: any;
  thresh: any;
}

class InfoComponent extends PureComponent<InfoProps> {
  render() {
    const { t, hologram, thresh } = this.props;
    return (
      <div className="tile is-child">
        <HardDiskUserQuotaInfo
          title={`${t('hard_disk.title_user_quota')}`}
          {...(hologram && hologram.quota)}
        />
        <HardDiskPropertiesInfo
          title={`${this.props.t('hard_disk.subtitle_properties')}`}
          {...(hologram && hologram.identifiers)}
        />
        <HardDiskStatusInfo
          title={`${t('hard_disk.subtitle_status')}`}
          hologram={hologram}
          thresh={thresh}
          {...hologram}
        />
        <HardDiskQuotaInfo
          title={`${t('hard_disk.subtitle_quota')}`}
          {...(hologram && hologram.quota)}
        />
      </div>
    );
  }
}

const HardDiskUserQuotaInfo = ({ title, used, typeUsed, total, typeTotal }: any) => (
  <ul>
    <p className="title is-6 is-marginless">{title}</p>
    <li>
      <nav className="level">
        <div className="level-item has-text-centered">
          <div>
            <p className="title">
              {used}
              <span className="is-size-7">{typeUsed}</span>
            </p>
            <p className="heading is-capitalized has-text-weight-bold subtitle-user-quota">
              <T>hard_disk.label_used</T>
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="title">
              {getFreeSpace(used, total)}
              {getFreeSpace(used, total) == '-' ? (
                ''
              ) : (
                <span className="is-size-7">{typeTotal}</span>
              )}
            </p>
            <p className="heading is-capitalized has-text-weight-bold subtitle-user-quota">
              <T>hard_disk.label_free</T>
            </p>
          </div>
        </div>
      </nav>
    </li>
  </ul>
);

const HardDiskPropertiesInfoComponent = ({ title, t, serialNo, model }: any) => (
  <ul>
    <li className="title is-6 is-marginless">{title}</li>
    <li>
      <DeviceProperty name={`${t('hard_disk.label_serial_number')}:`} value={serialNo} />
    </li>
    <li>
      <DeviceProperty name={`${t('hard_disk.label_model_number')}:`} value={model} />
    </li>
  </ul>
);

const HardDiskPropertiesInfo = connect((state: any) => ({
  t: state.i18n.t
}))(HardDiskPropertiesInfoComponent);

const getColorIcons = (thresh: any, currentItem: any, errorsThresholdId: any) => {
  if (thresh.length > 0) {
    const itemData = errorsThresholdId[currentItem];
    const ThrestholdItem = thresh && thresh.find((smart: any) => smart.id === itemData.id);
    if (itemData && Object.keys(ThrestholdItem || {}).length > 0) {
      return Number(itemData.raw) > Number(ThrestholdItem.thresh) ? successColor : dangerColor
    } else {
      return defaultColor;
    }
  }
};

const HardDiskStatusInfoComponent = ({
  title,
  t,
  status,
  errors,
  thresh,
  errorsThresholdId
}: any) => (
  <ul style={{ marginTop: '2em' }}>
    <li className="title is-6 is-marginless">{title}</li>
    <li>
      <DeviceProperty
        name={`${t('hard_disk.subtitle_status')}:`}
        value={status.status === 'Invalid' ? t('general.not_available') : status.status}
      />
    </li>
    <li>
      <DeviceProperty name={`${t('hard_disk.label_temperature')}:`} value={status.temperature} />
    </li>
    {hardDiskFields.map(({field, label}) => (
      <li>
        <div className="level device-property" style={{ marginTop: '0.4em' }}>
          <div className="level-left" style={{ width: '50%' }}>
            <T>{label}</T>
            <>:</>
          </div>
          <Icon path={mdiCircle} size={iconSize} color={getColorIcons(thresh, field, errorsThresholdId)}/>
          <div className="level-item level-left" style={{ marginLeft: '3px' }}>
            {errors[field]}
          </div>
        </div>
      </li>
    ))}
  </ul>
);

const HardDiskStatusInfo = connect((state: any, thresh: any) => ({
  t: state.i18n.t,
  thresh: thresh.thresh
}))(HardDiskStatusInfoComponent);

const HardDiskQuotaInfoComponent = ({ title, used, typeUsed, total, typeTotal, t }: any) => (
  <ul style={{ marginTop: '2em' }}>
    <li className="title is-6 is-marginless">{title}</li>
    <li>
      <DeviceProperty
        name={`${t('hard_disk.label_quota_usage')}:`}
        value={
          used && total && !isNaN(used) && !isNaN(total)
            ? `${parseFloat(((used / total) * 100).toString()).toFixed(
                2
              )}%  (${used} ${typeUsed}/ ${total} ${typeTotal})`
            : t('general.not_available')
        }
      />
    </li>
  </ul>
);

const HardDiskQuotaInfo = connect((state: any) => ({
  t: state.i18n.t
}))(HardDiskQuotaInfoComponent);

const DeviceProperty = ({ name, value }: any) => (
  <div className="level device-property" style={{ marginTop: '0.4em' }}>
    <div className="level-left" style={{ width: '50%' }}>
      {name}
    </div>
    <div className="level-item level-left">{value}</div>
  </div>
);
