import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SideMenuComponent from './side-menu-component';
import { toggleCategory, toggleSubCategory } from './redux/action';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  sideMenu: state.sideMenu,
  t: state.i18n.t
});

export default connect(mapStateToProps, { toggleCategory, toggleSubCategory })(SideMenuComponent);
