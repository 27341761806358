export const ADD_TOASTER = 'ADD_TOASTER';
export const ADD_TOASTER_TIMEOUT = 'ADD_TOASTER_TIMEOUT';
export const ADD_TOOLTIP = 'ADD_TOOLTIP';
export const DEACTIVATE_TOASTER = 'DEACTIVATE_TOASTER';
export const REMOVE_TOASTER = 'REMOVE_TOASTER';

export interface ToasterMessage {
  message: string;
  title?: string;
  type: 'danger' | 'info' | 'success' | 'warning';
}

export interface ToasterObject {
  toaster: ToasterMessage;
  isActive: boolean;
  hasTimeout: boolean;
}

export interface ToasterState {
  toasters: Array<ToasterObject>;
  tooltip?: ToasterMessage;
}

export interface ToasterAction {
  type:
    | typeof ADD_TOASTER
    | typeof ADD_TOASTER_TIMEOUT
    | typeof ADD_TOOLTIP
    | typeof DEACTIVATE_TOASTER
    | typeof REMOVE_TOASTER;
  payload: any;
}
