import { connect } from 'react-redux';
import { hasPendingTasksOf } from 'core/indicator/pending-tasks-redux';
import IdMappingGridComponent from './id-mapping-grid-component';
import { ID_MAPPING_REQUEST } from './redux/reducer';
import { FILE_UPLOAD_ID_MAPPING_REQUEST } from '../modal-upload/redux/types';

const mapStateToProps = (state: any) => ({
  t: state.i18n.t,
  results: state.management.idMapping.grid.results,
  isFetching:
    hasPendingTasksOf(FILE_UPLOAD_ID_MAPPING_REQUEST, state.pendingTasks) ||
    hasPendingTasksOf(ID_MAPPING_REQUEST, state.pendingTasks),
  filled: state.management.idMapping.form.filled
});

export default connect(mapStateToProps)(IdMappingGridComponent);
