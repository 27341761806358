export const DEVICE_DETAIL_HARD_DISK_REQUEST = 'DEVICE_DETAIL_HARD_DISK_REQUEST';
export const DEVICE_DETAIL_HARD_DISK_SUCCESS = 'DEVICE_DETAIL_HARD_DISK_SUCCESS';
export const DEVICE_DETAIL_HARD_DISK_FAILURE = 'DEVICE_DETAIL_HARD_DISK_FAILURE';
export const HARD_DISK_CLEAR_CACHE = 'HARD_DISK_CLEAR_CACHE';

export type DEVICE_DETAIL_HARD_DISK_REQUEST = typeof DEVICE_DETAIL_HARD_DISK_REQUEST;
export type DEVICE_DETAIL_HARD_DISK_SUCCESS = typeof DEVICE_DETAIL_HARD_DISK_SUCCESS;
export type DEVICE_DETAIL_HARD_DISK_FAILURE = typeof DEVICE_DETAIL_HARD_DISK_FAILURE;
export type HARD_DISK_CLEAR_CACHE = typeof HARD_DISK_CLEAR_CACHE;

export interface DeviceDetailHardDiskAction {
  type:
    | DEVICE_DETAIL_HARD_DISK_REQUEST
    | DEVICE_DETAIL_HARD_DISK_SUCCESS
    | DEVICE_DETAIL_HARD_DISK_FAILURE
    | HARD_DISK_CLEAR_CACHE;
  payload?: any;
}

export interface DeviceHardDiskIdentifiers {
  serialNo: string;
  model: string;
  brand: string;
}

export interface DeviceHardDiskStatus {
  status: string;
  temperature: string;
}

export interface DeviceHardDiskQuota {
  total: any;
  used: any;
  typeTotal: any;
  typeUsed: any;
}

export interface DeviceHardDiskState {
  current?: DeviceHardDisk;
}

export interface DeviceHardDiskThresholdItem {
  atributeName: string;
  id: string;
  thresh: string;
}

export interface DeviceHardDiskThreshold {
  threshold: Array<DeviceHardDiskThresholdItem>;
}

export interface DeviceHardDisk {
  brand: string;
  identifiers: DeviceHardDiskIdentifiers;
  status: DeviceHardDiskStatus;
  quota: DeviceHardDiskQuota;
  errorsThresholdInfo: DeviceHardDiskThreshold;
}
