import {
  TABLE_ASSIGN_ALL_ROWS,
  TABLE_GET_SELECTED_ROW_INDEX,
  TABLE_GET_SELECTED_ROWS,
  TABLE_CLEAR_CACHE,
  TABLE_RESET_AFTER_DOWNLOAD,
  TABLE_EDIT_FIELD,
  TABLE_UPDATE_SORT,
  Sort,
  TABLE_ORDER_OWN_ITEMS
} from './types';

export function selectedRowIndex(table: string, field: string, index?: any) {
  return {
    type: TABLE_GET_SELECTED_ROWS,
    payload: {
      table,
      index,
      field
    }
  };
}

export function selectOneRow(table: string, index: any) {
  return {
    type: TABLE_GET_SELECTED_ROW_INDEX,
    payload: {
      table,
      index
    }
  };
}

export function assignTableRows(table: string, data: any, id: string, field?: string) {

  return {
    type: TABLE_ASSIGN_ALL_ROWS,
    payload: {
      table,
      data,
      id,
      field
    }
  };
}

export function clearTableCache(table?: string) {
  return {
    type: TABLE_CLEAR_CACHE,
    payload: {
      table
    }
  };
}

export function resetTableState() {
  return {
    type: TABLE_RESET_AFTER_DOWNLOAD
  };
}

export function editField(table?: string, data?: any, id?: number) {
  return {
    type: TABLE_EDIT_FIELD,
    payload: {
      table,
      data,
      id
    }
  };
}

export function updateSort(table: string, sort: Sort) {
  return {
    type: TABLE_UPDATE_SORT,
    payload: {
      table,
      sort
    }
  }
}
