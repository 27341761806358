import {
  GENERAL_BUCKET_SUCCESS,
  DeviceDetailOverviewAction,
  GeneralDeviceState,
  GENERAL_SHADOW_SUCCESS,
  GENERAL_VIP_SUCCESS,
  GENERAL_VIP_FAILURE,
  GENERAL_ID_MAPPING_SUCCESS,
  GENERAL_ID_MAPPING_FAILURE,
  GENERAL_CLEAR_CACHE
} from './types';

const initialState: GeneralDeviceState = {
  bucket: undefined,
  idMapping: undefined,
  shadow: undefined,
  vip: undefined
};

export default function(state = initialState, action: DeviceDetailOverviewAction) {
  switch (action.type) {
    case GENERAL_CLEAR_CACHE:
      return {
        ...state,
        bucket: undefined,
        idMapping: undefined,
        shadow: undefined,
        vip: undefined
      };
    case GENERAL_BUCKET_SUCCESS:
      return {
        ...state,
        bucket: action.payload.bucket
      };
    case GENERAL_SHADOW_SUCCESS:
      return {
        ...state,
        shadow: action.payload.shadow
      };
    case GENERAL_VIP_FAILURE:
    case GENERAL_VIP_SUCCESS:
      return {
        ...state,
        vip: action.payload
      };
    case GENERAL_ID_MAPPING_SUCCESS:
    case GENERAL_ID_MAPPING_FAILURE:
      return {
        ...state,
        idMapping: action.payload
      };
    default:
      return state;
  }
}
