import {
  SpeedTestState,
  SpeedTestAction,
  SPEED_TEST_REQUEST,
  SPEED_TEST_SUCCESS,
  SPEED_TEST_FAILURE,
  SPEED_TEST_CLEAR_CACHE,
  SPEED_TEST_WAITING_COMMAND,
  SPEED_TEST_HISTORY_SUCCESS,
  SPEED_TEST_HISTORY_FAILURE,
  SPEED_TEST_STATUS
} from './types';

export const initialState: SpeedTestState = {
  results: [
    {
      pingTimeMS: '',
      downloadBitrateAVG: '',
      finishedAt: '',
      totalElements: 0
    }
  ],
  download: '0',
  latency: '0',
  waitingCommandExecution: false,
  activityLog: null
};

export default function(state = initialState, { type, payload }: SpeedTestAction) {
  switch (type) {
    case SPEED_TEST_WAITING_COMMAND: {
      return { ...state, waitingCommandExecution: payload };
    }
    case SPEED_TEST_CLEAR_CACHE:
      return initialState;

    case SPEED_TEST_SUCCESS: {
      return {
        ...state,
        results: state.results,
        download:
          payload.response.message && payload.response.message.downloadBitrateAVG
            ? payload.response.message.downloadBitrateAVG
            : '0',
        latency:
          payload.response.message && payload.response.message.pingTimeMS
            ? payload.response.message.pingTimeMS
            : '0',
        activityLog: {
          udid: payload.deviceId,
          status: payload.status,
          eventName: payload.name,
          lastManaged: payload.startDate,
          commandTraceId: payload.transactionID,
          logResponse: payload.response.message,
          logRequest: payload.request.message,
          progress: payload.request.progress ? payload.request.progress.replace('%', '') : null,
          error: payload.response.errorText
        }
      };
    }

    case SPEED_TEST_HISTORY_SUCCESS:
      return { ...state, results: payload };

    case SPEED_TEST_REQUEST:
    case SPEED_TEST_HISTORY_FAILURE:
    case SPEED_TEST_FAILURE:
    default:
      return {
        ...state
      };
  }
}
