import { DVBItemChanged, DVBItemToShow } from '../types';

export const DVB_MANAGEMENT_SERVICE_PASTE = 'DVB_MANAGEMENT_SERVICE_PASTE';
export const DVB_MANAGEMENT_SERVICE_SELECT = 'DVB_MANAGEMENT_SERVICE_SELECT';
export const DVB_MANAGEMENT_SERVICES_SET = 'DVB_MANAGEMENT_SERVICES_SET';

export interface DVBManagementServiceAction {
  type:
    | typeof DVB_MANAGEMENT_SERVICE_PASTE
    | typeof DVB_MANAGEMENT_SERVICE_SELECT
    | typeof DVB_MANAGEMENT_SERVICES_SET;
  payload: any;
}

export interface DVBService extends DVBItemToShow, DVBItemChanged {
  serviceId: string;
  serviceName: string;
  serviceType: string;
  scramble: string;
  mediaId: string;
  eitPresFlwFlag: string;
  lcn: string;
  lcn2: string;
}

export interface DVBManagementService {
  items: Array<DVBService>;
  itemsChanged?: Array<DVBService>;
  itemsSearch?: Array<DVBService>;
  selected?: DVBService;
  copied?: DVBService;
}
