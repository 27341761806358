import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import { execute as fetch } from '../../grid/redux/action';
import {
  OVERLAY_MODAL_IPGEO_CLOSE,
  OVERLAY_MODAL_IPGEO_OPEN,
  FILE_UPLOAD_IP_GEOLOCATION_FAILURE,
  FILE_UPLOAD_IP_GEOLOCATION_REQUEST,
  FILE_UPLOAD_IP_GEOLOCATION_SUCCESS,
  SET_FILE_TO_UPLOAD
} from './types';
import { envs } from 'application/envHandler';

export function setFileValue(data: FormData) {
  return {
    type: SET_FILE_TO_UPLOAD,
    payload: data
  };
}

export const open = (options: { title?: string; content?: string; execute?: any }) => ({
  type: OVERLAY_MODAL_IPGEO_OPEN,
  payload: options
});

export const close = () => ({
  type: OVERLAY_MODAL_IPGEO_CLOSE
});

function request() {
  return {
    type: FILE_UPLOAD_IP_GEOLOCATION_REQUEST
  };
}

function success(payload: any) {
  return {
    type: FILE_UPLOAD_IP_GEOLOCATION_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: FILE_UPLOAD_IP_GEOLOCATION_FAILURE,
    payload
  };
}

export function uploadFile(data: FormData) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch(request());
    dispatch(add('FILE_UPLOAD_IP_GEOLOCATION_REQUEST', {}));
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    try {
      const response = await apiClient.post(
        `${envs.REACT_APP_API_URL}/ip-geolocation/whitelist/upload`,
        data,
        config
      );

      await dispatch(success(response.data));
      await dispatch(fetch());
      await dispatch(remove('FILE_UPLOAD_IP_GEOLOCATION_REQUEST'));
    } catch (rejection) {
      console.error(rejection);

      const r = rejection as any;
 
      if (
        r &&
        r.response &&
        r.response.status === 422 &&
        r.response.data &&
        r.response.data.error
      ) {
        dispatch(
          addToaster({
            title: 'management.ip_geolocation',
            message: `${getState().i18n.t('error_messages.VIPOC_ERROR_010')} -  ${
              r.response.data.error.message
            }`,
            type: 'danger'
          })
        );
      } else {
        dispatch(
          addToaster({
            title: 'management.ip_geolocation',
            message: 'error_messages.VIPOC_ERROR_011',
            type: 'danger'
          })
        );
      }

      dispatch(remove('FILE_UPLOAD_IP_GEOLOCATION_REQUEST'));

      dispatch(fetch());
      return dispatch(failure('Failure'));
    }
  };
}
