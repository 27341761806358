import React, { Component } from 'react';
import { TFunction } from 'i18next';
import { UploadModalState } from '../modal-upload/redux/types';
import ModalUploadEsnMappingContainer from '../modal-upload';

interface EsnMappingFormProps {
  startRefresh: any;
  stopRefresh: any;
  open: (options: UploadModalState) => void;
  close: any;
  t: TFunction;
}

export default class EsnMappingFormComponent extends Component<EsnMappingFormProps> {
  openFileUpload() {
    const { stopRefresh, close, startRefresh, open } = this.props;
    stopRefresh();
    const onExecuteCommand = () => {
      close();
      startRefresh();
    };
    open({
      title: 'esn_mapping.button_upload_file',
      active: true,
      execute: onExecuteCommand,
      disabledUpload: true,
      dataUpload: new FormData(),
      fileNameToUpload: ''
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="field has-addons">
          <div className="control is-3">
            <button
              className="button is-black"
              style={{ width: '10em' }}
              onClick={() => this.openFileUpload()}
            >
              {t('esn_mapping.button_upload_file')}
              <span className="icon has-margin-left-sm">
                <i className="mdi mdi-cloud-upload" />
              </span>
            </button>
          </div>
        </div>
        <ModalUploadEsnMappingContainer />
      </div>
    );
  }
}
