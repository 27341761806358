export const SIDE_MENU_TOGGLE_CATEGORY = 'SIDE_MENU_SET_CATEGORY_ACTIVE';
export const SIDE_MENU_TOGGLE_SUBCATEGORY = 'SIDE_MENU_TOGGLE_SUBCATEGORY';
export const SIDE_MENU_CLEAR_CACHE = 'SIDE_MENU_CLEAR_CACHE';
export interface SideMenuAction {
  type: string;
  payload: any;
}

export interface SideMenuState {
  sideMenu: Map<string, SideMenu>;
}

export interface SideMenu {
  categories: Category[];
}

export interface Category {
  name: String;
  isActive: Boolean;
  subCategories?: SubCategory[];
}

export interface SubCategory {
  name: String;
  isActive: Boolean;
}
