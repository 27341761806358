import { DVBPopUp, FieldItem } from '../popUp/redux/types';
import { DVBSearch } from '../search/redux/types';
import { DVB_ACTIONS, DVB_COLUMNS } from './enums';
import { DVBManagementNetwork } from './redux-networks/types';
import { DVBManagementNode } from './redux-nodes/types';
import { DVBManagementRegion, DVBRegion } from './redux-regions/types';
import { DVBManagementService } from './redux-services/types';
import { DVBManagementStream } from './redux-streams/types';

export const DVB_MANAGEMENT_CONSTANTS = 'DVB_MANAGEMENT_CONSTANTS';
export const DVB_MANAGEMENT_COPY = 'DVB_MANAGEMENT_COPY';
export const DVB_MANAGEMENT_CHANGES_DELETE = 'DVB_MANAGEMENT_CHANGES_DELETE';
export const DVB_MANAGEMENT_CHANGES_SAVE = 'DVB_MANAGEMENT_CHANGES_SAVE';
export const DVB_MANAGEMENT_LOADING_TOGGLE = 'DVB_MANAGEMENT_LOADING_TOGGLE';
export const DVB_MANAGEMENT_REQUEST_SUBMIT = 'DVB_MANAGEMENT_REQUEST_SUBMIT';
export const DVB_MANAGEMENT_SAVE_CHANGE = 'DVB_MANAGEMENT_SAVE_CHANGE';
export const DVB_MANAGEMENT_TUNING_DATA = 'DVB_MANAGEMENT_TUNING_DATA';

export interface DVBManagementAction {
  type:
    | typeof DVB_MANAGEMENT_CONSTANTS
    | typeof DVB_MANAGEMENT_COPY
    | typeof DVB_MANAGEMENT_CHANGES_DELETE
    | typeof DVB_MANAGEMENT_CHANGES_SAVE
    | typeof DVB_MANAGEMENT_LOADING_TOGGLE
    | typeof DVB_MANAGEMENT_REQUEST_SUBMIT
    | typeof DVB_MANAGEMENT_SAVE_CHANGE
    | typeof DVB_MANAGEMENT_TUNING_DATA;
  payload?: any;
}

export interface DVBItemToShow {
  label?: string;
  id?: string;
  rootId?: string;
  children?: any;
}

export interface DVBItemChanged {
  canEditChange?: boolean;
  canDeleteChange?: boolean;
  changeId?: string;
  changeLabel?: string;
}

export interface DVBConstant {
  assetName: string;
  label: string;
  value: string;
}

export interface Field {
  column: string;
  fields: Array<FieldItem>;
}

export interface DVBChange {
  changeId?: string;
  requestId?: string;
  action: DVB_ACTIONS;
  type: DVB_COLUMNS;
  user?: string;
  from: {
    regionId?: string;
    nodeId?: string;
    onId?: string;
    tsId?: string;
    serviceId?: string;
  };
  to: {
    regionId?: string;
    nodeId?: string;
    onId?: string;
    tsId?: string;
    serviceId?: string;
  };
  old: DVBRegion;
  new: DVBRegion;
  error?: string;
}

export interface DVBManagementGeneralState {
  constants?: {
    mod: Array<DVBConstant>;
    dvbType: Array<DVBConstant>;
    eitPresFlwFlag: Array<DVBConstant>;
    scramble: Array<DVBConstant>;
  };
  isLoading: {
    region: boolean;
    network: boolean;
    node: boolean
  };
  copied?: DVBRegion;
}

export interface DVBManagemenMainState {
  general: DVBManagementGeneralState;
  popUp: DVBPopUp;
  search: DVBSearch;
}

export interface DVBManagementState {
  main: DVBManagemenMainState;
  region: DVBManagementRegion;
  node: DVBManagementNode;
  network: DVBManagementNetwork;
  stream: DVBManagementStream;
  service: DVBManagementService;
}
