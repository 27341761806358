export const CHANNEL_GET = 'CHANNEL_GET';
export const KALTURA_GET = 'KALTURA_GET';
export const CHANNEL_GET_FAILURE = 'CHANNEL_GET_FAILURE';
export const CHANNEL_GET_SUCCESS = 'CHANNEL_GET_SUCCESS';
export const CHANNEL_SELECT = 'CHANNEL_SELECT';
export const CHANNEL_ASSOCIATIONS_NODES_ADD_LCN = 'CHANNEL_ASSOCIATIONS_NODES_ADD_LCN';
export enum ChannelsType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export interface Channels {
  channelId: number;
  mediaId: number;
  number: number;
  name: string;
  resolution: string;
  epgId: number;
  deviceType: number;
  level: Array<string>;
}

export interface ChannelsState {
  isActive: boolean;
  isFetching: boolean;
  totalCount: number;
  channelActive?: Channels;
  channels: Channels[];
}

export interface ChannelsAction {
  payload: any;
  type:
    | typeof CHANNEL_GET
    | typeof CHANNEL_GET_FAILURE
    | typeof CHANNEL_GET_SUCCESS
    | typeof CHANNEL_SELECT
    | typeof CHANNEL_ASSOCIATIONS_NODES_ADD_LCN
    | typeof KALTURA_GET;
}
