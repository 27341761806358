export const OVERVIEW_USER_CONVERSION_REQUEST = 'OVERVIEW_USER_CONVERSION_REQUEST';
export const OVERVIEW_USER_CONVERSION_SUCCESS = 'OVERVIEW_USER_CONVERSION_SUCCESS';
export const OVERVIEW_USER_CONVERSION_FAILURE = 'OVERVIEW_USER_CONVERSION_FAILURE';
export const OVERVIEW_CLEAR_CACHE = 'OVERVIEW_CLEAR_CACHE';
export const DEVICE_DETAIL_CONVERSION = 'DEVICE_DETAIL_CONVERSION';

export type OVERVIEW_USER_CONVERSION_REQUEST = typeof OVERVIEW_USER_CONVERSION_REQUEST;
export type OVERVIEW_USER_CONVERSION_SUCCESS = typeof OVERVIEW_USER_CONVERSION_SUCCESS;
export type OVERVIEW_USER_CONVERSION_FAILURE = typeof OVERVIEW_USER_CONVERSION_FAILURE;
export type OVERVIEW_CLEAR_CACHE = typeof OVERVIEW_CLEAR_CACHE;
export type DEVICE_DETAIL_CONVERSION = typeof DEVICE_DETAIL_CONVERSION;

export interface DeviceDetailOverviewAction {
  type:
    | OVERVIEW_USER_CONVERSION_REQUEST
    | OVERVIEW_USER_CONVERSION_SUCCESS
    | OVERVIEW_USER_CONVERSION_FAILURE
    | OVERVIEW_CLEAR_CACHE;
  payload?: any;
}

export interface DeviceOverviewIdentifiers {
  serialNo: string;
  model: string;
  brand: string;
  bootT: string;
}

export interface DeviceOverviewState {
  current?: DeviceOverview;
}

export interface DeviceImage {
  bucketName: string;
  fileName: string;
  createdDate: string;
  file: string;
}
export interface VipDeviceHDMI {
  edidVersion: string;
  eisaId: string;
  manufactureDate: string;
  manufacturerName: string;
  productCode: string;
  productModel: string;
  serialNumber: string;
  validHeader: string;
}
export interface VipDeviceDetail {
  amuse: string;
  asuiver: string;
  audds: string;
  auout: string;
  brnme: string;
  cal: string;
  cgmac: string;
  cpulv: string;
  cputp: string;
  device_dt: string;
  device_id: string;
  device_ts: string;
  dlang: string;
  dmfre: string;
  dmuse: string;
  dttch: string;
  dttci: string;
  dvbc: string;
  dvsd: string;
  entt: string;
  envrm: string;
  epgv: string;
  event_ts: string;
  eventgroup_id: string;
  eventtype_id: string;
  hash: string;
  hdcp: string;
  hddpnr: string;
  hddsn: string;
  hddst: string;
  hddtp: string;
  hddts: string;
  hddus: string;
  hdmic: string;
  hdmid: VipDeviceHDMI;
  hdmiMode: string;
  hdmir: string;
  household_id: string;
  hrres: string;
  hwv: string;
  ipmsk: string;
  ledbr: string;
  lockch: string;
  macet: string;
  mcast: string;
  mn: string;
  netcg: string;
  ngrs: string;
  ntype: string;
  opco_id: string;
  opcoid: string;
  pin: string;
  powsm: string;
  prdns: string;
  prvip: string;
  pvsd: string;
  rpair: string;
  scdns: string;
  sn: string;
  ssid: string;
  stbmd: string;
  subst: string;
  swupd: string;
  swv: string;
  ua: string;
  user_id: string;
  vltd: string;
  wfchn: string;
  wffrq: string;
  wfmac: string;
  wfsig: string;
}

export interface DeviceOverview {
  connected: boolean;
  lastConnection: string;
  connectedFor: string;
  connectionType: string;
  connectedDevices: string;
  image: string;
  identifiers: DeviceOverviewIdentifiers;
  imageList?: Array<DeviceImage>;
  deviceDetail?: any;
  location?: any;
  accountProperties: {
    hhid: string;
    region_id: string;
    customer_type: string;
  };

  softwareProperties: {
    version: string;
    ui: string;
    rcctrlversion: string;
  };

  securityProperties: {
    caId: string;
    caName: string;
    caVersion: string;
  };

  userConversion?: {
    channels: {
      current: string;
      previous: string;
    };
  };
}

export interface CommandState {
  last: any;
}

export const COMMAND_EXECUTION_REQUEST = 'COMMAND_EXECUTION_REQUEST';
export const COMMAND_EXECUTION_SUCCESS = 'COMMAND_EXECUTION_SUCCESS';
export const COMMAND_EXECUTION_FAILURE = 'COMMAND_EXECUTION_FAILURE';

export interface CommandAction {
  type:
    | typeof COMMAND_EXECUTION_REQUEST
    | typeof COMMAND_EXECUTION_FAILURE
    | typeof COMMAND_EXECUTION_SUCCESS;
  payload?: any;
}
