export const regionsColumns = [
  { name: 'spectrum.regions.column_region_id', width: '15%', sort: true, field: 'regionId' },
  { name: 'spectrum.regions.column_region_label', width: '35%', sort: true, field: 'regionLabel' },
  { name: 'spectrum.regions.column_parent_region_id', width: '30%', sort: true, field: 'parentRegionId' },
  { name: 'spectrum.regions.column_regional_lcn', width: 'auto', sort: true, field: 'regionalLCN'}
];


export const nodesColumns = [
  { name: 'id', hidden: true, field: 'id' },
  { name: 'spectrum.nodes.column_node_id', width: '15%', sort: true, field: 'nodeId' },
  { name: 'spectrum.nodes.column_node_label', width: '35%', sort: true, field: 'nodeLabel' },
  { name: 'spectrum.nodes.column_child_region_id', width: '30%', sort: true, field: 'childRegionId' },
  { name: 'spectrum.nodes.column_regional_lcn', width: 'auto', sort: true, field: 'regLCN'}
];

// Disassociated channels ------------------------------------------------------------------------------------------

export const disassociatedRegionsColumns = [
  { name: 'spectrum.regions.column_region_id', width: '15%', sort: true, field: 'regionId' },
  { name: 'spectrum.regions.column_region_label', width: '35%', sort: true, field: 'regionLabel' },
  { name: 'spectrum.regions.column_parent_region_id', width: '30%', sort: true, field: 'parentRegionId' },
  { name: 'spectrum.regions.column_regional_lcn', width: 'auto' }
];

export const disassociatedNodesColumns = [
  { name: 'id', hidden: true, field: 'id' },
  { name: 'spectrum.nodes.column_node_id', width: '15%', sort: true, field: 'nodeId' },
  { name: 'spectrum.nodes.column_node_label', width: '35%', sort: true, field: 'nodeLabel' },
  { name: 'spectrum.nodes.column_child_region_id', width: '30%', sort: true, field: 'childRegionId' },
  { name: 'spectrum.nodes.column_regional_lcn', width: 'auto' }
];
