import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import ProgressBarComponent from './progress-bar-component';
import { handleProgress, handleClose, handleOpen } from './redux/action';

const mapStateToProps = (state: ApplicationState) => ({
  progressBar: state.progressBar,
  channels: state.management.spectrum.channel
});

export default connect(mapStateToProps, { handleProgress, handleClose, handleOpen })(
  ProgressBarComponent
);
