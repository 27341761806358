import { TFunction } from 'i18next';
import { SpeedTestItem } from './types';
import DateUtils from 'core/utils/date-utils';

export default (data: any): SpeedTestItem => {
  return {
    pingTimeMS: data ? data.pingTimeMS : '',
    downloadBitrateAVG: data ? data.downloadBitrateAVG : '',
    finishedAt: data && data.finishedAt ? DateUtils.formatDate(data.finishedAt) : ''
  };
};

export const getLabels = (t: TFunction): SpeedTestItem => ({
  pingTimeMS: t('speed_test.column_ping_time_ms'),
  downloadBitrateAVG: t('speed_test.column_download_bitrate_avg'),
  finishedAt: t('speed_test.column_date')
});
