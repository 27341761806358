import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SearchComponent from './search-component';
import {
  onChangeCurrentFilterValue,
  onChangeCurrentComparator,
  onSelectFilter,
  toggleMoreFilters,
  updateDays,
  translateFilter,
} from './redux/action';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  onSearch: ownProps.onSearch,
  search: state.search,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  onChangeCurrentFilterValue,
  onChangeCurrentComparator,
  onSelectFilter,
  toggleMoreFilters,
  updateDays,
  translateFilter,
  goToFirstPage,
})(SearchComponent);
