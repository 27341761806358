import { CardSizes } from 'core/card/enum';
import { DVB_ACTIONS, DVB_COLUMNS, DVB_FIELD_TYPES } from '../../redux/enums';

export const DVB_MANAGEMENT_POPUP = 'DVB_MANAGEMENT_POPUP';
export const DVB_MANAGEMENT_POPUP_MOVE = 'DVB_MANAGEMENT_POPUP_MOVE';
export const DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN = 'DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN';
export const DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS = 'DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS';
export const DVB_MANAGEMENT_POPUP_OLD_OBJECT = 'DVB_MANAGEMENT_POPUP_OLD_OBJECT';
export const DVB_MANAGEMENT_POPUP_TOGGLE = 'DVB_MANAGEMENT_POPUP_TOGGLE';
export const DVB_MANAGEMENT_POPUP_SCHEDULE_DATE = 'DVB_MANAGEMENT_POPUP_SCHEDULE_DATE';
export const DVB_MANAGEMENT_POPUP_SWITCH_MODE = 'DVB_MANAGEMENT_POPUP_SWITCH_MODE';
export const DVB_MANAGEMENT_POPUP_CHANGE_ITEM = 'DVB_MANAGEMENT_POPUP_CHANGE_ITEM';

export interface DVBManagementPopUpAction {
  type:
    | typeof DVB_MANAGEMENT_POPUP
    | typeof DVB_MANAGEMENT_POPUP_MOVE
    | typeof DVB_MANAGEMENT_POPUP_MOVE_CLEAR_DROPDOWN
    | typeof DVB_MANAGEMENT_POPUP_MOVE_NEXT_OPTIONS
    | typeof DVB_MANAGEMENT_POPUP_OLD_OBJECT
    | typeof DVB_MANAGEMENT_POPUP_TOGGLE
    | typeof DVB_MANAGEMENT_POPUP_SCHEDULE_DATE
    | typeof DVB_MANAGEMENT_POPUP_SWITCH_MODE
    | typeof DVB_MANAGEMENT_POPUP_CHANGE_ITEM;
  payload: any;
}

export interface DVBPopUp {
  action?: DVB_ACTIONS;
  column: DVB_COLUMNS;
  data: Array<FieldItem>;
  hasChanged?: boolean;
  isActive?: boolean;
  item?: any;
  oldData?: Array<FieldItem>;
  parent?: DVB_COLUMNS;
  size: CardSizes;
}

export interface FieldItem {
  isActive?: boolean;
  key: string;
  label?: string;
  options?: Array<{
    key: string;
    value: string;
  }>;
  type?: DVB_FIELD_TYPES;
  value?: any;
}
