import * as React from 'react';
import { CustomFrequency, RfTuningScanState } from './redux/types';
import RfTuningTableComponent from '../status/rf-tuning-table-component';
import TaskPanelComponent from 'core/job-panel';
import { TFunction } from 'i18next';
import { RfTuningStatusResult } from '../status/redux/types';
import { Plus } from 'assets/svg';
import CardComponent from 'core/card';
import { CardSizes } from 'core/card/enum';
import './scan-styles.scss';
import RfTuningFullFrequencyHistoryComponent from '../status/rf-tuning-status-component-history';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { Fragment } from 'react';

export interface RfTuningScanComponentProps {
  id: string;
  hhid: string;
  startScan: any;
  results: Array<RfTuningStatusResult>;
  changeCustomTypeValue: any;
  activityLog: any;
  t: TFunction;
  cancelCommand: (traceId: string, deviceId: string, t: TFunction) => void;
  frequencyLimits: any;
  scan: RfTuningScanState;
  addCustomFrequency: (frequency: string, symbrate: string, length: number, t: TFunction) => void;
  deleteCustomFrequency: (index: number) => void;
  clearListCustomFrequencies: () => void;
  changeDropdown: (option: string) => void;
  fetchHistory: (id: string, assetName: string, filter?: PaginationConfig) => void;
  toggleScanAccordion: (index: number) => void;
}

export default class RfTuningScanComponent extends React.Component<RfTuningScanComponentProps> {
  async startScan() {
    const { id, startScan, scan, changeDropdown } = this.props;
    changeDropdown('rf_tuning.dropdown_dvbc');
    const list = scan.customFrequencies.listCustomFrequencies;
    const parameters = {
      frequencies: list.map((item: CustomFrequency) => parseInt(item.frequency) * 1000),
      symbRates: list.map((item: CustomFrequency) => parseInt(item.symbrate)),
      jrt: ['RM;rfdetailsresult']
    };
    return startScan(id, parameters);
  }

  createBadge = (frequency: string, symbrate: string, key: number) => {
    const { t, deleteCustomFrequency, scan } = this.props;
    return (
      <span className="filter-badge is-secondary is-rounded is-small has-margin-right-md has-margin-bottom-md" key={key}>
        {`${t('rf_tuning.label_frequency_mhz')} : ${frequency}, ${t(
          'rf_tuning.label_symbrate_ksymb'
        )} : ${symbrate}`}
        <button
          disabled={scan.waitingCommandExecution}
          className="delete is-small"
          onClick={() => deleteCustomFrequency(key)}
        >
          x
        </button>
      </span>
    );
  };

  addCustomFrequencyIsDisabled() {
    const { scan } = this.props
    const { listCustomFrequencies, currentFrequency } = scan.customFrequencies
    const alreadyAddedFrequency = listCustomFrequencies.findIndex(item => item.frequency === currentFrequency) >= 0

    return alreadyAddedFrequency || scan.waitingCommandExecution || currentFrequency === ''
  }

  render() {
    const {
      results,
      changeCustomTypeValue,
      frequencyLimits,
      t,
      addCustomFrequency,
      clearListCustomFrequencies,
      changeDropdown,
      scan,
      id,
      hhid
    } = this.props;
    const currentFrequency = scan.customFrequencies.currentFrequency;
    const currentSymbrate = scan.customFrequencies.currentSymbrate;
    const listCustomFrequencies = scan.customFrequencies.listCustomFrequencies;

    return (
      <Fragment>
        <div className="columns has-padding-left-sm">
          <div className="has-padding-left-xs">
            {scan &&
              listCustomFrequencies.map((item: CustomFrequency, key: number) => {
                return this.createBadge(item.frequency, item.symbrate, key);
              })}
          </div>
          <div
            className={`column is-flex is-justified-end has-padding-bottom-none has-padding-top-none ${
              listCustomFrequencies.length === 0 && 'is-hidden'
            }`}
          >
            <button
              className={`button-link dropdown-link ${
                !scan.waitingCommandExecution && 'button-action'
              }`}
              style={{ height: 'fit-content', width: 'max-content' }}
              onClick={() => clearListCustomFrequencies()}
              disabled={scan.waitingCommandExecution}
            >
              {t(`rf_tuning.link_clear_frequencies`)}
            </button>
          </div>
        </div>

        <div className="columns has-margin-bottom-xs">
          <div className="column is-flex">
            <div
              className={`is-relative has-padding-right-md ${
                !scan.waitingCommandExecution && 'scan-add-freq'
              }`}
            >
              <button
                className="button is-rounded is-larger has-padding-left-md"
                disabled={scan.waitingCommandExecution}
              >
                <span className="is-flex">
                  <Plus className="icon-svg icon-white icon-x-small" />
                  {t('rf_tuning.button_add_frequency')}
                </span>
              </button>

              <div className={`scan-popover`}>
                <CardComponent size={CardSizes.SMALL}>
                  <div className="has-padding-sm">
                    <h5 className="has-padding-bottom-sm">{t('rf_tuning.label_frequency_mhz')}</h5>
                    <input
                      type="number"
                      className="input"
                      onChange={(event: any) =>
                        changeCustomTypeValue(event.target.value, 'currentFrequency')
                      }
                      value={currentFrequency}
                    />
                  </div>
                  <div className="has-padding-sm">
                    <h5 className="has-padding-bottom-sm">{t('rf_tuning.label_symbrate_ksymb')}</h5>
                    <input
                      type="number"
                      className="input"
                      onInput={(e: any) => (e.target.value = e.target.value.slice(0, 4))}
                      onChange={(event: any) =>
                        changeCustomTypeValue(event.target.value, 'currentSymbrate')
                      }
                      value={currentSymbrate}
                    />
                  </div>
                  <div className="is-flex is-justified-end has-padding-sm">
                    <button
                      className="button is-secondary is-rounded is-larger"
                      onClick={() =>
                        addCustomFrequency(
                          currentFrequency,
                          currentSymbrate || '6900',
                          listCustomFrequencies.length,
                          t
                        )
                      }
                      disabled={this.addCustomFrequencyIsDisabled()}
                    >
                      {t('rf_tuning.button_add')}
                    </button>
                  </div>
                </CardComponent>
              </div>
            </div>

            <button
              className="button is-primary is-rounded is-larger"
              onClick={() => this.startScan()}
              disabled={scan.waitingCommandExecution || listCustomFrequencies.length === 0}
            >
              {t('rf_tuning.button_scan')}
            </button>
          </div>

          <div className="column">
            <div className="select is-right">
              <select
                value={scan.dropdownValue}
                onChange={(event: any) => changeDropdown(event.target.value)}
                disabled={scan.waitingCommandExecution}
              >
                <option value="rf_tuning.dropdown_dvbc">
                  {this.props.t('rf_tuning.dropdown_dvbc')}
                </option>
                <option value="rf_tuning.dropdown_dvbc_history">
                  {this.props.t('rf_tuning.dropdown_dvbc_history')}
                </option>
              </select>
            </div>
          </div>
        </div>

        {this.props.activityLog && this.props.activityLog.status !== 'SUCCEEDED' && (
          <ul className="panel has-margin-bottom-md">
            <TaskPanelComponent
              item={this.props.activityLog}
              showProgressBar
              cancel={this.props.cancelCommand}
              t={this.props.t}
            />
          </ul>
        )}

        {scan && scan.dropdownValue === 'rf_tuning.dropdown_dvbc' ? (
          <RfTuningTableComponent frequencyHistories={results} frequencyLimits={frequencyLimits} />
        ) : (
          <RfTuningFullFrequencyHistoryComponent
            fetchHistory={this.props.fetchHistory}
            toggleAccordion={this.props.toggleScanAccordion}
            hhid={hhid}
            id={id}
            resultHistoryToShow={scan.resultHistoryToShow}
            frequencyLimits={this.props.frequencyLimits}
            historyType={'getFrequencyDetails'}
          />
        )}
      </Fragment>
    );
  }
}
