import PaginationComponent from 'core/pagination-optimized/pagination-container';
import NoRecordsComponent from 'core/no-records/no-records-container';
import React, { Fragment, Component } from 'react';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { paginationActivityLog } from 'core/pagination-optimized/redux/config';
import { TFunction } from 'i18next';
import TaskPanelComponent from 'core/job-panel';
import { ActivityLogResultItem, ActivityLogState } from './redux/types';
import Topbar from '../topbar/topbar-container';
import Card from 'core/card/card-component';
import ItemsPerPageComponent from 'core/items-per-page/items-per-page-container';
import { CardPaddings } from 'core/card/enum';
import MultipleSearchComponent from 'core/multiple-search/multiple-search-container';
import { clearAccordionTable } from 'core/table-accordion/redux/actions';
import { GeneralDeviceState } from '../general/redux/types';

interface ActivityLogComponentProps {
  activityLog: ActivityLogState;
  cancelLog: (traceId: string, deviceId: string) => void;
  fetch: (id: string, commandList?: boolean) => void;
  hhid: string;
  id: string;
  overview: any;
  t: TFunction;
  setListValues: (commandList: string[]) => void;
  changePageActivityLog: (startPage: any, endPage: any) => void;
  pagination: any;
  resetMultipleSearchState: () => void;
  general: GeneralDeviceState
}

export default class ActivityLogComponent extends Component<ActivityLogComponentProps> {
  componentDidMount() {
    const { fetch, id, resetMultipleSearchState } = this.props;
    resetMultipleSearchState();
    fetch(id, true);
  }

  onChangeActivityLogPage() {
    const { changePageActivityLog, activityLog, pagination } = this.props;

    if (activityLog && activityLog.results) {
      changePageActivityLog(
        paginationActivityLog.pageSize * paginationActivityLog.pageNumber,
        paginationActivityLog.pageSize * (paginationActivityLog.pageNumber + 1)
      );
    }
  }

  render() {
    const { activityLog, cancelLog, fetch, id, t, changePageActivityLog, pagination, general } = this.props;
    const pag = pagination.pagination.get('activity_log');

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>
        <div className="tile is-parent is-vertical has-margin-bottom-lg">
          <Card padding={CardPaddings.NONE}>
            <div className="columns has-padding-left-md has-padding-right-md has-padding-top-md has-margin-none">
              <div className="column">
                <h4 className="title is-size-4">{t('all_device_pages.menu_activity_log')}</h4>
              </div>

              <ItemsPerPageComponent
                id={'activity_log'}
                onChangeAction={() => changePageActivityLog(0, pag && pag.pageSize)}
              />
            </div>

            <div className="has-padding-right-md has-padding-left-md has-padding-bottom-sm">
              <MultipleSearchComponent
                id={'activityLog'}
                i18nKey={'command'}
                onMultipleSearch={() => {
                  clearAccordionTable('activityLog');
                  fetch(id);
                }}
                badgesOutside={true}
                removeCardPadding={true}
              />
            </div>

            <ul className="panel has-padding-top-xs">
              {activityLog &&
              activityLog.results &&
              activityLog.results.items &&
              activityLog.results.itemsToshow.length ? (
                activityLog.results.itemsToshow.map(
                  (item: ActivityLogResultItem, index: number) => (
                    <TaskPanelComponent
                      item={item}
                      showProgressBar={false}
                      key={index}
                      cancel={cancelLog}
                      t={t}
                      backgroundGrey={true}
                      hideCancelButton={general?.shadow?.reported?.software?.os === 'android'}
                    />
                  )
                )
              ) : (
                <NoRecordsComponent />
              )}
            </ul>
            <PaginationComponent
              id="activity_log"
              pageSize={paginationActivityLog && paginationActivityLog.pageSize}
              totalElements={
                activityLog && activityLog.results && activityLog.results.totalElements
              }
              onChange={() => this.onChangeActivityLogPage()}
            />
          </Card>
        </div>
      </Fragment>
    );
  }
}
