import AuthService from 'core/auth/auth-service';
import { add, remove } from 'core/spinner/redux/actions';
import queryString from 'core/utils/query-string';
import apiClient from 'core/api/client';
import { addToaster } from 'core/toaster/redux/actions';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { TFunction } from 'i18next';
import {
  FREQUENCY_SCAN_HISTORY_REQUEST,
  TOGGLE_ACCORDION,
  RF_TUNING_SET_SHOW_GRAPHIC,
  RF_TUNING_STATUS,
  RF_TUNING_STATUS_SUCCESS,
  RF_TUNING_STATUS_FAILURE,
  RF_TUNING_SELECT_FREQUENCY_TAB,
  RF_TUNING_STATUS_THRESHOLDS_SUCCESS,
  RF_TUNING_STATUS_DVB_TYPES_SUCCESS,
  RF_TUNING_DVB_TYPES,
  RF_TUNING_STATUS_THRESHOLDS,
  RF_TUNING_FORCE_CLEAR_CACHE,
  RF_TUNING_CLEAR_CACHE,
  RF_TUNING_WAITING_COMMAND
} from './types';
import { ApplicationState } from 'application/application-redux';
import { handleCommandError } from 'core/utils/error-utils';
import { successCustomResults } from '../../scan/redux/actions';
import { envs } from 'application/envHandler';

let timerTimeout: any;
let timerInterval: any;

export function clearCache(payload: any) {
  return {
    type: RF_TUNING_CLEAR_CACHE,
    payload: payload
  };
}

export function clearCacheRFTuning() {
  return {
    type: RF_TUNING_FORCE_CLEAR_CACHE
  };
}

function waitingCommand(payload: any) {
  return {
    type: RF_TUNING_WAITING_COMMAND,
    payload
  };
}

function success(payload: any) {
  return {
    type: RF_TUNING_STATUS_SUCCESS,
    payload
  };
}

function successThresholds(payload: any) {
  return {
    type: RF_TUNING_STATUS_THRESHOLDS_SUCCESS,
    payload
  };
}

function successDVBTypes(payload: any) {
  return {
    type: RF_TUNING_STATUS_DVB_TYPES_SUCCESS,
    payload
  };
}

function successHistory(payload: any) {
  return {
    type: FREQUENCY_SCAN_HISTORY_REQUEST,
    payload
  };
}

function failure(payload: any) {
  return {
    type: RF_TUNING_STATUS_FAILURE,
    payload
  };
}

export function onSelectFrequencyTab(value: string) {
  return {
    type: RF_TUNING_SELECT_FREQUENCY_TAB,
    payload: value
  };
}

export function toggleAccordion(index: number) {
  return {
    type: TOGGLE_ACCORDION,
    payload: index
  };
}

export function onSelectGraphic(value: string) {
  return {
    type: RF_TUNING_SET_SHOW_GRAPHIC,
    payload: value
  };
}

export function fetchThresholds(id: string) {
  return async (dispatch: any) => {
    dispatch(add(RF_TUNING_STATUS_THRESHOLDS, {}));
    await AuthService.refreshToken();
    apiClient.get(`${envs.REACT_APP_API_URL}/device/${id}/rf-tuning/thresholds`).then(
      (response) => {
        dispatch(remove(RF_TUNING_STATUS_THRESHOLDS));
        return dispatch(successThresholds(response.data));
      },
      (rejection) => {
        dispatch(remove(RF_TUNING_STATUS_THRESHOLDS));
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_rf_tuning',
            message: 'error_messages.VIPOC_ERROR_039',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function fetchDVBTypes(id: string) {
  return async (dispatch: any) => {
    dispatch(add(RF_TUNING_DVB_TYPES, {}));
    await AuthService.refreshToken();
    apiClient.get(`${envs.REACT_APP_API_URL}/device/${id}/rf-tuning/dvb`).then(
      (response) => {
        dispatch(remove(RF_TUNING_DVB_TYPES));
        return dispatch(successDVBTypes(response.data));
      },
      (rejection) => {
        dispatch(remove(RF_TUNING_DVB_TYPES));
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_rf_tuning',
            message: 'error_messages.VIPOC_ERROR_037',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function fetchHistory(id: string, assetName: string, filter?: PaginationConfig) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(FREQUENCY_SCAN_HISTORY_REQUEST, {}));
    await AuthService.refreshToken();
    apiClient
      .get(
        `${
          envs.REACT_APP_API_URL
        }/device/${id}/rf-tuning/history?assetName=${assetName}&${queryString(
          filter,
          getState().application.offset
        )}`
      )
      .then(
        (response) => {
          dispatch(remove(FREQUENCY_SCAN_HISTORY_REQUEST));
          assetName === 'runChannelsStats'
            ? dispatch(successHistory(response.data))
            : dispatch(successCustomResults(response.data));
        },
        (rejection) => {
          dispatch(remove(FREQUENCY_SCAN_HISTORY_REQUEST));
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_rf_tuning',
              message: 'error_messages.VIPOC_ERROR_043',
              type: 'danger'
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}

export function stopChannelStats() {
  return async (dispatch: any) => {
    clearInterval(timerInterval);
    clearTimeout(timerTimeout);
    dispatch(waitingCommand(false));
    dispatch(remove(RF_TUNING_STATUS));
    return dispatch(failure({}));
  };
}

export function isFinal(status: string) {
  switch (status) {
    case 'COMPLETED':
    case 'SUCCEEDED':
    case 'FAILED':
    case 'TIME_EXCEEDED':
    case 'CANCELED':
    case 'REJECTED':
      return true;
    default:
      return false;
  }
}

function doFetchCommandStatus(dispatch: any, id: string, jobId: string) {
  apiClient
    .get(`${envs.REACT_APP_API_URL}/command/runChannelsStats/device/${id}/status`, {
      headers: { transactionID: jobId }
    })
    .then(
      (secondResponse) => {
        let initialStatus: any = { ...secondResponse.data };
        if (isFinal(secondResponse.data.status)) {
          if (secondResponse.data.status === 'TIME_EXCEEDED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_rf_tuning',
                message: 'error_messages.VIPOC_ERROR_042',
                type: 'danger'
              })
            );
          } else if (secondResponse.data.status === 'FAILED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_rf_tuning',
                message: secondResponse.data.response.errorText,
                type: 'danger'
              })
            );

            initialStatus = {
              ...secondResponse.data,
              request: { message: {}, progress: '0%' },
              type: 'DVBC'
            };
            dispatch(success(initialStatus));
          }

          clearInterval(timerInterval);
          clearTimeout(timerTimeout);
          dispatch(waitingCommand(false));
        }
        dispatch(remove(RF_TUNING_STATUS));
        return dispatch(success(initialStatus));
      },
      (secondRejection) => {
        dispatch(remove(RF_TUNING_STATUS));
        return dispatch(failure(secondRejection));
      }
    );
}

export function fetchCommandStatus(id: string, jobId: string) {
  return async (dispatch: any) => {
    timerTimeout = setTimeout(() => {
      clearInterval(timerInterval);
      dispatch(waitingCommand(false));
      dispatch(remove(RF_TUNING_STATUS));
    }, 1900000);
    doFetchCommandStatus(dispatch, id, jobId);
    timerInterval = setInterval(() => doFetchCommandStatus(dispatch, id, jobId), 5000);
  };
}

export function fetchCommand(id: string) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(RF_TUNING_STATUS, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: 'runChannelsStats',
        deviceId: id,
        jsonParameters: { responseTopic: 'RM;channelstatsresult' }
      })
      .then(
        (response) => {
          const { jobId } = response.data.responseJson;
          if (response.data.httpCode === '400') {
            handleCommandError(
              dispatch,
              jobId,
              'all_device_pages.menu_rf_tuning',
              getState().i18n.t,
              RF_TUNING_STATUS,
              waitingCommand
            );
          } else {
            dispatch(waitingCommand(true));
            dispatch(fetchCommandStatus(id, response.data.responseJson.jobId));
          }
        },
        (rejection) => {
          dispatch(remove(RF_TUNING_STATUS));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_021';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_rf_tuning',
              message,
              type: 'danger'
            })
          );
          dispatch(waitingCommand(false));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function cancelCommand(traceId: string, deviceId: string, t: TFunction) {
  return async (dispatch: any) => {
    dispatch(add(RF_TUNING_STATUS, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/device/${deviceId}/activity-log`, {
        deviceId,
        traceId
      })
      .then(
        () => {},
        (rejection) => {
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_033';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_activity_log',
              message,
              type: 'danger'
            })
          );
        }
      );
  };
}
