export const ACCESS_TOKEN_KEY = 'TOKEN';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const CURRENT_OPCO_KEY = 'OPCO';
export const CURRENT_LANG_KEY = 'LANG';
export const AD_GROUP_MAPPINGS = 'AD_GROUPS';

export type VipocGroup = {
  prefix: string;
  moduleName: string;
  role: string;
  opco: string;
  lang: string;
};
