import React, { Component } from 'react';
import { OnnetOffnetResult } from './redux/reducer';
import { Link } from 'react-router-dom';
import OnnetOffnetGridComponent from '../onnet-offnet/onnet-offnet-grid-component';
import { TFunction } from 'i18next';
import './index.scss'
import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types';
import { getRangeEntries, RemoveEntryFunction, SaveEntryFunction, SaveNewEntryFunction, SelectEntryFunction } from './redux/action';

interface OnnetOffnetComponentProps {
  id: any
  isFetching: boolean
  results: OnnetOffnetResult

  t: TFunction

  execute:  () => Promise<any>
  submitChanges: (result: OnnetOffnetResult) => Promise<void>

  selectEntry: SelectEntryFunction
  removeEntry: RemoveEntryFunction
  saveEntry: SaveEntryFunction
  saveNewEntry: SaveNewEntryFunction

  openModal: OpenModalFunction
  closeModal: CloseModalFunction
}

export default class OnnetOffnetComponent extends Component<OnnetOffnetComponentProps> {
  componentDidMount() {
    this.props.execute();
  }

  private submitChanges() {
    this.props.submitChanges(this.props.results);
  }

  hasChanges() {
    return getRangeEntries(this.props.results.ranges).length || getRangeEntries(this.props.results.rangesIpv6).length
  }

  render() {
    const { t, id } = this.props;

    return (
      <div className="section">
        <nav className="level">
          <div className="level-left">
            <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
              Onnet Offnet
              <strong>{id}</strong>
              <p>
                <Link
                  style={{ fontSize: '13px', color: 'grey' }}
                  className="is-small"
                  to={{ pathname: `/`, state: { title: 'Home' } }}
                >
                  <span className="icon">
                    <i className="mdi mdi-keyboard-return" />
                  </span>
                  {t('general.link_back')}
                </Link>
              </p>
            </h3>
          </div>
          <div className="level-right is-2">
            <button className="button is-primary button-content" onClick={() => this.submitChanges()} disabled={!this.hasChanges()}>
              <span className="icon">
                <i className="mdi mdi-playlist-check" />
              </span>
              <span>{t('onnet_offnet.label_submit_changes')}</span>
            </button>
          </div>
        </nav>
        <div className="table-container">
          <div className="table-item">
            <OnnetOffnetGridComponent
              title="IPV4"
              group="v4"
              isFetching={this.props.isFetching}
              results={this.props.results.ranges}
              selectEntry={this.props.selectEntry}
              removeEntry={this.props.removeEntry}
              saveEntry={this.props.saveEntry}
              openModal={this.props.openModal}
              closeModal={this.props.closeModal}
              saveNewEntry={this.props.saveNewEntry}
              t={this.props.t}
            />
          </div>
          <div className="table-item">
            <OnnetOffnetGridComponent
              title="IPV6"
              group="v6"
              isFetching={this.props.isFetching}
              results={this.props.results.rangesIpv6}
              selectEntry={this.props.selectEntry}
              removeEntry={this.props.removeEntry}
              saveEntry={this.props.saveEntry}
              openModal={this.props.openModal}
              closeModal={this.props.closeModal}
              saveNewEntry={this.props.saveNewEntry}
              t={this.props.t}
            />
          </div>
        </div>
      </div>
    );
  }
}
