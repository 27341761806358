import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import CardComponent from 'core/card';
import { CardPaddings } from 'core/card/enum';
import { Warning } from 'assets/svg';
import './settings-card-styles.scss';
import { AssetValue } from '../../redux/types';
interface SettingsCardProps {
  element: any;
  i18nKey: string;
  t: TFunction;
  componentId: string;
  hasChanged: boolean;
  updateFields: (
    componentId: string,
    field: { name: string; newValue: string; assets?: string }
  ) => void;
}

export default class SettingsCardComponent extends Component<SettingsCardProps> {
  getTranslatedMeasure = (word: string, t: TFunction) => {
    const keyToTranslate = word.split(' ')[1]; // hrs
    if (keyToTranslate) {
      return word.split(' ')[0] + ' ' + t(keyToTranslate);
    } else if ((word && word.includes('settings.')) || word.includes('general.')) {
      return t(word);
    }
    return word;
  };

  getListLabel = (elementName: string, key: number, item: AssetValue, t: TFunction) => {
    if (elementName === 'audioVolume') {
      return key === 0 ? `${t('settings.label_actual_value')}: ` + item.label : item.label
    }

    if (elementName === 'updateToSpecificFirmware') {
      return item.label
    }

    if (elementName === 'hdmiMode' && (item.value && item.value !== 'auto')) {
      return item.value
    }

    return this.getTranslatedMeasure(item.label, t)
  }

  handleNumberChange = (value: number, element: any) => {
    const { updateFields, componentId } = this.props;
    if (!value) value = 0;
    if (value < 0) value = 0;
    if (value > 100) value = 100;

    updateFields(componentId, {
      name: element.name,
      newValue: value.toString()
    });
  };

  onChange(e: any, element: any) {
    const { updateFields, componentId } = this.props;
    const re = /^[0-9\b]+$/;

    if (e.target.value !== '') {
      if (re.test(e.target.value) && e.target.value.length < 5) {
        updateFields(componentId, {
          name: element.name,
          newValue: e.target.value
        });
        return e.target.value;
      } else {
        return (e.target.value = element.currentValue);
      }
    } else {
      updateFields(componentId, {
        name: element.name,
        newValue: e.target.value
      });
      return e.target.value;
    }
  }

  render() {
    const { element, i18nKey, t, componentId, updateFields, hasChanged } = this.props;    
    
    return (
      <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
        <div className="columns flex-wrap has-padding-xs">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`${i18nKey}${element.label}`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.current_value`)}</h6>
          </div>

          {element && element.type === 'radio' && (
            <div className="column is-12 has-padding-top-none has-margin-bottom-xxs">
              <div className="columns has-padding-top-md has-padding-left-sm has-padding-bottom-sm">
                {element.available.map((item: any, key: number) => (
                  <div
                    key={key}
                    className={`column is-6 has-padding-right-none has-padding-left-none`}
                  >
                    <label
                      className="radio"
                      onClick={(e) => {
                        if(!hasChanged || element.currentValue !== element.value) {
                          updateFields(componentId, {
                            name: element.name,
                            newValue: item.value
                          })
                        }
                      }}
                    >
                      <input
                        className="is-checkradio is-small"
                        type="radio"
                        checked={item.value === element.currentValue}
                        readOnly
                        disabled={hasChanged && element.currentValue === element.value}
                      />
                      <label>{t(`${item.label}`)}</label>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {element && element.type === 'number' && (
            <div className="column settings-in">
              <input
                className="input"
                min={0}
                type="number"
                value={parseInt(element.currentValue) === -1 ? '' : parseInt(element.currentValue)}
                placeholder={t('general.not_available')}
                pattern="[0-9]*[.,]?[0-9]+"
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 3);
                }}
                onChange={(e) => this.handleNumberChange(parseInt(e.target.value), element)}
                max={100}
                disabled={hasChanged && element.currentValue === element.value}
              />
            </div>
          )}

          {element && element.type === 'text' && (
            <div className="column">
              <input
                type="text"
                className="input"
                disabled={element.disabled || (hasChanged && element.currentValue === element.value)}
                value={element.currentValue}
                onChange={(e) => this.onChange(e, element)}
              />
              {element.error && (
                <p className="has-error-message has-padding-top-sm is-flex">
                  <Warning className="icon icon-svg icon-error" />
                  {t(element.error)}
                </p>
              )}
            </div>
          )}

          {element && element.type === 'list' && (
            <div className="column">
              <div className="select is-fullwidth">
                <select
                  value={element.currentValue}
                  disabled={element.disabled || (hasChanged && String(element.currentValue) === String(element.value))}
                  onChange={(e) =>
                    updateFields(componentId, {
                      name: element.name,
                      newValue: e.target.value
                    })
                  }
                >
                  {element.available.map((item: AssetValue, key: number) => (
                    <option key={key} value={item.value} disabled={item.active === false}>
                      {this.getListLabel(element.name, key, item, t)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {element && element.type === 'check' && (
            <div className="columns has-padding-md">
              {element.available.map((item: AssetValue, key: number) => (
                <div key={key} className="column">
                  <div className="has-checkbox checkbox">
                    <input
                      className="is-small"
                      type="checkbox"
                      name={t(item.label)}
                      checked={item.selected}
                      onChange={(e) =>
                        updateFields(componentId, {
                          name: element.name,
                          newValue: item.label,
                          assets: 'changeChannelVisibility'
                        })
                      }
                      disabled={hasChanged && String(element.currentValue) === String(element.value)}
                    />

                    <label>{t(item.label)}</label>
                  </div>
                </div>
              ))}
            </div>
          )}

          {element && element.previousValue && (
            <Fragment>
              <div className="column is-12 has-border-top has-padding-bottom-none">
                <h6 className="text is-h6 is-grey is-bold">{t(`settings.previous_value`)}</h6>
              </div>
              <div className="column is-12">
                <input
                  type="text"
                  className="input"
                  disabled
                  defaultValue={
                    element.previousValue ? element.previousValue : t(`general.not_available`)
                  }
                />
              </div>
            </Fragment>
          )}
        </div>
      </CardComponent>
    );
  }
}
