export const SUBSCRIPTIONS_FAILURE = 'SUBSCRIPTIONS_FAILURE';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_SEARCH = 'SUBSCRIPTIONS_SEARCH';
export const SUBSCRIPTIONS_CLEAR_CACHE = 'SUBSCRIPTIONS_CLEAR_CACHE ';

export interface SubscriptionAction {
  type: string;
  payload?: any;
}

export interface SubscriptionItem {
  id: string;
  transactionId: string;
  name: string;
  purchaseDate: string;
  renewalDate: string;
  price: string;
}

export interface SubscriptionsState {
  items?: Array<SubscriptionItem>;
  totalElements?: number;
}
