import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import AuthService from 'core/auth';
import { addToaster } from 'core/toaster/redux/actions';
import requestGrid from '../../grid/redux/action';
import {
  OVERLAY_MODAL_IDMP_CLOSE,
  OVERLAY_MODAL_IDMP_OPEN,
  FILE_UPLOAD_ID_MAPPING_FAILURE,
  FILE_UPLOAD_ID_MAPPING_REQUEST,
  FILE_UPLOAD_ID_MAPPING_SUCCESS,
  SET_FILE_TO_UPLOAD,
  SET_FILE_INGEST_UPLOAD_TYPE
} from './types';
import { envs } from 'application/envHandler';

export function setFileType(value: string) {
  return {
    type: SET_FILE_INGEST_UPLOAD_TYPE,
    payload: value
  };
}

export function setFileValue(value: string) {
  return {
    type: SET_FILE_TO_UPLOAD,
    payload: value
  };
}

export const open = (options: { title?: string; content?: string; execute?: any }) => ({
  type: OVERLAY_MODAL_IDMP_OPEN,
  payload: options
});

export const close = () => ({
  type: OVERLAY_MODAL_IDMP_CLOSE
});

function request() {
  return {
    type: FILE_UPLOAD_ID_MAPPING_REQUEST
  };
}

function success(payload: any) {
  return {
    type: FILE_UPLOAD_ID_MAPPING_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: FILE_UPLOAD_ID_MAPPING_FAILURE,
    payload
  };
}

export function uploadFile(data: FormData) {
  return async (dispatch: any, getState: any) => {
    dispatch(request());
    dispatch(add(FILE_UPLOAD_ID_MAPPING_REQUEST, {}));
    const { fileType } = getState().management.idMapping.modal;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        opco: AuthService.getCurrentOpco(),
        fileType
      }
    };
    apiClient.post(`${envs.REACT_APP_API_URL}/id-mapping/upload`, data, config).then(
      response => {
        dispatch(requestGrid()).then(() => {
          dispatch(remove(FILE_UPLOAD_ID_MAPPING_REQUEST));

          return dispatch(success(response.data));
        });
      },
      rejection => {
        dispatch(requestGrid()).then(() => {
          let errorMsg: string = 'error_messages.VIPOC_ERROR_011';
          if (rejection.response && rejection.response.data) {
            errorMsg = rejection.response.data;
            if (rejection.response.status === 422) {
              errorMsg = 'error_messages.VIPOC_ERROR_045';
            }
          }
          dispatch(remove(FILE_UPLOAD_ID_MAPPING_REQUEST));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: errorMsg,
              type: 'danger'
            })
          );
          return dispatch(failure('Failure'));
        });
      }
    );
  };
}
