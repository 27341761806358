import DateUtils from 'core/utils/date-utils';
import { TFunction } from 'i18next';
import { VodItem } from './types';

export default (data: VodItem, t: TFunction): VodItem => {
  return {
    assetId: data ? data.assetId : '',
    transactionId: data ? data.transactionId : '',
    assetTitle: data ? data.assetTitle : '',
    assetType: data ? data.assetType : '',
    purchaseDate: data && data.purchaseDate ? DateUtils.formatDate(data.purchaseDate) : '',
    expiryDate: data && data.endDate ? DateUtils.formatDate(data.endDate) : '',
    duration: data ? data.duration : '',
    watched: data
      ? data.lastView === null
        ? t('vod.column_not_watched')
        : DateUtils.formatDate(data.lastView)
      : t('vod.column_not_watched')
  };
};

export const getLabels = (t: TFunction): VodItem => ({
  assetId: t('vod.column_asset_id'),
  transactionId: t('vod.column_transaction_id'),
  assetTitle: t('vod.column_asset_title'),
  assetType: t('vod.column_asset_type'),
  purchaseDate: t('vod.column_purchase_date'),
  expiryDate: t('vod.column_expiry_date'),
  duration: t('vod.column_duration'),
  watched: t('vod.column_watched')
});
