import { SET_PENDING_TASK_SPINNER, DELETE_PENDING_TASK, SpinnerAction } from './types';

export const add = (key: string, value: any, spinnerActive: boolean = true): SpinnerAction => ({
  type: SET_PENDING_TASK_SPINNER,
  payload: {
    key,
    value,
    spinnerActive
  }
});

export const remove = (key: string, spinnerActive: boolean = true): SpinnerAction => ({
  type: DELETE_PENDING_TASK,
  payload: {
    key,
    spinnerActive
  }
});
