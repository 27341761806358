import { OverlayModalState, OVERLAY_MODAL_CLOSE, OVERLAY_MODAL_OPEN } from './types';

export const open = (options: OverlayModalState) => ({
  type: OVERLAY_MODAL_OPEN,
  payload: options
});

export const close = () => ({
  type: OVERLAY_MODAL_CLOSE
});
