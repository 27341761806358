export const DELETE_PENDING_TASK = 'DELETE_PENDING_TASK';
export const SET_PENDING_TASK_SPINNER = 'SET_PENDING_TASK_SPINNER';
export interface SpinnerState {
  tasks: Map<string, any>;
  isActive: boolean;
}
export interface SpinnerAction {
  type: typeof SET_PENDING_TASK_SPINNER | typeof DELETE_PENDING_TASK;
  payload: any;
}
