import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import {
  CLEAR_ID_MAPPING_FILTER,
  CREATE_ID_MAPPING_FILTER_TAG,
  POPULATE_ID_MAPPING_FILTER,
  SET_ID_MAPPING_FILTER_VALUE,
  SHOW_ID_MAPPING_FILTER,
  DELETE_ID_MAPPING_FILTER_TAG,
  CLEAR_ALL_ID_MAPPING_FILTER
} from './action';
import { options } from './data';

export const ID_MAPPING_CHANGE_QUERIES = 'ID_MAPPING_CHANGE_QUERIES';

export interface IdMappingOption {
  key: string;
  value: Array<IdMappingOption> | Array<string> | null | string;
  label: string;
  isTerminator: boolean;
  type: string;
  parent: string;
  uuid?: string;
  index: number;
}

export interface IdMappingState {
  visible: Array<any>;
  selected: any;
  filled: any;
  lastQuery: any;
  queries: Array<String>;
}

export interface IdMappingFilter {
  key: string;
  type: string;
  value: Array<IdMappingOption>;
  index: number;
}

const shadowSelect: IdMappingFilter = {
  key: 'things',
  type: 'select',
  index: 0,
  value: options
};

const initialState: IdMappingState = {
  visible: [shadowSelect],
  selected: {},
  filled: {},
  lastQuery: {
    valid: false
  },
  queries: ['things.thingName:*']
};

export interface IdMappingAction {
  type: string;
  payload: any;
}

export default function(
  state: IdMappingState = initialState,
  action: IdMappingAction
): IdMappingState {
  switch (action.type) {
    case SET_ID_MAPPING_FILTER_VALUE: {
      const filled: any = {};
      const { selected } = state;
      selected[action.payload.key] = action.payload.value;
      return { ...state, selected, filled };
    }
    case SHOW_ID_MAPPING_FILTER: {
      const alreadyExists = R.findIndex(R.propEq('key', action.payload.key))(state.visible) !== -1;
      if (alreadyExists) return { ...state };

      const parentIndex = state.visible.findIndex(
        (element: IdMappingOption) => element.key === action.payload.parent
      );
      const spliced = state.visible.splice(parentIndex + 1, state.visible.length - 1);
      spliced.forEach((value: any) => {
        delete state.selected[value.key];
      });

      state.visible.push(action.payload);
      state.visible.forEach((option: any) => ({ ...option, uuid: uuid() }));

      return { ...state };
    }

    case CLEAR_ALL_ID_MAPPING_FILTER: {
      return {
        visible: [shadowSelect],
        selected: {},
        filled: {},
        lastQuery: {
          valid: false
        },
        queries: ['things.thingName:*']
      };
    }
    case CLEAR_ID_MAPPING_FILTER: {
      const { visible } = state;
      const index = visible.findIndex((element: IdMappingOption) => element.key === action.payload);
      const spliced = visible.splice(index + 1, visible.length - 1);
      spliced.forEach((value: any) => {
        delete state.selected[value.key];
      });
      return { ...state, visible };
    }

    case POPULATE_ID_MAPPING_FILTER: {
      const filled: any = {};
      filled[action.payload.key] = action.payload.value;
      if (action.payload.value.length === 0) {
        delete filled[action.payload.key];
      }
      return { ...state, filled };
    }

    case ID_MAPPING_CHANGE_QUERIES: {
      return { ...state, queries: action.payload };
    }

    case CREATE_ID_MAPPING_FILTER_TAG: {
      const sortByIndex = R.sortBy(R.prop('index'));
      const sorted = sortByIndex(state.visible);

      const lastElement = sorted[sorted.length - 1];
      const isLastElementFilled =
        state.filled[lastElement.key] !== undefined && state.filled[lastElement.key].length > 0;
      const isValidAndFilled = isLastElementFilled && lastElement.isTerminator;
      const query: string = sorted.map((option: any) => option.key).join('.');

      if (isValidAndFilled) {
        const { queries } = state;
        queries.push(`${query}:${state.filled[lastElement.key]}`);
        return {
          ...state,
          visible: [shadowSelect],
          selected: {},
          filled: {},
          lastQuery: {
            valid: isValidAndFilled,
            value: `${query}:${state.filled[lastElement.key]}`
          },
          queries
        };
      }
      return {
        ...state,
        lastQuery: {
          valid: false
        }
      };
    }

    case DELETE_ID_MAPPING_FILTER_TAG:
      const queries = state.queries.filter(query => query !== action.payload);
      return { ...state, queries };
    default:
      return state;
  }
}
