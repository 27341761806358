import React from 'react'
import { DeleteActionParams, DeleteConfirmInterface, DeleteServiceFunction } from '../../types'
import PopUp from 'core/pop-up'
import { CardSizes } from 'core/card/enum'
import { t } from 'i18next'

function onDelete(action: DeleteServiceFunction, params: string | DeleteActionParams, setActive: React.Dispatch<React.SetStateAction<boolean>>) {
  action(params)
  setActive(false)
}

export default function DeleteConfirm({ title, body, action, params, setActive }: DeleteConfirmInterface) {
  return (
    <PopUp isActive size={CardSizes.MEDIUM}>
      <div className="columns is-flex flex-wrap">
        <div className="column is-12 is-vertical-center">
          <div className="field-row">
            <h5 className="text is-h5 is-bold is-marginless">
              {title}
            </h5>
          </div>
        </div>
        <div className="column is-12 is-vertical-center">
          {body}
        </div>
      </div>
      <div className="column is-12 is-centered">
        <button className="button is-larger is-primary" onClick={() => onDelete(action, params, setActive)}>{t('general.confirm')}</button>
        <button className="button is-larger is-secondary" onClick={() => setActive(false)}>{t('general.cancel')}</button>
      </div>
    </PopUp>
  )
}
