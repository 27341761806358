import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import { IdMappingState, IdMappingOption } from './redux/reducer';
import {
  clear as clearType,
  populate as populateType,
  setValue as setValueType,
  show as showType,
  validateAndCreateQueryTag as validateAndCreateQueryTagType,
  deleteQuery as deleteQueryType
} from './redux/action';
import { UploadModalState } from '../modal-upload/redux/types';
import ModalUploadIdMappingContainer from '../modal-upload';

interface IdMappingPropertiesSelectProps {
  default?: any;
  onChange: any;
  options: any;
  value: any;
  disabled: boolean;
  t: TFunction;
}

const IdMappingPropertiesSelect = ({
  onChange,
  value,
  disabled,
  t,
  options,
  ...remaining
}: IdMappingPropertiesSelectProps) => {
  return (
    <div className="select is-fullwidth">
      <select onChange={onChange} value={value || remaining.default} disabled={disabled}>
        <option value="">{t('id_mapping.select_mapping_ingest')}</option>
        {options.map((option: any) => (
          <option key={option.label} value={JSON.stringify(option)}>
            {t(option.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

interface IdMappingFormProps {
  form: IdMappingState;
  show: typeof showType;
  setValue: typeof setValueType;
  populate: typeof populateType;
  clear: typeof clearType;
  validateAndCreateQueryTag: typeof validateAndCreateQueryTagType;
  deleteQuery: typeof deleteQueryType;
  execute: any;
  open: (options: UploadModalState) => void;
  close: any;
  isFetching: boolean;
  clearAll: any;
  t: TFunction;
}

export default class IdMappingFormComponent extends Component<IdMappingFormProps> {
  componentWillUnmount() {
    const { clearAll } = this.props;

    clearAll();
  }

  onChangeSelect(key: string, event: React.FormEvent<HTMLSelectElement>) {
    const { show, setValue, populate, clear } = this.props;
    setValue(key, event.currentTarget.value);
    if (event.currentTarget.value.length > 0) {
      const option: IdMappingOption = JSON.parse(event.currentTarget.value);
      if (option.isTerminator && ['select-value'].includes(option.type)) {
        populate(key, option.value);
      } else {
        show(option);
      }
    } else {
      clear(key);
    }
  }

  onChangetext(key: string, event: any) {
    const { populate } = this.props;
    populate(key, event.target.value);
  }

  async onSearch() {
    const { form, execute } = this.props;
    await execute(form.queries);
  }

  openFileUpload() {
    const { open, close } = this.props;
    const command = () => {
      close();
    };
    open({
      title: 'id_mapping.button_upload_file',
      active: true,
      execute: command,
      disabledUpload: true,
      dataUpload: new FormData(),
      fileNameToUpload: '',
      fileType: 'XLS'
    });
  }

  render() {
    const { form, isFetching, t } = this.props;
    return (
      <div>
        <div className="field has-addons">
          {form.visible.map((filter: any) => {
            return (
              <div className="control is-expanded" key={filter.key}>
                {filter.type === 'select' && (
                  <IdMappingPropertiesSelect
                    default=""
                    disabled={isFetching}
                    value={form.selected[filter.key]}
                    onChange={(event: any) => this.onChangeSelect(filter.key, event)}
                    options={filter.value.map((option: any) => ({ ...option }))}
                    t={t}
                  />
                )}

                {filter.type === 'text' && (
                  <input
                    className="input"
                    type="text"
                    onChange={(option: any) => this.onChangetext(filter.key, option)}
                  />
                )}

                {filter.type === 'timestamp' && (
                  <input
                    className="input"
                    type="date"
                    onChange={(option: any) => this.onChangetext(filter.key, option)}
                  />
                )}

                {filter.type === 'number' && (
                  <input
                    className="input"
                    min="1"
                    type="number"
                    onChange={(option: any) => this.onChangetext(filter.key, option)}
                  />
                )}
              </div>
            );
          })}
          <div className="control column-rounded is-3">
            <button
              className="button is-primary"
              onClick={() => this.onSearch()}
              disabled={isFetching}
            >
              <span>{t('general.search')}</span>
              <span className="icon">
                <i className="mdi mdi-magnify" />
              </span>
            </button>
            <Link
              to={{
                pathname: '/management/id-mapping/editable-mapping',
                state: { title: 'id_mapping.button_manage_mapping' }
              }}
            >
              <button className="button is-dark">
                <span>{t('id_mapping.button_manage_mapping')}</span>
                <span className="icon">
                  <i className="mdi mdi-table-edit" />
                </span>
              </button>
            </Link>
            <button
              className="button is-black"
              style={{ width: '10em', marginLeft: 0 }}
              onClick={() => this.openFileUpload()}
              disabled={isFetching}
            >
              <span>{t('id_mapping.button_upload_file')}</span>
              <span className="icon">
                <i className="mdi mdi-cloud-upload" />
              </span>
            </button>
          </div>
        </div>
        <ModalUploadIdMappingContainer />
      </div>
    );
  }
}
