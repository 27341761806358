import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import ActionsComponent from './actions-component';
import {
  toggleAccordion,
  toggleAccordionOptions,
  resetActions,
  runCommands
} from '../redux/actions';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  settings: state.devices.detail._settings,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  toggleAccordion,
  toggleAccordionOptions,
  openModal,
  closeModal,
  resetActions,
  runCommands
})(ActionsComponent);
