import { UploadModalAction, UploadModalState } from './types';

const initialState: UploadModalState = {
  active: false,
  disabledUpload: true,
  dataUpload: new FormData(),
  fileNameToUpload: ''
};

export default function(state = initialState, action: UploadModalAction) {
  switch (action.type) {
    case 'OVERLAY_MODAL_IPGEO_CLOSE':
      return {
        ...state,
        active: false,
        disabledUpload: false,
        fileNameToUpload: ''
      };
    case 'OVERLAY_MODAL_IPGEO_OPEN':
      return {
        ...state,
        active: true,
        title: action.payload.title,
        execute: action.payload.execute,
        fileNameToUpload: 'CSV file ...',
        dataUpload: new FormData(),
        disabledUpload: true,
        uploadResponse: ''
      };
    case 'SET_FILE_TO_UPLOAD':
      return {
        ...state,
        active: true,
        disabledUpload: !action.payload.get('file'),
        fileNameToUpload: action.payload.get('file').name
      };
    case 'FILE_UPLOAD_IP_GEOLOCATION_SUCCESS':
      return {
        ...state,
        uploadResponse: action.payload
      };
    default:
      return state;
  }
}
