import { RF_TUNING_CHANGE_TAB, RF_TUNING_CLEAR_TAB_CACHE } from './reducer';

export default function changeTab(payload: string) {
  return {
    type: RF_TUNING_CHANGE_TAB,
    payload
  };
}

export function clearCacheTabRFtuning() {
  return { type: RF_TUNING_CLEAR_TAB_CACHE };
}
