import {
  DVBConstant,
  DVBManagementAction,
  DVBManagementGeneralState,
  DVB_MANAGEMENT_CONSTANTS,
  DVB_MANAGEMENT_COPY,
  DVB_MANAGEMENT_LOADING_TOGGLE
} from './types';
import regionsReducer from './redux-regions';
import nodesReducer from './redux-nodes';
import networksReducer from './redux-networks';
import streamsReducer from './redux-streams';
import servicesReducer from './redux-services';
import dvbManagementPopUpReducer from '../popUp/redux';
import dvbManagementSearchReducer from '../search/redux';
import { combineReducers } from 'redux';
import { DVB_COLUMNS } from './enums';

const initialState: DVBManagementGeneralState = {
  constants: undefined,
  isLoading: {
    region: false,
    network: false,
    node: false
  }
};

const dvbManagementReducer = function(state = initialState, action: DVBManagementAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_CONSTANTS: {
      const assetValues = action.payload.assetsValues;
      const mod = assetValues.filter((asset: DVBConstant) => asset.assetName === 'mod');
      const dvbType = assetValues.filter((asset: DVBConstant) => asset.assetName === 'dvbType');
      const eitPresFlwFlag = assetValues.filter(
        (asset: DVBConstant) => asset.assetName === 'eitPresFlwFlag'
      );
      const scramble = assetValues.filter((asset: DVBConstant) => asset.assetName === 'scramble');

      return {
        ...state,
        constants: {
          mod,
          dvbType,
          eitPresFlwFlag,
          scramble
        }
      };
    }

    case DVB_MANAGEMENT_COPY: {
      const region = action.payload.region;
      const node = action.payload.node;
      const network = action.payload.network;
      const stream = action.payload.stream;
      const service = action.payload.service;

      if (stream) {
        stream.services = service && [service];
      }

      if (network) {
        network.transportStreams = stream && [stream];
      }

      if (node) {
        node.networks = network && [network];
      }

      if (region) {
        region.nodes = node && [node];
      }

      return {
        ...state,
        copied: region
      };
    }

    case DVB_MANAGEMENT_LOADING_TOGGLE: {
      const column = action.payload.column;
      return {
        ...state,
        isLoading: {
          region: column === DVB_COLUMNS.REGION ? action.payload.isActive : state.isLoading.region,
          network:
            column === DVB_COLUMNS.NETWORK ? action.payload.isActive : state.isLoading.network,
          node:
            column === DVB_COLUMNS.NODE ? action.payload.isActive : state.isLoading.node
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({
  main: combineReducers({
    general: dvbManagementReducer,
    popUp: dvbManagementPopUpReducer,
    search: dvbManagementSearchReducer
  }),
  region: regionsReducer,
  node: nodesReducer,
  network: networksReducer,
  stream: streamsReducer,
  service: servicesReducer
});
