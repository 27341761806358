import { ChannelManagement, Document, Tv /* , Configurations */ } from 'assets/svg';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import './homepage-styles.scss';

interface SpectrumHomepageProps {
  t: TFunction;
}

export default class SpectrumHomepageComponent extends Component<SpectrumHomepageProps> {
  render() {
    const { t } = this.props;
    return (
      <section className="homepage">
        <h3 className="text is-h3 is-bold">{t('spectrum.title_spectrum')}</h3>

        <div className="homepage-container">
          <a className="homepage-box" href="/management/spectrum/dvb_management">
            <Tv className="icon-svg" />
            <h4 className="text is-h4 is-bold has-margin-bottom-md">
              {t('spectrum.menu_dvb_management')}
            </h4>
            <p className="text is-highlighted is-underline">
              {t('spectrum.link_manage_broadcast_information')}
            </p>
          </a>

          <a className="homepage-box" href="/management/spectrum/channels">
            <ChannelManagement className="icon-svg" />
            <h4 className="text is-h4 is-bold has-margin-bottom-md">
              {t('spectrum.menu_logical_channels')}
            </h4>
            <p className="text is-highlighted is-underline">
              {t('spectrum.link_manage_channels_information')}
            </p>
          </a>

          <a className="homepage-box" href="/management/spectrum/requests">
            <Document className="icon-svg" />
            <h4 className="text is-h4 is-bold has-margin-bottom-md">
              {t('spectrum.menu_requests')}
            </h4>
            <p className="text is-highlighted is-underline">{t('spectrum.link_manage_requests')}</p>
          </a>
          {/* To be developed in the future */}
          {/* <a className="homepage-box" href="#">
            <Configurations className="icon-svg" />
            <h4 className="text is-h4 is-bold has-margin-bottom-md">
              {t('spectrum.menu_configurations')}
            </h4>
            <p className="text is-highlighted is-underline">
              {t('spectrum.link_manage_configurations')}
            </p>
          </a> */}
        </div>
      </section>
    );
  }
}
