import { add, remove } from 'core/indicator/pending-tasks-redux';
import apiClient from 'core/api';
import authService from 'core/auth';
import { addToaster } from 'core/toaster/redux/actions';
import { ID_MAPPING_FAILURE, ID_MAPPING_REQUEST, ID_MAPPING_SUCCESS } from './reducer';
import mapper from './mapper';
import { envs } from 'application/envHandler';

function request() {
  return {
    type: ID_MAPPING_REQUEST
  };
}

function success(payload: any) {
  return {
    type: ID_MAPPING_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: ID_MAPPING_FAILURE,
    payload
  };
}

export default function execute() {
  return async (dispatch: any) => {
    dispatch(request());
    dispatch(add('ID_MAPPING_REQUEST', {}));
    apiClient
      .get(`${envs.REACT_APP_API_URL}/id-mapping`, {
        headers: { opco: authService.getCurrentOpco() }
      })
      .then(
        response => {
          dispatch(remove('ID_MAPPING_REQUEST'));
          return dispatch(success(mapper(response.data)));
        },
        rejection => {
          dispatch(remove(ID_MAPPING_REQUEST));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: 'error_messages.VIPOC_ERROR_007',
              type: 'danger'
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}
