import {
  DvrState,
  DVR_FAILURE,
  DVR_SUCCESS,
  DVR_DELETE_RECORDING_SUCCESS,
  DVR_DELETE_RECORDING_FAILURE,
  DVR_CLEAR_CACHE,
  DVR_TIMELINE_RECORDINGS_SUCCESS,
  DVR_TIMELINE_TOGGLE
} from './types';

const initialState: DvrState = {
  items: undefined,
  totalElements: undefined,
  timelineItems: undefined,
  isToShowTimeline: false
};

interface DvrAction {
  type: string;
  payload: any;
}

export default function (state = initialState, { type, payload }: DvrAction) {
  switch (type) {
    case DVR_SUCCESS:
      return {
        ...state,
        items: payload.items,
        totalElements: payload.totalElements ? parseInt(payload.totalElements) : 0,
      };
    case DVR_DELETE_RECORDING_SUCCESS:
    case DVR_DELETE_RECORDING_FAILURE:
    case DVR_FAILURE:
      return { ...state };
    case DVR_CLEAR_CACHE:
      return initialState;
    case DVR_TIMELINE_RECORDINGS_SUCCESS:
      return {
        ...state,
        timelineItems: payload,
      };
    case DVR_TIMELINE_TOGGLE:
      return {
        ...state,
        isToShowTimeline: !state.isToShowTimeline,
      };
    default: {
      return { ...state };
    }
  }
}
