import React, { Component } from 'react';
import queryString from 'query-string';

interface LoginHandlerComponentProps {
  location: any;
  fetch: any;
}

export default class LoginHandlerComponent extends Component<LoginHandlerComponentProps> {
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.props.fetch(values.code);
  }

  render() {
    return <></>;
  }
}
