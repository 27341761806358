export const USER_SETTINGS_TOGGLE = 'USER_SETTINGS_TOGGLE';
export const USER_SETTINGS_ON_CHANGE_DROPDOWNS = 'USER_SETTINGS_ON_CHANGE_DROPDOWNS';
export const USER_SETTINGS_FETCH = 'USER_SETTINGS_FETCH';
export const USER_SETTINGS_FAILURE = 'USER_SETTINGS_FAILURE';
export const USER_SETTINGS_SUCCESS = 'USER_SETTINGS_SUCCESS';
export const USER_SETTINGS_UPDATE = 'USER_SETTINGS_UPDATE';
export const USER_SETTINGS_UPDATE_SUCCESS = 'USER_SETTINGS_UPDATE_SUCCESS';
export const USER_SETTINGS_UPDATE_FAILURE = 'USER_SETTINGS_UPDATE_FAILURE';
export const USER_SETTINGS_CREATE = 'USER_SETTINGS_CREATE';
export const USER_SETTINGS_CREATE_SUCCESS = 'USER_SETTINGS_CREATE_SUCCESS';
export const USER_SETTINGS_CREATE_FAILURE = 'USER_SETTINGS_CREATE_FAILURE';
export const USER_SETTINGS_HAS_CHANGED_OPCO_LANGUAGE = 'USER_SETTINGS_HAS_CHANGED_OPCO_LANGUAGE';
export interface UserSettingsAction {
  type: string;
  payload?: any;
}

export interface UserSettingsState {
  isActive: boolean;
  hasChanged: boolean;
  actualSettings?: ActualSettings;
  newSettings?: NewSettings;
}

export interface ActualSettings {
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  opco: string;
  language: string;
}

export interface NewSettings {
  opco: string;
  language: string;
}
