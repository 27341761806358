import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import {
  execute,
  selectEntry,
  sendResolution,
  clearPagination,
  cancelIngest
} from './redux/action';
import IdMappingCollisionComponent from './collision-mapping-component';
import { hasPendingTasks } from '../../../../core/indicator/pending-tasks-redux';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  application: state.application,
  results: state.management.idMapping.collision.results,
  id: ownProps.match.params.id,
  isFetching: hasPendingTasks(state.pendingTasks),
  pageNumber: state.management.idMapping.collision.pageNumber,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  execute,
  selectEntry,
  sendResolution,
  clearPagination,
  openModal,
  closeModal,
  cancelIngest
})(IdMappingCollisionComponent);
