import { Close } from 'assets/svg';
import { CardSizes } from 'core/card/enum';
import PopUpComponent from 'core/pop-up';
import { TFunction } from 'i18next';
import { togglePopUp } from 'modules/management/spectrum/dvb-management/popUp/redux/action';
import React, { Component } from 'react';

interface EditProps {
  t: TFunction;
  children: any;
  isActiveEdit?: boolean;
  onClose?: Function;
  size?: CardSizes;
  method: Function;
}

export default class EditComponent extends Component<EditProps, { isActive: boolean }> {
  constructor(props: EditProps) {
    super(props);
    // Don't call this.setState() here!
    this.state = { isActive: !!this.props.isActiveEdit };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isActiveEdit !== this.props.isActiveEdit) {
      this.setState({ isActive: !!this.props.isActiveEdit });
    }
  }

  clickClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { children, t, isActiveEdit, size, method } = this.props;

    return (
      <PopUpComponent isActive={this.state.isActive} size={CardSizes.SMALL}>
        <div className="columns is-flex is-justified-between">
          <div className="column is-narrow">
            <h5 className="text is-h5 is-bold is-marginless">{t('spectrum.button.edit')}</h5>
          </div>
          <div className="column flex-zero">
            <button className="button button-action" onClick={() => this.clickClose()}>
              <Close className="icon-svg icon-small" />
            </button>
          </div>
        </div>

        <div className="columns is-mobile flex-wrap">
          <div className="column is-12">
            <div className="">{children}</div>
          </div>
          <div className="column is-12 has-text-right">
            <button type="submit" className="button is-primary is-larger" onClick={() => method()}>
              {t('spectrum.button.save')}
            </button>
          </div>
        </div>
      </PopUpComponent>
    );
  }
}
