import { Dispatch } from "redux";
import { ANONYMIZER_KEY, hashesUrl } from "../consts";
import { RequestHashParams, HashResponseType } from "../types";
import { add, remove } from 'core/spinner/redux/actions'
import authService from 'core/auth/auth-service'
import apiClient from 'core/api'
import { addToaster } from 'core/toaster/redux/actions'

import { hashDeleteSuccess, hashFetchFailure, hashFetchSuccess } from "../redux/hashes/actions";

export function fetchHashById({ id, serviceId }: RequestHashParams) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()

    return apiClient.get(hashesUrl(`/id?id=${id}&svc=${serviceId}`)).then(
      (response: { data: HashResponseType }) => {
        const { result } = response.data
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(hashFetchSuccess({
          id,
          exp: result.exp,
          aid: result.aid,
        }))
      },
      (error) => {        
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(hashFetchFailure())
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: error.response.status === 404 ? 'error_messages.VIPOC_ERROR_065' : 'error_messages.VIPOC_ERROR_064',
            type: 'danger'
          })
        )
      },
    )
  }
}

export function fetchHashByAid({ id, serviceId }: RequestHashParams) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()

    return apiClient.get(hashesUrl(`/aid/${id}?svc=${serviceId}`)).then(
      (response: { data: HashResponseType }) => {
        const { result } = response.data
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(hashFetchSuccess({
          aid: id,
          exp: result.exp,
          id: result.id
        }))
      },
      (error) => {
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(hashFetchFailure())
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: error.response.status === 404 ? 'error_messages.VIPOC_ERROR_065' : 'error_messages.VIPOC_ERROR_064',
            type: 'danger'
          })
        )
      },
    )
  }
}

export function deleteHashById({ id, serviceId }: RequestHashParams) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()

    return apiClient.delete(hashesUrl(`/id?id=${id}&svc=${serviceId}`)).then(
      () => {        
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(hashDeleteSuccess())
        return dispatch(addToaster({
          title: 'home.icon_anonymizer',
          message: 'anonymizer.delete_hash_success',
          type: 'success'
        }))
      },
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: 'error_messages.VIPOC_ERROR_066',
            type: 'danger'
          })
        )
      },
    )
  }
}