export interface DeviceLogState {
  hasMore: boolean;
  content: Array<any>;
  pageNumber: number;
}

export interface DeviceLogItem {
  udid: string;
  household: string;
  deviceModel: string;
  lastManaged: string;
  eventName: string;
}

export const DEVICE_LOG_REQUEST = 'DEVICE_LOG_REQUEST';
export const DEVICE_LOG_SUCCESS = 'DEVICE_LOG_SUCCESS';
export const DEVICE_LOG_SUCCESS_CONCAT = 'DEVICE_LOG_SUCCESS_CONCAT';
export const DEVICE_LOG_FAILURE = 'DEVICE_LOG_FAILURE';

export interface DeviceLogRequestAction {
  type: typeof DEVICE_LOG_REQUEST;
}

export interface DeviceLogSuccessActionPayload {
  hasMore: boolean;
  content: Array<DeviceLogItem>;
}

export interface DeviceLogSuccessAction {
  type: typeof DEVICE_LOG_SUCCESS;
  payload: DeviceLogSuccessActionPayload;
}

export interface DeviceLogSuccessConcatAction {
  type: typeof DEVICE_LOG_SUCCESS_CONCAT;
  payload: DeviceLogSuccessActionPayload;
}

export interface DeviceLogFailureAction {
  type: typeof DEVICE_LOG_FAILURE;
  payload: any;
}

export type DeviceLogActionTypes =
  | DeviceLogRequestAction
  | DeviceLogSuccessAction
  | DeviceLogFailureAction
  | DeviceLogSuccessConcatAction;
