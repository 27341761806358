import apiClient from 'core/api';
import AuthService from 'core/auth';
import queryString, { createArrayToQueryString } from 'core/utils/query-string';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import {
  ACTIVITY_LOG_INFO,
  ACTIVITY_LOG_FAILURE,
  ACTIVITY_LOG_SUCCESS,
  ACTIVITY_LOG_CANCEL,
  ACTIVITY_LOG_CHANGE_PAGE,
  ACTIVITY_LOG_SET_COMMANDS_LIST
} from './types';
import { ApplicationState } from 'application/application-redux';
import { setListValues } from 'core/multiple-search/redux/action';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';
import { envs } from 'application/envHandler';

function failure(payload: any) {
  return {
    type: ACTIVITY_LOG_FAILURE,
    payload
  };
}

function success(data: any, startPage: number, endPage: number) {
  return {
    type: ACTIVITY_LOG_SUCCESS,
    payload: {
      data,
      startPage,
      endPage
    }
  };
}

function successCancel(payload: any) {
  return {
    type: ACTIVITY_LOG_CANCEL,
    payload
  };
}

function setCommandList(data: any) {
  return {
    type: ACTIVITY_LOG_SET_COMMANDS_LIST,
    payload: {
      data
    }
  };
}

export function fetch(id: string, commandList?: boolean) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(ACTIVITY_LOG_INFO, {}));
    await AuthService.refreshToken();
    const activityLog = getState().multipleSearch.multipleFilters.get('activityLog');

    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/device/${id}/activity-log/?${
          activityLog &&
          queryString(
            createArrayToQueryString(activityLog.selectedFilters),
            getState().application.offset
          )
        }`
      )
      .then(
        (response) => {
          dispatch(remove(ACTIVITY_LOG_INFO));
          const pagination = getState().pagination.pagination.get('activity_log');
          const pageSize = pagination && pagination.pageSize;
          if (pageSize) {
            dispatch(success(response.data, 0, pageSize));
          }

          dispatch(goToFirstPage('activity_log'));
          if (getState().devices.detail.activityLog.commandList === undefined || commandList) {
            dispatch(setCommandList(response.data));
          }

          return dispatch(setListValues(getState().devices.detail.activityLog['commandList']));
        },
        (rejection) => {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_activity_log',
              message: 'error_messages.VIPOC_ERROR_019',
              type: 'danger'
            })
          );
          dispatch(remove(ACTIVITY_LOG_INFO));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function cancelLog(traceId: string, id: string) {
  return async (dispatch: any) => {
    dispatch(add(ACTIVITY_LOG_CANCEL, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/device/${id}/activity-log`, {
        deviceId: id,
        traceId
      })
      .then(
        (response) => {
          dispatch(remove(ACTIVITY_LOG_CANCEL));
          dispatch(fetch(id));
          return dispatch(successCancel(response.data));
        },
        (rejection) => {
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_033';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_activity_log',
              message,
              type: 'danger'
            })
          );
          dispatch(remove(ACTIVITY_LOG_CANCEL));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function changePageActivityLog(startPage: any, endPage: any) {
  return {
    type: ACTIVITY_LOG_CHANGE_PAGE,
    payload: {
      startPage,
      endPage
    }
  };
}
