import {
  DVB_MANAGEMENT_SERVICE_SELECT,
  DVB_MANAGEMENT_SERVICES_SET,
  DVBService,
  DVBManagementServiceAction
} from './types';
import { ApplicationState } from 'application/application-redux';
import { buildSearch } from '../../search/redux/action';
import { mergeChanges } from '../action';
import { DVB_COLUMNS } from '../enums';

function action(type: any, payload?: any): DVBManagementServiceAction {
  return {
    type: type,
    payload: payload
  };
}

export function selectService(serviceId?: string) {
  return {
    type: DVB_MANAGEMENT_SERVICE_SELECT,
    payload: serviceId
  };
}

export function setServices(services?: Array<DVBService>, isToClear?: boolean, isSearch?: boolean) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    new Promise<void>(resolve => {
      dispatch(action(DVB_MANAGEMENT_SERVICES_SET, { services, isToClear, isSearch }));

      if (services) {
        dispatch(mergeChanges(DVB_COLUMNS.SERVICE));
      }

      resolve();
    }).then(() => {
      dispatch(buildSearch(getState().management.spectrum.dvbManagement));
    });
  };
}
