import { connect } from 'react-redux';
import { hasPendingTasksOf } from 'core/indicator/pending-tasks-redux';
import IdMappingFormComponent from './id-mapping-form-component';
import { open, close } from '../modal-upload/redux/actions';
import {
  show,
  populate,
  setValue,
  clear,
  clearAll,
  validateAndCreateQueryTag,
  deleteQuery
} from './redux/action';
import execute from '../grid/redux/action';
import { FILE_UPLOAD_ID_MAPPING_REQUEST } from '../modal-upload/redux/types';
import { ID_MAPPING_REQUEST } from '../grid/redux/reducer';

const mapStateToProps = (state: any, ownProps: any) => ({
  t: state.i18n.t,
  form: state.management.idMapping.form,
  fetchHistory: ownProps.fetchHistory,
  currentHistory: ownProps.currentHistory,
  isFetching:
    hasPendingTasksOf(FILE_UPLOAD_ID_MAPPING_REQUEST, state.pendingTasks) ||
    hasPendingTasksOf(ID_MAPPING_REQUEST, state.pendingTasks)
});

export default connect(mapStateToProps, {
  show,
  populate,
  setValue,
  clear,
  validateAndCreateQueryTag,
  deleteQuery,
  execute,
  open,
  close,
  clearAll
})(IdMappingFormComponent);
