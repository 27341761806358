import React from 'react';
import { IdMappingEditableResult } from './redux/reducer';
import { TFunction } from 'i18next';

type Props = {
  errorMessage: any;
  execute: any;
  isFetching: boolean;
  results: IdMappingEditableResult;
  selectEntry: any;
  removeEntry: any;
  saveEntry: any;
  changeEntry: any;
  submitChanges: any;
  openModal: any;
  closeModal: any;
  pageNumber: number;
  newEntry: number;
  t: TFunction;
};

export default class IdMappingTableComponent extends React.Component<Props> {
  private confirmAndRemove(event: any, index: number) {
    event.preventDefault();
    const command = () => {
      this.props.closeModal();
      this.props.removeEntry(index);
    };

    this.props.openModal({
      title: 'modal.title.confirm_entry_exclusion',
      active: false,
      content: `modal.content.confirm_action`,
      execute: command
    });
  }

  private changeEntry(event: any, key: string, index: number) {
    event.preventDefault();
    this.props.changeEntry(index, key, event.currentTarget.value);
  }

  render() {
    const { t } = this.props;
    if (this.props.isFetching) {
      return (
        <div className="loader-wrapper box">
          <div className="loader" />
        </div>
      );
    }

    if (
      !this.props.isFetching &&
      (!this.props.results.content || this.props.results.content.length === 0)
    ) {
      return (
        <div className="box" style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}>
          <p className="has-text-centered" style={{ verticalAlign: 'middle', lineHeight: '600px' }}>
            {t('id_mapping.error.entries_not_found')}
          </p>
        </div>
      );
    }

    const { primaryKey, secondaryKeys, columns } = this.props.results.schemaProperties;

    let requiredKeys: Array<string> = [];
    requiredKeys = requiredKeys.concat(primaryKey);
    requiredKeys = requiredKeys.concat(secondaryKeys);

    let notRequired = columns.filter(
      col => primaryKey !== col && secondaryKeys.indexOf(col) === -1
    );
    let keys = requiredKeys.concat(notRequired);

    const RequiredMark = () => <span style={{ color: '#e60000' }}> *</span>;

    let errorMessages: Array<string> = [];
    if (this.props.errorMessage) {
      errorMessages = Object.keys(this.props.errorMessage).map(key => {
        const value = this.props.errorMessage[key];

        const join = Object.keys(value)
          .map((m: any) => {
            return value[m].translation ? t(value[m].translation) : value[m].message;
          })
          .join('. ');

        return t('id_mapping.error.label_line') +' '+ (parseInt(key) + 1) + ': ' + join;
      });
    }

    return (
      <div>
        {errorMessages.length > 0 && (
          <div className="notification is-danger container">
            {errorMessages.map((message: string, index: number) => {
              return (
                <ul key={index}>
                  <li>{`${message}.`}</li>
                </ul>
              );
            })}
          </div>
        )}
        <div
          className="table-container box"
          style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
        >
          <table className="table is-fullwidth tableCollisions" id="table-container">
            <thead>
              <tr>
                <th>{t('settings.tab_actions')}</th>
                {keys &&
                  keys.map((key: string, index: number) => {
                    return (
                      <th key={index}>
                        <span>{key}</span>
                        {requiredKeys.indexOf(key) !== -1 && <RequiredMark />}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {this.props.results &&
                this.props.results.content.map((result: any, row: number) => {
                  if (result.entryStatus !== 'DELETED') {
                    return (
                      <tr key={row}>
                        <td>
                          <span
                            className="icon has-text-danger"
                            style={{ verticalAlign: 'middle' }}
                          >
                            <i
                              className="mdi mdi-24px mdi-delete"
                              style={{ cursor: 'pointer', color: '#d72c2c' }}
                              onClick={(e: any) =>
                                result.isNew === true
                                  ? this.props.removeEntry(row)
                                  : this.confirmAndRemove(e, row)
                              }
                            />
                          </span>
                          <span className="icon" style={{ verticalAlign: 'middle' }}>
                            {!result.editable && (
                              <i
                                className="mdi mdi-24px mdi-lock-outline"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.props.selectEntry(row)}
                              />
                            )}
                            {result.editable && (
                              <i
                                className="mdi mdi-24px mdi-lock-open-outline"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.props.saveEntry(row)}
                              />
                            )}
                          </span>
                        </td>
                        {keys &&
                          keys.map((key, columnIndex) => {
                            if (result.editable === true) {
                              return (
                                <td key={columnIndex}>
                                  <input
                                    className="input is-small"
                                    type="text"
                                    key={result.uuid}
                                    defaultValue={result.entry[key]}
                                    onChange={(e: any) => this.changeEntry(e, key, row)}
                                    style={{ height: '2.3125em' }}
                                  />
                                </td>
                              );
                            }
                            return (
                              <td key={columnIndex}>
                                <span style={{ height: '2.3125em' }}>{result.entry[key]}</span>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  }

                  return null;
                })}
            </tbody>
          </table>
        </div>
        <div className="columns">
          <div className="column" style={{ textAlign: 'center' }}>
            <nav
              className="pagination is-left"
              role="navigation"
              aria-label="pagination"
              style={{ marginBottom: '3em' }}
            >
              <button
                className="pagination-previous button is-dark"
                disabled={this.props.pageNumber < 1}
                onClick={(event: any) => this.props.execute(this.props.pageNumber - 1)}
              >
                Previous
              </button>
              <button
                className="pagination-next button is-primary"
                disabled={!this.props.results.hasNext}
                onClick={(event: any) => this.props.execute(this.props.pageNumber + 1)}
              >
                {t('general.next_page')}
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
