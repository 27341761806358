import React, { Component, Fragment } from 'react';
import { Calendar, Close } from 'assets/svg/index';
import { TFunction } from 'i18next';
import PopUpComponent from 'core/pop-up';
import DatePicker from 'core/date/datepicker';
import DateUtils from 'core/utils/date-utils';
import { DVBManagementState } from '../redux/types';
import { DVB_COLUMNS, DVB_FIELD_TYPES, DVB_ACTIONS } from '../redux/enums';
import {
  DVBPopUp,
  DVB_MANAGEMENT_POPUP_CHANGE_ITEM,
  DVB_MANAGEMENT_POPUP_SWITCH_MODE,
  FieldItem
} from './redux/types';
import { RequestsMainState } from '../../requests/redux/types';
import FileUploadComponent from 'core/file-upload';
import { CardSizes } from 'core/card/enum';
import { BagdeTypes } from 'core/badge/enum';

interface DVBManagementPopUpProps {
  action: (type: any, payload?: any) => void;
  dvbConstants: any;
  dvbManagement: DVBManagementState;
  popUp: DVBPopUp;
  requests: RequestsMainState;
  saveChanges: (changeId?: string) => void;
  selectDropdown: (dvbManagement: any, field: string, id: string) => void;
  setScheduleDate: (scheduleDate: any) => void;
  submitRequest: (requestId: string, scheduleDate: string) => void;
  t: TFunction;
  togglePopUp: (isActive: boolean) => void;
}

export default class DVBManagementPopUpComponent extends Component<DVBManagementPopUpProps> {
  onChangeInput(field: string, value: string) {
    const { action } = this.props;
    action(DVB_MANAGEMENT_POPUP_CHANGE_ITEM, {
      field,
      value
    });
  }

  buildFormPopUp = (size: string) => {
    const { action, dvbConstants, saveChanges, popUp, t, requests } = this.props;
    const mode = popUp.action || DVB_ACTIONS.VIEW;
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          popUp.item ? saveChanges(popUp.item.changeId) : saveChanges();
        }}
      >
        <div className="columns flex-wrap is-mobile">
          {popUp &&
            popUp.data.map((fieldItem: FieldItem, index: number) => {
              const constants = dvbConstants
                ? dvbConstants[fieldItem.key] || fieldItem.options
                : [];
              const oldItem = popUp.oldData?.find((item: any) => item.key === fieldItem.key);
              const showOldValue = oldItem?.value !== fieldItem.value;

              return (
                fieldItem.isActive &&
                (mode !== DVB_ACTIONS.CREATE ||
                  (mode === DVB_ACTIONS.CREATE && fieldItem.type !== DVB_FIELD_TYPES.READONLY)) && (
                  <div className={`column ${size}`} key={index}>
                    {fieldItem.label && (
                      <label className="label text is-bold">{t(fieldItem.label)}</label>
                    )}
                    {mode !== DVB_ACTIONS.VIEW &&
                      fieldItem.type !== DVB_FIELD_TYPES.READONLY &&
                      (fieldItem.type === 'dropdown' && constants && constants.length ? (
                        <div className="select is-fullwidth">
                          <select
                            name={fieldItem.key}
                            value={fieldItem.value}
                            onChange={e => this.onChangeInput(fieldItem.key, e.target.value)}
                          >
                            <option />
                            {constants.map((item: any, constIndex: number) => {
                              return (
                                <option key={constIndex} value={item.id || item.label}>
                                  {item.label || item.id}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : (
                        <input
                          type={fieldItem.type}
                          className="input"
                          name={fieldItem.key}
                          value={fieldItem.value || ''}
                          onChange={e => this.onChangeInput(fieldItem.key, e.target.value)}
                        />
                      ))}
                    {((mode === DVB_ACTIONS.UPDATE &&
                      fieldItem.type === DVB_FIELD_TYPES.READONLY) ||
                      mode === DVB_ACTIONS.VIEW) && (
                      <Fragment>
                        <p className={`text ${showOldValue ? 'is-highlighted is-bold' : ''}`}>
                          {fieldItem.value ? fieldItem.value : '-'}
                          {showOldValue && (
                            <span className="text is-grey is-size-sm has-margin-left-sm">
                              ({oldItem?.value})
                            </span>
                          )}
                        </p>
                      </Fragment>
                    )}
                  </div>
                )
              );
            })}
        </div>
        {requests.selectedRequest?.isRequestOpen && (
          <div className="columns is-right">
            {popUp.action === DVB_ACTIONS.VIEW &&
              popUp.item.canEditChange &&
              (requests.selectedRequest?.canEditOtherEntities || popUp.item.changeId) && (
                <div className="column is-narrow">
                  <button
                    type="button"
                    className="button is-tertiary is-larger"
                    onClick={() => action(DVB_MANAGEMENT_POPUP_SWITCH_MODE)}
                  >
                    {t('spectrum.button.edit')}
                  </button>
                </div>
              )}

            {popUp.action !== DVB_ACTIONS.VIEW && (
              <div className="column is-narrow">
                <button
                  type="submit"
                  className="button is-primary is-larger"
                  disabled={!popUp.hasChanged}
                >
                  {t('spectrum.button.save')}
                </button>
              </div>
            )}
          </div>
        )}
      </form>
    );
  };

  buildMovePopUp = () => {
    const { dvbManagement, saveChanges, selectDropdown, popUp, t } = this.props;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          saveChanges();
        }}
      >
        <div className="columns flex-wrap is-mobile">
          {popUp &&
            popUp.data.map((fieldItem: FieldItem, index: number) => {
              const dropdowns = fieldItem.options;

              return (
                <div className="column is-12" key={index}>
                  {fieldItem.label && (
                    <label className="label text is-bold">{t(fieldItem.label)}</label>
                  )}

                  <div className="select is-fullwidth">
                    <select
                      name={fieldItem.key}
                      onChange={e => {
                        this.onChangeInput(fieldItem.key, e.target.value);
                        selectDropdown(dvbManagement, fieldItem.key, e.target.value);
                      }}
                      disabled={!fieldItem.options || fieldItem.options.length === 0}
                      value={fieldItem.value}
                    >
                      <option />
                      {dropdowns &&
                        dropdowns.map(
                          (item: { key: string; value: string }, constIndex: number) => {
                            return (
                              <option key={constIndex} value={item.key}>
                                {item.value || item.key}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="columns is-right">
          <div className="column is-narrow">
            <button
              type="submit"
              className="button is-primary is-larger"
              disabled={!popUp.hasChanged}
            >
              {t('spectrum.button.save')}
            </button>
          </div>
        </div>
      </form>
    );
  };

  buildSchedulePopUp = () => {
    const { requests, submitRequest, t, togglePopUp } = this.props;
    const requestId = requests.selectedRequest?.requestId || '';
    const scheduleDate = requests.selectedRequest?.scheduleDate?.start || '';

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          submitRequest(requestId, scheduleDate || '');
          togglePopUp(false);
        }}
      >
        <div className="columns">
          <div className="column has-padding-top-none">
            <label className="label">{t('Date')}</label>

            <button
              type="button"
              className="button button-date-label"
              onClick={() => {
                document.getElementsByName('datepicker')[0].click();
              }}
            >
              {scheduleDate
                ? DateUtils.formatDate(scheduleDate, 'YYYY-MM-DD HH:mm')
                : t('general.select_date')}
              <Calendar className="icon icon-svg" />
            </button>
          </div>
        </div>
        <div className="columns is-right">
          <div className="column is-narrow">
            <button type="submit" className="button is-primary" disabled={!scheduleDate}>
              {t('spectrum.button.submit')}
            </button>
          </div>
        </div>
      </form>
    );
  };

  getBadgeType(item: any) {
    const { requests } = this.props;
    const type =
      !requests.selectedRequest?.isRequestOpen || (item && item.canEditChange)
        ? BagdeTypes.INFO
        : BagdeTypes.NONE;
    return item && item.error ? BagdeTypes.DANGER : type;
  }

  getColumnSize = (size: string) => {
    switch (size) {
      case 'is-small':
        return 'is-12';
      case 'is-large':
        return 'is-4';
      default:
        return 'is-6';
    }
  };

  getPopUp = (popUp: DVBPopUp) => {
    const mode = popUp.action || DVB_ACTIONS.NONE;

    switch (mode) {
      case DVB_ACTIONS.MOVE: {
        return this.buildMovePopUp();
      }

      case DVB_ACTIONS.CREATE:
      case DVB_ACTIONS.UPDATE:
      case DVB_ACTIONS.VIEW: {
        return this.buildFormPopUp(this.getColumnSize(popUp.size));
      }

      case DVB_ACTIONS.SCHEDULE: {
        return this.buildSchedulePopUp();
      }
      default:
        break;
    }
  };

  getPopUpTilte = (column: DVB_COLUMNS, action?: DVB_ACTIONS, data?: Array<FieldItem>) => {
    const { t } = this.props;
    let actiontranslation;

    switch (action) {
      case DVB_ACTIONS.CREATE:
        actiontranslation = t('spectrum.button.add');
        break;
      case DVB_ACTIONS.UPDATE:
        actiontranslation = t('spectrum.button.edit');
        break;
      case DVB_ACTIONS.MOVE:
        actiontranslation = t('spectrum.actions.move');
        break;
      case DVB_ACTIONS.SCHEDULE:
        return t('spectrum.button.schedule');
      default:
        actiontranslation = '';
        break;
    }

    const columnTranslation = actiontranslation
      ? t(`spectrum.${column}`).toLowerCase()
      : t(`spectrum.${column}`);

    return actiontranslation + ' ' + columnTranslation;
  };

  render() {
    const { popUp, requests, setScheduleDate, t, togglePopUp } = this.props;
    const request = requests.selectedRequest;
    let size: CardSizes;

    if (popUp.action === DVB_ACTIONS.MOVE || popUp.action === DVB_ACTIONS.SCHEDULE) {
      size = CardSizes.SMALL;
    } else {
      size = popUp.size;
    }

    return (
      popUp && (
        <Fragment>
          {popUp.action !== DVB_ACTIONS.UPLOAD ? (
            <PopUpComponent
              isActive={popUp.isActive}
              size={size}
              badge={{
                text: popUp.item?.changeLabel,
                type: this.getBadgeType(popUp.item)
              }}
            >
              <div className="columns is-flex is-justified-between">
                <div className="column is-narrow">
                  <h5 className="text is-h5 is-bold is-marginless">
                    {this.getPopUpTilte(popUp.column, popUp.action, popUp.data)}
                  </h5>
                </div>
                <div className="column flex-zero">
                  <button className="button button-action" onClick={() => togglePopUp(false)}>
                    <Close className="icon-svg icon-small" />
                  </button>
                </div>
              </div>

              {this.getPopUp(popUp)}
            </PopUpComponent>
          ) : (
            {
              /* <FileUploadComponent t={t} isActive={popUp && popUp.isActive}>
              <div className="text is-size-sm">
                <p>{t('spectrum.upload.label.size').replace('{0}', '10MB')}</p>
                <p>{t('spectrum.upload.label.data_format').replace('{0}', 'OIFP')}</p>
                <p>
                  {t('spectrum.upload.file_name').replace(
                    '{0}',
                    '[OpCoID]_dvbUpdate_[YYYY-MM-DDTHH:MM:SS].csv'
                  )}
                </p>
              </div>
            </FileUploadComponent> */
            }
          )}
          <DatePicker
            isMasked
            isRange={false}
            value={request?.scheduleDate}
            onChangePeriod={(scheduleDate: any) => setScheduleDate(scheduleDate)}
          />
        </Fragment>
      )
    );
  }
}
