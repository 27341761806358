import {
  ProvisionedDevicesState,
  ProvisionedDevicesAction,
  PROV_DEVICES_SUCCESS,
  PROV_DEVICES_CLEAR_CACHE,
  PROV_DEVICES_CHANGE_PAGE
} from './types';

const initialState: ProvisionedDevicesState = {
  items: undefined,
  totalElements: undefined,
  itemsToShow: undefined
};

export default function (state = initialState, action: ProvisionedDevicesAction) {
  switch (action.type) {
    case PROV_DEVICES_SUCCESS:
      state.items = action.payload.devices;
      state.totalElements = action.payload.devices.length;
      state.itemsToShow = state && state.items && state.items.slice(0, 5);
      return {
        ...state
      };
    case PROV_DEVICES_CLEAR_CACHE:
      return {
        ...state,
        items: undefined,
        totalElements: undefined,
        itemsToShow: undefined
      };
    case PROV_DEVICES_CHANGE_PAGE:
      return {
        ...state,
        itemsToShow:
          state.items && state.items.slice(action.payload.startPage, action.payload.endPage)
      };
    default:
      return state;
  }
}
