import React, { Component } from 'react';
import { CardSizes, CardPaddings } from 'core/card/enum';
import BadgeComponent from 'core/badge';
import './card-styles.scss';
import { BagdeTypes } from 'core/badge/enum';

interface CardProps {
  badge?: {
    text: string;
    type: BagdeTypes;
  };
  children: any;
  hasShadow?: boolean;
  size?: CardSizes;
  padding?: CardPaddings;
  isFullHeight?: boolean;
}

export default class CardComponent extends Component<CardProps> {
  render() {
    const { badge, children, hasShadow, isFullHeight, padding, size } = this.props;
    return (
      <div
        className={`card-wrapper 
        ${size && size}
        ${isFullHeight ? 'height-full' : ''}
      `}
      >
        <div
          className={`card 
          ${hasShadow ? 'card-shadow' : ''}
          ${padding ? `has-padding-${padding}` : ''}`}
        >
          {children}
        </div>
        <div>
          {badge && badge.text && (
            <BadgeComponent hasButton={false} type={badge.type} isAbsolute>
              {badge.text}
            </BadgeComponent>
          )}
        </div>
      </div>
    );
  }
}
