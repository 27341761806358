import React, { Component } from 'react';
import AuthService from 'core/auth';
import T from 'core/i18n/t';
import { TFunction } from 'i18next';
import PopUpComponent from '../../../../core/pop-up';

interface UploadModalComponentProps {
  isActive: boolean;
  title: string;
  content: string;
  close: any;
  execute: any;
  uploadFile: any;
  setFileValue: any;
  disabledUpload: boolean;
  dataUpload: FormData;
  fileNameToUpload: string;
  ingestType: string;
  fileType: string;
  setFileType: any;
  t: TFunction;
}

export default class ModalIdMappingComponent extends Component<UploadModalComponentProps> {
  async sendFile() {
    const { close, uploadFile, dataUpload } = this.props;
    uploadFile(dataUpload);
    close();
  }

  handleFileChosen(event: any) {
    const { setFileValue, dataUpload } = this.props;
    dataUpload.delete('file');
    dataUpload.append('file', event.target.files[0]);
    setFileValue(dataUpload);
    event.target.value = null;
  }

  changeFileType(event: any) {
    const { setFileType } = this.props;
    setFileType(event.target.value);
    event.target.value = null;
  }

  render() {
    const { isActive, title, close, disabledUpload, fileNameToUpload, fileType, t } = this.props;
    return (
      <PopUpComponent isActive={isActive}>
        <h4 className="title is-4">{t(title)}</h4>
        <div className="content">
          <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
            {t('id_mapping.label_upload_for_opco')}
            <strong>
              <h2 className="subtitle is-4" style={{ display: 'inline', marginLeft: '8px' }}>
                {AuthService.getCurrentOpco()}
              </h2>
            </strong>
          </h3>

          <div className="column control" style={{ padding: 0, marginTop: '10px' }}>
            <div className="is-flex field">
              <div className="column" style={{ marginLeft: 0, padding: 0 }}>
                <label className="label is-small">{t('id_mapping.label_choose_file_type')}</label>
                <div className="control">
                  <div className="select is-small" style={{ minWidth: '100%' }}>
                    <select
                      value={fileType}
                      style={{ minWidth: '100%' }}
                      onChange={event => this.changeFileType(event)}
                    >
                      <option>CSV</option>
                      <option>XLS</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="is-flex field">
              <div className="file has-name is-primary" style={{ display: 'inline-block' }}>
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    onChange={event => this.handleFileChosen(event)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="mdi mdi-file-document" />
                    </span>
                    <span>{t('id_mapping.label_choose_file')}</span>
                  </span>
                  <span className="file-name" style={{ maxWidth: '12em' }}>
                    {t(fileNameToUpload)}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <button
            className="button is-primary"
            disabled={disabledUpload}
            onClick={() => this.sendFile()}
          >
            <span>{t('id_mapping.label_upload')}</span>
            <span className="icon has-margin-left-sm">
              <i className="mdi mdi-cloud-upload" />
            </span>
          </button>
          <button className="button is-secondary" onClick={close}>
            <T>general.cancel</T>
          </button>
        </div>
      </PopUpComponent>
    );
  }
}
