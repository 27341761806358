import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import { PaginationRequest } from 'core/pagination/pagination-component';
import { addToaster } from 'core/toaster/redux/actions';
import {
  IP_GEOLOCATION_FAILURE,
  IP_GEOLOCATION_REQUEST,
  IP_GEOLOCATION_SUCCESS,
  IP_GEOLOCATION_EXPORT_FAILURE,
  IP_GEOLOCATION_EXPORT_REQUEST,
  IP_GEOLOCATION_EXPORT_SUCCESS,
  IP_GEOLOCATION_FILTER_ADDRESSESS
} from './reducer';
import { envs } from 'application/envHandler';

export function request() {
  return {
    type: IP_GEOLOCATION_REQUEST
  };
}

function success(payload: any) {
  return {
    type: IP_GEOLOCATION_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: IP_GEOLOCATION_FAILURE,
    payload
  };
}

function exportRequest() {
  return {
    type: IP_GEOLOCATION_EXPORT_REQUEST
  };
}

function exportSuccess(payload: any) {
  return {
    type: IP_GEOLOCATION_EXPORT_SUCCESS,
    payload
  };
}

function exportFailure(payload: any) {
  return {
    type: IP_GEOLOCATION_EXPORT_FAILURE,
    payload
  };
}

export function execute(page?: PaginationRequest) {
  return async (dispatch: any) => {
    dispatch(request());
    dispatch(add('IP_GEOLOCATION_REQUEST', {}));
    const pagination = page
      ? `pageNumber=${page.page}&pageSize=${page.size}`
      : 'pageNumber=0&pageSize=30';
    apiClient.get(`${envs.REACT_APP_API_URL}/ip-geolocation/whitelist?${pagination}`).then(
      response => {
        dispatch(remove('IP_GEOLOCATION_REQUEST'));
        return dispatch(success(response.data));
      },
      rejection => {
        dispatch(remove('IP_GEOLOCATION_REQUEST'));
        dispatch(
          addToaster({
            title: 'management.ip_geolocation',
            message: 'error_messages.VIPOC_ERROR_008',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function exportData() {
  return async (dispatch: any) => {
    dispatch(exportRequest());
    dispatch(add('IP_GEOLOCATION_EXPORT_REQUEST', {}));
    apiClient.post(`${envs.REACT_APP_API_URL}/ip-geolocation/whitelist/export`).then(
      response => {
        dispatch(remove('IP_GEOLOCATION_EXPORT_REQUEST'));
        const zipName = 'CDNWhitelistExport.zip';
        const a = document.createElement('a');
        a.href = `data:application/zip;base64,${response.data}`;
        a.download = zipName;
        a.click();
        return dispatch(exportSuccess(''));
      },
      rejection => {
        dispatch(remove('IP_GEOLOCATION_EXPORT_REQUEST'));
        dispatch(
          addToaster({
            title: 'management.ip_geolocation',
            message: 'error_messages.VIPOC_ERROR_009',
            type: 'danger'
          })
        );
        return dispatch(exportFailure(rejection));
      }
    );
  };
}

export function onFilter(filter: string) {
  return {
    type: IP_GEOLOCATION_FILTER_ADDRESSESS,
    payload: filter
  };
}
