import React, { Component } from 'react';
import DateUtils from 'core/utils/date-utils';
import { TFunction } from 'i18next';
import { EsnMappingResultRow } from './redux/reducer';

interface EsnMappingGridComponentProps {
  results: Array<EsnMappingResultRow>;
  t: TFunction;
}

export default class EsnMappingGridComponent extends Component<EsnMappingGridComponentProps> {
  render() {
    const { t, results } = this.props;
    return (
      <div className="paper table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th colSpan={7} className="subtitle is-7">
                {t('esn_mapping.last_ten_ingests')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="has-text-grey-light has-text-weight-light">
                {t('esn_mapping.column_id')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('esn_mapping.column_status')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('esn_mapping.column_message')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('esn_mapping.column_start_time')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('esn_mapping.column_end_time')}
              </th>
            </tr>

            {results &&
              results.map((result: EsnMappingResultRow, index: number) => {
                return (
                  <tr key={index}>
                    <td>{result.id}</td>
                    <td>{result.status}</td>
                    <td>{result.message}</td>
                    <td>{DateUtils.formatDate(result.startTime)}</td>
                    <td>{DateUtils.formatDate(result.endTime)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}
