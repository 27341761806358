import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import LogsHistoryComponent from './logs-history-component';
import { fetchLogsHistory, onChangeLogsHistoryPage, generateURL, resetURL } from './redux/actions';
import { resetTableState } from 'core/table/redux/actions';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t,
  logsHistory: state.devices.detail.logsHistory,
  table: state.table
});

export default connect(mapStateToProps, {
  fetchLogsHistory,
  onChangeLogsHistoryPage,
  generateURL,
  resetURL,
  resetTableState
})(LogsHistoryComponent);
