import { connect } from 'react-redux';
import { open } from 'core/modal/redux/actions';
import { getThreshhold } from './redux/actions';

import DeviceDetailHardDiskComponent from './device-hard-disk-component';

const mapStateToProps = (state: any, ownProps: any) => ({
  hardDisk: state.devices.detail.hardDisk,
  general: state.devices.detail.general,
  hhid: ownProps.hhid,
  id: ownProps.id,
  t: state.i18n.t
});

export default connect(mapStateToProps, { open, getThreshhold })(DeviceDetailHardDiskComponent);
