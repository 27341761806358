export const OVERLAY_MODAL_OPEN = 'OVERLAY_MODAL_OPEN';
export const OVERLAY_MODAL_CLOSE = 'OVERLAY_MODAL_CLOSE';

export interface OverlayModalState {
  active: boolean;
  content?: string;
  execute?: any;
  size?: 'is-small' | 'is-large';
  title?: string;
  children?: JSX.Element | Array<JSX.Element> | string
}

export interface OverlayModalAction {
  type: typeof OVERLAY_MODAL_CLOSE | typeof OVERLAY_MODAL_OPEN;
  payload: any;
}

export type OpenModalFunction = (options: OverlayModalState) => {
  type: string;
  payload: OverlayModalState;
}

export type CloseModalFunction = () => {
    type: string;
}