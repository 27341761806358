export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CHANGE_ACCORDION_PAGE = 'CHANGE_ACCORDION_PAGE';
export const CLEAR_ACCORDION_TABLE = 'CLEAR_ACCORDION_TABLE';
export const CLEAR_CACHE_ACCORDION_TABLE = 'CLEAR_CACHE_ACCORDION_TABLE';

export interface TableAccordionConfig {
  keys: String[];
  shownIndex: number;
  tableItems: any;
  tableItemsToShow: any;
}

export interface TableAccordionState {
  accordion: Map<string, TableAccordionConfig>;
}

export interface TableAccordionAction {
  type: string;
  payload?: any;
}