import {
  ADD_TOASTER,
  ADD_TOASTER_TIMEOUT,
  ADD_TOOLTIP,
  DEACTIVATE_TOASTER,
  REMOVE_TOASTER,
  ToasterAction,
  ToasterMessage,
  ToasterObject
} from './types';

export const addToaster = (toaster: ToasterMessage): ToasterAction => ({
  type: ADD_TOASTER,
  payload: {
    toaster
  }
});

export const addTooltip = (toaster: ToasterMessage): ToasterAction => ({
  type: ADD_TOOLTIP,
  payload: {
    toaster
  }
});

export const addTimeout = (toaster: ToasterObject): ToasterAction => ({
  type: ADD_TOASTER_TIMEOUT,
  payload: {
    toaster
  }
});

export const removeToaster = (toaster: ToasterObject): ToasterAction => ({
  type: REMOVE_TOASTER,
  payload: {
    toaster
  }
});

export const deactivateToaster = (toaster: ToasterObject): ToasterAction => ({
  type: DEACTIVATE_TOASTER,
  payload: {
    toaster
  }
});
