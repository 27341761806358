import { CarouselAction, CarouselState, CAROUSEL_SET_INDEX } from './types';

const initialState: CarouselState = {
  activeIndex: 0
};

export default function(state: CarouselState = initialState, action: CarouselAction) {
  switch (action.type) {
    case CAROUSEL_SET_INDEX:
      return { ...state, activeIndex: action.payload.index };
    default:
      return { ...state };
  }
}
