import { add, remove } from 'core/spinner/redux/actions';

import queryString from 'core/utils/query-string';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { addToaster } from 'core/toaster/redux/actions';
import { TFunction } from 'i18next';
import { ApplicationState } from 'application/application-redux';
import apiClient from '../../../../../../core/api/client';
import AuthService from '../../../../../../core/auth/auth-service';
import {
  CLEAN_SCAN_RESULT,
  CHANNEL_SCAN_FAILURE,
  CHANNEL_SCAN_SUCCESS,
  CHANNEL_SCAN,
  TOGGLE_ACCORDION,
  CHANNEL_WAITING_COMMAND,
  CHANNEL_SCAN_STATUS
} from './types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

let timerTimeout: any;
let timerInterval: any;

function waitingCommand(payload: any) {
  return {
    type: CHANNEL_WAITING_COMMAND,
    payload
  };
}

function failure(payload: any) {
  return {
    type: CHANNEL_SCAN_FAILURE,
    payload
  };
}

function success(payload: any) {
  return {
    type: CHANNEL_SCAN_SUCCESS,
    payload
  };
}

function status(payload: any) {
  return {
    type: CHANNEL_SCAN_STATUS,
    payload
  };
}

export function cleanChannelsResult() {
  return {
    type: CLEAN_SCAN_RESULT
  };
}

export function toggleAccordion(index: number) {
  return {
    type: TOGGLE_ACCORDION,
    payload: index
  };
}

export function stopChannelScan() {
  return async (dispatch: any) => {
    clearInterval(timerInterval);
    clearTimeout(timerTimeout);
    dispatch(waitingCommand(false));
    dispatch(remove(CHANNEL_SCAN));
    return dispatch(failure({}));
  };
}

function isFinal(status: string) {
  switch (status) {
    case 'COMPLETED':
    case 'SUCCEEDED':
    case 'FAILED':
    case 'TIME_EXCEEDED':
    case 'CANCELED':
    case 'REJECTED':
      return true;
    default:
      return false;
  }
}

export function fetch(id: string, filter?: PaginationConfig) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(CHANNEL_SCAN_SUCCESS, {}));
    await AuthService.refreshToken();
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/device/${id}/channel/history?${queryString(
          filter,
          getState().application.offset
        )}`
      )
      .then(
        response => {
          dispatch(remove(CHANNEL_SCAN_SUCCESS));
          dispatch(success(response.data));
        },
        () => {
          dispatch(remove(CHANNEL_SCAN_SUCCESS));
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_channels',
              message: 'error_messages.VIPOC_ERROR_044',
              type: 'danger'
            })
          );
          return dispatch(failure({}));
        }
      );
  };
}

function doFetchChannelScanStatus(
  dispatch: any,
  id: string,
  jobId: string,
  filter?: PaginationConfig
) {
  apiClient
    .get(`${envs.REACT_APP_API_URL}/command/runChannelScan/device/${id}/status`, {
      headers: { transactionID: jobId }
    })
    .then(
      secondResponse => {
        let initialStatus: any = { ...secondResponse.data };
        if (isFinal(secondResponse.data.status)) {
          initialStatus = { ...secondResponse.data, request: { message: {}, progress: '0%' } };

          if (secondResponse.data.status === 'TIME_EXCEEDED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_channels',
                message: 'error_messages.VIPOC_ERROR_042',
                type: 'danger'
              })
            );
          } else if (secondResponse.data.status === 'FAILED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_channels',
                message: secondResponse.data.response.errorText,
                type: 'danger'
              })
            );

            dispatch(status(initialStatus));
          } else if (
            secondResponse.data.status === 'SUCCEEDED' ||
            secondResponse.data.status === 'COMPLETED'
          ) {
            dispatch(fetch(id, filter));
          }

          clearInterval(timerInterval);
          clearTimeout(timerTimeout);
          dispatch(waitingCommand(false));
        }
        dispatch(remove(CHANNEL_SCAN));
        return dispatch(status(initialStatus));
      },
      secondRejection => {
        dispatch(remove(CHANNEL_SCAN));
        return dispatch(failure(secondRejection));
      }
    );
}

export function fetchChannelScanStatus(id: string, jobId: string, filter?: PaginationConfig) {
  return async (dispatch: any) => {
    timerTimeout = setTimeout(() => {
      clearInterval(timerInterval);
      dispatch(waitingCommand(false));
      dispatch(remove(CHANNEL_SCAN));
    }, 1900000);
    doFetchChannelScanStatus(dispatch, id, jobId, filter);
    timerInterval = setInterval(() => doFetchChannelScanStatus(dispatch, id, jobId, filter), 5000);
  };
}

export function fetchChannelScan(id: string, filter?: PaginationConfig) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(CHANNEL_SCAN, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: 'runChannelScan',
        deviceId: id,
        jsonParameters: {"responseTopic": "RM;channelscanresult"}
      })
      .then(
        response => {
          if (response.data.httpCode === '400') {
            handleCommandError(
              dispatch,
              response.data.responseJson.jobId,
              'dvr.column_channel',
              getState().i18n.t,
              'CHANNEL_SCAN',
              waitingCommand
            );
          } else {
            dispatch(waitingCommand(true));
            dispatch(fetchChannelScanStatus(id, response.data.responseJson.jobId, filter));
          }
        },
        rejection => {
          dispatch(remove('CHANNEL_SCAN'));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_021';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_channels',
              message,
              type: 'danger'
            })
          );
          dispatch(waitingCommand(false));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function cancelCommand(traceId: string, deviceId: string, t: TFunction) {
  return async (dispatch: any) => {
    dispatch(add(CHANNEL_SCAN, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/device/${deviceId}/activity-log`, {
        deviceId,
        traceId
      })
      .then(
        () => {},
        rejection => {
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_033';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_activity_log',
              message,
              type: 'danger'
            })
          );
        }
      );
  };
}
