import {
  DVB_MANAGEMENT_NODE_SELECT,
  DVB_MANAGEMENT_NODES_SET,
  DVBNode,
  DVBManagementNodesAction,
  DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION
} from './types';
import { selectNetwork, setNetworks } from '../redux-networks/action';
import { ApplicationState } from 'application/application-redux';
import { mergeChanges } from '../action';
import { DVB_COLUMNS } from '../enums';

function action(type: any, payload?: any): DVBManagementNodesAction {
  return {
    type: type,
    payload: payload
  };
}

export function selectNode(nodeId?: string) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    dispatch(setNetworks(undefined, true));
    dispatch(selectNetwork());
    dispatch(action(DVB_MANAGEMENT_NODE_SELECT, nodeId));

    const selectedNodeChildren =
      getState().management.spectrum.dvbManagement.node.selected?.children || [];
    dispatch(setNetworks(selectedNodeChildren && nodeId ? selectedNodeChildren : undefined));
  };
}

export function setNodes(
  nodes?: Array<DVBNode>,
  regionId?: string,
  isToClear?: boolean,
  isSearch?: boolean
) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    const isRegionalization = getState().management.spectrum.dvbManagement.node.isRegionalization;

    dispatch(
      action(DVB_MANAGEMENT_NODES_SET, {
        nodes,
        regionId,
        isToClear,
        isSearch,
        isRegionalization
      })
    );
    if (nodes) {
      dispatch(mergeChanges(DVB_COLUMNS.NODE));
    }
  };
}

export function setIsRegionalization(responseNode?: boolean) {
  return function (dispatch: Function) {
    dispatch(action(DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION, responseNode));
  };
}
