export const CHANNEL_SCAN = 'CHANNEL_SCAN';
export const CHANNEL_SCAN_STATUS = 'CHANNEL_SCAN_STATUS';
export const CHANNEL_SCAN_SUCCESS = 'CHANNEL_SCAN_SUCCESS';
export const CHANNEL_SCAN_FAILURE = 'CHANNEL_SCAN_FAILURE';
export const CLEAN_SCAN_RESULT = 'CLEAN_SCAN_RESULT';
export const TOGGLE_ACCORDION = 'TOGGLE_ACCORDION';
export const CHANNEL_WAITING_COMMAND = 'CHANNEL_WAITING_COMMAND';

export type CHANNEL_SCAN = typeof CHANNEL_SCAN;
export type CHANNEL_SCAN_STATUS = typeof CHANNEL_SCAN_STATUS;
export type CHANNEL_SCAN_SUCCESS = typeof CHANNEL_SCAN_SUCCESS;
export type CHANNEL_SCAN_FAILURE = typeof CHANNEL_SCAN_FAILURE;
export type CLEAN_SCAN_RESULT = typeof CLEAN_SCAN_RESULT;
export type TOGGLE_ACCORDION = typeof TOGGLE_ACCORDION;
export type CHANNEL_WAITING_COMMAND = typeof CHANNEL_WAITING_COMMAND;

export interface ChannelScanAction {
  type:
    | CHANNEL_SCAN
    | CHANNEL_SCAN_STATUS
    | CHANNEL_SCAN_SUCCESS
    | CHANNEL_SCAN_FAILURE
    | CLEAN_SCAN_RESULT
    | TOGGLE_ACCORDION
    | CHANNEL_WAITING_COMMAND;
  payload?: any;
}

export interface ChannelScanRowResult {
  frequency: string;
  symbrate: string;
  nrChannels: string;
  regionID: string;
  type: string;
}

export interface ChannelScanResult {
  accordionActive: boolean;
  accrodionIndex: number;
  channelHistories: Array<ChannelScanRowResult>;
  finishedAt: string;
  totalElements: number;
}

export interface ChannelScanState {
  results: Array<ChannelScanResult>;
  activityLog: any;
  waitingCommandExecution: boolean;
}

export interface ChannelScanFilter {
  pageSize: number;
  pageNumber: number;
  order: string;
}
