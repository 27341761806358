import React, { Component, Fragment } from 'react';
import CountUp from 'react-countup';
import T from 'core/i18n/t';
import TaskPanelComponent from 'core/job-panel';
import './speed-test-styles.scss';
import { SpeedTestState } from './redux/types';
import TableComponent from 'core/table/table-container';
import mapper, { getLabels } from './redux/mapper';
import { SearchState } from 'core/search/redux/types';
import { paginationSpeedTest } from 'core/pagination-optimized/redux/config';
import { TFunction } from 'i18next';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import Topbar from '../topbar/topbar-container';
import { GeneralDeviceState } from '../general/redux/types';

interface SpeedTestComponentProps {
  speedTest: SpeedTestState;
  search: SearchState;
  id: string;
  onSearch: (id: string, t: TFunction, filter?: PaginationConfig, comparator?: string) => void;
  onStartSpeedTest: (id: string, t: TFunction) => void;
  t: TFunction;
  waitingCommandExecution: boolean;
  cancelCommand: (traceId: string, deviceId: string, t: TFunction) => void;
  activityLog: any;
  general: GeneralDeviceState
}
export default class SpeedTestComponent extends Component<SpeedTestComponentProps> {
  componentDidMount() {
    const { onSearch, search } = this.props;

    const currentSearch = search.filters.get('speed_test');
    if ( !(currentSearch && currentSearch.currentFilter.value) ){
      onSearch(this.props.id, this.props.t, paginationSpeedTest);
    }
  }

  render() {
    const { cancelCommand, id, onSearch, speedTest, t, general } = this.props;
    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical">
          <div className="tile is-parent box flex-zero has-margin-bottom-md">
            <Info {...this.props} />
          </div>

          {speedTest.activityLog && speedTest.activityLog.status !== 'SUCCEEDED' && (
            <ul className="panel has-margin-bottom-md">
              <TaskPanelComponent
                item={speedTest.activityLog}
                showProgressBar
                cancel={cancelCommand}
                t={t}
                hideCancelButton={general?.shadow?.reported?.software?.os === 'android'}
              />
            </ul>
          )}

          <TableComponent
            id="speed_test"
            items={
              this.props.speedTest && this.props.speedTest.results
                ? this.props.speedTest.results.map(mapper)
                : []
            }
            columns={getLabels(this.props.t)}
            title={`${t('speed_test.subtitle_history')}`}
            onChangePageSize={(filter: PaginationConfig, days?: number, comparator?: string) =>
              this.props.speedTest && onSearch(this.props.id, this.props.t, filter, comparator)
            }
            onSearch={(filter: PaginationConfig, days?: number, comparator?: string) =>
              this.props.speedTest && onSearch(this.props.id, this.props.t, filter, comparator)
            }
            pagination={paginationSpeedTest}
            totalElements={
              this.props.speedTest &&
              this.props.speedTest.results &&
              this.props.speedTest.results[0] &&
              this.props.speedTest.results[0].totalElements
            }
            sortableItems={[]}
          />
        </div>
      </Fragment>
    );
  }
}

const Info = (props: any) => (
  <div className="tile is-child">
    <SpeedTestUserQuotaInfo title="User Quota" {...props} />
  </div>
);

class SpeedTestUserQuotaInfo extends Component<SpeedTestComponentProps> {
  render() {
    const { id, onStartSpeedTest, speedTest, t } = this.props;
    return (
      <ul>
        <p className="title is-6 has-margin-sm">
          <button
            className="button is-primary"
            onClick={() => onStartSpeedTest(id, t)}
            disabled={speedTest.waitingCommandExecution}
          >
            <span>
              <T>speed_test.button_start_test</T>
            </span>
          </button>
        </p>
        <li>
          <nav className="level div-counter-speedtest">
            <div className="level-item has-text-centered">
              <div>
                <p className="title">
                  <CountUp
                    className="custom-count"
                    duration={2}
                    start={0}
                    end={parseFloat(speedTest.download)}
                    decimals={3}
                    decimal="."
                  />
                  <span className="is-size-7">MBps</span>
                </p>
                <p className="heading is-capitalized has-text-weight-bold subtitle-user-quota">
                  <T>speed_test.label_download</T>
                </p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="title">
                  <CountUp
                    className="custom-count"
                    duration={2}
                    start={0}
                    end={parseFloat(speedTest.latency)}
                  />
                  <span className="is-size-7">ms</span>
                </p>
                <p className="heading is-capitalized has-text-weight-bold subtitle-user-quota">
                  <T>speed_test.label_latency</T>
                </p>
              </div>
            </div>
          </nav>
        </li>
      </ul>
    );
  }
}