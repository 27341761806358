export interface AuthTokenState {
  isFetching: boolean;
  content?: AuthTokenItem;
}

export interface AuthTokenItem {
  idToken: string;
  refreshToken: string;
  expireDate: string;
  tokenType: string;
  username: string;
}

export const AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST';
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS';
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE';

export interface AuthTokenRequestAction {
  type: typeof AUTH_TOKEN_REQUEST;
}

export interface AuthTokenSuccessAction {
  type: typeof AUTH_TOKEN_SUCCESS;
  payload: AuthTokenItem;
}

export interface AuthTokenFailureAction {
  type: typeof AUTH_TOKEN_FAILURE;
  payload: any;
}

export type AuthTokenActionTypes =
  | AuthTokenRequestAction
  | AuthTokenSuccessAction
  | AuthTokenFailureAction;
