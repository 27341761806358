import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import InputComponent from './input-component';
import { addSelectedFilters, updateInput } from '../../multiple-search/redux/action';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t
});

export default connect(mapStateToProps, { addSelectedFilters, updateInput })(InputComponent);
