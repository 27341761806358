import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import ItemsPerPageComponent from './items-per-page-component';
import { changePageSize } from "../pagination-optimized/redux/actions";


const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t,
  pagination: state.pagination.pagination
});

export default connect(mapStateToProps, {
  changePageSize
})(ItemsPerPageComponent);