import { combineReducers } from 'redux';
import deviceAdvancedSearchHistory from '../history/redux';
import {
  AdvancedSearchAction,
  AdvancedSearchState,
  ADVANCED_SEARCH_SUCCESS,
  ADVANCED_SEARCH_CHANGE_PAGE
} from './types';

const initialState: AdvancedSearchState = {
  results: [],
  resultsToShow: [],
  totalElements: 0,
  lastEvaluatedData: ''
};


function advancedSearchExecutionReducer(state = initialState, action: AdvancedSearchAction) {
  const { startItem, endItem, isToConcat, data, lastEvaluated = '' } = action.payload || {}; 
  switch (action.type) {
    case ADVANCED_SEARCH_SUCCESS: {
      const allResults =  isToConcat ? state.results.concat(data) : [...state.results];
      const resultsToShow = isToConcat
          ? allResults.slice(startItem, endItem)
          : data.slice(startItem, endItem);

      const totalElements = isToConcat ? allResults.length : data.length;

      
      return {
        ...state,
        results: isToConcat ? [...allResults] : [...data],
        resultsToShow: [...resultsToShow],
        totalElements: totalElements,
        lastEvaluatedData: lastEvaluated || ''
      };
    }

    case ADVANCED_SEARCH_CHANGE_PAGE:
      return {
        ...state,
        resultsToShow: state.results.slice(startItem, endItem)
      };

    default: {
      return { ...state };
    }
  }
}

export default combineReducers({
  execution: advancedSearchExecutionReducer,
  history: deviceAdvancedSearchHistory
});
