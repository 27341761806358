import { Field } from './types';
import { DVB_COLUMNS, DVB_FIELD_TYPES } from './enums';
import { DVBPopUp } from '../popUp/redux/types';
import { CardSizes } from 'core/card/enum';

export const searchFields: Array<Field> = [
  {
    column: DVB_COLUMNS.REGION,
    fields: [
      { key: 'regionId', label: 'spectrum.region.id', value: '', isActive: false },
      { key: 'regionLabel', label: 'spectrum.region.label', value: '', isActive: false }
    ]
  },
  {
    column: DVB_COLUMNS.NODE,
    fields: [
      { key: 'nodeId', label: 'spectrum.node.id', value: '', isActive: false },
      { key: 'nodeLabel', label: 'spectrum.node.label', value: '', isActive: false },
      {
        key: 'childRegionId',
        label: 'spectrum.node.child_region_id',
        value: '',
        isActive: false
      }
    ]
  },
  {
    column: DVB_COLUMNS.NETWORK,
    fields: [{ key: 'onId', label: 'spectrum.network.id', value: '', isActive: false }]
  },
  {
    column: DVB_COLUMNS.STREAM,
    fields: [{ key: 'tsId', label: 'spectrum.stream.id', value: '', isActive: false }]
  },
  {
    column: DVB_COLUMNS.SERVICE,
    fields: [
      { key: 'serviceId', label: 'spectrum.service.id', value: '', isActive: false },
      { key: 'serviceName', label: 'spectrum.service.name', value: '', isActive: false }
    ]
  }
];

export const popUpData: Array<DVBPopUp> = [
  {
    column: DVB_COLUMNS.REGION,
    data: [
      {
        key: 'id',
        label: 'spectrum.region.id',
        value: undefined,
        type: DVB_FIELD_TYPES.READONLY,
        isActive: true
      },
      {
        key: 'label',
        label: 'spectrum.region.label',
        value: undefined,
        type: DVB_FIELD_TYPES.TEXT,
        isActive: true
      },
      {
        key: 'parentRegionId',
        label: 'spectrum.region.parent_region_id',
        value: undefined,
        type: DVB_FIELD_TYPES.READONLY,
        isActive: true
      },
      {
        key: 'rootId',
        value: undefined,
        isActive: false
      }
    ],
    size: CardSizes.SMALL
  },
  {
    column: DVB_COLUMNS.NODE,
    data: [
      {
        key: 'nodeId',
        label: 'spectrum.node.id',
        value: undefined,
        type: DVB_FIELD_TYPES.TEXT,
        isActive: true
      },
      {
        key: 'nodeLabel',
        label: 'spectrum.node.label',
        value: undefined,
        type: DVB_FIELD_TYPES.TEXT,
        isActive: true
      },
      {
        key: 'childRegionId',
        label: 'spectrum.node.child_region_id',
        value: undefined,
        type: DVB_FIELD_TYPES.READONLY,
        isActive: true
      },
      {
        key: 'regionId',
        value: undefined,
        isActive: false
      },
      {
        key: 'rootId',
        value: undefined,
        isActive: false
      }
    ],
    size: CardSizes.SMALL
  },
  {
    column: DVB_COLUMNS.NETWORK,
    data: [
      {
        key: 'onId',
        label: 'spectrum.network.id',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'rootId',
        value: undefined,
        isActive: false
      }
    ],
    size: CardSizes.SMALL
  },
  {
    column: DVB_COLUMNS.STREAM,
    data: [
      {
        key: 'tsId',
        label: 'spectrum.stream.id',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'dvbType',
        label: 'spectrum.stream.dvb_type',
        value: undefined,
        type: DVB_FIELD_TYPES.DROPDOWN,
        isActive: true
      },
      {
        key: 'mod',
        label: 'spectrum.stream.modulation',
        value: undefined,
        type: DVB_FIELD_TYPES.DROPDOWN,
        isActive: true
      },
      {
        key: 'freq',
        label: 'spectrum.stream.frequency',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'symRate',
        label: 'spectrum.stream.symbol_rate',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'rootId',
        value: undefined,
        isActive: false
      }
    ],
    size: CardSizes.NONE
  },
  {
    column: DVB_COLUMNS.SERVICE,
    data: [
      {
        key: 'serviceId',
        label: 'spectrum.service.id',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'serviceName',
        label: 'spectrum.service.name',
        value: undefined,
        type: DVB_FIELD_TYPES.TEXT,
        isActive: true
      },
      {
        key: 'serviceType',
        label: 'spectrum.service.type',
        value: undefined,
        type: DVB_FIELD_TYPES.DROPDOWN,
        isActive: true
      },

      {
        key: 'mediaId',
        label: 'spectrum.service.media_id',
        value: undefined,
        type: DVB_FIELD_TYPES.TEXT,
        isActive: true
      },
      {
        key: 'lcn',
        label: 'spectrum.service.lcn',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'lcn2',
        label: 'spectrum.service.lcn2',
        value: undefined,
        type: DVB_FIELD_TYPES.NUMBER,
        isActive: true
      },
      {
        key: 'scramble',
        label: 'spectrum.service.scramble',
        value: undefined,
        type: DVB_FIELD_TYPES.DROPDOWN,
        isActive: true
      },
      {
        key: 'eitPresFlwFlag',
        label: 'spectrum.service.eitPresFlwFlag',
        value: undefined,
        type: DVB_FIELD_TYPES.DROPDOWN,
        isActive: true
      },
      {
        key: 'rootId',
        value: undefined,
        isActive: false
      }
    ],
    size: CardSizes.LARGE
  }
];
