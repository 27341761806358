import {
  TOGGLE_ITEM,
  DELETE_ITEM,
  CHANGE_ACCORDION_PAGE,
  CLEAR_ACCORDION_TABLE,
  CLEAR_CACHE_ACCORDION_TABLE
} from './types';

export function toggleItem(id: string, index: number, items: any, pagination: any) {
  return {
    type: TOGGLE_ITEM,
    payload: {
      id,
      index,
      items,
      pagination
    }
  };
}

export function deleteItem(item: any) {
  return {
    type: DELETE_ITEM,
    payload: {
      item
    }
  };
}

export function onChangeAccordionPage(id: string) {
  return {
    type: CHANGE_ACCORDION_PAGE,
    payload: {
      id
    }
  };
}

export function clearAccordionTable(id: string) {
  return {
    type: CLEAR_ACCORDION_TABLE,
    payload: {
      id
    }
  };
}

export function clearAccordionCache() {
  return {
    type: CLEAR_CACHE_ACCORDION_TABLE
  };
}
