export const FILTERS_SLIDER_UPDATE_DAYS = 'FILTERS_SLIDER_UPDATE_DAYS';
export const FILTERS_TOGGLE = 'FILTERS_TOGGLE';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const SET_CURRENT_FILTER_VALUE = 'SET_CURRENT_FILTER_VALUE';
export const SET_CURRENT_COMPARATOR_VALUE = 'SET_CURRENT_COMPARATOR_VALUE';
export const TRANSLATE_FILTER = 'TRANSLATE_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const ADD_FILTERS = "ADD_FILTERS" ;
export const SET_TYPE_FILTER_VALUE = "SET_TYPE_FILTER_VALUE"
export const RESET_FILTER = 'RESET_FILTER'

export interface MoreFilters {
  state: boolean;
  days: number;
}
export interface SearchFilter {
  filterTypes: Array<{ name: string; value: string; type: string }>;
  currentFilter: {
    type: {
      name: string;
      value: any;
      type: string;
      listValues?: Array<string>;
      translateField?: boolean;
    };
    value?: any;
  };
  moreFilters?: MoreFilters;
  numberFilters?: Array<ComparatorType>;
  showComparators?: boolean;
  currentComparator?: string;
}

export interface SearchState {
  filters: Map<string, SearchFilter>;
}

export interface SearchItem {
  name: string;
  value: string;
  type: string;
  listValues?: Array<string>;
  translateField?: boolean;
  label?: string
}

export interface ComparatorType {
  name: string;
  value: string;
}

export interface FilterAction {
  type: string;
  payload: any;
}

export interface InitialFilterMap {
  [key: string]: SearchFilter |
  Array<{
    name: string;
    value: string;
    type: string;
  }>
}
