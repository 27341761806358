import React, { Component, Fragment } from 'react';
import TableComponent from 'core/table/table-container';
import { TFunction } from 'i18next';
import { PaginationConfig, PaginationState } from 'core/pagination-optimized/redux/types';
import { paginationProvDevices } from 'core/pagination-optimized/redux/config';
import { ProvisionedDeviceItem, ProvisionedDevicesState } from './redux/types';
import mapper, { labelsProvDevices } from './redux/mapper';
import Topbar from '../topbar/topbar-container';
import AccountSecurityComponent from '../account-security/account-security-container';
import PaginationComponent from 'core/pagination-optimized/pagination-container';
import CardComponent from 'core/card/card-component';
import { CardPaddings } from 'core/card/enum';
import SearchComponent from 'core/search/search-container';
import ItemsPerPageComponent from 'core/items-per-page/items-per-page-container';
import './provisioned-devices-style.scss';

interface ProvisionedDevicesProps {
  t: TFunction;
  provisionedDevices: ProvisionedDevicesState;
  fetchProvisionedDevices: (hhid: string, filter?: PaginationConfig) => void;
  id: string;
  hhid: string;
  onDelete: (unmanagedDeviceId: string, udid: string, hhid: string) => void;
  pagination: PaginationState;
  changePageProvDevices: (startPage: Number, endPage: Number) => void;
}

export default class ProvisionedDevicesComponent extends Component<ProvisionedDevicesProps> {
  componentDidMount(): void {
    const { fetchProvisionedDevices, hhid, provisionedDevices } = this.props;
    if (provisionedDevices.items === undefined) {
      fetchProvisionedDevices(hhid);
    }
  }

  onChangeProvDevices() {
    const { changePageProvDevices, provisionedDevices, pagination } = this.props;

    const pages = pagination && pagination.pagination.get('account.provisioned_devices');

    if (pages) {
      changePageProvDevices(
        pages.pageSize * pages.pageNumber,
        pages.pageSize * (pages.pageNumber + 1)
      );
    }
  }


  render() {
    const { t, provisionedDevices, fetchProvisionedDevices, id, hhid, onDelete, pagination } =
      this.props;

    const pag = pagination && pagination.pagination.get('account.provisioned_devices');

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical width-full">
          <AccountSecurityComponent id={id} />

          <CardComponent padding={CardPaddings.NONE}>
            <div className="flex-topbar">
              <h4 className="title is-size-4 has-padding-top-md has-padding-left-xs">
                {t('account.subtitle_provisioned_devices')}

              </h4>

              <ItemsPerPageComponent
                id={'account.provisioned_devices'}
                onChangeAction={() => this.onChangeProvDevices()}
                option={[5, 10, 20]}
              />
            </div>

            <div>
              <SearchComponent
                id={'account.provisioned_devices'}
                items={provisionedDevices && provisionedDevices.itemsToShow}
                onSearch={(filter: any) => fetchProvisionedDevices(hhid, filter)}

                pagination={pag as PaginationConfig}
                hasOnlyFilter
              />
            </div>
            <div className="no-drop-shadow">
              <TableComponent
                id="account.provisioned_devices"
                onDelete={(item: ProvisionedDeviceItem) => onDelete(item.udid, id, hhid)}
                
                onDeleteMessage={t('modal.content.delete.hhid')}
                hasAction
                items={
                  provisionedDevices && provisionedDevices.itemsToShow
                    ? provisionedDevices.itemsToShow.map(mapper.bind(this, t))
                    : []
                }
                columns={labelsProvDevices(t)}

                pagination={paginationProvDevices}
                sortableItems={
                  provisionedDevices &&
                  provisionedDevices.items &&
                  provisionedDevices.items.map(mapper.bind(this, t))
                }
              />
            </div>
            <PaginationComponent
              id="account.provisioned_devices"
              pageSize={(pag && pag.pageSize) || 5}
              totalElements={(provisionedDevices && provisionedDevices.totalElements) || 5}
              onChange={() => this.onChangeProvDevices()}
            />
          </CardComponent>
        </div>
      </Fragment>
    );
  }
}
