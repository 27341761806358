import { v4 as uuid } from 'uuid';

export const ID_MAPPING_COLLISION_REQUEST = 'ID_MAPPING_COLLISION_REQUEST';
export const ID_MAPPING_COLLISION_SUCCESS = 'ID_MAPPING_COLLISION_SUCCESS';
export const ID_MAPPING_COLLISION_FAILURE = 'ID_MAPPING_COLLISION_FAILURE';
export const ID_MAPPING_RESOLUTION_REQUEST = 'ID_MAPPING_RESOLUTION_REQUEST';
export const ID_MAPPING_RESOLUTION_SUCCESS = 'ID_MAPPING_RESOLUTION_SUCCESS';
export const ID_MAPPING_RESOLUTION_FAILURE = 'ID_MAPPING_RESOLUTION_FAILURE';
export const ID_MAPPING_SELECT_ENTRY = 'ID_MAPPING_SELECT_ENTRY';
export const CLEAR_ID_COLLISION_PAGINATION = 'CLEAR_ID_COLLISION_PAGINATION';
interface IdMappingCollisionState {
  results: IdMappingCollisionResult;
  pageNumber: number;
}

interface IdMappingCollisionAction {
  type: string;
  payload: IdMappingCollisionResult | any;
}

const initialState: IdMappingCollisionState = {
  results: {
    content: [],
    hasNext: false,
    schemaProperties: {
      primaryKey: '',
      secondaryKeys: [],
      columns: []
    }
  },
  pageNumber: 0
};

export interface IdMappingCollisionResult {
  content: Array<IdMappingCollisionResultRow>;
  hasNext: boolean;
  schemaProperties: {
    primaryKey: string;
    secondaryKeys: Array<string>;
    columns: Array<string>;
  };
}

export interface IdMappingCollisionResultRow {
  collision: any;
  mappings: Array<any>;
  selected: any;
}

export default function(state = initialState, action: IdMappingCollisionAction) {
  switch (action.type) {
    case CLEAR_ID_COLLISION_PAGINATION: {
      return { ...initialState };
    }

    case ID_MAPPING_COLLISION_SUCCESS: {
      const payload = {
        ...action.payload,
        content: appendSelectionControls(action.payload.content),
        hasNext: action.payload.hasNext
      };

      return {
        ...state,
        results: payload,
        disableSendResolution: true,
        pageNumber: action.payload.pageNumber
      };
    }
    case ID_MAPPING_RESOLUTION_SUCCESS: {
      return { ...state };
    }

    case ID_MAPPING_SELECT_ENTRY: {
      return {
        ...state,
        results: {
          ...state.results,
          content: state.results.content.map((resultRow: IdMappingCollisionResultRow) => {
            if (
              resultRow.collision.uuid === action.payload.uuid ||
              resultRow.mappings.filter(mapping => mapping.uuid === action.payload.uuid).length > 0
            ) {
              return { ...resultRow, selected: action.payload.uuid };
            }
            return { ...resultRow };
          })
        }
      };
    }

    default: {
      return { ...state };
    }
  }
}

function appendSelectionControls(content: Array<IdMappingCollisionResultRow>) {
  return content.map((resultRow: IdMappingCollisionResultRow) => {
    const collisionUuid = uuid();
    const parsedCollision = { ...JSON.parse(resultRow.collision), uuid: collisionUuid };
    const parsedMappings = resultRow.mappings.map(mapping => ({
      ...JSON.parse(mapping),
      uuid: uuid()
    }));

    return {
      selected: collisionUuid,
      collision: parsedCollision,
      mappings: parsedMappings
    };
  });
}
