import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import authService from 'core/auth';
import {
  ID_MAPPING_EDITABLE_LIST_REQUEST,
  ID_MAPPING_EDITABLE_LIST_SUCCESS,
  ID_MAPPING_EDITABLE_LIST_FAILURE,
  ID_MAPPING_UNLOCK_ENTRY,
  ID_MAPPING_LOCK_ENTRY,
  ID_MAPPING_EDITABLE_REMOVE_ENTRY,
  ID_MAPPING_MANAGEMENT_ADD_NEW_ENTRY,
  ID_MAPPING_EDITABLE_CHANGE_ENTRY,
  ID_MAPPING_SET_FILTER,
  ID_MAPPING_SET_FILTER_VALUE,
  ID_MAPPING_MANAGEMENT_CLEAR,
  IdMappingRow
} from './reducer';
import { envs } from 'application/envHandler';

export function clear() {
  return {
    type: ID_MAPPING_MANAGEMENT_CLEAR
  };
}

function request() {
  return {
    type: ID_MAPPING_EDITABLE_LIST_REQUEST
  };
}

function success(payload: any) {
  return {
    type: ID_MAPPING_EDITABLE_LIST_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: ID_MAPPING_EDITABLE_LIST_FAILURE,
    payload
  };
}

export function selectEntry(index: number) {
  return {
    type: ID_MAPPING_UNLOCK_ENTRY,
    payload: index
  };
}

export function saveEntry(index: number) {
  return {
    type: ID_MAPPING_LOCK_ENTRY,
    payload: index
  };
}

export function changeEntry(index: number, key: string, value: string) {
  return {
    type: ID_MAPPING_EDITABLE_CHANGE_ENTRY,
    payload: {
      index,
      key,
      value
    }
  };
}

export function newEntry(index: number) {
  return {
    type: ID_MAPPING_MANAGEMENT_ADD_NEW_ENTRY,
    payload: index
  };
}

export function removeEntry(index: number) {
  return {
    type: ID_MAPPING_EDITABLE_REMOVE_ENTRY,
    payload: index
  };
}

export function execute(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    dispatch(request());
    dispatch(add(ID_MAPPING_EDITABLE_LIST_REQUEST, {}));
    const filters =
      getState().management.idMapping.editable.currentFilter &&
      getState().management.idMapping.editable.currentFilterValue
        ? `&filter=${getState().management.idMapping.editable.currentFilter}&filterValue=${
            getState().management.idMapping.editable.currentFilterValue
          }`
        : '';
    apiClient
      .get(
        `${
          envs.REACT_APP_API_URL
        }/id-mapping/management?pageSize=10&pageNumber=${pageNumber || 0}${filters}`,
        { headers: { opco: authService.getCurrentOpco() } }
      )
      .then(
        response => {
          dispatch(remove(ID_MAPPING_EDITABLE_LIST_REQUEST));
          return dispatch(success(response.data));
        },
        rejection => {
          dispatch(remove(ID_MAPPING_EDITABLE_LIST_REQUEST));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: 'error_messages.VIPOC_ERROR_007',
              type: 'danger'
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}

export function submitChanges(result: Array<IdMappingRow>) {
  return async (dispatch: any, getState: any) => {
    const data = {
      entries: result.map((c: any) => ({
        entry: JSON.stringify({ ...c.entry }),
        entryStatus: c.entryStatus,
        oldId: c.oldId
      }))
    };
    dispatch(request());
    dispatch(add(ID_MAPPING_EDITABLE_LIST_REQUEST, {}));
    apiClient
      .post(`${envs.REACT_APP_API_URL}/id-mapping/management/change/`, data, {
        headers: { opco: authService.getCurrentOpco() }
      })
      .then(
        () => {
          dispatch(clear());
          dispatch(execute(getState().management.idMapping.editable.results.pageNumber || 0));

          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: 'alert_info_messages.VIPOC_INFO_003',
              type: 'success'
            })
          );
        },
        rejection => {
          dispatch(remove(ID_MAPPING_EDITABLE_LIST_REQUEST));
          let errorMsg: string = 'error_messages.VIPOC_ERROR_046';
          if (rejection.response && rejection.response.data) {
            if (rejection.response.status === 422) {
              errorMsg = 'error_messages.VIPOC_ERROR_047';
              if (rejection.response.data && rejection.response.data.errors) {
                const mappingErrors = rejection.response.data.errors[0];

                if (mappingErrors) {
                  dispatch(
                    addToaster({
                      title: 'home.icon_id_mapping',
                      message: errorMsg,
                      type: 'danger'
                    })
                  );

                  return dispatch(failure({ error: mappingErrors, status: 422 }));
                }
              }
            }
          }

          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: errorMsg,
              type: 'danger'
            })
          );
          return dispatch(failure({ error: rejection, status: rejection.response.status }));
        }
      );
  };
}

export function setFilter(filter: any) {
  return {
    type: ID_MAPPING_SET_FILTER,
    payload: filter
  };
}

export function setFilterValue(filterValue: any) {
  return {
    type: ID_MAPPING_SET_FILTER_VALUE,
    payload: filterValue
  };
}
