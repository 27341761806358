import {
  ProgressBarAction,
  PROGRESS_BAR_CHANGE,
  PROGRESS_BAR_CLOSE,
  PROGRESS_BAR_ACTIVE
} from './types';

export function action(type: any, payload?: any): ProgressBarAction {
  return {
    type,
    payload
  };
}

export function handleProgress(progress: number) {
  return { type: PROGRESS_BAR_CHANGE, payload: progress };
}

export function handleClose(close: boolean) {
  return { type: PROGRESS_BAR_CLOSE, payload: close };
}

export function handleOpen(isActive: boolean) {
  return { type: PROGRESS_BAR_ACTIVE, payload: isActive };
}
