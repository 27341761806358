import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import queryString from 'core/utils/query-string';
import { add, remove } from 'core/spinner/redux/actions';
import {
  CHANNEL_GET_SUCCESS,
  CHANNEL_GET_FAILURE,
  CHANNEL_GET,
  ChannelsAction,
  Channels,
  CHANNEL_SELECT,
  KALTURA_GET
} from './type';
import { PROGRESS_BAR_CHANGE } from 'core/progress-bar/redux/types';
import { addToaster } from 'core/toaster/redux/actions';
import { getChannelNodes } from '../../channels-associations/redux/redux-nodes/action';
import { getChannelRegions } from '../../channels-associations/redux/redux-regions/action';
import { Sort } from 'core/table/redux/types';
import { ApplicationState } from 'application/application-redux';
import { handleClose } from 'core/progress-bar/redux/action';
import { clearTableCache, updateSort } from 'core/table/redux/actions';
import { filterMapper } from 'core/table/types';
import { envs } from 'application/envHandler';

export function action(type: any, payload?: any): ChannelsAction {
  return {
    type,
    payload
  };
}

export interface SpectrumChannelsAssociationParam {
  eligible?: boolean;
  clearCache?: boolean;
  pageNumber?: number;
  filter?: any;
  sort?: Sort;
  channelId: number;
}
export interface SpectrumChannelsParam {
  pageNumber?: number;
  filter?: any;
  removeSpinner?: boolean;
  sort?: Sort;
}

export interface SprectrumChannelsPutRegionalLCN {
  value: any;
  id?: number;
  channelId: number;
  type?: string;
}

function parseFilter(filter: any): any {
  const parsedFilters: any = {
    number: (filter?.channelNumber && filter?.channelNumber[0]) || '',
    numberEnd: (filter?.channelNumber && filter?.channelNumber[1]) || '',
    name: filter?.channelName || '',
    mediaId: filter?.mediaId || '',
    deviceType: filter?.deviceType || '',
    level: filter?.level || ''
  };

  return Object.assign(
    {},
    ...Object.keys(parsedFilters)
      .filter((key) => parsedFilters[key])
      .map((key) => ({ [key]: parsedFilters[key] }))
  );
}

function parseSort(sort?: Sort): string {
  if (!sort || sort.column == '' || sort.order == '') {
    return '';
  }
  return `${sort.column}-${sort.order.toUpperCase()}`;
}

async function requestChannels(
  pageNumber?: number,
  filter?: any,
  sort?: Sort,
  offset?: any
): Promise<{ result: Channels[]; totalCount: number }> {
  const opco = AuthService.getCurrentOpco();
  await AuthService.refreshToken();
  const response = await apiClient.get(
    `${envs.REACT_APP_API_URL}/be/spectrum/channels/opcos/${opco}?pageSize=10&pageIndex=${
      pageNumber ? pageNumber : 1
    }&${queryString(parseFilter(filter), offset)}&ordering=${parseSort(sort)}&user=${
      AuthService.getCurrentUser().username
    }`
  );
  return response.data;
}

export function getChannels(params: SpectrumChannelsParam) {
  const spinner = params.removeSpinner ? false : true;

  return async (dispatch: any, getState: () => ApplicationState) => {
    if (spinner) {
      dispatch(add(CHANNEL_GET, {}));
    }

    try {
      const response = await requestChannels(
        params.pageNumber,
        params.filter ||
          filterMapper(getState().search.filters.get('spectrum.channels')?.currentFilter),
        params.sort,
        getState().application.offset
      );
      params.sort && dispatch(updateSort('spectrum.channels', params.sort));
      return dispatch(
        action(CHANNEL_GET_SUCCESS, {
          data: response.result,
          totalCount: response.totalCount,
          t: getState().i18n.t
        })
      );
    } catch (err) {
      return dispatch(action(CHANNEL_GET_FAILURE, err));
    } finally {
      if (spinner) {
        dispatch(remove(CHANNEL_GET));
      }
    }
  };
}

export function getChannel(id: number) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    try {
      dispatch(add(CHANNEL_GET, {}));
      const response = await requestChannels(1, { mediaId: id });

      if (response.result.length > 0) {
        const channel = response.result[0];
        dispatch(
          action(CHANNEL_SELECT, {
            data: channel
          })
        );
      }
    } catch (err) {
      dispatch(action(CHANNEL_GET_FAILURE, err));
    } finally {
      dispatch(remove(CHANNEL_GET));
    }
  };
}

function formatedValue(item?: any, type?: string, typeFunction?: string) {
  const columnsSelect = item.selectedRows
    .filter((row: any) => {
      return row.selected == true;
    })
    .map((e: any) => {
      switch (typeFunction) {
        case 'associate': {
          if (type == 'nodes') {
            return {
              id: e.rowId,
              regionalLCN: e.field
            };
          } else {
            return {
              regionId: e.rowId,
              regionalLCN: e.field
            };
          }
        }
        case 'disassociate': {
          if (type == 'nodes') {
            return {
              id: e.rowId,
              regionalLCN: e.field
            };
          } else {
            return {
              regionId: e.rowId
            };
          }
        }
      }
    });
  const value =
    type == 'nodes'
      ? {
          nodes: [...columnsSelect]
        }
      : {
          regions: [...columnsSelect]
        };
  if (columnsSelect.length == 0) {
    return undefined;
  } else {
    return value;
  }
}

export function putRegionalLCN(params: SprectrumChannelsPutRegionalLCN) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const opco = AuthService.getCurrentOpco();
    const valueLCN = {
      channels: [
        {
          regionalLCN: parseInt(params.value)
        }
      ]
    };
    await AuthService.refreshToken();
    apiClient
      .put(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/${params.type}/${params.id}/channel/${params.channelId}`,
        valueLCN
      )
      .then(
        (response) => {
          dispatch(
            addToaster({
              title: 'spectrum.nodes.column_regional_lcn',
              message: response.data.message,
              type: 'success'
            })
          );
          return dispatch(
            params.type == 'node'
              ? getChannelNodes({ channelId: params.channelId })
              : getChannelRegions({ channelId: params.channelId })
          );
        },
        (rejection) => {
          return dispatch(
            addToaster({
              title: 'spectrum.nodes.column_regional_lcn',
              message: rejection.response.data.message,
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {});
  };
}

export function disassociate(channelId: number, item?: any, type?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const opco = AuthService.getCurrentOpco();
    const value = formatedValue(item, type, 'disassociate');
    let level = '';

    if (type) {
      level = type.slice(0, -1);
    }

    if (value == undefined) {
      return dispatch(
        addToaster({
          title: 'spectrum.error',
          message: getState()
            .i18n.t('spectrum.channels.selected.message')
            .replace(
              '{0}',
              getState().i18n.t(type == 'regions' ? 'spectrum.region' : 'spectrum.node')
            ),
          type: 'danger'
        })
      );
    }
    await AuthService.refreshToken();
    apiClient
      .delete(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/channel/${channelId}/${type}`,
        value
      )
      .then(
        (response) => {
          dispatch(
            addToaster({
              title: getState()
                .i18n.t('spectrum.title.message_disassociate')
                .replace(
                  '{0}',
                  getState().i18n.t(type == 'regions' ? 'spectrum.regions' : 'spectrum.nodes')
                ),
              message: 'spectrum.message_disassociate',
              type: 'success'
            })
          );
          dispatch(clearTableCache(type == 'nodes' ? 'spectrum.nodes' : 'spectrum.regions'));
          return dispatch(
            type == 'nodes'
              ? getChannelNodes({ channelId: channelId })
              : getChannelRegions({ channelId: channelId })
          );
        },
        (rejection) => {
          return dispatch(
            addToaster({
              title: getState()
                .i18n.t('spectrum.title.message_disassociate')
                .replace(
                  '{0}',
                  getState().i18n.t(type == 'regions' ? 'spectrum.regions' : 'spectrum.nodes')
                ),
              message: rejection.response.data.message,
              type: 'danger'
            })
          );
        }
      );
  };
}

export function associate(channelId: number, item?: any, type?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const opco = AuthService.getCurrentOpco();
    const value = formatedValue(item, type, 'associate');
    let level = '';

    if (type) {
      level = type.slice(0, -1);
    }

    if (value == undefined) {
      return dispatch(
        addToaster({
          title: 'spectrum.error',
          message: getState()
            .i18n.t('spectrum.channels.selected.message')
            .replace(
              '{0}',
              getState().i18n.t(type == 'regions' ? 'spectrum.region' : 'spectrum.node')
            ),
          type: 'danger'
        })
      );
    }
    await AuthService.refreshToken();
    apiClient
      .post(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/channel/${channelId}/${type}`,
        value
      )
      .then(
        (response) => {
          item.selectedRows.forEach((element: any) => { element.selected = false});
          dispatch(
            addToaster({
              title: getState()
                .i18n.t('spectrum.title.message_associate')
                .replace(
                  '{0}',
                  getState().i18n.t(type == 'regions' ? 'spectrum.regions' : 'spectrum.nodes')
                ),
              message: 'spectrum.message_associate',
              type: 'success'
            })
          );
          return dispatch(
            type == 'nodes'
              ? getChannelNodes({ channelId, eligible: true })
              : getChannelRegions({ channelId, eligible: true })
          );
        },
        (rejection) => {
          return dispatch(
            addToaster({
              title: getState()
                .i18n.t('spectrum.title.message_associate')
                .replace(
                  '{0}',
                  getState().i18n.t(type == 'regions' ? 'spectrum.regions' : 'spectrum.nodes')
                ),
              message: rejection.response.data.message,
              type: 'danger'
            })
          );
        }
      );
  };
}

export function getDownloadChannels() {
  const opcoId = 'vfde';
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(
      addToaster({
        title: 'spectrum.title.message_download',
        message: 'spectrum.download.message_preparing',
        type: 'info'
      })
    );

    await AuthService.refreshToken();
    apiClient.get(`${envs.REACT_APP_API_URL}/be/spectrum/download/opcos/${opcoId}`).then(
      (response) => {
        window.open(response.data.preSignedURL, '_self');
        return dispatch(
          addToaster({
            title: 'spectrum.title.message_download',
            message: '',
            type: 'success'
          })
        );
      },
      (rejection) => {
        return dispatch(
          addToaster({
            title: 'spectrum.title.message_download',
            message: rejection.data?.message || '',
            type: 'danger'
          })
        );
      }
    );
  };
}

export function getKalturaSync() {
  const opcoId = AuthService.getCurrentOpco();
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(
      action(KALTURA_GET, {
        isActive: true
      })
    );
    await AuthService.refreshToken();
    apiClient
      .get(`${envs.REACT_APP_API_URL}/be/spectrum/channels/opcos/${opcoId}/channelsSync`)
      .then(
        async () => {
          const response = await dispatch(getChannels({ pageNumber: 0, removeSpinner: true }));
          if (response) {
            dispatch(
              action(KALTURA_GET, {
                isActive: false
              })
            );
            dispatch(
              addToaster({
                title: 'spectrum.button_kaltura_sync',
                message: 'spectrum.kaltura_sync.successful_channels_msg',
                type: 'success'
              })
            );
          }
        },
        (rejection) => {
          dispatch(handleClose(true));
          dispatch(
            action(KALTURA_GET, {
              isActive: false
            })
          );
          return dispatch(
            addToaster({
              title: 'spectrum.button_kaltura_sync',
              message: rejection.response.data.message,
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {});
  };
}
