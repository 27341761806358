import React, { Component, Fragment } from 'react';
import TableComponent from 'core/table/table-container';
import { TFunction } from 'i18next';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { paginationSubscriptions } from 'core/pagination-optimized/redux/config';
import { SubscriptionsState } from './redux/types';
import Topbar from '../topbar/topbar-container';
import AccountSecurityComponent from '../account-security/account-security-container';
import mapper, { getLabels } from './redux/mapper';

interface SubscriptionsProps {
  t: TFunction;
  subscriptions: SubscriptionsState;
  fetchSubscriptions: (hhid: string, filter: PaginationConfig) => void;
  id: string;
  hhid: string;
}

export default class SubscriptionsComponent extends Component<SubscriptionsProps> {
  componentDidMount(): void {
    const { t, fetchSubscriptions, hhid, subscriptions } = this.props;
    if (subscriptions.items === undefined) {
      fetchSubscriptions(hhid, paginationSubscriptions);
    }
  }

  render() {
    const { t, subscriptions, fetchSubscriptions, id, hhid } = this.props;

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical width-full">
          <AccountSecurityComponent id={id} />

          <TableComponent
            id="account.subscriptions"
            items={
              subscriptions && subscriptions.items
                ? subscriptions.items.map((sub) => {
                    return mapper(sub, t);
                  })
                : []
            }
            title={`${t('all_device_pages.menu_subscriptions')}`}
            columns={getLabels(t)}
            onChangePageSize={(filter: any) => fetchSubscriptions(hhid, filter)}
            onSearch={(filter: any) => fetchSubscriptions(hhid, filter)}
            pagination={paginationSubscriptions}
            totalElements={subscriptions && subscriptions.totalElements}
            sortableItems={[]}
          />
        </div>
      </Fragment>
    );
  }
}
