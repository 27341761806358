import { connect } from 'react-redux';
import ModalIdMappingComponent from './modal-ip-geolocation-component';
import { close, open, uploadFile, setFileValue } from './redux/actions';

const mapStateToProps = (state: any) => ({
  isActive: state.management.ipGeolocation.modal.active,
  title: state.management.ipGeolocation.modal.title,
  content: state.management.ipGeolocation.modal.content,
  execute: state.management.ipGeolocation.modal.execute,
  disabledUpload: state.management.ipGeolocation.modal.disabledUpload,
  dataUpload: state.management.ipGeolocation.modal.dataUpload,
  fileNameToUpload: state.management.ipGeolocation.modal.fileNameToUpload,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  closeModal: close,
  openModal: open,
  uploadFile,
  setFileValue
})(ModalIdMappingComponent);
