import { connect } from 'react-redux'
import { fetchAllServices, createService, updateService, deleteService } from './services/services'
import { fetchHashByAid, fetchHashById, deleteHashById } from './services/hashes'
import AnonymizerComponent from './anonymizer-component'
import { ApplicationState } from 'application/application-redux';

const mapStateToProps = (state: ApplicationState) => ({
  services: state.management.anonymizer.services,
  hash: state.management.anonymizer.hash,
  service: state.management.anonymizer.service
})

export default connect(mapStateToProps, {
  fetchAllServices,
  createService,
  updateService,
  deleteService,
  fetchHashById,
  fetchHashByAid,
  deleteHashById,
})(AnonymizerComponent)
