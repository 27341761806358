import { combineReducers } from 'redux';
import dashboardReducer from './dashboard/redux';
import detailReducer from './detail/redux';
import deviceAdvancedSearchReducer from './advanced-search/redux';

export default combineReducers({
  dashboard: dashboardReducer,
  detail: detailReducer,
  advancedSearch: deviceAdvancedSearchReducer
});
