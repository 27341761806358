import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import AuthService from 'core/auth';
import { addToaster } from 'core/toaster/redux/actions';
import { AxiosError, AxiosResponse } from 'axios';
import { ApplicationState } from 'application/application-redux';
import vipMapper from './mapper-vip-device-detail';
import mapper from './mapper-device-shadow';
import {
  DeviceDetailOverviewAction,
  GENERAL_BUCKET,
  GENERAL_BUCKET_FAILURE,
  GENERAL_BUCKET_SUCCESS,
  GENERAL_CLEAR_CACHE,
  GENERAL_ID_MAPPING,
  GENERAL_ID_MAPPING_FAILURE,
  GENERAL_ID_MAPPING_SUCCESS,
  GENERAL_SHADOW,
  GENERAL_SHADOW_FAILURE,
  GENERAL_SHADOW_SUCCESS,
  GENERAL_VIP,
  GENERAL_VIP_FAILURE,
  GENERAL_VIP_SUCCESS
} from './types';
import { fetchAssetValuesSync, fetchAssetValues, setFields } from '../../_settings/redux/actions';
import mapperSettings from '../../_settings/redux/mapper';
import { envs } from 'application/envHandler';

export function clearCacheGeneralDeviceInfo(): DeviceDetailOverviewAction {
  return {
    type: GENERAL_CLEAR_CACHE
  };
}

function successShadow(shadow: any): DeviceDetailOverviewAction {
  return {
    type: GENERAL_SHADOW_SUCCESS,
    payload: {
      shadow
    }
  };
}

function failureShadow(payload: any): DeviceDetailOverviewAction {
  return {
    type: GENERAL_SHADOW_FAILURE,
    payload
  };
}

function successBucket(bucket: any): DeviceDetailOverviewAction {
  return {
    type: GENERAL_BUCKET_SUCCESS,
    payload: {
      bucket
    }
  };
}

function failureBucket(payload: any): DeviceDetailOverviewAction {
  return {
    type: GENERAL_BUCKET_FAILURE,
    payload
  };
}

function successVIP(payload: any) {
  return {
    type: GENERAL_VIP_SUCCESS,
    payload
  };
}

function failedVIP(payload: any) {
  return {
    type: GENERAL_VIP_FAILURE,
    payload
  };
}

export function calls(id: string, settingsCall?: boolean, fetchAll?: boolean) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    if (settingsCall) {
      const data = await Promise.all([
        fetchAssetValuesSync(dispatch),
        fetchShadowSync(id, dispatch, getState, settingsCall)
      ]);
    } else {
      if (fetchAll) {
        const data = await Promise.all([
          fetchAssetValuesSync(dispatch),
          fetchShadowSync(id, dispatch, getState, settingsCall)
        ]);
      }
      else {
        const data = await Promise.all([
          fetchAssetValuesSync(dispatch)
        ]);
      }
    }

    dispatch(
      setFields(
        mapperSettings(
          getState().i18n.t,
          getState().devices.detail.general,
          getState().devices.detail._settings['assetsValues']
        )
      )
    );
  };
}

export function fetchBucket(model?: string) {
  return async (dispatch: any) => {
    dispatch(add(GENERAL_BUCKET, {}));
    await AuthService.refreshToken();
    const deviceModel = model || 'default';
    apiClient.get(`${envs.REACT_APP_API_URL}/device/${deviceModel}/bucket`).then(
      (res: AxiosResponse) => {
        dispatch(remove(GENERAL_BUCKET));
        return dispatch(successBucket(res.data));
      },
      (rej: AxiosResponse) => {
        dispatch(remove(GENERAL_BUCKET));
        dispatch(
          addToaster({
            title: 'general.bucket',
            message: 'error_messages.VIPOC_ERROR_026',
            type: 'danger'
          })
        );
        return dispatch(failureBucket(rej));
      }
    );
  };
}

export function fetchShadowSync(
  id: string,
  dispatch: Function,
  getState: () => ApplicationState,
  settingsCall?: boolean
) {
  dispatch(add(GENERAL_SHADOW, {}));
  return apiClient.get(`${envs.REACT_APP_API_URL}/device-hologram/${id}`).then(
    (response: AxiosResponse) => {
      const deviceOverview = mapper(response.data, getState().i18n.t('general.not_available'));
      dispatch(remove(GENERAL_SHADOW));
      if (!settingsCall) {
        dispatch(fetchBucket(deviceOverview.reported.hardware.mod));
      }

      return dispatch(successShadow(deviceOverview));
    },
    (rejection: AxiosError) => {
      dispatch(remove(GENERAL_SHADOW));
      if (!settingsCall) {
        dispatch(fetchBucket());
      } else {
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_settings',
            message: 'error_messages.VIPOC_ERROR_029',
            type: 'danger'
          })
        );
      }

      if (
        rejection.response &&
        (rejection.response.status === 412 || rejection.response.status === 500)
      ) {
        dispatch(
          addToaster({
            title: 'general.device_shadow_detail',
            message: 'error_messages.VIPOC_ERROR_036',
            type: 'danger'
          })
        );
      }
      return dispatch(failureShadow(rejection));
    }
  );
}

export function fetchShadow(id: string, settingsCall?: boolean) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    return fetchShadowSync(id, dispatch, getState, settingsCall);
  };
}

// export function fetchVipSync(
//   id: string,
//   dispatch: Function,
//   getState: () => ApplicationState,
//   callback?: Function
// ) {
//   dispatch(add(GENERAL_VIP, {}));
//   return apiClient.get(`${envs.REACT_APP_API_URL}/device/detail/${id}`).then(
//     (response) => {
//       dispatch(remove(GENERAL_VIP));
//       const deviceVIP = vipMapper(
//         response.data,
//         getState().i18n.t('general.not_available'),
//         getState().i18n.t
//       );
//       if (callback) {
//         dispatch(callback(deviceVIP, getState().i18n.t));
//       }
//       return dispatch(successVIP(deviceVIP));
//     },
//     (rejection: AxiosError) => {
//       let errorMessage = 'error_messages.VIPOC_ERROR_027';
//       const deviceVIP = vipMapper(
//         {},
//         getState().i18n.t('general.not_available'),
//         getState().i18n.t
//       );

//       if (rejection.response && rejection.response.status === 504) {
//         errorMessage = '';
//       }

//       dispatch(remove(GENERAL_VIP));
//       dispatch(
//         addToaster({
//           title: 'all_device_pages.menu_overview',
//           message: errorMessage,
//           type: 'danger'
//         })
//       );
//       if (callback) {
//         dispatch(callback(deviceVIP, getState().i18n.t));
//       }
//       return dispatch(failedVIP(deviceVIP));
//     }
//   );
// }

// export function fetchVip(id: string, callback?: Function) {
//   return async (dispatch: any, getState: () => ApplicationState) => {
//     return fetchVipSync(id, dispatch, getState, callback);
//   };
// }

export function fetchIdMapping(id: string) {
  return async (dispatch: any) => {
    dispatch(add(GENERAL_ID_MAPPING, {}));
    await AuthService.refreshToken();
    apiClient.get(`${envs.REACT_APP_API_URL}/device/${id}/id-mapping`).then(
      (response) => {
        dispatch(remove(GENERAL_ID_MAPPING));
        return dispatch({
          type: GENERAL_ID_MAPPING_SUCCESS,
          payload: response.data
        });
      },
      () => {
        dispatch(
          addToaster({
            title: 'general.device_custom_field_mapping',
            message: 'error_messages.VIPOC_ERROR_007',
            type: 'danger'
          })
        );
        dispatch(remove(GENERAL_ID_MAPPING));
        return dispatch({ type: GENERAL_ID_MAPPING_FAILURE, payload: {} });
      }
    );
  };
}
