import React, { Component } from 'react';
import { TFunction } from 'i18next';
import { ChevronDown } from '../../assets/svg';
import './items-per-page-styles.scss';
import { filterMapper, translate } from 'core/table/types';

interface ItemsPerPageProps {
  id: string;
  changePageSize: (id: string, newSize: number) => void;
  onChangeAction?: any;
  search?: any;
  pagination: any;
  t: TFunction;
  option?: any;
}

export default class ItemsPerPageComponent extends Component<ItemsPerPageProps> {
  changeRecordsNumber(itemsNumber: number, pagination: any) {
    const { id, search, changePageSize, onChangeAction } = this.props;

    if (pagination && itemsNumber !== pagination.pageSize) {
      changePageSize(id, itemsNumber);

      if (search) {
        const _search = search.filters.get(id);
        const currentFilter =
          _search && _search.currentFilter && _search.currentFilter.value
            ? _search.currentFilter
            : null;
        const days = _search && _search.moreFilters && _search.moreFilters.days;
        const comparator = _search && currentFilter && _search.currentComparator;
        
        if(translate[id]) {
          return onChangeAction(filterMapper(currentFilter, pagination, id), days, comparator)
        }
        
        onChangeAction(filterMapper(currentFilter, pagination), days, comparator);
      } else if (onChangeAction) {
        onChangeAction();
      }
    }
  }

  render() {
    const { pagination, search, id, t, option } = this.props;

    const currentPagination: any = pagination.get(id) || null;
    return (
      <div className={`search-records-per-page ${search ? 'has-margin-right-md' : ''}`}>
        <span>{t('general.records_per_page')}</span>
        <div className="current-pagesize">
          <span>{currentPagination && currentPagination.pageSize}</span>
          <ChevronDown className="icon-svg icon-tiny" />
          <div className="dropdown-options">
            {(option || [5, 10, 20]).map((itemsNumber: number) => (
              <button
                key={itemsNumber}
                className={
                  currentPagination && itemsNumber === currentPagination.pageSize
                    ? 'button-link dropdown-item active-item'
                    : 'button-link dropdown-item'
                }
                onClick={() => this.changeRecordsNumber(itemsNumber, currentPagination)}
              >
                {itemsNumber}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
