import React, { Component } from 'react';
import { TFunction } from 'i18next';
import PopUpComponent from '../../../../core/pop-up';

interface UploadModalComponentProps {
  isActive: boolean;
  title: string;
  content: string;
  close: any;
  execute: any;
  uploadFile: any;
  setFileValue: any;
  disabledUpload: boolean;
  dataUpload: FormData;
  fileNameToUpload: string;
  startRefresh: any;
  stopRefresh: any;
  t: TFunction;
}

export default class EsnModalMappingComponent extends Component<UploadModalComponentProps> {
  private async sendFile() {
    await this.props.uploadFile(this.props.dataUpload);
    this.onClose();
  }

  onChangeFileUpload(event: any) {
    this.props.dataUpload.delete('file');
    this.props.dataUpload.append('file', event.target.files[event.target.files.length - 1]);
    this.props.setFileValue(this.props.dataUpload);
    event.target.value = null;
  }

  onClose() {
    this.props.startRefresh();
    this.props.close();
  }

  render() {
    const { t, disabledUpload, close, isActive, title, fileNameToUpload } = this.props;
    return (
      <PopUpComponent isActive={isActive}>
        <h4 className="title is-4">{t(title)}</h4>
        <div className="content">
          <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
            {t('esn_mapping.label_choose_esn_file')}
          </h3>

          <div className="column is-2 control" style={{ marginTop: '15px', marginLeft: '-12px' }}>
            <div className="is-flex">
              <div className="file has-name is-primary" style={{ display: 'inline-block' }}>
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    onChange={event => this.onChangeFileUpload(event)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="mdi mdi-file-document" />
                    </span>
                    <span className="file-label">{t('esn_mapping.label_choose_file')}</span>
                  </span>
                  <span className="file-name" style={{ maxWidth: '9em' }}>
                    {t(fileNameToUpload)}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-buttons">
          <button
            className="button is-primary"
            disabled={disabledUpload}
            onClick={() => this.sendFile()}
          >
            {t('esn_mapping.label_upload')}
            <span className="icon has-margin-left-sm">
              <i className="mdi mdi-cloud-upload" />
            </span>
          </button>
          <button className="button is-secondary" onClick={close}>
            {t('general.cancel')}
          </button>
        </div>
      </PopUpComponent>
    );
  }
}
