import { RESET_ANONYMIZER_SERVICE, SET_ANONYMIZER_SERVICE } from "../../consts";
import { ServiceType } from "../../types";

export function setService(payload: ServiceType | number) {
  return {
    type: SET_ANONYMIZER_SERVICE,
    payload,
  }
}

export function resetService() {
  return {
    type: RESET_ANONYMIZER_SERVICE
  }
}