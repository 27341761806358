export enum DVB_ACTIONS {
  NONE = 'none',
  COPY_PASTE = 'copy',
  CREATE = 'create',
  DELETE = 'delete',
  MOVE = 'move',
  SCHEDULE = 'schedule',
  UPDATE = 'update',
  UPLOAD = 'upload',
  VIEW = 'view'
}

export enum DVB_COLUMNS {
  NONE = 'none',
  NETWORK = 'network',
  NODE = 'node',
  REGION = 'region',
  SERVICE = 'service',
  STREAM = 'stream'
}

export enum DVB_FIELD_TYPES {
  DROPDOWN = 'dropdown',
  NUMBER = 'number',
  READONLY = 'readonly',
  TEXT = 'text'
}

export enum DVB_FORM_SIZES {
  NONE = '',
  SMALL = 'is-small',
  LARGE = 'is-large'
}

export const DVB_COLUMNS_ORDER = [
  DVB_COLUMNS.REGION,
  DVB_COLUMNS.NODE,
  DVB_COLUMNS.NETWORK,
  DVB_COLUMNS.STREAM,
  DVB_COLUMNS.SERVICE
];
