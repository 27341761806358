export const CHANNEL_ASSOCIATIONS_NODES_GET = 'CHANNEL_ASSOCIATIONS_NODES_GET';
export const CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE = 'CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE';
export const CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS = 'CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS';

export enum ChannelsAssociationsNodesType {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export interface ChannelsAssociationsNodes {
    id: number,
    nodeId: string,
    nodeLabel: string,
    childRegionId: number,
    regionalLCN: number
}

export interface ChannelsAssociationsNodesState {
    isActive: boolean;
    isFetching: boolean;
    filteredCount: number;
    nodes: ChannelsAssociationsNodes[];
}

export interface ChannelsAssociationsNodesAction {
    payload: any;
    type:
      | typeof CHANNEL_ASSOCIATIONS_NODES_GET
      | typeof CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE
      | typeof CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS
  }