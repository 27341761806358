import React, { useEffect, useRef, useState } from 'react'
import PopUp from 'core/pop-up'
import { t } from 'i18next'
import { Close } from 'assets/svg'
import { CardSizes } from 'core/card/enum'
import { CreateServiceFunction, ServicesFormInterface, UnitEnum, UpdateServiceFunction } from '../../types'
import './index.scss'

function submitForm(e: React.FormEvent<HTMLFormElement>, id: string, value: string, unit: UnitEnum, serviceAction: CreateServiceFunction | UpdateServiceFunction, setActive: React.Dispatch<React.SetStateAction<boolean>>) {
  e.preventDefault()
  serviceAction({ id, exp: { unit, value: parseInt(value) } })
  onClose(setActive)
}

function onClose(setActive: React.Dispatch<React.SetStateAction<boolean>>) {
  setActive(false)
}

function handleServiceExpirationValueChange(e: React.ChangeEvent<HTMLInputElement>, setServiceExpirationValue: React.Dispatch<React.SetStateAction<string>>) {
  const value = e.target.value
  if (!value || (/^[0-9]*$/.test(value) && parseInt(value) > 0)) {
    setServiceExpirationValue(value)
  }
}

export default function ServicesForm({ service, setActive, serviceAction }: ServicesFormInterface) {
  const isUpdating = !!service
  const [serviceId, setServiceId] = useState(service?.id || "")
  const [serviceExpirationValue, setServiceExpirationValue] = useState(service?.exp?.value.toString() || '')
  const [serviceExpirationUnit, setServiceExpirationUnit] = useState<UnitEnum>(service?.exp?.unit || UnitEnum.SECONDS)
  
  return (
    <PopUp isActive size={CardSizes.SMALL}>
      <div className="columns is-flex">
          <div className="column is-vertical-center">
            <h5 className="text is-h5 is-bold is-marginless">
              {t(isUpdating ? 'anonymizer.update_service' : 'anonymizer.new_service')}
            </h5>
          </div>
          <div className="column is-narrow">
            <button className="button button-action" onClick={() => onClose(setActive)}>
              <Close className="icon-svg icon-small has-margin-top-sm" />
            </button>
          </div>
        </div>
        <form className="columns flex-wrap" onSubmit={(e) => submitForm(e, serviceId, serviceExpirationValue, serviceExpirationUnit, serviceAction, setActive)}>
          <div className="column is-12 fields-container">
            <div className="field-row">
              <label className="label text is-bold">
                {t('anonymizer.service_name')}
              </label>
              <input className="input" type="text" disabled={isUpdating} value={serviceId} onChange={e => setServiceId(e.target.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''))}/>
            </div>
            <div className="field-row">
              <label className="label text is-bold">
                {t('anonymizer.service_expiration')}
              </label>
              <div className="columns">
                <div className="column is-4">
                  <input className="input" type="text" value={serviceExpirationValue} onChange={e => handleServiceExpirationValueChange(e, setServiceExpirationValue)}/>
                </div>
                <div className="column is-8">
                  <div className="select is-fullwidth">
                    <select value={serviceExpirationUnit} onChange={e => setServiceExpirationUnit(e.target.value as UnitEnum)}> 
                      {Object.values(UnitEnum).map(unit => <option key={unit} value={unit}>{t(`anonymizer.unit_${unit}`)}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-12 is-centered">
            <button type="submit" disabled={!serviceId || !serviceExpirationValue} className="button is-larger is-primary">{t('general.save')}</button>
          </div>
        </form>
    </PopUp>
  )
}
