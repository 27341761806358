import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';

import { ApplicationState } from 'application/application-redux';
import {
  RegionalizationChannelsAssociationsAction,
  REGIONALIZATION_ASSOCIATIONS_GET,
  REGIONALIZATION_ASSOCIATIONS_GET_FAILURE,
  REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS,
  REGIONALIZATION_GET,
  REGIONALIZATION_GET_SUCCESS
} from './types';
import { assignTableRows, clearTableCache } from 'core/table/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { TableObject } from 'core/table/redux/types';
import { clearAllFilters } from 'core/search/redux/action';
import {
  DVBNode,
  DVB_MANAGEMENT_NODES_SET
} from 'modules/management/spectrum/dvb-management/redux/redux-nodes/types';
import { DVB_MANAGEMENT_LOADING_TOGGLE } from 'modules/management/spectrum/dvb-management/redux/types';
import { DVB_COLUMNS } from 'modules/management/spectrum/dvb-management/redux/enums';
import { setNodes } from 'modules/management/spectrum/dvb-management/redux/redux-nodes/action';
import { envs } from 'application/envHandler';

export interface SpectrumGetChannelsAscParams {
  select?: any;
  type?: string;
  filter?: any;
  eligible?: boolean;
  id?: number;
  clearCache?: boolean;
}

export interface SprectrumPutRegionalLCN {
  value: any;
  channelId?: number;
  type?: string;
  eligible?: boolean;
  select: any;
}

export interface SprectrumActionParams {
  type: string;
  item?: TableObject;
  select?: any;
}

export function action(type: any, payload?: any): RegionalizationChannelsAssociationsAction {
  return {
    type,
    payload
  };
}

function parseFilter(filter: any): any {
  const parsedFilters: any = {
    name: filter?.channelName || '',
    mediaId: filter?.mediaId || '',
    regLCN: filter?.regionalLCN || ''
  };

  return Object.assign(
    {},
    ...Object.keys(parsedFilters)
      .filter((key) => parsedFilters[key])
      .map((key) => ({ [key]: parsedFilters[key] }))
  );
}

export function getChannelRegionalization(params: SpectrumGetChannelsAscParams) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const eligibleASS = params.eligible || false;
    const pageNumber = params.filter?.pageNumber ? params.filter?.pageNumber + 1 : 1;
    const idType = params.select?.id ? params.select?.id : params.id;
    const opco = AuthService.getCurrentOpco();

    dispatch(add(eligibleASS ? REGIONALIZATION_GET : REGIONALIZATION_ASSOCIATIONS_GET, {}));

    await AuthService.refreshToken();

    const query = {
      ...parseFilter(params.filter),
      pageIndex: pageNumber,
      pageSize: 5
    };
    if (eligibleASS) {
      query.eligible = true;
    }
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/${params.type}/${idType}/channels`,
        { params: query }
      )
      .then(
        response => {
          if (params.clearCache) {
            dispatch(clearAllFilters());
            dispatch(
              clearTableCache(
                eligibleASS
                  ? 'spectrum.regionalization.associate'
                  : 'spectrum.regionalization.disassociate'
              )
            );
          }
          dispatch(
            assignTableRows(
              eligibleASS
                ? 'spectrum.regionalization.associate'
                : 'spectrum.regionalization.disassociate',
              response.data.result,
              'channelId',
              'regionalLCN'
            )
          );
          return dispatch(
            action(
              eligibleASS ? REGIONALIZATION_GET_SUCCESS : REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS,
              {
                data: response.data.result,
                selected: params.select,
                totalCount: response.data.totalCount,
                type: params.type,
                t: getState().i18n.t
              }
            )
          );
        },
        rejection => {
          return dispatch(action(REGIONALIZATION_ASSOCIATIONS_GET_FAILURE, rejection));
        }
      )
      .finally(() => {
        dispatch(remove(eligibleASS ? REGIONALIZATION_GET : REGIONALIZATION_ASSOCIATIONS_GET));
      });
  };
}

function formatedValue(item?: any) {
  const columnsSelect = item.selectedRows
    .filter((row: any) => {
      return row.selected == true;
    })
    .map((e: any) => {
      return {
        channelId: e.rowId,
        regionalLCN: e.field
      };
    });
  const value = {
    channels: [...columnsSelect]
  };
  if (columnsSelect.length == 0) {
    return undefined;
  } else {
    return value;
  }
}

export function regionalizationAssociateChannel(params: SprectrumActionParams) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const channelSelected = formatedValue(params.item);
    const opco = AuthService.getCurrentOpco();

    if (channelSelected === undefined) {
      return dispatch(
        addToaster({
          title: 'spectrum.error',
          message: getState()
            .i18n.t('spectrum.channels.selected.message')
            .replace('{0}', getState().i18n.t('spectrum.channel')),
          type: 'danger'
        })
      );
    }
    await AuthService.refreshToken();
    apiClient
      .post(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/${params.type}/${params.select?.id}/channels`,
        channelSelected
      )
      .then(
        response => {
          dispatch(
            getChannelRegionalization({
              type: params.type,
              eligible: true,
              select: params.select,
              clearCache: true
            })
          );
          dispatch(
            getChannelRegionalization({
              type: params.type,
              select: params.select,
              clearCache: true
            })
          );
          return dispatch(
            addToaster({
              title: 'spectrum.saved.update',
              message: response.data.message,
              type: 'success'
            })
          );
        },
        reject => {
          return dispatch(
            addToaster({
              title: 'Error',
              message: reject.response.data.message.replace(':', ' -'),
              type: 'danger'
            })
          );
        }
      );
  };
}

export function regionalizationDisassociateChannel(params: SprectrumActionParams) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const value = formatedValue(params.item);
    const opco = AuthService.getCurrentOpco();
    if (value == undefined) {
      return dispatch(
        addToaster({
          title: 'spectrum.error',
          message: getState()
            .i18n.t('spectrum.channels.selected.message')
            .replace('{0}', getState().i18n.t('spectrum.channel')),
          type: 'danger'
        })
      );
    }
    await AuthService.refreshToken();
    apiClient
      .delete(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/${params.type}/${params.select?.id}/channels`,
        value
      )
      .then(
        response => {
          dispatch(
            getChannelRegionalization({
              type: params.type,
              select: params.select,
              clearCache: true
            })
          );
          dispatch(
            getChannelRegionalization({
              type: params.type,
              eligible: true,
              select: params.select,
              clearCache: true
            })
          );
          return dispatch(
            addToaster({
              title: 'spectrum.saved.update',
              message: response.data.message,
              type: 'success'
            })
          );
        },
        rejection => {
          return dispatch(
            addToaster({
              title: 'spectrum.saved.update_error',
              message: rejection,
              type: 'danger'
            })
          );
        }
      );
  };
}

export function putRegLCN(params: SprectrumPutRegionalLCN) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const opco = AuthService.getCurrentOpco();
    const valueLCN = {
      channels: [
        {
          regionalLCN: parseInt(params.value)
        }
      ]
    };
    await AuthService.refreshToken();
    apiClient
      .put(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/${params.type}/${params.select?.id}/channel/${params.channelId}`,
        valueLCN
      )
      .then(
        response => {
          dispatch(
            getChannelRegionalization({
              type: params.type,
              eligible: true,
              id: params.select?.id,
              select: params.select,
              clearCache: true
            })
          );
          dispatch(
            getChannelRegionalization({
              type: params.type,
              id: params.select?.id,
              select: params.select,
              clearCache: true
            })
          );
          return dispatch(
            addToaster({
              title: 'spectrum.saved.update',
              message: response.data.message,
              type: 'success'
            })
          );
        },
        rejection => {
          return dispatch(
            addToaster({
              title: 'spectrum.saved.update_error',
              message: rejection.response.data.message,
              type: 'danger'
            })
          );
        }
      );
  };
}
