export const REQUESTS_GET_REQUESTS = 'REQUESTS_GET_REQUESTS';
export const REQUESTS_GET_CHANGES = 'REQUESTS_GET_CHANGES';
export const REQUESTS_REPLY_REQUEST = 'REQUESTS_REPLY_REQUEST';
export const REQUESTS_SET_REQUEST = 'REQUESTS_SET_REQUEST';
export const REQUESTS_SET_REQUEST_INITIAL = 'REQUESTS_SET_REQUEST_INITIAL';
export const REQUESTS_SET_SCHEDULE_DATE = 'REQUESTS_SET_SCHEDULE_DATE';
export const REQUESTS_CHANGE_PAGE = 'REQUESTS_CHANGE_PAGE';

export interface RequestsAction {
  type:
    | typeof REQUESTS_GET_REQUESTS
    | typeof REQUESTS_GET_CHANGES
    | typeof REQUESTS_REPLY_REQUEST
    | typeof REQUESTS_SET_REQUEST
    | typeof REQUESTS_SET_REQUEST_INITIAL
    | typeof REQUESTS_SET_SCHEDULE_DATE;
  payload?: any;
}

export interface Request {
  createdAt: string;
  modifiedAt: string;
  requestId: string;
  requestStatus: string;
  schedulingDate: string;
  user: string;
}

export interface RequestsMainState {
  pagination: {
    pageSize: number;
    pageNumber: number;
  };
  totalCount: number;
  index: string;
  requests?: Request[];
  listIndex: Record<number, string>;
  selectedRequest?: {
    canEditOtherEntities?: boolean;
    otherChanges?: any[];
    userChanges?: any[];
    isRequestOpen?: boolean;
    isRequestPending?: boolean;
    requestId?: string;
    requestStatus?: string;
    results?: {
      failed: any[];
      succeeded: any[];
    };
    scheduleDate?: {
      start: string;
      end?: string;
    };
    details?: boolean
  };
}

export enum RequestReply {
  APPROVE = 'approve',
  CANCEL = 'cancel',
  REJECT = 'reject'
}

export enum RequestStatus {
  APPROVED = 'approved',
  CANCELED = 'canceled',
  DRAFT = 'draft',
  EXPIRED = 'expired',
  PENDING = 'pending',
  REJECTED = 'rejected'
}
