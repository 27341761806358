import {
  ADD_TOASTER,
  ADD_TOASTER_TIMEOUT,
  REMOVE_TOASTER,
  DEACTIVATE_TOASTER,
  ToasterState,
  ToasterAction,
  ADD_TOOLTIP
} from './types';

const initialState: ToasterState = {
  toasters: []
};

export default function(state = initialState, action: ToasterAction): ToasterState {
  switch (action.type) {
    case ADD_TOASTER:
      if (state.toasters) {
        state.toasters.push({ toaster: action.payload.toaster, isActive: true, hasTimeout: false });
      }

      return {
        ...state
      };

    case ADD_TOASTER_TIMEOUT:
      return {
        ...state,
        toasters: state.toasters
      };

    case ADD_TOOLTIP: {
      return {
        ...state,
        tooltip: {
          message: action.payload.toaster.message,
          title: action.payload.toaster.title,
          type: action.payload.toaster.type
        }
      };
    }
    case DEACTIVATE_TOASTER:
      action.payload.toaster.isActive = false;
      return {
        ...state
      };

    case REMOVE_TOASTER:
      return {
        ...state,
        toasters: state.toasters.filter(obj => obj !== action.payload.toaster)
      };
    default:
      return state;
  }
}
