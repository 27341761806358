import { combineReducers } from 'redux';
import { reducer as idMappingReducer } from './id-mapping';
import { reducer as ipGeolocationReducer } from './ip-geolocation';
import { reducer as onnetOffnetReducer } from './onnet-offnet';
import { reducer as esnMappingReducer } from './esn-mapping';
import spectrumReducer from './spectrum/redux';
import anonymizerReducer from './anonymizer/redux';

export default combineReducers({
  idMapping: idMappingReducer,
  ipGeolocation: ipGeolocationReducer,
  onnetOffnet: onnetOffnetReducer,
  esnMapping: esnMappingReducer,
  spectrum: spectrumReducer,
  anonymizer: anonymizerReducer,
});
