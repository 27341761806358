import React, { Component } from 'react';
import { ChevronLeft } from 'assets/svg';
import './carousel-styles.scss';
import { DeviceImage } from 'modules/operational/device/detail/general/redux/types';
import { CarouselState } from './redux/types';
import { TFunction } from 'i18next';

interface CarouselProps {
  images: Array<DeviceImage>;
  setActiveIndex: (index: number) => void;
  carousel: CarouselState;
  t: TFunction;
}

export default class Carousel extends Component<CarouselProps> {
  goToOtherImage(isNext: boolean) {
    const { setActiveIndex, carousel } = this.props;
    let index = carousel.activeIndex;

    if (isNext) {
      ++index;
      this.setImagePosition(-200);
    } else {
      --index;
      this.setImagePosition(200);
    }

    setActiveIndex(index);
  }

  setImagePosition(value: number) {
    const element = document.getElementById('carousel-cont');

    if (element) {
      element.style.left = `${
        Number(element.style.left.substring(0, element.style.left.length - 2)) + value
      }px`;
    }
  }

  goToSlide(index: number) {
    const { setActiveIndex, carousel } = this.props;
    const previousIndex = carousel.activeIndex;
    let value = (previousIndex - index) * 200;

    this.setImagePosition(value);

    setActiveIndex(index);
  }

  render() {
    const { images, carousel, t } = this.props;

    return (
      <div className="carousel is-child has-padding-sm">
        <p className="has-margin-bottom-sm has-margin-top-sm text is-h5">
          {t('overview.device_image')}
        </p>
        <div className="is-vertical-center is-justified-center has-padding-top-md">
          {images && images.length > 1 && (
            <button
              className="button carousel-arrow button-action"
              disabled={carousel.activeIndex === 0}
              onClick={() => this.goToOtherImage(false)}
            >
              <ChevronLeft className="icon carousel-arrow icon-svg icon-xx-small" />
            </button>
          )}

          <div className="carousel-wrapper">
            <div className="carousel-container">
              <div className="carousel-slider" id="carousel-cont">
                {images &&
                  images.length > 0 &&
                  images.map((img, index) => (
                    <div className="carousel-img" key={index}>
                      <img src={`data:image/jpeg;base64,${images[index].file}`} alt="Device" />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {images && images.length > 1 && (
            <button
              className="button button-action carousel-arrow "
              disabled={carousel.activeIndex === images.length - 1}
              onClick={() => this.goToOtherImage(true)}
            >
              <ChevronLeft className="icon-180 icon icon-svg icon-xx-small" />
            </button>
          )}
        </div>

        {images && images.length > 1 && (
          <div className="is-flex has-padding-top-md is-justified-center">
            {images.map((img, index) => (
              <button
                key={index}
                className={`carousel-bullet ${
                  index === carousel.activeIndex ? 'carousel-bullet-selected' : ''
                }`}
                onClick={() => this.goToSlide(index)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
