import { TFunction } from 'i18next';
import { ChannelsAssociationsRegions, ChannelsRegions, ChannelsAssociationsRegionsType } from './type';

export default (data: ChannelsAssociationsRegions, t: TFunction): ChannelsAssociationsRegions => {
    const ChannelsAssociationsRegionsType = getChannelsType(data);
    return {
      regionId: data.regionId ,
      regionLabel: data.regionLabel,
      parentRegionId: data.parentRegionId,
      regionalLCN: data.regionalLCN
    };
};

function getChannelsType(result: any) {
    if (result) {
      if (!result.failed || result.failed === 0) {
        return ChannelsAssociationsRegionsType.SUCCESS;
      } else if (!result.succeeded || result.succeeded === 0) {
        return ChannelsAssociationsRegionsType.ERROR;
      }
    }
  
    return ChannelsAssociationsRegionsType.WARNING;
}

export function mapperChannelRegions (data: ChannelsRegions, t: TFunction): ChannelsRegions {
  const ChannelsAssociationsRegionsType = getChannelsType(data);
  return {
    regionId: data.regionId ,
    regionLabel: data.regionLabel,
    parentRegionId: data.parentRegionId,
    regionalLCN: data.regionalLCN
  };
}; 