import apiClient from 'core/api';
import AuthService from 'core/auth';
import queryString, { createArrayToQueryString } from 'core/utils/query-string';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import {
  DVR_INFO,
  DVR_FAILURE,
  DvrAction,
  DVR_SUCCESS,
  DVR_DELETE_RECORDING,
  RecordingsItem,
  DVR_DELETE_RECORDING_FAILURE,
  DVR_CLEAR_CACHE,
  DVR_TIMELINE_RECORDINGS,
  DVR_TIMELINE_RECORDINGS_SUCCESS,
  DVR_TIMELINE_TOGGLE,
} from './types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

function failure(payload: any): DvrAction {
  return {
    type: DVR_FAILURE,
    payload,
  };
}

function success(payload: any): DvrAction {
  return {
    type: DVR_SUCCESS,
    payload,
  };
}

function getTimelineSuccess(payload: any): DvrAction {
  return {
    type: DVR_TIMELINE_RECORDINGS_SUCCESS,
    payload,
  };
}

function failureDelete(): DvrAction {
  return {
    type: DVR_DELETE_RECORDING_FAILURE,
  };
}

function successDelete(): DvrAction {
  return {
    type: DVR_DELETE_RECORDING_FAILURE,
  };
}

export function clearCacheDvr(): DvrAction {
  return {
    type: DVR_CLEAR_CACHE,
  };
}

export function toggleTimeline(): DvrAction {
  return {
    type: DVR_TIMELINE_TOGGLE,
  };
}

function formatProgram(filter: any) {
  if (filter && filter.programName) {
    return { ...filter, episode: filter.programName };
  }
  return filter;
}

export function fetch(params: { hhid: string, deviceId: string }) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(DVR_INFO, {}));
    await AuthService.refreshToken();
    const state = getState();
    const filters = state.multipleSearch.multipleFilters.get('accordion.dvr');
    const pagination = state.pagination.pagination.get('accordion.dvr');

    const selectedFilter =
      filters && formatProgram(createArrayToQueryString(filters.selectedFilters));

    const recordingType = filters?.selectedFilters.find(filter => filter.name === 'recordingType')
    const idToFetch = recordingType?.value === 'cloud' ? params.hhid : params.deviceId
    
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/dvr/${idToFetch}?${queryString(
          { ...pagination, ...selectedFilter },
          getState().application.offset
        )}`
      )
      .then(
        (response) => {
          dispatch(remove(DVR_INFO));
          return dispatch(success(response.data));
        },
        (rejection) => {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_dvr',
              message: 'error_messages.VIPOC_ERROR_021',
              type: 'danger',
            })
          );
          dispatch(remove(DVR_INFO));
          return dispatch(failure(rejection));
        }
      );
  };
}

//TODO deleteDvrItem or deleteMultipleRecordings
export function deleteRecording(item: RecordingsItem, id: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(DVR_DELETE_RECORDING, {}));
    await AuthService.refreshToken();
    const command =
      item.status === 'scheduled' ? 'deleteLocalPlannedRecording' : 'deleteLocalRecording';
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command,
        deviceId: id,
        jsonParameters: { recID: item.assetId },
      })
      .then(
        (response) => {
          if (response.data.httpCode === '400') {
            const { jobId } = response.data.responseJson;
            handleCommandError(
              dispatch,
              jobId,
              'dvr.delete.title',
              getState().i18n.t,
              DVR_DELETE_RECORDING
            );
          } else {
            dispatch(remove(DVR_DELETE_RECORDING));
            dispatch(
              addToaster({
                title: 'dvr.delete.title',
                message: 'dvr.delete.success',
                type: 'success',
              })
            );
            return dispatch(successDelete());
          }
        },
        (rejection) => {
          dispatch(remove(DVR_DELETE_RECORDING));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_024';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_dvr',
              message,
              type: 'danger',
            })
          );
          return dispatch(failureDelete());
        }
      );
  };
}

export function getTimelineRecordings({ hhid, deviceId }: { hhid: string, deviceId: string }) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(DVR_TIMELINE_RECORDINGS, {}));
    await AuthService.refreshToken();
    apiClient
      .get(`${envs.REACT_APP_API_URL}/dvr/${hhid}/${deviceId}/recordings/per-day`)
      .then(
        (response) => {
          dispatch(remove(DVR_TIMELINE_RECORDINGS));
          return dispatch(getTimelineSuccess(response.data));
        },
        (rejection) => {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_dvr',
              message: 'error_messages.VIPOC_ERROR_021',
              type: 'danger',
            })
          );
          dispatch(remove(DVR_TIMELINE_RECORDINGS));
          return dispatch(failure(rejection));
        }
      );
  };
}
