import { ApplicationState } from "application/application-redux";
import { connect } from "react-redux";
import RfTuningDvbcComponent from "./rf-tuning-dvbc-monitoring-component";

const mapStateToProps = (state: ApplicationState) => ({
  dvbc: state.devices.detail.general.shadow?.custom?.DVBC || [],
  frequencyLimits: state.devices.detail.rfTuning.status.frequencyLimits,
});


export default connect(mapStateToProps, {})(RfTuningDvbcComponent)