import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import mapper from './mapper';
import {
  DEVICE_DETAIL_HARD_DISK_FAILURE,
  DEVICE_DETAIL_HARD_DISK_REQUEST,
  DEVICE_DETAIL_HARD_DISK_SUCCESS,
  DeviceDetailHardDiskAction,
  DeviceHardDisk,
  HARD_DISK_CLEAR_CACHE
} from './types';
import { envs } from 'application/envHandler';

export function clearCacheSuccess(payload: any) {
  return {
    type: HARD_DISK_CLEAR_CACHE,
    payload
  };
}

export function clearCache() {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const deviceHardDisk: DeviceHardDisk = mapper(
      null,
      getState().i18n.t('general.not_available'),
      getState().devices.detail.general.shadow,
      getState().i18n.t,
      getState().devices.detail.general.shadow
    );
    return dispatch(clearCacheSuccess(deviceHardDisk));
  };
}

function request(): DeviceDetailHardDiskAction {
  return {
    type: DEVICE_DETAIL_HARD_DISK_REQUEST
  };
}

function success(payload: any): DeviceDetailHardDiskAction {
  return {
    type: DEVICE_DETAIL_HARD_DISK_SUCCESS,
    payload
  };
}

function failure(payload: any): DeviceDetailHardDiskAction {
  return {
    type: DEVICE_DETAIL_HARD_DISK_FAILURE,
    payload
  };
}

export function fetch(id: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(request());
    dispatch(add('DEVICE_DETAIL_HARD_DISK', {}));
    apiClient.get(`${envs.REACT_APP_API_URL}/device/detail/${id}`).then(
      (response) => {
        dispatch(remove('DEVICE_DETAIL_HARD_DISK'));
        console.error(response.data);

        return dispatch(
          success(
            mapper(
              response.data,
              getState().i18n.t('general.not_available'),
              getState().devices.detail.general.shadow,
              getState().i18n.t,
              []
            )
          )
        );
      },
      (rejection) => {
        dispatch(remove('DEVICE_DETAIL_HARD_DISK'));
        dispatch(
          addToaster({
            title: 'overview.icon_hard_disk',
            message: 'error_messages.VIPOC_ERROR_025',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function clearThreshold() {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const deviceHardDisk: DeviceHardDisk = mapper(
      '',
      getState().i18n.t('general.not_available'),
      {},
      getState().i18n.t,
      []
    );
    return dispatch(success(deviceHardDisk));
  };
}

export function getThreshhold(callback: any) {

  return async (dispatch: any, getState: () => ApplicationState) => {
    const modelNumber = getState().devices.detail.general.shadow.custom.HDDPNr;

    if (modelNumber)
      apiClient
        .get(
          `${envs.REACT_APP_API_URL}/device-hologram/account/harddisk?model=${modelNumber}`
        )
        .then(
          (response) => {
            callback(response.data);
            return dispatch(
              success(
                mapper(
                  '',
                  getState().i18n.t('general.not_available'),
                  getState().devices.detail.general.shadow,
                  getState().i18n.t,
                  response.data
                )
              )
            );
          },
          (rejection) => {
            console.log({ rejection });
            return dispatch(
              success(
                mapper(
                  '',
                  getState().i18n.t('general.not_available'),
                  getState().devices.detail.general.shadow,
                  getState().i18n.t,
                  [{}]
                )
              )
            );
          }
        );
  };
}
