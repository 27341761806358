import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import {
  CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS,
  CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE,
  CHANNEL_ASSOCIATIONS_NODES_GET,
  ChannelsAssociationsNodesAction
} from './type';
import { ApplicationState } from 'application/application-redux';
import { assignTableRows, clearTableCache, updateSort } from 'core/table/redux/actions';
import queryString from 'core/utils/query-string';
import { clearAllFilters } from 'core/search/redux/action';
import { addToaster } from 'core/toaster/redux/actions';
import { Sort, TableState } from 'core/table/redux/types';
import { SpectrumChannelsAssociationParam } from '../../../redux/redux-channels/action';
import { envs } from 'application/envHandler';

export function action(type: any, payload?: any): ChannelsAssociationsNodesAction {
  return {
    type,
    payload
  };
}

function parseFilter(filter: any): any {
  const parsedFilters: any = {
    nodeLabel: filter?.nodeLabel || '',
    nodeId: filter?.nodeId || '',
    childRegionId: filter?.childRegionId || ''
  };

  return Object.assign(
    {},
    ...Object.keys(parsedFilters)
      .filter(key => parsedFilters[key])
      .map(key => ({ [key]: parsedFilters[key] }))
  );
}

function parseSort(sort?: Sort): string {
  if (!sort || sort.column == '' || sort.order == '') {
    return '';
  }
  return `${sort.column}-${sort.order}`;
}

export function getChannelNodes(params: SpectrumChannelsAssociationParam) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const opco = AuthService.getCurrentOpco();
    const eligibleNodes = params?.eligible || false;
    dispatch(add(CHANNEL_ASSOCIATIONS_NODES_GET, {}));

    await AuthService.refreshToken();

    const query = {
      ...parseFilter(params?.filter),
      ordering: parseSort(params?.sort),
      pageSize: 5,
      pageIndex: params?.pageNumber ?? 1
    };

    if (eligibleNodes) {
      query.eligible = true;
    }
    
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/be/spectrum/regionalization/opcos/${opco}/channel/${params.channelId}/nodes`,
        { params: query }
      )
      .then(
        response => {
          if (params?.clearCache) {
            dispatch(clearAllFilters());
            dispatch(clearTableCache('spectrum.nodes'));
          }
          params?.sort && dispatch(updateSort('spectrum.nodes', params.sort));
          dispatch(assignTableRows('spectrum.nodes', response.data.result, 'id', 'regionalLCN'));
          return dispatch(
            action(CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS, {
              data: response.data.result,
              totalCount: response.data.totalCount,
              t: getState().i18n.t
            })
          );
        },
        rejection => {
          return dispatch(action(CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE, rejection));
        }
      )
      .finally(() => {
        dispatch(remove(CHANNEL_ASSOCIATIONS_NODES_GET));
      });
  };
}
