import moment from 'moment';
import {
  containsValues,
  activityLogMultipleSearch,
  advancedSearchSingleSearch,
  requestMultipleSearch,
  dvrMultipleSearchConstructor
} from './config';
import {
  ConstructorMap,
  MultipleSearchAction,
  MultipleSearchFilter,
  MultipleSearchState,
  MULTIPLE_SEARCH_ADD_SELECTED_FILTERS,
  MULTIPLE_SEARCH_CLEAR_ALL_FILTERS,
  MULTIPLE_SEARCH_DELETE_FILTER,
  MULTIPLE_SEARCH_RESET_SHOW_FILTERS,
  MULTIPLE_SEARCH_RESET_STATE,
  MULTIPLE_SEARCH_SET_LIST_VALUES,
  MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS,
  MULTIPLE_SEARCH_UPDATE_INPUT
} from './types';

const inicialMultipleFilter = new Map();

inicialMultipleFilter.set('advanced.search', advancedSearchSingleSearch);
inicialMultipleFilter.set('accordion.dvr', dvrMultipleSearchConstructor());
inicialMultipleFilter.set('activityLog', activityLogMultipleSearch);
inicialMultipleFilter.set('spectrum.requests', requestMultipleSearch);

const initialState: MultipleSearchState = {
  multipleFilters: inicialMultipleFilter
};

const constructorMap: ConstructorMap = {
  'accordion.dvr': dvrMultipleSearchConstructor,
}

export default function (state = initialState, { type, payload }: MultipleSearchAction) {
  switch (type) {
    case MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS:
      const multipleSearchSelected = state.multipleFilters.get(payload.id);

      if (multipleSearchSelected) {
        multipleSearchSelected.showFilters = !multipleSearchSelected.showFilters;
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_ADD_SELECTED_FILTERS:
      const selectedMultipleSearch = state.multipleFilters.get(payload.id);

      if (selectedMultipleSearch && payload.value !== '') {
        const hasItem = selectedMultipleSearch.selectedFilters.find(
          (item: any) => item.name === payload.name
        );

        const uniqueValues =
          selectedMultipleSearch.singleItemFilters ||
          containsValues.find((item: any) => payload.name === item);
        const selectBox = selectedMultipleSearch.levels[0].categories[0].fields[0];
        const isThere =
          selectedMultipleSearch.selectedFilters.find((item: any) => selectBox.currentValue === item.name) &&
          payload.name === 'inputBox';
        if (!hasItem && !payload.type && !isThere) {
          if (payload.name == 'selectBox') {
            selectedMultipleSearch.selectedFilters = selectedMultipleSearch.selectedFilters.filter(
              (e: any) => {
                return !selectBox.listValues?.includes(e.name)
              }
            );
            selectedMultipleSearch.levels[0].categories[0].fields[1].currentValue = '';
          } else {
            if (selectedMultipleSearch.selectedFilters.length < 6) {
              if (payload.name === 'reprocessCode') {
                const reprocessCode = payload.value.split(' ')[0];
                selectedMultipleSearch.selectedFilters.push({
                  name: payload.name,
                  value: reprocessCode
                });
              } else if (payload.name === 'nagraEmmPlayoutDate') {
                const date = new Date(payload.value);
                selectedMultipleSearch.selectedFilters.push({
                  name: payload.name,
                  value: payload.value,
                  valueToSend: moment(date.setHours(date.getHours() - 2)).format(
                    'YYYY-MM-DDTHH:mm:SSZ'
                  )
                });
              } else {
                if (payload.name === 'inputBox') {
                  selectedMultipleSearch.selectedFilters.push({
                    name: selectBox.currentValue,
                    value: payload.value
                  });
                } else {
                  selectedMultipleSearch.selectedFilters.push({
                    name: payload.name,
                    value: payload.value
                  });
                }
              }
            }
          }

          selectedMultipleSearch.levels.forEach((level: any) => {
            level.categories.forEach((category: any) => {
              category.fields.forEach((field: any) => {
                if (field.name === payload.name) {
                  field.currentValue = payload.value;
                }
              });
            });
          });
        } else {
          selectedMultipleSearch.levels.forEach((level: any) => {
            level.categories.forEach((category: any) => {
              category.fields.forEach((field: any) => {
                if (field.name === payload.name) {
                  field.currentValue = payload.value;
                }
              });
            });
          });

          if (uniqueValues && payload.name !== 'selectBox') {
            selectedMultipleSearch.selectedFilters.find((element: any) => {
              if (
                element.name === payload.name ||
                (element.name === selectBox.currentValue && payload.name === 'inputBox')
              ) {
                if (payload.name === 'reprocessCode') {
                  element.value = payload.value.split(' ')[0];
                } else {
                  element.value = payload.value;
                }
              }
            });
          } else {
            const alreadyHasValue = selectedMultipleSearch.selectedFilters.some(
              (item: any) =>
                item.name === payload.name && item.value.split(',').includes(payload.value)
            );

            if (!alreadyHasValue) {
              selectedMultipleSearch.selectedFilters.find((element: any) => {
                if (element.name === payload.name) {
                  element.value = `${element.value},${payload.value}`;
                }
              });
            }
          }
        }
      } else {
        if (selectedMultipleSearch) {
          selectedMultipleSearch.levels.forEach((level: any) => {
            level.categories.forEach((category: any) => {
              category.fields.forEach((field: any) => {
                if (field.name === payload.name) {
                  field.currentValue = '';
                }
              });
            });
          });

          const filtered = selectedMultipleSearch.selectedFilters.filter((element: any) => {
            return element.name !== payload.name;
          });
          selectedMultipleSearch.selectedFilters = filtered;
        }
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_DELETE_FILTER:
      const selectMultipleSearch = state.multipleFilters.get(payload.id);
      const selectBox = selectMultipleSearch?.levels[0].categories[0].fields[0];

      if (selectMultipleSearch) {
        const filtered = selectMultipleSearch.selectedFilters.filter(
          (element: any, index: number) => {
            return index !== payload.index;
          }
        );
        selectMultipleSearch.selectedFilters = filtered;

        const find = selectMultipleSearch.selectedFilters.find(
          (element: any) => element.name === payload.filter.name
        );
        const isSelectBox = selectBox?.currentValue.includes(payload.filter.name);
        selectMultipleSearch.levels.forEach((level: any) => {
          level.categories.forEach((category: any) => {
            category.fields.forEach((field: any) => {
              if (
                typeof payload.filter.value !== 'object' &&
                payload.filter.value.includes(',') &&
                field.type === 'check'
              ) {
                field.currentValue = false;
              }
              if (field.name === payload.filter.value && field.type === 'check') {
                field.currentValue = false;
              }

              if (find) {
                if (find.name === field.name) {
                  field.currentValue = find.value;
                }
              } else if (payload.filter.name === field.name) {
                field.currentValue = '';
              } else if (isSelectBox && (field.name === 'selectBox' || field.name === 'inputBox')) {
                field.currentValue = '';
              }
            });
          });
        });
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_CLEAR_ALL_FILTERS:
      if(constructorMap[payload.id]) {
        state.multipleFilters.set(payload.id, constructorMap[payload.id]())

        return {
          ...state
        }
      }

      const selectedMultiSearch = state.multipleFilters.get(payload.id);

      if (selectedMultiSearch) {
        selectedMultiSearch.levels.forEach((level: any) => {
          level.categories.forEach((category: any) => {
            category.fields.forEach((field: any) => {
              if (field.type === 'check') {
                field.currentValue = false;
              } else {
                field.currentValue = '';
              }
            });
          });
        });
        selectedMultiSearch.selectedFilters = [];
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_UPDATE_INPUT:
      const inputSearch = state.multipleFilters.get(payload.id);
      if (inputSearch) {
        inputSearch.levels.forEach((level: any) => {
          level.categories.forEach((category: any) => {
            category.fields.forEach((field: any) => {
              if (field.name === payload.name) {
                field.currentValue = payload.value;
              }
            });
          });
        });
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_RESET_SHOW_FILTERS:
      const selectedMultSearch = state.multipleFilters.get(payload.id);
      if (selectedMultSearch) {
        selectedMultSearch.showFilters = false;
      }
      return {
        ...state
      };

    case MULTIPLE_SEARCH_RESET_STATE:
      const accordionActivityLog = state.multipleFilters.get('activityLog');

      if (accordionActivityLog) {
        accordionActivityLog.levels.map((level: any) => {
          level.categories.map((category: any) => {
            category.fields.map((field: any) => {
              field.currentValue = '';
            });
          });
          accordionActivityLog.selectedFilters = [];
        });
        accordionActivityLog.showFilters = false;
      }

      return {
        ...state
      };

    case MULTIPLE_SEARCH_SET_LIST_VALUES:
      const accordionActLog = state.multipleFilters.get('activityLog');
      if (accordionActLog) {
        accordionActLog.levels[0].categories[0].fields[2].listValues = payload.commandList;
      }

      return {
        ...state
      };
    default: {
      return {
        ...state
      };
    }
  }
}
