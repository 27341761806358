
import { ApplicationState } from 'application/application-redux';
import { close, open } from 'core/modal/redux/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { changeMenu } from 'application/header/navbar/redux/actions';
import { action, getRequests, replyRequest } from './redux/action';
import RequestsComponent from './requests-component';
import { addFilter, onChangeTypeFilterValue } from '../../../../core/search/redux/action';

const mapStateToProps = (state: ApplicationState) => ({
  requests: state.management.spectrum.requests.requests,
  mainRequests: state.management.spectrum.requests,
  pagination: state.pagination.pagination,
  t: state.i18n.t,
  search: state.search,
  multipleSearch: state.multipleSearch
});
export default connect(mapStateToProps, {
  action,
  changeMenu,
  close,
  getRequests,
  open,
  push,
  replyRequest,
  addFilter,
  onChangeTypeFilterValue
})(RequestsComponent);
