import React, { Component } from 'react';
import IdMappingForm from './form';
import IdMappingGridComponent from './grid';

interface IdMappingComponentProps {
  execute: any;
  queries: Array<any>;
  fetchHistory: any;
  currentHistory: any;
}

export default class IdMappingComponent extends Component<IdMappingComponentProps> {
  componentDidMount() {
    this.props.execute();
  }

  render() {
    return (
      <div className="section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-child">
              <div className="section">
                <IdMappingForm
                  fetchHistory={this.props.fetchHistory}
                  currentHistory={this.props.currentHistory}
                />
              </div>

              <div className="section">
                <IdMappingGridComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
