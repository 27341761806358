import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

interface BreadcrumbProps {
  router: any;
  goToHome: () => void;
  goToDashboard: () => void;
}

class BreadcrumbsComponent extends PureComponent<BreadcrumbProps> {
  render() {
    const { router } = this.props;

    let title =
      router.location.state && router.location.state.title ? router.location.state.title : '';
    title = router.location.pathname === '/' ? 'Home' : title;

    return <h6>{title}</h6>;
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  router: state.router,
  goToHome: ownProps.goToHome,
  goToDashboard: ownProps.goToDashboard,
});

export default connect(mapStateToProps)(BreadcrumbsComponent);
