import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import TableAccordionComponent from './table-accordion-component';
import { toggleItem, deleteItem, onChangeAccordionPage } from './redux/actions';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t,
  tableAccordion: state.accordion,
  pagination: state.pagination.pagination,
  table: state.table
});

export default connect(mapStateToProps, {
  toggleItem,
  deleteItem,
  onChangeAccordionPage,
  goToFirstPage
})(TableAccordionComponent);
