import { TFunction } from 'i18next';
import { GeneralDeviceState } from '../../general/redux/types';
import { AssetValue, CommandParameter } from './types';
import getUserGroup from '../../account-security/account-security-component';

export default (t: TFunction, general: GeneralDeviceState, assetValues: any) => {
  const shadow = general.shadow;
  const hdmiModeAvailable =  getAvailableHdmiModes(shadow && shadow.custom.HDMID, 'settings.label_select')
  assetValues.updateToSpecificFirmware.unshift({label: t('settings.label_select'), value: '', active: false})

  return {
    assetsValues: assetValues,
    configurations: [
      {
        component: 'audio_subtitle',
        fields: [
          {
            name: 'audioState',
            label: 'aud_state',
            type: 'radio',
            value: shadow && shadow.custom && shadow.custom.MUTE ? shadow.custom.MUTE : '',
            currentValue: shadow && shadow.custom && shadow.custom.MUTE ? shadow.custom.MUTE : '',
            available: assetValues.audioState
          },
          {
            name: 'uiLanguage',
            label: 'ui_language',
            type: 'list',
            value: assetValues.setUILanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.UILg) >= 0 ? shadow?.reported.config.UILg : '',
            currentValue: assetValues.setUILanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.UILg) >= 0 ? shadow?.reported.config.UILg : '',
            available: assetValues.setUILanguage
          },
          {
            name: 'subtitles',
            label: 'subtitles',
            type: 'list',
            value: assetValues.subtitles.findIndex((setting: any) => setting.value === parseInt(shadow?.reported.config.subs.toString() || '')) >= 0 ? parseInt(shadow?.reported.config.subs.toString() || '') : '',
            currentValue: assetValues.subtitles.findIndex((setting: any) => setting.value === parseInt(shadow?.reported.config.subs.toString() || '')) >= 0 ? parseInt(shadow?.reported.config.subs.toString() || '') : '',
            available: assetValues.subtitles
          },
          {
            name: 'audioVolume',
            label: 'aud_vol',
            type: 'list',
            value:
              shadow && shadow.custom && shadow.custom.CAL !== null
                ? convertAudioVolumeToSTB(parseInt(shadow.custom.CAL))
                : '',
            currentValue:
              shadow && shadow.custom && shadow.custom.CAL !== null
                ? convertAudioVolumeToSTB(parseInt(shadow.custom.CAL))
                : '',
            previousValue:
              shadow &&
                shadow.custom &&
                shadow.pCustom.pCal !== 'Not available' &&
                shadow.pCustom.pCal !== null
                ? shadow.pCustom.pCal
                : t('general.not_available'),
            available:
              shadow &&
              shadow.custom &&
              shadow.custom.CAL &&
              getAudioVolumeValues(
                convertAudioVolumeToSTB(parseInt(shadow && shadow.custom && shadow.custom.CAL)),
                t
              )
          },
          {
            name: 'primaryAudioLanguage',
            label: 'primary_aud',
            type: 'list',
            value: assetValues.setPreferredAudioLanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.pAuLg) >= 0 ? shadow?.reported.config.pAuLg : '',
            currentValue: assetValues.setPreferredAudioLanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.pAuLg) >= 0 ? shadow?.reported.config.pAuLg : '',
            available: assetValues.setPreferredAudioLanguage
          },
          {
            name: 'primarySubtitleLanguage',
            label: 'primary_subt',
            type: 'list',
            value: assetValues.setPreferredSubtitleLanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.pSuLg) >= 0 ? shadow?.reported.config.pSuLg : '',
            currentValue: assetValues.setPreferredSubtitleLanguage.findIndex((setting: any) => setting.value === shadow?.reported.config.pSuLg) >= 0 ? shadow?.reported.config.pSuLg : '',
            available: assetValues.setPreferredSubtitleLanguage
          }
        ],
        hasChanged: false
      },
      {
        component: 'hdmi',
        fields: [
          {
            name: 'hdmiAudioMode',
            label: 'aud_mode',
            type: 'list',
            value: assetValues.hdmiAudioMode.findIndex((setting: any) => setting.value === shadow?.reported.config.HDMIAM) >= 0 ? shadow?.reported.config.HDMIAM : '',
            currentValue: assetValues.hdmiAudioMode.findIndex((setting: any) => setting.value === shadow?.reported.config.HDMIAM) >= 0 ? shadow?.reported.config.HDMIAM : '',
            available: assetValues.hdmiAudioMode
          },
          {
            name: 'hdmic',
            label: 'hdmic',
            type: 'text',
            value:
              shadow && shadow.custom && shadow.custom.HDMIC !== 'Not available'
                ? showHdmicValue(shadow.custom.HDMIC, t)
                : t('general.not_available'),
            currentValue:
              shadow && shadow.custom && shadow.custom.HDMIC !== 'Not available'
                ? showHdmicValue(shadow.custom.HDMIC, t)
                : t('general.not_available'),
            disabled: true
          },
          {
            name: 'tvControlMode',
            label: 'tv_ctrl_mode',
            type: 'text',
            value:
              shadow && shadow.reported.config.TVCtrl
                ? shadow.reported.config.TVCtrl
                : t('general.not_available'),
            currentValue:
              shadow && shadow.reported.config.TVCtrl
                ? shadow.reported.config.TVCtrl
                : t('general.not_available'),
            disabled: true
          },
          {
            name: 'hdmiMode',
            label: 'hdmi_mode',
            type: 'list',
            value:
              shadow &&
                shadow.reported.config.HDMIM &&
                shadow &&
                shadow.custom &&
                shadow.custom.HDMID &&
                shadow.custom.HDMID.hdmiModes !== null &&
                Array.isArray(shadow.custom.HDMID.hdmiModes) &&
                shadow.custom.HDMID.hdmiModes.length > 1
                ? shadow.reported.config.HDMIM === '4k'
                  ? '2160p'
                  : shadow.reported.config.HDMIM
                : '',
            currentValue:
              shadow &&
                shadow.reported.config.HDMIM &&
                shadow &&
                shadow.custom &&
                shadow.custom.HDMID &&
                shadow.custom.HDMID.hdmiModes !== null &&
                Array.isArray(shadow.custom.HDMID.hdmiModes) &&
                shadow.custom.HDMID.hdmiModes.length > 1
                ? shadow.reported.config.HDMIM === '4k'
                  ? '2160p'
                  : shadow.reported.config.HDMIM
                : '',
            previousValue:
              shadow && shadow.prevDeviceShadow && shadow.prevDeviceShadow.config
                ? shadow.prevDeviceShadow.config.HDMIM === 'auto'
                  ? 'Auto'
                  : shadow.prevDeviceShadow.config.HDMIM === t('general.not_available')
                    ? t('general.not_available')
                    : 'Manual'
                : t('general.not_available'),
            available: hdmiModeAvailable
          },
          {
            name: 'hdmiResolution',
            label: 'hdmi_resolution',
            type: 'text',
            value:
              shadow && shadow.reported.config.HDMIR
                ? shadow.reported.config.HDMIR
                : t('general.not_available'),
            currentValue:
              shadow && shadow.reported.config.HDMIR
                ? shadow.reported.config.HDMIR
                : t('general.not_available'),
            previousValue:
              shadow && shadow.pCustom ? shadow.pCustom.pHDMIR : t('general.not_available'),
            disabled: true
          },
          {
            name: 'hdcpStatus',
            label: 'hdcp_status',
            type: 'text',
            value: shadow && shadow.custom ? shadow.custom.HDCP : t('general.not_available'),
            currentValue: shadow && shadow.custom ? shadow.custom.HDCP : t('general.not_available'),
            previousValue:
              shadow && shadow.pCustom ? shadow.pCustom.pHDCP : t('general.not_available'),
            disabled: true
          }
        ],
        hasChanged: false
      },
      {
        component: 'advanced',
        fields: [
          {
            name: 'gdprConsentVersion',
            label: 'gdpr',
            type: 'text',
            value:
              shadow &&
                shadow.reported.config.GDPRCsV &&
                shadow.reported.config.GDPRCsV !== 'Not available'
                ? parseInt(shadow.reported.config.GDPRCsV.toString())
                : t('general.not_available'),
            currentValue:
              shadow &&
                shadow.reported.config.GDPRCsV &&
                shadow.reported.config.GDPRCsV !== 'Not available'
                ? parseInt(shadow.reported.config.GDPRCsV.toString())
                : t('general.not_available'),
            disabled: true
          },
          {
            name: 'networkType',
            label: 'network_type',
            type: 'text',
            value:
              shadow && shadow.reported.config.nType
                ? shadow.reported.config.nType
                : t('general.not_available'),
            currentValue:
              shadow && shadow.reported.config.nType
                ? shadow.reported.config.nType
                : t('general.not_available'),
            disabled: true
          },
          {
            name: 'localPaddingTime',
            label: 'local_pad_time',
            type: 'list',
            value: '',
            currentValue: '',
            available: assetValues.setLocalPaddingTime
          },
          {
            name: 'unsubscribedChannels',
            label: 'unsub_channels',
            type: 'list',
            value:
              (shadow &&
                shadow.reported.config.unSuCh &&
                shadow.reported.config.unSuCh !== 'Not available' &&
                shadow.reported.config.unSuCh === 'show') ||
                (shadow && shadow.reported.config.unSuCh === 'hide')
                ? shadow.reported.config.unSuCh
                : '',
            currentValue:
              (shadow &&
                shadow.reported.config.unSuCh &&
                shadow.reported.config.unSuCh !== 'Not available' &&
                shadow.reported.config.unSuCh === 'show') ||
                (shadow && shadow.reported.config.unSuCh === 'hide')
                ? shadow.reported.config.unSuCh
                : '',
            available: assetValues.unsubscribedChannels
          },
          assetValues.changeChannelVisibility.length !== 0
            ? {
              name: 'cpFilter',
              label: 'cp_filter',
              type: 'check',
              value: '',
              currentValue: '',
              available: assetValues.changeChannelVisibility
            }
            : {}
        ],
        hasChanged: false
      },
      {
        component: 'parental_mode',
        fields: [
          {
            name: 'parentalPinValue',
            label: 'new_parental_pin',
            type: 'text',
            value: '',
            currentValue: ''
          },
          // {
          //     name: 'masterPinStatus',
          //     label: 'parental_pinStatus',
          //     type: 'text',
          //     disabled: true,
          //     value:
          //       shadow && shadow.custom && shadow.custom.PIN !== 'Not available'
          //       ? getPinStatus(shadow.custom.PIN, t)
          //       : t('general.not_available'),
          //     currentValue:
          //       shadow && shadow.custom && shadow.custom.PIN !== 'Not available'
          //       ? getPinStatus(shadow.custom.PIN, t)
          //       : t('general.not_available')
          // },
          assetValues.hideSetParentalMode.length !== 0 &&
            assetValues.hideSetParentalMode[0].value === 'false'
            ? {
              name: 'parentalModeVisibility',
              label: 'parental_visibility',
              type: 'list',
              value: '',
              currentValue: '',
              available: assetValues.parentalModeVisibility
            }
            : {},
          assetValues.hideSetParentalMode.length !== 0 &&
            assetValues.hideSetParentalMode[0].value === 'false'
            ? {
              name: 'parentalModeRating',
              label: 'parental_rating',
              type: 'list',
              value: assetValues.setParentalMode.findIndex((setting: any) => setting.value === shadow?.reported.config.pRatg) >= 0 ? shadow?.reported.config.pRatg : '',
              currentValue: assetValues.setParentalMode.findIndex((setting: any) => setting.value === shadow?.reported.config.pRatg) >= 0 ? shadow?.reported.config.pRatg : '',
              available: assetValues.setParentalMode
            }
            : {}
        ],
        hasChanged: false
      },
      {
        component: 'accessibility',
        fields: [
          {
            name: 'visualImpAudio',
            label: 'audio_description',
            type: 'radio',
            value:
              shadow &&
              shadow.reported.config.visImpAu &&
              getAccessibilityVal(shadow.reported.config.visImpAu),
            currentValue:
              shadow &&
              shadow.reported.config.visImpAu &&
              getAccessibilityVal(shadow.reported.config.visImpAu),
            available: assetValues.visualImpAudio
          },
          {
            name: 'hearImpSu',
            label: 'hearing_mode',
            type: 'radio',
            value:
              shadow &&
              shadow.reported.config.hearImpSu &&
              getAccessibilityVal(shadow.reported.config.hearImpSu),
            currentValue:
              shadow &&
              shadow.reported.config.hearImpSu &&
              getAccessibilityVal(shadow.reported.config.hearImpSu),
            available: assetValues.hearingDisabilities
          }
        ],
        hasChanged: false
      },
      {
        component: 'startup_standby',
        fields: [
          {
            name: 'activeStandbyTimer',
            label: 'standby_timer',
            type: 'list',
            value: assetValues.setActiveStandbyTimer.findIndex((setting: any) => setting.value === shadow?.reported.config.actStByT.toString() || '') >= 0 ? shadow?.reported.config.actStByT.toString() : '',
            currentValue: assetValues.setActiveStandbyTimer.findIndex((setting: any) => setting.value === shadow?.reported.config.actStByT.toString() || '') >= 0 ? shadow?.reported.config.actStByT.toString() : '',
            available: assetValues.setActiveStandbyTimer
          },
          {
            name: 'remoteCtrlMode',
            label: 'remote_ctrl_mode',
            type: 'text',
            value:
              shadow && shadow.reported.config.RCM && shadow.reported.config.RCM !== 'Not available'
                ? shadow.reported.config.RCM
                : t('general.not_available'),
            currentValue:
              shadow && shadow.reported.config.RCM && shadow.reported.config.RCM !== 'Not available'
                ? shadow.reported.config.RCM
                : t('general.not_available'),
            disabled: true
          },
          {
            name: 'powerSavingMode',
            label: 'power_saving_mode',
            type: 'radio',
            value:
              shadow &&
                shadow.reported.config.pSvM &&
                shadow.reported.config.pSvM !== 'Not available'
                ? shadow.reported.config.pSvM
                : '',
            currentValue:
              shadow &&
                shadow.reported.config.pSvM &&
                shadow.reported.config.pSvM !== 'Not available'
                ? shadow.reported.config.pSvM
                : '',
            available: assetValues.setPowerSavingMode
          }
        ],
        hasChanged: false
      },
      {
        component: 'update',
        fields: [
          {
            name: 'updateToSpecificFirmware',
            label: 'updateToSpecificFirmware',
            type: 'list',
            value: assetValues.updateToSpecificFirmware.findIndex((setting: any) => setting.value === shadow?.reported.config.usageID) >= 0 ? shadow?.reported.config.usageID : '',
            currentValue: assetValues.updateToSpecificFirmware.findIndex((setting: any) => setting.value === shadow?.reported.config.usageID) >= 0 ? shadow?.reported.config.usageID : '',
            available: assetValues.updateToSpecificFirmware
          }
        ],
        hasChanged: false
      },
      {
        component: 'account_security',
        fields: [
          {
            name: 'user_group',
            label: 'user_group_label',
            type: 'string',
            currentValue:
              assetValues &&
                shadow &&
                shadow.reported &&
                shadow.reported.config &&
                shadow.reported.config.usageID && shadow.reported.config.usageID !== 'Not available'
                ?
                getSummary(assetValues.updateToSpecificFirmware, shadow.reported.config.usageID, t)
                : t('general.not_available'),
            available: assetValues.updateToSpecificFirmware
          }
        ],
        hasChanged: false
      }
    ],
    logs: {
      fields: [
        {
          name: 'logLevelFields',
          label: 'logs',
          type: 'check',
          value: 0,
          currentValue: 0,
          available: assetValues.lumberjack_logLevelFields
        },
        {
          name: 'ui',
          label: 'ui',
          type: 'list',
          value: 0,
          currentValue: 0,
          available: assetValues.lumberjack_ui
        },
        {
          name: 'window',
          label: 'window',
          type: 'list',
          value: 0,
          currentValue: 0,
          available: assetValues.lumberjack_window
        }
      ],
      hasChanged: false,
      flush: {
        name: 'flush_logs',
        label: 'flush_logs',
        type: 'check',
        value: '',
        currentValue: '',
        available: [
          { label: 'none', selected: false },
          { label: 'now', selected: false },
          { label: 'in5min', selected: false }
        ],
        selected: false
      }
    },
    actions: {
      fields: [
        {
          name: 'partial_reset',
          label: 'partial_reset',
          type: 'check',
          value: '',
          currentValue: '',
          available: [
            { label: 'recordings', selected: false },
            { label: 'DRM', selected: false },
            { label: 'network', selected: false },
            { label: 'filesystem', selected: false }
          ],
          selected: false
        },
        {
          name: 'factory_reset',
          label: 'factory_reset',
          type: 'check',
          value: '',
          currentValue: '',
          available: [
            { label: 'hard', selected: false },
            { label: 'soft', selected: false }
          ],
          selected: false
        },
        {
          name: 'reboot',
          label: 'reboot',
          type: 'check',
          value: '',
          currentValue: '',
          available: [
            { label: 'forced', selected: false },
            { label: 'nonforced', selected: false }
          ],
          selected: false
        }
      ],
      hasChanged: false
    },
    hasChanged: false
  };
};

export const commandsMapper = (field: any): CommandParameter[] => {
  let command: CommandParameter[] = [];

  field.map((item: any) => {
    if (item) {
      switch (item.name) {
        //*************** AUDIO & SUBTITLES *************/

        case 'audioState':
          const otherField = field.filter(
            (el: any) => el.name === 'audioVolume' && el.currentValue != el.value
          );
          if (otherField.length > 0) {
            command.push({
              command: 'setAudioVolume',
              key: 'aud_state',
              jsonParameters: {
                mute: item.currentValue === 'true' ? true : false,
                value: convertAudioVolumeToSTB(parseInt(otherField[0].currentValue))
              }
            });
          } else {
            command.push({
              command: 'setAudioVolume',
              key: 'aud_state',
              jsonParameters: { mute: item.currentValue === 'true' ? true : false }
            });
          }
          break;

        case 'audioVolume':
          const difField = field.filter(
            (el: any) => el.name === 'audioState' && el.currentValue != el.value
          );
          if (difField.length > 0) {
            command.push({
              command: 'setAudioVolume',
              key: 'aud_state',
              jsonParameters: {
                mute: difField[0].currentValue === 'true' ? true : false,
                value: convertAudioVolumeToSTB(parseInt(item.currentValue))
              }
            });
          } else {
            command.push({
              command: 'setAudioVolume',
              key: 'aud_state',
              jsonParameters: { value: convertAudioVolumeToSTB(parseInt(item.currentValue)) }
            });
          }
          break;

        case 'subtitles':
          command.push({
            command: 'setSubtitleMode',
            key: 'subtitles',
            jsonParameters: { subs: parseInt(item.currentValue) }
          });
          break;

        case 'uiLanguage':
          command.push({
            command: 'setUILanguage',
            key: 'ui_language',
            jsonParameters: { UILg: item.currentValue }
          });
          break;

        case 'primarySubtitleLanguage':
          command.push({
            command: 'setPreferredSubtitleLanguage',
            key: 'primary_subt',
            jsonParameters: { pSuLg: item.currentValue }
          });
          break;

        case 'primaryAudioLanguage':
          command.push({
            command: 'setPreferredAudioLanguage',
            key: 'primary_aud',
            jsonParameters: { pAuLg: item.currentValue }
          });
          break;

        //*************** HDMI ****************/

        case 'hdmiMode':
          command.push({
            command: 'setHDMIMode',
            key: 'hdmi_mode',
            jsonParameters: {
              HDMIM: item.currentValue === '2160p' ? '4k' : item.currentValue
            }
          });
          break;

        case 'hdmiAudioMode':
          command.push({
            command: 'setHDMIAudioMode',
            key: 'aud_mode',
            jsonParameters: { HDMIAM: item.currentValue }
          });
          break;

        //*************** ADVANCED ****************/

        case 'localPaddingTime':
          command.push({
            command: 'setLocalPaddingTime',
            key: 'local_pad_time',
            jsonParameters: { padding: parseInt(item.currentValue, 10) }
          });
          break;

        case 'unsubscribedChannels':
          const fieldDif = field.filter(
            (el: any) => el.name === 'cpFilter' && el.currentValue != el.value
          );
          const unsubscribed = item.currentValue === 'show' ? true : false;

          if (fieldDif.length > 0) {
            command.push({
              command: 'changeChannelVisibility',
              key: 'unsub_channels',
              jsonParameters: {
                unsubscribed,
                CPFiltering: fieldDif[0].currentValue
              }
            });
          } else {
            command.push({
              command: 'changeChannelVisibility',
              key: 'unsub_channels',
              jsonParameters: { unsubscribed }
            });
          }
          break;

        case 'cpFilter':
          const fieldD = field.filter(
            (el: any) => el.name === 'unsubscribedChannels' && el.currentValue != el.value
          );

          if (fieldD.length > 0) {
            command.push({
              command: 'changeChannelVisibility',
              key: 'cp_filter',
              jsonParameters: {
                CPFiltering: item.currentValue,
                unsubscribed: fieldD[0].currentValue === 'show' ? true : false
              }
            });
          } else {
            command.push({
              command: 'changeChannelVisibility',
              key: 'cp_filter',
              jsonParameters: { CPFiltering: item.currentValue }
            });
          }

          break;

        //*************** ACCESSIBILITY ****************/

        case 'visualImpAudio':
          command.push({
            command: 'setAudioDescription',
            key: 'audio_description',
            jsonParameters: { visImpAu: item.currentValue === 'true' }
          });
          break;

        case 'hearImpSu':
          command.push({
            command: 'setHearingDisabilityMode',
            key: 'hearing_mode',
            jsonParameters: { hearImpSu: item.currentValue === 'true' }
          });
          break;

        //*************** STARTUP & STANDBY ****************/

        case 'activeStandbyTimer':
          command.push({
            command: 'setActiveStandbyTimer',
            key: 'standby_timer',
            jsonParameters: { actStByT: parseInt(item.currentValue) }
          });
          break;

        case 'powerSavingMode':
          command.push({
            command: 'setPowerSavingMode',
            key: 'power_saving_mode',
            jsonParameters: { pSvM: item.currentValue }
          });
          break;

        //*************** UPDATE ****************/

        case 'updateToSpecificFirmware':
          command.push({
            command: 'updateToSpecificFirmware',
            key: 'updateToSpecificFirmware',
            jsonParameters: { firmwareImageUrl: parseInt(item.currentValue) }
          });
          break;

        default:
          return command;
      }
    }
  });

  return Object.values(
    command.reduce((acc, cur) => Object.assign(acc, { [cur.command]: cur }), {})
  );
};

export const parentalModeMapper = (items: any) => {
  let command: CommandParameter[] = [];
  const visibility = items[0].fields.filter((it: any) => it.name === 'parentalModeVisibility');
  const pRatg = items[0].fields.filter((it: any) => it.name === 'parentalModeRating');
  const changedFields = items[0].fields.filter((field: any) => field.value != field.currentValue);

  if (changedFields) {
    changedFields.map((item: any) => {
      if (item.name === 'parentalPinValue') {
        command.push({
          command: 'setParentalPin',
          key: 'new_parental_pin',
          jsonParameters: { pin: item.currentValue }
        });
      }
      if (item.name === 'parentalModeVisibility' || item.name === 'parentalModeRating') {
        const jsonParameters =
          visibility && visibility[0] && visibility[0].currentValue !== visibility[0].value
            ? {
              pRatg: parseInt(pRatg[0].currentValue),
              visibility: visibility[0].currentValue
            }
            : {
              pRatg: parseInt(pRatg[0].currentValue)
            };
        command.push({
          command: 'setParentalMode',
          key: 'parental_visibility',
          jsonParameters
        });
      }
    });
  }
  return Object.values(
    command.reduce((acc, cur) => Object.assign(acc, { [cur.command]: cur }), {})
  );
};

export const actionsMapper = (item: any) => {
  let command: CommandParameter = {
    command: '',
    key: '',
    jsonParameters: {}
  };
  let jsonParameters: any = {};

  item[0].available.map((element: AssetValue) => {
    if (element.selected) {
      jsonParameters[element.label.toLowerCase()] = element.selected;
    }
  });

  if (item) {
    switch (item[0].name) {
      case 'reboot':
        command = {
          command: 'reboot',
          key: 'reboot',
          jsonParameters: { forced: item[0].available[0].selected }
        };

        break;

      case 'factory_reset':
        command = {
          command: 'doFactoryReset',
          key: 'factory_reset',
          jsonParameters: { hard: item[0].available[0].selected }
        };

        break;

      case 'partial_reset':
        command = {
          command: 'doPartialReset',
          key: 'partial_reset',
          jsonParameters
        };

        break;
    }
  }

  return command;
};

function convertAudioVolumeToSTB(volume: number) {
  if (isNaN(volume)) {
    return -1;
  }
  return volume;
}

function getAvailableHdmiModes(hdmiMode: any, labelDefault: string) {
  const values = [];

  values.push(
    { label: labelDefault, value: '', active: false },
    { label: 'Auto', value: 'auto', active: true }
  );

  if (hdmiMode && hdmiMode.hdmiModes && typeof hdmiMode.hdmiModes !== 'string') {
    hdmiMode.hdmiModes.map((item: any) => {
      values.push({
        label: `${item.formats}/${item.fieldRate}`,
        value: item.option,
        active: true
      });
    });
  }

  return values;
}

function getAudioVolumeValues(currentValue: any, t: TFunction) {
  let audioVol: any = [];
  if (currentValue !== -1) {
    audioVol.push({
      label: currentValue.toString(),
      value: currentValue.toString(),
      active: false
    });
  } else {
    audioVol.push({ label: t('general.not_available'), value: -1, active: false });
  }

  for (let i = 0; i <= 100; i++) {
    if (i === 0 || !(i % 5)) {
      audioVol.push({ label: i.toString(), value: i.toString(), active: true });
    }
  }
  return audioVol;
}

const showHdmicValue = (hdmic: string, t: TFunction) => {
  if (hdmic === 'true') return t('settings.label_connected');
  if (hdmic === 'false') return t('settings.label_disconnected');
  return t('general.not_available');
};

const getPinStatus = (pin: string, t: TFunction) => {
  if (pin === 'Enabled') {
    return t('settings.parental_mode.parental_pin.unlocked');
  } else if (pin === 'Disabled') {
    return t('settings.parental_mode.parental_pin.locked');
  } else {
    return t('general.not_available');
  }
};

function getAccessibilityVal(value: string) {
  if (value !== 'Not available') {
    return value === 'true' || value === 'false' ? value : '';
  } else {
    return '';
  }
}

interface simpleObj {
  value: any;
  label: any;
}

function getSummary(assetValues: any, usageIdValue: any, t: TFunction) {
  const foundLabel: simpleObj[] = [];

  if (assetValues.length > 0) {
    assetValues.forEach((item: any) => {
      if (usageIdValue === item.value) {
        foundLabel.push(item);
      }
    });
  }

  if (foundLabel.length > 0) return foundLabel;
  else return t('general.not_available');
}
