import { FieldItem } from '../../popUp/redux/types';
import { DVB_COLUMNS } from '../../redux/enums';

export const DVB_MANAGEMENT_SEARCH = 'DVB_MANAGEMENT_SEARCH';
export const DVB_MANAGEMENT_SEARCH_BUILD = 'DVB_MANAGEMENT_SEARCH_BUILD';
export const DVB_MANAGEMENT_SEARCH_FIELDS = 'DVB_MANAGEMENT_SEARCH_FIELDS';
export const DVB_MANAGEMENT_SEARCH_SET_COLUMN = 'DVB_MANAGEMENT_SEARCH_SET_COLUMN';
export const DVB_MANAGEMENT_SEARCH_SET_FIELD = 'DVB_MANAGEMENT_SEARCH_SET_FIELD';
export const DVB_MANAGEMENT_SEARCH_TOGGLE = 'DVB_MANAGEMENT_SEARCH_TOGGLE';
export const DVB_MANAGEMENT_SEARCH_VALUE = 'DVB_MANAGEMENT_SEARCH_VALUE';

export interface DVBManagementSearchAction {
  type:
    | typeof DVB_MANAGEMENT_SEARCH
    | typeof DVB_MANAGEMENT_SEARCH_BUILD
    | typeof DVB_MANAGEMENT_SEARCH_FIELDS
    | typeof DVB_MANAGEMENT_SEARCH_SET_COLUMN
    | typeof DVB_MANAGEMENT_SEARCH_SET_FIELD
    | typeof DVB_MANAGEMENT_SEARCH_TOGGLE
    | typeof DVB_MANAGEMENT_SEARCH_VALUE;
  payload: any;
}

export interface SearchColumn {
  key: DVB_COLUMNS;
  value: string;
  isActive: boolean;
}

export interface DVBSearch {
  isActive?: boolean;
  columns: Array<SearchColumn>;
  fields: Array<FieldItem>;
  value?: string;
}
