import {
  ProgressBarAction,
  PROGRESS_BAR_CHANGE,
  PROGRESS_BAR_CLOSE,
  PROGRESS_BAR_DURATION,
  PROGRESS_BAR_ACTIVE
} from './types';

const initialState = { progress: 0, close: true, duration: 0, isActive: false };

export default function(state = initialState, action: ProgressBarAction) {
  switch (action.type) {
    case PROGRESS_BAR_CHANGE:
      return { ...state, progress: action.payload };
    case PROGRESS_BAR_CLOSE:
      return { ...state, close: action.payload };
    case PROGRESS_BAR_DURATION:
      return { ...state, duration: action.payload };
    case PROGRESS_BAR_ACTIVE:
      return { ...state, isActive: true };
    default: {
      return state;
    }
  }
}
