export const ADVANCED_SEARCH_REQUEST = 'ADVANCED_SEARCH_REQUEST';
export const ADVANCED_SEARCH_SUCCESS = 'ADVANCED_SEARCH_SUCCESS';
export const ADVANCED_SEARCH_FAILURE = 'ADVANCED_SEARCH_FAILURE';
export const ADVANCED_SEARCH_CHANGE_PAGE = 'ADVANCED_SEARCH_CHANGE_PAGE';
export const INITIALIZE_DEVICE_CUSTOM_FIELDS = 'INITIALIZE_ADVANCED_SEARCH_DEVICE_CUSTOM_FIELDS';


export interface AdvancedSearchState {
  results: Array<any>;
  resultsToShow: Array<any>;
  totalElements: number;
  lastEvaluatedData: string;
}

export interface AdvancedSearchAction {
  type: string;
  payload: any;
}

export interface AdvancedSearchItem {
  udid: string;
  hhid: string;
  deviceMod: string;
  connection: string;
  softwareVersion: string;
  kdsn?: string;
  deviceFamily?: string;
}
