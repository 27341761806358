import React, { useState } from 'react'
import T from 'core/i18n/t'
import { getModule } from '../status/rf-tuning-table-component'
import { RfTuningLimitValueRange, RfTuningLimitValues } from '../status/redux/types'
import { isEmpty } from 'core/utils/general-utils'
import { FrequencyLimitsType } from './rf-tuning-dvbc-monitoring-component'
import { SortAsc, SortDesc } from 'assets/svg'

export type DVBCRowType = {
  LF: string
  PWR: string
  BERPO: string
  LS: number
  TSID: string
}

type RfTuningDvbcTableProps = {
  dvbc: Array<DVBCRowType>
  frequencyLimits: FrequencyLimitsType
}

type ColumnOrder = 'ASC' |  'DESC'

type ToggleType = {
  ASC: ColumnOrder
  DESC: ColumnOrder
}

const toggleOrder: ToggleType = {
  ASC: 'DESC',
  DESC: 'ASC',
}

function thresholds(frequency: string, value: any, column: string, frequencyLimits: FrequencyLimitsType) {
  if (isEmpty(frequencyLimits)) {
    return 'is-default'
  }

  const thresholds = frequencyLimits.find((e: RfTuningLimitValues) => e.lf === frequency);

  if (!thresholds) {
    return ' is-default';
  }

  let thresholdColumn: RfTuningLimitValueRange = { above: '', below: '' };
  switch (column) {
    case 'all':
      const keys = Object.keys(thresholds);
      let isDanger = false;
      for (let i = 0; i < keys.length; i++) {
        switch (keys[i]) {
          case 'pwr':
            if (
              parseFloat(value.PWR) < parseFloat(thresholds.pwr.below) ||
              parseFloat(value.PWR) > parseFloat(thresholds.pwr.above)
            ) {
              isDanger = true;
            }
            break;
          case 'ber':
            if (
              parseFloat(value.BERPO) > parseFloat(thresholds.ber.below) ||
              parseFloat(value.BERPO) > parseFloat(thresholds.ber.above)
            ) {
              isDanger = true;
            }
            break;
        }        
      }      
      return isDanger ? ' is-danger' : ' is-success';
    case 'pwr':
      thresholdColumn = thresholds[column];
      break;
    case 'ber':
      thresholdColumn.below = thresholds[column].below
      thresholdColumn.above = thresholds[column].above
      return (parseFloat(value) > parseFloat(thresholdColumn.below) ||
              (parseFloat(value) > parseFloat(thresholdColumn.above)))
                ? ' is-danger'
                : '';
    default:
      return ' ';
  }

  return thresholdColumn &&
    (parseFloat(value) < parseFloat(thresholdColumn.below) ||
      (thresholdColumn.above && parseFloat(value) > parseFloat(thresholdColumn.above)))
    ? ' is-danger'
    : '';
}

function sortList(current: DVBCRowType, prev: DVBCRowType, order: ColumnOrder) {
  if(order === 'ASC') {
    return parseInt(current.LF) - parseInt(prev.LF)
  }
  return parseInt(prev.LF) - parseInt(current.LF)
}

export default function RfTuningDvbcMonitoringTable({dvbc, frequencyLimits}: RfTuningDvbcTableProps) {
  const [order, setOrder] = useState<ColumnOrder>('ASC')
  return (
    <>
      <div className="table-optimized" role="table">
        <div className="flex-table header has-status" role="rowgroup">
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_lf</T>
            <span
              className="clickable-icon"
              onClick={() => {
                setOrder(toggleOrder[order])
              }}
            >
              {order === 'ASC' ? <SortAsc className="icon icon-svg has-margin-left-sm" /> : <SortDesc className="icon icon-svg has-margin-left-sm" />}
            </span>
          </div>
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_pwr</T>
          </div>
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_berpo</T>
          </div>
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_ls</T>
          </div>
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_tsid</T>
          </div>
          <div className="flex-cell" role="columnheader">
            <T>rf_tuning.full_frequency_modulation</T>
          </div>
        </div>
        {dvbc.sort((current, prev) => sortList(current, prev, order)).map((row, index) => (
          <div className="flex-table row" role="rowgroup" key={index}>
            <div className="has-status" role="cell">
                <span className={`is-status${thresholds(row.LF, row, 'all', frequencyLimits)}`}></span>
              </div>
            <div className="flex-cell" role="cell">
              {row.LF}
            </div>
            <div className={`flex-cell${thresholds(row.LF, row.PWR, 'pwr', frequencyLimits)}`} role="cell">
              {row.PWR}
            </div>
            <div className={`flex-cell${thresholds(row.LF, row.BERPO, 'ber', frequencyLimits)}`} role="cell">
              {row.BERPO}
            </div>
            <div className="flex-cell" role="cell">
              {row.LS}
            </div>
            <div className="flex-cell" role="cell">
              {row.TSID}
            </div>
            <div className="flex-cell" role="cell">
            {getModule(row.LF.toString(), frequencyLimits)}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
