import { t } from 'i18next'
import React, { useState } from 'react'
import { ServicesManagementInterface } from '../../types'
import DeleteConfirm from '../DeleteConfirm'
import ServicesForm from '../ServicesForm'
import './index.scss'

function DeleteConfirmBody() {
  return <div>{t('anonymizer.delete_service_confirm_text_01')} <b>{t('anonymizer.delete_service_confirm_text_02')}</b> {t('anonymizer.delete_service_confirm_text_03')}</div>
}

export default function ServicesManagement({ service, deleteService, updateService }: ServicesManagementInterface) {
  const [serviceFormActive, setServiceFormActive] = useState(false)
  const [serviceDeleteActive, setServiceDeleteActive] = useState(false)

  return (
    <>
      <div className="is-flex columns management-container">
        <div className="column">
          {t('anonymizer.service_expiration')}: {service.exp.value} {service.exp.unit}
        </div>
        <div className="column button-container">
          <button className="button is-primary" onClick={() => setServiceFormActive(true)}>{t('anonymizer.edit')}</button>
          <button className="button is-primary" onClick={() => setServiceDeleteActive(true)}>{t('anonymizer.delete')}</button>
        </div>
      </div>
      {serviceFormActive && <ServicesForm setActive={setServiceFormActive} serviceAction={updateService} service={service}/>}
      {serviceDeleteActive && <DeleteConfirm setActive={setServiceDeleteActive} action={deleteService} params={service.id} title={t('anonymizer.delete_service_confirm_title')} body={<DeleteConfirmBody/>}/>}
    </>
  )
}
