import React, { Component } from 'react';
import './sidebar-style.scss';
import { ChevronLeft } from 'assets/svg';
import { SidebarState } from './redux/types';

interface SidebarProps {
  sidebarIsHidden: any;
  sideStatus: SidebarState;
  children: any;
  leftSide: boolean;
}

export default class Sidebar extends Component<SidebarProps> {
  render() {
    const { sidebarIsHidden, sideStatus, leftSide } = this.props;

    return (
      <div className={`is-relative ${leftSide ? 'left-side has-margin-right-sm' : ''}`}>
        <button className="sidebar-button" onClick={() => sidebarIsHidden()}>
          <ChevronLeft
            className={`icon has-padding-xs icon-white icon-svg icon-xx-small ${
              sideStatus.sidebar.collapsed ? '' : 'icon-180'
            }`}
          />
        </button>
        <div
          className={`sidebar-sticky ${
            sideStatus.sidebar.collapsed ? 'sidebar-content-collapsed' : ''
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
