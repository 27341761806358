import React, { Component } from 'react';
import T from 'core/i18n/t';
import PopUpComponent from 'core/pop-up';
import { TFunction } from 'i18next';
import { CardSizes } from 'core/card/enum';

interface OverlayModalComponentProps {
  isActive: boolean;
  title: string;
  content?: string;
  close: any;
  execute: any;
  size?: CardSizes;
  t: TFunction;
  children?: JSX.Element | Array<JSX.Element> | string
}

export default class OverlayModalComponent extends Component<OverlayModalComponentProps> {
  render() {
    const { isActive, title, content, close, execute, size, t, children } = this.props;
    return (
      <PopUpComponent isActive={isActive} size={size}>
        <h4 className="title is-4">{t(title)}</h4>
        {content && <p className="content">{t(content)}</p>}
        <div className="content">
          {children}
        </div>
        <div className="modal-buttons">
          <button className="button is-primary" onClick={execute}>
            <T>general.confirm</T>
          </button>
          <button className="button is-secondary" onClick={close}>
            <T>general.cancel</T>
          </button>
        </div>
      </PopUpComponent>
    );
  }
}
