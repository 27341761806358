import DateUtils from 'core/utils/date-utils';
import { TFunction } from 'i18next';
import { SubscriptionItem } from './types';

function formatPrice(price: string, t: TFunction) {
  return t('general.currency') + (Math.floor(parseFloat(price) * 100) / 100).toFixed(2);
}

export default (data: SubscriptionItem, t: TFunction): SubscriptionItem => {
  return {
    id: data ? data.id : '',
    transactionId: data ? data.transactionId : '',
    name: data ? data.name : '',
    purchaseDate: data ? DateUtils.formatDate(data.purchaseDate) : '',
    renewalDate: data ? DateUtils.formatDate(data.renewalDate) : '',
    price: data ? formatPrice(data.price, t) : ''
  };
};

export const getLabels = (t: TFunction): SubscriptionItem => ({
  id: t('account.subscriptions.column_subscription_id'),
  transactionId: t('account.subscriptions.column_transaction_id'),
  name: t('account.subscriptions.column_module_name'),
  purchaseDate: t('account.subscriptions.column_purchase_date'),
  renewalDate: t('account.subscriptions.column_renewal_date'),
  price: t('account.subscriptions.column_price')
});
