import React, { Component, Fragment } from 'react';
import './device-detail-styles.scss';
import DeviceDetailOverviewContainer from './overview/device-detail-overview-container';
import DeviceHardDiskContainer from './hard-disk/device-hard-disk-container';
import DvrContainer from './dvr';
import VodContainer from './vod';
import SpeedTestContainer from './speed-test';
import RfTuningContainer from './rf-tuning';
import ActivityLogContainer from './activity-log';
import ChannelsContainer from './channels';
import SubscriptionsContainer from './subscriptions';
import ProvisionedDevicesContainer from './provisioned-devices';
import { getCurrentNavbarMenu } from 'application/header/navbar/redux/actions';
import Navbar from 'application/header/navbar';
import { NavbarState } from 'application/header/navbar/redux/types';
import LogsContainer from './logs';
import SettingsComponent from './_settings/settings-container';

interface DeviceDetailProps {
  openModal: any;
  closeModal: any;
  hhid: string;
  id: string;
  serialNr: string;
  navbar: NavbarState;
  currentPage: string;
  resetFilter: () => void;
}

export default class DeviceDetailComponent extends Component<DeviceDetailProps> {
  componentWillUnmount(): void {
    this.props.resetFilter()
  }

  render() {
    const { currentPage } = this.props;
    const nav = this.props.navbar.navbar.get('device');

    return (
      <Fragment>
        <Navbar
          hhid={this.props.hhid}
          id={this.props.id}
          serialNr={this.props.serialNr}
          currentPage={currentPage}
        />
        <div className="section has-margin-bottom-lg">
          <div className="tile is-ancestor flex-wrap">
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'overview' && (
              <DeviceDetailOverviewContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'provisioned_devices' && (
              <ProvisionedDevicesContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'subscriptions' && (
              <SubscriptionsContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'rf_tuning' && (
              <RfTuningContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'channels' && (
              <ChannelsContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'speed_test' && (
              <SpeedTestContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'dvr' && (
              <DvrContainer
                hhid={this.props.hhid}
                id={this.props.id}
                serialNr={this.props.serialNr}
              />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'hard_disk' && (
              <DeviceHardDiskContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'vod' && (
              <VodContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'activity_log' && (
              <ActivityLogContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'logs' && (
              <LogsContainer hhid={this.props.hhid} id={this.props.id} />
            )}
            {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'settings' && (
              <SettingsComponent hhid={this.props.hhid} id={this.props.id} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
