import apiClient from 'core/api'
import { Dispatch } from 'redux'
import { ANONYMIZER_KEY, servicesUrl } from '../consts'
import { ServiceType, ServiceTypeResponse } from '../types'
import { add, remove } from 'core/spinner/redux/actions'
import authService from 'core/auth/auth-service'
import { addToaster } from 'core/toaster/redux/actions'
import { serviceCreateSuccess, serviceDeleteSuccess, serviceListSuccess, serviceUpdateSuccess } from '../redux/services/actions'
import { resetService, setService } from '../redux/service/actions'

export function fetchAllServices() {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()

    return apiClient.get(servicesUrl()).then(
      (response: { data: { services: ServiceTypeResponse } }) => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(serviceListSuccess(response.data.services))
      },
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: 'error_messages.VIPOC_ERROR_060',
            type: 'danger'
          })
        )
      },
    )
  }
}

export function createService(params: ServiceType) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()
    const { id, exp } = params
    return apiClient.post(servicesUrl(`/${id}`), { exp }).then(
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(serviceCreateSuccess(params))
        dispatch(setService(params))
        return dispatch(addToaster({
          title: 'home.icon_anonymizer',
          message: 'anonymizer.create_service_success',
          type: 'success'
        }))
      },
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: 'error_messages.VIPOC_ERROR_061',
            type: 'danger'
          })
        )
      },
    )
  }
}

export function updateService(params: ServiceType) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()
    const { id, exp } = params
    return apiClient.put(servicesUrl(`/${id}`), { exp }).then(
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(setService(params))
        dispatch(serviceUpdateSuccess(params))
        return dispatch(addToaster({
          title: 'home.icon_anonymizer',
          message: 'anonymizer.update_service_success',
          type: 'success'
        }))
      },
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: 'error_messages.VIPOC_ERROR_062',
            type: 'danger'
          })
        )
      },
    )
  }
}

export function deleteService(serviceName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(add(ANONYMIZER_KEY, {}))
    await authService.refreshToken()

    return apiClient.delete(servicesUrl(`/${serviceName}`)).then(
      () => {        
        dispatch(remove(ANONYMIZER_KEY))
        dispatch(resetService())
        dispatch(serviceDeleteSuccess(serviceName))
        return dispatch(addToaster({
          title: 'home.icon_anonymizer',
          message: 'anonymizer.delete_service_success',
          type: 'success'
        }))
      },
      () => {
        dispatch(remove(ANONYMIZER_KEY))
        return dispatch(
          addToaster({
            title: 'home.icon_anonymizer',
            message: 'error_messages.VIPOC_ERROR_063',
            type: 'danger'
          })
        )
      },
    )
  }
}
