import { SelectedFilters } from 'core/multiple-search/redux/types';
import moment from 'moment';

function groupProperties(rootKey: string, key: string) {
  return rootKey + key.charAt(0).toUpperCase() + key.slice(1);
}

function mapObject(rootKey: string, obj: any, offset: number) {
  return Object.keys(obj)
    .map(
      (key) =>
        `${groupProperties(rootKey, key)}=${
          moment(obj[key]).isValid()
            ? moment(obj[key]).subtract(offset, 'hour').toISOString()
            : obj[key]
        }`
    )
    .join('&');
}

function mapArray(key: string, params: Array<any>, offset: number) {
  return params.map((obj, index) => mapObject(`${key}[${index}]`, obj, offset)).join('&');
}

const queryString = (params: any, offset: number) => {
  if (params) {
    return Object.keys(params)
      .map((key) =>
        Array.isArray(params[key])
          ? mapArray(key, params[key], offset)
          : typeof params[key] === 'object'
          ? mapObject(key, params[key], offset)
          : `${key}=${encodeURIComponent(params[key])}`
      )
      .join('&');
  }
};

export function createArrayToQueryString(data: SelectedFilters[]) {
  if (!data) {
    return;
  }
  const queryData: any = {} as any;
  data.forEach((item: SelectedFilters) => {
    if (typeof item.value === 'string') {
      queryData[item.name] =
        item.valueToSend ||
        item.value
          .split(',')
          .map((value) => value.replace(/^EMPTY$/gi, '#'))
          .join(',');
    } else {
      queryData[item.name] = item.value;
    }
  });
  return queryData;
}

export default queryString;
