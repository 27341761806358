import {
  DVBManagementRegionAction,
  DVB_MANAGEMENT_REGION_SELECT,
  DVB_MANAGEMENT_REGIONS_FAILURE,
  DVB_MANAGEMENT_REGIONS_SUCCESS,
  DVBManagementRegion,
  DVBRegion
} from './types';

const initialState: DVBManagementRegion = {
  items: [],
  itemsChanged: undefined,
  itemsSearch: undefined
};

export default function(state = initialState, action: DVBManagementRegionAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_REGION_SELECT: {
      if (!action.payload) {
        state.selected = undefined;
      }

      state.items.forEach((region: DVBRegion) => {
        if (region.rootId == action.payload) {
          state.selected = region;
        }
      });

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_REGIONS_SUCCESS: {
      let regions: Array<DVBRegion> = [];
      const payload: Array<DVBRegion> = action.payload && action.payload.items;

      if (payload) {
        payload.forEach((item: any) => {
          item.nodes &&
            item.nodes.forEach((node: any) => {
              node.id = node.nodeId;
              node.label = node.nodeLabel;
            });

          regions.push({
            regionLabel: item.regionLabel || item.label,
            label: item.regionLabel || item.label,
            regionId: item.regionId || item.id,
            id: item.regionId || item.id,
            rootId: item.rootId || item.regionId || item.id,
            parentRegionId: item.parentRegionId,
            children: item.nodes || item.children,
            canEditChange: item.canEditChange === undefined ? true : item.canEditChange,
            changeLabel: item.changeLabel,
            changeId: item.changeId
          });
        });
      }

      return {
        ...state,
        items: action.payload.isSearch ? state.items : regions,
        itemsSearch: action.payload && action.payload.isSearch ? regions : undefined
      };
    }

    case DVB_MANAGEMENT_REGIONS_FAILURE:
    default: {
      return state;
    }
  }
}
