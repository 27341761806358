import { TFunction } from 'i18next';
import { GeneralDeviceState, VipDeviceDetailSmart } from '../../general/redux/types';

export function formatKbytes(value: any, decimals = 2) {
  //replacing whitespaces and kb + converting kb to bytes
  const bytes = parseFloat(value.replace(/\D/g, ''));
  if (isNaN(bytes)) {
    return '-';
  }
  if (bytes === 0) return '0';
  const base = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  //const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(base));

  const result = parseFloat((bytes / Math.pow(base, i)).toFixed(dm));
  return result;
}

function isValid(hddst: string, t: TFunction) {
  if (
    hddst === 'not_mounted' ||
    hddst === 'not_plugged' ||
    hddst === 'reading_and_recording' ||
    hddst === 'recording' ||
    hddst === 'standby'
  ) {
    return t(`hard_disk.subtitle_status_${hddst}`);
  }
  return t('hard_disk.subtitle_status_invalid');
}

export default (
  data: any,
  notAvailableLabel: string,
  general: GeneralDeviceState,
  t: TFunction,
  threshold: any,
): any => {
  const hologram = general.shadow;

  function getSmartRaw(
    smartList: Array<VipDeviceDetailSmart>,
    field: string,
    notAvailableLabel: string
  ) {
    const smartRaw: VipDeviceDetailSmart | undefined =
      typeof smartList !== 'string' ? smartList.find((smart) => smart.name === field) : undefined;
    return smartRaw ? smartRaw.raw : notAvailableLabel;
  }

  function getSmartInfo(
    smartList: Array<VipDeviceDetailSmart>,
    field: string,
    notAvailableLabel: string,
  ) {
    const smartRaw: VipDeviceDetailSmart | undefined =
      typeof smartList !== 'string' ? smartList.find((smart) => smart.name === field) : undefined;
    return smartRaw ? smartRaw : notAvailableLabel;
  }

  function getType(value: any) {
    const arrayValue = value.split(' ');
    const bytes = parseFloat(arrayValue[0]);
    const type = arrayValue[1] ? arrayValue[1].toUpperCase() : 'B';
    if (bytes === 0) return 'GB';
    const base = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(base));
    return sizes[sizes.findIndex((i) => i === type) + i];
  }
  return {
    identifiers: {
      serialNo: hologram && hologram.custom ? hologram.custom.HDDSN : notAvailableLabel,
      brand:
        hologram && hologram.custom && hologram.custom.BRNME
          ? hologram.custom.BRNME
          : notAvailableLabel,
      model: hologram && hologram.custom ? hologram.custom.HDDPNr : notAvailableLabel
    },
    status: {
      status:
        hologram && hologram.custom && hologram.custom.HDDST
          ? isValid(hologram.custom.HDDST, t)
          : notAvailableLabel,
      temperature:
        hologram && hologram.custom && hologram.custom.HDDTP
          ? hologram.custom.HDDTP + (hologram.custom.HDDTP !== 'Not available' ? ' ºC' : '')
          : notAvailableLabel
    },
    quota: {
      total:
        hologram && hologram.custom && hologram.custom.HDDTS
          ? formatKbytes(hologram.custom.HDDTS)
          : '-',
      used:
        hologram && hologram.custom && hologram.custom.HDDUS
          ? formatKbytes(hologram.custom.HDDUS)
          : '-',
      typeTotal:
        hologram && hologram.custom && hologram.custom.HDDTS ? getType(hologram.custom.HDDTS) : '',
      typeUsed:
        hologram && hologram.custom && hologram.custom.HDDUS ? getType(hologram.custom.HDDUS) : ''
    },
    errors: {
      errorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'error_rate', notAvailableLabel)
          : notAvailableLabel,
      reallocatedSector:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'reallocated_sector_ct', notAvailableLabel)
          : notAvailableLabel,
      seekErrorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'seek_error_rate', notAvailableLabel)
          : notAvailableLabel,
      offlineUncorrectable:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'offline_uncorrectable', notAvailableLabel)
          : notAvailableLabel,
      udmaCrcError:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'udma_crc_error_count', notAvailableLabel)
          : notAvailableLabel,
      multizoneErrorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'multi_zone_error_rate', notAvailableLabel)
          : notAvailableLabel,
      spinUpTime:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'spin_up_time', notAvailableLabel)
          : notAvailableLabel,
      startStopCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'start_stop_count', notAvailableLabel)
          : notAvailableLabel,
      powerOnHours:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'power_on_hours', notAvailableLabel)
          : notAvailableLabel,
      spinRetryCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'spin_retry_count', notAvailableLabel)
          : notAvailableLabel,
      calibrationRetryCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'calibration_retry_count', notAvailableLabel)
          : notAvailableLabel,
      powerCycleCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'power_cycle_count', notAvailableLabel)
          : notAvailableLabel,
      powerOffRetractCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'power_off_retract_count', notAvailableLabel)
          : notAvailableLabel,
      loadCycleCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'load_cycle_count', notAvailableLabel)
          : notAvailableLabel,
      reallocatedEventCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'reallocated_event_count', notAvailableLabel)
          : notAvailableLabel,
      currentPendingSector:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartRaw(hologram.custom.HDDSMART, 'current_pending_sector', notAvailableLabel)
          : notAvailableLabel
    },
    errorsThresholdId: {
      errorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'error_rate', notAvailableLabel)
          : notAvailableLabel,
      reallocatedSector:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'reallocated_sector_ct', notAvailableLabel)
          : notAvailableLabel,
      seekErrorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'seek_error_rate', notAvailableLabel)
          : notAvailableLabel,
      offlineUncorrectable:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'offline_uncorrectable', notAvailableLabel)
          : notAvailableLabel,
      udmaCrcError:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'udma_crc_error_count', notAvailableLabel)
          : notAvailableLabel,
      multizoneErrorRate:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'multi_zone_error_rate', notAvailableLabel)
          : notAvailableLabel,
      spinUpTime:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'spin_up_time', notAvailableLabel)
          : notAvailableLabel,
      startStopCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'start_stop_count', notAvailableLabel)
          : notAvailableLabel,
      powerOnHours:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'power_on_hours', notAvailableLabel)
          : notAvailableLabel,
      spinRetryCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'spin_retry_count', notAvailableLabel)
          : notAvailableLabel,
      calibrationRetryCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'calibration_retry_count', notAvailableLabel)
          : notAvailableLabel,
      powerCycleCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'power_cycle_count', notAvailableLabel)
          : notAvailableLabel,
      powerOffRetractCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'power_off_retract_count', notAvailableLabel)
          : notAvailableLabel,
      loadCycleCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'load_cycle_count', notAvailableLabel)
          : notAvailableLabel,
      reallocatedEventCount:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'reallocated_event_count', notAvailableLabel)
          : notAvailableLabel,
      currentPendingSector:
        hologram && hologram.custom && hologram.custom.HDDSMART
          ? getSmartInfo(hologram.custom.HDDSMART, 'current_pending_sector', notAvailableLabel)
          : notAvailableLabel
    },
    errorsThresholdInfo: {
      threshold
    }
  };
};
