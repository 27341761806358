export const CHANNEL_ASSOCIATIONS_REGIONS_GET = 'CHANNEL_ASSOCIATIONS_REGIONS_GET';
export const CHANNEL_ASSOCIATIONS_REGIONS_GET_FAILURE = 'CHANNEL_ASSOCIATIONS_REGIONS_GET_FAILURE';
export const CHANNEL_ASSOCIATIONS_REGIONS_GET_SUCCESS = 'CHANNEL_ASSOCIATIONS_REGIONS_GET_SUCCESS';
export const CHANNEL_REGIONS_GET = 'CHANNEL_REGIONS_GET';
export const CHANNEL_REGIONS_GET_FAILURE = 'CHANNEL_REGIONS_GET_FAILURE';
export const CHANNEL_REGIONS_GET_SUCCESS = 'CHANNEL_REGIONS_GET_SUCCESS';

export enum ChannelsAssociationsRegionsType {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export interface ChannelsAssociationsRegions {
    regionId: number;
    regionLabel: string;
    parentRegionId: number;
    regionalLCN: number;
}

export interface ChannelsAssociationsRegionsState {
    isActive: boolean;
    isFetching: boolean;
    filteredCount: number;
    regions: ChannelsAssociationsRegions[];
}

export interface ChannelsRegions {
    regionId: number ,
    regionLabel: string,
    parentRegionId: number,
    regionalLCN: number
}

export interface ChannelsRegionsState {
    isActive: boolean;
    isFetching: boolean;
    filteredCount: number;
    regions: ChannelsRegions[];
}

export interface ChannelsAssociationsRegionsAction {
    payload: any;
    type:
      | typeof CHANNEL_ASSOCIATIONS_REGIONS_GET
      | typeof CHANNEL_ASSOCIATIONS_REGIONS_GET_FAILURE
      | typeof CHANNEL_ASSOCIATIONS_REGIONS_GET_SUCCESS
      | typeof CHANNEL_REGIONS_GET
      | typeof CHANNEL_REGIONS_GET_FAILURE
      | typeof CHANNEL_REGIONS_GET_SUCCESS
  }