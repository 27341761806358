import { envs } from "application/envHandler"
import { HashType, ServiceType } from "./types"

export const ANONYMIZER_SERVICE_LIST_SUCCESS = 'ANONYMIZER_SERVICE_LIST_SUCCESS'
export const ANONYMIZER_SERVICE_LIST_FAILURE = 'ANONYMIZER_SERVICE_LIST_FAILURE'
export const ANONYMIZER_SERVICE_CREATE_SUCCESS = 'ANONYMIZER_SERVICE_CREATE_SUCCESS'
export const ANONYMIZER_SERVICE_UPDATE_SUCCESS = 'ANONYMIZER_SERVICE_UPDATE_SUCCESS'
export const ANONYMIZER_SERVICE_DELETE_SUCCESS = 'ANONYMIZER_SERVICE_DELETE_SUCCESS'

export const ANONYMIZER_HASH_FETCH_SUCCESS = 'ANONYMIZER_HASH_FETCH_SUCCESS'
export const ANONYMIZER_HASH_FETCH_FAILURE = 'ANONYMIZER_HASH_FETCH_FAILURE'
export const ANONYMIZER_HASH_DELETE_SUCCESS = 'ANONYMIZER_HASH_DELETE_SUCCESS'
export const ANONYMIZER_HASH_RESET = 'ANONYMIZER_HASH_DELETE_SUCCESS'

export const SET_ANONYMIZER_SERVICE = 'SET_ANONYMIZER_SERVICE'
export const RESET_ANONYMIZER_SERVICE = 'RESET_ANONYMIZER_SERVICE'

export const ANONYMIZER_KEY = 'ANONYMIZER'

export const servicesUrl = (path = '') => `${envs.REACT_APP_API_URL}/integration/services${path}`
export const hashesUrl = (path = '') => `${envs.REACT_APP_API_URL}/integration/anonymizations${path}`

export const initialServicesState = [] as Array<ServiceType>

export const initialHashState: HashType = {
  exp: '',
  id: '',
  aid: '',
}

export const HASH_FILTER_OPTIONS = ['id', 'aid']

export const initialServiceState: ServiceType | number = 0
