import { v4 as uuid } from 'uuid';

export const ID_MAPPING_EDITABLE_LIST_REQUEST = 'ID_MAPPING_EDITABLE_LIST_REQUEST';
export const ID_MAPPING_EDITABLE_LIST_SUCCESS = 'ID_MAPPING_EDITABLE_LIST_SUCCESS';
export const ID_MAPPING_EDITABLE_LIST_FAILURE = 'ID_MAPPING_EDITABLE_LIST_FAILURE';
export const ID_MAPPING_UNLOCK_ENTRY = 'ID_MAPPING_UNLOCK_ENTRY';
export const ID_MAPPING_EDITABLE_REMOVE_ENTRY = 'ID_MAPPING_EDITABLE_REMOVE_ENTRY';
export const ID_MAPPING_LOCK_ENTRY = 'ID_MAPPING_LOCK_ENTRY';
export const ID_MAPPING_MANAGEMENT_ADD_NEW_ENTRY = 'ID_MAPPING_MANAGEMENT_ADD_NEW_ENTRY';
export const ID_MAPPING_EDITABLE_CHANGE_ENTRY = 'ID_MAPPING_EDITABLE_CHANGE_ENTRY';
export const ID_MAPPING_SET_FILTER = 'ID_MAPPING_MANAGEMENT_SET_FILTER';
export const ID_MAPPING_SET_FILTER_VALUE = 'ID_MAPPING_MANAGEMENT_SET_FILTER_VALUE';
export const ID_MAPPING_MANAGEMENT_CLEAR = 'ID_MAPPING_MANAGEMENT_CLEAR';
interface IdMappingEditableState {
  results: IdMappingEditableResult;
  message: any;
  currentFilter: any;
  currentFilterValue: any;
}

interface IdMappingEditableAction {
  type: string;
  payload: any;
}

const initialState: IdMappingEditableState = {
  results: {
    content: [],
    totalPages: 0,
    hasNext: false,
    totalElements: 0,
    size: 0,
    number: 0,
    numberOfElements: 0,
    schemaProperties: {
      columns: [],
      primaryKey: '',
      secondaryKeys: []
    }
  },
  currentFilter: null,
  currentFilterValue: null,
  message: null
};

export interface IdMappingEditableResult {
  content: Array<IdMappingRow>;
  totalPages: number;
  hasNext: boolean;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
  schemaProperties: {
    primaryKey: string;
    secondaryKeys: Array<string>;
    columns: Array<string>;
  };
}

export interface IdMappingRow {
  entry: any;
  entryStatus: string;
  editable?: boolean;
  isNew: boolean;
  uuid: any;
  oldId?: any;
}
export default function(state = initialState, action: IdMappingEditableAction) {
  switch (action.type) {
    case ID_MAPPING_MANAGEMENT_CLEAR: {
      return { ...initialState };
    }
    case ID_MAPPING_UNLOCK_ENTRY: {
      const index = action.payload;
      const newContent = [...state.results.content];
      newContent[index].editable = true;
      return { ...state, results: { ...state.results, content: newContent }, message: null };
    }
    case ID_MAPPING_LOCK_ENTRY: {
      const index = action.payload;
      const newContent = [...state.results.content];
      newContent[index].editable = false;
      return { ...state, results: { ...state.results, content: newContent }, message: null };
    }
    case ID_MAPPING_EDITABLE_CHANGE_ENTRY: {
      const { index, key, value } = action.payload;
      const oldContent = state.results.content.map(c => {
        return {
          ...c,
          entry: { ...c.entry }
        };
      });

      const newContent = oldContent.map(c => {
        return {
          ...c,
          entry: { ...c.entry }
        };
      });
      // @ts-ignore
      newContent[index].entry[key] = value;
      if (newContent[index].isNew === false) {
        if (
          newContent[index].entry[state.results.schemaProperties.primaryKey] !==
          oldContent[index].entry[state.results.schemaProperties.primaryKey]
        ) {
          newContent[index].entryStatus = 'REPLACED';
          newContent[index].oldId = newContent[index].oldId
            ? newContent[index].oldId
            : oldContent[index].entry[state.results.schemaProperties.primaryKey];
        } else {
          newContent[index].entryStatus = 'EDITED';
        }
      }

      return { ...state, results: { ...state.results, content: newContent }, message: null };
    }
    case ID_MAPPING_MANAGEMENT_ADD_NEW_ENTRY: {
      if (state.results && state.results.content) {
        const newContent = state.results.content;
        newContent.unshift({
          entry: [],
          entryStatus: 'CREATED',
          isNew: true,
          editable: true,
          uuid: uuid(),
          oldId: null
        });
        return { ...state, results: { ...state.results, content: newContent } };
      }

      return { ...state, results: { ...state.results }, message: null };
    }
    case ID_MAPPING_EDITABLE_REMOVE_ENTRY: {
      const index = action.payload;
      const newContent = [...state.results.content];
      const entry = newContent[index];
      entry.entryStatus = 'DELETED';

      if (entry.isNew) newContent.splice(index, 1);
      return { ...state, results: { ...state.results, content: newContent }, message: null };
    }
    case ID_MAPPING_EDITABLE_LIST_SUCCESS: {
      const payload = {
        ...action.payload,
        content: action.payload.content.map((c: any) => ({
          entry: { ...JSON.parse(c.entry), isNew: false, editable: false, uuid: uuid() },
          editable: false,
          isNew: false
        })),
        totalPages: action.payload.totalPages,
        pageNumber: action.payload.pageNumber
      };
      return { ...state, results: payload, message: null };
    }
    case ID_MAPPING_EDITABLE_LIST_FAILURE: {
      return { ...state, message: action.payload.status === 422 ? action.payload.error : null };
    }
    case ID_MAPPING_SET_FILTER: {
      const currentFilter = action.payload && action.payload.length > 0 ? action.payload : null;

      return {
        ...state,
        currentFilter,
        currentFilterValue: currentFilter === null ? null : state.currentFilterValue
      };
    }
    case ID_MAPPING_SET_FILTER_VALUE: {
      return { ...state, currentFilterValue: action.payload };
    }
    default: {
      return { ...state };
    }
  }
}
