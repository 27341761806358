import React, { Component } from 'react';
import { Close, CloseCircle, WarningCircle, TickCircle } from 'assets/svg';
import { ToasterMessage, ToasterObject } from './redux/types';
import T from 'core/i18n/t';
import './toaster-styles.scss';

interface ToasterItemProps {
  deactivateToaster?: (toaster: ToasterObject) => void;
  index?: number;
  noIcon?: boolean;
  removeToaster?: (toaster: ToasterObject) => void;
  toaster?: ToasterObject;
  tooltip?: ToasterMessage;
}

export default class ToasterItemComponent extends Component<ToasterItemProps> {
  renderIcon(type: string) {
    switch (type) {
      case 'success':
        return <TickCircle className="icon icon-svg icon-white-fill" />;
      case 'danger':
        return <CloseCircle className="icon icon-svg icon-white-fill" />;
      case 'info':
      case 'warning':
      default:
        return <WarningCircle className="icon icon-svg icon-white-fill" />;
    }
  }

  removeToaster(toaster: ToasterObject) {
    const { deactivateToaster, removeToaster } = this.props;
    if (deactivateToaster && removeToaster) {
      deactivateToaster(toaster);
      setTimeout(() => {
        removeToaster(toaster);
      }, 500);
    }
  }

  render() {
    const { index, noIcon, toaster, tooltip, removeToaster } = this.props;
    let toasterMessage = tooltip ? tooltip : toaster?.toaster;

    if (!toasterMessage) {
      toasterMessage = {
        message: '',
        type: 'warning'
      };
    }

    if (toaster && !toaster.hasTimeout) {
      setTimeout(() => {
        this.removeToaster(toaster);
      }, 10000);
    }

    return (
      <div
        className={`toaster toaster-${toasterMessage.type}
        ${!tooltip && !toaster?.isActive ? 'toaster-out' : ''}`}
        key={index}
      >
        <div className="caption">
          {!noIcon && (
            <div className="caption-media toaster-media">
              {this.renderIcon(toasterMessage.type)}
            </div>
          )}
          <div className="caption-text">
            {toasterMessage.title && (
              <strong>
                <T>{toasterMessage.title}</T>
              </strong>
            )}
            <p>
              <T>{toasterMessage.message}</T>
            </p>
          </div>
          {removeToaster && toaster && (
            <button className="caption-close" onClick={() => this.removeToaster(toaster)}>
              <Close className="icon icon-svg is-x-small" />
            </button>
          )}
        </div>
      </div>
    );
  }
}
