import { TFunction } from 'i18next';
import { RegionalizationChannelsAssociations, RegionalizationChannelsAssociationsType } from './types';

export default (data: RegionalizationChannelsAssociations, t: TFunction): RegionalizationChannelsAssociations => {
    const channelsType = getChannelsType(data);
    return {
      channelId: data.channelId,
      mediaId: data.mediaId ,
      name: data.name,
      regionalLCN: data.regionalLCN
    };
};

function getChannelsType(result: any) {
    if (result) {
      if (!result.failed || result.failed === 0) {
        return RegionalizationChannelsAssociationsType.SUCCESS;
      } else if (!result.succeeded || result.succeeded === 0) {
        return RegionalizationChannelsAssociationsType.ERROR;
      }
    }
  
    return RegionalizationChannelsAssociationsType.WARNING;
}
