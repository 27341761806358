import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import ConfigurationsComponent from './configurations-component';
import { clearFields } from '../redux/actions';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import { runCommands, changeParentalPin, setParentalPinError } from '../redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  t: state.i18n.t,
  settings: state.devices.detail._settings
});

export default connect(mapStateToProps, {
  clearFields,
  openModal,
  closeModal,
  runCommands,
  changeParentalPin,
  setParentalPinError
})(ConfigurationsComponent);
