import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import AccountSecurityComponent from './account-security-component';
import { fetchShadow, calls } from '../general/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  general: state.devices.detail.general,
  t: state.i18n.t,
  settings: state.devices.detail._settings
});

export default connect(mapStateToProps, {
  fetchShadow,
  calls
})(AccountSecurityComponent);
