export enum CardSizes {
  NONE = '',
  SMALL = 'is-small',
  MEDIUM = 'is-medium',
  LARGE = 'is-large'
}

export enum CardPaddings {
  NONE = 'none',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg'
}
