import {
  FIRST_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  LAST_PAGE,
  CHANGE_PAGE_SIZE,
  RESET_PAGE_NUMBER,
  ORDER_ITEMS,
  CHANGE_FIELD_ORDER,
  TO_PAGE
} from './types';

export function goToFirstPage(id: string) {
  return {
    type: FIRST_PAGE,
    payload: {
      id
    }
  };
}

export function goToNextPage(id: string) {
  return {
    type: NEXT_PAGE,
    payload: {
      id
    }
  };
}

export function goToPreviousPage(id: string) {
  return {
    type: PREVIOUS_PAGE,
    payload: {
      id
    }
  };
}

export function goToLastPage(id: string, lastPage: number) {
  return {
    type: LAST_PAGE,
    payload: {
      id,
      lastPage
    }
  };
}

export function goToPage(id: string, page: number) {
  return {
    type: TO_PAGE,
    payload: {
      id,
      page
    }
  };
}

export function changePageSize(id: string, newSize: number) {
  return {
    type: CHANGE_PAGE_SIZE,
    payload: {
      id,
      newSize
    }
  };
}

export function orderItems(id: string, field: string) {
  return {
    type: ORDER_ITEMS,
    payload: {
      id,
      field
    }
  };
}


export function resetPageNumber() {
  return {
    type: RESET_PAGE_NUMBER
  };
}

export function changefieldOrder(id: string, sortField: string, sortOrder: string) {
  return {
    type: CHANGE_FIELD_ORDER,
    payload: {
      id,
      sortField,
      sortOrder
    }
  };
}
