import Carousel from './carousel-component';
import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { setActiveIndex } from './redux/action';

const mapStateToProps = (state: ApplicationState) => ({
  images: state.devices.detail.general.bucket,
  carousel: state.carousel,
  t: state.i18n.t
});

export default connect(mapStateToProps, { setActiveIndex })(Carousel);
