import React from 'react';
import { Link } from 'react-router-dom';
import T from 'core/i18n/t';
import 'modules/home/shortcut/shortcut-styles.scss';

export type ShortcutProps = {
  title: string;
  icon: any;
  link: string;
  category: string;
  group?: string;
};

export const ShortcutComponent = (props: ShortcutProps) => (
  <div className="column is-narrow is-button is-link is-hovered">
    <Link to={{ pathname: props.link, state: { title: props.title } }}>
      <div className="box is-flex box-card-is-link">
        <div className="level">
          <div className="level-item has-text-centered">
            <div>
              {props.icon}

              <h6 className="subtitle is-6">
                <T>{props.title}</T>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
);
