import moment from 'moment';
import { IdMappingResult, IdMappingResultRow } from './reducer';

export default (data: any): IdMappingResult => {
  const response = data;
  return {
    totalElement: response.totalElement,
    totalPages: response.totalPages,
    size: response.size,
    content: response.content.map(buildContent)
  };
};

function buildContent(item: IdMappingResultRow): IdMappingResultRow {
  return {
    uuid: item.uuid ? item.uuid : 'b7a1c417583a9f-4554-b3992c22ff0517a #',
    status: item.status ? item.status : 'PENDING #',
    message: item.message ? item.message : 'Waiting for automatic process #',
    lastUpdate: item.lastUpdate ? moment(item.lastUpdate).format('YYYY-MM-DD HH:mm:ss') : '',
    behavior: item.behavior ? item.behavior : '',
    username: item.username ? item.username : 'system',
    opco: item.opco ? item.opco : '',
    collisions: item.collisions ? item.collisions : ''
  };
}
