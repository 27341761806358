import { Moment } from 'moment';

export const FREQUENCY_HISTORY = 'FREQUENCY_SCAN';
export const FREQUENCY_SCAN_HISTORY_REQUEST = 'FREQUENCY_SCAN_HISTORY_SUCCESS';
export const FREQUENCY_SCAN_FAILURE = 'FREQUENCY_SCAN_FAILURE';
export const TOGGLE_ACCORDION = 'TOGGLE_ACCORDION';
export const RF_TUNING_DVB_TYPES = 'RF_TUNING_DVB_TYPES';
export const RF_TUNING_STATUS_THRESHOLDS = 'RF_TUNING_STATUS_THRESHOLDS';
export const RF_TUNING_SET_SHOW_GRAPHIC = 'RF_TUNING_SET_SHOW_GRAPHIC';
export const RF_TUNING_STATUS = 'RF_TUNING_STATUS';
export const RF_TUNING_STATUS_SUCCESS = 'RF_TUNING_STATUS_SUCCESS';
export const RF_TUNING_STATUS_FAILURE = 'RF_TUNING_STATUS_FAILURE';
export const RF_TUNING_SELECT_FREQUENCY_TAB = 'RF_TUNING_SELECT_FREQUENCY_TAB';
export const RF_TUNING_STATUS_THRESHOLDS_SUCCESS = 'RF_TUNING_STATUS_THRESHOLDS_SUCCESS';
export const RF_TUNING_STATUS_DVB_TYPES_SUCCESS = 'RF_TUNING_STATUS_DVB_TYPES_SUCCESS';
export const RF_TUNING_CLEAR_CACHE = 'RF_TUNING_CLEAR_CACHE';
export const RF_TUNING_FORCE_CLEAR_CACHE = 'RF_TUNING_FORCE_CLEAR_CACHE';
export const RF_TUNING_WAITING_COMMAND = 'RF_TUNING_WAITING_COMMAND';

export type FREQUENCY_SCAN = typeof FREQUENCY_HISTORY;
export type FREQUENCY_SCAN_HISTORY_REQUEST = typeof FREQUENCY_SCAN_HISTORY_REQUEST;
export type FREQUENCY_SCAN_FAILURE = typeof FREQUENCY_SCAN_FAILURE;
export type TOGGLE_ACCORDION = typeof TOGGLE_ACCORDION;

export interface RfTuningLimitValueRange {
  below: string;
  above: string;
}

export interface RfTuningLimitValues {
  lf: string;
  modulation: string;
  snr: RfTuningLimitValueRange;
  pwr: RfTuningLimitValueRange;
  ber: RfTuningLimitValueRange;
}

export interface FrequencyScanAction {
  type: FREQUENCY_SCAN | FREQUENCY_SCAN_HISTORY_REQUEST | FREQUENCY_SCAN_FAILURE | TOGGLE_ACCORDION;
  payload?: any;
}

export interface RfTuningStatusState {
  results: Array<RfTuningStatusResult>;
  allResults: any;
  lastResultDate: Moment;
  activityLog: any;
  currentFrequencyTab: string;
  currentGraphic: string;
  frequencyListToShow: Array<string>;
  frequencyLimits: any;
  resultToGraph: any;
  resultHistory: Array<RfTuningHistoryResult>;
  resultHistoryToShow?: Array<RfTuningHistoryResult>;
  waitingCommandExecution: boolean;
}

export interface RfTuningStatusResult {
  TSID: string;
  LF: string;
  LS: string;
  PWR: string;
  BERPO: string;
  modulation: string;
  type: string;
}

export interface FrequencyScanFilter {
  pageSize: number;
  pageNumber: number;
  order: string;
}

export interface RfTuningHistoryResult {
  accordionActive: boolean;
  accrodionIndex: number;
  frequencyHistories: Array<RfTuningStatusResult>;
  finishedAt: string;
  totalElements: number;
}

export const dropdownLabel: any = {
  dropdown_dvbc: 'rf_tuning.dropdown_dvbc',
  dropdown_dvbc_history: 'rf_tuning.dropdown_dvbc_history',
  dropdown_dvbc_graphic: 'rf_tuning.dropdown_dvbc_graphic',
  dropdown_dvbt: 'rf_tuning.dropdown_dvbt',
  dropdown_dvbt_history: 'rf_tuning.dropdown_dvbt_history',
  dropdown_dvbt_graphic: 'rf_tuning.dropdown_dvbt_graphic'
};
