import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SubscriptionsComponent from './subscriptions-component';
import { fetchSubscriptions } from './redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  subscriptions: state.devices.detail.subscriptions,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  fetchSubscriptions
})(SubscriptionsComponent);
