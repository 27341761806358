import { combineReducers } from 'redux';

import idMappingForm from './form/redux/reducer';
import idMappingGrid from './grid/redux/reducer';
import idMappingModal from './modal-upload/redux/reducer';
import idMappingCollision from './collision/redux/reducer';
import idMappingEditable from './editable-mapping/redux/reducer';

export default combineReducers({
  grid: idMappingGrid,
  form: idMappingForm,
  modal: idMappingModal,
  collision: idMappingCollision,
  editable: idMappingEditable
});
