import React from 'react';
import { ShortcutComponent } from 'modules/home/shortcut';
import T from 'core/i18n/t';
import { ShortcutProps } from '../shortcut-component';

type ShortcutGroupProps = {
  title: string;
  shortcuts: Array<ShortcutProps>;
};

export const ShortcutGroup = (props: ShortcutGroupProps) => (
  <section>
    <section className="has-margin-top-lg has-margin-bottom-md">
      <h4 className="subtitle is-4">
        <T>{props.title}</T>
      </h4>
    </section>
    <section>
      <div className="columns">
        {props.shortcuts.map((shortcut, key) => (
          <ShortcutComponent key={key} {...shortcut} />
        ))}
      </div>
    </section>
  </section>
);
