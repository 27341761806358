import { SIDEBAR_IS_HIDDEN, SidebarAction, SidebarState } from './types';

const initialState: SidebarState = {
  sidebar: {
    collapsed: false
  }
};

export default function(state = initialState, { type, payload }: SidebarAction) {
  switch (type) {
    case SIDEBAR_IS_HIDDEN:
      return {
        ...state,
        sidebar: { collapsed: !state.sidebar.collapsed }
      };
    default: {
      return { ...state };
    }
  }
}
