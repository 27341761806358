import React, { Component, Fragment } from 'react';
import { Cpu, SoundOff, SoundOn, Save, Sync, Tv } from 'assets/svg';
import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { TFunction } from 'i18next';
import { VipDeviceDetail } from '../redux/types';
import { isEmpty } from 'core/utils/general-utils';
import { formatKbytes } from '../../hard-disk/redux/mapper';
import { DeviceShadow, GeneralDeviceState } from '../../general/redux/types';

interface DeviceDetailInfoActionsProps {
  vip: VipDeviceDetail;
  id: string;
  t: TFunction;
  shadow: DeviceShadow;
}
class DeviceDetailInfoActions extends Component<DeviceDetailInfoActionsProps> {
  getUsage(GeneralDeviceState: DeviceShadow, t: TFunction) {
    const total: any = formatKbytes(GeneralDeviceState.custom.HDDTS);
    const used: any = formatKbytes(GeneralDeviceState.custom.HDDUS);

    if (!isEmpty(GeneralDeviceState) && GeneralDeviceState.custom.HDDTS) {
      if (used && total && !isNaN(used) && !isNaN(total)) {
        return `${parseFloat(((used / total) * 100).toString()).toFixed(2)}%`;
      }
    }
    return t('general.not_available');
  }

  render() {
    const { vip, t, shadow } = this.props;
    return (
      <Fragment>
        <div className="has-padding-sm">
          <div className="columns has-margin-bottom-none has-padding-top-xs">
            <div className="column">
              <h5 className="text is-h5">{t('overview.label_device_overview')}</h5>
            </div>
            <div className="column is-narrow">
              <Sync className="icon icon-svg icon-x-small is-hidden" />
            </div>
          </div>

          <div className="columns has-margin-top-xs has-padding-bottom-sm">
            <div className="column has-padding-top-none">
              <InfoActionIcon
                title={t('overview.icon_volume')}
                subtitle={
                  shadow && shadow.custom
                    ? shadow.custom.CAL + (shadow.custom.CAL !== 'Not available' ? ' %' : '')
                    : t('general.not_available')
                }
              >
                {shadow && shadow.custom && shadow.custom.MUTE && shadow.custom.MUTE === 'false' ? (
                  <SoundOn className="icon icon-svg icon-highlighted has-margin-right-md is-medium" />
                ) : (
                  <SoundOff className="icon icon-svg icon-highlighted has-margin-right-md is-medium" />
                )}
              </InfoActionIcon>

              <InfoActionIcon
                title={t('overview.icon_cpu_temperature')}
                subinfo={
                  shadow && shadow.custom && shadow.custom.CPUTp
                    ? t('overview.label_temp') +
                      ': ' +
                      shadow.custom.CPUTp +
                      (shadow.custom.CPUTp !== 'Not available' ? ' ºC' : '')
                    : t('general.not_available')
                }
                subtitle={`${t('overview.label_load')}: ${
                  (shadow && shadow.custom && shadow.custom.CPULV && shadow.custom.CPULV) ||
                  t('general.not_available')
                }`}
              >
                <Cpu className="icon icon-highlighted has-margin-right-md is-medium" />
              </InfoActionIcon>
            </div>

            <div className="column has-padding-right-none has-padding-top-none">
              <InfoActionIcon
                title={t('overview.icon_manufacturer')}
                subtitle={
                  shadow &&
                  shadow.custom &&
                  shadow.custom.HDMID &&
                  shadow.custom.HDMID.manufacturerName
                    ? shadow.custom.HDMID.manufacturerName
                    : t('general.not_available')
                }
              >
                <Tv className="icon icon-highlighted has-margin-right-md is-medium" />
              </InfoActionIcon>

              <InfoActionIcon
                title={t('overview.icon_hard_disk')}
                subinfo={
                  shadow && shadow.custom && shadow.custom.HDDTP
                    ? t('overview.label_temp') +
                      ': ' +
                      shadow.custom.HDDTP +
                      (shadow.custom.HDDTP !== 'Not available' ? ' ºC' : '')
                    : t('general.not_available')
                }
                subtitle={
                  shadow && shadow.custom && shadow.custom.HDDTS
                    ? t('overview.label_usage') + ': ' + this.getUsage(shadow, t)
                    : t('general.not_available')
                }
              >
                <Save className="has-margin-right-md icon-highlighted icon is-medium" />
              </InfoActionIcon>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  vip: ownProps.vip,
  id: ownProps.id,
  t: state.i18n.t
});

export default connect(mapStateToProps)(DeviceDetailInfoActions);

const InfoActionIcon = ({ disabled, children, subtitle, subinfo, title }: any) => (
  <div className={`is-flex has-margin-top-md ${disabled ? 'column-disabled' : ''}`}>
    {children}
    <div>
      <p className="has-text-weight-bold text-is-size-sm">{title}</p>
      <p className="text is-size-sm width-full">{subinfo}</p>
      <p className="text is-size-sm width-full">{subtitle}</p>
    </div>
  </div>
);
