import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SelectComponent from './select-component';
import { addSelectedFilters } from '../../multiple-search/redux/action';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t
});

export default connect(mapStateToProps, { addSelectedFilters })(SelectComponent);
