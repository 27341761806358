import React, { Component } from 'react';
import { TFunction } from 'i18next';
import './channels-styles.scss';
import ProgressBarComponent from 'core/progress-bar';
import TableComponent from 'core/table/table-container';
import { columns } from './redux/config';
import { paginationChannels, paginationSpeedTest } from 'core/pagination-optimized/redux/config';
import { Sort } from 'core/table/redux/types';
import { SpectrumChannelsParam } from './redux/redux-channels/action';
import { ChannelsState } from './redux/redux-channels/type';

interface SpectrumChannelsProps {
  t: TFunction;
  action: (type: any, payload?: any) => void;
  push: (uri: string) => void;
  getChannels: (params: SpectrumChannelsParam) => void;
  channel: ChannelsState;
  getKalturaSync: () => void;
}

export default class SpectrumChannelsComponent extends Component<
  SpectrumChannelsProps,
  { isActive: boolean }
> {
  constructor(props: SpectrumChannelsProps) {
    super(props);
    this.state = { isActive: false };
  }

  redirectAction = (mediaId: number) => {
    this.props.push(`/management/spectrum/channels/${mediaId}/channels-associations`);
  };

  componentDidUpdate(prevProps: SpectrumChannelsProps) {
    if (prevProps.channel.isActive !== this.props.channel.isActive) {
      this.setState({ isActive: this.props.channel.isActive });
    }
  }

  componentDidMount() {
    this.props.getChannels({});
  }

  handleOpen = () => {
    this.props.getKalturaSync();
    this.setState({ isActive: true });
  };

  handleClose = () => {
    this.setState({ isActive: false });
  };

  render() {
    const { t } = this.props;
    return (
      <section className="section-spaced">
        <TableComponent
          className="has-padding-bottom-sm"
          id="spectrum.channels"
          title={t('spectrum.channels')}
          items={this.props.channel.channels}
          pagination={paginationChannels}
          onSearch={(filter: any, days?: number, comparator?: string) => {
            this.props.getChannels({
              pageNumber: ++filter.pageNumber,
              filter: filter,
              sort: {
                order: filter.sortOrder,
                column: filter.sortField
              }
            });
          }}
          buttonAction={{
            buttonName: 'spectrum.button_kaltura_sync',
            buttonClass: 'is-primary',
            method: this.handleOpen.bind(this)
          }}
          redirectAction={{
            name: 'spectrum.actions.details',
            method: this.redirectAction,
            parameter: 'mediaId'
          }}
          columns={columns}
          tableClasses="mb-5 pb-1 pt-5"
          totalElements={this.props.channel.totalCount}
          sortAction={{
            method: (sort: Sort) => {
              this.props.getChannels({ pageNumber: sort.pageNumber, sort });
            }
          }}
        />

        <ProgressBarComponent
          duration={100}
          title={t('spectrum.fetching_data_from').replace('{0}', ' ')}
          titleSpan={'Kaltura...'}
          bodyMsg={t('spectrum.kaltura_sync.updating_channels_msg')}
          isActive={this.state.isActive}
        />
      </section>
    );
  }
}
