import {
  RfTuningScanAction,
  RfTuningScanState,
  RF_TUNING_FAILURE,
  RF_TUNING_ADD_TO_FREQUENCY_LIST,
  RF_TUNING_CHANGE_CUSTOM_FREQQUENCY,
  RF_TUNING_FORM_CHANGE_SCAN_TYPE,
  RF_TUNING_SCAN,
  RF_TUNING_SCAN_CLEAR_CACHE,
  RF_TUNING_SCAN_WAITING_COMMAND,
  RF_TUNING_SUCCESS,
  RF_TUNING_DELETE_CUSTOM_FREQUENCY,
  RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST,
  RF_TUNING_CHANGE_DROPDOWN,
  RF_TUNING_SCAN_SUCCESS_HISTORY,
  RF_TUNING_SCAN_TOGGLE_ACCORDION
} from './types';
import mapper from '../../status/redux/mapper';
import { RfTuningHistoryResult, RfTuningStatusResult } from '../../status/redux/types';

const initialState: RfTuningScanState = {
  activityLog: null,
  results: [],
  customFrequencies: {
    currentFrequency: '',
    currentSymbrate: '',
    listCustomFrequencies: []
  },
  waitingCommandExecution: false,
  dropdownValue: 'rf_tuning.dropdown_dvbc',
  resultHistoryToShow: []
};

export default (state = initialState, { type, payload }: RfTuningScanAction) => {
  switch (type) {
    case RF_TUNING_SCAN_WAITING_COMMAND: {
      return { ...state, waitingCommandExecution: payload };
    }
    case RF_TUNING_SCAN:
    case RF_TUNING_FAILURE: {
      return {
        ...state
      };
    }
    case RF_TUNING_SUCCESS: {
      return {
        ...state,
        results:
          payload.response &&
          payload.response.message &&
          payload.response.message.DVBC &&
          payload.response.message.DVBC.map((item: any) => {
            return mapper(item);
          }),
        activityLog: {
          udid: payload.deviceId,
          status: payload.status,
          eventName: payload.name,
          lastManaged: payload.startDate,
          commandTraceId: payload.transactionID,
          logResponse: payload.response.message,
          logRequest: payload.request.message,
          progress: payload.request.progress ? payload.request.progress.replace('%', '') : null,
          error: payload.response.errorText
        }
      };
    }
    case RF_TUNING_FORM_CHANGE_SCAN_TYPE: {
      return { ...state, form: { scanType: payload, customFrequency: '', default: '140' } };
    }

    case RF_TUNING_CHANGE_CUSTOM_FREQQUENCY: {
      return {
        ...state,
        customFrequencies: {
          currentFrequency:
            payload.field === 'currentFrequency'
              ? payload.value
              : state.customFrequencies.currentFrequency,
          currentSymbrate:
            payload.field === 'currentSymbrate'
              ? payload.value
              : state.customFrequencies.currentSymbrate,
          listCustomFrequencies: state.customFrequencies.listCustomFrequencies
        }
      };
    }

    case RF_TUNING_SCAN_CLEAR_CACHE: {
      return {
        ...state,
        activityLog: null,
        results: [],
        customFrequencies: {
          currentFrequency: '',
          currentSymbrate: '',
          listCustomFrequencies: []
        },
        dropdownValue: 'rf_tuning.dropdown_dvbc',
        waitingCommandExecution: false,
        resultHistoryToShow: []
      };
    }

    case RF_TUNING_ADD_TO_FREQUENCY_LIST: {
      if(state.customFrequencies.listCustomFrequencies.findIndex(item => item.frequency  ===  payload.frequency) < 0) {  
        state.customFrequencies.listCustomFrequencies.push({
          frequency: payload.frequency,
          symbrate: payload.symbrate
        });
      }
      state.customFrequencies.currentFrequency = '';
      state.customFrequencies.currentSymbrate = '';

      return {
        ...state
      };
    }

    case RF_TUNING_DELETE_CUSTOM_FREQUENCY: {
      state.customFrequencies.listCustomFrequencies.splice(payload.index, 1);

      return {
        ...state
      };
    }

    case RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST: {
      state.customFrequencies.listCustomFrequencies = [];

      return {
        ...state
      };
    }

    case RF_TUNING_CHANGE_DROPDOWN: {
      return {
        ...state,
        dropdownValue: payload.option
      };
    }

    case RF_TUNING_SCAN_SUCCESS_HISTORY: {
      return {
        ...state,
        resultHistoryToShow: payload.results.map((result: any, index: number) => {
          return {
            ...result,
            frequencyHistories: result.frequencyHistories.map((freqHist: RfTuningStatusResult) => {
              return mapper(freqHist);
            }),
            accordionActive: false,
            accrodionIndex: index
          };
        })
      };
    }

    case RF_TUNING_SCAN_TOGGLE_ACCORDION: {
      let resultHistoryToShow: RfTuningHistoryResult[] = [];

      if (state.resultHistoryToShow) {
        resultHistoryToShow = Object.values(state.resultHistoryToShow);
        resultHistoryToShow.forEach((obj: any) => {
          if (obj.accrodionIndex === payload.index) {
            obj.accordionActive = !obj.accordionActive;
          } else {
            obj.accordionActive = false;
          }
        });
      }

      return {
        ...state,
        resultHistoryToShow
      };
    }

    default:
      return state;
  }
};
