export const DVR_FAILURE = 'DVR_FAILURE';
export const DVR_SUCCESS = 'DVR_SUCCESS';
export const DVR_INFO = 'DVR_INFO';
export const DVR_DELETE_RECORDING = 'DVR_DELETE_RECORDING';
export const DVR_DELETE_RECORDING_SUCCESS = 'DVR_DELETE_RECORDING_SUCCESS';
export const DVR_DELETE_RECORDING_FAILURE = 'DVR_DELETE_RECORDING_FAILURE';
export const DVR_CLEAR_CACHE = 'DVR_CLEAR_CACHE';
export const DVR_TIMELINE_RECORDINGS = 'DVR_TIMELINE_RECORDINGS';
export const DVR_TIMELINE_RECORDINGS_SUCCESS = 'DVR_TIMELINE_RECORDINGS_SUCCESS';
export const DVR_TIMELINE_TOGGLE = 'DVR_TIMELINE_TOGGLE';

export type DVR_FAILURE = typeof DVR_FAILURE;
export type DVR_SUCCESS = typeof DVR_SUCCESS;
export type DVR_INFO = typeof DVR_INFO;
export type DVR_DELETE_RECORDING = typeof DVR_DELETE_RECORDING;
export type DVR_DELETE_RECORDING_SUCCESS = typeof DVR_DELETE_RECORDING_SUCCESS;
export type DVR_DELETE_RECORDING_FAILURE = typeof DVR_DELETE_RECORDING_FAILURE;
export type DVR_CLEAR_CACHE = typeof DVR_CLEAR_CACHE;
export type DVR_TIMELINE_RECORDINGS = typeof DVR_TIMELINE_RECORDINGS;
export type DVR_TIMELINE_RECORDINGS_SUCCESS = typeof DVR_TIMELINE_RECORDINGS_SUCCESS;
export type DVR_TIMELINE_TOGGLE = typeof DVR_TIMELINE_TOGGLE;
export interface DvrAction {
  type:
    | DVR_FAILURE
    | DVR_SUCCESS
    | DVR_DELETE_RECORDING
    | DVR_DELETE_RECORDING_SUCCESS
    | DVR_DELETE_RECORDING_FAILURE
    | DVR_CLEAR_CACHE
    | DVR_TIMELINE_RECORDINGS
    | DVR_TIMELINE_RECORDINGS_SUCCESS
    | DVR_TIMELINE_TOGGLE;
  payload?: any;
}

export interface DvrItem {
  programId: string;
  program: string;
  recordings: RecordingsItem[];
}

export interface RecordingsItem {
  actualStartTime: string;
  actualEndTime: string;
  assetId: string;
  channelId: string;
  channelName: string;
  channelNumber: string;
  duration: string;
  endTime: string;
  episodeName: string;
  source: string;
  startTime: string;
  timestamp: string;
  status: string;
  hasDeleteAction?: boolean;
  type: string
}

export interface DvrState {
  items?: Array<DvrItem>;
  totalElements?: number;
  timelineItems?: TimelineItem[];
  isToShowTimeline: boolean;
}

export interface TimelineItem {
  date: string;
  status: Status[];
}

export interface Status {
  status: string;
  amount: number;
}

export interface DvrColumn {
  name: string;
  width: string;
  field: string;
}
