import { ANONYMIZER_HASH_FETCH_SUCCESS, ANONYMIZER_HASH_FETCH_FAILURE, ANONYMIZER_HASH_DELETE_SUCCESS, ANONYMIZER_HASH_RESET } from '../../consts'
import { HashType } from '../../types'

export function hashFetchSuccess(payload: HashType) {
  return {
    type: ANONYMIZER_HASH_FETCH_SUCCESS,
    payload,
  }
}

export function hashFetchFailure() {
  return {
    type: ANONYMIZER_HASH_FETCH_FAILURE,
  }
}

export function hashDeleteSuccess() {
  return {
    type: ANONYMIZER_HASH_DELETE_SUCCESS,
  }
}

export function resetHash() {  
  return {
    type: ANONYMIZER_HASH_RESET
  }
}
