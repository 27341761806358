import { connect } from 'react-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import {
  execute,
  selectEntry,
  saveEntry,
  removeEntry,
  saveNewEntry,
  submitChanges
} from './redux/action';
import { hasPendingTasksOf } from '../../../core/indicator/pending-tasks-redux';
import OnnetOffnetComponent from './onnet-offnet-component';

const mapStateToProps = (state: any, ownProps: any) => ({
  results: state.management.onnetOffnet.results,
  id: ownProps.match.params.id,
  isFetching: hasPendingTasksOf('ONNET_OFFNET_LIST_REQUEST', state.pendingTasks),
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  execute,
  selectEntry,
  saveEntry,
  removeEntry,
  saveNewEntry,
  openModal,
  closeModal,
  submitChanges
})(OnnetOffnetComponent);
