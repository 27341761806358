import React from 'react'
import T from 'core/i18n/t'
import './index.scss'
import RfTuningDvbcMonitoringTable from './rf-tuning-dvbc-monitoring-table'
import { RfTuningLimitValues } from '../status/redux/types'
import dvbcMapper from './mapper'

type DVBCType = Array<Array<string>>

export type FrequencyLimitsType = Array<RfTuningLimitValues>

type RfTuningDvbcComponentProps = {
  dvbc: DVBCType
  frequencyLimits: FrequencyLimitsType
}

function dvbcRowMap(dvbc: DVBCType) {
  return dvbcMapper(dvbc)
}

export default function RfTuningDvbcComponent({ dvbc, frequencyLimits }: RfTuningDvbcComponentProps) {
  return (
    <>
      <div className="columns container is-fluid has-margin-left-none has-margin-right-none is-vcentered">
        <div className="dvbc-header">
            <h2 className="alert message">!</h2>
            <h2 className="message"><T>rf_tuning.dvbc_monitoring_not_real_time_data_message</T></h2>
        </div>
      </div>
      <RfTuningDvbcMonitoringTable dvbc={dvbcRowMap(dvbc)} frequencyLimits={frequencyLimits}/>
    </>
  )
}
