import { TFunction } from 'i18next';
import { Channels, ChannelsType } from './type';

export default (data: Channels, t: TFunction): Channels => {
    const channelsType = getChannelsType(data);
    return {
      channelId: data.channelId,
      mediaId: data.mediaId ,
      number: data.number,
      name: data.name,
      resolution: data.resolution,
      epgId: data.epgId,
      deviceType: data.deviceType,
      level: data.level
    };
};

function getChannelsType(result: any) {
    if (result) {
      if (!result.failed || result.failed === 0) {
        return ChannelsType.SUCCESS;
      } else if (!result.succeeded || result.succeeded === 0) {
        return ChannelsType.ERROR;
      }
    }
  
    return ChannelsType.WARNING;
}
