import { connect } from 'react-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import {
  execute,
  selectEntry,
  saveEntry,
  removeEntry,
  newEntry,
  changeEntry,
  submitChanges,
  setFilter,
  setFilterValue,
  clear
} from './redux/action';
import IdMappingEditableComponent from './editable-mapping-component';

const mapStateToProps = (state: any, ownProps: any) => ({
  application: state.application,
  results: state.management.idMapping.editable.results,
  id: ownProps.match.params.id,
  showNewEntry: state.management.idMapping.editable.showNewEntry,
  pageNumber: state.management.idMapping.editable.results
    ? state.management.idMapping.editable.results.number || 0
    : 0,
  errorMessage: state.management.idMapping.editable.message,
  currentFilter: state.management.idMapping.editable.currentFilter,
  currentFilterValue: state.management.idMapping.editable.currentFilterValue,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  execute,
  selectEntry,
  saveEntry,
  newEntry,
  removeEntry,
  openModal,
  closeModal,
  changeEntry,
  submitChanges,
  setFilter,
  setFilterValue,
  clear
})(IdMappingEditableComponent);
