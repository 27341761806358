import {
  DVBManagementStreamAction,
  DVB_MANAGEMENT_STREAM_SELECT,
  DVB_MANAGEMENT_STREAMS_SET,
  DVBStream,
  DVBManagementStream
} from './types';

const initialState: DVBManagementStream = {
  items: [],
  itemsChanged: undefined,
  itemsSearch: undefined
};

export default function(state = initialState, action: DVBManagementStreamAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_STREAM_SELECT: {
      if (!action.payload) {
        state.selected = undefined;
      }

      if (state.itemsChanged) {
        state.itemsChanged.forEach((stream: DVBStream) => {
          if (stream.rootId === action.payload) {
            state.selected = stream;
          }
        });
      }

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_STREAMS_SET: {
      const streams: Array<DVBStream> = [];
      const isToClear = action.payload && action.payload.isToClear;
      const isMove = action.payload && action.payload.isMove;
      const streamsPayload = action.payload && action.payload.streams;

      if (streamsPayload) {
        action.payload.streams.forEach((item: DVBStream) => {
          item.services &&
            item.services.forEach((service: any) => {
              service.id = service.serviceeId;
              service.label = service.serviceName;
            });

          if ((item.canEditChange === undefined) && (action.payload.canEditOtherEntities === undefined)) {
            item.canEditChange = true;
          }  

          streams.push({
            tsId: item.tsId,
            rootId: item.rootId || item.tsId || item.id,
            id: item.tsId,
            children: item.services || item.children,
            freq: item.freq,
            mod: item.mod,
            dvbType: item.dvbType,
            symRate: item.symRate,
            canEditChange:
              item.canEditChange === undefined
                ? action.payload.canEditOtherEntities
                : item.canEditChange,
            changeLabel: item.changeLabel,
            changeId: item.changeId
          });
        });
      }

      return {
        ...state,
        items:
          !isMove && (isToClear || (state.items && state.items.length === 0))
            ? streams
            : state.items,
        itemsSearch: action.payload.isSearch ? streams : undefined,
        itemsAux: isMove ? streams : [],
        itemsChanged: streamsPayload ? state.itemsChanged : []
      };
    }

    default: {
      return state;
    }
  }
}
