import {
  UserSettingsAction,
  UserSettingsState,
  USER_SETTINGS_CREATE_FAILURE,
  USER_SETTINGS_CREATE_SUCCESS,
  USER_SETTINGS_ON_CHANGE_DROPDOWNS,
  USER_SETTINGS_SUCCESS,
  USER_SETTINGS_TOGGLE,
  USER_SETTINGS_UPDATE_FAILURE,
  USER_SETTINGS_UPDATE_SUCCESS
} from './types';

const initialState: UserSettingsState = {
  isActive: false,
  hasChanged: false,
  actualSettings: undefined,
  newSettings: undefined
};

export default function(state = initialState, action: UserSettingsAction) {
  switch (action.type) {
    case USER_SETTINGS_TOGGLE:
      if (state.newSettings && state.actualSettings) {
        state.newSettings.language = state.actualSettings.language;
        state.newSettings.opco = state.actualSettings.opco;
      }

      return { ...state, isActive: !state.isActive, hasChanged: false };

    case USER_SETTINGS_ON_CHANGE_DROPDOWNS:
      if (action.payload.dropdownId === 'opco' && state.newSettings) {
        state.newSettings.opco = action.payload.value;
      } else {
        if (state.newSettings) {
          state.newSettings.language = action.payload.value;
        }
      }

      const hasChanged =
        state.newSettings?.opco !== state.actualSettings?.opco ||
        state.newSettings?.language !== state.actualSettings?.language;
      return {
        ...state,
        hasChanged: hasChanged
      };

    case USER_SETTINGS_SUCCESS:
      state.actualSettings = action.payload;
      state.newSettings = {
        opco: action.payload.opco,
        language: action.payload.language
      };
      return { ...state };

    case USER_SETTINGS_UPDATE_SUCCESS:
      return { ...state, isActive: false };

    case USER_SETTINGS_CREATE_SUCCESS:
    case USER_SETTINGS_UPDATE_FAILURE:
    case USER_SETTINGS_CREATE_FAILURE:
    default: {
      return { ...state };
    }
  }
}
