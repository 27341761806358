export const OVERLAY_MODAL_IDMP_OPEN = 'OVERLAY_MODAL_IDMP_OPEN';
export const OVERLAY_MODAL_IDMP_CLOSE = 'OVERLAY_MODAL_IDMP_CLOSE';
export const FILE_UPLOAD_ID_MAPPING_REQUEST = 'FILE_UPLOAD_ID_MAPPING_REQUEST';
export const FILE_UPLOAD_ID_MAPPING_SUCCESS = 'FILE_UPLOAD_ID_MAPPING_SUCCESS';
export const FILE_UPLOAD_ID_MAPPING_FAILURE = 'FILE_UPLOAD_ID_MAPPING_FAILURE';
export const SET_FILE_TO_UPLOAD = 'SET_FILE_TO_UPLOAD';
export const SET_FILE_INGEST_UPLOAD_TYPE = 'SET_FILE_INGEST_UPLOAD_TYPE';

export interface UploadModalState {
  active: boolean;
  disabledUpload: boolean;
  title?: string;
  content?: string;
  execute?: any;
  dataUpload: FormData;
  fileNameToUpload: string;
  uploadResponse?: string;
  fileType: string;
}

export interface UploadModalAction {
  type:
    | typeof OVERLAY_MODAL_IDMP_CLOSE
    | typeof OVERLAY_MODAL_IDMP_OPEN
    | typeof SET_FILE_TO_UPLOAD
    | typeof FILE_UPLOAD_ID_MAPPING_SUCCESS
    | typeof FILE_UPLOAD_ID_MAPPING_FAILURE
    | typeof SET_FILE_INGEST_UPLOAD_TYPE;
  payload: any;
}
