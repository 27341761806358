import PopUpComponent from 'core/pop-up';
import React, { Component } from 'react';
import './progress-bar-styles.scss';
import { ProgressBarState } from './redux/types';

interface ProgressBarProps {
  duration: number;
  progressBar: ProgressBarState;
  handleProgress: (progress: number) => void;
  handleClose: (close: boolean) => void;
  isActive: boolean;
  title: any;
  titleSpan?: any;
  bodyMsg: string;
  noCounter?: boolean;
}

export default class ProgressBarComponent extends Component<
  ProgressBarProps,
  { isActive: boolean }
> {
  constructor(props: ProgressBarProps) {
    super(props);
    this.state = { isActive: this.props.isActive };
  }

  componentDidUpdate(prevProps: ProgressBarProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({ isActive: this.props.isActive });
      if (this.props.isActive == true) {
        this.props.handleClose(false);
        this.startProgressBar();
      }
    }
  }

  startProgressBar = () => {
    this.props.progressBar.progress = 0;
    const interval = setInterval(() => {
      let width = this.props.progressBar.progress;

      if (width == 95) {
        if (!this.props.isActive) {
          width++;
          this.props.handleProgress(width);
        }
      } else {
        if (width == 100) {
          this.props.handleClose(true);
          this.props.progressBar.progress = 0;
          clearInterval(interval);
        } else {
          if (!this.props.isActive) {
            width = 100;
            this.props.progressBar.progress = 100;
            this.props.handleProgress(width);
          } else {
            width++;
            this.props.handleProgress(width);
          }
        }
      }
    }, this.props.duration);
  };

  render() {
    const { progressBar } = this.props;
    return (
      <PopUpComponent isActive={!this.props.progressBar.close}>
        <div className="has-margin-x-lg has-text-centered">
          <h4 className="text is-h4 has-margin-bottom-md">
            {this.props.title}
            {this.props.titleSpan ? (
              <span className="text is-h4 is-bold has-margin-bottom-md">
                {this.props.titleSpan}
              </span>
            ) : (
              ''
            )}
          </h4>

          <p className="text is-grey">{this.props.bodyMsg}</p>
        </div>

        <div className="progress">
          <div className="progress-fill" style={{ width: progressBar.progress + '%' }}></div>
          {!this.props.noCounter ? (
            <span className="progress-text">{progressBar.progress}%</span>
          ) : (
            ''
          )}
        </div>
      </PopUpComponent>
    );
  }
}
