import { TFunction } from 'i18next';
import React, { Component, Fragment } from 'react';
import { GeneralDeviceState } from '../general/redux/types';
import './topbar-styles.scss';

interface TopbarProps {
  deviceCustomFields: any;
  id: string;
  general: GeneralDeviceState;
  initializeParameters: () => void;
  fetchShadow: (id: string) => void;
  t: TFunction;
  applicationParameters: any;
}

export default class Topbar extends Component<TopbarProps> {
  componentDidMount() {
    const { id, general, initializeParameters, fetchShadow } = this.props;

    initializeParameters();
    if (
      !general ||
      !general.shadow ||
      !general.shadow.reported ||
      !general.shadow.reported.hardware ||
      !general.shadow.reported.hardware.mod
    ) {
      if (general && general.shadow === undefined) {
        fetchShadow(id);
      }
    }
  }

  hideKDSN() {
    return Object.values(this.props.applicationParameters).includes('KDSN');
  }

  KDSNField(general: GeneralDeviceState, notApplicableLabel: string, notAvailable: string) {
    const kdsn = general?.shadow?.externalID
    const isNotApplicable = kdsn && kdsn === 'N/A'

    return (<p className={`is-inline-block topbar-item ${isNotApplicable ? 'div-tooltip-topbar has-tooltip-topbar top-tooltip' : ''}`} data-info={`${notApplicableLabel}`}>
      <a className="title is-6">KDSN </a>
      {kdsn ?? notAvailable}
    </p>)
  }

  render() {
    const { deviceCustomFields, id, general, t } = this.props;

    return (
      <Fragment>
        <div className="topbar-container has-margin-left-sm">
          <p className="is-inline-block topbar-item">
            <a className="title is-6">{t('sidebar.device_id')} </a>
            {this.props && id}
          </p>

          <p className="is-inline-block topbar-item">
            <a className="title is-6">Household Id </a>
            {general && general.shadow && general.shadow.householdID
              ? general.shadow.householdID
              : t('general.not_available')}
          </p>

          {this.hideKDSN() 
            ? this.KDSNField(general, t('general.not_applicable'), t('general.not_available'))
            : ''
          }

          <p className="is-inline-block topbar-item">
            <a className="title is-6">{`${t('general.user.id')} `}</a>
            {general && general.shadow && general.shadow.userID
              ? general.shadow.userID
              : t('general.not_available')}
          </p>

          <p className="is-inline-block topbar-item">
            <a className="title is-6">{`${t('general.user.external_id')} `}</a>
            {general && general.shadow && general.shadow.domainExernalID
              ? general.shadow.domainExernalID
              : t('general.not_available')}
          </p>
        </div>
      </Fragment>
    );
  }
}
