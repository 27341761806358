import { connect } from 'react-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import { orderItems, goToFirstPage, changefieldOrder } from 'core/pagination-optimized/redux/actions';
import { ApplicationState } from 'application/application-redux';
import TableComponent from './table-component';
import { selectedRowIndex, selectOneRow } from './redux/actions';
import { push } from 'connected-react-router';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t,
  spinner: state.spinner,
  hasSlider: ownProps.hasSlider,
  onSearch: ownProps.onSearch,
  search: state.search,
  table: state.table,
});

export default connect(mapStateToProps, {
  closeModal,
  openModal,
  selectedRowIndex,
  selectOneRow,
  push,
  orderItems,
  goToFirstPage,
  changefieldOrder
})(TableComponent);
