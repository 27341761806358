import React, { Component, Fragment } from 'react';
import { ChevronLeft, DoubleArrows } from 'assets/svg';
import { filterMapper } from 'core/table/types';
import { TFunction } from 'i18next';
import { SearchState } from 'core/search/redux/types';
import { PaginationState } from './redux/types';
import './pagination-styles.scss';
import { HideLastpageNumber } from './redux/enum';

interface PaginationComponentProps {
  id: string;
  pagination: PaginationState;
  pageSize: number;
  totalElements: number;
  search: SearchState;
  hideLastpageNumber?: HideLastpageNumber;
  onChange: (filter: any, days?: number, comparator?: string) => void;
  goToFirstPage: (id: string) => void;
  goToNextPage: (id: string) => void;
  goToPreviousPage: (id: string) => void;
  goToLastPage: (id: string, lastPage: number) => void;
  t: TFunction;
  singleArrowOnly?:boolean;
}

export default class PaginationComponent extends Component<PaginationComponentProps> {
  onChangePage(action: any, lastPage?: number) {
    const { id, pagination, search, onChange } = this.props;
    const _pagination = pagination.pagination.get(id) || {
      pageNumber: 0,
      pageSize: 10,
      sortOrder: 'DESC'
    };
    if (lastPage) {
      action(id, lastPage - 1);
    } else {
      action(id);
    }

    const _search = search.filters.get(id);
    const currentFilter =
      _search && _search.currentFilter && _search.currentFilter.value
        ? _search.currentFilter
        : null;
    const previousDays = _search && _search.moreFilters && _search.moreFilters.days;
    const currentComparator = _search && _search.currentComparator;
    onChange(filterMapper(currentFilter, _pagination, id), previousDays, currentComparator);
  }

  render() {
    const {
      id,
      pagination,
      pageSize,
      totalElements,
      hideLastpageNumber,
      goToFirstPage,
      goToNextPage,
      goToPreviousPage,
      goToLastPage,
      t, 
      singleArrowOnly
    } = this.props;

    const lastPage = Math.ceil(totalElements / pageSize);
    const lastPageIndex = lastPage - 1;

    const _pagination = pagination.pagination.get(id);
    const currentPage = _pagination ? _pagination.pageNumber : 0;

    return (
      <div>
        {totalElements > 0 && (
          <nav
            className="pagination is-centered has-margin-top-md has-margin-bottom-md"
            role="navigation"
            aria-label="pagination"
          >
            <ul className="pagination-list has-margin-bottom-none has-padding-bottom-sm has-padding-top-sm">
              <li className={hideLastpageNumber === 'all' ? 'is-hidden' : ''}>
                <button
                  disabled={currentPage === 0}
                  className="pagination-action"
                  onClick={() => this.onChangePage(goToFirstPage)}
                >
                 {singleArrowOnly ? "" : <DoubleArrows
                    className="icon icon-svg icon-svg-fill is-shape"
                    title={t('general.first_page')}
                  />}
                </button>
              </li>

              <li>
                <button
                  disabled={currentPage === 0}
                  className="pagination-action pagination-action-left"
                  onClick={() => this.onChangePage(goToPreviousPage)}
                >
                  <ChevronLeft className="icon icon-svg" title={t('general.previous_page')} />
                </button>
              </li>

              <li>
                <span className="pagination-label">
                  {`${currentPage + 1}`.padStart(2, '0')}
                  <span
                    className={
                      hideLastpageNumber ? 'is-hidden' : 'has-padding-left-xs has-padding-right-xs'
                    }
                  >
                    {' '}
                    of{' '}
                  </span>
                  {!hideLastpageNumber && `${lastPage}`.padStart(2, '0')}
                </span>
              </li>

              <li>
                <button
                  disabled={currentPage === lastPageIndex}
                  className="pagination-action pagination-action-right"
                  onClick={() => this.onChangePage(goToNextPage)}
                >
                  <ChevronLeft className="icon icon-svg icon-180" title={t('general.next_page')} />
                </button>
              </li>

              <li className={hideLastpageNumber === 'all' ? 'is-hidden' : ''}>
                <button
                  disabled={currentPage === lastPageIndex}
                  className="pagination-action"
                  onClick={() => this.onChangePage(goToLastPage, lastPage)}
                >
                  {singleArrowOnly ? "" : <DoubleArrows
                    className="icon icon-svg icon-svg-fill icon-180 is-shape"
                    title={t('general.last_page')}
                  />}
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    );
  }
}
