import { envs } from 'application/envHandler';
import { SideMenu } from './types';

const categories = {
  categories: [
    {
      name: 'configurations',
      isActive: true,
      subCategories: [
        {
          name: 'audio_subtitle',
          isActive: true
        },
        {
          name: 'hdmi',
          isActive: false
        },
        {
          name: 'advanced',
          isActive: false
        },
        {
          name: 'parental_mode',
          isActive: false
        },
        {
          name: 'accessibility',
          isActive: false
        },
        {
          name: 'startup_standby',
          isActive: false
        },
        {
          name: 'update',
          isActive: false
        }
      ]
    },
    {
      name: 'actions',
      isActive: false
    }
  ]
};

export const settingsSideMenu = (showLogs: boolean) => {
  if (showLogs && !categories.categories.find(category => category.name === 'logs')) {
    categories.categories.push({
      name: 'logs',
      isActive: false,
      subCategories: [
        { name: 'manage_logs', isActive: true },
        { name: 'flush_logs', isActive: false }
      ]
    })
  }

  return categories as SideMenu
}
