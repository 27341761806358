import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import AuthService from 'core/auth/auth-service';
import {
  OVERVIEW_CLEAR_CACHE,
  DEVICE_DETAIL_CONVERSION,
  OVERVIEW_USER_CONVERSION_REQUEST,
  OVERVIEW_USER_CONVERSION_SUCCESS,
  OVERVIEW_USER_CONVERSION_FAILURE,
  DeviceDetailOverviewAction,
  COMMAND_EXECUTION_FAILURE,
  COMMAND_EXECUTION_REQUEST,
  COMMAND_EXECUTION_SUCCESS,
  CommandAction
} from './types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

export function clearCacheOverview() {
  return {
    type: OVERVIEW_CLEAR_CACHE
  };
}

function request(): DeviceDetailOverviewAction {
  return {
    type: OVERVIEW_USER_CONVERSION_REQUEST
  };
}

// function successUserConversion(payload: any): DeviceDetailOverviewAction {
//   return {
//     type: OVERVIEW_USER_CONVERSION_SUCCESS,
//     payload
//   };
// }

// function failureUserConversion(payload: any): DeviceDetailOverviewAction {
//   return {
//     type: OVERVIEW_USER_CONVERSION_FAILURE,
//     payload
//   };
// }

// export function fetchUserConversion(id: string) {
//   return async (dispatch: any, getState: () => ApplicationState) => {
//     dispatch(request());
//     dispatch(add(DEVICE_DETAIL_CONVERSION, {}));
//     apiClient.get(`${envs.REACT_APP_API_URL}/device/user-conversion/${id}`).then(
//       response => {
//         dispatch(remove(DEVICE_DETAIL_CONVERSION));
//         response.data.notAvailable = getState().i18n.t('general.not_available');
//         return dispatch(successUserConversion(response.data));
//       },
//       rejection => {
//         dispatch(remove(DEVICE_DETAIL_CONVERSION));
//         dispatch(
//           addToaster({
//             title: 'all_device_pages.menu_overview',
//             message: 'error_messages.VIPOC_ERROR_034',
//             type: 'danger'
//           })
//         );
//         return dispatch(failureUserConversion(rejection));
//       }
//     );
//   };
// }

const commandsMap: { [k: string]: string } = {
  reboot: 'reboot',
  standBy: 'goToPowerState',
  powerOn: 'goToPowerState',
  powerOff: 'goToPowerState',
}

const parametersMap: { [k: string]: any } = {
  reboot: { forced: true },
  standBy: { powerstate: 'activestandby' },
  powerOn: { powerstate: 'on' },
  powerOff: { powerstate: 'on' },
}

function requestCommand(): CommandAction {
  return {
    type: COMMAND_EXECUTION_REQUEST
  };
}

function failure(payload: any): CommandAction {
  return {
    type: COMMAND_EXECUTION_FAILURE,
    payload
  };
}

function success(payload: any): CommandAction {
  return {
    type: COMMAND_EXECUTION_SUCCESS,
    payload
  };
}

export function execute(params: { command: string; id: string }) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add('COMMAND_EXECUTION', {}));
    await AuthService.refreshToken();
    dispatch(requestCommand());
    const dataToSend: any = {
      command: commandsMap[params.command] || params.command,
      deviceId: params.id,
      jsonParameters: parametersMap[params.command]
    };

    apiClient.post(`${envs.REACT_APP_API_URL}/command`, dataToSend).then(
      (response) => {
        if (response.data.httpCode === '400') {
          handleCommandError(
            dispatch,
            response.data.responseJson.jobId,
            'settings.title.command_actions',
            getState().i18n.t,
            'COMMAND_EXECUTION'
          );
        } else {
          dispatch(remove('COMMAND_EXECUTION'));
          dispatch(
            addToaster({
              title: 'settings.title.command_actions',
              message: getState()
                .i18n.t('alert_info_messages.VIPOC_INFO_014')
                .replace('{0}', dataToSend.command)
                .replace('{1}', response.data.responseJson.jobId),
              type: 'success'
            })
          );
          return dispatch(success({ ...response.data }));
        }
      },
      (rejection) => {
        dispatch(remove('COMMAND_EXECUTION'));
        dispatch(
          addToaster({
            title: 'settings.title.command_actions',
            message: 'error_messages.VIPOC_ERROR_022',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function refreshEmm(hhid: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    await AuthService.refreshToken();

    apiClient.put(`${envs.REACT_APP_API_URL}/integration/household/${hhid}/ipSchema`, { ipSchema: 'v4' }).then(
      (response) => {
        if (response.data.httpCode === '400') {
          dispatch(
            addToaster({
              title: 'overview.label_device_overview',
              message: getState()
                .i18n.t('error_messages.VIPOC_ERROR_059'),
              type: 'success'
            })
          );
        } else {     
          dispatch(
            addToaster({
              title: 'overview.label_device_overview',
              message: getState()
                .i18n.t('alert_info_messages.VIPOC_INFO_018'),
              type: 'success'
            })
          );
          return dispatch(success({ ...response.data }));
        }
      },
      (rejection) => {
        const message: string =
          rejection === 'error_messages.permission_denied'
            ? rejection
            : 'error_messages.VIPOC_ERROR_059';
        dispatch(
          addToaster({
            title: 'overview.label_device_overview',
            message,
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}
