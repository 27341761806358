import { connect } from 'react-redux';
import { open, close } from 'core/modal/redux/actions';
import DeviceDetailOverviewComponent from './device-detail-overview-component';
import { execute, refreshEmm } from './redux/actions';

const mapStateToProps = (state: any, ownProps: any) => ({
  overview: state.devices.detail.overview,
  id: ownProps.id,
  hhid: ownProps.hhid,
  t: state.i18n.t,
  general: state.devices.detail.general
});

export default connect(mapStateToProps, {
  open,
  close,
  execute,
  refreshEmm,
})(DeviceDetailOverviewComponent);
