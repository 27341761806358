import { PaginationConfig } from './types';

export const paginationSubscriptions: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'purchase_date',
  sortOrder: 'DESC'
};

export const paginationProvDevices: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'udid',
  sortOrder: 'DESC'
};

export const paginationChannelScan: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'finishedAt',
  sortOrder: 'DESC'
};

export const paginationRfTunning: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'finishedAt',
  sortOrder: 'DESC'
};

export const paginationDVR: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'assetId',
  sortOrder: 'DESC'
};

export const paginationVOD: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10,
  sortField: 'purchasedate',
  sortOrder: 'DESC'
};

export const paginationActivityLog: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC'
};

export const paginationSpeedTest: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'finishedAt',
  sortOrder: 'DESC'
};

export const paginationRequests: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10
};

export const paginationChannels: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10,
  sortField: 'mediaId',
  sortOrder: 'ASC'
};

export const paginationChannelsAssociationsRegions: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: '',
  sortOrder: ''
};

export const paginationChannelsAssociationsNodes: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: '',
  sortOrder: ''
};

export const paginationLogs: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10
};

export const paginationRegionalization: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5,
  sortField: 'mediaId',
  sortOrder: 'ASC'
};

export const regionalizationAssociate: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5
};

export const regionalizationDisassociate: PaginationConfig = {
  pageNumber: 0,
  pageSize: 5
};

export const paginationReprocess: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10,
  pageSave: 0,
  doubleArrows: false,
  sortField: '',
  sortOrder: ''
};

export const paginationReprocessDetails: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10
};

export const paginationAdvancedSearch: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10,
  sortField: 'udid',
  sortOrder: 'ASC'
};

export const paginationAccordionDvr: PaginationConfig = {
  pageNumber: 0,
  pageSize: 10
};