import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { push } from 'connected-react-router';
import { getAssetValuesFields } from 'core/utils/api-utils';
import {
  action,
  deleteChangesIfExist,
  getChanges,
  getTuningData,
  onCopy,
  onPaste,
  submitRequest
} from './redux/action';
import { changeMenu } from 'application/header/navbar/redux/actions';
import { replyRequest } from '../requests/redux/action';
import { setChangeInfo } from './popUp/redux/action';
import { getSearchFields, toggleSearch, onClearFilters } from './search/redux/action';
import { getRegions, selectRegion, setRegions } from './redux/redux-regions/action';
import { selectNetwork } from './redux/redux-networks/action';
import { selectNode } from './redux/redux-nodes/action';
import { selectStream } from './redux/redux-streams/action';
import { selectService } from './redux/redux-services/action';
import DVBManagementComponent from './dvb-management-component';
import { setIsRegionalization } from 'modules/management/spectrum/dvb-management/redux/redux-nodes/action';

const mapStateToProps = (state: ApplicationState) => ({
  dvbManagement: state.management.spectrum.dvbManagement,
  request: state.management.spectrum.requests,
  t: state.i18n.t,
  tooltip: state.toasters.tooltip
});
export default connect(mapStateToProps, {
  action,
  deleteChangesIfExist,
  getAssetValuesFields,
  onClearFilters,
  getChanges,
  getSearchFields,
  getTuningData,
  getRegions,
  changeMenu,
  onCopy,
  onPaste,
  push,
  replyRequest,
  setChangeInfo,
  selectNetwork,
  selectNode,
  selectRegion,
  selectService,
  selectStream,
  setRegions,
  submitRequest,
  toggleSearch,
  setIsRegionalization
})(DVBManagementComponent);
