import { DVBNetwork } from '../redux-networks/types';
import { DVBItemChanged, DVBItemToShow } from '../types';

export const DVB_MANAGEMENT_NODE_SELECT = 'DVB_MANAGEMENT_NODE_SELECT';
export const DVB_MANAGEMENT_NODES_SET = 'DVB_MANAGEMENT_NODES_SET';
export const DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION =
  'DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION';
export interface DVBManagementNodesAction {
  type:
    | typeof DVB_MANAGEMENT_NODE_SELECT
    | typeof DVB_MANAGEMENT_NODES_SET
    | typeof DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION;
  payload: any;
}

export interface DVBNode extends DVBItemToShow, DVBItemChanged {
  id: string;
  nodeId: string;
  nodeLabel: string;
  childRegionId?: string;
  networks?: Array<DVBNetwork>;
}
export interface DVBManagementNode {
  items: Array<DVBNode>;
  itemsChanged?: Array<DVBNode>;
  itemsSearch?: Array<DVBNode>;
  selected?: DVBNode;
  isRegionalization?: boolean;
}
