import {
  DEVICE_DETAIL_HARD_DISK_SUCCESS,
  DeviceDetailHardDiskAction,
  DeviceHardDiskState,
  HARD_DISK_CLEAR_CACHE
} from './types';

const initialState: DeviceHardDiskState = {};

export default function(state = initialState, action: DeviceDetailHardDiskAction) {
  switch (action.type) {
    case HARD_DISK_CLEAR_CACHE:
      return {
        ...state,
        ...action.payload
      };
    case DEVICE_DETAIL_HARD_DISK_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
