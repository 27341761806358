import { t } from 'i18next';
import DateUtils from 'core/utils/date-utils';
import moment from 'moment';
import { DvrItem, DvrColumn, RecordingsItem } from './types';

const allowedStatusToBeDeleted = ['scheduled','completed','partial','failed']

export default (data: any): DvrItem => {
  if (!data) {
    return {
      programId: '',
      program: '',
      recordings: []
    };
  }

  const recordings: RecordingsItem[] =
    data && data.recordings
      ? data.recordings.map((rec: any) => {
          return {
            assetId: rec?.assetId || t('general.not_available'),
            episodeName: rec?.episodeName || t('general.not_available'),
            channelName: rec?.channelName || t('general.not_available'),
            channelNumber: rec?.channelNumber || t('general.not_available'),
            startTime: rec && rec.startTime ? DateUtils.formatDate(rec.startTime) : t('general.not_available'),
            endTime: rec && rec.endTime ? DateUtils.formatDate(rec.endTime) : t('general.not_available'),
            actualStartTime:
              rec && rec.actualStartTime ? DateUtils.formatDate(rec.actualStartTime) : t('general.not_available'),
            actualEndTime: rec && rec.actualEndTime ? DateUtils.formatDate(rec.actualEndTime) : t('general.not_available'),
            duration:
              rec && rec.startTime && rec.endTime
                ? DateUtils.daysBetween(moment.utc(rec.startTime), moment.utc(rec.endTime))
                : t('general.not_available'),
            source: rec?.source || t('general.not_available'),
            timestamp: rec && rec.timestamp ? DateUtils.formatDate(rec.timestamp) : t('general.not_available'),
            status: rec?.status || t('general.not_available'),
            type: rec.type ? t(`dvr.select_${rec.type}`) : t('general.not_available'),
            hasDeleteAction: rec
              ? allowedStatusToBeDeleted.includes(rec.status) && rec.type === 'local'
              : false,
          };
        })
      : [];
  return {
    programId: data ? data.programId : '',
    program: data && data.programName !== null ? data.programName : t('general.not_available'),
    recordings: recordings || []
  };
};

export const dvrColumns = (): Array<DvrColumn> => [
  { name: 'dvr.column_asset_id', width: '10%', field: 'assetId' },
  { name: 'dvr.column_episode', width: '12%', field: 'episodeName' },
  { name: 'dvr.field_channel', width: '15%', field: 'channelName' },
  { name: 'dvr.column_channel_number', width: '15%', field: 'channelNumber' },
  { name: 'dvr.column_start_time', width: '15%', field: 'startTime' },
  { name: 'dvr.column_end_time', width: '15%', field: 'endTime' },
  { name: 'dvr.column_actual_start_time', width: '15%', field: 'actualStartTime' },
  { name: 'dvr.column_actual_end_time', width: '15%', field: 'actualEndTime' },
  { name: 'dvr.column_duration', width: '12%', field: 'duration' },
  { name: 'dvr.column_source', width: '10%', field: 'source' },
  { name: 'dvr.column_last_event', width: '15%', field: 'timestamp' },
  { name: 'dvr.column_status', width: '10%', field: 'status' },
  { name: 'dvr.column_recording_type', width: '10%', field: 'type' },
  { name: '', width: '1%', field: '' }
];

export const scaleValues = (maxValue: number) => {
  if (maxValue <= 8) return [2, 4, 6, 8];
  if (maxValue > 8 && maxValue <= 20) return [5, 10, 15, 20];
  if (maxValue > 20 && maxValue <= 40) return [10, 20, 30, 40];
  if (maxValue > 40 && maxValue <= 80) return [20, 40, 60, 80];
  if (maxValue > 80 && maxValue <= 100) return [25, 50, 75, 100];
  if (maxValue > 100 && maxValue <= 200) return [50, 100, 150, 200];
  if (maxValue > 200 && maxValue <= 300) return [100, 200, 300];
  if (maxValue > 300 && maxValue <= 600) return [200, 400, 600];
  if (maxValue > 600 && maxValue <= 800) return [200, 400, 600, 800];
  if (maxValue > 800 && maxValue <= 1000) return [250, 500, 750, 1000];
  if (maxValue > 1000 && maxValue <= 1500) return [375, 750, 1125, 1500];
  if (maxValue > 1500 && maxValue <= 2000) return [500, 1000, 1500, 2000];
  if (maxValue > 2000 && maxValue <= 2500) return [1000, 1500, 2000, 2500];
  if (maxValue > 2500 && maxValue <= 3000) return [750, 1500, 2250, 3000];
  if (maxValue > 3000 && maxValue <= 5000) return [1250, 2500, 3750, 5000];
  if (maxValue > 5000) return [maxValue];
};
