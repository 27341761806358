import { Delete, Search } from 'assets/svg'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import NoRecords from 'core/no-records'
import { ANONYMIZER_HASH_RESET, HASH_FILTER_OPTIONS } from '../../consts'
import { FetchHashByAidFunction, FetchHashByIdFunction, HashesManagementInterface } from '../../types'
import './index.scss'
import DeleteConfirm from '../DeleteConfirm'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { resetHash } from '../../redux/hashes/actions'

function DeleteConfirmBody() {
  return <div>{t('anonymizer.delete_hash_confirm')}</div>
}

function fetchHash(fetchOption: string, hashId: string, serviceId: string, fetchHashByAId: FetchHashByAidFunction, fetchHashById: FetchHashByIdFunction) {
  if(fetchOption === 'id') {
    return fetchHashById({ id: hashId, serviceId })
  }

  return fetchHashByAId({ id: hashId, serviceId })
}

const initialSelectOption = HASH_FILTER_OPTIONS[0]

export default function HashesManagement({ hash, service, fetchHashByAId, fetchHashById, deleteHash}: HashesManagementInterface) {
  const [hashFilterOption, setHashFilterOption] = useState(initialSelectOption)
  const [hashFieldToSearch, setHashFieldToSearch] = useState('')
  const [hashDeleteActive, setHashDeleteActive] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetHash())
    setHashFilterOption(initialSelectOption)
    setHashFieldToSearch('')
  }, [service.id])
  

  return (
    <>
      <div className="is-flex columns">
        <div className="column is-6">
          <div className="columns">
            <div className="column is-6">
              <div className="select is-fullwidth">
                <select value={hashFilterOption} onChange={(e) => setHashFilterOption(e.target.value)}> 
                  {HASH_FILTER_OPTIONS.map(filterOption => <option key={filterOption} value={filterOption}>{t(`anonymizer.${filterOption}`)}</option>)}
                </select>
              </div>
            </div>
            <div className="column is-6">
              <div className='input-container'>
                <input className="input has-icon" placeholder={t('general.search')} type="text" value={hashFieldToSearch} onChange={e => setHashFieldToSearch(e.target.value)}/>
                <span className="icon in-input">
                  <Search className="icon icon-svg is-small icon-atlo" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6 button-container">
          <button 
            className="button is-primary"
            disabled={!hashFieldToSearch}
            onClick={() => fetchHash(hashFilterOption, hashFieldToSearch, service.id, fetchHashByAId, fetchHashById)}>
              {t('general.search')}
          </button>
        </div>
      </div>
      {hash.exp ? <div>
        <div className="is-flex columns row-container">
          <div className="column is-4"><b>{t('anonymizer.id')}</b></div>
          <div className="column is-4"><b>{t('anonymizer.aid')}</b></div>
          <div className="column is-2"><b>{t('anonymizer.expiration')}</b></div>
        </div>
        <div className="is-flex columns align-items-center row-container">
          <div className="column is-4 anonymizer-field">{hash.id}</div>
          <div className="column is-4 anonymizer-field">{hash.aid}</div>
          <div className="column is-2 anonymizer-field">{moment.utc(hash.exp).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div className="column is-2 is-flex align-items-center justify-content-end">
            <button className="button-link icon-hovered" onClick={() => setHashDeleteActive(true)}>
              <Delete className="icon icon-svg is-medium delete-button" />
            </button>
          </div>
        </div>
      </div>: 
      <NoRecords t={t} subtitle={t('anonymizer.search_hash_subtitle')}/>}
      {hashDeleteActive && <DeleteConfirm setActive={setHashDeleteActive} action={deleteHash} params={{ id: hash.id || '', serviceId: service.id }} title={t('anonymizer.delete_hash_confirm_title')} body={<DeleteConfirmBody/>}/>}
    </>
  )
}
