import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import SpectrumChannelsComponent from './channels-component';
import { getChannels, action, getChannel, getKalturaSync } from './redux/redux-channels/action';
import { push } from 'connected-react-router';

const mapStateToProps = (state: ApplicationState) => ({
  channel: state.management.spectrum.channel,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  push,
  getChannels,
  action,
  getChannel,
  getKalturaSync
})(SpectrumChannelsComponent);
