import { FieldItem } from '../../popUp/redux/types';
import { searchFields } from '../../redux/config';
import { DVB_COLUMNS } from '../../redux/enums';
import { Field } from '../../redux/types';
import {
  DVBManagementSearchAction,
  DVBSearch,
  DVB_MANAGEMENT_SEARCH_BUILD,
  DVB_MANAGEMENT_SEARCH_FIELDS,
  DVB_MANAGEMENT_SEARCH_SET_COLUMN,
  DVB_MANAGEMENT_SEARCH_SET_FIELD,
  DVB_MANAGEMENT_SEARCH_TOGGLE,
  DVB_MANAGEMENT_SEARCH_VALUE,
  SearchColumn
} from './types';

const initialState: DVBSearch = {
  isActive: false,
  columns: [],
  fields: []
};

function hasData(items: any) {
  return items && items.itemsChanged && items.items.length > 0;
}

export default function(state = initialState, action: DVBManagementSearchAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_SEARCH_BUILD: {
      const dvbManagement = action.payload.dvbManagement;
      const searchColumns: Array<SearchColumn> = [];

      if (hasData(dvbManagement.region)) {
        searchColumns.push({
          key: DVB_COLUMNS.REGION,
          value: 'spectrum.regions',
          isActive:
            (dvbManagement.main.search.columns &&
              dvbManagement.main.search.columns[0] &&
              dvbManagement.main.search.columns[0].isActive) ||
            false
        });
      }

      if (hasData(dvbManagement.node)) {
        searchColumns.push({
          key: DVB_COLUMNS.NODE,
          value: 'spectrum.nodes',
          isActive:
            (dvbManagement.main.search.columns &&
              dvbManagement.main.search.columns[1] &&
              dvbManagement.main.search.columns[1].isActive) ||
            false
        });
      }

      if (hasData(dvbManagement.network)) {
        searchColumns.push({
          key: DVB_COLUMNS.NETWORK,
          value: 'spectrum.networks',
          isActive:
            (dvbManagement.main.search.columns &&
              dvbManagement.main.search.columns[2] &&
              dvbManagement.main.search.columns[2].isActive) ||
            false
        });
      }

      if (hasData(dvbManagement.stream)) {
        searchColumns.push({
          key: DVB_COLUMNS.STREAM,
          value: 'spectrum.streams',
          isActive:
            (dvbManagement.main.search.columns &&
              dvbManagement.main.search.columns[3] &&
              dvbManagement.main.search.columns[3].isActive) ||
            false
        });
      }

      if (hasData(dvbManagement.service)) {
        searchColumns.push({
          key: DVB_COLUMNS.SERVICE,
          value: 'spectrum.services',
          isActive:
            (dvbManagement.main.search.columns &&
              dvbManagement.main.search.columns[4] &&
              dvbManagement.main.search.columns[4].isActive) ||
            false
        });
      }
      return {
        ...state,
        columns: searchColumns
      };
    }

    case DVB_MANAGEMENT_SEARCH_FIELDS: {
      let column = action.payload.column;
      let fields: Array<FieldItem> = [];

      if (column) {
        const search = searchFields.find((searchField: Field) =>
          searchField.column.includes(column)
        );
        fields = search ? search.fields : [];
      }
      return {
        ...state,
        fields
      };
    }

    case DVB_MANAGEMENT_SEARCH_SET_COLUMN: {
      state.columns.forEach((item: SearchColumn) => {
        if (item.key.includes(action.payload.column)) {
          item.isActive = true;
        }else{
          item.isActive = false;
        }
      });

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_SEARCH_SET_FIELD: {
      state.fields.forEach((fieldItem: FieldItem) => {
        
        if (fieldItem.key === action.payload.field) {
          fieldItem.isActive = true;

        }else {
          fieldItem.isActive = false;
        }
      });

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_SEARCH_TOGGLE: {
      return {
        ...state,
        isActive: !state.isActive
      };
    }

    case DVB_MANAGEMENT_SEARCH_VALUE: {
      return {
        ...state,
        value: action.payload.value
      };
    }

    default: {
      return state;
    }
  }
}
