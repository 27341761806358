export const OVERLAY_MODAL_IPGEO_OPEN = 'OVERLAY_MODAL_IPGEO_OPEN';
export const OVERLAY_MODAL_IPGEO_CLOSE = 'OVERLAY_MODAL_IPGEO_CLOSE';
export const FILE_UPLOAD_IP_GEOLOCATION_REQUEST = 'FILE_UPLOAD_IP_GEOLOCATION_REQUEST';
export const FILE_UPLOAD_IP_GEOLOCATION_SUCCESS = 'FILE_UPLOAD_IP_GEOLOCATION_SUCCESS';
export const FILE_UPLOAD_IP_GEOLOCATION_FAILURE = 'FILE_UPLOAD_IP_GEOLOCATION_FAILURE';
export const SET_FILE_TO_UPLOAD = 'SET_FILE_TO_UPLOAD';

export interface UploadModalState {
  active: boolean;
  disabledUpload: boolean;
  title?: string;
  content?: string;
  execute?: any;
  dataUpload: FormData;
  fileNameToUpload: string;
  uploadResponse?: string;
}

export interface UploadModalAction {
  type:
    | typeof OVERLAY_MODAL_IPGEO_CLOSE
    | typeof OVERLAY_MODAL_IPGEO_OPEN
    | typeof SET_FILE_TO_UPLOAD
    | typeof FILE_UPLOAD_IP_GEOLOCATION_SUCCESS;
  payload: any;
}
