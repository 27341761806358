import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import UserSettingsComponent from './user-settings-component';
import {
  toggleUserSettings,
  onChangeDropdowns,
  fetchUserSettings,
  updateUserSettings
} from './redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  userSettings: state.navbar.userSettings,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  toggleUserSettings,
  onChangeDropdowns,
  fetchUserSettings,
  updateUserSettings
})(UserSettingsComponent);
