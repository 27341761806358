export const MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS = 'MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS';
export const MULTIPLE_SEARCH_ADD_SELECTED_FILTERS = 'MULTIPLE_SEARCH_ADD_SELECTED_FILTERS';
export const MULTIPLE_SEARCH_CLEAR_ALL_FILTERS = 'MULTIPLE_SEARCH_CLEAR_ALL_FILTERS';
export const MULTIPLE_SEARCH_DELETE_FILTER = 'MULTIPLE_SEARCH_DELETE_FILTER';
export const MULTIPLE_SEARCH_UPDATE_INPUT = 'MULTIPLE_SEARCH_UPDATE_INPUT';
export const MULTIPLE_SEARCH_RESET_SHOW_FILTERS = 'MULTIPLE_SEARCH_RESET_SHOW_FILTERS';
export const MULTIPLE_SEARCH_RESET_STATE = 'MULTIPLE_SEARCH_RESET_STATE';
export const MULTIPLE_SEARCH_SET_LIST_VALUES = 'MULTIPLE_SEARCH_SET_LIST_VALUES';
export interface MultipleSearchAction {
  type: string;
  payload: any;
}

export interface MultipleSearchState {
  multipleFilters: Map<string, MultipleSearchFilter>;
}

export interface MultipleSearchFilter {
  levels: Level[];
  showFilters: boolean;
  selectedFilters: SelectedFilters[];
  singleItemFilters?: boolean;
}

export interface Level {
  level: number;
  categories: Category[];
}

export interface Category {
  label: string;
  isToShowLabel: boolean;
  fields: Field[];
}

export interface Field {
  name: string;
  type: string;
  listValues?: string[];
  currentValue?: any;
  translateList?: boolean;
  tooltipText?: any;
  dateKey?: string;
  class?: string;
  removeLabel?: boolean;
  disabled?: boolean;
  displayEmpty?: boolean
}

export interface SelectedFilters {
  name: string;
  value: string;
  valueToSend?: string;
}

export type ConstructorMap = {
  [k: string]:  () => MultipleSearchFilter
}
