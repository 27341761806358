import React, { Component, Fragment } from 'react';
import { RfTuningStatusResult, RfTuningLimitValues, RfTuningLimitValueRange } from './redux/types';
import T from 'core/i18n/t';

export interface RfTuningTableComponentProps {
  frequencyHistories?: Array<RfTuningStatusResult>;
  frequencyLimits?: Array<RfTuningLimitValues>;
}

function isEmpty(obj: any) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function getModule(frequency: string, frequencyLimits: any) {
  if (!isEmpty(frequencyLimits)) {
    let limitFrquencyToCompare: any = frequencyLimits.find(
      (e: RfTuningLimitValues) => e.lf === frequency
    );
    return limitFrquencyToCompare ? limitFrquencyToCompare.modulation : '-';
  }
}

export default class RfTuningTableComponent extends Component<RfTuningTableComponentProps> {
  thresholds(frequency: string, value: any, column: string) {
    const thresholds =
      this.props.frequencyLimits &&
      this.props.frequencyLimits.find((e: RfTuningLimitValues) => e.lf === frequency);

    if (!thresholds) {
      return ' is-default';
    }

    let thresholdColumn: RfTuningLimitValueRange = { above: '', below: '' };
    switch (column) {
      case 'all':
        const keys = Object.keys(thresholds);
        let isDanger = false;

        for (let i = 0; i < keys.length; i++) {
          switch (keys[i]) {
            case 'pwr':
              if (
                parseFloat(value[keys[i].toUpperCase()]) < parseFloat(thresholds['pwr'].below) ||
                parseFloat(value[keys[i].toUpperCase()]) > parseFloat(thresholds['pwr'].above)
              ) {
                isDanger = true;
              }
              break;
            /* case 'snr':
              if (
                parseFloat(value[keys[i].toUpperCase()]) < parseFloat(thresholds['snr'].below) ||
                parseFloat(value[keys[i].toUpperCase()]) > parseFloat(thresholds['snr'].above)
              ) {
                isDanger = true;
              }
              break; */
            case 'ber':
              if (
                parseFloat(value['BERPO']) > parseFloat(thresholds['ber'].below) ||
                parseFloat(value['BERPO']) > parseFloat(thresholds['ber'].above)
              ) {
                isDanger = true;
              }
              break;
          }
        }

        return isDanger ? ' is-danger' : ' is-success';
      case 'pwr':
        thresholdColumn = thresholds[column];
        break;
      /* case 'snr':
        thresholdColumn = thresholds[column];
        break; */
      case 'ber':
        thresholdColumn.below = thresholds[column].below;
        thresholdColumn.above = thresholds[column].above;
        return (parseFloat(value) > parseFloat(thresholdColumn.below) ||
                  (parseFloat(value) > parseFloat(thresholdColumn.above)))
                ? ' is-danger'
                : '';
      default:
        return ' ';
    }

    return thresholdColumn &&
      (parseFloat(value) < parseFloat(thresholdColumn.below) ||
        (thresholdColumn.above && parseFloat(value) > parseFloat(thresholdColumn.above)))
      ? ' is-danger'
      : '';
  }

  public render() {
    return (
      <Fragment>
        <div className="table-optimized" role="table">
          <div className="flex-table header has-status" role="rowgroup">
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_lf</T>
            </div>
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_pwr</T>
            </div>
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_berpo</T>
            </div>
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_ls</T>
            </div>
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_tsid</T>
            </div>
            <div className="flex-cell" role="columnheader">
              <T>rf_tuning.full_frequency_modulation</T>
            </div>
          </div>

          {this.props.frequencyHistories &&
            this.props.frequencyHistories.map((row: RfTuningStatusResult, index: number) => (
              <div className="flex-table row" role="rowgroup" key={index}>
                <div className="has-status" role="cell">
                  <span className={`is-status${this.thresholds(row.LF, row, 'all')}`}></span>
                </div>

                <div className="flex-cell" role="cell">
                  {row.LF}
                </div>

                <div className={`flex-cell${this.thresholds(row.LF, row.PWR, 'pwr')}`} role="cell">
                  {row.PWR}
                </div>

                <div
                  className={`flex-cell${this.thresholds(row.LF, row.BERPO, 'ber')}`}
                  role="cell"
                >
                  {row.BERPO}
                </div>

                  {/* <div
                    className={`flex-cell${this.thresholds(row.LF, row.SNR, 'snr')}`}
                    role="cell"
                  >
                    {row.SNR}
                  </div> */}

                <div className="flex-cell" role="cell">
                  {row.LS}
                </div>

                <div className="flex-cell" role="cell">
                  {row.TSID}
                </div>

                <div className="flex-cell" role="cell">
                  {getModule(row.LF, this.props.frequencyLimits)}
                </div>
              </div>
            ))}
        </div>
      </Fragment>
    );
  }
}
