import { DVBCRowType } from "./rf-tuning-dvbc-monitoring-table";

function dbmwToDbuv(str: string) {
  return (parseFloat(str) + 90 + 10 * Math.log10(75)).toFixed(2);
}

function fixBerpo(str: string) {
  const parsedBerpo = parseFloat(str)

  if (parsedBerpo === 0) {
    return '1e-10';
  }

  return parsedBerpo.toExponential();
};

function khzToMhz(str: string) {
  return (parseInt(str, 10) / 1000).toString();
};

function isTSID0(str:string) {
  return str === '0'
}

export default (data: Array<Array<string>>): Array<DVBCRowType> => {
  return data.map(row=> ({
    LF: khzToMhz(row[0]),
    PWR: !isTSID0(row[4]) ? dbmwToDbuv(row[1]) : '0',
    BERPO: !isTSID0(row[4]) ? fixBerpo(row[2]) : '0',
    LS: parseFloat(row[3]),
    TSID: row[4],
  }))
};
