import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import {
  DVB_MANAGEMENT_REGION_SELECT,
  DVB_MANAGEMENT_REGIONS_FAILURE,
  DVB_MANAGEMENT_REGIONS_SUCCESS,
  DVBRegion,
  DVBManagementRegionAction
} from './types';
import { selectNode, setNodes } from '../redux-nodes/action';
import { DVB_MANAGEMENT_LOADING_TOGGLE } from '../types';
import { DVB_COLUMNS } from '../enums';
import { ApplicationState } from 'application/application-redux';
import { setColumn } from '../../search/redux/action';
import { getChanges, mergeChanges } from '../action';
import { envs } from 'application/envHandler';

function action(type: any, payload?: any): DVBManagementRegionAction {
  return {
    type: type,
    payload: payload
  };
}
export interface RegionsParams {
  pageSize?: number;
  pageIndex?: number;
  prevArray?: Array<DVBRegion>;
  changes: boolean;
}

export function selectRegion(regionId?: string) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    dispatch(setNodes(undefined, undefined, true));
    dispatch(selectNode());
    dispatch(action(DVB_MANAGEMENT_REGION_SELECT, regionId));

    const selectedRegionChildren = getState().management.spectrum.dvbManagement.region.selected?.children;
    const nodes = selectedRegionChildren && regionId ? selectedRegionChildren : [];
    dispatch(setNodes(nodes, regionId));
    if (!regionId) {
      const htmlElement = document.getElementsByClassName('card-column-container')[0];
      if (htmlElement) {
        htmlElement.classList.remove('animated');
      }
    }
  };
}

export function setRegions(items?: Array<DVBRegion>, isSearch?: boolean) {
  return async function (dispatch: Function) {
    new Promise<void>((resolve) => {
      dispatch(action(DVB_MANAGEMENT_REGIONS_SUCCESS, { items, isSearch }));
      resolve();
    }).then(() => {
      if (items) {
        dispatch(mergeChanges(DVB_COLUMNS.REGION));
        dispatch(setColumn(DVB_COLUMNS.REGION));
      }
    });
  };
}

export function getRegions(params: RegionsParams) {
  const opco = AuthService.getCurrentOpco();
  return async function(dispatch: Function, getState: () => ApplicationState) {
    dispatch(action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: true, column: DVB_COLUMNS.REGION }));
    const opco = AuthService.getCurrentOpco();
    await AuthService.refreshToken();
    const queryPageSize = params.pageSize ? params.pageSize : 25;
    const queryPageIndex = params.pageIndex ? params.pageIndex : 1;
    const totalRegions: Array<DVBRegion> = params.prevArray ? params.prevArray : [];
    let totalElements: number;
    let totalRegionsCount: number;
    return apiClient
      .get(
        `${envs.REACT_APP_API_URL}/be/spectrum/regions/opcos/${opco}?pageSize=${queryPageSize}&pageIndex=${queryPageIndex}`
      )
      .then(
        response => {
          totalRegions.push(...response.data.result);

          totalElements = response.data.totalCount;
          totalRegionsCount = totalRegions.length;

          if (totalElements > totalRegionsCount) {
            dispatch(
              getRegions({
                pageSize: queryPageSize,
                pageIndex: queryPageIndex + 1,
                prevArray: totalRegions,
                changes: params.changes
              })
            );
          } else {
            dispatch(setRegions(totalRegions));
          }
        },
        rejection => {
          return dispatch(action(DVB_MANAGEMENT_REGIONS_FAILURE, rejection));
        }
      )
      .finally(() => {
        if (params.changes) {
          dispatch(getChanges(DVB_COLUMNS.REGION));
        } else {
          dispatch(
            action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.REGION })
          );
        }
      });
  };
}
