import React, { Component } from 'react';
import { TFunction } from 'i18next';
import { IpGeolocationResult, IpGeolocationResultRow } from './redux/reducer';

interface IpGeolocationGridComponentProps {
  currentFilter: any;
  results: IpGeolocationResult;
  execute: any;
  t: TFunction;
}

export default class IpGeolocationGridComponent extends Component<IpGeolocationGridComponentProps> {
  componentDidMount(): void {
    const { execute } = this.props;
    execute();
  }

  render() {
    const { currentFilter, results, t } = this.props;
    return (
      <div className="paper table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th colSpan={8} className="subtitle is-7">
                {t('ip_geolocation.title_grid')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_opco')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_serial_number')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_ip_address_start')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_ip_address_end')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_creation_date')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_start_date')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_expiration')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('ip_whitelist.column_user')}
              </th>
            </tr>
            {results &&
              results.content
                .filter((result: IpGeolocationResultRow) => {
                  if (currentFilter) {
                    return (
                      result.ipAddressStart.startsWith(currentFilter) ||
                      result.ipAddressEnd.startsWith(currentFilter)
                    );
                  }
                  return result;
                })
                .map((result: IpGeolocationResultRow) => {
                  return (
                    <tr key={result.userId}>
                      <td>{result.opcoId}</td>
                      <td>{result.serialNumber}</td>
                      <td>{result.ipAddressStart}</td>
                      <td>{result.ipAddressEnd}</td>
                      <td>{result.createDate}</td>
                      <td>{result.startDate}</td>
                      <td>{result.expiryDate}</td>
                      <td>{result.userId}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    );
  }
}
