import { TFunction } from 'i18next';
import { NotificationType, VipocNotification } from './types';

export default (data: VipocNotification, t: TFunction): VipocNotification => {
  const notificationType = getNotificationType(data.payload?.result);
  return {
    createdAt: data.createdAt,
    id: data.id,
    read: data.read,
    object: data.object,
    title: data.title ? data.title.charAt(0).toUpperCase() + data.title.slice(1) : '',
    source: data.source ? data.source.charAt(0).toUpperCase() + data.source.slice(1) : '',
    type: notificationType,
    message: getMessage(notificationType, data.object.status, t),
    module: data.module,
    payload: data.payload
    
  };
};

function getNotificationType(result: any) {
  if (result) {
    if (!result.failed[0] || result.failed[0] === 0) {
      return NotificationType.SUCCESS;
    } else if (!result.succeeded[0] || result.succeeded[0] === 0) {
      return NotificationType.ERROR;
    }
  }

  return NotificationType.WARNING;
}

function getMessage(type: string, status: string, t: TFunction) {
  switch (type) {
    case NotificationType.ERROR:
      return t('notifications.spectrum.error');
    case NotificationType.SUCCESS:
      return t('notifications.spectrum.success');
    case NotificationType.WARNING:
      return t('notifications.spectrum.warning').replace('{0}', status);
    default:
      return '';
  }
}
