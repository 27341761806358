import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import ApplicationComponent from 'application/application-container';
import { configureStore, hist } from 'application/application-redux';
import 'assets/scss/_main.scss';
import AuthService from 'core/auth';
import { ACCESS_TOKEN_KEY } from 'core/auth/constants';
import apiClient from 'core/api';
import i18nInitialize, { getLanguage } from 'core/i18n/i18n';
import { envs } from './envHandler';
import { preLoadRoutes } from './application-routes';
import initializeState from './application-state-initialization';

apiClient.initialize(ACCESS_TOKEN_KEY);

export default class ApplicationContainer extends Component {
  userIsActive: any;
  oneMin = 60 * 1000;
  maxTimeUserInactivity: any;
  intervalFunction: any;
  timeToBeLoggout: any;

  logout() {
    apiClient
      .get(`${envs.REACT_APP_API_URL}/auth/token-logout`, {
        params: { token: localStorage.getItem(ACCESS_TOKEN_KEY) }
      })
      .then(
        () => {
          AuthService.logout();
          window.location.href = `${envs.REACT_APP_COGNITO_LOGIN_URL}`;
        },
        () => {
          AuthService.logout();
          window.location.href = `${envs.REACT_APP_COGNITO_LOGIN_URL}`;
        }
      );
  }

  refreshToken = async () => {
    var token: any = localStorage.getItem(ACCESS_TOKEN_KEY);
    const decodedUser = jwt_decode(token) as any;

    let timeToRefreshToken = (decodedUser.exp - Math.floor(Date.now() / 1000)) * 1000;
    console.log(
      'TOKEN -> timeToRefreshToken:',
      new Date(timeToRefreshToken).toISOString().slice(11, 19),
      'userIsActive: ',
      this.userIsActive
    );

    if (this.userIsActive === false) {
      this.logout();
    } else {
      if (timeToRefreshToken <= 90000 && this.userIsActive) {
        //if passed the time to refresh the token AND user is active
        await AuthService.refreshTokenByTime();
        token = localStorage.getItem(ACCESS_TOKEN_KEY);
      }
    }
  };

  inactivityTime = () => {
    let token: any = localStorage.getItem(ACCESS_TOKEN_KEY);
    let decodedUser = jwt_decode(token) as any;
    //time for user to be considered inactive
    let maxTimeUserInactivity = (decodedUser.exp - decodedUser.iat) * 1000;

    console.log('TOKEN -> maxTimeUserInactivity:', new Date(maxTimeUserInactivity).toISOString().slice(11, 19))

    const logoutAction = () => {
      this.userIsActive = false;
    };

    const resetTimer = () => {
      // console.log('TOKEN -> USER MOVE:', new Date(Date.now()).toISOString().slice(11, 19),);
      clearTimeout(this.timeToBeLoggout);
      this.userIsActive = true;
      this.timeToBeLoggout = setTimeout(logoutAction, maxTimeUserInactivity);
    };

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.ontouchmove = resetTimer; // required by some devices
    window.onclick = resetTimer; // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener('scroll', resetTimer, true);
  };

  componentDidMount() {
    this.inactivityTime();
    this.userIsActive = true;

    this.intervalFunction = setInterval(() => {
      this.refreshToken();
    }, this.oneMin / 2);
  }

  render() {
    initializeState()
    const reduxStore = configureStore({})
    reduxStore.dispatch(preLoadRoutes())

    i18nInitialize(
      reduxStore,
      `${envs.REACT_APP_API_URL}/i18n`,
      getLanguage(AuthService.getCurrentLanguage())
    )
    
    return <ApplicationComponent histor={hist} store={reduxStore} />;
  }
}
