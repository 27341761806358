import { TFunction } from 'i18next';
import { ChannelScanRowResult } from './types';

export default (data: ChannelScanRowResult): ChannelScanRowResult => {
  return {
    type: data ? data.type : '',
    nrChannels: data ? data.nrChannels : '',
    frequency: data ? data.frequency : '',
    symbrate: data ? data.symbrate : '',
    regionID: data ? data.regionID : ''
  };
};

export function labelsChannelScan(t: TFunction): ChannelScanRowResult {
  return {
    type: t('channels.column_type'),
    nrChannels: t('channels.column_nr_channel'),
    frequency: t('channels.column_frequency'),
    symbrate: t('channels.column_symbrate'),
    regionID: t('channels.column_region_id')
  };
}
