import { TFunction } from 'i18next';
import { DeviceShadow } from '../types';

export function updateToSpecificFirmwareParameters(t: TFunction) {
  const map = new Map();
  map.set('firmwareImageURL', {
    label: t('settings.label_firm_img_url'),
    type: 'text',
    value: ''
  });

  map.set('uiImageURL', {
    label: t('settings.label_ui_img_url'),
    type: 'text',
    value: ''
  });

  map.set('loaderImageURL', {
    label: t('settings.label_loader_img_url'),
    type: 'text',
    value: ''
  });

  return map;
}

export function doPartialResetParameters(t: TFunction) {
  const map = new Map();
  map.set('recordings', {
    label: t('settings.label_recordings'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  });

  map.set('drm', {
    label: t('settings.label_DRM'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  });

  map.set('network', {
    label: t('settings.label_network'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  });

  // TODO commented in VGSVTVVIPOC-460
  /* map.set('bluetooth', {
    label: t('settings.label_bluetooth'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  }); */

  map.set('filesystem', {
    label: t('settings.label_filesystem'),

    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  }); 

  return map;
}
export function doFactoryResetParameters(t: TFunction) {
  const map = new Map();
  map.set('hard', {
    label: t('settings.label_hard'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  });

  return map;
}

export function rebootParameters(t: TFunction) {
  const map = new Map();
  map.set('forced', {
    label: t('settings.label_forced'),
    type: 'boolean',
    value: false,
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ]
  });
  return map;
}

export function setPowerSavingModeParameters(t: TFunction, shadow?: DeviceShadow) {
  const map = new Map();
  map.set('pSvM', {
    label: t('command.label.set_power_saving_mode'),
    type: 'unique',
    value: shadow && shadow.reported && shadow.reported.config ? shadow.reported.config.pSvM : '',
    available: [
      { value: 'active', label: t('settings.value_active') },
      { value: 'passive', label: t('settings.value_passive') }
    ]
  });

  return map;
}

export function loggingEnableMap(logLevelValue: number, windowValue: number) {
  const map = new Map();
  map.set('logLevel', {
    label: 'Log Level',
    type: 'number',
    value: logLevelValue
  });
  map.set('window', {
    label: 'Window',
    type: 'number',
    value: windowValue
  });

  return map;
}
