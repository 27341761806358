import moment from 'moment';
import { MultipleSearchFilter } from './types';

export const containsValues: string[] = [
  'accountNumber',
  'casStb',
  'serialStb',
  'vscLegacy',
  'stbMac',
  'bssFileName',
  'sbpResponseDateTime',
  'reprocessCode',
  'reprocessDateTime',
  'nagraManualRetry',
  'createdBy',
  'nagraEmmPlayoutDate'
];

export const advancedSearchMultipleSearch: MultipleSearchFilter = {
  levels: [
    {
      level: 1,
      categories: [
        {
          label: 'principal',
          isToShowLabel: false,
          fields: [
            {
              name: 'custom.udid',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'deviceShadow.reported.account.HHID',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'custom.kdSerialNumber',
              type: 'text',
              currentValue: ''
            }
          ]
        }
      ]
    },
    {
      level: 2,
      categories: [
        {
          label: 'hardware',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.hardware.brn',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'deviceShadow.reported.hardware.mod',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'deviceShadow.reported.hardware.SN',
              type: 'text',
              currentValue: ''
            }
          ]
        }
      ]
    },
    {
      level: 3,
      categories: [
        {
          label: 'deviceStatus',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.status.connected',
              type: 'list',
              listValues: ['TRUE', 'FALSE'],
              currentValue: '',
              translateList: true
            }
          ]
        },
        {
          label: 'lastConnectionDate',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.status.lastConnectionDate',
              type: 'date',
              dateKey: 'general.select_date',
              currentValue: {
                start: moment(),
                end: moment()
              }
            }
          ]
        },
        {
          label: 'software',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.software.ver',
              type: 'text',
              currentValue: ''
            }
          ]
        },
        {
          label: 'account',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.account.regID',
              type: 'text',
              currentValue: ''
            }
          ]
        }
      ]
    }
  ],
  showFilters: false,
  selectedFilters: [],
  singleItemFilters: true
};

export const advancedSearchSingleSearch: MultipleSearchFilter = {
  levels: [
    {
      level: 1,
      categories: [
        {
          label: 'principal',
          isToShowLabel: false,
          fields: [
            {
              name: 'selectBox',
              type: 'list',
              currentValue: '',
              listValues: ['custom.udid', 'deviceShadow.reported.account.HHID','custom.kdSerialNumber'],
              translateList: true,
              class: 'is-4'
            },
            {
              name: 'inputBox',
              type: 'text',
              currentValue: '',
              class: 'is-5',
              removeLabel: true,
              disabled: true
            },
          ]
        }
      ]
    },
    {
      level: 2,
      categories: [
        {
          label: 'hardware',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.hardware.brn',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'deviceShadow.reported.hardware.mod',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'deviceShadow.reported.hardware.SN',
              type: 'text',
              currentValue: ''
            }
          ]
        }
      ]
    },
    {
      level: 3,
      categories: [
        {
          label: 'deviceStatus',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.status.connected',
              type: 'list',
              listValues: ['TRUE', 'FALSE'],
              currentValue: '',
              translateList: true
            }
          ]
        },
        {
          label: 'lastConnectionDate',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.status.lastConnectionDate',
              type: 'date',
              dateKey: 'general.select_date',
              currentValue: {
                start: moment(),
                end: moment()
              }
            }
          ]
        },
        {
          label: 'software',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.software.ver',
              type: 'text',
              currentValue: ''
            }
          ]
        },
        {
          label: 'account',
          isToShowLabel: true,
          fields: [
            {
              name: 'deviceShadow.reported.account.regID',
              type: 'text',
              currentValue: ''
            }
          ]
        }
      ]
    }
  ],
  showFilters: false,
  selectedFilters: [],
  singleItemFilters: true
};

export function dvrMultipleSearchConstructor (): MultipleSearchFilter {
  return {
    levels: [
      {
        level: 1,
        categories: [
          {
            label: 'principal',
            isToShowLabel: false,
            fields: [
              {
                name: 'asset',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'programId',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'program',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'status',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'recordingType',
                type: 'list',
                listValues: ['cloud', 'local'],
                currentValue: 'cloud',
                translateList: true,
                displayEmpty: false,
              },
            ]
          }
        ]
      },
      {
        level: 2,
        categories: [
          {
            label: 'channel',
            isToShowLabel: true,
            fields: [
              {
                name: 'channel',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'channelNumber',
                type: 'text',
                currentValue: ''
              }
            ]
          },
          {
            label: 'start_time',
            isToShowLabel: true,
            fields: [
              {
                name: 'startTime',
                type: 'date',
                dateKey: 'general.select_date',
                currentValue: {
                  start: moment(),
                  end: moment()
                }
              }
            ]
          },
          {
            label: 'end_time',
            isToShowLabel: true,
            fields: [
              {
                name: 'endTime',
                type: 'date',
                dateKey: 'general.select_date',
                currentValue: {
                  start: moment(),
                  end: moment()
                }
              }
            ]
          }
        ]
      },
      {
        level: 3,
        categories: [
          {
            label: 'management',
            isToShowLabel: true,
            fields: [
              {
                name: 'source',
                type: 'text',
                currentValue: ''
              },
              {
                name: 'lastEvent',
                type: 'date',
                dateKey: 'dvr.field_lastEvent',
                currentValue: {
                  start: moment(),
                  end: moment()
                }
              }
            ]
          },
          {
            label: 'actual_start_time',
            isToShowLabel: true,
            fields: [
              {
                name: 'actualStartTime',
                type: 'date',
                dateKey: 'general.select_date',
                currentValue: {
                  start: moment(),
                  end: moment()
                }
              }
            ]
          },
          {
            label: 'actual_end_time',
            isToShowLabel: true,
            fields: [
              {
                name: 'actualEndTime',
                type: 'date',
                dateKey: 'general.select_date',
                currentValue: {
                  start: moment(),
                  end: moment()
                }
              }
            ]
          }
        ]
      }
    ],
    showFilters: false,
    selectedFilters: [
      {
        name: 'recordingType',
        value: 'cloud'
      }
    ],
    singleItemFilters: true
  };
}

export const activityLogMultipleSearch: MultipleSearchFilter = {
  levels: [
    {
      level: 1,
      categories: [
        {
          label: 'principal',
          isToShowLabel: false,
          fields: [
            {
              name: 'traceId',
              type: 'text',
              currentValue: ''
            },
            {
              name: 'status',
              type: 'list',
              listValues: ['success', 'inProgress', 'failed'],
              currentValue: '',
              translateList: true
            },
            {
              name: 'eventName',
              type: 'list',
              listValues: [],
              currentValue: '',
              translateList: true
            },
            {
              name: 'lastManaged',
              type: 'date',
              dateKey: 'general.select_date',
              currentValue: {
                start: moment(),
                end: moment()
              }
            }
          ]
        }
      ]
    }
  ],
  showFilters: false,
  selectedFilters: [],
  singleItemFilters: true
};

export const requestMultipleSearch: MultipleSearchFilter = {
  levels: [
    {
      level: 1,
      categories: [
        {
          label: 'principal',
          isToShowLabel: false,
          fields: [
            {
              name: 'status',
              type: 'list',
              listValues: ['approved', 'canceled', 'draft', 'expired', 'pending', 'rejected'],
              currentValue: '',
              translateList: true
            },
            {
              name: 'createdBy',
              type: 'list',
              listValues: [],
              currentValue: '',
              translateList: false,
            }
          ]
        }
      ]
    }
  ],
  showFilters: false,
  selectedFilters: [],
  singleItemFilters: true
};
