import React, { Component, Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import './graphic-styles.scss';

interface GraphicProps {
  data: any;
  currentGraphic: string;
}

export default class GraphicComponent extends Component<GraphicProps> {
  render() {
    const { currentGraphic } = this.props;
    return (
      <Fragment>
        <div className="graph-container">
          <Bar
            data={this.props.data}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                      max: currentGraphic.includes('berpo')
                        ? -10
                        : currentGraphic.includes('pwr')
                        ? 80
                        : 80,
                      callback(value: any) {
                        if (currentGraphic.includes('berpo')) {
                          if (value === 0) {
                            return value;
                          }

                          return (value = `1e-${value}`);
                        }

                        return value;
                      }
                    },
                    type: currentGraphic.includes('berpo') ? 'logarithmic' : 'linear'
                  }
                ],
                xAxes: [
                  {
                    categoryPercentage: 1,
                    barPercentage: 1
                  }
                ]
              },
              legend: {
                display: false
              },
              tooltips: false,
              maintainAspectRatio: false
            }}
          />
        </div>
      </Fragment>
    );
  }
}
