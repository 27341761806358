import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import MultipleSearchComponent from './multiple-search-component';
import { toggleShowMoreFilters, clearAllFilters, deleteFilter } from './redux/action';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  t: state.i18n.t,
  multipleSearch: state.multipleSearch
});

export default connect(mapStateToProps, {
  toggleShowMoreFilters,
  clearAllFilters,
  deleteFilter,
  goToFirstPage
})(MultipleSearchComponent);
