export const DEVICE_DETAIL_SETTINGS_TRANSLATE = 'DEVICE_DETAIL_SETTINGS_TRANSLATE';

export const DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW =
  'DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW';

export const DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW =
  'DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW';

export const DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER = 'DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER';
export const DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION =
  'DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION';

export const DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR = 'DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR';
export const DEVICE_DETAIL_SETTINGS_CLEAR_PENDING_EXECUTIONS =
  'DEVICE_DETAIL_SETTINGS_CLEAR_PENDING_EXECUTIONS';
export const DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS = 'DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS';

export const DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_REQUEST =
  'DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_REQUEST';
export const DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS =
  'DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS';
export const DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE =
  'DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE';

export const DEVICE_DETAIL_SETTINGS_VIP_FETCH_REQUEST = 'DEVICE_DETAIL_SETTINGS_VIP_FETCH_REQUEST';
export const DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS = 'DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS';
export const DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE = 'DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE';

export const DEVICE_DETAIL_SETTINGS_SELECT_ACTION = 'DEVICE_DETAIL_SETTINGS_SELECT_ACTION';

export const DEVICE_DETAIL_SETTINGS_EXECUTION_REQUEST = 'DEVICE_DETAIL_SETTINGS_EXECUTION_REQUEST';
export const DEVICE_DETAIL_SETTINGS_EXECUTION_FAILURE = 'DEVICE_DETAIL_SETTINGS_EXECUTION_FAILURE';
export const DEVICE_DETAIL_SETTINGS_EXECUTION_SUCCESS = 'DEVICE_DETAIL_SETTINGS_EXECUTION_SUCCESS';

export const DEVICE_DETAIL_SETTINGS_ACTION_RUN_REQUEST =
  'DEVICE_DETAIL_SETTINGS_ACTION_RUN_REQUEST';
export const DEVICE_DETAIL_SETTINGS_ACTION_RUN_SUCCESS =
  'DEVICE_DETAIL_SETTINGS_ACTION_RUN_SUCCESS';
export const DEVICE_DETAIL_SETTINGS_ACTION_RUN_FAILURE =
  'DEVICE_DETAIL_SETTINGS_ACTION_RUN_FAILURE';

export const SETTINGS_VIP_CLEAR_CACHE = 'SETTINGS_VIP_CLEAR_CACHE';
export const SETTINGS_SHADOW_CLEAR_CACHE = 'SETTINGS_SHADOW_CLEAR_CACHE';

export const DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_ACTION =
  'DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_ACTION';
export const DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_WINDOW_ACTION =
  'DEVICE_DETAIL_SETTINGS_CHANGE_LOGS_WINDOW_ACTION';

export const DEVICE_DETAIL_SETTINGS_ACTION_LOGS_REQUEST =
  'DEVICE_DETAIL_SETTINGS_ACTION_LOGS_REQUEST';
export const DEVICE_DETAIL_SETTINGS_CLEAR_LOGS_ACTION = 'DEVICE_DETAIL_SETTINGS_CLEAR_LOGS_ACTION';
export const DEVICE_DETAIL_SETTINGS_LOGS_ASSETS_VALUES =
  'DEVICE_DETAIL_SETTINGS_LOGS_ASSETS_VALUES';

export interface DeviceSettingOption {
  value: any;
  label: string;
  active?: boolean;
}

export interface DeviceSettingAssetsValues {
  assetName: string;
  value: string;
  label: string;
  opco: string;
}

export type ExecutionStatus = 'success' | 'failure' | 'pending';

export interface DeviceSettingActionParameter {
  label: string;
  value: string;
  parameters: Map<any, any>;
  previousParameters?: Map<any, any>;
}

export type DeviceDEtailSettingParameterType =
  | 'list'
  | 'text'
  | 'boolean'
  | 'multiSelect'
  | 'number';

export interface DeviceDetailSettingParameter {
  category:
    | 'audioAndSubtitles'
    | 'hdmi'
    | 'accessibility'
    | 'startupAndStandby'
    | 'advanced'
    | 'parentalMode';
  label: string;
  type: DeviceDEtailSettingParameterType;
  value: any;
  disabled?: boolean;
  available?: Array<DeviceSettingOption>;
  error?: string;
  householdId?: string;
}

export interface DeviceDetailSettingsAction {
  type: string;
  payload: any;
}

export interface DeviceDetailSettingsState {
  tabs: {
    firstRow: {
      current: string;
    };

    secondRow: {
      current: string;
    };
  };

  oldParameters?: Map<string, DeviceDetailSettingParameter>;
  parameters?: Map<string, DeviceDetailSettingParameter>;
  shadow?: DeviceShadow;

  logs: LogsState;
  oldlogs?: LogsState;
  actions: {
    selected?: DeviceSettingActionParameter;
    available: Array<DeviceSettingActionParameter>;
  };

  hasChanged: boolean;

  executions: Map<string, ExecutionStatus>;
  assetsValues?: Map<string, DeviceSettingAssetsValues>;
}

export interface DeviceShadowConfiguration {
  actStByT: number;
  conDev: string;
  GDPRCsV: number;
  HDMIAM: string;
  HDMIM: string;
  HDMIR: string;
  hearImpSu: boolean;
  nType: string;
  pAuLg: string;
  pSuLg: string;
  RCM: string;
  sAuLg: string;
  sSuLg: string;
  standBy: string;
  subs: number;
  TVCtrl: string;
  UILg: string;
  unSuCh: string;
  visImpAu: string;
  visibility: string;
  pRatg: number;
  parentalPin: string;
  pSvM: string;
}

export interface DeviceShadow {
  reported?: { config?: DeviceShadowConfiguration };
}

export interface KeyValues {
  label: string;
  value: number | KeyValues[];
  selected?: boolean;
}

export interface LogsAction {
  type: any;
  payload: any;
}

export interface LogsState {
  logLevelFields: KeyValues[];
  window: KeyValues;
  hasChanged: boolean;
  hasChangedParameters: boolean;
}

export interface LogsAsset {
  assetName: string;
  label: string;
  value: number;
}
