import { dvrAccordion } from './config';
import {
  TableAccordionAction,
  TableAccordionState,
  TOGGLE_ITEM,
  DELETE_ITEM,
  CHANGE_ACCORDION_PAGE,
  CLEAR_ACCORDION_TABLE,
  CLEAR_CACHE_ACCORDION_TABLE
} from './types';

const initialAccordion = new Map();
initialAccordion.set('accordion.dvr', dvrAccordion);

const initialState: TableAccordionState = {
  accordion: initialAccordion
};

function getAccordion(state: TableAccordionState, id: string) {
  return state.accordion.get(id);
}

export default function (state = initialState, { type, payload }: TableAccordionAction) {
  switch (type) {
    case TOGGLE_ITEM:
      let accordionItem = getAccordion(state, payload.id);
      if (accordionItem) {
        const start =
          (payload.pagination && payload.pagination.pageSize * payload.pagination.pageNumber) || 0;
        const end =
          (payload.pagination &&
            payload.pagination.pageSize * (payload.pagination.pageNumber + 1)) ||
          10;
        accordionItem.shownIndex = payload.index !== accordionItem.shownIndex ? payload.index : -1;
        accordionItem.tableItems = payload.items;
        accordionItem.tableItemsToShow = payload.items.slice(start, end);
      }

      return {
        ...state
      };

    case DELETE_ITEM:
      return {
        ...state
      };

    case CHANGE_ACCORDION_PAGE:
      let pageItem = getAccordion(state, payload.id);
      if (pageItem) {
        pageItem.shownIndex = -1;
        pageItem.tableItems = [];
        pageItem.tableItemsToShow = [];
      }
      return {
        ...state
      };

    case CLEAR_ACCORDION_TABLE:
      let tableItem = getAccordion(state, payload.id);
      if (tableItem) {
        tableItem.shownIndex = -1;
        tableItem.tableItems = [];
        tableItem.tableItemsToShow = [];
      }
      return {
        ...state
      };

    case CLEAR_CACHE_ACCORDION_TABLE:
      state.accordion.forEach((accordionTable: any) => {
        accordionTable.shownIndex = -1;
        accordionTable.tableItems = [];
        accordionTable.tableItemsToShow = [];
      });
      return {
        ...state
      };
    default: {
      return { ...state };
    }
  }
}
