import { DVBStream } from '../redux-streams/types';
import { DVBItemChanged, DVBItemToShow } from '../types';

export const DVB_MANAGEMENT_NETWORK_PASTE = 'DVB_MANAGEMENT_NETWORK_PASTE';
export const DVB_MANAGEMENT_NETWORK_SELECT = 'DVB_MANAGEMENT_NETWORK_SELECT';
export const DVB_MANAGEMENT_NETWORKS_SET = 'DVB_MANAGEMENT_NETWORKS_SET';

export interface DVBManagementNetworkAction {
  type:
    | typeof DVB_MANAGEMENT_NETWORK_PASTE
    | typeof DVB_MANAGEMENT_NETWORK_SELECT
    | typeof DVB_MANAGEMENT_NETWORKS_SET;
  payload: any;
}

export interface DVBNetwork extends DVBItemToShow, DVBItemChanged {
  onId: string;
  transportStreams?: Array<DVBStream>;
}

export interface DVBManagementNetwork {
  items: Array<DVBNetwork>;
  itemsChanged?: Array<DVBNetwork>;
  itemsSearch?: Array<DVBNetwork>;
  itemsAux?: Array<DVBNetwork>;
  selected?: DVBNetwork;
  copied?: DVBNetwork;
}
