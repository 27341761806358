import { add, remove } from 'core/spinner/redux/actions';
import { AxiosError, AxiosResponse } from 'axios';
import apiClient from 'core/api';
import { toGenericError } from 'core/utils/error-utils';
import { addToaster } from 'core/toaster/redux/actions';
import authService from 'core/auth';
import { ApplicationState } from 'application/application-redux';
import {
  OVERLAY_MODAL_ESN_CLOSE,
  OVERLAY_MODAL_ESN_OPEN,
  FILE_UPLOAD_ESN_MAPPING_FAILURE,
  FILE_UPLOAD_ESN_MAPPING_REQUEST,
  FILE_UPLOAD_ESN_MAPPING_SUCCESS,
  SET_FILE_TO_UPLOAD
} from './types';
import { envs } from 'application/envHandler';

export function setFileValue(data: FormData) {
  return {
    type: SET_FILE_TO_UPLOAD,
    payload: data
  };
}

export const open = (options: { title?: string; content?: string; execute?: any }) => ({
  type: OVERLAY_MODAL_ESN_OPEN,
  payload: options
});

export const close = () => ({
  type: OVERLAY_MODAL_ESN_CLOSE
});

function request() {
  return {
    type: FILE_UPLOAD_ESN_MAPPING_REQUEST
  };
}

function success(payload: any) {
  return {
    type: FILE_UPLOAD_ESN_MAPPING_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: FILE_UPLOAD_ESN_MAPPING_FAILURE,
    payload
  };
}

export function uploadFile(data: FormData) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(request());
    dispatch(add(FILE_UPLOAD_ESN_MAPPING_REQUEST, {}));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data', opco: authService.getCurrentOpco() }
    };
    apiClient.post(`${envs.REACT_APP_API_URL}/esn-mapping/upload`, data, config).then(
      (response: AxiosResponse) => {
        dispatch(remove(FILE_UPLOAD_ESN_MAPPING_REQUEST));
        dispatch(
          addToaster({
            title: 'home.icon_esn_mapping',
            message: 'alert_info_messages.VIPOC_INFO_002',
            type: 'success'
          })
        );
        return dispatch(success(response.data));
      },
      (axiosError: AxiosError) => {
        const error = toGenericError(axiosError);
        if (error.code === 422) {
          dispatch(
            addToaster({
              title: 'home.icon_esn_mapping',
              message: getState().i18n.t('error_messages.VIPOC_ERROR_006') + error.message,
              type: 'danger'
            })
          );
        } else {
          dispatch(
            addToaster({
              title: 'home.icon_esn_mapping',
              message: getState().i18n.t('error_messages.VIPOC_ERROR_005') + error.message,
              type: 'danger'
            })
          );
        }

        dispatch(remove(FILE_UPLOAD_ESN_MAPPING_REQUEST));
        return dispatch(failure('Failure'));
      }
    );
  };
}
