export const columns = [
  { name: 'channelId', hidden: true, field: 'channelId' },
  { name: 'spectrum.channels.column_media_id', width: '10%', sort: true, field: 'mediaId' },
  { name: 'spectrum.channels.column_number', width: '10%', sort: true, field: 'number' },
  { name: 'spectrum.channels.column_name', width: '30%', sort: true, field: 'name' },
  { name: 'spectrum.channels.column_resolution', width: '10%', sort: true, field: 'resolution' },
  { name: 'spectrum.channels.column_epg_id', width: '10%', sort: true, field: 'epgId' },
  { name: 'spectrum.channels.column_device_type', width: '10%', sort: true, field: 'deviceType' },
  { name: 'spectrum.channels.column_level', width: 'calc(20% - 80px)', sort: true, field: 'level' }
];
