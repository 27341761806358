import moment from 'moment';
import React, { Fragment, Component } from 'react';
import InputComponent from '../input/input-container';
import SelectComponent from '../select/select-container';
import DatePicker from 'core/date/datepicker';
import { TFunction } from 'i18next';

interface FieldProps {
  t: TFunction;
  addSelectedFilters: (id: string, value: string, name: string, type?: string) => void;
  id: string;
  fieldData: {
    type: string;
    name: string;
    listValues: string[];
    currentValue: any;
    tooltipText: any;
    translateList?: boolean;
    dateKey?: string;
    inputDisabled?: boolean;
    removeLabel?: boolean;
  };
  i18nKey?: string;
  displayEmpty?: boolean
}

export default class FieldComponent extends Component<FieldProps, { clicked: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      clicked: false
    };
  }
  render() {
    const { t, addSelectedFilters, fieldData, id, i18nKey, displayEmpty = true } = this.props;

    return (
      <div onClick={() => {this.setState({ ...this.state, clicked: true })}}>
        {fieldData && (fieldData.type === 'text' || fieldData.type === 'number') && (
          <InputComponent
            inputData={{
              name: fieldData.name,
              currentValue: fieldData.currentValue,
              type: fieldData.type,
              tooltipText: fieldData.tooltipText,
              removeLabel: fieldData.removeLabel
            }}
            inputDisabled={fieldData.inputDisabled}
            id={id}
            i18nKey={i18nKey}
          />
        )}

        {fieldData && fieldData.type === 'list' && (
          <SelectComponent
            selectData={{
              name: fieldData.name,
              listValues: fieldData.listValues,
              currentValue: fieldData.currentValue,
              tooltipText: fieldData.tooltipText,
              translateList: fieldData.translateList,
              removeLabel: fieldData.removeLabel
            }}
            id={id}
            i18nKey={i18nKey}
            displayEmpty={displayEmpty}
          />
        )}

        {fieldData && fieldData.type === 'check' && (
          <div className="has-checkbox checkbox">
            <input
              className="is-small"
              type="checkbox"
              name={t(fieldData.name)}
              onChange={() =>
                addSelectedFilters(id, fieldData.name, 'nagraEmmPlayoutStatus', 'check')
              }
              checked={fieldData.currentValue}
            />
            <label>{t(`${i18nKey || 'datagrabber'}.field_${fieldData.name}`)}</label>
          </div>
        )}

        {fieldData && fieldData.type === 'date' && (
          <DatePicker
            isRange
            opened={this.state.clicked}
            value={{
              start: moment(),
              end: moment()
            }}
            label={fieldData.dateKey && t(fieldData.dateKey)}
            onChangePeriod={(date: any) => {
              addSelectedFilters(id, date, fieldData.name);
            }}
          />
        )}

        {fieldData && fieldData.type === 'singleDate' && (
          <DatePicker
            isRange={false}
            opened={this.state.clicked}
            label={fieldData.dateKey && t(fieldData.dateKey)}
            onChangeDate={(date: any) => {
              addSelectedFilters(id, date, fieldData.name);
            }}
          />
        )}

        {fieldData && fieldData.type === 'localDate' && (
          <DatePicker
            isRange
            opened={this.state.clicked}
            value={{
              start: moment(),
              end: moment()
            }}
            label={fieldData.dateKey && t(fieldData.dateKey)}
            onChangePeriod={(date: any) => {
              addSelectedFilters(id, date, fieldData.name);
            }}
            isLocalTime
          />
        )}
      </div>
    );
  }
}
