import { ApplicationState } from 'application/application-redux';
import { addToaster } from 'core/toaster/redux/actions';
import {
  MULTIPLE_SEARCH_ADD_SELECTED_FILTERS,
  MULTIPLE_SEARCH_CLEAR_ALL_FILTERS,
  MULTIPLE_SEARCH_DELETE_FILTER,
  MULTIPLE_SEARCH_RESET_SHOW_FILTERS,
  MULTIPLE_SEARCH_RESET_STATE,
  MULTIPLE_SEARCH_SET_LIST_VALUES,
  MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS,
  MULTIPLE_SEARCH_UPDATE_INPUT
} from './types';

export function toggleShowMoreFilters(id: string) {
  return {
    type: MULTIPLE_SEARCH_TOGGLE_SHOW_FILTERS,
    payload: {
      id
    }
  };
}

export function updateInput(id: string, value: string, name: string) {
  return {
    type: MULTIPLE_SEARCH_UPDATE_INPUT,
    payload: {
      id,
      value,
      name
    }
  };
}

export function addSelectedFilters(id: string, value: string, name: string, type?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const searchItem = getState().multipleSearch.multipleFilters.get(id);
    const hasItem =
      searchItem && searchItem?.selectedFilters.some((item: any) => item.name === name);

    if (searchItem && searchItem.selectedFilters.length >= 6 && !hasItem) {
      value = '';
      dispatch(
        addToaster({
          title: 'alert_info_messages.VIPOC_INFO_010',
          message: 'alert_info_messages.VIPOC_INFO_016',
          type: 'info'
        })
      );
      return dispatch(updateInput(id, '', name));
    }
    await dispatch({
      type: MULTIPLE_SEARCH_ADD_SELECTED_FILTERS,
      payload: {
        id,
        value,
        name,
        type
      }
    });
  };
}

export function deleteFilter(id: string, filter: any, index: number) {
  return {
    type: MULTIPLE_SEARCH_DELETE_FILTER,
    payload: {
      id,
      filter,
      index
    }
  };
}

export function clearAllFilters(id: string) {
  return {
    type: MULTIPLE_SEARCH_CLEAR_ALL_FILTERS,
    payload: {
      id
    }
  };
}

export function resetShowFilters(id: string) {
  return {
    type: MULTIPLE_SEARCH_RESET_SHOW_FILTERS,
    payload: {
      id
    }
  };
}

export function resetMultipleSearchState() {
  return {
    type: MULTIPLE_SEARCH_RESET_STATE
  };
}

export function setListValues(commandList: any) {
  return {
    type: MULTIPLE_SEARCH_SET_LIST_VALUES,
    payload: {
      commandList
    }
  };
}
