import { TFunction } from 'i18next';
import DateUtils from 'core/utils/date-utils';
import { ProvisionedDeviceItem } from './types';

export default (t: TFunction, data: ProvisionedDeviceItem): ProvisionedDeviceItem => {
  return {
    udid: data ? data.udid : '',
    createDate: data && data.createDate ? data.createDate : t('general.not_available'),
    deviceType: data ? data.deviceType : '',
    deviceFamily: data ? data.deviceFamily : '',
    lastTechnicalDataEventDate: data ? DateUtils.formatDate(data.lastTechnicalDataEventDate) : '',
    hasDeleteAction: data ? data.deviceType === 'Unmanaged' : false
  };
};

export const labelsProvDevices = (t: TFunction): ProvisionedDeviceItem => ({
  udid: t('account.provisioned_devices.column_device_id'),
  createDate: t('account.provisioned_devices.create_date.title'),
  deviceType: t('account.provisioned_devices.column_device_type'),
  deviceFamily: t('account.provisioned_devices.column_device_family'),
  lastTechnicalDataEventDate: t('account.provisioned_devices.column_last_managed')
});
