import mapper from './mapper';
import {
  NotificationsAction,
  NotificationsState,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_READ,
  NOTIFICATION_READ_ALL,
  NOTIFICATION_SET,
  NOTIFICATION_TOGGLE,
  VipocNotification
} from './types';

const initialState: NotificationsState = {
  isActive: false,
  isFetching: false,
  notifications: [],
  pagination: {
    index: "",
    totalCount: 0
  }
};

export default function(state = initialState, action: NotificationsAction) {
  switch (action.type) {
    case NOTIFICATION_TOGGLE: {
      return { ...state, isActive: action.payload.isActive };
    }

    case NOTIFICATION_SET: {
      const data = JSON.parse(action.payload.data);
      state.notifications.push(mapper(data, action.payload.t));
      
      return { ...state };
    }

    case NOTIFICATION_GET_SUCCESS: {
      const data = action.payload.data;
      const index = action.payload.index;
      const totalCount = action.payload.totalCount;
      return {
        ...state,
        pagination:{
          index: index,
          totalCount: totalCount
        },
        notifications: data.map((notification: VipocNotification) =>
          mapper(notification, action.payload.t)
        )
      };
    }

    case NOTIFICATION_READ: {
      const notification = state.notifications.find(
        (item: VipocNotification) => item.id === action.payload.id
      );

      if (notification) {
        notification.read = action.payload.read;
      }

      return {
        ...state
      };
    }

    case NOTIFICATION_READ_ALL: {
      return {
        ...state,
        notifications: state.notifications.map((item: VipocNotification) => {
          item.read = true;

          return item;
        })
      };
    }

    default:
      return { ...state };
  }
}
