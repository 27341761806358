import {
  DVBManagementNetworkAction,
  DVB_MANAGEMENT_NETWORK_SELECT,
  DVB_MANAGEMENT_NETWORKS_SET,
  DVBManagementNetwork,
  DVBNetwork
} from './types';

const initialState: DVBManagementNetwork = {
  items: [],
  itemsChanged: undefined,
  itemsSearch: undefined
};

export default function(state = initialState, action: DVBManagementNetworkAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_NETWORK_SELECT: {
      if (!action.payload) {
        state.selected = undefined;
      }

      if (state.itemsChanged) {
        state.itemsChanged.forEach((network: DVBNetwork) => {
          if (network.rootId === action.payload) {
            state.selected = network;
          }
        });
      }

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_NETWORKS_SET: {
      const networks: Array<DVBNetwork> = [];
      const isToClear = action.payload && action.payload.isToClear;
      const isAux = action.payload && action.payload.isAux;
      const networksPayload = action.payload && action.payload.item;

      if (networksPayload) {
        const networksList =
          networksPayload.result && networksPayload.result.length > 0 && networksPayload.result[0]
            ? networksPayload.result[0].networks
            : networksPayload;

        if (networksList && networksList.length > 0) {
          networksList.forEach((item: any) => {
            item.transportStreams &&
              item.transportStreams.forEach((stream: any) => {
                stream.id = stream.tsId;
              });

            networks.push({
              id: item.onId || item.id,
              rootId: item.rootId || item.onId || item.id,
              onId: item.onId || item.id,
              children: item.transportStreams || item.children,
              transportStreams: item.transportStreams || item.children,
              canEditChange: item.canEditChange === undefined ? true : item.canEditChange,
              changeLabel: item.changeLabel,
              changeId: item.changeId
            });
          });
        }
      }

      return {
        ...state,
        items:
          !isAux && (isToClear || (state.items && state.items.length === 0))
            ? networks
            : state.items,
        itemsSearch: action.payload.isSearch ? networks : undefined,
        itemsAux: isAux ? networks : [],
        itemsChanged: networksPayload ? state.itemsChanged : []
      };
    }

    default: {
      return state;
    }
  }
}
