import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api/client';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import {
  NotificationsAction,
  NOTIFICATION_GET,
  NOTIFICATION_GET_FAILURE,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_READ,
  NOTIFICATION_READ_ALL,
  NOTIFICATION_READ_FAILURE,
  VipocNotification
} from './types';
import { ApplicationState } from 'application/application-redux';
import { envs } from 'application/envHandler';

export function action(type: any, payload?: any): NotificationsAction {
  return {
    type,
    payload
  };
}

export function getNotifications(index?: string, prevNotifications?: Array<VipocNotification>) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    if (prevNotifications?.length == 0) {
      dispatch(add(NOTIFICATION_GET, {}));
    } 
    await AuthService.refreshToken();
    const indexNotifications = index || "";
    let arrayNotifications = prevNotifications || [];

    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/be/spectrum/notifications?pageSize=4&pageIndex=${indexNotifications}&user=${
          AuthService.getCurrentUser().username
        }`
      )
      .then(
        response => {
          if (arrayNotifications.length < response.data.totalCount ) {
            arrayNotifications.push(...response.data.results)
          }
          return dispatch(
            action(NOTIFICATION_GET_SUCCESS, { data: arrayNotifications, index: response.data.index, totalCount: response.data.totalCount, t: getState().i18n.t })
          );
        },
        rejection => {
          dispatch(
            addToaster({
              title: 'notifications.title',
              message: 'error_messages.VIPOC_ERROR_050',
              type: 'danger'
            })
          );
          return dispatch(action(NOTIFICATION_GET_FAILURE, rejection));
        }
      )
      .finally(() => {
        dispatch(remove(NOTIFICATION_GET));
      });
  };
}

export function readAllNotifications() {
  return async (dispatch: Function) => {
    await AuthService.refreshToken();

    apiClient
      .post(
        `${envs.REACT_APP_API_URL}/be/spectrum/notifications/mark-read?user=${
          AuthService.getCurrentUser().username
        }`
      )

      .then(
        () => {
          return dispatch(action(NOTIFICATION_READ_ALL));
        },
        rejection => {
          return dispatch(action(NOTIFICATION_READ_FAILURE, rejection));
        }
      );
  };
}

export function readNotification(id: string, read: boolean) {
  return async (dispatch: Function) => {
    await AuthService.refreshToken();

    apiClient
      .put(`${envs.REACT_APP_API_URL}/be/spectrum/notification/${id}`, {
        read
      })
      .then(
        response => {
          return dispatch(action(NOTIFICATION_READ, { id, read }));
        },
        rejection => {
          return dispatch(action(NOTIFICATION_READ_FAILURE, rejection));
        }
      );
  };
}
