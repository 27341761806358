import { connect } from 'react-redux';
import ToasterComponent from './toaster-component';
import { removeToaster, deactivateToaster, addTimeout } from './redux/actions';
import { ApplicationState } from 'application/application-redux';

const mapStateToProps = (state: ApplicationState) => ({
  toasters: state.toasters,
  t: state.i18n.t
});

export default connect(mapStateToProps, { removeToaster, deactivateToaster, addTimeout })(
  ToasterComponent
);
