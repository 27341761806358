import { IdMappingOption } from './reducer';

export const options: Array<IdMappingOption> = [
  {
    key: 'uuid',
    label: 'id_mapping.column_uuid',
    value: null,
    isTerminator: true,
    type: 'text',
    parent: 'things',
    index: 1
  },
  {
    key: 'username',
    label: 'id_mapping.column_operator',
    value: null,
    isTerminator: true,
    type: 'text',
    parent: 'things',
    index: 1
  },
  {
    key: 'behavior',
    label: 'id_mapping.column_behavior',
    isTerminator: false,
    type: 'select',
    parent: 'things',
    index: 1,
    value: [
      {
        key: 'DROP_FILE',
        label: 'DROP_FILE',
        isTerminator: true,
        value: 'DROP_FILE',
        type: 'select-value',
        parent: 'behavior',
        index: 2
      },
      {
        key: 'DROP_LINE',
        label: 'DROP_LINE',
        isTerminator: true,
        value: 'DROP_LINE',
        type: 'select-value',
        parent: 'behavior',
        index: 2
      },
      {
        key: 'REPLACE_LINE',
        label: 'REPLACE_LINE',
        isTerminator: true,
        value: 'REPLACE_LINE',
        type: 'select-value',
        parent: 'behavior',
        index: 2
      }
    ]
  },

  {
    key: 'status',
    label: 'id_mapping.column_status',
    isTerminator: false,
    type: 'select',
    parent: 'things',
    index: 1,
    value: [
      {
        key: 'BUSINESS_INVALID',
        label: 'Business Invalid',
        value: 'BUSINESS_INVALID',
        isTerminator: true,
        type: 'select-value',
        parent: 'status',
        index: 2
      },
      {
        key: 'FINISHED',
        label: 'id_mapping.value_finished',
        value: 'FINISHED',
        isTerminator: true,
        type: 'select-value',
        parent: 'status',
        index: 2
      },
      {
        key: 'STARTED',
        label: 'id_mapping.value_started',
        value: 'STARTED',
        isTerminator: true,
        type: 'select-value',
        parent: 'status',
        index: 2
      }
    ]
  }
];
