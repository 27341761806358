import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import AdvancedSearchHistoryComponent from './advanced-search-history-component';
import {
  changeTab,
  fetch,
  onChangeQueryValue,
  selectQueriesByHistory,
  update,
  editFavourite,
  onChangeFavouriteName,
} from './redux/action';

const mapStateToProps = (state: ApplicationState) => ({
  currentTab: state.devices.advancedSearch.history.currentTab,
  list: state.devices.advancedSearch.history.list,
  query: state.devices.advancedSearch.history.query,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  changeTab,
  fetch,
  onChangeQueryValue,
  selectQueriesByHistory,
  update,
  editFavourite,
  onChangeFavouriteName
})(AdvancedSearchHistoryComponent);
