export const CAROUSEL_SET_INDEX = 'CAROUSEL_SET_INDEX';

export interface CarouselAction {
  type: typeof CAROUSEL_SET_INDEX;
  payload: any;
}

export interface CarouselState {
  activeIndex: number;
}
