import React, { Component } from 'react';
import { ToasterObject, ToasterState } from './redux/types';
import './toaster-styles.scss';
import ToasterItemComponent from './toaster-item-component';
import { TFunction } from 'i18next';

interface ToasterProps {
  addTimeout: (toaster: ToasterObject) => void;
  deactivateToaster: (toaster: ToasterObject) => void;
  removeToaster: (toaster: ToasterObject) => void;
  t: TFunction;
  toasters: ToasterState;
}

export default class ToasterComponent extends Component<ToasterProps> {
  render() {
    const { deactivateToaster, removeToaster, toasters } = this.props;
    return (
      <div className="toaster-container">
        {toasters &&
          toasters.toasters &&
          toasters.toasters.map((item: ToasterObject, index: number) => {
            return (
              <ToasterItemComponent
                toaster={item}
                deactivateToaster={deactivateToaster}
                removeToaster={removeToaster}
                index={index}
                key={index}
              />
            );
          })}
      </div>
    );
  }
}
