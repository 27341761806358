import { NoRecords } from "assets/svg";

export default function ErrorPage() {
  return (
    <div
      className={'card flex-column is-vertical-center is-justified-center no-data has-text-centered has-padding-lg has-margin-top-sm'}
    >
      <NoRecords className="has-margin-bottom-lg" />
      <h5 className="text is-h5 is-bold has-margin-bottom-md">
        There was a problem initializing the application
      </h5>
      please, contact an administrator.
    </div>
  )
}
