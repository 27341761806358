import React, { Component } from 'react';
import TableComponent from '../table/table-container';
import PaginationComponent from '../pagination-optimized/pagination-container';
import { ChevronUp, Delete, NoRecords } from '../../assets/svg';
import './table-accordion-styles.scss';
import { TableAccordionState } from './redux/types';
import { TFunction } from 'i18next';

interface TableAccordionProps {
  tableAccordion: TableAccordionState;
  fetch: { params?: any; method: Function, allParams?: any };
  onChangeAccordionPage: (id: string) => void;
  toggleItem: (id: string, index: number, items: any, pagination: any) => void;
  deleteItem?: (item: any) => void;
  deleteTableItem?: { params?: any; method: Function };
  tableId: string;
  tableColumns: any;
  tablePagination: any;
  tableKeys: Array<string>;
  tableValues: String;
  accordionPagination: any;
  items: any;
  totalItems?: number;
  t: TFunction;
  goToFirstPage: (id: string) => void;
}

export default class TableAccordionComponent extends Component<TableAccordionProps> {
  getTableKey(item: any, k_index: number) {
    const { tableKeys, t, tableId } = this.props;

    let shown_names: any = [];
    for (let i = 0; i < tableKeys.length; i++) {
      const entry: any = item[tableKeys[i]];
      if (entry) {
        if (shown_names.length && i !== 0) {
          shown_names.push([null, ' | ']);
        }
        shown_names.push([tableKeys[i], entry]);
      }
    }

    return (
      <span key={k_index}>
        {shown_names.map((name: any, index: number) =>
          name[0] ? (
            <span className="white-space-pre" key={index}>
              <b>{t(`${tableId}.field_${name[0]}`)}:</b> {name[1]}
            </span>
          ) : (
            <span key={index} className="border-right" />
          )
        )}
      </span>
    );
  }

  getTableValues(item: any) {
    const { tableValues } = this.props;

    let shown_values: any = [];
    for (let i in Object.entries(item)) {
      if (tableValues === Object.entries(item)[i][0]) {
        shown_values = Object.entries(item)[i][1];
        break;
      }
    }
    return shown_values;
  }

  onChangePageAccordion(id: string) {
    const { onChangeAccordionPage, fetch } = this.props;
    fetch.method(fetch.allParams || fetch.params.id);
    onChangeAccordionPage(id);
  }

  render() {
    const {
      toggleItem,
      deleteItem,
      deleteTableItem,
      tableAccordion,
      tableId,
      tableColumns,
      tablePagination,
      items,
      totalItems,
      accordionPagination,
      goToFirstPage,
      t
    } = this.props;

    const currentId: string = `accordion.${tableId}`;
    const currentAccordion = tableAccordion.accordion.get(currentId);

    return (
      <div className="is-parent">
        {items && items.length > 0 ? (
          items.map((item: any, index: number) => (
            <div
              className={`box accordion-box has-background-white-ter
                ${currentAccordion && currentAccordion.shownIndex === index ? 'is-active' : ''}
              `}
              key={index}
            >
              <div className="columns is-vertical-center has-padding-md is-marginless">
                <div className="column is-marginless is-paddingless">
                  <div
                    className="is-clickable is-inline-block chevron-container accordion-chevron"
                    onClick={() =>
                      toggleItem(currentId, index, this.getTableValues(item), tablePagination)
                    }
                  >
                    <button className="button button-action" onClick={() => goToFirstPage(tableId)}>
                      <ChevronUp
                        className={`icon-svg icon-xxx-small has-margin-right-md icon-middle icon-red  ${
                          currentAccordion && currentAccordion.shownIndex === index ? '' : 'icon-90'
                        }`}
                      />
                    </button>
                  </div>
                  {this.getTableKey(item, index)}
                </div>
                <button
                  className="column is-clickable is-marginless has-text-right is-paddingless is-flex-grow-0 is-hidden"
                  onClick={() => deleteItem && deleteItem(item)}
                >
                  <Delete className="icon-svg icon-medium icon-button icon-middle" />
                </button>
              </div>

              <div
                className={`table-accordion ${
                  currentAccordion && currentAccordion.shownIndex === index
                    ? 'table-accordion-expanded has-padding-top-md border-top'
                    : 'table-accordion-collapsed'
                }`}
              >
                <div>
                  <TableComponent
                    id={tableId}
                    items={currentAccordion?.tableItemsToShow}
                    columns={tableColumns}
                    pagination={tablePagination}
                    onDeleteMessage={t('modal.content.delete.device')}
                    onDelete={(item: any) =>
                      deleteTableItem && deleteTableItem.method(item, deleteTableItem.params['id'])
                    }
                    sortableItems={currentAccordion && currentAccordion.tableItems}
                    hasAction
                  />

                  <div>
                    <PaginationComponent
                      id={tableId}
                      pageSize={tablePagination.pageSize ? tablePagination.pageSize : 10}
                      totalElements={this.getTableValues(item).length}
                      onChange={() => function() {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className={`flex-column is-vertical-center is-justified-center no-data has-text-centered has-padding-lg has-margin-top-sm
              has-border-top`}
          >
            <NoRecords className="has-margin-bottom-lg" />

            <h5 className="text is-h5 is-bold has-margin-bottom-md">
              {t('general.label_no_results')}
            </h5>
          </div>
        )}

        <div>
          <PaginationComponent
            id={currentId}
            pageSize={accordionPagination.pageSize ? accordionPagination.pageSize : 10}
            totalElements={totalItems}
            onChange={() => this.onChangePageAccordion(currentId)}
          />
        </div>
      </div>
    );
  }
}
