import { AxiosError, AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { remove } from '../spinner/redux/actions';
import { addToaster } from '../toaster/redux/actions';

export interface GenericError {
  message: string;
  code: number;
}

function handleAxiosErrorResponse(response: AxiosResponse<any>): GenericError {
  return {
    code: response.status,
    message: response.data.message ? response.data.message : JSON.stringify(response.data)
  };
}

export const toGenericError = (error: AxiosError): GenericError => {
  if (error.response) {
    return handleAxiosErrorResponse(error.response);
  }
  return {
    code: error.code ? parseInt(error.code, 10) : 500,
    message: 'An internal error ocurred. Contact the administrator'
  };
};
export const handleCommandError = (
  dispatch: Function,
  jobId: string,
  commandName: string,
  t: TFunction,
  commandType?: string,
  callback?: Function
): void => {
  if (commandType) {
    dispatch(remove(commandType));
  }
  if (callback) {
    dispatch(callback(false));
  }
  dispatch(
    addToaster({
      title: t(commandName),
      message: `${t('error_messages.VIPOC_ERROR_051')} ${jobId}`,
      type: 'danger'
    })
  );
};
