import React, { Component } from 'react';
import PaginationComponent from 'core/pagination-optimized/pagination-container';
import SearchComponent from 'core/search/search-container';
import ItemsPerPageComponent from 'core/items-per-page/items-per-page-container';
import NoRecordsComponent from 'core/no-records/no-records-container';
import { Delete, Sort, SortAsc, Edit, SortDesc } from 'assets/svg';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { SearchState } from 'core/search/redux/types';
import './table-styles.scss';
import { TFunction } from 'i18next';
import { SpinnerState } from 'core/spinner/redux/types';
import { Select, TableObject, TableState } from './redux/types';
import { Link } from 'react-router-dom';
import { filterMapper, translate } from 'core/table/types';
import MouseEventHandler from 'react-select';
import TooltipComponent from 'core/tooltip';

type DataItem = {
  name: string
  width?: string
  sort?: boolean
  field?: string
  info?: string
}

type ClickableRowType = {
  rowParams: any;
  method: Function;
  cursorDefault?: { param: string | ''; value: string | '' };
  cursorDefaultFunction?: (rowParams: any) => boolean
}
interface TableComponentProps {
  columns: any;
  hasAction?: boolean;
  hasSlider: boolean;
  id: string;
  items: any;
  title?: string;
  closeModal: () => void;
  onDelete: (row: any) => void;
  onDeleteMessage: string;
  search: SearchState;
  onSearch?: (filter: any, days?: number, comparator?: string) => void;
  openModal: (data: any) => void;
  selectData?: {
    field: string;
    select: Select;
    selectDisabled?: {
      all: boolean;
      single: boolean;
      singleSelect: { value: any; isActive: boolean };
    };
    index?: any;
  };
  pagination?: PaginationConfig;
  totalElements?: number;
  t: TFunction;
  buttonAction?: {
    buttonName: string;
    buttonClass?: string;
    method: Function;
    isToEnable?: boolean;
  };
  editButton: { buttonName?: any; buttonClass?: string; method: Function };
  redirectAction?: { name: string; method?: Function; parameter?: string; value?: any };
  selectedRowIndex: (id: string, field: string, index?: any) => void;
  table: TableState;
  noResultSubtitle?: string;
  export?: any;
  assignRows: (id: string, data: any) => void;
  selectOneRow: (id: string, index: any) => void;
  parentCallback?: any;
  changePageSize: (id: string, newSize: number) => void;
  onChangePageSize: any;
  inputText: { value?: string; valueFn?: Function; placeholder: string; method: Function };
  tableClasses: { value: string };
  sortAction?: { method: Function };
  changefieldOrder: (id: string, sortField: string, sortOrder: string) => void;
  clickableRow: ClickableRowType;
  tableOrderItems: (id: string, index: number) => void;
  orderItems: (id: string, field: string) => void;
  goToFirstPage: (id: string) => void;
  sortableItems: any;
  hiddenColumns: string;
  push: (uri: string) => void;
}

export default class TableComponent extends Component<TableComponentProps> {
  confirmAndRemove(item: any) {
    const { openModal, closeModal, onDelete, onDeleteMessage } = this.props;

    const command = () => {
      closeModal();
      onDelete(item);
    };

    openModal({
      title: 'modal.title.delete',
      content: onDeleteMessage,
      execute: command
    });
  }

  componentDidUpdate(prevProps: TableComponentProps) {
    if (
      this.props.selectData &&
      this.props.selectData?.selectDisabled?.all &&
      prevProps.selectData?.selectDisabled?.all !== this.props.selectData?.selectDisabled?.all
    ) {
      const tableItem = this.props.table.items.get(this.props.id);
      if (tableItem?.selectAll == true) {
        this.props.selectData && this.props.selectedRowIndex(this.props.id, 'selectAll');
      }
    }
  }

  tableObject = (): TableObject | undefined => {
    return this.props.table.items.get(this.props.id);
  };

  onSort = (column: DataItem): MouseEventHandler => {
    const { field } = column;
    const table = this.tableObject();
    const { column: currentColumn, order: currentOrder } = table?.sort || {};

    let newOrder = 'asc';
    let newColumn = field;

    if (currentColumn === field) {
      newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
    }
    this.props.changefieldOrder(this.props.id, newColumn || '', newOrder);
    return this.props.sortAction?.method({
      column: newColumn,
      order: newOrder,
      pageNumber: this.props.pagination?.pageNumber ? this.props.pagination.pageNumber + 1 : 1
    });
  };

  renderSortIcon = (column: DataItem) => {
    const table = this.tableObject();
    const { column: columnField, order } = table?.sort || {};

    let SortComponent = Sort;

    if (columnField === column.field) {
      if (order === 'asc') {
        SortComponent = SortAsc;
      }

      if (order === 'desc') {
        SortComponent = SortDesc;
      }
    }

    return (
      <span onClick={() => this.onSort(column)}>
        <SortComponent
          className={`${
            column.sort
              ? 'icon icon-svg icon-x-small icon-highlighted-red has-margin-right-md'
              : 'icon icon-svg icon-x-small icon-highlighted-red has-margin-right-md is-hidden'
          }`}
        />
      </span>
    );
  };

  tableHeader(columns: any, tableItem: any, t: TFunction) {
    const { orderItems, search, onSearch, pagination, sortableItems, id } = this.props;
    const _search = search.filters.get(id);
    const currentFilter =
      _search && _search.currentFilter && _search.currentFilter.value
        ? _search.currentFilter
        : null;
    const days = _search && _search.moreFilters && _search.moreFilters.days;
    const comparator = (_search && currentFilter && _search.currentComparator) || 'EQ';
    if (Array.isArray(columns)) {
      return columns.map((column: DataItem, index: number) => (
        <div
          className="flex-cell flex-none"
          role="columnheader"
          style={{ width: column?.width }}
          key={index}
        >
          {t(column.name)}
          {this.renderSortIcon(column)}
          {sortableItems && pagination && column.name ? (
            <span
              className="clickable-icon"
              onClick={() => {
                orderItems(id, column.field || column.name);
                onSearch && onSearch(filterMapper(currentFilter, pagination, id), days, comparator);
              }}
            >
              {pagination.sortField === column.name || pagination.sortField === column.field ? (
                pagination && pagination.sortOrder === 'ASC' ? (
                  <SortAsc className="icon icon-svg has-margin-left-sm" />
                ) : (
                  <SortDesc className="icon icon-svg has-margin-left-sm" />
                )
              ) : (
                <Sort className="icon icon-svg has-margin-left-sm" />
              )}
            </span>
          ) : (
            ''
          )}
          {column.info && column.name ? (
            <span className="tableHeader-tooltip">
              <span className="has-padding-top-xxs tableHeader-tooltip-wrap">
                <TooltipComponent data={column.info} t={t} />
              </span>
            </span>
          ) : (
            ''
          )}
        </div>
      ));
    }
    return Object.keys(columns).map((key: string, index: number) => (
      <div className="flex-cell" role="columnheader" key={index}>
        {columns[key]}
        {this.renderSortIcon(columns[key])}
        {sortableItems ? (
          <span
            className="clickable-icon"
            onClick={() => {
              orderItems(id, key);
              onSearch && onSearch(filterMapper(currentFilter, pagination, id), days, comparator);
            }}
          >
            {(pagination && pagination.sortField === key) ||
            (translate[id] && pagination && pagination.sortField === translate[id][key]) ? (
              pagination.sortOrder === 'ASC' ? (
                <SortAsc className="icon icon-svg has-margin-left-sm" />
              ) : (
                <SortDesc className="icon icon-svg has-margin-left-sm" />
              )
            ) : (
              <Sort className="icon icon-svg has-margin-left-sm" />
            )}
          </span>
        ) : (
          ''
        )}
      </div>
    ));
  }

  changeRecordsNumber(itemsNumber: number) {
    const { id, pagination, search, onChangePageSize, changePageSize } = this.props;

    if (pagination && itemsNumber !== pagination.pageSize) {
      changePageSize(id, itemsNumber);

      const _search = search.filters.get(id);
      const currentFilter =
        _search && _search.currentFilter && _search.currentFilter.value
          ? _search.currentFilter
          : null;
      const days = _search && _search.moreFilters && _search.moreFilters.days;
      const comparator = _search && currentFilter && _search.currentComparator;

      if (onChangePageSize) {
        onChangePageSize(filterMapper(currentFilter, pagination, id), days, comparator);
      }
    }
  }

  renderTableCell(value: string, row: any, key: number) {
    if (value === 'hasDeleteAction' && row[value]) {
      return (
        <button
          className="icon-button"
          key={key}
          onClick={() => {
            this.confirmAndRemove(row);
          }}
        >
          <Delete className="icon icon-svg" key={key} />
        </button>
      );
    } else {
      return row[value];
    }
  }

  isCheckboxChecked(selectedId: string, row: any) {
    const { selectData } = this.props;

    if (selectData) {
      const isChecked = row.find((item: any) => item.rowId === selectedId);
      return isChecked && isChecked.selected;
    }

    return false;
  }

  sendDataToParent(tableItem: TableObject | undefined) {
    const { parentCallback } = this.props;

    const numberOfSelected =
      tableItem && tableItem.selectedRows.filter((item: any) => item.selected);

    parentCallback(numberOfSelected && numberOfSelected.length);
  }

  visibleItem(item: any): any {
    const { hiddenColumns } = this.props;

    if (Array.isArray(this.props.columns)) {
      let newItem = { ...item };
      this.props.columns.forEach((c: any) => {
        if (c.hidden) {
          delete newItem[c.field];
        }
      });
      return newItem;
    }
    if (hiddenColumns) {
      delete item[hiddenColumns];
    }
    return item;
  }

  visibleColumns(): any[] {
    if (Array.isArray(this.props.columns)) {
      return this.props.columns.filter((c: any) => !c.hidden);
    }
    return this.props.columns;
  }

  displayRow(row: any, value: string) {
    const valueToReturn = value !== 'hasDeleteAction' && row[value] !== undefined && row[value] !== null && `${row[value]}`

    if (this.props.id === 'dvr' && value === 'status') {
      return this.props.t(`dvr.label_status_${valueToReturn}`)
    }

    return valueToReturn
  }

  renderRow(selectedId: any, tableItem: any, row: any, index: number) {
    const {
      id,
      t,
      items,
      hasAction,
      selectData,
      selectedRowIndex,
      redirectAction,
      inputText,
      editButton,
      parentCallback
    } = this.props;

    function isToShowAction(value: string, _hasAction?: boolean) {
      return _hasAction || (!_hasAction && value !== 'hasDeleteAction');
    }
    let columns = this.visibleColumns();
    return (
      <>
        {selectData &&
          (selectData.select === Select.MULTIPLE || selectData.select === Select.BOTH) && (
            <div className="is-align-center flex-cell flex-none">
              <div className="has-checkbox checkbox table-checkbox">
                <input
                  className="is-small"
                  type="checkbox"
                  onChange={() => [
                    selectedRowIndex(id, selectData.field, selectedId),
                    parentCallback && this.sendDataToParent(tableItem)
                  ]}
                  checked={
                    (tableItem && this.isCheckboxChecked(selectedId, tableItem.selectedRows)) ||
                    false
                  }
                />

                <label />
              </div>
            </div>
          )}

        {Object.keys(this.visibleItem(row)).map(
          (value, key) =>
            isToShowAction(value, hasAction) &&
            value !== 'rowId' && (
              <div
                className={`flex-cell white-space-pre
              ${value === 'hasDeleteAction' ? ' flex-cell-action' : ''}
              ${Array.isArray(columns) ? 'flex-none' : ''}
              
              `}
                role="cell"
                key={key}
                style={{
                  width: Array.isArray(columns) ? columns[key]?.width : 'auto'
                }}
                
              >
                <div className={`div-tooltip ${row[value] === 'N/A' && 'has-tooltip top-tooltip'}`} data-info={`${t('general.not_applicable')} `}>
                  {value === 'hasDeleteAction' && row[value] && (
                    <button
                      className="icon-button"
                      key={key}
                      onClick={() => {
                        this.confirmAndRemove(row);
                      }}
                    >
                      <Delete className="icon icon-svg" key={key} />
                    </button>
                  )}
                  {this.displayRow(row, value)}
                </div>
              </div>
            )
        )}

        {redirectAction && (
          <div className="flex-cell">
            <button
              className="button button-action"
              disabled={row.batchStatus === 'COMPLETED-NO-DATA'}
              onClick={() => {
                redirectAction.parameter
                  ? redirectAction.method && redirectAction.method(row[redirectAction.parameter])
                  : redirectAction.method && redirectAction.method();
              }}
            >
              {t(redirectAction.name)}
            </button>
          </div>
        )}

        {inputText && (
          <div className="flex-cell">
            <input
              type="text"
              className="input inputText"
              value={inputText.valueFn ? inputText.valueFn(row) : ''}
              placeholder={inputText.placeholder}
              onChange={(e) => inputText.method(e, row)}
            />
          </div>
        )}

        {editButton && (
          <div className="flex-cell">
            <div className="actions">
              <button
                className={`button-has-icon.svg button-action icon-x-small ${
                  editButton.buttonClass ? editButton.buttonClass : ''
                }`}
                onClick={() => editButton.method(row)}
                disabled={items.length === 0}
              >
                <Edit className="icon-svg icon-button icon-highlighted" />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  cursorCondition(row: any, clickableRow: ClickableRowType, rowParams?: any) {
    if (clickableRow.cursorDefault) {
      const {param, value} = clickableRow.cursorDefault
      return row[param] != value
    }

    if(clickableRow.cursorDefaultFunction) {
      const {cursorDefaultFunction, rowParams} = clickableRow
      return cursorDefaultFunction(rowParams.map((param: string) => row[param]))
    }
  }

  render() {
    const {
      hasAction,
      hasSlider,
      id,
      items,
      sortableItems,
      title,
      onSearch,
      search,
      onChangePageSize,
      pagination,
      totalElements,
      buttonAction,
      table,
      selectedRowIndex,
      selectData,
      noResultSubtitle,
      t,
      tableClasses,
      selectOneRow,
      parentCallback,
      clickableRow
    } = this.props;

    let columns = this.visibleColumns();

    function isToShowPagination(_pagination?: PaginationConfig, _totalElements?: number): boolean {
      if (_pagination && _totalElements && _totalElements > 0) {
        return true;
      }
      return false;
    }

    function listArray(value: any) {
      Object.keys(value).forEach((index) => {
        if (Array.isArray(value[index])) {
          value[index] = value[index].join(', ');
          return value[index];
        }
      });
    }

    const tableItem = table.items.get(id);
    let showItems: any = sortableItems && sortableItems.length ? [] : items;
    if (pagination && sortableItems && sortableItems.length) {
      const index = pagination.sortField || '';
      const direction = pagination.sortOrder || 'DESC';
      showItems = sortableItems
        .sort((a: any, b: any) => {
          return a[index] > b[index]
            ? direction === 'ASC'
              ? 1
              : -1
            : direction === 'ASC'
            ? -1
            : 1;
        })
        .slice(
          (pagination && pagination.pageSize * pagination.pageNumber) || 0,
          (pagination && pagination.pageSize * (pagination.pageNumber + 1)) || 10
        );
    }
    return (
      <div
        className={`${
          tableClasses
            ? `table-optimized ${tableClasses}`
            : 'table-optimized has-padding-top-md has-margin-bottom-none'
        }`}
        role="table"
      >
        <div className="columns">
          {title && (
            <div className="column has-padding-bottom-none has-padding-left-lg has-padding-top-md responsive-title">
              <h4 className="title is-size-4 has-padding-top-md has-padding-left-xs">{title}</h4>
            </div>
          )}

          {onChangePageSize && (
            <ItemsPerPageComponent id={id} search={search} onChangeAction={onChangePageSize} />
          )}
        </div>
        <div className="columns is-vertical-center is-justified-between has-margin-bottom-none has-margin-top-sm">
          <div className="column has-padding-top-none has-padding-bottom-none">
            {onSearch && (
              <SearchComponent
                hasSlider={hasSlider}
                id={id}
                items={items}
                onSearch={onSearch}
                pagination={pagination}
              />
            )}
          </div>
          {buttonAction && (
            <div className="column is-narrow mr-5">
              <button
                className={`button is-larger ${
                  buttonAction.buttonClass ? buttonAction.buttonClass : ''
                }`}
                onClick={() => buttonAction.method()}
                disabled={buttonAction.isToEnable ? !buttonAction.isToEnable : items.length === 0}
              >
                {t(buttonAction.buttonName)}
              </button>
            </div>
          )}
        </div>

        {items && items.length > 0 ? (
          <>
            <div className="table-optimized-horizontal-container-scroll">
              <div className="table-optimized-horizontal-scroll">
                <div
                  className={`flex-table header${hasAction && id !== 'dvr' ? ' has-action' : ''}`}
                  role="rowgroup"
                >
                  {selectData &&
                    (selectData.select === Select.MULTIPLE ||
                      selectData.select === Select.BOTH) && (
                      <div className="is-align-center flex-cell flex-none">
                        <div className="has-checkbox checkbox table-checkbox">
                          <input
                            className="is-small"
                            type="checkbox"
                            onChange={() => [
                              selectData && selectedRowIndex(id, 'selectAll'),
                              parentCallback && this.sendDataToParent(tableItem)
                            ]}
                            checked={tableItem && tableItem.selectAll}
                            disabled={selectData && selectData.selectDisabled?.all}
                          />

                          <label />
                        </div>
                      </div>
                    )}
                  {columns && this.tableHeader(columns, tableItem, t)}
                </div>

                {showItems.map((row: any, index: number) => {
                  listArray(row);
                  const selectedId = selectData && selectData.field && row[selectData.field];
                  return clickableRow ? (
                    <Link
                      to={{
                        pathname: clickableRow.method(
                          clickableRow.rowParams.map((param: string) => {
                            return row[param];
                          })
                        )
                      }}
                      className={`flex-table row${hasAction ? ' has-action' : ''}
                        ${clickableRow ? ' clickable' : ''}
                        ${
                          this.cursorCondition(row, clickableRow)
                          ? 'default'
                          : ''}
                        ${
                          selectData &&
                          (selectData.select === Select.BOTH ||
                            selectData.select === Select.SINGLE) &&
                          tableItem &&
                          selectedId === tableItem.selectedRow
                            ? 'is-active'
                            : ''
                        }
                        `}
                      role="rowgroup"
                      key={index}
                    >
                      {this.renderRow(selectedId, tableItem, row, index)}
                    </Link>
                  ) : (
                    <div
                      className={`flex-table row${hasAction ? ' has-action' : ''}
                          ${
                            selectData &&
                            (selectData.select === Select.BOTH ||
                              selectData.select === Select.SINGLE) &&
                            tableItem &&
                            selectedId === tableItem.selectedRow
                              ? 'is-active'
                              : ''
                          }`}
                      role="rowgroup"
                      key={index}
                      onClick={() =>
                        selectData &&
                        (selectData.select === Select.BOTH ||
                          selectData.select === Select.SINGLE) &&
                        selectOneRow(id, selectedId)
                      }
                    >
                      {this.renderRow(selectedId, tableItem, row, index)}
                    </div>
                  );
                })}
              </div>
            </div>

            {isToShowPagination(pagination, totalElements) && (
              <PaginationComponent
                id={id}
                pageSize={pagination?.pageSize ? pagination.pageSize : 5}
                totalElements={totalElements}
                onChange={(filter: any, days: number, comparator: string) =>
                  onSearch && onSearch(filter, days, comparator)
                }
              />
            )}
          </>
        ) : (
          <NoRecordsComponent borderTop={onSearch ? true : false} subtitle={noResultSubtitle} />
        )}
      </div>
    );
  }
}
