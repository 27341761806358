import Navbar from 'application/header/navbar';
import { NavbarState } from 'application/header/navbar/redux/types';
import React, { Component, Fragment } from 'react';
import DVBManagementComponent from './dvb-management/dvb-management-container';
import SpectrumHomepageComponent from './homepage/homepage-container';
import RequestsComponent from './requests';
import { getCurrentNavbarMenu } from 'application/header/navbar/redux/actions';
import SpectrumChannelsComponent from './logical-channels/channels';
import SpectrumRegionalizationComponent from './logical-channels/regionalization';
import SpectrumChannelsAssociationComponent from './logical-channels/channels/channels-associations/channels-associations-component';
interface SpectrumProps {
  navbar: NavbarState;
  currentPage: string;
}

export default class SpectrumComponent extends Component<SpectrumProps> {
  render() {
    const nav = this.props.navbar.navbar.get('spectrum');
    const { currentPage } = this.props;
    return (
      <Fragment>
        <Navbar currentPage={currentPage} />
        {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'homepage' && (
          <SpectrumHomepageComponent />
        )}
        {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'dvb_management' && (
          <DVBManagementComponent />
        )}
        {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'channels' && (
          <SpectrumChannelsComponent />
        )}
        {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'regionalization' && (
          <SpectrumRegionalizationComponent />
        )}
        {nav && getCurrentNavbarMenu(nav.current, currentPage) === 'requests' && (
          <RequestsComponent />
        )}
      </Fragment>
    );
  }
}
