import { TFunction } from 'i18next';
import {
  FILTERS_SLIDER_UPDATE_DAYS,
  FILTERS_TOGGLE,
  SET_FILTER_TYPE,
  SET_CURRENT_FILTER_VALUE,
  SET_CURRENT_COMPARATOR_VALUE,
  TRANSLATE_FILTER,
  CLEAR_ALL_FILTERS,
  ADD_FILTERS,
  SearchItem,  
  SET_TYPE_FILTER_VALUE,
  RESET_FILTER,
} from './types';

export function onChangeCurrentFilterValue(id: string, value: string | string[]) {
  return {
    type: SET_CURRENT_FILTER_VALUE,
    payload: {
      id,
      value
    }
  };
}

export function onChangeCurrentComparator(id: string, value: string) {
  return {
    type: SET_CURRENT_COMPARATOR_VALUE,
    payload: {
      id,
      value
    }
  };
}

export function onSelectFilter(id: string, value: string) {
  return {
    type: SET_FILTER_TYPE,
    payload: {
      id,
      value
    }
  };
}

export function toggleMoreFilters(id: string, filterState: boolean) {
  return {
    type: FILTERS_TOGGLE,
    payload: {
      id,
      filterState
    }
  };
}

export function updateDays(id: string, day: number) {
  return {
    type: FILTERS_SLIDER_UPDATE_DAYS,
    payload: {
      id,
      day
    }
  };
}

export function translateFilter(t: TFunction) {
  return {
    type: TRANSLATE_FILTER,
    payload: { t }
  };
}

export function clearAllFilters() {
  return {
    type: CLEAR_ALL_FILTERS
  };
}

export function addFilter(id: string, filter: SearchItem){
  return {
    type:ADD_FILTERS, 
    payload: { id, filter}
  }
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  }
}

export function onChangeTypeFilterValue(id: string, value: Array<SearchItem>) {
  return {
    type: SET_TYPE_FILTER_VALUE,
    payload: {
      id,
      value
    }
  };
}
