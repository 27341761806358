import {
  USER_SETTINGS_TOGGLE,
  USER_SETTINGS_ON_CHANGE_DROPDOWNS,
  USER_SETTINGS_FETCH,
  USER_SETTINGS_FAILURE,
  USER_SETTINGS_SUCCESS,
  NewSettings,
  USER_SETTINGS_UPDATE,
  USER_SETTINGS_UPDATE_SUCCESS,
  USER_SETTINGS_UPDATE_FAILURE,
  USER_SETTINGS_CREATE,
  USER_SETTINGS_CREATE_SUCCESS,
  USER_SETTINGS_CREATE_FAILURE
} from './types';
import AuthService from 'core/auth';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import { add, remove } from 'core/spinner/redux/actions';
import { ACCESS_TOKEN_KEY } from 'core/auth/constants';
import { ApplicationState } from 'application/application-redux';
import { push } from 'connected-react-router';
import { envs } from 'application/envHandler';

export function toggleUserSettings() {
  return {
    type: USER_SETTINGS_TOGGLE
  };
}

export function onChangeDropdowns(dropdownId: string, value: string) {
  return {
    type: USER_SETTINGS_ON_CHANGE_DROPDOWNS,
    payload: {
      dropdownId,
      value
    }
  };
}

function userSettingsFailure(payload: any) {
  return {
    type: USER_SETTINGS_FAILURE,
    payload
  };
}

function userSettingsSuccess(payload: any) {
  return {
    type: USER_SETTINGS_SUCCESS,
    payload
  };
}

function updateUserSettingsSuccess(payload: any) {
  return {
    type: USER_SETTINGS_UPDATE_SUCCESS,
    payload
  };
}

function updateUserSettingsFailure(payload: any) {
  return {
    type: USER_SETTINGS_UPDATE_FAILURE,
    payload
  };
}

function createUserSettingsSuccess(payload: any) {
  return {
    type: USER_SETTINGS_CREATE_SUCCESS,
    payload
  };
}

function createUserSettingsFailure(payload: any) {
  return {
    type: USER_SETTINGS_CREATE_FAILURE,
    payload
  };
}

export function fetchUserSettings() {
  return async (dispatch: Function) => {
    dispatch(add(USER_SETTINGS_FETCH, {}));
    await AuthService.refreshToken();
    const currentOpco = AuthService.getCurrentOpco();
    const currentLanguage = AuthService.getCurrentLanguage();
    const user = AuthService.getCurrentUser();

    apiClient
      .get(`${envs.REACT_APP_API_URL}/user-settings`, {
        headers: {
          ignoreCommonHeaders: true,
          opco: user.opcos[0],
          token: localStorage.getItem(ACCESS_TOKEN_KEY)
        }
      })
      .then(
        response => {
          dispatch(remove(USER_SETTINGS_FETCH));

          if (!response.data.opco || !response.data.language) {
            return dispatch(
              createUserSettings({ opco: user.opcos[0].substr(-2), language: user.langs[0] })
            );
          }

          if (!currentLanguage || !currentOpco) {
            dispatch(
              AuthService.switchLanguage(response.data.language),
              AuthService.switchOpco(`vf${response.data.opco}`)
            );
          }

          return dispatch(userSettingsSuccess(response.data), push('/'));
        },
        (rejection) => {
          dispatch(
            addToaster({
              title: 'user_settings.label_user_settings',
              message: 'error_messages.VIPOC_ERROR_052',
              type: 'danger'
            })
          );
          dispatch(remove(USER_SETTINGS_FETCH));
          return dispatch(userSettingsFailure(rejection));
        }
      );
  };
}

export function createUserSettings(newSettings: NewSettings) {
  return async (dispatch: any) => {
    dispatch(add(USER_SETTINGS_CREATE, {}));
    await AuthService.refreshToken();
    const user = AuthService.getCurrentUser();

    apiClient
      .post(`${envs.REACT_APP_API_URL}/user-settings`, newSettings, {
        headers: {
          ignoreCommonHeaders: true,
          opco: user.opcos[0],
          token: localStorage.getItem(ACCESS_TOKEN_KEY)
        }
      })
      .then(
        response => {
          dispatch(remove(USER_SETTINGS_CREATE));
          dispatch(fetchUserSettings());
          return dispatch(createUserSettingsSuccess({ ...response.data }));
        },
        rejection => {
          dispatch(
            addToaster({
              title: 'user_settings.label_user_settings',
              message: 'error_messages.VIPOC_ERROR_053',
              type: 'danger'
            })
          );
          dispatch(remove(USER_SETTINGS_CREATE));
          return dispatch(createUserSettingsFailure(rejection));
        }
      );
  };
}

export function updateUserSettings(newSettings: NewSettings) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(USER_SETTINGS_UPDATE, {}));
    await AuthService.refreshToken();
    const user = AuthService.getCurrentUser();

    apiClient
      .put(`${envs.REACT_APP_API_URL}/user-settings`, newSettings, {
        headers: {
          ignoreCommonHeaders: true,
          opco: user.opcos[0],
          token: localStorage.getItem(ACCESS_TOKEN_KEY)
        }
      })
      .then(
        response => {
          dispatch(remove(USER_SETTINGS_UPDATE));
          const state = getState();
          if (
            newSettings.language !== state.navbar.userSettings.actualSettings?.language &&
            newSettings.opco !== state.navbar.userSettings.actualSettings?.opco
          ) {
            dispatch(
              AuthService.switchLanguage(newSettings.language),
              AuthService.switchOpco(`vf${newSettings.opco}`)
            );
          }

          if (newSettings.opco !== state.navbar.userSettings.actualSettings?.opco) {
            dispatch(AuthService.switchOpco(`vf${newSettings.opco}`));
          }

          if (newSettings.language !== state.navbar.userSettings.actualSettings?.language) {
            dispatch(AuthService.switchLanguage(newSettings.language));
          }

          return dispatch(fetchUserSettings(), updateUserSettingsSuccess({ ...response.data }));
        },
        rejection => {
          dispatch(
            addToaster({
              title: 'user_settings.label_user_settings',
              message: 'error_messages.VIPOC_ERROR_054',
              type: 'danger'
            })
          );
          dispatch(remove(USER_SETTINGS_UPDATE));
          return dispatch(updateUserSettingsFailure(rejection));
        }
      );
  };
}
