import {
  FileUploadAction,
  FileUploadState,
  FILE_UPLOAD_CLOSE,
  FILE_UPLOAD_CLEAN_CACHE,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_FAILURE,
  LAST_SELECTED_FILE
} from './types';

const initialState: FileUploadState = {
  isActive: false,
  selectFile: new FormData(),
  disabledUploadButton: true,
  selectedFileErrorMsg: '',
  selectedFileSuccessMsg: '',
  fileContent: []
};

export default function (state = initialState, action: FileUploadAction) {
  switch (action.type) {
    case FILE_UPLOAD_CLEAN_CACHE: {
      state.selectFile = new FormData();
      state.selectedFileErrorMsg = '';
      state.disabledUploadButton = true;
      state.fileContent = [];
      return { ...state };
    }

    case SELECTED_FILE_SUCCESS: {
      state.selectFile = action.payload.file;
      state.disabledUploadButton = false;
      state.selectedFileErrorMsg = '';
      state.fileContent = action.payload.fileContent;

      return { ...state };
    }

    case SELECTED_FILE_FAILURE: {
      const file = action.payload.file;
      const type = action.payload.type;
      state.selectFile = file;
      state.disabledUploadButton = true;
      state.selectedFileErrorMsg = action.payload.errorMsg;

      return { ...state };
    }

    case LAST_SELECTED_FILE: {
      return { ...state };
    }

    case FILE_UPLOAD_CLOSE: {
      state.selectFile = '';
      state.selectedFileErrorMsg = '';
      state.disabledUploadButton = true;
      state.fileContent = [];
      return { ...state };
    }

    default:
      return { ...state };
  }
}
