export const SPEED_TEST_HISTORY_FAILURE = 'SPEED_TEST_HISTORY_FAILURE';
export const SPEED_TEST_HISTORY_SUCCESS = 'SPEED_TEST_HISTORY_SUCCESS';
export const SPEED_TEST_HISTORY_INFO = 'SPEED_TEST_HISTORY_INFO';
export const SPEED_TEST_INFO = 'SPEED_TEST_INFO';
export const SPEED_TEST_REQUEST = 'SPEED_TEST_REQUEST';
export const SPEED_TEST_STATUS = 'SPEED_TEST_STATUS';
export const SPEED_TEST_SUCCESS = 'SPEED_TEST_SUCCESS';
export const SPEED_TEST_FAILURE = 'SPEED_TEST_FAILURE';
export const SPEED_TEST_CLEAR_CACHE = 'SPEED_TEST_CLEAR_CACHE';
export const SPEED_TEST_WAITING_COMMAND = 'SPEED_TEST_WAITING_COMMAND';

export type SPEED_TEST_HISTORY_FAILURE = typeof SPEED_TEST_HISTORY_FAILURE;
export type SPEED_TEST_HISTORY_SUCCESS = typeof SPEED_TEST_HISTORY_SUCCESS;
export type SPEED_TEST_HISTORY_INFO = typeof SPEED_TEST_HISTORY_INFO;

export type SPEED_TEST_INFO = typeof SPEED_TEST_INFO;
export type SPEED_TEST_REQUEST = typeof SPEED_TEST_REQUEST;
export type SPEED_TEST_STATUS = typeof SPEED_TEST_STATUS;
export type SPEED_TEST_SUCCESS = typeof SPEED_TEST_SUCCESS;
export type SPEED_TEST_FAILURE = typeof SPEED_TEST_FAILURE;
export type SPEED_TEST_CLEAR_CACHE = typeof SPEED_TEST_CLEAR_CACHE;
export type SPEED_TEST_WAITING_COMMAND = typeof SPEED_TEST_WAITING_COMMAND;

export interface SpeedTestAction {
  type:
    | SPEED_TEST_HISTORY_FAILURE
    | SPEED_TEST_HISTORY_SUCCESS
    | SPEED_TEST_HISTORY_INFO
    | SPEED_TEST_INFO
    | SPEED_TEST_REQUEST
    | SPEED_TEST_STATUS
    | SPEED_TEST_SUCCESS
    | SPEED_TEST_FAILURE
    | SPEED_TEST_CLEAR_CACHE
    | SPEED_TEST_WAITING_COMMAND;
  payload?: any;
}

export interface SpeedTestItem {
  pingTimeMS: string;
  downloadBitrateAVG: string;
  finishedAt: string;
  totalElements?: number;
}

export interface SpeedTestState {
  results: Array<SpeedTestItem>;
  download: string;
  latency: string;
  waitingCommandExecution: boolean;
  activityLog: any;
}
