import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import AuthService from 'core/auth/auth-service';
import { ApplicationState } from 'application/application-redux';
import { fetchAdvancedSearch } from '../../redux/action';
import { clearAllFilters } from 'core/multiple-search/redux/action';
import {
  ADVANCED_SEARCH_HISTORY,
  ADVANCED_SEARCH_HISTORY_FAILURE,
  ADVANCED_SEARCH_HISTORY_SUCCESS,
  ADVANCED_SEARCH_HISTORY_UPDATE,
  ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE,
  ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS,
  ADVANCED_SEARCH_HISTORY_CHANGE_TAB,
  SET_CURRENT_HISTORY_FILTER_VALUE,
  ADVANCED_SEARCH_EDIT_FAVOURITE,
  EDIT_CURRENT_FAVOURITE_VALUE,
  AdvancedSearchHistory
} from './types';
import { envs } from 'application/envHandler';

function success(payload: any) {
  return {
    type: ADVANCED_SEARCH_HISTORY_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: ADVANCED_SEARCH_HISTORY_FAILURE,
    payload
  };
}

function successUpdate(payload: any) {
  return {
    type: ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS,
    payload
  };
}

function failureUpdate(payload: any) {
  return {
    type: ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE,
    payload
  };
}

export function fetch(query?: string) {
   return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(ADVANCED_SEARCH_HISTORY, {}));
    await AuthService.refreshToken();

    const current = getState().devices.advancedSearch.history.current;
    let endpoint: string = `${envs.REACT_APP_API_URL}/device/history?user=${
      AuthService.getCurrentUser().username
    }`;

    if (query) {
      endpoint += `&searchName=${query}&identifier=${query}`;
    }

    return apiClient.get(endpoint, {}).then(
      response => {
        dispatch(remove(ADVANCED_SEARCH_HISTORY));
        return dispatch(success({
          content: response.data.map((item: any) => {
            item.similarSearch = item.query === current;
            return item;
          })
        }));
      },
      rejection => {
        dispatch(remove(ADVANCED_SEARCH_HISTORY));
        return dispatch(failure(rejection));
      }
    );
  };
}

export function editFavourite(item: AdvancedSearchHistory) {
  return {
    type: ADVANCED_SEARCH_EDIT_FAVOURITE,
    payload: item
  };
}

export function onChangeFavouriteName(item: AdvancedSearchHistory, value: string) {
  return {
    type: EDIT_CURRENT_FAVOURITE_VALUE,
    payload: {
      item,
      value
    },
  };
}

export function update(item: AdvancedSearchHistory) {
  return async (dispatch: any) => {
    dispatch(add(ADVANCED_SEARCH_HISTORY_UPDATE, {}));
    await AuthService.refreshToken();

    return apiClient.put(`${envs.REACT_APP_API_URL}/device/history`, item).then(
      response => {
        dispatch(remove(ADVANCED_SEARCH_HISTORY_UPDATE));
        dispatch(fetch());
        return dispatch(successUpdate({ ...response.data }));
      },
      rejection => {
        dispatch(remove(ADVANCED_SEARCH_HISTORY_UPDATE));
        return dispatch(failureUpdate(rejection));
      }
    );
  };
}

export function changeTab(tab: string) {
  return {
    type: ADVANCED_SEARCH_HISTORY_CHANGE_TAB,
    payload: tab
  };
}

export function onChangeQueryValue(value: string) {
  return {
    type: SET_CURRENT_HISTORY_FILTER_VALUE,
    payload: value
  };
}

export function selectQueriesByHistory(historyItem: AdvancedSearchHistory) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const item = { ...historyItem };
    if (item.isEditing) { return false }

    const queries: any = item.query.split('AND').map((q: string) => q.trim());
    const mappedQueries: any = queries.map((query: any) => query.split(':'));
    let search = getState().multipleSearch.multipleFilters.get('advanced.search');
    dispatch(clearAllFilters('advanced.search'));
    if (search) {
      const newFilters = mappedQueries.map((query: any) => {
        return {'name': query[0], 'value': query[1]}
      });
      search.selectedFilters = newFilters;
      dispatch(fetchAdvancedSearch(item.query, false, item.id.toString()))
    }
  };
}
