export const hardDiskFields = [
  {
    label: 'hard_disk.label_error_rate',
    field: 'errorRate',
  },
  {
    label: 'hard_disk.label_reallocated',
    field: 'reallocatedSector',
  },
  {
    label: 'hard_disk.label_seek_error_rate',
    field: 'seekErrorRate',
  },
  {
    label: 'hard_disk.label_offline_uncorrectable',
    field: 'offlineUncorrectable',
  },
  {
    label: 'hard_disk.label_udma_crc_error',
    field: 'udmaCrcError',
  },
  {
    label: 'hard_disk.label_multizone_error_rate',
    field: 'multizoneErrorRate',
  },
  {
    label: 'hard_disk.label_spin_up_time',
    field: 'spinUpTime',
  },
  {
    label: 'hard_disk.label_start_stop_count',
    field: 'startStopCount',
  },
  {
    label: 'hard_disk.label_power_on_hours',
    field: 'powerOnHours',
  },
  {
    label: 'hard_disk.label_spin_retry_count',
    field: 'spinRetryCount',
  },
  {
    label: 'hard_disk.label_calibration_retry_count',
    field: 'calibrationRetryCount',
  },
  {
    label: 'hard_disk.label_power_cycle_count',
    field: 'powerCycleCount',
  },
  {
    label: 'hard_disk.label_power_off_retract_count',
    field: 'powerOffRetractCount',
  },
  {
    label: 'hard_disk.label_load_cycle_count',
    field: 'loadCycleCount',
  },
  {
    label: 'hard_disk.label_reallocated_event_count',
    field: 'reallocatedEventCount',
  },
  {
    label: 'hard_disk.label_current_pending_sector',
    field: 'currentPendingSector',
  },
]

export const defaultColor = '#4A4A4A'

export const successColor = '#019900'

export const dangerColor = '#E60100'

export const iconSize = .67
