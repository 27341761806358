import React, { Component, Fragment } from 'react';
import EditComponent from 'core/edit/edit-component';
import { TFunction } from 'i18next';
import './channels-associations-styles.scss';
import TableComponent from 'core/table/table-container';
import {
  regionsColumns,
  nodesColumns,
  disassociatedRegionsColumns,
  disassociatedNodesColumns
} from './redux/config';
import {
  paginationChannelsAssociationsRegions,
  paginationChannelsAssociationsNodes
} from 'core/pagination-optimized/redux/config';
import NavbarComponent from 'application/header/navbar';
import { Select, Sort, TableState } from 'core/table/redux/types';
import { ChannelsAssociationsRegionsState, ChannelsRegions } from './redux/redux-regions/type';
import {
  ChannelsAssociationsNodes,
  ChannelsAssociationsNodesState
} from './redux/redux-nodes/type';
import {
  SpectrumChannelsAssociationParam,
  SprectrumChannelsPutRegionalLCN
} from '../redux/redux-channels/action';
import { ChannelsState } from '../redux/redux-channels/type';
import { DVBRegion } from 'modules/management/spectrum/dvb-management/redux/redux-regions/types';
import { getSelected } from 'core/table/redux/reducer';
import { DVBNode } from 'modules/management/spectrum/dvb-management/redux/redux-nodes/types';
import FileUploadContainer from 'core/file-upload/file-upload-container';

interface SpectrumChannelsAssociationProps {
  t: TFunction;
  currentPage: any;
  getChannel: (id: number) => void;
  getChannelNodes: (spectrumNodesParam: SpectrumChannelsAssociationParam) => void;
  getChannelRegions: (spectrumRegionsParam: SpectrumChannelsAssociationParam) => void;
  disassociate: (channelId: number, value?: any, type?: string) => void;
  associate: (channelId: number, value?: any, type?: string) => void;
  getDownloadChannels: () => void;
  regionsAssociation: ChannelsAssociationsRegionsState;
  nodesAssociation: ChannelsAssociationsNodesState;
  regions: ChannelsRegions;
  table: TableState;
  putRegionalLCN: (PutRegionalLCNParams: SprectrumChannelsPutRegionalLCN) => void;
  editField: (table?: any, data?: any, id?: number) => void;
  channelsState: ChannelsState;
  match: any;
}

export default class SpectrumChannelsAssociationComponent extends Component<
  SpectrumChannelsAssociationProps,
  {
    isActive: boolean;
    onCheck: boolean;
    showCurrentAssociations: boolean;
    onEdit: boolean;
    itemEdit: { id: number; parentId: number; label: string; regionalLCN: number; type: string };
    valueNode: { id: any; LCN: any }[];
  }
> {
  constructor(props: SpectrumChannelsAssociationProps) {
    super(props);
    this.state = {
      isActive: false,
      onCheck: false,
      showCurrentAssociations: true,
      onEdit: false,
      itemEdit: { id: NaN, parentId: NaN, label: '', regionalLCN: NaN, type: '' },
      valueNode: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  toggle = () => {
    this.setState({
      showCurrentAssociations: !this.state.showCurrentAssociations
    });
  };
  componentDidMount() {
    const { mediaId } = this.props.match.params;
    this.props.getChannel(mediaId);
  }

  componentDidUpdate(prevProps: SpectrumChannelsAssociationProps) {
    const { channelActive } = this.props.channelsState;

    if (prevProps.channelsState.channelActive !== channelActive && channelActive) {
      this.props.getChannelNodes({ channelId: channelActive.channelId });
      this.props.getChannelRegions({ channelId: channelActive.channelId });
    }
  }

  getSelected(state: TableState, id: string) {
    return state.items.get(id);
  }

  disassociateRegionsAction = () => {
    const table = this.getSelected(this.props.table, 'spectrum.regions');
    if (this.props.channelsState.channelActive?.channelId) {
      this.props.disassociate(this.props.channelsState.channelActive?.channelId, table, 'regions');
    }
  };

  disassociateNodesAction = () => {
    const table = this.getSelected(this.props.table, 'spectrum.nodes');
    if (this.props.channelsState.channelActive?.channelId) {
      this.props.disassociate(this.props.channelsState.channelActive?.channelId, table, 'nodes');
    }
  };

  associateRegionsAction = () => {
    const table = this.getSelected(this.props.table, 'spectrum.regions');
    if (this.props.channelsState.channelActive?.channelId) {
      this.props.associate(this.props.channelsState.channelActive?.channelId, table, 'regions');
      this.setState({itemEdit: { id: NaN, parentId: NaN, label: '', regionalLCN: NaN, type: '' }});
    }

  };

  associateNodesAction = () => {
    const table = this.getSelected(this.props.table, 'spectrum.nodes');
    if (this.props.channelsState.channelActive?.channelId) {
      this.props.associate(this.props.channelsState.channelActive?.channelId, table, 'nodes');
    }
  };

  changeChannelAction() {
    window.location.href = '/management/spectrum/channels';
  }

  redirectAction() {
    window.location.href = '/management/spectrum/channels/channels-associations';
  }

  onModalClose() {
    this.setState({ isActive: false });
  }

  onEditClose() {
    this.setState({ onEdit: false });
  }

  onSelectCheck() {
    this.setState({ onCheck: !this.state.onCheck });
  }

  selectCurrentAssociations = () => {
    this.setState({ showCurrentAssociations: true });
    if (this.props.channelsState.channelActive) {
      this.props.getChannelNodes({
        clearCache: true,
        channelId: this.props.channelsState.channelActive?.channelId
      });
      this.props.getChannelRegions({
        clearCache: true,
        channelId: this.props.channelsState.channelActive?.channelId
      });
    }
  };

  selectNewAssociations = () => {
    this.setState({ showCurrentAssociations: false });
    if (this.props.channelsState.channelActive) {
      this.props.getChannelRegions({
        eligible: true,
        clearCache: true,
        channelId: this.props.channelsState.channelActive?.channelId
      });
      this.props.getChannelNodes({
        eligible: true,
        clearCache: true,
        channelId: this.props.channelsState.channelActive?.channelId
      });
    }
  };

  editAction(item: any) {
    this.setState({
      onEdit: !this.state.onEdit,
      itemEdit: {
        id: item.regionId || item.id || item.nodeId,
        parentId: item.parentRegionId || item.childRegionId,
        label: item.regionLabel || item.nodeLabel,
        regionalLCN: item.putRegionalLCN,
        type: item.regionLabel ? 'region' : 'node'
      }
    });
  }

  orderTable = () => {};

  handleChangeLCN = (event: any, row: any) => {
    const table = row.hasOwnProperty('nodeLabel') ? 'spectrum.nodes' : 'spectrum.regions';
    const value = event.target.value;
    this.props.editField(table, value, row.regionId || row.id);
  };

  handleChange = (event: any) => {
    const value = event.target.value;
    this.setState({ itemEdit: { ...this.state.itemEdit, regionalLCN: value } });
  };

  editSave = () => {
    if (this.props.channelsState.channelActive?.channelId) {
      this.props.putRegionalLCN({
        value: this.state.itemEdit.regionalLCN,
        id: this.state.itemEdit.id,
        type: this.state.itemEdit.type,
        channelId: this.props.channelsState.channelActive?.channelId
      });
    }
    this.onEditClose();
  };

  render() {
    const { t, currentPage, regionsAssociation, nodesAssociation } = this.props;
    return (
      <Fragment>
        <FileUploadContainer
          isActive={this.state.isActive}
          title={'Upload file'}
          clue={'Please select a file to upload'}
          confirmButton={'Confirm Upload'}
          close={this.onModalClose.bind(this)}
          formatFile={'XLSX'}
          maxMBsize={10}
          pageType={'regionalization'}
        ></FileUploadContainer>

        <NavbarComponent currentPage={currentPage} />

        <EditComponent
          t={t}
          isActiveEdit={this.state.onEdit}
          onClose={this.onEditClose.bind(this)}
          method={this.editSave.bind(this)}
        >
          <div className="text">
            <h6 className="text is-h6 is-bold">{t('spectrum.regions.column_regional_lcn')}</h6>
            <input
              type="text"
              className="input  my-3"
              value={this.state.itemEdit.regionalLCN || ''}
              onChange={this.handleChange}
            />
            <p className="mb-2">
              <span className="text is-bold">{t('spectrum.channel')}: </span>
              {this.props.channelsState.channelActive?.name}
            </p>
            <p className="mb-4">
              <span className="text is-bold">{t('spectrum.regions.column_region_label')}: </span>
              {this.state.itemEdit.label}
            </p>
          </div>
        </EditComponent>

        <div className="m-5  mt-6 is-flex is-justified-between">
          <div className="info">
            <p className="text is-size-sm has-margin-y-sm">
              <span className="text is-highlighted is-bold">{t('spectrum.service.media_id')}:</span>
              <span className="has-margin-right-sm">
                {' '}
                {this.props.channelsState.channelActive?.mediaId}{' '}
              </span>
              <span className="text is-highlighted is-bold has-margin-left-sm">
                {' '}
                {t('spectrum.channel')}:
              </span>
              <span className="has-margin-right-sm">
                {' '}
                {this.props.channelsState.channelActive?.name}
              </span>
            </p>
            <button
              className="button button-action is-highlighted"
              onClick={() => this.changeChannelAction()}
            >
              {t('spectrum.button.change')}
            </button>
          </div>

          <div>
            <button
              className="button is-rounded"
              onClick={() => {
                this.props.getDownloadChannels();
              }}
            >
              {t('spectrum.button.download_csv').replace('CSV', 'Excel')}
            </button>
            <button
              className="button is-rounded"
              onClick={() => {
                this.setState({ isActive: true });
              }}
            >
              {t('spectrum.button.upload_csv').replace('CSV', 'Excel')}
            </button>
          </div>
        </div>
        <div className="m-5">
          <div className="tabs is-medium">
            <ul className="subtabs">
              <li className={this.state.showCurrentAssociations ? 'is-active' : ''}>
                <button className="button-link" onClick={this.selectCurrentAssociations}>
                  {t('spectrum.subtab_current_associations')}
                </button>
              </li>
              <li className={this.state.showCurrentAssociations ? '' : 'is-active'}>
                <button className="button-link" onClick={this.selectNewAssociations}>
                  {t('spectrum.subtab_new_associations')}
                </button>
              </li>
            </ul>
          </div>
        </div>

        {this.state.showCurrentAssociations ? (
          <section className="m-5">
            <TableComponent
              id="spectrum.regions"
              tableClasses="mb-5 pb-1 pt-5"
              title={t('spectrum.regions')}
              onSearch={(filter: any) => {
                if (this.props.channelsState.channelActive) {
                  this.props.getChannelRegions({
                    pageNumber: ++filter.pageNumber,
                    sort: {
                      order: filter.sortOrder,
                      column: filter.sortField
                    },
                    filter: filter,
                    channelId: this.props.channelsState.channelActive?.channelId
                  });
                }
              }}
              selectData={{ field: 'regionId', select: Select.MULTIPLE, selectOne: false }}
              buttonAction={{
                buttonName: 'spectrum.button_disassociate',
                buttonClass: 'is-primary',
                method: this.disassociateRegionsAction.bind(this)
              }}
              columns={regionsColumns}
              items={regionsAssociation.regions || {}}
              editButton={{
                method: this.editAction.bind(this)
              }}
              sortAction={{
                method: (sort: Sort) => {
                  if (this.props.channelsState.channelActive) {
                    this.props.getChannelRegions({
                      sort,
                      channelId: this.props.channelsState.channelActive.channelId,
                      pageNumber: sort.pageNumber
                    });
                  }
                }
              }}
              totalElements={regionsAssociation.filteredCount}
              pagination={paginationChannelsAssociationsRegions}
            />

            <TableComponent
              id="spectrum.nodes"
              tableClasses="mb-5 pb-1 pt-5"
              title={t('spectrum.nodes')}
              onSearch={(filter: any) => {
                if (this.props.channelsState.channelActive) {
                  this.props.getChannelNodes({
                    pageNumber: ++filter.pageNumber,
                    sort: {
                      order: filter.sortOrder,
                      column: filter.sortField
                    },
                    filter: filter,
                    channelId: this.props.channelsState.channelActive?.channelId
                  });
                }
              }}
              selectData={{ field: 'id', select: Select.MULTIPLE, selectOne: false }}
              buttonAction={{
                buttonName: 'spectrum.button_disassociate',
                buttonClass: 'is-primary',
                method: this.disassociateNodesAction.bind(this)
              }}
              columns={nodesColumns}
              items={nodesAssociation.nodes}
              editButton={{
                method: this.editAction.bind(this)
              }}
              sortAction={{
                method: (sort: Sort) => {
                  if (this.props.channelsState.channelActive) {
                    this.props.getChannelNodes({
                      sort,
                      channelId: this.props.channelsState.channelActive.channelId,
                      pageNumber: sort.pageNumber
                    });
                  }
                }
              }}
              totalElements={nodesAssociation.filteredCount}
              pagination={paginationChannelsAssociationsNodes}
            />
          </section>
        ) : (
          <section className="m-5">
            <TableComponent
              id="spectrum.regions"
              tableClasses="mb-5 pb-1 pt-5"
              title={t('spectrum.regions')}
              onSearch={(filter: any) => {
                if (this.props.channelsState.channelActive) {
                  this.props.getChannelRegions({
                    eligible: true,
                    pageNumber: ++filter.pageNumber,
                    sort: {
                      order: filter.sortOrder,
                      column: filter.sortField
                    },
                    filter: filter,
                    channelId: this.props.channelsState.channelActive?.channelId
                  });
                }
              }}
              selectData={{ field: 'regionId', select: Select.MULTIPLE, selectOne: false }}
              buttonAction={{
                buttonName: 'spectrum.button_associate',
                buttonClass: 'is-primary-confirm',
                method: this.associateRegionsAction.bind(this)
              }}
              columns={disassociatedRegionsColumns}
              items={regionsAssociation.regions || {}}
              inputText={{
                placeholder: '',
                valueFn: (row: ChannelsRegions) => {
                  const selectedRows =
                    getSelected(this.props.table, 'spectrum.regions')?.selectedRows || [];
                  return selectedRows.find((item: any) => item.rowId === row.regionId)?.field;
                },
                method: this.handleChangeLCN
              }}
              sortAction={{
                method: (sort: Sort) => {
                  if (this.props.channelsState.channelActive) {
                    this.props.getChannelRegions({
                      eligible: true,
                      sort,
                      channelId: this.props.channelsState.channelActive.channelId,
                      pageNumber: sort.pageNumber
                    });
                  }
                }
              }}
              totalElements={regionsAssociation.filteredCount}
              pagination={paginationChannelsAssociationsRegions}
            />

            <TableComponent
              id="spectrum.nodes"
              tableClasses="mb-5 pb-1 pt-5"
              title={t('spectrum.nodes')}
              onSearch={(filter: any) => {
                if (this.props.channelsState.channelActive) {
                  this.props.getChannelNodes({
                    eligible: true,
                    pageNumber: ++filter.pageNumber,
                    sort: {
                      order: filter.sortOrder,
                      column: filter.sortField
                    },
                    filter: filter,
                    channelId: this.props.channelsState.channelActive?.channelId
                  });
                }
              }}
              selectData={{ field: 'id', select: Select.MULTIPLE, selectOne: false }}
              buttonAction={{
                buttonName: 'spectrum.button_associate',
                buttonClass: 'is-primary-confirm',
                method: this.associateNodesAction.bind(this)
              }}
              columns={disassociatedNodesColumns}
              items={nodesAssociation.nodes}
              inputText={{
                placeholder: '',
                valueFn: (row: ChannelsAssociationsNodes) => {
                  const selectedRows =
                    getSelected(this.props.table, 'spectrum.nodes')?.selectedRows || [];
                  return selectedRows.find((item: any) => item.rowId === row.id)?.field;
                },
                method: this.handleChangeLCN
              }}
              sortAction={{
                method: (sort: Sort) => {
                  if (this.props.channelsState.channelActive) {
                    this.props.getChannelNodes({
                      eligible: true,
                      sort,
                      channelId: this.props.channelsState.channelActive.channelId,
                      pageNumber: sort.pageNumber
                    });
                  }
                }
              }}
              totalElements={nodesAssociation.filteredCount}
              pagination={paginationChannelsAssociationsNodes}
            />
          </section>
        )}
      </Fragment>
    );
  }
}
