import { RfTuningStatusResult } from '../../status/redux/types';

export const RF_TUNING_SCAN = 'RF_TUNING_SCAN';
export const RF_TUNING_SUCCESS = 'RF_TUNING_SUCCESS';
export const RF_TUNING_FAILURE = 'RF_TUNING_FAILURE';
export const RF_TUNING_SCAN_WAITING_COMMAND = 'RF_TUNING_SCAN_WAITING_COMMAND';
export const RF_TUNING_FORM_CHANGE_SCAN_TYPE = 'RF_TUNING_FORM_CHANGE_SCAN_TYPE';
export const RF_TUNING_CHANGE_CUSTOM_FREQQUENCY = 'RF_TUNING_FORM_CHANGE_CUSTOM_FREQQUENCY';
export const RF_TUNING_SCAN_CLEAR_CACHE = 'RF_TUNING_SCAN_CLEAR_CACHE';
export const RF_TUNING_ADD_TO_FREQUENCY_LIST = 'RF_TUNING_FORM_ADD_TO_FREQUENCY_LIST';
export const RF_TUNING_DELETE_CUSTOM_FREQUENCY = 'RF_TUNING_DELETE_CUSTOM_FREQUENCY';
export const RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST = 'RF_TUNING_CLEAR_CUSTOM_FREQUENCIES_LIST';
export const RF_TUNING_CHANGE_DROPDOWN = 'RF_TUNING_CHANGE_DROPDOWN';
export const RF_TUNING_SCAN_TOGGLE_ACCORDION = 'RF_TUNING_SCAN_TOGGLE_ACCORDION';
export const RF_TUNING_SCAN_SUCCESS_HISTORY = 'RF_TUNING_SCAN_SUCCESS_HISTORY';
export interface RfTuningScanAction {
  type: string;
  payload: any;
}

export interface RfTuningScanState {
  activityLog: any;
  results: Array<RfTuningStatusResult>;
  customFrequencies: CustomFrequencies;
  waitingCommandExecution: boolean;
  dropdownValue: string;
  resultHistoryToShow: [];
}

export interface CustomFrequencies {
  currentFrequency: string;
  currentSymbrate: string;
  listCustomFrequencies: CustomFrequency[];
}

export interface CustomFrequency {
  frequency: string;
  symbrate: string;
}

export interface FrequencyDetailParameter {
  frequencies: [];
  symbRates: [];
  jrt: [];
}
