import { TFunction } from 'i18next';
import { AdvancedSearchItem } from './types';
import DateUtils from 'core/utils/date-utils';

export default (data: any, t: TFunction): AdvancedSearchItem => {
  if (!data.deviceShadow) {
    return {
      udid: data && data.udid ? data.udid : '',
      hhid: data && data.householdID ? data.householdID : t('general.not_available'),
      deviceMod: t('general.not_available'),
      softwareVersion: t('general.not_available'),
      connection: data.connectedStatus === 'true' ? 'Online' : t('general.not_available'),
      kdsn: data?.externalID || t('general.not_available'),
      deviceFamily: data && data.deviceFamily ? data.deviceFamily : '',
    };
  }
  return {
    udid: data && data.udid ? data.udid : t('general.not_available'),
    hhid: data && data.householdID ? data.householdID : t('general.not_available'),
    deviceMod:
      data &&
      data.deviceShadow.reported &&
      data.deviceShadow.reported.hardware &&
      data.deviceShadow.reported.hardware.mod
        ? data.deviceShadow.reported.hardware.mod
        : t('general.not_available'),
    softwareVersion:
      data &&
      data.deviceShadow.reported &&
      data.deviceShadow.reported.software &&
      data.deviceShadow.reported.software.ver
        ? data.deviceShadow.reported.software.ver
        : t('general.not_available'),
    connection:
      data &&
      data.deviceShadow.reported &&
      data.deviceShadow.reported.status &&
      data.deviceShadow.reported.status.connected === 'true'
        ? 'Online'
        : data &&
          data.deviceShadow.reported &&
          data.deviceShadow.reported.status &&
          data.deviceShadow.reported.status.lastConnectionDate
        ? DateUtils.formatUnixDate(data.deviceShadow.reported.status.lastConnectionDate)
        : t('general.not_available'),
    kdsn: data && data.externalID ? data.externalID : t('general.not_available'),
    deviceFamily: data && data.deviceFamily ? data.deviceFamily : ''
  };
};

export const labelsAdvancedSearch = (t: TFunction): AdvancedSearchItem => ({
  udid: t('advanced_search.column_udid'),
  hhid: t('advanced_search.column_hhid'),
  deviceMod: t('advanced_search.column_device_mod'),
  softwareVersion: t('advanced_search.column_software_version'),
  connection: t('advanced_search.column_connection'),
  kdsn: t('advanced_search.column_kdsn')
});
