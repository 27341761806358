import { OverlayModalAction, OverlayModalState } from './types';

const initialState: OverlayModalState = {
  active: false,
  content: '',
  execute: () => {},
  title: ''
};

export default function(state = initialState, action: OverlayModalAction) {
  switch (action.type) {
    case 'OVERLAY_MODAL_CLOSE':
      return {
        active: false
      };
    case 'OVERLAY_MODAL_OPEN':
      return {
        active: true,
        content: action.payload.content,
        execute: action.payload.execute,
        size: action.payload.size,
        title: action.payload.title,
        children: action.payload.children,
      };
    default:
      return state;
  }
}
