import { ANONYMIZER_HASH_DELETE_SUCCESS, ANONYMIZER_HASH_FETCH_FAILURE, ANONYMIZER_HASH_FETCH_SUCCESS, ANONYMIZER_HASH_RESET, initialHashState } from "../../consts";
import { AnonymizerAction } from "../../types";

export default function (state = initialHashState, { type, payload }: AnonymizerAction) {
  switch (type) {
    case ANONYMIZER_HASH_FETCH_SUCCESS:
      return {...payload}

    case ANONYMIZER_HASH_FETCH_FAILURE:
      return {...initialHashState}

    case ANONYMIZER_HASH_DELETE_SUCCESS:
      return {...initialHashState}

    case ANONYMIZER_HASH_RESET:      
      return {...initialHashState}

    default:
      return {...state}
  }
}