import React, { Component } from 'react';
import { Search } from 'assets/svg/index';
import { TFunction } from 'i18next';
import { DVBManagementState } from '../redux/types';
import { DVB_COLUMNS } from '../redux/enums';
import { FieldItem } from '../popUp/redux/types';
import {
  SearchColumn,
  DVB_MANAGEMENT_SEARCH_SET_FIELD,
  DVB_MANAGEMENT_SEARCH_VALUE
} from './redux/types';

interface DVBManagementSearchProps {
  action: (type: any, payload?: any) => void;
  dvbManagement: DVBManagementState;
  onClearFilters: () => void;
  onSearch: (dvbManagement: DVBManagementState) => void;
  setColumn: (column: string) => void;
  t: TFunction;
}

export default class DVBManagementSearchComponent extends Component<DVBManagementSearchProps> {
  componentDidMount = () => {
    this.onChangeColumn("region")
  }
  
  onChangeColumn = (column: string) => {
    const { setColumn } = this.props;
    setColumn(column);
  };

  onClearFilters = () => {
    const { onClearFilters } = this.props;
    onClearFilters();
    document.getElementsByClassName('card-column-container')[0].classList.remove('animated');
  };

  onSearch = () => {
    const { dvbManagement, onSearch } = this.props;

    onSearch(dvbManagement);
    const column = dvbManagement.main.search.columns.find(item => item.isActive);
    const columnValue = column ? column.key : DVB_COLUMNS.NONE;
    if (columnValue === DVB_COLUMNS.REGION) {
      document.getElementsByClassName('card-column-container')[0].classList.remove('animated');
    }
  };

  render() {
    const { action, dvbManagement, t } = this.props;
    const field = dvbManagement.main.search.fields.find(item => item.isActive);

    return (
      <div className="card has-margin-bottom-md is-flex is-justified-between">
        <div className="columns search is-marginless no-padding">
          <div className="column is-4">
            <div className="select">
              <select
                value={dvbManagement.main.search.columns.find(item => item.isActive)?.key}
                onChange={(event: any) => this.onChangeColumn(event.target.value)}
                disabled={
                  !dvbManagement.main.search.columns || !dvbManagement.main.search.columns.length
                }
              >
                {dvbManagement.main.search.columns &&
                  dvbManagement.main.search.columns.map((column: SearchColumn, index: number) => (
                    <option value={column.key} key={index}>
                      {t(column.value)}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="column is-4">
            <div className="select">
              <select
                value={field?.key}
                onChange={(event: any) =>
                  action(DVB_MANAGEMENT_SEARCH_SET_FIELD, { field: event.target.value })
                }
                disabled={
                  !dvbManagement.main.search.fields || !dvbManagement.main.search.fields.length
                }
              >
                {dvbManagement.main.search.fields &&
                  dvbManagement.main.search.fields.map((fieldItem: FieldItem, index: number) => {
                    return (
                      <option value={fieldItem.key} key={index}>
                        {fieldItem.label && t(fieldItem.label)}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="column is-4 is-relative">
            <input
              className="input has-icon"
              type="text"
              placeholder={t('general.search') + '...'}
              disabled={
                !dvbManagement.main.search.columns ||
                !dvbManagement.main.search.columns.length ||
                !dvbManagement.main.search.fields ||
                !dvbManagement.main.search.fields.length
              }
              onChange={(event: any) =>
                action(DVB_MANAGEMENT_SEARCH_VALUE, { value: event.target.value })
              }
              value={dvbManagement.main.search.value || ''}
            />
            <span className="icon is-small in-input">
              <Search className="icon icon-svg icon-atlo" />
            </span>
          </div>
        </div>

        <div className="columns is-right">
          <div className="column is-narrow">
            <button type="submit" className="button is-secondary" onClick={() => this.onSearch()}>
              {t('general.search')}
            </button>
          </div>

          <div className="column is-narrow">
            <button
              type="button"
              className="button is-tertiary"
              onClick={() => this.onClearFilters()}
            >
              {t('general.clear_filters')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
