export const SIDEBAR_IS_HIDDEN = 'SIDEBAR_IS_HIDDEN';

export interface SidebarState {
  sidebar: {
    collapsed: boolean;
  };
}

export interface SidebarAction {
  type: string;
  payload?: any;
}
