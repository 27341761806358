export default class DvbManagementMapper {
  static mapErrors(error: string): string {
    if (error.includes('Resource already exists')) {
      return 'spectrum.error.02';
    } else if (error.includes('Resource not found')) {
      return 'spectrum.error.03';
    } else if (error.includes('Resource deletion not allowed')) {
      return 'spectrum.error.04';
    } else {
      return 'spectrum.error.01';
    }
  }
}
