import mapper from './mapper';
import { addToaster } from 'core/toaster/redux/actions';
import {
  RegionalizationChannelsAssociations,
  RegionalizationChannelsAssociationsAction,
  RegionalizationChannelsAssociationsState,
  REGIONALIZATION_GET_SUCCESS,
  REGIONALIZATION_ASSOCIATIONS_GET_FAILURE,
  REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS
} from './types';

const initialState: RegionalizationChannelsAssociationsState = {
  channelsAssociations: {
    isActive: false,
    totalCount: NaN,
    channels: []
  },
  channelsEligible: {
    isActive: false,
    totalCount: NaN,
    channels: []
  },
  type: '',
  isSelected: ''
};

export default function(state = initialState, action: RegionalizationChannelsAssociationsAction) {
  switch (action.type) {
    case REGIONALIZATION_ASSOCIATIONS_GET_SUCCESS: {
      state.channelsAssociations.channels = [];
      const data = action.payload.data;
      const totalCount = action.payload.totalCount;
      const Selected = action.payload.selected;
      const typeSelected = action.payload.type;
      return {
        ...state,
        isSelected: Selected,
        type: typeSelected,
        channelsAssociations: {
          channels: data.map((channel: RegionalizationChannelsAssociations) =>
            mapper(channel, action.payload.t)
          ),
          totalCount
        }
      };
    }
    case REGIONALIZATION_GET_SUCCESS: {
      state.channelsEligible.channels = [];
      const totalCount = action.payload.totalCount;
      const data = action.payload.data;
      const Selected = action.payload.selected;
      return {
        ...state,
        isSelected: Selected,
        channelsEligible: {
          channels: data.map((channel: RegionalizationChannelsAssociations) =>
            mapper(channel, action.payload.t)
          ),
          totalCount
        }
      };
    }
    case REGIONALIZATION_ASSOCIATIONS_GET_FAILURE: {
      return {
        ...state,
        channelsAssociations: {
          channels: [],
          totalCount: 0
        },
        channelsEligible: {
          channels: [],
          totalCount: 0
        }
      };
    }
    default:
      return { ...state };
  }
}
