import TooltipComponent from 'core/tooltip';
import { formatCamelCaseToSnakeCase } from 'core/utils/string-utils';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import './select-styles.scss';

interface SelectProps {
  t: TFunction;
  id: string;
  addSelectedFilters: (id: string, value: string, name: string, type?: string) => void;
  selectData: {
    name: string;
    listValues: string[];
    currentValue: string;
    tooltipText: any;
    translateList?: boolean;
    removeLabel?: boolean;
  };
  i18nKey?: string;
  displayEmpty?: boolean
}

export default class SelectComponent extends Component<SelectProps> {
  render() {
    const { t, addSelectedFilters, id, selectData, i18nKey, displayEmpty = true } = this.props;

    return (
      <div className="input-wrap select">
        <select
          className="input-select"
          name="select"
          data-value={selectData.currentValue}
          value={selectData.currentValue}
          onChange={(event: any) => addSelectedFilters(id, event.target.value, selectData.name)}
        >
          {displayEmpty && <option value="" />}
          {selectData &&
            selectData.listValues &&
            selectData.listValues.map((option: string, index: number) => (
              <option value={option} key={index}>
                {selectData.translateList
                  ? i18nKey === 'command'
                    ? t(`command.label.${formatCamelCaseToSnakeCase(option)}`)
                    : t(`${i18nKey}.select_${option}`)
                  : option}
              </option>
            ))}
        </select>
        <span className="floating-label">
          {selectData && !selectData.removeLabel && (
          <span className="has-padding-right-xs">
            {t(`${i18nKey || 'datagrabber'}.field_${selectData.name}`)}
          </span>)}
          {selectData.tooltipText && (
            <span className="has-padding-top-xxs input-select-tooltip">
              <TooltipComponent data={selectData.tooltipText} t={t} />
            </span>
          )}
        </span>
      </div>
    );
  }
}
