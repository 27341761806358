import { IdMappingAction } from './reducer';

export const SHOW_ID_MAPPING_FILTER = 'SHOW_ID_MAPPING_FILTER';
export const CLEAR_ID_MAPPING_FILTER = 'CLEAR_ID_MAPPING_FILTER';
export const POPULATE_ID_MAPPING_FILTER = 'POPULATE_ID_MAPPING_FILTER';
export const SET_ID_MAPPING_FILTER_VALUE = 'SET_ID_MAPPING_FILTER_VALUE';
export const CREATE_ID_MAPPING_FILTER_TAG = 'CREATE_ID_MAPPING_FILTER_TAG';
export const DELETE_ID_MAPPING_FILTER_TAG = 'DELETE_ID_MAPPING_FILTER_TAG';
export const CLEAR_ALL_ID_MAPPING_FILTER = 'CLEAR_ALL_ID_MAPPING_FILTER';

export function setValue(key: string, value: any): IdMappingAction {
  return {
    type: SET_ID_MAPPING_FILTER_VALUE,
    payload: {
      key,
      value
    }
  };
}

export function show(option: any): IdMappingAction {
  return {
    type: SHOW_ID_MAPPING_FILTER,
    payload: option
  };
}

export function clear(key: string): IdMappingAction {
  return {
    type: CLEAR_ID_MAPPING_FILTER,
    payload: key
  };
}

export function clearAll(): IdMappingAction {
  return {
    type: CLEAR_ALL_ID_MAPPING_FILTER,
    payload: null
  };
}

export function populate(key: string, value: any): IdMappingAction {
  return {
    type: POPULATE_ID_MAPPING_FILTER,
    payload: {
      key,
      value
    }
  };
}

export function validateAndCreateQueryTag(): IdMappingAction {
  return {
    type: CREATE_ID_MAPPING_FILTER_TAG,
    payload: ''
  };
}

export function deleteQuery(query: string): IdMappingAction {
  return {
    type: DELETE_ID_MAPPING_FILTER_TAG,
    payload: query
  };
}
