import React, { Component } from 'react';
import { Route } from 'react-router-dom';

export interface ModuleRoute {
  path: string;
  component: any;
  exact?: boolean;
  routes?: Array<Route>;
  search?: string;
  name?: string;
  group?: string;
}

export default class ModuleRouteComponent extends Component<ModuleRoute> {
  render() {
    const route = this.props;
    return <Route path={route.path} render={(props) => <this.props.component {...props} />} />;
  }
}
