import { combineReducers } from 'redux';
import overviewReducer from '../overview/redux';
import hardDiskReducer from '../hard-disk/redux';
import dvrReducer from '../dvr/redux';
import vodReducer from '../vod/redux';
import speedTestReducer from '../speed-test/redux';
import rfTuningReducer from '../rf-tuning/redux';
import activityLogReducer from '../activity-log/redux';
import settingsReducer from '../settings/redux';
import channelsReducer from '../channels/redux';
import subscriptionsReducer from '../subscriptions/redux';
import generalReducer from '../general/redux/reducers';
import provisionedDevicesReducer from '../provisioned-devices/redux';
import logsHistoryReducer from '../logs/redux';
import _settingsReducer from '../_settings/redux/reducer';

export default combineReducers({
  overview: overviewReducer,
  hardDisk: hardDiskReducer,
  dvr: dvrReducer,
  vod: vodReducer,
  speedTest: speedTestReducer,
  rfTuning: rfTuningReducer,
  activityLog: activityLogReducer,
  settings: settingsReducer,
  channels: channelsReducer,
  subscriptions: subscriptionsReducer,
  provisionedDevices: provisionedDevicesReducer,
  general: generalReducer,
  logsHistory: logsHistoryReducer,
  _settings: _settingsReducer
});
