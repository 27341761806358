import React, { Component, Fragment } from 'react';
import {
  Location,
  NetworkSignal,
  Node,
  Stream,
  Dots,
  List,
  Delete,
  Move,
  Paste,
  Copy,
  View,
  Filter,
  Sync
} from 'assets/svg/index';
import { DVBItemToShow, DVBManagementState, DVB_MANAGEMENT_CONSTANTS } from './redux/types';
import { DVB_ACTIONS, DVB_COLUMNS } from './redux/enums';
import { TFunction } from 'i18next';
import T from 'core/i18n/t';
import authService from 'core/auth';
import BadgeComponent from 'core/badge';
import CardComponent from 'core/card';
import DVBManagementSearchComponent from './search/dvb-management-search-container';
import DVBManagementPopUpComponent from './popUp/dvb-management-popUp-container';
import { DVBRegion } from './redux/redux-regions/types';
import { RequestReply, RequestsMainState } from '../requests/redux/types';
import ToasterItemComponent from 'core/toaster/toaster-item-component';
import { ADD_TOOLTIP, ToasterMessage } from 'core/toaster/redux/types';
import { sortList } from 'core/utils/general-utils';
import { CardPaddings } from 'core/card/enum';
import { BagdeTypes } from 'core/badge/enum';
import FileUploadContainer from 'core/file-upload/file-upload-container';
import FileUploadComponent from 'core/file-upload';
import { RegionsParams } from './redux/redux-regions/action';
import { DVBNode } from './redux/redux-nodes/types';

interface DVBManagementProps {
  action: (type: any, payload?: any) => void;
  deleteChangesIfExist: (rootId: string, column: DVB_COLUMNS) => void;
  dvbManagement: DVBManagementState;
  getAssetValuesFields: (callback: any, toasterTitle: string, t: TFunction) => void;
  getChanges: (column: DVB_COLUMNS) => void;
  getTuningData: (nodeId?: string) => void;
  getRegions: (params: RegionsParams) => void;
  changeMenu: (tab: string, id: string) => void;
  onCopy: (column: DVB_COLUMNS, previousColumn: DVB_COLUMNS, itemn: any) => void;
  onPaste: (column: DVB_COLUMNS, item: any) => void;
  push: (uri: string) => void;
  replyRequest: (id: string, command: RequestReply) => void;
  request: RequestsMainState;
  selectNetwork: (networkId: string) => void;
  selectNode: (nodeId: string) => void;
  selectRegion: (regionId?: string) => void;
  selectService: (serviceId: string) => void;
  selectStream: (streamId: string) => void;
  setChangeInfo: (action: DVB_ACTIONS, column?: DVB_COLUMNS, item?: any) => void;
  setRegions: (items?: DVBRegion[]) => void;
  submitRequest: (requestId: string, scheduledDate?: string) => void;
  t: TFunction;
  toggleSearch: () => void;
  onClearFilters: () => void;
  tooltip?: ToasterMessage;
  setIsRegionalization: (responseNode: boolean) => void;
}

export default class DVBManagementComponent extends Component<
  DVBManagementProps,
  { isActive: boolean }
> {
  constructor(props: DVBManagementProps) {
    super(props);
    this.state = { isActive: false };
  }

  isSuperUser = authService.isSuperUser('spectrum');
  componentDidMount() {
    const { getAssetValuesFields, getRegions, request, t } = this.props;
    getAssetValuesFields(this.getConstants, 'spectrum.menu_dvb_management', t); 
   
    this.selectRegion();
    this.props.setIsRegionalization(false);
    
    if (
      !request.selectedRequest ||
      (request.selectedRequest.isRequestOpen && !request.selectedRequest.canEditOtherEntities)
    ) {
      this.props.onClearFilters();
     
    } 
    else {
      this.selectRegion();
    }
  }

  getBadgeType(item: any) {
    const { request } = this.props;
    const type =
      !request.selectedRequest?.isRequestOpen || item.canEditChange
        ? BagdeTypes.INFO
        : BagdeTypes.NONE;
    return item.error ? BagdeTypes.DANGER : type;
  }

  onModalClose() {
    this.setState({ isActive: false });
  }

  onSetRegion(action: DVB_ACTIONS, column: DVB_COLUMNS, item: any) {
    this.selectRegion(item);
    this.props.setChangeInfo(action, column, item);
  }

  buildCard = (column: DVB_COLUMNS, items?: any) => {
    const {
      action,
      deleteChangesIfExist,
      dvbManagement,
      onCopy,
      onPaste,
      request,
      setChangeInfo,
      t
    } = this.props;
    const isLoading = dvbManagement.main.general.isLoading;
    const dvbManagementObj = dvbManagement as any;
    const isRequestOpen = request.selectedRequest?.isRequestOpen;

    return (
      <Fragment>
        <div className="card-column-header">
          <h5 className="text is-h5 is-marginless">{t(`spectrum.${column}s`)}</h5>
          {column && isRequestOpen && request.selectedRequest?.canEditOtherEntities && (
            <button
              className="button button-action"
              onClick={() => {
                setChangeInfo(DVB_ACTIONS.CREATE, column);
              }}
              disabled={!this.canAdd(column)}
            >
              <T>spectrum.button.add</T>
            </button>
          )}
        </div>
        
        <div className="card-column">
          {items && items.length
            ? items.map((item: any, index: number) => {
                return (
                  <div key={index} className="card-column-item-wrapper">
                    {item.changeLabel && (
                      <BadgeComponent
                        action={() => {
                          deleteChangesIfExist(item.rootId, column);
                        }}
                        hasButton={
                          item.canDeleteChange &&
                          (request.selectedRequest?.isRequestOpen || item.error)
                        }
                        isAbsolute
                        type={this.getBadgeType(item)}
                        onHover={() =>
                          item.error
                            ? action(ADD_TOOLTIP, {
                                toaster: {
                                  message: item.error,
                                  type: 'danger'
                                }
                              })
                            : {}
                        }
                      >
                        {item.changeLabel}
                      </BadgeComponent>
                    )}
                    <button
                      className={`card-column-item ${
                        dvbManagementObj[column] &&
                        dvbManagementObj[column].selected &&
                        dvbManagementObj[column].selected.rootId == item.rootId
                          ? 'card-column-item-selected'
                          : ''
                      }`}
                      onClick={() => this.getSelectApi(column, item)}
                      disabled={isLoading.network}
                    >
                      {this.getIcon(false, false, column)}
                      <div className="card-column-item-info">
                        <h6 className="text is-h6 is-bold is-marginless">{item.label}</h6>
                        <p>{t(`spectrum.${column}`) + ' ' + (item.id ? item.id : item.rootId)}</p>
                        {item.childRegionId && (
                          <p>{t('spectrum.node.child_region_id') + ': ' + item.childRegionId}</p>
                        )}
                      </div>
                    </button>
                    <div className="actions">
                      {column === DVB_COLUMNS.REGION ||
                      !isRequestOpen ||
                      !item.canEditChange ||
                      !request.selectedRequest?.canEditOtherEntities ? (
                        <Fragment>
                          <Dots className="icon-svg" />
                          <div className="action-list">
                            <CardComponent hasShadow padding={CardPaddings.NONE}>
                              <ul>
                                <li>
                                  <button
                                    className="button-link is-vertical-center"
                                    onClick={() => {
                                      this.onSetRegion(DVB_ACTIONS.VIEW, column, item);
                                    }}
                                  >
                                    <View className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                    <T>spectrum.actions.details</T>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="button-link is-vertical-center"
                                    onClick={() =>
                                      this.onSetRegion(DVB_ACTIONS.DELETE, column, item)
                                    }
                                  >
                                    <Delete className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                    <T>spectrum.actions.delete</T>
                                  </button>
                                </li>
                              </ul>
                            </CardComponent>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Dots className="icon-svg" />
                          <div className="action-list">
                            <CardComponent hasShadow padding={CardPaddings.NONE}>
                              <ul>
                                <li>
                                  <button
                                    className="button-link is-vertical-center"
                                    onClick={() => {
                                      setChangeInfo(DVB_ACTIONS.VIEW, column, item);
                                    }}
                                  >
                                    <View className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                    <T>spectrum.actions.details</T>
                                  </button>
                                </li>
                                {column !== DVB_COLUMNS.NODE && (
                                  <li>
                                    <button
                                      className="button-link is-vertical-center"
                                      onClick={() => {
                                        onCopy(column, this.getNearbyColumn(column, false), item);
                                      }}
                                    >
                                      <Copy className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                      <T>spectrum.actions.copy</T>
                                    </button>
                                  </li>
                                )}

                                {this.canPaste(column, item.id) && (
                                  <li>
                                    <button
                                      className="button-link is-vertical-center"
                                      onClick={() =>
                                        onPaste(this.getNearbyColumn(column, true), item)
                                      }
                                    >
                                      <Paste className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                      <T>spectrum.actions.paste</T>
                                    </button>
                                  </li>
                                )}
                                <li>
                                  <button
                                    className="button-link is-vertical-center"
                                    onClick={() => setChangeInfo(DVB_ACTIONS.MOVE, column, item)}
                                  >
                                    <Move className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                    <T>spectrum.actions.move</T>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="button-link is-vertical-center"
                                    onClick={() => setChangeInfo(DVB_ACTIONS.DELETE, column, item)}
                                  >
                                    <Delete className="icon icon-svg icon-small is-marginless has-margin-right-md" />
                                    <T>spectrum.actions.delete</T>
                                  </button>
                                </li>
                              </ul>
                            </CardComponent>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                );
              })
            : this.emptycard(isLoading, column)}
        </div>
      </Fragment>
    );
  };

  canAdd = (column: DVB_COLUMNS) => {
    const { dvbManagement } = this.props;
    switch (column) {
      case DVB_COLUMNS.REGION:
        return true;
      case DVB_COLUMNS.NODE:
        return dvbManagement.region.selected ? true : false;
      case DVB_COLUMNS.NETWORK:
        return dvbManagement.node.selected ? true : false;
      case DVB_COLUMNS.STREAM:
        return dvbManagement.network.selected ? true : false;
      case DVB_COLUMNS.SERVICE:
        return dvbManagement.stream.selected ? true : false;
      default:
        return false;
    }
  };

  canPaste = (column: DVB_COLUMNS, id: string) => {
    const { dvbManagement } = this.props;
    const copied = dvbManagement.main.general.copied;

    if (copied) {
      const node = copied.nodes;
      const network = node && node[0] && node[0].networks;
      const stream = network && network[0] && network[0].transportStreams;
      const service = stream && stream[0] && stream[0].services;

      switch (column) {
        case DVB_COLUMNS.NODE: {
          return (
            network &&
            network.length > 0 &&
            node &&
            node[0].id !== id &&
            (!stream || stream?.length === 0) &&
            (!service || service?.length === 0)
          );
        }

        case DVB_COLUMNS.NETWORK: {
          return (
            stream &&
            stream.length > 0 &&
            network &&
            network[0].id !== id &&
            (!service || service?.length === 0)
          );
        }

        case DVB_COLUMNS.STREAM: {
          return service && service.length > 0 && stream && stream[0].id !== id;
        }
      }
    }
  };

  emptycard = (isLoading: any, column: DVB_COLUMNS) => {
    return (
      <Fragment>
        <div className="no-data">
          {this.getIcon(isLoading, true, column)}
          <p>{this.getNoDataLabel(isLoading, column)}</p>
        </div>
      </Fragment>
    );
  };

  getNearbyColumn = (column: DVB_COLUMNS, next?: boolean): DVB_COLUMNS => {
    switch (column) {
      case DVB_COLUMNS.NODE: {
        return next ? DVB_COLUMNS.NETWORK : DVB_COLUMNS.REGION;
      }

      case DVB_COLUMNS.NETWORK: {
        return next ? DVB_COLUMNS.STREAM : DVB_COLUMNS.NODE;
      }

      case DVB_COLUMNS.STREAM: {
        return next ? DVB_COLUMNS.SERVICE : DVB_COLUMNS.NETWORK;
      }

      case DVB_COLUMNS.SERVICE: {
        return next ? DVB_COLUMNS.NONE : DVB_COLUMNS.STREAM;
      }

      default:
        return DVB_COLUMNS.NONE;
    }
  };

  getConstants = (assetsValues: any) => {
    const { action } = this.props;
    action(DVB_MANAGEMENT_CONSTANTS, { assetsValues });
  };

  getIcon = (isLoading: any, isNoData: boolean, type?: DVB_COLUMNS) => {
    switch (type) {
      case DVB_COLUMNS.REGION:
        if (isLoading.region) {
          return <Sync className="icon-svg icon-spinner" />;
        }
        return <Location className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      case DVB_COLUMNS.NODE:
        return <Node className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      case DVB_COLUMNS.NETWORK:
        if (isLoading.network) {
          return <Sync className="icon-svg icon-spinner" />;
        }
        return (
          <NetworkSignal className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />
        );
      case DVB_COLUMNS.STREAM:
        return <Stream className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      case DVB_COLUMNS.SERVICE:
        return <List className={`icon-svg ${isNoData ? 'icon-faded' : 'icon-highlighted'}`} />;
      default:
        break;
    }
  };

  getListToShow = (list: any, isLoading?: boolean) => {
    if (isLoading) {
      return [];
    } else {
      return list.itemsSearch ? list.itemsSearch : list.itemsChanged || [];
    }
  };

  getNoDataLabel = (isLoading: any, type: DVB_COLUMNS) => {
    const { dvbManagement } = this.props;

    switch (type) {
      case DVB_COLUMNS.REGION:
        if (isLoading.region) {
          return (
            <span className="text is-dark">
              <T>spectrum.fetching_data</T>
            </span>
          );
        }

        return <T>spectrum.no_results</T>;
      case DVB_COLUMNS.NODE:
        if (this.hasNoData(dvbManagement.region, dvbManagement.node)) {
          return <T>spectrum.no_results</T>;
        }

        return <T>spectrum.nodes.no_data</T>;
      case DVB_COLUMNS.NETWORK:
        if (isLoading.network) {
          return (
            <span className="text is-dark">
              <T>spectrum.fetching_data</T>
            </span>
          );
        } else if (this.hasNoData(dvbManagement.node, dvbManagement.network)) {
          return <T>spectrum.no_results</T>;
        }

        return <T>spectrum.networks.no_data</T>;
      case DVB_COLUMNS.STREAM:
        if (this.hasNoData(dvbManagement.network, dvbManagement.stream)) {
          return <T>spectrum.no_results</T>;
        }

        return <T>spectrum.transport_streams.no_data</T>;
      case DVB_COLUMNS.SERVICE:
        if (this.hasNoData(dvbManagement.stream, dvbManagement.service)) {
          return <T>spectrum.no_results</T>;
        }

        return <T>spectrum.services.no_data</T>;
      default:
        return <T>spectrum.fetching_data</T>;
    }
  };

  getSelectApi(column: DVB_COLUMNS, item: DVBItemToShow) {
    const { selectNetwork, selectService, selectStream } = this.props;

    switch (column) {
      case DVB_COLUMNS.REGION:
        document.getElementsByClassName('card-column-container')[0].classList.add('animated');
        return this.selectRegion(item);
      case DVB_COLUMNS.NODE:
        return this.selectNode(item);
      case DVB_COLUMNS.NETWORK:
        selectNetwork(item.rootId || '');
        break;
      case DVB_COLUMNS.STREAM:
        selectStream(item.rootId || '');
        break;
      case DVB_COLUMNS.SERVICE:
        selectService(item.rootId || '');
        break;
      default:
        break;
    }
  }

  hasNoData = (previousList: any, currentList: any) => {
    return (
      previousList.itemsChanged &&
      previousList.itemsChanged.length &&
      previousList.selected &&
      (!currentList.itemsSearch || currentList.itemsSearch.length === 0)
    );
  };

  selectNode(item: any) {
    const { getTuningData, request, selectNode } = this.props;
    const isRequestOpen = request.selectedRequest?.isRequestOpen;

    if (isRequestOpen) {
      getTuningData(item.rootId);
    }

    selectNode(item.rootId || '');
  }

  selectRegion(item?: DVBItemToShow) {
    const { selectRegion } = this.props;
    selectRegion(item ? item.rootId : undefined);
  }

  render() {
    const {
      changeMenu,
      dvbManagement,
      push,
      replyRequest,
      request,
      setChangeInfo,
      submitRequest,
      toggleSearch,
      t,
      tooltip
    } = this.props;
    const selectedRegion = dvbManagement.region.selected;
    const isLoading = dvbManagement.main.general.isLoading;
    const isRequestOpen = request.selectedRequest?.isRequestOpen;
    const isPendingRequest = request.selectedRequest?.isRequestPending;

    return (
      <Fragment>
        <FileUploadContainer
          isActive={this.state.isActive}
          title={'Upload file'}
          clue={'Please select a file to upload and await its validation'}
          confirmButton={'Confirm Upload'}
          close={this.onModalClose.bind(this)}
          formatFile={'CSV'}
          maxMBsize={5}
          pageType={'dvbManagement'}
        ></FileUploadContainer>
        <section className="section-spaced">
          <div className="columns has-margin-bottom-sm is-justified-between is-vertical-center">
            <div className="column is-narrow">
              {!selectedRegion ? (
                <h4 className="text is-bold is-h4">
                  <T>spectrum.broadcast_information</T>
                </h4>
              ) : (
                <div className="info is-vertical-center">
                  <h4 className="text is-h4 is-bold has-margin-right-sm">{selectedRegion.label}</h4>
                  <h5 className="text is-h5">
                    <T>spectrum.region</T> {selectedRegion.id}
                  </h5>
                  <button
                    className="button button-action is-highlighted"
                    onClick={() => this.selectRegion()}
                  >
                    <T>spectrum.button.change</T>
                  </button>
                </div>
              )}
            </div>

            <div className="column is-narrow is-flex">
              {isRequestOpen && (
                <Fragment>
                  <button
                    className="button is-rounded is-primary"
                    onClick={() => submitRequest(request.selectedRequest?.requestId || '')}
                    disabled={!request.selectedRequest?.requestId}
                  >
                    <T>spectrum.button.submit</T>
                  </button>

                  <button
                    type="button"
                    className="button is-rounded is-larger"
                    onClick={() =>
                      setChangeInfo(
                        DVB_ACTIONS.SCHEDULE,
                        DVB_COLUMNS.NONE,
                        t('spectrum.button.schedule')
                      )
                    }
                    disabled={!request.selectedRequest?.requestId}
                  >
                    <T>spectrum.button.schedule</T>
                  </button>

                  {request.selectedRequest?.isRequestOpen && (
                    <button
                      type="button"
                      className="button is-rounded is-larger"
                      onClick={() => {
                        this.setState({ isActive: true });
                      }}
                    >
                      <T>spectrum.button.upload</T>
                    </button>
                  )}
                </Fragment>
              )}

              {this.isSuperUser && isPendingRequest && this.props.request.selectedRequest?.details && (
                <Fragment>
                  <button
                    className="button is-rounded is-larger"
                    onClick={() =>
                      replyRequest(request.selectedRequest?.requestId || '', RequestReply.APPROVE)
                    }
                  >
                    <T>spectrum.actions.approve</T>
                  </button>

                  <button
                    className="button is-rounded is-larger"
                    onClick={() =>
                      replyRequest(request.selectedRequest?.requestId || '', RequestReply.REJECT)
                    }
                  >
                    <T>spectrum.actions.reject</T>
                  </button>
                </Fragment>
              )}

              {(isRequestOpen === false || isPendingRequest) &&
                this.props.request.selectedRequest?.details && (
                  <div className="info">
                    <p className="text is-size-sm has-margin-y-sm">
                      <span className="text is-highlighted is-bold">
                        {t('spectrum.requests.request_id')}:
                      </span>
                      <span className="has-margin-left-sm">
                        {request.selectedRequest?.requestId}
                      </span>
                    </p>
                    <button
                      className="button button-action is-highlighted"
                      onClick={() => {
                        changeMenu('requests', 'spectrum');
                        push('/management/spectrum/requests');
                      }}
                      type="button"
                    >
                      <T>spectrum.button.change</T>
                    </button>
                  </div>
                )}

              <button
                className={`button is-circle 
                  ${
                    request.selectedRequest?.isRequestOpen ? 'has-separator' : 'has-margin-left-md'
                  } 
                  ${dvbManagement.main.search.isActive ? 'is-selected' : ''}`}
                onClick={() => toggleSearch()}
                type="button"
              >
                <Filter className="icon-svg" />
              </button>
            </div>
          </div>

          {dvbManagement.main.search.isActive && (
            <DVBManagementSearchComponent
              search={dvbManagement.main.search}
              dvbManagement={dvbManagement}
            />
          )}

          <div
            className={`card-column-wrapper ${
              dvbManagement.main.search.isActive ? 'card-column-wrapper-compressed' : ''
            }`}
          >
            <div className="card-column-container">
              <CardComponent>
                {this.buildCard(
                  DVB_COLUMNS.REGION,
                  sortList(this.getListToShow(dvbManagement.region, isLoading.region), 'label')
                )}
              </CardComponent>
              <CardComponent>
                {this.buildCard(
                  DVB_COLUMNS.NODE,
                  sortList(this.getListToShow(dvbManagement.node), 'label')
                )}
              </CardComponent>
              <CardComponent>
                {this.buildCard(
                  DVB_COLUMNS.NETWORK,
                  sortList(this.getListToShow(dvbManagement.network, isLoading.network))
                )}
              </CardComponent>
              <CardComponent>
                {this.buildCard(
                  DVB_COLUMNS.STREAM,
                  sortList(this.getListToShow(dvbManagement.stream))
                )}
              </CardComponent>
              <CardComponent>
                {this.buildCard(
                  DVB_COLUMNS.SERVICE,
                  sortList(this.getListToShow(dvbManagement.service))
                )}
              </CardComponent>
            </div>
          </div>

          <DVBManagementPopUpComponent
            popUp={dvbManagement.main.popUp}
            constants={dvbManagement.main.general.constants}
          />

          <span className="badge-error">
            <ToasterItemComponent tooltip={tooltip} noIcon />
          </span>
        </section>
      </Fragment>
    );
  }
}
