import moment from 'moment';
import { TFunction } from 'i18next';
import {
  searchFilterProvDevices,
  searchFilterSubscriptions,
  searchFilterDvr,
  searchFilterVod,
  searchFilterActivityLog,
  searchFilterSpeedTest,
  searchFilterLogsHistory,
  columnsToCompare,
  searchFilterRegionalization,
  searchFilterChannels,
  searchFilterRegions,
  searchFilterNodes,
  searchFilterRegionalizationRemaining,
  searchDevicesFilterTypes,
  searchFilterRequests
} from './config';
import {
  FilterAction,
  SearchState,
  SearchFilter,
  FILTERS_SLIDER_UPDATE_DAYS,
  FILTERS_TOGGLE,
  SET_CURRENT_FILTER_VALUE,
  SET_CURRENT_COMPARATOR_VALUE,
  SET_FILTER_TYPE,
  TRANSLATE_FILTER,
  CLEAR_ALL_FILTERS,
  ADD_FILTERS,
  SET_TYPE_FILTER_VALUE,
  RESET_FILTER,
  InitialFilterMap,
} from './types';

const initialFilterMap: InitialFilterMap = {
  'account.subscriptions': searchFilterSubscriptions,
  'account.provisioned_devices': searchFilterProvDevices,
  'dvr': searchFilterDvr,
  'vod': searchFilterVod,
  'activity_log': searchFilterActivityLog,
  'speed_test': searchFilterSpeedTest,
  'logs': searchFilterLogsHistory,
  'spectrum.channels': searchFilterChannels,
  'spectrum.regions': searchFilterRegions,
  'spectrum.nodes': searchFilterNodes,
  'spectrum.regionalization.disassociate': searchFilterRegionalization,
  'spectrum.regionalization.associate': searchFilterRegionalizationRemaining,
  'device': searchDevicesFilterTypes,
  'spectrum.requests': searchFilterRequests,
}

function setInitialFilters(filter: Map<string, SearchFilter>) {
  Object.keys(initialFilterMap).forEach(id => {
    filter.set(id, initialFilterMap[id] as SearchFilter)
  })

  return filter
}

const inicialFilter = new Map();

const initialState: SearchState = {
  filters: setInitialFilters(inicialFilter)
};

function camelToSnakeCase(text: string) {
  return text
    .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/, '$1_$2')
    .toLowerCase();
}

function calcShowComparators(selectedTable: string, selectField: string): boolean {
  let searchTable = selectedTable.split('.').pop();
  if (!searchTable) {
    searchTable = selectedTable;
  }

  return columnsToCompare[searchTable]
    ? columnsToCompare[searchTable].includes(selectField)
    : false;
}

function translate(filters: Map<string, SearchFilter>, t: TFunction): Map<string, SearchFilter> {
  const translatedMap = new Map<string, SearchFilter>();

  filters.forEach((filter: SearchFilter, key: string) => {
    if (filter) {
      const translatedTypes = filter.filterTypes.map((type) => ({
        ...type,
        name: t(`${key}.column_${camelToSnakeCase(type.value)}`)
      }));

      const translatedFilter: SearchFilter = {
        ...filter,
        filterTypes: translatedTypes,
        currentFilter: {
          ...filter.currentFilter,
          type:
            translatedTypes.find((type: any) => type.value === filter.currentFilter.type.value) ||
            translatedTypes[0]
        },
        showComparators: calcShowComparators(key, filter.currentFilter.type.value)
      };

      translatedMap.set(key, translatedFilter);
    }
  });

  return translatedMap;
}

function getDefaultFilterValue(filterType: any) {
  let filterValue;
  switch (filterType.type) {
    case 'date':
      filterValue = { start: moment().toISOString(), end: moment().toISOString() };
      break;
    case 'list':
      filterValue = filterType.listValues && filterType.listValues[0];
      break;
    case 'boolean':
      filterValue = false;
      break;
    default:
      filterValue = '';
  }

  return filterValue;
}

export default function (state: SearchState = initialState, action: FilterAction): SearchState {
  switch (action.type) {
    case FILTERS_TOGGLE: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);

      if (searchFilter) {
        const currentSearchFilter: SearchFilter = {
          ...searchFilter,
          moreFilters: {
            state: !action.payload.filterState,
            days: searchFilter.moreFilters ? searchFilter.moreFilters.days : 0
          }
        };
        filters.delete(action.payload.id);
        filters.set(action.payload.id, currentSearchFilter);
      }

      return {
        ...state,
        filters
      };
    }

    case FILTERS_SLIDER_UPDATE_DAYS: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);

      if (searchFilter) {
        const currentSearchFilter: SearchFilter = {
          ...searchFilter,
          moreFilters: {
            state: searchFilter.moreFilters ? searchFilter.moreFilters.state : false,
            days: action.payload.day
          }
        };

        filters.delete(action.payload.id);
        filters.set(action.payload.id, currentSearchFilter);
      }

      return {
        ...state,
        filters
      };
    }

    case SET_FILTER_TYPE: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);

      if (searchFilter) {
        const currentFilterType = searchFilter.filterTypes.find(
          (type: any) => type.value === action.payload.value
        ) || {
          name: '',
          type: '',
          value: ''
        };

        const filterValue = searchFilter.currentFilter.type
          ? searchFilter.currentFilter.type.type === 'string' &&
            currentFilterType.type === 'string' &&
            searchFilter.currentFilter.value
            ? searchFilter.currentFilter.value
            : getDefaultFilterValue(currentFilterType)
          : '';

        const currentSearchFilter: SearchFilter = {
          ...searchFilter,
          currentFilter: {
            type: currentFilterType,
            value: filterValue
          },
          showComparators: calcShowComparators(action.payload.id, action.payload.value)
        };

        filters.delete(action.payload.id);
        filters.set(action.payload.id, currentSearchFilter);
      }

      return {
        ...state,
        filters
      };
    }

    case SET_CURRENT_FILTER_VALUE: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);
      if (searchFilter) {
        const currentSearchFilter: SearchFilter = {
          ...searchFilter,
          currentFilter: { type: searchFilter.currentFilter.type, value: action.payload.value }
        };

        filters.delete(action.payload.id);
        filters.set(action.payload.id, currentSearchFilter);
      }

      return {
        ...state,
        filters
      };
    }

    case SET_CURRENT_COMPARATOR_VALUE: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);

      if (searchFilter) {
        const currentSearchFilter: SearchFilter = {
          ...searchFilter,
          currentComparator: action.payload.value
        };

        filters.delete(action.payload.id);
        filters.set(action.payload.id, currentSearchFilter);
      }

      return {
        ...state,
        filters
      };
    }

    case ADD_FILTERS: {
      const id = action.payload.id;
      const filter = action.payload.filter;
      state.filters.get(id)?.filterTypes.push(filter);
      return {
        ...state
      };
    }

    case TRANSLATE_FILTER: {
      return {
        ...state,
        filters: translate(state.filters, action.payload.t)
      };
    }

    case CLEAR_ALL_FILTERS: {
      return initialState;
    }
    
    case SET_TYPE_FILTER_VALUE: {
      const { filters } = state;
      const searchFilter = filters.get(action.payload.id);

      if (searchFilter) {
        const filterTypesSearch: SearchFilter = {
          ...searchFilter,
          filterTypes: action.payload.value
        };
        filters.delete(action.payload.id);
        filters.set(action.payload.id, filterTypesSearch);
      }
      return {
        ...state,
        filters
      };
    }

    case RESET_FILTER: {
      const { filters } = state

      const filter = setInitialFilters(filters)

      return {
        filters: filter
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
