import apiClient from 'core/api';
import AuthService from 'core/auth';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import refreshResults from '../../grid/redux/action';
import { add, remove } from '../../../../../core/indicator/pending-tasks-redux';
import {
  ID_MAPPING_COLLISION_REQUEST,
  ID_MAPPING_COLLISION_SUCCESS,
  ID_MAPPING_COLLISION_FAILURE,
  ID_MAPPING_RESOLUTION_SUCCESS,
  ID_MAPPING_RESOLUTION_FAILURE,
  ID_MAPPING_SELECT_ENTRY,
  CLEAR_ID_COLLISION_PAGINATION,
  IdMappingCollisionResult
} from './reducer';
import { envs } from 'application/envHandler';

function request() {
  return {
    type: ID_MAPPING_COLLISION_REQUEST
  };
}

export function selectEntry(uuid: string) {
  return {
    type: ID_MAPPING_SELECT_ENTRY,
    payload: {
      uuid
    }
  };
}

function successResolution(payload: any) {
  return {
    type: ID_MAPPING_RESOLUTION_SUCCESS,
    payload
  };
}

function failureResolution(payload: any) {
  return {
    type: ID_MAPPING_RESOLUTION_FAILURE,
    payload
  };
}

function success(payload: IdMappingCollisionResult, pageNumber: number) {
  return {
    type: ID_MAPPING_COLLISION_SUCCESS,
    payload: { ...payload, pageNumber }
  };
}

function failure(payload: any) {
  return {
    type: ID_MAPPING_COLLISION_FAILURE,
    payload
  };
}

export function execute(id: string, pageNumber: number) {
  return async (dispatch: any) => {
    dispatch(request());
    dispatch(add('ID_MAPPING_COLLISION_REQUEST', {}));
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/id-mapping/report/${id}/detail?pageSize=7&pageNumber=${pageNumber}`,
        { headers: { opco: AuthService.getCurrentOpco() } }
      )
      .then(
        response => {
          dispatch(remove('ID_MAPPING_COLLISION_REQUEST'));
          dispatch(refreshResults());
          return dispatch(success(response.data, pageNumber));
        },
        rejection => {
          dispatch(remove('ID_MAPPING_COLLISION_REQUEST'));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: 'error_messages.VIPOC_ERROR_007',
              type: 'danger'
            })
          );
          dispatch(refreshResults());
          return dispatch(failure(rejection));
        }
      );
  };
}

export function sendResolution(uuid: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add('ID_MAPPING_RESOLUTION_REQUEST', {}));
    const state = getState().management.idMapping.collision;
    const result: IdMappingCollisionResult = state.results;
    const solutionRequest = {
      jobId: uuid,
      solutions: result.content.map(idMappingResultRow => {
        let selected = idMappingResultRow.mappings.find(
          mapping => mapping.uuid === idMappingResultRow.selected
        );
        if (!selected) {
          selected = idMappingResultRow.collision;
        }
        return {
          collision: JSON.stringify(idMappingResultRow.collision),
          solution: JSON.stringify(selected)
        };
      })
    };

    apiClient
      .post(`${envs.REACT_APP_API_URL}/id-mapping/management/solve/`, solutionRequest, {
        headers: { opco: AuthService.getCurrentOpco() }
      })
      .then(
        response => {
          dispatch(remove('ID_MAPPING_RESOLUTION_REQUEST'));
          dispatch(successResolution(response.data));
          window.location.href = '/management/id-mapping';
        },
        rejection => {
          let errorMsg: string = 'error_messages.VIPOC_ERROR_048';
          if (rejection.response && rejection.response.data) {
            errorMsg = rejection.response.data;
          }
          dispatch(remove('ID_MAPPING_RESOLUTION_REQUEST'));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: errorMsg,
              type: 'danger'
            })
          );
          return dispatch(failureResolution(rejection));
        }
      );
  };
}

export function cancelIngest(jobUid: string) {
  return async (dispatch: any) => {
    dispatch(add('ID_MAPPING_RESOLUTION_REQUEST', {}));
    apiClient
      .post(`${envs.REACT_APP_API_URL}/id-mapping/management/cancel-ingest/${jobUid}`, {
        headers: { opco: AuthService.getCurrentOpco() }
      })
      .then(
        () => {
          dispatch(remove('ID_MAPPING_RESOLUTION_REQUEST'));
          window.location.href = '/management/id-mapping';
        },
        rejection => {
          let errorMsg: string = 'error_messages.VIPOC_ERROR_048';
          if (rejection.response && rejection.response.data) {
            errorMsg = rejection.response.data;
          }
          dispatch(remove('ID_MAPPING_RESOLUTION_REQUEST'));
          dispatch(
            addToaster({
              title: 'home.icon_id_mapping',
              message: errorMsg,
              type: 'danger'
            })
          );
          return dispatch(failureResolution(rejection));
        }
      );
  };
}

export function clearPagination() {
  return {
    payload: null,
    type: CLEAR_ID_COLLISION_PAGINATION
  };
}
