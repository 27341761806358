export const IP_GEOLOCATION_REQUEST = 'IP_GEOLOCATION_REQUEST';
export const IP_GEOLOCATION_SUCCESS = 'IP_GEOLOCATION_SUCCESS';
export const IP_GEOLOCATION_FAILURE = 'IP_GEOLOCATION_FAILURE';
export const IP_GEOLOCATION_EXPORT_REQUEST = 'IP_GEOLOCATION_EXPORT_REQUEST';
export const IP_GEOLOCATION_EXPORT_SUCCESS = 'IP_GEOLOCATION_EXPORT_SUCCESS';
export const IP_GEOLOCATION_EXPORT_FAILURE = 'IP_GEOLOCATION_EXPORT_FAILURE';
export const IP_GEOLOCATION_FILTER_ADDRESSESS = 'IP_GEOLOCATION_FILTER_ADDRESSESS';

interface IpGeolocationState {
  currentFilter: any;
  results: IpGeolocationResult;
}

interface IpGeolocationAction {
  type: string;
  payload: any;
}

const initialState: IpGeolocationState = {
  currentFilter: null,
  results: {
    content: [],
    totalPages: 0,
    size: 0,
    totalElements: 0,
    number: 0,
    numberOfElements: 0
  }
};

export interface IpGeolocationResult {
  content: Array<IpGeolocationResultRow>;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
}

export interface IpGeolocationResultRow {
  serialNumber: number;
  opcoId: string;
  ipAddressStart: string;
  ipAddressEnd: string;
  createDate: string;
  startDate: string;
  expiryDate: string;
  userId: string;
}

export default function(state = initialState, action: IpGeolocationAction) {
  switch (action.type) {
    case IP_GEOLOCATION_SUCCESS: {
      return { ...state, results: action.payload };
    }
    case IP_GEOLOCATION_FILTER_ADDRESSESS: {
      return {
        ...state,
        currentFilter: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
}
