import React from 'react';
import { IdMappingCollisionResult, IdMappingCollisionResultRow } from './redux/reducer';
import CollisionRowComponent from './collision-row-component';
import { selectEntry } from './redux/action';
import { TFunction } from 'i18next';

type Props = {
  execute: any;
  selectEntry: typeof selectEntry;
  isFetching: boolean;
  results: IdMappingCollisionResult;
  id: any;
  pageNumber: number;
  clearPagination: any;
  t: TFunction;
};

type HeaderProps = {
  keys: Array<string>;
};

const Header = (props: HeaderProps) => {
  return (
    <thead>
      <tr>
        <th style={{ textAlign: 'center', padding: 10 }}>Selected</th>
        <th style={{ textAlign: 'center', padding: 10 }}>Origin</th>
        {props.keys.map((key: string, index: number) => (
          <th key={index}>{key}</th>
        ))}
      </tr>
    </thead>
  );
};

export default class IdMappingCollisionGridComponent extends React.Component<Props> {
  componentWillUnmount() {
    this.props.clearPagination();
  }

  render() {
    const { primaryKey, secondaryKeys, columns } = this.props.results.schemaProperties;
    const { t } = this.props;
    let requiredKeys: Array<string> = [];
    requiredKeys = requiredKeys.concat(primaryKey);
    requiredKeys = requiredKeys.concat(secondaryKeys);

    const notRequired = columns.filter(
      col => primaryKey !== col && secondaryKeys.indexOf(col) === -1
    );
    const keys = requiredKeys.concat(notRequired);

    if (this.props.isFetching) {
      return (
        <div className="tile is-child box">
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        </div>
      );
    }
    return (
      <div>
        {(!this.props.results || this.props.results.content.length === 0) && (
          <div className="container">
            <p>Duplications not found</p>
          </div>
        )}

        {this.props.results &&
          this.props.results.content.map((conflict: IdMappingCollisionResultRow, index: number) => {
            return (
              <div className="table-container" key={index}>
                <table
                  className="table is-fullwidth is-hoverable is-striped"
                  style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
                  id={`table-container-${index}`}
                >
                  <Header keys={keys} />
                  <CollisionRowComponent
                    keys={keys}
                    onChange={(uuid: string) => this.props.selectEntry(uuid)}
                    selected={conflict.selected}
                    collision={conflict.collision}
                    mappings={conflict.mappings}
                  />
                </table>
              </div>
            );
          })}
        <div className="columns">
          <div className="column" style={{ textAlign: 'center' }}>
            <nav
              className="pagination is-left"
              role="navigation"
              aria-label="pagination"
              style={{ marginBottom: '3em' }}
            >
              <button
                className="pagination-previous button is-dark"
                disabled={this.props.pageNumber < 1}
                onClick={() => this.props.execute(this.props.id, this.props.pageNumber - 1)}
              >
                Previous
              </button>
              <button
                className="pagination-next button is-primary"
                disabled={!this.props.results.hasNext}
                onClick={() => this.props.execute(this.props.id, this.props.pageNumber + 1)}
              >
                {t('general.next_page')}
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
