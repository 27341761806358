import { Action } from 'redux';
import { clone } from 'ramda';

interface PendingTasksState {
  tasks: Map<string, any>;
}

const SET = 'SET_PENDING_TASK';
type SET = typeof SET;

const DELETE = 'DELETE_PENDING_TASK';
type DELETE = typeof DELETE;

interface PendingTaskAction extends Action {
  payload: {
    key: string;
    value?: any;
  };
}

const initialState: PendingTasksState = {
  tasks: new Map<string, any>()
};

export const pendingTasksReducer = (
  state = initialState,
  action: PendingTaskAction
): PendingTasksState => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        tasks: state.tasks.set(action.payload.key, action.payload.value)
      };
    case DELETE:
      const newMap = clone(state.tasks);
      newMap.delete(action.payload.key);
      return {
        ...state,
        tasks: newMap
      };

    default:
      return {
        ...state
      };
  }
};

export const add = (key: string, value: any): PendingTaskAction => ({
  type: SET,
  payload: {
    key,
    value
  }
});

export const remove = (key: string): PendingTaskAction => ({
  type: DELETE,
  payload: {
    key
  }
});

export const hasPendingTasks = (state: PendingTasksState): boolean =>
  state.tasks && state.tasks.size > 0;

export const hasPendingTasksOf = (key: string, state: PendingTasksState): boolean =>
  state.tasks && state.tasks.size > 0 && state.tasks.get(key);
