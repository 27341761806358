export const ONNET_OFFNET_LIST_REQUEST = 'ONNET_OFFNET_LIST_REQUEST';
export const ONNET_OFFNET_LIST_SUCCESS = 'ONNET_OFFNET_LIST_SUCCESS';
export const ONNET_OFFNET_LIST_FAILURE = 'ONNET_OFFNET_LIST_FAILURE';
export const ONNET_OFFNET_SELECT_ENTRY = 'ONNET_OFFNET_SELECT_ENTRY';
export const ONNET_OFFNET_REMOVE_ENTRY = 'ONNET_OFFNET_REMOVE_ENTRY';
export const ONNET_OFFNET_SAVE_ENTRY = 'ONNET_OFFNET_SAVE_ENTRY';
export const ONNET_OFFNET_SAVE_NEW_ENTRY = 'ONNET_OFFNET_SAVE_NEW_ENTRY';

interface OnnetOffnetState {
  results: OnnetOffnetResult
}

interface OnnetOffnetAction {
  type: string
  payload: any
}

export interface OnnetOffnetResult {
  ranges: Array<OnnetOffnetRow>
  rangesIpv6: Array<OnnetOffnetRow>
  opco: string
}

export interface OnnetOffnetRow {
  entry: string
  entryStatus: string
  editable?: boolean
  newEntry?: boolean
}

const initialState: OnnetOffnetState = {
  results: {
    ranges: [],
    rangesIpv6: [],
    opco: ''
  },
}

function mapRangePayload(range: string) {
  return {
    entry: range,
    editable: false
  }
}

function getRangePropertyForState(range: string) {
  return range === 'v4' ? 'ranges' : 'rangesIpv6'
}

export default function(state = initialState, action: OnnetOffnetAction) {
  switch (action.type) {
    case ONNET_OFFNET_SELECT_ENTRY: {
      const { index, range } = action.payload
      const property = getRangePropertyForState(range)
      state.results[property][index].editable = true

      return { ...state }
    }

    case ONNET_OFFNET_SAVE_ENTRY: {
      const { index, range, value } = action.payload
      const property = getRangePropertyForState(range)

      if(!state.results[property][index].newEntry) {
        state.results[property].push({
          entry: state.results[property][index].entry,
          editable: false,
          entryStatus: 'DELETE',
        })
      }

      state.results[property][index].editable = false
      state.results[property][index].entry = value
      state.results[property][index].entryStatus = 'ADD'

      return { ...state }
    }
  
    case ONNET_OFFNET_SAVE_NEW_ENTRY: {
      const { value, range } = action.payload
      const property = getRangePropertyForState(range)

      const newRow = {
        entry: value,
        entryStatus: 'ADD',
        editable: false,
        newEntry: true
      }

      state.results[property].unshift(newRow)

      return { ...state }
    }
  
    case ONNET_OFFNET_REMOVE_ENTRY: {
      const { index, range } = action.payload
      const property = getRangePropertyForState(range)

      if(!state.results[property][index].newEntry) {
        state.results[property][index].entryStatus = 'DELETE'
      } else {
        state.results[property].splice(index, 1)
      }

      return { ...state }
    }

    case ONNET_OFFNET_LIST_SUCCESS: {
      const data = action.payload[0]
      
      const results = {
        opco: data.opco,
        ranges: data.ranges.map(mapRangePayload),
        rangesIpv6: data['ranges-ipv6'].map(mapRangePayload),
      }
      
      return { ...state, results }
    }

    default: {
      return { ...state }
    }
  }
}
