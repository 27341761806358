export const VOD_FAILURE = 'VOD_FAILURE';
export const VOD_SUCCESS = 'VOD_SUCCESS';
export const VOD_SEARCH_LIST = 'VOD_SEARCH_LIST';
export const VOD_CLEAR_CACHE = 'VOD_CLEAR_CACHE';

export type VOD_FAILURE = typeof VOD_FAILURE;
export type VOD_SUCCESS = typeof VOD_SUCCESS;
export type VOD_CLEAR_CACHE = typeof VOD_CLEAR_CACHE;

export interface VODAction {
  type: VOD_FAILURE | VOD_SUCCESS | VOD_CLEAR_CACHE;
  payload?: any;
}

export interface VodItem {
  assetId: string;
  transactionId: string;
  assetTitle: string;
  assetType: string;
  purchaseDate: string;
  lastView?: string;
  expiryDate: string;
  duration: string;
  watched: string;
  endDate?: string;
}

export interface VodState {
  items?: Array<VodItem>;
  totalElements?: number;
}
