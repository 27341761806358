import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ApplicationState } from 'application/application-redux';
import { action, getNotifications, readAllNotifications, readNotification } from './redux/actions';
import { getRequests } from 'modules/management/spectrum/requests/redux/action';
import NotificationComponent from './notifications-component';

const mapStateToProps = (state: ApplicationState) => ({
  notification: state.notification,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  action,
  getNotifications,
  getRequests,
  push,
  readAllNotifications,
  readNotification
})(NotificationComponent);
