import {
  DEVICE_DETAIL_LOGS_CHANGE_PAGE,
  DEVICE_DETAIL_LOGS_CLEAR_CACHE,
  DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS,
  DEVICE_DETAIL_LOGS_HISTORY_SUCCESS,
  DEVICE_DETAIL_LOGS_RESET_URL,
  LogsHistoryAction,
  LogsHistoryState
} from './types';

const initialState: LogsHistoryState = {
  totalElements: undefined,
  items: undefined,
  itemsToShow: undefined,
  downloadLink: undefined,
  selectedItems: undefined
};

export default function(state = initialState, { type, payload }: LogsHistoryAction) {
  switch (type) {
    case DEVICE_DETAIL_LOGS_HISTORY_SUCCESS:
      const items = payload.data.records;

      return {
        ...state,
        totalElements: payload.data.recordCount,
        items: items,
        itemsToShow: items && items.slice(payload.start, payload.end)
      };

    case DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS:
      return {
        ...state,
        downloadLink: payload.data.downloadLink,
        selectedItems: payload.items
      };

    case DEVICE_DETAIL_LOGS_CHANGE_PAGE:
      const logsSliced = state.items && state.items.slice(payload.startItem, payload.endItem);

      return {
        ...state,
        itemsToShow: logsSliced
      };

    case DEVICE_DETAIL_LOGS_CLEAR_CACHE:
      return initialState;

    case DEVICE_DETAIL_LOGS_RESET_URL:
      state.downloadLink = undefined;
      state.selectedItems = undefined;
      return {
        ...state
      };
    default:
      return { ...state };
  }
}
