import mapper from './mapper';
import { addToaster } from 'core/toaster/redux/actions';
import {
    ChannelsAssociationsRegionsAction,
    ChannelsAssociationsRegions,
    ChannelsAssociationsRegionsState,
    CHANNEL_ASSOCIATIONS_REGIONS_GET_SUCCESS,
    CHANNEL_ASSOCIATIONS_REGIONS_GET_FAILURE,
    ChannelsRegionsState
} from "./type"

const initialState: ChannelsAssociationsRegionsState = {
  isActive: false,
  isFetching: false,
  filteredCount: 0,
  regions: []
};
const ChannelsRegionsStateInitialState: ChannelsRegionsState = {
  isActive: false,
  isFetching: false,
  filteredCount: 0,
  regions: []
};

export default function(state = initialState, action: ChannelsAssociationsRegionsAction) {
    switch (action.type) {
      case CHANNEL_ASSOCIATIONS_REGIONS_GET_SUCCESS: {
        const data = action.payload.data;
        const count = action.payload.filteredCount;
        return {
          ...state, filteredCount: count,
          regions: data.map((region: ChannelsAssociationsRegions) =>
            mapper(region, action.payload.t)
          ) 
        };
      }
      case CHANNEL_ASSOCIATIONS_REGIONS_GET_FAILURE:{
        return {
          ...state,
          filteredCount: 0,
          regions: []
        };
      }
      default:
        return { ...state };
    }
}