import React, { Component } from 'react';
import T from 'core/i18n/t';
import { ChevronDown } from 'assets/svg';
import { MenuItem, NavbarState } from 'application/header/navbar/redux/types';

interface TabProps {
  onChange: (tab: string) => void;
  tabs: Array<MenuItem>;
  tabSelected?: string;
  i18nkey?: string;
  getSubMenus?: any;
  navbar?: NavbarState;
  id?: string;
}

export default class TabComponent extends Component<TabProps> {
  onMouseLeaveTab(element: any) {
    const { id, getSubMenus } = this.props;
    while (element) {
      if (element.classList.contains('nav')) {
        return;
      }
      element = element.parentElement;
    }

    getSubMenus(id);
  }

  isToSelectParent(menu: MenuItem, tab: string) {
    const navWithSubtabs = menu.subtabs?.find((element) => element === tab);
    return navWithSubtabs !== undefined;
  }

  render() {
    const { onChange, getSubMenus, tabs, tabSelected, i18nkey, id, navbar } = this.props;
    const nav = navbar && id && navbar.navbar.get(id);

    return (
      <div className="tabs is-medium has-margin-bottom-none">
        <ul>
          {tabs &&
            nav &&
            tabs.map((tab, key) => {
              const hasSubtabs = tab.hasOwnProperty('subtabs');
              return (
                <li
                  key={key}
                  className={
                    tabSelected &&
                    (tabSelected === tab.value || this.isToSelectParent(tab, tabSelected))
                      ? 'is-active'
                      : ''
                  }
                >
                  <button
                    className="button-link"
                    onClick={() => !hasSubtabs && onChange(tab.value)}
                    onMouseEnter={() => hasSubtabs && getSubMenus(id, tab.value)}
                    onMouseLeave={(event: any) => this.onMouseLeaveTab(event.target)}
                  >
                    <T>{`${i18nkey}.menu_${tab.value}`}</T>
                    {hasSubtabs && (
                      <ChevronDown className="icon-svg icon-tiny has-margin-left-sm" />
                    )}
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}
