export const NAVBAR_CHANGE_CURRENT_TAB = 'NAVBAR_CHANGE_CURRENT_TAB';
export const NAVBAR_GET_SELECTED_SUBTABS = 'NAVBAR_GET_SELECTED_SUBTABS';

export interface NavbarAction {
  type: string;
  payload: any;
}

export interface NavbarState {
  navbar: Map<string, Navbar>;
}

export interface Navbar {
  available: MenuItem[];
  current: MenuItem;
  subtabstoshow?: MenuItem;
  i18nkey?: string;
}

export interface MenuItem {
  name: string;
  value: string;
  subtabs?: string[];
}
