import moment from 'moment';

import {
  FREQUENCY_HISTORY,
  FREQUENCY_SCAN_FAILURE,
  FREQUENCY_SCAN_HISTORY_REQUEST,
  TOGGLE_ACCORDION,
  RF_TUNING_STATUS_FAILURE,
  RF_TUNING_STATUS,
  RF_TUNING_STATUS_SUCCESS,
  RF_TUNING_SELECT_FREQUENCY_TAB,
  RF_TUNING_STATUS_THRESHOLDS_SUCCESS,
  RF_TUNING_SET_SHOW_GRAPHIC,
  RfTuningStatusState,
  RfTuningHistoryResult,
  RfTuningStatusResult,
  dropdownLabel,
  RF_TUNING_STATUS_DVB_TYPES_SUCCESS,
  RF_TUNING_FORCE_CLEAR_CACHE,
  RF_TUNING_WAITING_COMMAND
} from './types';

import mapper from './mapper';

interface RfTuningStatusAction {
  type: string;
  payload: any;
}

const initialState: RfTuningStatusState = {
  results: [],
  allResults: [],
  lastResultDate: moment(),
  activityLog: null,
  currentFrequencyTab: '',
  currentGraphic: 'rf_tuning.full_frequency_pwr',
  frequencyListToShow: [],
  frequencyLimits: {},
  resultToGraph: {
    labels: [],
    datasets: [
      {
        backgroundColor: '#666666',
        borderColor: '#B5B5B5',
        borderWidth: 3,
        borderSkipped: 'right',
        data: [
          // -Math.log(Math.pow(Math.E, -9)),
        ]
      }
    ]
  },
  resultHistory: [
    {
      frequencyHistories: [
        {
          TSID: '',
          LF: '',
          LS: '',
          PWR: '',
          BERPO: '',
          modulation: '',
          type: ''
        }
      ],
      finishedAt: '',
      accordionActive: false,
      accrodionIndex: 0,
      totalElements: 0
    }
  ],
  waitingCommandExecution: false
};

function getColumnFromKey(column: string) {
  const columnSplited = column.split('_');
  return columnSplited[columnSplited.length - 1].toUpperCase();
}

function compareOnColumn(column: string) {
  return function compare(a: any, b: any) {
    if (a[column] < b[column]) {
      return -1;
    }
    if (a[column] > b[column]) {
      return 1;
    }
    return 0;
  };
}

function getNewGraphToShow(state: RfTuningStatusState, graphicToShow: string) {
  let resultToGraph;
  const dataGraph: Array<any> = [];
  const labelGraph: Array<any> = [];

  if (state.currentFrequencyTab.includes('dvbc')) {
    resultToGraph = state.allResults.DVBC;
  } else if (state.currentFrequencyTab.includes('dvbt')) {
    resultToGraph = state.allResults.DVBT;
  }

  for (let i = 0; i < resultToGraph.length; i++) {
    if (graphicToShow.includes('berpo')) {
      dataGraph.push(resultToGraph[i][getColumnFromKey(graphicToShow)].split('-')[1]);
    } else {
      dataGraph.push(resultToGraph[i][getColumnFromKey(graphicToShow)]);
    }
    labelGraph.push(resultToGraph[i].LF);
  }

  const currentResultToGraph = state.resultToGraph;
  currentResultToGraph.datasets[0].data = dataGraph;
  currentResultToGraph.labels = labelGraph;
  return currentResultToGraph;
}

export function getHistoryToShow(state: RfTuningStatusState) {
  const resultHistoryToShow: Array<RfTuningHistoryResult> = [];
  const { resultHistory } = state;

  for (let i = 0; i < resultHistory.length; i++) {
    const { frequencyHistories } = resultHistory[i];
    const frequencyHistoriesToShow: Array<RfTuningStatusResult> = [];

    if (frequencyHistories) {
      for (let j = 0; j < frequencyHistories.length; j++) {
        if (state.currentFrequencyTab.includes(frequencyHistories[j].type.toLowerCase())) {
          frequencyHistoriesToShow.push(frequencyHistories[j]);
        }
      }
      resultHistoryToShow.push({
        frequencyHistories: frequencyHistoriesToShow.sort(compareOnColumn('LF')),
        accordionActive: false,
        accrodionIndex: i,
        totalElements: resultHistory[0].totalElements,
        finishedAt: resultHistory[i].finishedAt
      });
    }
  }

  return resultHistoryToShow;
}

export default function (state = initialState, { payload, type }: RfTuningStatusAction) {
  switch (type) {
    case RF_TUNING_WAITING_COMMAND: {
      return { ...state, waitingCommandExecution: payload };
    }

    case RF_TUNING_FORCE_CLEAR_CACHE:
      return { ...initialState };

    case FREQUENCY_HISTORY:
    case FREQUENCY_SCAN_FAILURE:
    case RF_TUNING_STATUS_FAILURE: {
      return {
        ...state
      };
    }

    case FREQUENCY_SCAN_HISTORY_REQUEST: {
      let index = 0;
      payload.forEach((obj: any) => {
        obj.accrodionIndex = index;
        obj.accordionActive = false;
        index++;
      });

      payload.forEach((scan: any) => {
        for (let i = 0; i < scan.frequencyHistories.length; i++) {
          scan.frequencyHistories[i] = mapper(scan.frequencyHistories[i]);
        }
      });

      state.resultHistory = payload;
      if (state.currentFrequencyTab.includes('history') && state.resultHistory) {
        state.resultHistoryToShow = getHistoryToShow(state);
      }

      return {
        ...state
      };
    }

    case RF_TUNING_STATUS_SUCCESS: {
      let { frequencyListToShow } = state;

      if (payload.response.message) {
        Object.keys(payload.response.message).forEach((key: any) => {
          for (let i = 0; i < payload.response.message[key].length; i++) {
            payload.response.message[key][i] = mapper(payload.response.message[key][i]);
          }
        });
        if (Object.keys(payload.response.message).length > 0) {
          frequencyListToShow = new Array<string>();
        }
        for (const prop in payload.response.message) {
          frequencyListToShow.push(dropdownLabel[`dropdown_${prop.toLowerCase()}`]);
          frequencyListToShow.push(dropdownLabel[`dropdown_${prop.toLowerCase()}_graphic`]);
          frequencyListToShow.push(dropdownLabel[`dropdown_${prop.toLowerCase()}_history`]);
          payload.response.message[prop].sort(compareOnColumn('LF'));
        }
        state.allResults = payload.response.message;

        if (state.currentFrequencyTab.includes('dvbc')) {
          state.results = payload.response.message.DVBC;
        } else if (state.currentFrequencyTab.includes('dvbt')) {
          state.results = payload.response.message.DVBT;
        }
      } 
      return {
        ...state,
        frequencyListToShow,
        activityLog: {
          udid: payload.deviceId,
          status: payload.status,
          eventName: payload.name,
          lastManaged: payload.startDate,
          commandTraceId: payload.transactionID,
          logResponse: payload.response.message,
          logRequest: payload.request.message,
          progress: payload.request.progress ? payload.request.progress.replace('%', '') : null,
          error: payload.response.errorText
        }
      };
    }

    case RF_TUNING_SELECT_FREQUENCY_TAB: {
      state.currentFrequencyTab = payload;

      if (payload.includes('dvbc')) {
        state.results = state.allResults.DVBC;
      } else if (payload.includes('dvbt')) {
        state.results = state.allResults.DVBT;
      }

      if (state.allResults.length === 0) {
        state.results = [];
      }

      if (payload.includes('graphic') && state.results) {
        state.resultToGraph = getNewGraphToShow(state, state.currentGraphic);
      }

      if (state.currentFrequencyTab.includes('history') && state.resultHistoryToShow) {
        state.resultHistoryToShow = getHistoryToShow(state);
      }

      return { ...state };
    }

    case RF_TUNING_STATUS_THRESHOLDS_SUCCESS: {
      return { ...state, frequencyLimits: payload };
    }

    case RF_TUNING_STATUS_DVB_TYPES_SUCCESS: {
      const frequencyListToShow = new Array<string>();

      payload.forEach((type: string) => {
        frequencyListToShow.push(dropdownLabel[`dropdown_${type.toLowerCase()}`]);
        frequencyListToShow.push(dropdownLabel[`dropdown_${type.toLowerCase()}_history`]);
        if (state.results.length) {
          frequencyListToShow.push(dropdownLabel[`dropdown_${type.toLowerCase()}_graphic`]);
        }
      });

      const currentFrequencyTab = payload[0]
        ? dropdownLabel[`dropdown_${payload[0].toLowerCase()}`]
        : dropdownLabel.dropdown_dvbc;

      return { ...state, frequencyListToShow, currentFrequencyTab };
    }

    case RF_TUNING_SET_SHOW_GRAPHIC: {
      return {
        ...state,
        currentGraphic: payload,
        resultToGraph: getNewGraphToShow(state, payload)
      };
    }

    case TOGGLE_ACCORDION: {
      let results: RfTuningHistoryResult[] = [];

      if (state.resultHistoryToShow) {
        results = Object.values(state.resultHistoryToShow);
        results.forEach((obj: any) => {
          if (obj.accrodionIndex === payload) {
            obj.accordionActive = !obj.accordionActive;
          } else {
            obj.accordionActive = false;
          }
        });
      }

      return {
        ...state,
        resultHistoryToShow: results
      };
    }

    case RF_TUNING_STATUS: {
      return {
        ...state,
        activityLog: {
          ...state.activityLog,
          udid: payload.deviceId,
          status: payload.status,
          eventName: payload.name,
          lastManaged: payload.startDate,
          commandTraceId: payload.transactionID,
          logResponse: payload.response.parameters,
          logRequest: payload.request.parameters,
          progress: payload.request.progress
            ? payload.request.progress.replace('%', '')
            : state.activityLog && state.activityLog.progress
        }
      };
    }

    default: {
      return { ...state };
    }
  }
}
