import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import AuthService from 'core/auth/auth-service';
import { addToaster } from 'core/toaster/redux/actions';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import queryString from 'core/utils/query-string';
import { TFunction } from 'i18next';
import { paginationSpeedTest } from 'core/pagination-optimized/redux/config';
import { goToFirstPage } from 'core/pagination-optimized/redux/actions';
import {
  SPEED_TEST_HISTORY_INFO,
  SPEED_TEST_SUCCESS,
  SPEED_TEST_HISTORY_SUCCESS,
  SPEED_TEST_FAILURE,
  SPEED_TEST_INFO,
  SPEED_TEST_CLEAR_CACHE,
  SPEED_TEST_WAITING_COMMAND,
  SpeedTestAction,
  SpeedTestItem
} from './types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

let timerTimeout: any;
let timerInterval: any;

function waitingCommand(payload: any) {
  return {
    type: SPEED_TEST_WAITING_COMMAND,
    payload
  };
}

export function clearCacheSpeedTest() {
  return {
    type: SPEED_TEST_CLEAR_CACHE
  };
}

function success(payload: any) {
  return {
    type: SPEED_TEST_SUCCESS,
    payload
  };
}

function successFetch(payload: Array<SpeedTestItem>): SpeedTestAction {
  return {
    type: SPEED_TEST_HISTORY_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: SPEED_TEST_FAILURE,
    payload
  };
}

export function stopSpeedTest() {
  return async (dispatch: any) => {
    clearInterval(timerInterval);
    clearTimeout(timerTimeout);
    dispatch(waitingCommand(false));
    dispatch(remove(SPEED_TEST_INFO));
    return dispatch(failure({}));
  };
}

function isFinal(status: string) {
  switch (status) {
    case 'COMPLETED':
    case 'SUCCEEDED':
    case 'FAILED':
    case 'TIME_EXCEEDED':
    case 'CANCELED':
    case 'REJECTED':
      return true;
    default:
      return false;
  }
}

function doFetchSpeedTestStatus(dispatch: any, id: string, jobId: string, t: TFunction) {
  apiClient
    .get(`${envs.REACT_APP_API_URL}/command/runSpeedTest/device/${id}/status`, {
      headers: { transactionID: jobId }
    })
    .then(
      secondResponse => {
        let initialStatus: any = { ...secondResponse.data };

        if (isFinal(secondResponse.data.status)) {
          initialStatus = { ...secondResponse.data, request: { message: {}, progress: '0%' } };

          if (secondResponse.data.status === 'TIME_EXCEEDED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_speed_test',
                message: 'error_messages.VIPOC_ERROR_042',
                type: 'danger'
              })
            );
          } else if (secondResponse.data.status === 'FAILED') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_speed_test',
                message: secondResponse.data.response.errorText,
                type: 'danger'
              })
            );

            dispatch(success(initialStatus));
          } else if (
            secondResponse.data.status === 'SUCCEEDED' ||
            secondResponse.data.status === 'COMPLETED'
          ) {
            dispatch(goToFirstPage('speed_test'));
            dispatch(fetch(id, t, paginationSpeedTest));
          }

          clearInterval(timerInterval);
          clearTimeout(timerTimeout);
          dispatch(waitingCommand(false));
        }

        dispatch(remove(SPEED_TEST_INFO));
        return dispatch(success(initialStatus));
      },
      secondRejection => {
        dispatch(remove(SPEED_TEST_INFO));
        return dispatch(failure(secondRejection));
      }
    );
}

export function fetchSpeedTestStatus(id: string, jobId: string, t: TFunction) {
  return async (dispatch: any) => {
    timerTimeout = setTimeout(() => {
      clearInterval(timerInterval);
      dispatch(waitingCommand(false));
      dispatch(remove(SPEED_TEST_INFO));
    }, 1900000);
    doFetchSpeedTestStatus(dispatch, id, jobId, t);
    timerInterval = setInterval(() => doFetchSpeedTestStatus(dispatch, id, jobId, t), 5000);
  };
}

export function fetchSpeedTest(id: string, t: TFunction) {
  return async (dispatch: any) => {
    dispatch(add(SPEED_TEST_INFO, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: 'runSpeedTest',
        deviceId: id
      })
      .then(
        response => {
          if (response.data.httpCode === '400') {
            handleCommandError(
              dispatch,
              response.data.responseJson.jobId,
              'all_device_pages.menu_speed_test',
              t,
              SPEED_TEST_INFO,
              waitingCommand
            );
          } else {
            dispatch(waitingCommand(true));
            dispatch(fetchSpeedTestStatus(id, response.data.responseJson.jobId, t));
          }
        },
        rejection => {
          dispatch(remove(SPEED_TEST_INFO));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? t(rejection)
              : t('error_messages.VIPOC_ERROR_021');
          dispatch(
            addToaster({
              title: t('all_device_pages.menu_speed_test'),
              message,
              type: 'danger'
            })
          );
          dispatch(waitingCommand(false));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function cancelCommand(traceId: string, deviceId: string, t: TFunction) {
  return async (dispatch: any) => {
    dispatch(add(SPEED_TEST_INFO, {}));
    await AuthService.refreshToken();
    apiClient
      .post(`${envs.REACT_APP_API_URL}/device/${deviceId}/activity-log`, {
        deviceId,
        traceId
      })
      .then(
        () => {},
        rejection => {
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_033';
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_activity_log',
              message,
              type: 'danger'
            })
          );
        }
      );
  };
}

export function fetch(id: string, t: TFunction, filter?: PaginationConfig, comparator?: string) {
  return async function(dispatch: any, getState: any) {
    dispatch(add(SPEED_TEST_HISTORY_INFO, {}));
    await AuthService.refreshToken();
    let numberComparator = '';
    const sendComparator = filter && Object.values(filter).every(elem => elem !== '');
    if (comparator && sendComparator) {
      numberComparator = `&compare=${comparator}`;
    }
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/device/${id}/speed-test/history?${queryString(
          filter,
          getState().application.offset
        ) + numberComparator}`
      )
      .then(
        response => {
          dispatch(remove(SPEED_TEST_HISTORY_INFO));
          dispatch(successFetch(response.data));
        },
        rejection => {
          dispatch(
            addToaster({
              title: t('all_device_pages.menu_speed_test'),
              message: t('error_messages.VIPOC_ERROR_031'),
              type: 'danger'
            })
          );
          dispatch(remove(SPEED_TEST_HISTORY_INFO));
          return dispatch(failure(rejection));
        }
      );
  };
}
