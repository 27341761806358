import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import ProvisionedDevicesComponent from './provisioned-devices-component';
import { changePageProvDevices, fetchProvisionedDevices, onDelete } from './redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  provisionedDevices: state.devices.detail.provisionedDevices,
  t: state.i18n.t,
  pagination: state.pagination
});

export default connect(mapStateToProps, {
  fetchProvisionedDevices,
  onDelete,
  changePageProvDevices,
})(ProvisionedDevicesComponent);
