import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import LogsComponent from './logs-component';
import { runCommands, updateLogs, resetLogs, updateFlushLogs, resetFlushLogs } from '../redux/actions';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  settings: state.devices.detail._settings,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  runCommands,
  updateLogs,
  updateFlushLogs,
  resetLogs,
  resetFlushLogs,
  openModal,
  closeModal
})(LogsComponent);
