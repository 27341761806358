import { ApplicationInfoState } from './../../../../../application/application-redux';
import { ApplicationState } from 'application/application-redux';
import apiClient from 'core/api';
import authService from 'core/auth';
import { DecodedUser } from 'core/auth/auth-service';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { buildRequestObject, getChanges } from '../../dvb-management/redux/action';
import { DVB_COLUMNS, DVB_COLUMNS_ORDER } from '../../dvb-management/redux/enums';
import { selectNetwork } from '../../dvb-management/redux/redux-networks/action';
import { selectNode } from '../../dvb-management/redux/redux-nodes/action';
import { selectRegion } from '../../dvb-management/redux/redux-regions/action';
import { selectStream } from '../../dvb-management/redux/redux-streams/action';
import { DVB_MANAGEMENT_LOADING_TOGGLE } from '../../dvb-management/redux/types';
import queryString, { createArrayToQueryString } from 'core/utils/query-string';

import {
  RequestReply,
  RequestsAction,
  RequestsMainState,
  REQUESTS_GET_REQUESTS,
  REQUESTS_REPLY_REQUEST,
  REQUESTS_SET_REQUEST
} from './types';
import { envs } from 'application/envHandler';

export interface SpectrumRequestsParam {
  requestId?: string,
  column?: any,
  id?: string,
  request?: RequestsMainState, 
  pageNumber?:number,
  isSearch?: boolean
}

function getSelectFunction(column: DVB_COLUMNS, id: string) {
  const parentColumn = DVB_COLUMNS_ORDER[DVB_COLUMNS_ORDER.indexOf(column) - 1];

  switch (parentColumn) {
    case undefined:
    case DVB_COLUMNS.REGION: {
      return selectRegion(id);
    }

    case DVB_COLUMNS.NODE: {
      return selectNode(id);
    }

    case DVB_COLUMNS.NETWORK: {
      return selectNetwork(id);
    }

    case DVB_COLUMNS.STREAM: {
      return selectStream(id);
    }
  }
}

function isSuperUser(user: DecodedUser) {
  return (
    user.groups.find(
      (it) =>
        it.prefix == 'module' &&
        it.moduleName == 'spectrum' &&
        it.role == 'su' &&
        it.opco == authService.getCurrentOpco()
    ) != undefined
  );
}
export function action(type: any, payload?: any): RequestsAction {
  return {
    type: type,
    payload: payload
  };
}
export function allRequestChanges ( requestId?: string) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    await authService.refreshToken();
    const opco = authService.getCurrentOpco();
    const user = authService.getCurrentUser();
    const requestId = getState().management.spectrum.requests.selectedRequest?.requestId;
    return {
      user: user,
      opco: opco, 
      requestId: requestId

    };
  }}

export function getRequests(params?: SpectrumRequestsParam) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    dispatch(action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: true, column: DVB_COLUMNS.REGION }));
    await authService.refreshToken();
    const t = getState().i18n.t;
    const opco = authService.getCurrentOpco();
    let pageIndex = params?.request ? params.request.index : "";
    const pageSize = params?.request ? params?.request.pagination.pageSize : 10;
    const pageNumberRequest = params?.pageNumber ? params.pageNumber : 0;
    const requestId = getState().management.spectrum.requests.selectedRequest?.requestId;

    if(params?.request && params.request.listIndex){
      if(Object.keys(params.request.listIndex).find((e:any) => pageNumberRequest == e) ){
      pageIndex = params.request.listIndex[pageNumberRequest]
      }
    }

    const user = authService.getCurrentUser();
    const searchItem = getState().multipleSearch.multipleFilters.get('spectrum.requests');
    const newFilters = searchItem && createArrayToQueryString(searchItem.selectedFilters);
    const queryFilter =  queryString(newFilters, getState().application.offset);
    
    const urlchange = `${envs.REACT_APP_API_URL}/be/spectrum/allRequestChanges?user=${user.username}&opco=${opco}&requestId=${requestId}`;  

    const urlRequest = `${
      envs.REACT_APP_API_URL
    }/be/spectrum/requests?pageIndex=${pageIndex}&pageSize=${pageSize}&opco=vfde&user=${
      user.username
    }&super=${isSuperUser(user)}&${queryFilter}`;
    return apiClient
      .get(params?.requestId ? urlchange : urlRequest)
      .then(
        (response) => {
          if (params?.requestId) {
            dispatch(action(REQUESTS_SET_REQUEST, { data: response.data }));
          } else {
            dispatch(action(REQUESTS_GET_REQUESTS, { data: response.data.results, totalCount:response.data.totalCount, index:response.data.index, pageNumber: pageNumberRequest, isSearch: params?.isSearch}));
          }
        },
        () => {
          dispatch(
            addToaster({
              title: t('spectrum.requests.error.title'),
              message: t('spectrum.requests.error.get_requests.message'),
              type: 'danger'
            })
          );
        }
      )
      .finally(() => {
        if (params?.requestId) {
          dispatch(buildRequestObject());

          if (params.id && params.column && params.column !== DVB_COLUMNS.NONE) {
            dispatch(getSelectFunction(params.column, params.id));
          }
        }
        dispatch(
          action(DVB_MANAGEMENT_LOADING_TOGGLE, { isActive: false, column: DVB_COLUMNS.REGION })
        );
      });
  };
}



export function replyRequest(requestId: string, type: RequestReply) {
  return async function (dispatch: Function, getState: () => ApplicationState) {
    dispatch(add(REQUESTS_REPLY_REQUEST, {}));
    await authService.refreshToken();
    const isApproved = type === RequestReply.APPROVE;
    const isCanceled = type === RequestReply.CANCEL;
    const t = getState().i18n.t;

    return apiClient
      .post(`${envs.REACT_APP_API_URL}/spectrum/replyRequest`, {
        requestId,
        approved: isApproved,
        canceled: isCanceled
      })
      .then(
        () => {
          let typeMessage = t('spectrum.requests.status_approved');

          if (!isApproved) {
            typeMessage = isCanceled
              ? t('spectrum.requests.status_canceled')
              : t('spectrum.requests.status_rejected');
          }

          dispatch(
            addToaster({
              title: t('spectrum.requests.success.title'),
              message: t('spectrum.requests.success.message').replace(
                '{0}',
                typeMessage.toLowerCase()
              ),
              type: 'success'
            })
          );
          dispatch(getRequests());
        },
        () => {
          dispatch(
            addToaster({
              title: t('spectrum.requests.error.title'),
              message: t('spectrum.requests.error.message').replace(
                '{0}',
                t('spectrum.requests.status_approved').toLowerCase()
              ),
              type: 'danger'
            })
          );
          dispatch(getRequests());
        }
      )
      .finally(() => {
        dispatch(remove(REQUESTS_REPLY_REQUEST));
        dispatch(getRequests());
      });
  };
}
