import React, { Component } from 'react';
import T from 'core/i18n/t';
import { TFunction } from 'i18next';
import PopUpComponent from '../../../../core/pop-up';

interface UploadModalComponentProps {
  isActive: boolean;
  title: string;
  content: string;
  execute: any;
  uploadFile: any;
  setFileValue: any;
  disabledUpload: boolean;
  dataUpload: FormData;
  fileNameToUpload: string;

  openModal: any;
  closeModal: any;
  t: TFunction;
}

export default class ModalIpGeolocationComponent extends Component<UploadModalComponentProps> {
  private async sendFile() {
    this.props.uploadFile(this.props.dataUpload);
    this.props.closeModal();
  }

  onChangeFileUpload(event: any) {
    this.props.dataUpload.delete('file');
    this.props.dataUpload.append('file', event.target.files[event.target.files.length - 1]);
    event.target.value = null;
    this.props.setFileValue(this.props.dataUpload);
  }

  render() {
    const { isActive, t } = this.props;
    return (
      <PopUpComponent isActive={isActive}>
        <h4 className="title is-4"> {t(this.props.title)}</h4>
        <div className="content">
          <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
            {t('ip_whitelist.label_bulk_insert')}
          </h3>

          <div className="column is-2 control" style={{ marginTop: '15px', marginLeft: '-12px' }}>
            <div className="is-flex">
              <div className="file has-name is-primary" style={{ display: 'inline-block' }}>
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    onChange={event => this.onChangeFileUpload(event)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="mdi mdi-file-document" />
                    </span>
                    <span className="file-label">{t('ip_geolocation.label_choose_file')}</span>
                  </span>
                  <span className="file-name" style={{ maxWidth: '9em' }}>
                    {this.props.fileNameToUpload}
                  </span>
                </label>
              </div>

              <div
                className="file is-primary"
                style={{ display: 'inline-block', marginLeft: '30px' }}
              ></div>
            </div>
          </div>
        </div>

        <div className="modal-buttons">
          <button
            className="button is-primary"
            disabled={this.props.disabledUpload}
            onClick={() => this.sendFile()}
          >
            {t('id_mapping.label_upload')}
            <span className="icon">
              <i className="mdi mdi-cloud-upload" />
            </span>
          </button>
          <button className="button is-secondary" onClick={this.props.closeModal}>
            <T>general.cancel</T>
          </button>
        </div>
      </PopUpComponent>
    );
  }
}
