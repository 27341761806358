import * as React from 'react';
import ModalUploadIpGeolocationContainer from './modal-upload';
import IpGeolocationGridContainer from './grid/ip-geolocation-grid-container';
import PaginationComponent from '../../../core/pagination';
import { IpGeolocationResult } from './grid/redux/reducer';
import { PaginationInfo, PaginationRequest } from '../../../core/pagination/pagination-component';
import { Upload, Download } from '../../../assets/svg/index';
import AuthService from '../../../core/auth/auth-service';
import { TFunction } from 'i18next';

interface IpGeolocationComponentProps {
  exportData: any;
  openModal: any;
  closeModal: any;
  execute: any;
  onFilter: any;
  results: IpGeolocationResult;
  t: TFunction;
}

export default class IpGeolocationComponent extends React.Component<
  IpGeolocationComponentProps,
  any
> {
  public onUpload() {
    this.props.openModal({
      title: 'ip_geolocation.button_upload_file',
      active: true,
      execute: () => this.props.closeModal(),
      disabledUpload: true,
      dataUpload: new FormData(),
      fileNameToUpload: ''
    });
  }

  public render() {
    const { t } = this.props;
    const currentUser = AuthService.getCurrentUser();
    const currentOpco = currentUser.opco;
    const writePermission = currentUser['cognito:groups'].includes(
      'module.ip-geolocation.write.' + currentOpco
    );

    const paginationInfo: PaginationInfo = {
      currentPage: this.props.results.number,
      first: this.props.results.number === 0,
      last: this.props.results.number === this.props.results.totalPages - 1,
      numberOfElements: this.props.results.numberOfElements,
      totalElements: this.props.results.totalElements,
      totalPages: this.props.results.totalPages,
      size: this.props.results.size
    };

    return (
      <div className="section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-child">
              <div className="section">
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      onChange={event => this.props.onFilter(event.target.value)}
                      placeholder={'Type the ip address filter'}
                    />
                  </div>

                  <div className="control column-rounded is-3">
                    <button
                      className="button is-primary  has-margin-left-none"
                      onClick={() => this.props.execute()}
                    >
                      <span>{t('general.search')}</span>
                      <span className="icon">
                        <i className="mdi mdi-magnify" />
                      </span>
                    </button>

                    <button
                      disabled={!writePermission}
                      title={
                        !writePermission
                          ? `${t('ip_geolocation.label_no_permission_to_upload')}`
                          : `${t('ip_geolocation.button_upload_file')}`
                      }
                      className="button is-dark has-margin-left-none"
                      style={{ width: '10em' }}
                      onClick={() => this.onUpload()}
                    >
                      <span>{t('ip_geolocation.button_upload_file')}</span>
                      <Upload className={'icon icon-svg icon-vcentered icon-white'} />
                    </button>

                    <button
                      className="button is-black has-margin-left-none"
                      onClick={() => this.props.exportData()}
                    >
                      {t('ip_whitelist.button_download_file')}
                      <Download className={'icon icon-svg  icon-vcentered  icon-white'} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="section">
                <IpGeolocationGridContainer />
                {this.props.results &&
                  this.props.results.content &&
                  this.props.results.totalElements !== 0 && (
                    <PaginationComponent
                      start={0}
                      info={paginationInfo}
                      onChange={(request: PaginationRequest) => this.props.execute(request)}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>

        <ModalUploadIpGeolocationContainer />
      </div>
    );
  }
}
