import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import { saveChanges, setScheduleDate, submitRequest } from '../redux/action';
import { action, selectDropdown, togglePopUp } from './redux/action';
import DVBManagementPopUpComponent from './dvb-management-popUp-component';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  dvbManagement: state.management.spectrum.dvbManagement,
  dvbConstants: ownProps.constants,
  popUp: ownProps.popUp,
  requests: state.management.spectrum.requests,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  action,
  selectDropdown,
  saveChanges,
  setScheduleDate,
  submitRequest,
  togglePopUp
})(DVBManagementPopUpComponent);
