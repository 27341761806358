import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import AuthService from 'core/auth';
import queryString from 'core/utils/query-string';
import { addToaster } from 'core/toaster/redux/actions';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';

import {
  SUBSCRIPTIONS_FAILURE,
  SUBSCRIPTIONS_SEARCH,
  SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_CLEAR_CACHE,
  SubscriptionAction
} from './types';
import { ApplicationState } from 'application/application-redux';
import { envs } from 'application/envHandler';

function successSubscriptions(payload: any): SubscriptionAction {
  return {
    type: SUBSCRIPTIONS_SUCCESS,
    payload
  };
}

function failure(payload: any): SubscriptionAction {
  return {
    type: SUBSCRIPTIONS_FAILURE,
    payload
  };
}

export function clearCacheSubscriptions(): SubscriptionAction {
  return {
    type: SUBSCRIPTIONS_CLEAR_CACHE
  };
}

export function fetchSubscriptions(hhid: string, filter?: PaginationConfig) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(SUBSCRIPTIONS_SEARCH, {}));
    await AuthService.refreshToken();
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/hhid/${hhid}/account/subscriptions?${queryString(
          filter,
          getState().application.offset
        )}`
      )
      .then(
        response => {
          dispatch(remove(SUBSCRIPTIONS_SEARCH));
          return dispatch(successSubscriptions(response.data));
        },
        rejection => {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_account',
              message: 'error_messages.VIPOC_ERROR_016',
              type: 'danger'
            })
          );
          dispatch(remove(SUBSCRIPTIONS_SEARCH));
          return dispatch(failure(rejection));
        }
      );
  };
}
