import { TFunction } from 'i18next';
import { DeviceShadow, DeviceDetailSettingParameter, DeviceSettingAssetsValues } from '../types';

import {
  audiovolume,
  priaudiolang,
  subtitles,
  prisubtitlelang,
  uilang,
  hdmimode,
  hdmires,
  hdmiaudiomode,
  hearingimpsubs,
  visualimpaudio,
  standbymode,
  activestandbytimer,
  changeChannelVisibility,
  localPaddingTime,
  parentalMode,
  parentalPinValue
} from './settings-actions';

export function getSettingExecutorsMap() {
  const settingsModifierActionsMap = new Map<string, any>();
  settingsModifierActionsMap.set('audiostate', audiovolume);
  settingsModifierActionsMap.set('audiovolume', audiovolume);
  settingsModifierActionsMap.set('priaudiolang', priaudiolang);
  // settingsModifierActionsMap.set('secaudiolang', priaudiolang);
  settingsModifierActionsMap.set('subtitles', subtitles);
  settingsModifierActionsMap.set('prisubtitlelang', prisubtitlelang);
  // settingsModifierActionsMap.set('secsubtitlelang', prisubtitlelang);
  settingsModifierActionsMap.set('uilang', uilang);

  settingsModifierActionsMap.set('hdmimode', hdmimode);
  settingsModifierActionsMap.set('hdmires', hdmires);
  settingsModifierActionsMap.set('hdmiaudiomode', hdmiaudiomode);

  settingsModifierActionsMap.set('hearingimpsubs', hearingimpsubs);
  settingsModifierActionsMap.set('visualimpaudio', visualimpaudio);

  settingsModifierActionsMap.set('standbymode', standbymode);
  settingsModifierActionsMap.set('activestandbytimer', activestandbytimer);

  settingsModifierActionsMap.set('unsubchannels', changeChannelVisibility);
  settingsModifierActionsMap.set('cpfilter', changeChannelVisibility);
  settingsModifierActionsMap.set('localPaddingTime', localPaddingTime);

  settingsModifierActionsMap.set('parentalModeVisibility', parentalMode);
  settingsModifierActionsMap.set('parentalModeRating', parentalMode);
  settingsModifierActionsMap.set('parentalPinValue', parentalPinValue);
  return settingsModifierActionsMap;
}

export default function (
  t: TFunction,
  shadow: DeviceShadow,
  assetsValues: Array<DeviceSettingAssetsValues>
) {
  function defaultLanguageList() {
    const values = [];
    values.push({ label: t('settings.label_select'), value: '', active: false });
    values.push({ label: t('settings.label_english'), value: 'en-GB', active: true });
    values.push({ label: t('settings.label_german'), value: 'de-DE', active: true });
    return values;
  }

  function defaultParentalMode() {
    const values = [];
    values.push({ label: t('settings.label_parental_rating.8'), value: 8, active: true });
    values.push({ label: t('settings.label_parental_rating.10'), value: 10, active: true });
    values.push({ label: t('settings.label_parental_rating.12'), value: 12, active: true });
    values.push({ label: t('settings.label_parental_rating.14'), value: 14, active: true });
    values.push({ label: t('settings.label_parental_rating.16'), value: 16, active: true });
    values.push({ label: t('settings.label_parental_rating.18'), value: 18, active: true });
    return values;
  }

  function getTranslatedMeasure(word: string, t: TFunction) {
    if (word) {
      const keyToTranslate = word.split(' ')[1]; // hrs
      if (keyToTranslate) {
        return word.split(' ')[0] + ' ' + t(keyToTranslate);
      } else {
        return t(word);
      }
    }
    return word;
  }

  const settingsMap = new Map<string, DeviceDetailSettingParameter>();
  const config =
    shadow && shadow.reported && shadow.reported.config ? shadow.reported.config : null;
  // ******************** Audio And Subtitles ***********

  settingsMap.set('priaudiolang', {
    label: t('settings.label_primary_aud'),
    type: 'list',
    value: config && config.pAuLg ? config.pAuLg : '',
    available: (
      assetsValues.length > 0
        ? !assetsValues.find((assetValue) => assetValue.assetName === 'setPreferredAudioLanguage')
        : []
    )
      ? defaultLanguageList()
      : assetsValues
          .filter((assetValue) => assetValue.assetName === 'setPreferredAudioLanguage')
          .map((assetValue) => ({ label: assetValue.label, value: assetValue.value })),
    category: 'audioAndSubtitles'
  });

  /*
  settingsMap.set('secaudiolang', {
    label: 'Secondary Audio Language',
    type: 'list',
    value: config && config.sAuLg ? config.sAuLg : '',
    available: defaultLanguageList(t),
    category: 'audioAndSubtitles'
  });
  */

  settingsMap.set('subtitles', {
    label: t('settings.label_subtitles'),
    type: 'list',
    value: config && config.subs && parseInt(config.subs.toString()),
    available: [
      { label: t('settings.label_select'), value: '', active: false },
      { label: t('settings.value_on'), value: 1 },
      { label: t('settings.value_off'), value: 0 }
    ],
    category: 'audioAndSubtitles'
  });

  settingsMap.set('prisubtitlelang', {
    label: t('settings.label_primary_subt'),
    type: 'list',
    value: config && config.pSuLg ? config.pSuLg : '',
    available: (
      assetsValues.length > 0
        ? !assetsValues.find(
            (assetValue) => assetValue.assetName === 'setPreferredSubtitleLanguage'
          )
        : []
    )
      ? defaultLanguageList()
      : assetsValues
          .filter((assetValue) => assetValue.assetName === 'setPreferredSubtitleLanguage')
          .map((assetValue) => ({ label: assetValue.label, value: assetValue.value })),
    category: 'audioAndSubtitles'
  });

  /*
  settingsMap.set('secsubtitlelang', {
    label: 'Secondary Subtitle Language',
    type: 'list',
    value: config && config.sSuLg,
    available: defaultLanguageList(t),
    category: 'audioAndSubtitles'
  });
  */

  settingsMap.set('uilang', {
    label: t('settings.label_ui_language'),
    type: 'list',
    value: config && config.UILg ? config.UILg : '',
    available: (
      assetsValues.length > 0
        ? !assetsValues.find((assetValue) => assetValue.assetName === 'setUILanguage')
        : []
    )
      ? defaultLanguageList()
      : assetsValues
          .filter((assetValue) => assetValue.assetName === 'setUILanguage')
          .map((assetValue) => ({ label: assetValue.label, value: assetValue.value })),
    category: 'audioAndSubtitles'
  });

  // ******************** HDMI ***********

  settingsMap.set('hdmires', {
    label: t('settings.label_hdmi_resolution'),
    type: 'text',
    value: config && config.HDMIR ? config.HDMIR : t('general.not_available'),
    category: 'hdmi',
    disabled: true
  });

  settingsMap.set('hdmiaudiomode', {
    label: t('settings.label_aud_mode'),
    type: 'list',
    value: config && config.HDMIAM ? config.HDMIAM : '',
    available: [
      { label: t('settings.label_select'), value: '', active: false },
      { label: t('settings.value_auto'), value: 'auto' },
      { label: t('settings.value_pcm'), value: 'pcm' },
      { label: t('settings.value_dolby'), value: 'dolby' }
    ],
    category: 'hdmi'
  });

  settingsMap.set('tvctrl', {
    label: t('settings.label_tv_ctrl_mode'),
    type: 'text',
    value: config && config.TVCtrl ? config.TVCtrl : t('general.not_available'),
    category: 'hdmi',
    disabled: true
  });

  // ******************** accessibility ***********

  // TODO commented in VGSVTVVIPOC-428
  /*
  {

  settingsMap.set('hearingimpsubs', {
    label: t('settings.label_hearing_impared'),
    type: 'boolean',
    value: config && config.hearImpSu,
    available: [
      { value: true, label: 'Enabled' },
      { value: false, label: 'Disabled' }
    ],
    category: 'accessibility'
  });

  } */

  settingsMap.set('visualimpaudio', {
    label: t('settings.label_audio_description'),
    type: 'boolean',
    value: config && config.visImpAu ? config.visImpAu === 'true' : '',
    available: [
      { value: true, label: t('settings.button_enabled') },
      { value: false, label: t('settings.button_disabled') }
    ],
    category: 'accessibility'
  });

  // ******************** Startup and Standby ***********
  settingsMap.set('activestandbytimer', {
    label: t('settings.label_standby_timer'),
    type: 'list',
    value: config && config.actStByT ? parseInt(config.actStByT.toString()) : '',
    available:
      assetsValues.length > 0 &&
      assetsValues.find((assetValue) => assetValue.assetName === 'setActiveStandbyTimer')
        ? assetsValues
            .filter((assetValue) => assetValue.assetName === 'setActiveStandbyTimer')
            .map((assetValue) => ({
              label: getTranslatedMeasure(assetValue.label, t),
              value: assetValue.value
            }))
        : [],
    category: 'startupAndStandby'
  });

  settingsMap.set('rcctrlmode', {
    label: t('settings.label_remote_ctrl_mode'),
    type: 'text',
    value: config && config.RCM ? config.RCM : t('general.not_available'),
    category: 'startupAndStandby',
    disabled: true
  });

  // ******************** advanced ***********
  settingsMap.set('gdprconsentvs', {
    label: t('settings.label_gdpr'),
    type: 'text',
    value:
      config && config.GDPRCsV ? parseInt(config.GDPRCsV.toString()) : t('general.not_available'),
    category: 'advanced',
    disabled: true
  });

  settingsMap.set('ntype', {
    label: t('settings.label_network_type'),
    type: 'text',
    value: config && config.nType ? config.nType : t('general.not_available'),
    category: 'advanced',
    disabled: true
  });

  settingsMap.set('localPaddingTime', {
    label: t('settings.label_local_pad_time'),
    type: 'list',
    value: '',
    available:
      assetsValues.length > 0 &&
      assetsValues.find((assetValue) => assetValue.assetName === 'setLocalPaddingTime')
        ? assetsValues
            .filter((assetValue) => assetValue.assetName === 'setLocalPaddingTime')
            .map((assetValue) => ({
              label: getTranslatedMeasure(assetValue.label, t),
              value: assetValue.value
            }))
        : [],
    category: 'advanced'
  });

  settingsMap.set('unsubchannels', {
    label: t('settings.label_unsub_channels'),
    type: 'list',
    value: config ? config && config.unSuCh : '',
    category: 'advanced',
    available: [
      { label: t('settings.label_select'), value: '', active: false },
      { label: t('settings.value_show'), value: true },
      { label: t('settings.value_hide'), value: false }
    ]
  });

  if (
    Object.keys(assetsValues).length !== 0 &&
    assetsValues.constructor === Array &&
    assetsValues.find((assetValue) => assetValue.assetName === 'changeChannelVisibility')
  ) {
    settingsMap.set('cpfilter', {
      label: t('settings.label_cp_filter'),
      type: 'multiSelect',
      value: '',
      available: (
        assetsValues.length > 0
          ? !assetsValues.find((assetValue) => assetValue.assetName === 'changeChannelVisibility')
          : []
      )
        ? []
        : assetsValues
            .filter((assetValue) => assetValue.assetName === 'changeChannelVisibility')
            .map((assetValue) => ({ label: assetValue.label, value: assetValue.value })),
      category: 'advanced'
    });
  }

  // ******************** Parental Controls  ***********

  settingsMap.set('parentalPinValue', {
    label: t('settings.label_new_parental_pin'),
    type: 'text',
    value: '',
    category: 'parentalMode'
  });

  if (
    Object.keys(assetsValues).length !== 0 &&
    assetsValues.constructor === Array &&
    assetsValues.find(
      (assetValue) => assetValue.assetName === 'hideSetParentalMode' && assetValue.value === 'false'
    )
  ) {
    settingsMap.set('parentalModeVisibility', {
      label: t('settings.label_parental_visibility'),
      type: 'list',
      value: config && config.visibility ? config.visibility : '',
      available: [
        { label: t('settings.label_select'), value: '', active: false },
        { label: t('settings.value_show'), value: 'show' },
        { label: t('settings.value_pin'), value: 'pin' },
        { label: t('settings.value_hide'), value: 'hide' }
      ],
      category: 'parentalMode'
    });

    settingsMap.set('parentalModeRating', {
      label: t('settings.label_parental_rating'),
      type: 'list',
      value: config && config.pRatg ? parseInt(config.pRatg.toString()) : '',
      available: (
        assetsValues.length > 0
          ? !assetsValues.find((assetValue) => assetValue.assetName === 'setParentalMode')
          : []
      )
        ? defaultParentalMode()
        : assetsValues
            .filter((assetValue) => assetValue.assetName === 'setParentalMode')
            .map((assetValue) => ({ label: assetValue.label, value: assetValue.value })),
      category: 'parentalMode'
    });
  }
  return settingsMap;
}
