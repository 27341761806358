import { t } from "i18next"
import './timeline-component.css'
import React, { useState } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
import { CustomAxisTickParams, SortedStatusType, TimelinePropsType } from "./types"
import { mapData } from "./defaultValues"
import { TimelineItem } from "../redux/types"

function getTodayDateLabel() {
  return getLegendLabel(new Date())
}

function dateStringFormat(day: number, month: string) {
  return day + ' ' + t(month).toUpperCase()
}

function getLegendLabel(date: Date) {
  const months = [
    'dvr.label_january',
    'dvr.label_february',
    'dvr.label_march',
    'dvr.label_april',
    'dvr.label_may',
    'dvr.label_june',
    'dvr.label_july',
    'dvr.label_august',
    'dvr.label_september',
    'dvr.label_october',
    'dvr.label_november',
    'dvr.label_december'
  ]

  const day = date.getUTCDate()
  const month = months[date.getUTCMonth()]
  return dateStringFormat(day, month)
}

function sortData(timelineData: Array<TimelineItem>) {
  const sortedData = []
  for (const timelineItem of timelineData) {
    const sortedStatus = timelineItem.status.map(item => ({ [t(`dvr.label_status_${item.status}`)]: item.amount  }))
    sortedData.push({
      name: getLegendLabel(new Date(timelineItem.date)),
      ...Object.assign({}, ...sortedStatus)
    })
  }

  return sortedData
}

const renderCustomAxisTick = ({ x, y, payload }: CustomAxisTickParams, todayLabel: string) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle">
        <tspan x="0" dy="1.2em">
          {payload.value}
        </tspan>
        {payload.value === todayLabel && (
          <tspan x="0" dy="1.2em">
            ({t('dvr.label_today')})
          </tspan>
        )}
      </text>
    </g>
  );
};

function handleOnClick(dataKey: string, activeFilter: boolean, setActiveFilter: React.Dispatch<React.SetStateAction<boolean>>, setOpacity: React.Dispatch<React.SetStateAction<SortedStatusType>>, initialOpacity: SortedStatusType) {
  if(activeFilter) {
    setActiveFilter(false)
    return setOpacity(initialOpacity)
  }
  setActiveFilter(true)

  setOpacity((prevState) => {
    const status: SortedStatusType = {}
    Object.keys(prevState).forEach(item => {
      status[item] = item === dataKey ? 1 : 0
    })
    return status
  })
}

function handleOnMouseEnter (dataKey: string, activeFilter: boolean, setOpacity: React.Dispatch<React.SetStateAction<SortedStatusType>>) {
  if(!activeFilter) {    
    setOpacity((prevState) => {
      const status: SortedStatusType = {}
      Object.keys(prevState).forEach(item => {
        status[item] = item === dataKey ? 1 : 0.2
      })
      return status
    })
  }
}

function handleOnMouseLeave (activeFilter: boolean, setOpacity: React.Dispatch<React.SetStateAction<SortedStatusType>>, initialOpacity: SortedStatusType) {
  if(!activeFilter) {
    setOpacity(initialOpacity)
  }
}

function onYAxisScroll (e: React.UIEvent<HTMLDivElement, UIEvent>) {
  const yAxisElement = document.getElementsByClassName("recharts-yAxis")[0]
  yAxisElement.setAttribute('style', "transform: translateX("+e.currentTarget.scrollLeft+"px); transition: none;")
}

function getLegendOrder(status: string | number) {
  const legends = {
    [t('dvr.label_status_completed')]: 6,
    [t('dvr.label_status_ongoing')]: 5,
    [t('dvr.label_status_scheduled')]: 4,
    [t('dvr.label_status_cancelled')]: 3,
    [t('dvr.label_status_deleted')]: 2,
    [t('dvr.label_status_partial')]: 1,
    [t('dvr.label_status_failed')]: 0,
  }

  return legends[status]
}

function getInitialOpacity() {
  return {
    [t('dvr.label_status_completed')]: 1,
    [t('dvr.label_status_ongoing')]: 1,
    [t('dvr.label_status_scheduled')]: 1,
    [t('dvr.label_status_cancelled')]: 1,
    [t('dvr.label_status_deleted')]: 1,
    [t('dvr.label_status_partial')]: 1,
    [t('dvr.label_status_failed')]: 1,
  }
}

export default function TimelineComponent({ timelineData }: TimelinePropsType) {
  const sortedData = sortData(timelineData)
  const todayLabel = getTodayDateLabel()

  const initialOpacity = getInitialOpacity()
  const [opacity, setOpacity] = useState<SortedStatusType>(getInitialOpacity())
  const [activeFilter, setActiveFilter] = useState(false)

  return (
    <div style={{ overflowX: "scroll", overflowY: "hidden", paddingBottom: "50px" }} onScroll={onYAxisScroll}>
      <BarChart
        data={sortedData}
        height={500}
        width={7500}
        margin={{
          top: 20,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} tick={({x, y, payload}) => renderCustomAxisTick({x, y, payload}, todayLabel)} enableBackground={0}/>
        <Tooltip itemSorter={item => {
          return item.dataKey ? getLegendOrder(item.dataKey) : 0
        }}/>
        <Legend
          width={3500}
          wrapperStyle={{
            position: "sticky",
            width: '100vw',
            bottom: 'inherit',
          }}
          onMouseEnter={(e) => handleOnMouseEnter(e.dataKey, activeFilter, setOpacity)}
          onMouseLeave={() => handleOnMouseLeave(activeFilter, setOpacity, initialOpacity)}
          onClick={e => handleOnClick(e.dataKey, activeFilter, setActiveFilter, setOpacity, initialOpacity)}
        />
        {mapData.map(data => {
          const dataKey = t(`dvr.label_status_${data.dataKey}`)
          return (
            opacity[dataKey] &&
            <Bar
              key={data.dataKey}
              dataKey={dataKey}
              fill={data.fill}
              stackId={data.stackId}
              opacity={opacity[dataKey]}
            />
          )
        })}
        <YAxis allowDecimals={false}/>
      </BarChart>
    </div>
  )
}
