import React, { Component } from 'react';
import { IdMappingEditableResult } from './redux/reducer';
import { Link } from 'react-router-dom';
import IdMappingTableComponent from './editable-grid-component';
import { TFunction } from 'i18next';

interface IdMappingEditableComponentProps {
  id: any;
  errorMessage: any;
  execute: any;
  isFetching: boolean;
  results: IdMappingEditableResult;

  setFilterValue: any;
  setFilter: any;
  currentFilter: any;
  currentFilterValue: any;

  selectEntry: any;
  removeEntry: any;
  saveEntry: any;
  changeEntry: any;
  submitChanges: any;

  openModal: any;
  closeModal: any;

  newEntry: any;
  saveNewEntry: any;
  showNewEntry: any;
  cancelNewEntry: any;
  changeNewEntry: any;
  clear: any;
  pageNumber: number;
  t: TFunction;
}

export default class IdMappingEditableComponent extends Component<IdMappingEditableComponentProps> {
  componentWillUnmount() {
    this.props.clear();
  }

  componentDidMount() {
    this.props.execute(this.props.pageNumber);
  }

  private submitChanges() {
    this.props.submitChanges(this.props.results.content);
  }

  render() {
    const { t } = this.props;
    const content = this.props.results.content || [];
    const foundEditabled: any = content.find(c => c.editable === true);
    const disableSubmitChanges = content.length === 0 || foundEditabled ? true : false;

    const { primaryKey, secondaryKeys } = this.props.results.schemaProperties;

    let requiredKeys: Array<string> = [];
    requiredKeys = requiredKeys.concat(primaryKey);
    requiredKeys = requiredKeys.concat(secondaryKeys);

    return (
      <div className="section">
        <nav className="level">
          <div className="level-left">
            <h3 className="subtitle is-5" style={{ display: 'inline', marginRight: '10px' }}>
              {t('id_mapping.button_manage_mapping')}
              <strong>{this.props.id}</strong>
              <p>
                <Link
                  style={{ fontSize: '13px', color: 'grey' }}
                  className="is-small"
                  to={{ pathname: `/management/id-mapping`, state: { title: 'Id Mapping' } }}
                >
                  <span className="icon">
                    <i className="mdi mdi-keyboard-return" />
                  </span>
                  {t('general.link_back')}
                </Link>
              </p>
            </h3>
          </div>
          <div className="level-right is-2">
            <div className="control column-rounded is-3">
              <button className="button " onClick={() => this.props.newEntry()}>
                <span className="icon ">
                  <i className="mdi mdi-plus-circle" />
                </span>
                {t('id_mapping.label_add_entry')}
              </button>
              <button
                className="button is-primary"
                style={{ marginLeft: 0 }}
                onClick={() => this.submitChanges()}
                disabled={disableSubmitChanges}
              >
                <span className="icon">
                  <i className="mdi mdi-playlist-check" />
                </span>
                {t('id_mapping.label_submit_changes')}
              </button>
            </div>
          </div>
        </nav>
        <div className="field has-addons">
          <div className="control" style={{ width: '100%' }}>
            <div className="select is-fullwidth">
              <select
                onChange={(e: any) => this.props.setFilter(e.target.value)}
                value={this.props.currentFilter || ''}
              >
                <option value="">{t('id_mapping.select_mapping_property')}</option>
                {requiredKeys.map((option: any, index: number) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {this.props.currentFilter && (
            <div className="control" style={{ width: '40%' }}>
              <input
                type="text"
                className="input"
                defaultValue={this.props.currentFilterValue}
                onChange={(e: any) => this.props.setFilterValue(e.target.value)}
              />
            </div>
          )}
          <div className="control">
            <button
              className="button is-primary"
              onClick={() => this.props.execute(0)}
              disabled={this.props.isFetching}
            >
              <span>{t('general.search')}</span>
              <span className="icon">
                <i className="mdi mdi-magnify" />
              </span>
            </button>
          </div>
        </div>

        <IdMappingTableComponent
          errorMessage={this.props.errorMessage}
          execute={this.props.execute}
          isFetching={this.props.isFetching}
          results={this.props.results}
          selectEntry={this.props.selectEntry}
          removeEntry={this.props.removeEntry}
          saveEntry={this.props.saveEntry}
          changeEntry={this.props.changeEntry}
          submitChanges={this.props.submitChanges}
          openModal={this.props.openModal}
          closeModal={this.props.closeModal}
          newEntry={this.props.newEntry}
          pageNumber={this.props.pageNumber}
          t={this.props.t}
        />
      </div>
    );
  }
}
