import { TFunction } from 'i18next';
import { ChannelsAssociationsNodes, ChannelsAssociationsNodesType } from './type';

export default (data: ChannelsAssociationsNodes, t: TFunction): ChannelsAssociationsNodes => {
    const ChannelsAssociationsNodesType = getChannelsType(data);
    return {
      id: data.id ,
      nodeId: data.nodeId,
      nodeLabel: data.nodeLabel,
      childRegionId: data.childRegionId,
      regionalLCN: data.regionalLCN
    };
};

function getChannelsType(result: any) {
    if (result) {
      if (!result.failed || result.failed === 0) {
        return ChannelsAssociationsNodesType.SUCCESS;
      } else if (!result.succeeded || result.succeeded === 0) {
        return ChannelsAssociationsNodesType.ERROR;
      }
    }
  
    return ChannelsAssociationsNodesType.WARNING;
}
