import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import { IdMappingResult } from './redux/reducer';

interface IdMappingGridComponentProps {
  results: IdMappingResult;
  filled: any;
  isFetching: boolean;
  t: TFunction;
}

const statusLabel = {
  FAILED: 'id_mapping.value_failed',
  STARTED: 'id_mapping.label_started',
  FINISHED: 'id_mapping.value_finished',
  INVALID: 'id_mapping.value_invalid_staging',
  BUSINESS_INVALID: 'id_mapping.value_invalid_ingest',
  QUEUED: 'id_mapping.value_started'
};

const behaviorLabel = {
  DROP_FILE: 'id_mapping.value_drop_file',
  DROP_LINE: 'id_mapping.value_drop_line',
  REPLACE_LINE: 'id_mapping.value_replace_line'
};

function displayStatus(status: any, t: TFunction) {
  // @ts-ignore
  const label = t(statusLabel[status]);
  return label || t('id_mapping.value_integration_error');
}

function displayBehavior(behavior: any, t: TFunction) {
  // @ts-ignore
  const label = t(behaviorLabel[behavior]);
  return label || t('id_mapping.value_integration_error');
}

export default class IdMappingGridComponent extends Component<IdMappingGridComponentProps> {
  render() {
    const { results, filled, isFetching, t } = this.props;

    if (isFetching) {
      return (
        <div className="loader-wrapper box">
          <div className="loader" />
        </div>
      );
    }

    return (
      <div className="paper table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th colSpan={7} className="subtitle is-7">
                {t('id_mapping.title_grid')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="has-text-grey-light has-text-weight-light">
                {t('id_mapping.column_uuid')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('id_mapping.column_timestamp')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('id_mapping.column_operator')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('id_mapping.column_behavior')}
              </th>
              <th className="has-text-grey-light has-text-weight-light">
                {t('id_mapping.column_status')}
              </th>
              <th className="has-text-grey-light has-text-weight-light" />
            </tr>

            {results &&
              results.content.map((result: any) => {
                const filterKey = filled ? Object.keys(filled)[0] : null;
                if (!filterKey || (filterKey && result[filterKey] === filled[filterKey])) {
                  return (
                    <tr key={result.uuid}>
                      <Td uuid={result.uuid} status={result.status} value={result.uuid} />
                      <Td uuid={result.uuid} status={result.status} value={result.lastUpdate} />
                      <Td uuid={result.uuid} status={result.status} value={result.username} />
                      <Td
                        uuid={result.uuid}
                        status={result.status}
                        value={displayBehavior(result.behavior, t)}
                      />
                      <Td
                        uuid={result.uuid}
                        status={result.status}
                        title={result.message}
                        value={displayStatus(result.status, t)}
                      />
                      <Td uuid={result.uuid} status={result.status} value={result.collisions} />
                    </tr>
                  );
                }
                return null;
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

const Td = ({ uuid, status, title, value }: any) => (
  <td title={title || ''}>
    {status && status === 'BUSINESS_INVALID' && (
      <Link
        className="has-text-grey-darker"
        to={{
          pathname: `/management/id-mapping/${uuid}/collision`,
          state: { title: 'Collision' }
        }}
      >
        {value}
      </Link>
    )}
    {status && status !== 'BUSINESS_INVALID' && value}
  </td>
);
