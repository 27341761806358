import React, { Component } from 'react';
import PopUpComponent from 'core/pop-up';
import { TFunction } from 'i18next';
import { Close } from 'assets/svg';
import { FileUploadState } from './redux/types';
import Papa from 'papaparse';

interface FileUploadProps {
  isActive?: boolean;
  fileUploadHandler: (event?: any, pageType?: string) => void;
  fileSelectedHandler: (event?: any, jsonFile?: {}, type?: string, pageType?: string) => void;
  closeUpload: () => void;
  fileUploadState: FileUploadState;
  title: string;
  children?: any;
  close: () => void;
  execute?: any;
  clue?: string;
  uploadFile?: any;
  setFileValue?: any;
  dataUpload?: FormData;
  dataFormat?: string;
  fileNameToUpload?: string;
  ingestType?: string;
  fileType?: string;
  setFileType?: any;
  confirmButton: string;
  t: TFunction;
  formatFile: string;
  maxMBsize: number;
  pageType?: string;
  labelSize?: string;
  labelAddButton?: string;
  parseToJson?: boolean;
  onUpload?: Function;
}

export default class FileUploadComponent extends Component<
  FileUploadProps,
  {
    isActive: boolean;
    disabledUploadButton: boolean;
    selectedFileErrorMsg: string;
    selectedFileSuccessMsg: string;
  }
> {
  fileInput: any;

  constructor(props: FileUploadProps) {
    super(props);
    this.state = {
      isActive: !!this.props.isActive,
      disabledUploadButton: false,
      selectedFileErrorMsg: '',
      selectedFileSuccessMsg: 'success'
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({
        isActive: !!this.props.isActive
      });
    }
  }

  clickClose = () => {
    this.fileInput.value = '';
    this.props.fileSelectedHandler('');
    this.props.close();
    this.props.closeUpload();
  };

  onChangeSelected = (event: any) => {
    const { files } = event.target;
    const { fileSelectedHandler, formatFile, parseToJson, pageType } = this.props;
    if (event.target.files[0] && parseToJson) {
      Papa.parse(files[0], {
        complete: function (results: any) {
          const [first, ...rest] = results.data;
          const finalResults = rest
            .filter((item: any) => {
              if (item[0] !== '') {
                return item[0];
              }
            })
            .map((item: any) => item[0]);
          const resultObject = {
            [first[0]]: finalResults
          };
          return fileSelectedHandler(files[0] || '', resultObject, formatFile);
        }
      });
    } else {
      return fileSelectedHandler(files[0] || '', undefined, formatFile, pageType);
    }
  };

  onClickUpload = () => {
    if (this.props.onUpload) {
      this.props.onUpload(
        this.props.fileUploadState.fileContent,
        this.props.fileUploadState.selectFile
      );
    } else {
      this.props.fileUploadHandler(this.props.fileUploadState, this.props.pageType);
    }
    this.clickClose();
  };

  render() {
    const { title, clue, confirmButton, t, labelSize, labelAddButton, parseToJson } = this.props;

    return (
      <PopUpComponent isActive={this.state.isActive}>
        <div className="columns is-flex is-justified-between">
          <div className="column is-narrow">
            <h5 className="text is-h5 is-bold is-marginless">{title}</h5>
          </div>
          <div className="column flex-zero">
            <button className="button button-action" onClick={this.clickClose}>
              <Close className="icon-svg icon-small" />
            </button>
          </div>
        </div>

        <div className="columns is-mobile flex-wrap">
          <div className="column is-12">
            <div className="modal-upload">
              <h5 className="text is-h5 pb-3">{clue}</h5>

              {this.props.fileUploadState.selectedFileErrorMsg ? (
                <div className="text is-size-sm is-danger">
                  {parseToJson ? (
                    this.props.fileUploadState.selectedFileErrorMsg.map(
                      (errorMsg: string, key: number) => {
                        return (
                          <p className="m-0" key={key}>
                            {t(errorMsg)}
                          </p>
                        );
                      }
                    )
                  ) : (
                    <>
                      <p className="m-0">
                        <span className="text is-danger is-bold">Error: </span>
                        {this.props.fileUploadState.selectedFileErrorMsg[0]}
                      </p>
                      <p className="has-margin-top-none">
                        {this.props.fileUploadState.selectedFileErrorMsg[1].split('\n').map((value: string)=> <span key={value}>
                    {value}
                          <br/>
                  </span>)}

                      </p>
                    </>
                  )}
                </div>
              ) : (
                <div className="text is-size-sm">
                  {labelSize?.split('\n').map((value: string)=> <span key={value}>
                    {value}
                    <br/>
                  </span>) ||
                    t('spectrum.upload.label.size')
                      .replace('{0}', `${this.props.maxMBsize}MB`)
                      .replace('CSV', `${this.props.formatFile}`)}
                </div>
              )}
              <input
                style={{ display: 'none' }}
                type="file"
                onChange={this.onChangeSelected}
                ref={(fileInput) => (this.fileInput = fileInput)}
              />

              <button
                className="button is-secondary is-larger"
                onClick={() => this.fileInput.click()}
              >
                {labelAddButton || t('spectrum.button.add_file')}
              </button>

              {this.props.fileUploadState?.selectFile.name && (
                <div className="text is-size-sm is-darkgrey">
                  <span className="text is-bold">File: </span>
                  {this.props.fileUploadState?.selectFile.name}
                </div>
              )}
              {/* {children} */}
              <div className="text is-size-sm" />
            </div>
          </div>
          <div className="column is-12 has-text-right">
            <button
              disabled={this.props.fileUploadState?.disabledUploadButton}
              type="submit"
              className="button is-primary is-larger"
              onClick={this.onClickUpload}
            >
              {confirmButton}
            </button>
          </div>
        </div>
      </PopUpComponent>
    );
  }
}
