import { VodState, VOD_SUCCESS, VOD_FAILURE, VOD_CLEAR_CACHE } from './types';

const initialState: VodState = {
  items: undefined,
  totalElements: undefined
};

interface VodAction {
  type: string;
  payload: any;
}

export default function(state = initialState, { type, payload }: VodAction) {
  switch (type) {
    case VOD_SUCCESS:
      return {
        ...state,
        items: payload.items,
        totalElements: payload.totalElements
      };

    case VOD_FAILURE:
      return { ...state };

    case VOD_CLEAR_CACHE:
      return initialState;

    default: {
      return { ...state };
    }
  }
}
