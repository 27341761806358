import { add, remove } from 'core/spinner/redux/actions';

import queryString from 'core/utils/query-string';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { ApplicationState } from 'application/application-redux';
import {
  CHANNEL_SCAN_REQUEST,
  CHANNEL_SCAN_SUCCESS,
  CLEAN_SCAN_RESULT,
  CHANNEL_SCAN_FAILURE,
  TOGGLE_ACCORDION
} from './types';
import AuthService from '../../../../../../../core/auth/auth-service';
import apiClient from '../../../../../../../core/api/client';
import { addToaster } from '../../../../../../../core/toaster/redux/actions';
import { handleCommandError } from '../../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

function request() {
  return {
    type: CHANNEL_SCAN_REQUEST
  };
}

function failure() {
  return {
    type: CHANNEL_SCAN_FAILURE
  };
}

function success(payload: any) {
  return {
    type: CHANNEL_SCAN_SUCCESS,
    payload
  };
}

export function cleanScanResult() {
  return {
    type: CLEAN_SCAN_RESULT
  };
}

export function toggleAccordion(index: number) {
  return {
    type: TOGGLE_ACCORDION,
    payload: index
  };
}

export function startScan(id: string, filter?: PaginationConfig) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch(request());
    dispatch(add('CHANNEL_SCAN', {}));
    await AuthService.refreshToken();

    const jobId = '';
    dispatch(request());

    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: 'runChannelScan',
        deviceId: id
      })
      .then(
        response => {
          if (response.data.httpCode === '400') {
            handleCommandError(
              dispatch,
              response.data.responseJson.jobId,
              'dvr.column_channel',
              getState().i18n.t,
              'CHANNEL_SCAN'
            );
          } else {
            const timerTimeout = setTimeout(() => {
              clearInterval(timerInterval);
              dispatch(remove('CHANNEL_SCAN'));
              dispatch(
                addToaster({
                  title: 'dvr.column_channel',
                  message: 'error_messages.VIPOC_ERROR_021',
                  type: 'danger'
                })
              );
            }, 20000); // 20 seconds

            let timerInterval = setInterval(
              () =>
                apiClient
                  .get(
                    `${`${envs.REACT_APP_API_URL}/command/` +
                      'runChannelScan/device/'}${id}/status`,
                    {
                      headers: {
                        transactionID: jobId
                      }
                    }
                  )
                  .then(
                    () => {
                      clearInterval(timerInterval);
                      clearTimeout(timerTimeout);
                      dispatch(remove('CHANNEL_SCAN'));
                      return dispatch(fetch(id, filter));
                    },
                    () => {
                      dispatch(remove('CHANNEL_SCAN'));
                      return dispatch(failure());
                    }
                  ),
              3000
            ); // 3 seconds
          }
        },
        rejection => {
          dispatch(remove('CHANNEL_SCAN'));
          const message: string =
            rejection === 'error_messages.permission_denied'
              ? rejection
              : 'error_messages.VIPOC_ERROR_035';
          dispatch(
            addToaster({
              title: 'dvr.column_channel',
              message,
              type: 'danger'
            })
          );
          return dispatch(failure());
        }
      );
  };
}

export function fetch(id: string, filter?: PaginationConfig) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(request());
    dispatch(add(CHANNEL_SCAN_SUCCESS, {}));
    await AuthService.refreshToken();
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/device/${id}/channel/history?${queryString(
          filter,
          getState().application.offset
        )}`
      )
      .then(
        response => {
          dispatch(remove(CHANNEL_SCAN_SUCCESS));
          dispatch(success(response.data));
        },
        () => {
          dispatch(remove(CHANNEL_SCAN_SUCCESS));
          dispatch(
            addToaster({
              title: 'dvr.column_channel',
              message: 'error_messages.VIPOC_ERROR_044',
              type: 'danger'
            })
          );
          return dispatch(failure());
        }
      );
  };
}
