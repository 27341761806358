import apiClient from 'core/api';
import AuthService from 'core/auth';
import queryString from 'core/utils/query-string';
import { add, remove } from 'core/spinner/redux/actions';
import { addToaster } from 'core/toaster/redux/actions';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { VODAction, VOD_FAILURE, VOD_SUCCESS, VOD_SEARCH_LIST, VOD_CLEAR_CACHE } from './types';
import { ApplicationState } from 'application/application-redux';
import { envs } from 'application/envHandler';

function failure(payload: any): VODAction {
  return {
    type: VOD_FAILURE,
    payload
  };
}

function success(payload: any): VODAction {
  return {
    type: VOD_SUCCESS,
    payload
  };
}

export function clearCacheVod(): VODAction {
  return {
    type: VOD_CLEAR_CACHE
  };
}

export default function fetch(hhid: string, filter?: PaginationConfig, days?: number) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    await AuthService.refreshToken();
    dispatch(add(VOD_SEARCH_LIST, {}));
    let previousDays = '';
    if (days) {
      previousDays = `&previousDays=${days}`;
    }
    return apiClient
      .get(
        `${envs.REACT_APP_API_URL}/hhid/${hhid}/video-on-demand?${queryString(
          filter,
          getState().application.offset
        ) + previousDays}`
      )
      .then(result => {
        dispatch(remove(VOD_SEARCH_LIST));
        dispatch(success(result.data));
      })
      .catch((error: any) => {
        dispatch(remove(VOD_SEARCH_LIST));
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_vod',
            message: 'error_messages.VIPOC_ERROR_032',
            type: 'danger'
          })
        );
        dispatch(failure(error));
      });
  };
}
