import { Action } from 'redux';
import { clone } from 'ramda';
import { DELETE_PENDING_TASK, SET_PENDING_TASK_SPINNER, SpinnerState } from './types';

const initialState: SpinnerState = {
  isActive: false,
  tasks: new Map<string, any>()
};

interface SpinnerAction extends Action {
  payload: {
    key: string;
    value?: any;
    spinnerActive: boolean;
  };
}

export const hasPendingTasks = (state: SpinnerState): boolean =>
  state.tasks && state.tasks.size > 0;

export const hasPendingTasksOf = (key: string, state: SpinnerState): boolean =>
  state.tasks && state.tasks.size > 0 && state.tasks.get(key);

export default function(state = initialState, action: SpinnerAction): SpinnerState {
  switch (action.type) {
    case SET_PENDING_TASK_SPINNER:
      return {
        ...state,
        tasks: state.tasks.set(action.payload.key, action.payload.value),
        isActive: action.payload.spinnerActive
      };
    case DELETE_PENDING_TASK:
      const newMap = clone(state.tasks);
      newMap.delete(action.payload.key);
      return {
        ...state,
        tasks: newMap,
        isActive: hasPendingTasks(state) && action.payload.spinnerActive
      };
    default:
      return state;
  }
}
