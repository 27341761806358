import { connect } from 'react-redux';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import DeviceDetailComponent from './device-detail-component';
import { ApplicationState } from 'application/application-redux';
import { resetFilter } from 'core/search/redux/action'

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  id: ownProps.match.params.id,
  hhid: ownProps.match.params.hhid,
  serialNr: ownProps.match.params.serialNr,
  currentPage: ownProps.match.params.type,
  navbar: state.navbar.navbar,
  general: state.devices.detail.general,
});

export default connect(mapStateToProps, { openModal, closeModal, resetFilter })(DeviceDetailComponent);
