import { Help } from 'assets/svg';
import { TFunction } from 'i18next';
import React, { Component, Fragment } from 'react';
import './tooltip-styles.scss';

interface TooltipProps {
  data: any;
  t: TFunction;
}

export default class TooltipComponent extends Component<TooltipProps> {
  render() {
    const { data, t } = this.props;
    return (
      <Fragment>
        <div className="tooltip-circle">
          <Help className="icon-xx-small icon-white" />
        </div>

        {typeof data === 'object' ? (
          <div className="tooltip-container tooltip-container-table">
            {data.map((item: any, key: number) => (
              <div className="columns is-vertical-center has-margin-xs" key={key}>
                <div className="column text-align-initial has-padding-xs is-2">{t(item.code)}</div>
                <div className="column text-align-initial has-padding-xs">{t(item.text)}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tooltip-container">
            <p>{t(data)}</p>
          </div>
        )}
      </Fragment>
    );
  }
}
