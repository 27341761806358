import {
  ChannelScanState,
  CHANNEL_SCAN,
  CHANNEL_SCAN_STATUS,
  CHANNEL_SCAN_FAILURE,
  CHANNEL_SCAN_SUCCESS,
  CLEAN_SCAN_RESULT,
  ChannelScanAction,
  TOGGLE_ACCORDION,
  CHANNEL_WAITING_COMMAND
} from './types';

const initialState: ChannelScanState = {
  results: [
    {
      channelHistories: [
        {
          frequency: '',
          symbrate: '',
          nrChannels: '',
          regionID: '',
          type: ''
        }
      ],
      finishedAt: '',
      accordionActive: false,
      accrodionIndex: 0,
      totalElements: 0
    }
  ],
  activityLog: null,
  waitingCommandExecution: false
};

export default (state = initialState, { type, payload }: ChannelScanAction) => {
  switch (type) {
    case CHANNEL_WAITING_COMMAND: {
      return { ...state, waitingCommandExecution: payload };
    }
    case CHANNEL_SCAN:
    case CHANNEL_SCAN_STATUS:
      return {
        ...state,
        activityLog: {
          udid: payload.deviceId,
          status: payload.status,
          eventName: payload.name,
          lastManaged: payload.startDate,
          commandTraceId: payload.transactionID,
          logResponse: payload.response.parameters,
          logRequest: payload.request.parameters,
          progress: payload.request.progress
            ? payload.request.progress.replace('%', '')
            : state.activityLog && state.activityLog.progress,
          error: payload.response.errorText
        }
      };
    case CHANNEL_SCAN_FAILURE: {
      return {
        ...state
      };
    }
    case CHANNEL_SCAN_SUCCESS: {
      let index = 0;
      payload.forEach((obj: any) => {
        obj.accrodionIndex = index;
        obj.accordionActive = false;
        index++;
      });

      return {
        ...state,
        results: payload
      };
    }

    case CLEAN_SCAN_RESULT: {
      return {
        ...state,
        results: [],
        activityLog: null
      };
    }

    case TOGGLE_ACCORDION: {
      const { results } = state;
      results.forEach(obj => {
        if (obj.accrodionIndex === payload) {
          obj.accordionActive = !obj.accordionActive;
        } else {
          obj.accordionActive = false;
        }
      });

      return {
        ...state,
        results
      };
    }
    default:
      return state;
  }
};
