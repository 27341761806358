import { DVBService } from '../redux-services/types';
import { DVBItemChanged, DVBItemToShow } from '../types';

export const DVB_MANAGEMENT_STREAM_PASTE = 'DVB_MANAGEMENT_STREAM_PASTE';
export const DVB_MANAGEMENT_STREAM_SELECT = 'DVB_MANAGEMENT_STREAM_SELECT';
export const DVB_MANAGEMENT_STREAMS_SET = 'DVB_MANAGEMENT_STREAMS_SET';

export interface DVBManagementStreamAction {
  type:
    | typeof DVB_MANAGEMENT_STREAM_PASTE
    | typeof DVB_MANAGEMENT_STREAM_SELECT
    | typeof DVB_MANAGEMENT_STREAMS_SET;
  payload: any;
}

export interface DVBStream extends DVBItemToShow, DVBItemChanged {
  tsId: string;
  freq: string;
  mod: string;
  dvbType: string;
  symRate: string;
  services?: Array<DVBService>;
}

export interface DVBManagementStream {
  items: Array<DVBStream>;
  itemsChanged?: Array<DVBStream>;
  itemsSearch?: Array<DVBStream>;
  selected?: DVBStream;
  copied?: DVBStream;
}
