// / <reference path="date-picker.d.ts" />
import React, { Component } from 'react';
import bulmaCalendar from 'bulma-calendar';
import any from 'ramda/es/any';
import defaultOptions from './date-picker-defaults';
import './date-input-style.scss';

export default class DateInputComponent extends Component<DatePickerOptions> {
  dateInput = React.createRef<HTMLInputElement>();

  instance: any = any;

  static defaultProps = { ...defaultOptions };

  componentDidMount() {
    this.instance = bulmaCalendar.attach(this.dateInput.current, {
      ...defaultOptions,
      ...this.props
    })[0];

    this.instance.on('validate', (event: any) => this.onSelect(event));
  }

  onSelect(event: any) {
    const { options } = event.data;

    if (options.closeOnRangeSelect && options.isRange && options.type === 'datetime') {
      event.data.hide();
    }

    this.props.onSelect(event);
  }

  open() {
    this.instance.show();
  }

  clear() {
    this.instance.clear();
  }

  render() {
    return (
      <div className={`date-input-wrapper${!this.props.showInput ? '-hidden' : ''}`}>
        <input type="date" ref={this.dateInput} />
      </div>
    );
  }
}
