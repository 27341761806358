import mapper from './mapper';
import { addToaster } from 'core/toaster/redux/actions';
import {
    ChannelsAssociationsNodesAction,
    ChannelsAssociationsNodes,
    ChannelsAssociationsNodesState,
    CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS,
    CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE
} from "./type"

const initialState: ChannelsAssociationsNodesState = {
    isActive: false,
    isFetching: false,
    filteredCount: 0,
    nodes: []
};

export default function(state = initialState, action: ChannelsAssociationsNodesAction) {
    switch (action.type) {
      case CHANNEL_ASSOCIATIONS_NODES_GET_SUCCESS: {
        const data = action.payload.data;
        const count = action.payload.totalCount;
        return {
          ...state, filteredCount: count,
          nodes: data.map((node: ChannelsAssociationsNodes) =>
            mapper(node, action.payload.t)
          ) 
        };
      }
      case CHANNEL_ASSOCIATIONS_NODES_GET_FAILURE:{
        return {
          ...state,
          filteredCount: 0,
          nodes: []
        };
      }
      default:
        return { ...state };
    }
}