import {
  DVB_MANAGEMENT_STREAM_SELECT,
  DVB_MANAGEMENT_STREAMS_SET,
  DVBStream,
  DVBManagementStreamAction
} from './types';
import { selectService, setServices } from '../redux-services/action';
import { ApplicationState } from 'application/application-redux';
import { getNextOptions } from '../../popUp/redux/action';
import { DVB_COLUMNS } from '../enums';
import { mergeChanges } from '../action';

function action(type: any, payload?: any): DVBManagementStreamAction {
  return {
    type: type,
    payload: payload
  };
}

export function selectStream(streamId?: string) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    dispatch(setServices(undefined, true));
    dispatch(selectService());
    dispatch(action(DVB_MANAGEMENT_STREAM_SELECT, streamId));

    const selectedStreamChildren =
      getState().management.spectrum.dvbManagement.stream.selected?.children || [];
    dispatch(setServices(selectedStreamChildren && streamId ? selectedStreamChildren : undefined));
  };
}

export function setStreams(
  streams?: Array<DVBStream>,
  isToClear?: boolean,
  isMove?: boolean,
  isSearch?: boolean
) {
  return function(dispatch: Function) {
    dispatch(
      action(DVB_MANAGEMENT_STREAMS_SET, {
        streams,
        isToClear,
        isMove,
        isSearch
      })
    );

    if (streams) {
      dispatch(mergeChanges(DVB_COLUMNS.STREAM));
    }
  };
}

export function setStreamsOnMove(streams?: Array<DVBStream>, isMove?: boolean) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    new Promise<void>(resolve => {
      dispatch(action(DVB_MANAGEMENT_STREAMS_SET, { streams, isToClear: false, isMove }));
      resolve();
    }).then(() => {
      getNextOptions(dispatch, getState().management.spectrum.dvbManagement, DVB_COLUMNS.NETWORK);
    });
  };
}
