import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import {
  ESN_MAPPING_FAILURE,
  ESN_MAPPING_REQUEST,
  ESN_MAPPING_SUCCESS,
  ESN_MAPPING_SET_INTERVAL
} from './reducer';
import { envs } from 'application/envHandler';

const REFRESHING_RATE = 120000;

function setIntervalId(id: any) {
  return {
    type: ESN_MAPPING_SET_INTERVAL,
    payload: id
  };
}

function request() {
  return {
    type: ESN_MAPPING_REQUEST
  };
}

function success(payload: any) {
  return {
    type: ESN_MAPPING_SUCCESS,
    payload
  };
}

function failure(payload: any) {
  return {
    type: ESN_MAPPING_FAILURE,
    payload
  };
}

export function startRefresh() {
  return async function(dispatch: any) {
    const intervalId = setInterval(() => {
      dispatch(search());
    }, REFRESHING_RATE);

    dispatch(setIntervalId(intervalId));
  };
}

export function search() {
  return async function(dispatch: any, getState: any) {
    dispatch(request());
    dispatch(add(ESN_MAPPING_REQUEST, {}));

    apiClient.get(`${envs.REACT_APP_API_URL}/esn-mapping`).then(
      response => {
        dispatch(remove(ESN_MAPPING_REQUEST));
        return dispatch(success(response.data));
      },
      rejection => {
        console.error(rejection);
        dispatch(remove(ESN_MAPPING_REQUEST));
        dispatch(
          addToaster({
            title: 'home.icon_esn_mapping',
            message: 'error_messages.VIPOC_ERROR_004',
            type: 'danger'
          })
        );
        return dispatch(failure(rejection));
      }
    );
  };
}

export function stopRefresh() {
  return async function(dispatch: any, getState: any) {
    const { interval } = getState().management.esnMapping.grid;

    clearInterval(interval);
    dispatch(setIntervalId(null));
  };
}
