import { Close, WarningCircle } from 'assets/svg';
import React, { Component } from 'react';
import './badge-styles.scss';
import { BagdeTypes } from './enum';

interface BadgeProps {
  action?: () => void;
  children: any;
  onHover?: () => void;
  hasButton: boolean;
  isAbsolute?: boolean;
  type: BagdeTypes;
}

export default class BadgeComponent extends Component<BadgeProps> {
  getBadgeType(type: string) {
    switch (type) {
      case 'danger':
        return 'badge-danger';
      case 'info':
        return 'badge-info';
      default:
        return '';
    }
  }

  setToasterPosition(element: any, out: boolean) {
    const { onHover } = this.props;
    const toaster: any = document.querySelectorAll('.badge-error')[0];
    const currentBadge = element.getBoundingClientRect();
    if (toaster) {
      if (out) {
        toaster.style.left = null;
        toaster.style.bottom = null;
      } else {
        toaster.style.left = `${currentBadge.x - 24}px`;
        toaster.style.bottom = `calc(100vh-${currentBadge.y}px)`;
      }
    }

    if (onHover) {
      onHover();
    }
  }

  render() {
    const { action, children, hasButton, isAbsolute, type } = this.props;

    return (
      <span
        className={`badge is-vertical-center 
        ${isAbsolute ? 'badge-float' : ''} 
        ${hasButton ? 'has-button' : ''}
        ${this.getBadgeType(type)}
        
        `}
        onMouseEnter={(e: any) => type === 'danger' && this.setToasterPosition(e.target, false)}
        onMouseOut={(e: any) => type === 'danger' && this.setToasterPosition(e.target, true)}
      >
        {children}
        {hasButton &&
          (type === 'danger' ? (
            <span className="badge-action">
              <WarningCircle className="icon icon-svg icon-white-fill icon-tiny" />
            </span>
          ) : (
            <button
              type="button"
              className="badge-action is-vertical-center is-justified-center"
              onClick={() => action && action()}
            >
              <Close className="icon icon-svg icon-xxx-small" />
            </button>
          ))}
      </span>
    );
  }
}
