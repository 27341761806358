export const ADVANCED_SEARCH_HISTORY = 'ADVANCED_SEARCH_HISTORY';
export const ADVANCED_SEARCH_HISTORY_FAILURE = 'ADVANCED_SEARCH_HISTORY_FAILURE';
export const ADVANCED_SEARCH_HISTORY_SUCCESS = 'ADVANCED_SEARCH_HISTORY_SUCCESS';

export const ADVANCED_SEARCH_HISTORY_UPDATE = 'ADVANCED_SEARCH_HISTORY_UPDATE';
export const ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE = 'ADVANCED_SEARCH_HISTORY_UPDATE_FAILURE';
export const ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS = 'ADVANCED_SEARCH_HISTORY_UPDATE_SUCCESS';

export const ADVANCED_SEARCH_HISTORY_CHANGE_TAB = 'ADVANCED_SEARCH_HISTORY_CHANGE_TAB';

export const SET_CURRENT_HISTORY_FILTER_VALUE = 'SET_CURRENT_HISTORY_FILTER_VALUE';
export const EDIT_CURRENT_FAVOURITE_VALUE = 'EDIT_CURRENT_FAVOURITE_VALUE';
export const ADVANCED_SEARCH_EDIT_FAVOURITE = 'ADVANCED_SEARCH_EDIT_FAVOURITE';

export interface AdvancedSearchHistoryState {
  list: Array<any>;
  all: Array<any>;
  currentTab: any;
  query: string;
  current?: any;
}

export interface AdvancedSearchHistory {
  createdBy: string;
  id: number;
  identifier: string;
  isHighlight: string;
  query: string;
  searchDate: string;
  searchName: string;
  isEditing: boolean;
  similarSearch: boolean;
}
