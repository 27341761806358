import DateUtils from 'core/utils/date-utils';
import { TFunction } from 'i18next';
import { Request } from './types';

export default class RequestsMapper {
  static formatRequest(request: Request, t: TFunction): Request {
    return {
      requestId: request.requestId,
      user: request.user,
      createdAt: request.createdAt && DateUtils.formatFromIotTimestamp(new Date(request.createdAt)),
      modifiedAt:
        request.modifiedAt && DateUtils.formatFromIotTimestamp(new Date(request.modifiedAt)),
      schedulingDate:
        request.schedulingDate && DateUtils.formatDate(new Date(request.schedulingDate)),
      requestStatus: t('spectrum.requests.status_' + request.requestStatus)
    };
  }

  static formatChanges(changes: any[]) {
    return Object.keys(changes).map((changeId: any) => {
      return { ...changes[changeId], id: changeId };
    });
  }

  static formatResults(results: any[]) {
    Object.keys(results).forEach((resultType: any) => {
      let result = results[resultType];
      const newResults: any[] = [];

      result.forEach((resultObj: any) => {
        Object.keys(resultObj).forEach((resultObjId: any) => {
          newResults.push(resultObj[resultObjId].changeResult);
        });
      });
      results[resultType] = newResults;
    });

    return results;
  }
}
