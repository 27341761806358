import {
  DeviceLogState,
  DeviceLogActionTypes,
  DEVICE_LOG_SUCCESS,
  DEVICE_LOG_REQUEST,
  DEVICE_LOG_FAILURE,
  DEVICE_LOG_SUCCESS_CONCAT
} from './types';

const initialState: DeviceLogState = {
  content: [],
  hasMore: false,
  pageNumber: 0
};

function _sortByDate(items: Array<any>) {
  items
    .sort((a, b) => {
      return a.lastManaged < b.lastManaged ? -1 : a.lastManaged > b.lastManaged ? 1 : 0;
    })
    .reverse();

  return items;
}

export default function(state = initialState, action: DeviceLogActionTypes): DeviceLogState {
  switch (action.type) {
    case DEVICE_LOG_REQUEST:
      return { ...state };
    case DEVICE_LOG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        content: action.payload.content ? _sortByDate(action.payload.content) : [],
        hasMore: action.payload.content ? action.payload.hasMore : false,
        pageNumber: 1
      };
    case DEVICE_LOG_SUCCESS_CONCAT:
      return {
        ...state,
        content: _sortByDate(state.content.concat(action.payload.content)),
        hasMore: action.payload.hasMore,
        pageNumber: state.pageNumber + 1
      };
    case DEVICE_LOG_FAILURE:
      return { ...state };
    default:
      return { ...state };
  }
}
