import React from 'react';
import { TFunction } from 'i18next';
import './index.scss'
import { OnnetOffnetRow } from './redux/reducer';
import { RemoveEntryFunction, SaveEntryFunction, SaveNewEntryFunction, SelectEntryFunction } from './redux/action';
import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types';

type Props = {
  title: string
  group: string
  isFetching: boolean
  results: Array<OnnetOffnetRow>

  t: TFunction

  selectEntry: SelectEntryFunction
  removeEntry: RemoveEntryFunction
  saveEntry: SaveEntryFunction
  saveNewEntry: SaveNewEntryFunction

  openModal: OpenModalFunction
  closeModal: CloseModalFunction
}

const initialState = {
  showNewEntry: false,
  entry: '',
}

export default class OnnetOffnetGridComponent extends React.Component<Props> {
  state = initialState

  newEntry () {
    this.setState({
      showNewEntry: true
    })
  }

  isBottom(el1: any, el2: any) {
    if (el1 && el2) {
      return el1.getBoundingClientRect().bottom >= el2.getBoundingClientRect().bottom;
    }
    return false;
  }

  trackScrolling = () => {
    const wrappedTable = document.getElementById('table-container');
    const wrappedDiv = document.getElementById('div-container');
    if (this.isBottom(wrappedDiv, wrappedTable)) {
    }
  };

  componentDidMount() {
    if (!this.props.isFetching) {
      // @ts-ignore
      document.getElementById('div-container').addEventListener('scroll', this.trackScrolling);      
    }
  }

  private confirmAndRemove(event: any, index: number) {
    event.preventDefault();
    const command = () => {
      this.props.closeModal();
      this.props.removeEntry(index, this.props.group);
    };

    this.props.openModal({
      title: 'modal.title.confirm_entry_exclusion',
      active: false,
      content: 'modal.content.confirm_action',
      execute: command
    });
  }

  render() {
    const { t } = this.props;
    if (this.props.isFetching) {
      return (
        <div className="loader-wrapper box">
          <div className="loader" />
        </div>
      );
    }

    if (
      !this.props.isFetching &&
      (!this.props.results || this.props.results.length === 0)
    ) {
      return (
        <div className="container">
          <h3 className="table-title">{this.props.title}</h3>
          <div className="table-container" id="div-container">
            <table
              className="table is-fullwidth is-hoverable is-striped tableCollisions"
              id="table-container"
            >
              <thead>
                <tr>
                  <th className="table-header-centered">{t('settings.tab_actions')}</th>
                  <th>
                    <div className="table-header-with-button">
                      {t('connectivity.label_ip_address')}
                      <button className="button is-dark has-margin-right-md button-content" disabled>
                        <span className="icon">
                          <i className="mdi mdi-plus-circle" />
                        </span>
                        <span>{t('onnet_offnet.label_add_entry')}</span>
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="no-entries-container">
            <p className="has-text-centered">
              {t('onnet_offnet.label_entries_not_found')}
            </p>
          </div>
        </div>
      );
    }

    let keys = ['entry'];

    return (
      <div className="container">
        <h3 className="table-title">{this.props.title}</h3>
        <div className="table-container" id="div-container">
          <table
            className="table is-fullwidth is-hoverable is-striped tableCollisions"
            id="table-container"
          >
            <thead>
              <tr>
                <th className="table-header-centered">{t('settings.tab_actions')}</th>
                <th>
                  <div className="table-header-with-button">
                    {t('connectivity.label_ip_address')}
                    <button className="button is-dark has-margin-right-md button-content" onClick={() => this.newEntry()}>
                      <span className="icon">
                        <i className="mdi mdi-plus-circle" />
                      </span>
                      <span>{t('onnet_offnet.label_add_entry')}</span>
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.showNewEntry && (
                <tr key={0}>
                  <td>
                    <span className="icon has-text-danger">
                      <i
                        className="mdi mdi-24px mdi-delete"
                        style={{ cursor: 'pointer', color: '#d72c2c' }}
                        onClick={() => this.setState(initialState)}
                      />
                    </span>
                    <span className="icon">
                      <i
                        className="mdi mdi-24px mdi-content-save"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.props.saveNewEntry(this.state.entry, this.props.group)
                          this.setState(initialState)
                        }}
                      />
                    </span>
                  </td>
                  {keys &&
                    keys.map((key, indexNew) => {
                      return (
                        <td key={indexNew}>
                          <input
                            defaultValue={''}
                            value={this.state.entry}
                            onChange={(event) => this.setState({ entry: event.target.value })}
                            style={{ width: '100%' }}
                          />
                        </td>
                      );
                    })}
                </tr>
              )}

              {this.props.results &&
                this.props.results.map((result: any, indexTR: number) => {
                  if (result.entryStatus !== 'DELETE') {
                    return (
                      <tr key={indexTR}>
                        <td>
                          <span className="icon has-text-danger">
                            <i
                              className="mdi mdi-24px mdi-delete"
                              style={{ cursor: 'pointer', color: '#d72c2c' }}
                              onClick={(e: any) => this.confirmAndRemove(e, indexTR)}
                            />
                          </span>
                          <span className="icon">
                            {!result.editable && (
                              <i
                                className="mdi mdi-24px mdi-lead-pencil"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.props.selectEntry(indexTR, this.props.group)
                                  this.setState({
                                    entry: result.entry
                                  })
                                }}
                              />
                            )}
                            {result.editable && (
                              <i
                                className="mdi mdi-24px mdi-content-save"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.props.saveEntry(indexTR, this.props.group, this.state.entry)
                                  this.setState(initialState)
                                }}
                              />
                            )}
                          </span>
                        </td>
                        {keys &&
                          keys.map((key, indexTD) => {
                            if (result.editable === true) {
                              return (
                                <td key={indexTD}>
                                  <input
                                    defaultValue={result.entry}
                                    onChange={(event) => this.setState({ entry: event.target.value })}
                                    style={{ width: '100%' }}
                                  />
                                </td>
                              );
                            }
                            return (
                              <td
                                key={indexTD}
                                onDoubleClick={() => this.props.selectEntry(indexTR, this.props.group)}
                                onBlurCapture={() => this.props.selectEntry(indexTR, this.props.group)}
                              >
                                {result.entry}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  }
                  return null;
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
