export function formatToTitleCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function formatCamelCaseToTitleCase(str: string) {
  const result = str
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, match => ` ${match.toLowerCase()}`);
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatCamelCaseToSnakeCase(str: string) {
  return str
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .split(/\s+/)
    .join('_')
    .toLowerCase();
}
