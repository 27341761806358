import { ApplicationState } from 'application/application-redux';
import { FieldItem } from '../../popUp/redux/types';
import { DVB_COLUMNS } from '../../redux/enums';
import { setNetworks, selectNetwork } from '../../redux/redux-networks/action';
import { setNodes, selectNode } from '../../redux/redux-nodes/action';
import { selectRegion, setRegions, getRegions } from '../../redux/redux-regions/action';
import { setServices, selectService } from '../../redux/redux-services/action';
import { setStreams, selectStream } from '../../redux/redux-streams/action';
import { DVBManagementState } from '../../redux/types';
import {
  DVBManagementSearchAction,
  DVBSearch,
  DVB_MANAGEMENT_SEARCH_BUILD,
  DVB_MANAGEMENT_SEARCH_FIELDS,
  DVB_MANAGEMENT_SEARCH_SET_COLUMN,
  DVB_MANAGEMENT_SEARCH_SET_FIELD,
  DVB_MANAGEMENT_SEARCH_TOGGLE,
  DVB_MANAGEMENT_SEARCH_VALUE
} from './types';

function action(type: any, payload?: any): DVBManagementSearchAction {
  return {
    type: type,
    payload: payload
  };
}

function filterList(list: Array<any>, field: string, value: string) {
  let results: Array<any> = [];
  
  list.forEach((item: any) => {
    if (item && item[field]) {
      if (isToSearchWithIncludes(field)) {
        if (item[field].toString().toLowerCase().includes(value)) {
          results.push(item);
        }
      } else {
        if (item[field].toString().toLowerCase() === value) {
          results.push(item);
        }
      }
    }
  });

  return results;
}

function getSelectedField(fields: Array<FieldItem>) {
  if (fields && fields.length) {
    const activeField = fields.find((item: any) => item.isActive);
    return activeField ? activeField.key : fields[0].key;
  }
}

function isToSearchWithIncludes(field: string) {
  return field.toLowerCase().includes('label') || field.toLowerCase().includes('name');
}

export function buildSearch(dvbManagement: DVBManagementState) {
  return {
    type: DVB_MANAGEMENT_SEARCH_BUILD,
    payload: { dvbManagement }
  };
}

export function getSearchFields(column?: string) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    new Promise<void>(resolve => {
      dispatch(action(DVB_MANAGEMENT_SEARCH_FIELDS, { column }));
      resolve();
    }).then(() => {
      const field = getSelectedField(
        getState().management.spectrum.dvbManagement.main.search.fields
      );
      dispatch(action(DVB_MANAGEMENT_SEARCH_SET_FIELD, { field: field || '' }));
      if (getState().management.spectrum.dvbManagement.main.search.columns.length == 0) {
        dispatch(buildSearch(getState().management.spectrum.dvbManagement));
      }
    });
  };
}

export function onClearFilters() {
  return async function(dispatch: Function) {
    new Promise<void>(resolve => {
      dispatch(getSearchFields("region"));
      dispatch(selectRegion());
      dispatch(action(DVB_MANAGEMENT_SEARCH_VALUE, { value: '' }));
      resolve();
    }).then(() => {
      dispatch(getRegions({changes: true}));
    });
  };
}

export function onSearch(dvbManagement: any) {
  return async function(dispatch: Function) {
    const search: DVBSearch = dvbManagement.main.search;
    const searchValue = search.value ? search.value.toLowerCase() : '';
    const columnSelected = search.columns.find(item => item.isActive);
    const columnValue = columnSelected ? columnSelected.key : '';
    const fieldSelected = search.fields.find(item => item.isActive);
    const fieldValue = fieldSelected ? fieldSelected.key : '';
    let listToFilter = dvbManagement[columnValue];

    if (listToFilter) {
      listToFilter = listToFilter.itemsChanged;
    } else {
      listToFilter = [];
    }
    const results: Array<any> =
      searchValue === '' ? listToFilter : filterList(listToFilter, fieldValue, searchValue);

    switch (columnValue) {
      case DVB_COLUMNS.REGION: {
        dispatch(setRegions(results, true));
        dispatch(selectRegion());
        break;
      }
      case DVB_COLUMNS.NODE: {
        dispatch(setNodes(results, undefined, false, true));
        dispatch(selectNode());
        break;
      }
      case DVB_COLUMNS.NETWORK: {
        dispatch(setNetworks(results, false, false, true));
        dispatch(selectNetwork());
        break;
      }
      case DVB_COLUMNS.STREAM: {
        dispatch(setStreams(results, false, false, true));
        dispatch(selectStream());
        break;
      }
      case DVB_COLUMNS.SERVICE: {
        dispatch(setServices(results, false, true));
        dispatch(selectService());
        break;
      }
      default:
        break;
    }
  };
}

export function setColumn(column: string) {
  return async function(dispatch: Function) {
    new Promise<void>(resolve => {
      dispatch(action(DVB_MANAGEMENT_SEARCH_SET_COLUMN, { column }));
      resolve();
    }).then(() => {
      dispatch(getSearchFields(column));
    });
  };
}

export function toggleSearch() {
  return {
    type: DVB_MANAGEMENT_SEARCH_TOGGLE
  };
}
