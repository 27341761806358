import {
  DVB_MANAGEMENT_NETWORK_SELECT,
  DVB_MANAGEMENT_NETWORKS_SET,
  DVBManagementNetworkAction
} from './types';
import { selectStream, setStreams } from '../redux-streams/action';
import { ApplicationState } from 'application/application-redux';
import { mergeChanges } from '../action';
import { DVB_COLUMNS } from '../enums';

function action(type: any, payload?: any): DVBManagementNetworkAction {
  return {
    type: type,
    payload: payload
  };
}

export function selectNetwork(networkId?: string) {
  return async function(dispatch: Function, getState: () => ApplicationState) {
    dispatch(setStreams(undefined, true));
    dispatch(selectStream());
    dispatch(action(DVB_MANAGEMENT_NETWORK_SELECT, networkId));

    const selecteNetworkChildren =
      getState().management.spectrum.dvbManagement.network.selected?.children || [];
    dispatch(setStreams(selecteNetworkChildren && networkId ? selecteNetworkChildren : undefined));
  };
}

export function setNetworks(item?: any, isToClear?: boolean, isAux?: boolean, isSearch?: boolean) {
  return async function(dispatch: Function) {
    onSetNetworks(dispatch, item, isToClear, isAux, isSearch);

    if (item) {
      dispatch(mergeChanges(DVB_COLUMNS.NETWORK));
    }
  };
}

export function onSetNetworks(
  dispatch: Function,
  item?: any,
  isToClear?: boolean,
  isAux?: boolean,
  isSearch?: boolean
) {
  dispatch(action(DVB_MANAGEMENT_NETWORKS_SET, { item, isToClear, isAux, isSearch }));
}
