import { ChevronDown } from 'assets/svg';
import DateUtils from 'core/utils/date-utils';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { paginationRfTunning } from 'core/pagination-optimized/redux/config';
import React, { Component, Fragment } from 'react';
import { RfTuningHistoryResult } from './redux/types';
import RfTuningTableComponent from './rf-tuning-table-component';
import NoRecordsComponent from 'core/no-records/no-records-container';

export interface RfTuningStatusComponentStatsProps {
  fetchHistory: (id: string, assetName: string, filter?: PaginationConfig) => void;
  toggleAccordion: (index: number) => void;
  hhid: string;
  id: string;
  resultHistoryToShow: Array<RfTuningHistoryResult>;
  frequencyLimits: any;
  historyType: string;
}

export default class RfTuningFullFrequencyHistoryComponent extends Component<RfTuningStatusComponentStatsProps> {
  componentDidMount() {
    const { fetchHistory, id, historyType } = this.props;
    fetchHistory(id, historyType, paginationRfTunning);
  }

  isEmpty() {
    return !this.props.resultHistoryToShow || this.props.resultHistoryToShow.length === 0;
  }

  public render() {
    return (
      <Fragment>
        {this.isEmpty() && (
          <div className="has-text-centered">
            <NoRecordsComponent />
          </div>
        )}

        <ul className="accordion">
          {this.props.resultHistoryToShow &&
            this.props.resultHistoryToShow.map((item: RfTuningHistoryResult, index: number) => (
              <li
                className={`accordion-item ${
                  !item.accordionActive ? 'accordion-item-collapsed' : ''
                }`}
                key={index}
              >
                <button
                  className="accordion-heading"
                  onClick={() => this.props.toggleAccordion(index)}
                >
                  <span className="chevron">
                    <span className="chevron-text">{DateUtils.formatDate(item.finishedAt)}</span>
                    <span className="chevron-container accordion-chevron">
                      <ChevronDown className="icon icon-svg" />
                    </span>
                  </span>
                </button>
                <div className="accordion-content">
                  <RfTuningTableComponent
                    frequencyHistories={item.frequencyHistories}
                    frequencyLimits={this.props.frequencyLimits}
                  />
                </div>
              </li>
            ))}
        </ul>
      </Fragment>
    );
  }
}
