import { connect } from 'react-redux';
import PaginationComponent from './pagination-component';
import { goToFirstPage, goToNextPage, goToPreviousPage, goToLastPage } from './redux/actions';

const mapStateToProps = (state: any, ownProps: any) => ({
  pagination: state.pagination,
  itemsPerPage: ownProps.itemsPerPage,
  numberItems: ownProps.numberItems,
  search: state.search,
  t: state.i18n.t
});

export default connect(mapStateToProps, {
  goToFirstPage,
  goToNextPage,
  goToPreviousPage,
  goToLastPage
})(PaginationComponent);
