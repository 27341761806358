export const PROV_DEVICES_FAILURE = 'PROV_DEVICES_FAILURE';
export const PROV_DEVICES_SUCCESS = 'PROV_DEVICES_SUCCESS';
export const PROV_DEVICES_SEARCH = 'ACCOUNT_SEARCH_PROV_DEVICES';
export const ON_DELETE_PROV_DEVICE = 'ON_DELETE_PROV_DEVICE';
export const PROV_DEVICES_CLEAR_CACHE = 'ACCOUNT_CLEAR_CACHE';
export const PROV_DEVICES_CHANGE_PAGE = 'PROV_DEVICES_CHANGE_PAGE'

export interface ProvisionedDevicesAction {
  type: string;
  payload?: any;
}

export interface ProvisionedDeviceItem {
  udid: string;
  createDate: string;
  deviceType: string;
  deviceFamily: string;
  lastTechnicalDataEventDate: string;
  hasDeleteAction?: boolean;
}

export interface ProvisionedDevicesState {
  items?: Array<ProvisionedDeviceItem>;
  totalElements?: number;
  itemsToShow?: Array<ProvisionedDeviceItem>; 
}
