import React, { Component } from 'react';
import './toggle-swtich-styles.scss';

interface ToggleSwitchProps {
  id: string;
  text: string[];
  disabled: boolean;
  onChangeToggleSwitch: any;
  currentToggleValue: string;
}

export default class ToggleSwitch extends Component<ToggleSwitchProps> {
  render() {
    const { id, disabled, text, onChangeToggleSwitch, currentToggleValue } = this.props;

    return (
      <div className="toggle-switch">
        <input
          disabled={disabled}
          id={id}
          onChange={() => onChangeToggleSwitch(currentToggleValue)}
          type="checkbox"
          value={currentToggleValue}
          defaultChecked={currentToggleValue ? true : false}
        />
        {id && (
          <label className="toggle-switch-label" htmlFor={id}>
            <span
              className={
                disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'
              }
              data-yes={text[0]}
              data-no={text[1]}
            />
            <span
              className={
                disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'
              }
            />
          </label>
        )}
      </div>
    );
  }
}
