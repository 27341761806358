export enum UnitEnum {
  SECONDS = 'seconds',
  HOURS = 'hours',
  MONTHS = 'months',
  YEARS = 'years',
}

export type ServiceType = {
  id: string, 
  exp: { 
    unit: UnitEnum, 
    value: number 
  }
}

export type ServiceTypeResponse = Array<ServiceType> | null

export interface HeaderProps {
  selected?: ServiceType | number
  services: Array<ServiceType>
  createService: CreateServiceFunction
}

export interface ServicesFormInterface {
  service?: ServiceType
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  serviceAction: CreateServiceFunction | UpdateServiceFunction
}

export interface AnonymizerAction {
  type: string
  payload: any
}

export interface AnonymizerBodyInterface {
  hash: HashType
  service: ServiceType
  updateService: UpdateServiceFunction
  deleteService: DeleteServiceFunction
  fetchHashByAid: FetchHashByAidFunction
  fetchHashById: FetchHashByIdFunction
  deleteHash: DeleteHashFunction
}

export interface ServicesManagementInterface {
  service: ServiceType
  updateService: UpdateServiceFunction
  deleteService: DeleteServiceFunction
}

export interface DeleteConfirmInterface {
  title: string
  body: JSX.Element | Array<JSX.Element> | string
  params: string | DeleteActionParams
  action: (id: string | DeleteActionParams) => void
  setActive: React.Dispatch<React.SetStateAction<boolean>>
}

export type FetchAllServicesFunction = () => void

export type CreateServiceFunction = (params: ServiceType) => void

export type UpdateServiceFunction = (params: ServiceType) => void

export type DeleteActionParams = {
  serviceId: string
  id: string
}

export type DeleteServiceFunction = (params: any) => void

export type HashType = {
  exp: string
  id?: string
  aid?: string
}

export interface HashesManagementInterface {
  hash: HashType
  service: ServiceType
  fetchHashByAId: FetchHashByAidFunction
  fetchHashById: FetchHashByIdFunction
  deleteHash: DeleteHashFunction
}

export type RequestHashParams = {
  serviceId: string
  id: string
}

export type HashResponseType = {
  result: {
    exp: string
    aid?: string
    id?: string
  }
}

export type FetchHashByAidFunction = (params: RequestHashParams) => void

export type FetchHashByIdFunction = (params: RequestHashParams) => void

export type DeleteHashFunction = (params: any) => void

export interface ServiceAction {
  type: string
  payload: ServiceType
}
