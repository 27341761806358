import React, { Component } from 'react';
import EsnMappingForm from './form';
import EsnMappingGridComponent from './grid';

interface IdMappingComponentProps {
  startRefresh: any;
  stopRefresh: any;
  search: any;
  queries: Array<any>;
}

export default class EsnMappingComponent extends Component<IdMappingComponentProps> {
  componentDidMount() {
    this.props.search();
    this.props.startRefresh();
  }

  componentWillUnmount() {
    this.props.stopRefresh();
  }

  render() {
    return (
      <div className="section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-child">
              <div className="section">
                <EsnMappingForm />
                <EsnMappingGridComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
