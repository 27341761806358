import { CAROUSEL_SET_INDEX } from './types';

export function setActiveIndex(index: number) {
  return {
    type: CAROUSEL_SET_INDEX,
    payload: {
      index
    }
  };
}
