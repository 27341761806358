import authService from 'core/auth';
import apiClient from 'core/api';
import { ApplicationState } from 'application/application-redux';
import { addToaster } from 'core/toaster/redux/actions';
import { add, remove } from 'core/spinner/redux/actions';
import { TFunction } from 'i18next';
import { envs } from 'application/envHandler';

export function actions(type: string, data?: any) {
  return {
    type,
    payload: {
      data
    }
  };
}

export function getAssetValuesFields(callback: any, toasterTitle: string, t: TFunction) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    await authService.refreshToken();
    if ( getState().devices.detail.settings.assetsValues ){
      return callback(getState().devices.detail.settings.assetsValues);
    }

    dispatch(add('ASSET_VALUES', {}, false));
    return apiClient
      .get(`${envs.REACT_APP_API_URL}/assets-values`)
      .then((response: any) => {
        const assetsValues = response.data;
        if (!assetsValues) {
          dispatch(
            addToaster({
              title: t(toasterTitle),
              message: t('error_messages.VIPOC_ERROR_049'),
              type: 'danger'
            })
          );
        }
        dispatch(remove('ASSET_VALUES', false));
        return callback(assetsValues);
      })
      .catch((error: any) => {
        dispatch(remove('ASSET_VALUES', false));
        dispatch(
          addToaster({
            title: t(toasterTitle),
            message: t('error_messages.VIPOC_ERROR_049'),
            type: 'danger'
          })
        );
        return dispatch(actions('failure'));
      });
  };
}
