import { NAVBAR_CHANGE_CURRENT_TAB, MenuItem, NAVBAR_GET_SELECTED_SUBTABS } from './types';

export function getSubMenus(id: string, selectedTab?: string) {
  return {
    type: NAVBAR_GET_SELECTED_SUBTABS,
    payload: { id, selectedTab }
  };
}

export function changeMenu(tab: string, id: string) {
  return {
    type: NAVBAR_CHANGE_CURRENT_TAB,
    payload: {
      tab,
      id
    }
  };
}

export function getCurrentNavbarMenu(currentTab?: MenuItem, currentPage?: string) {
  return currentPage ? currentPage : currentTab?.value;
}
