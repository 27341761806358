import React, { Component } from 'react';

interface SpinnerProps {
  isActive: boolean;
}

export default class SpinnerComponent extends Component<SpinnerProps> {
  handleSpinnerStatus = () => {
    const { isActive } = this.props;
    if (isActive) {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.removeProperty('overflow-y');
    }

    return isActive;
  };

  render() {
    return (
      <div className={`loader-container${this.handleSpinnerStatus() ? ' is-active' : ''}`}>
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      </div>
    );
  }
}
