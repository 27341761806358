import React, { Component, Fragment } from 'react';
import T from 'core/i18n/t';
import RfTuningScanComponent from './scan';
import RfTuningFullFrequencyComponent from './status';
import Topbar from '../topbar/topbar-container';
import RfTuningDvbcComponent from './dvbc/rf-tuning-dvbc-monitoring-container';

interface RfTuningComponentProps {
  changeTab: any;
  currentTab: string;
  currentFrequencyTab: string;
  hhid: string;
  id: string;
}

export default class RfTuningComponent extends Component<RfTuningComponentProps> {
  toggleTab(tab: string) {
    const { changeTab } = this.props;
    changeTab(tab);
  }

  render() {
    const { currentTab, id, hhid } = this.props;
    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical has-margin-bottom-lg">
          <div className="tile is-parent is-paddingless is-horizontal">
            <div className="container is-fluid has-margin-none">
              <div className="tabs is-medium">
                <ul>
                  <li className={currentTab === 'full' ? 'is-active' : ''}>
                    <button className="button-link" onClick={() => this.toggleTab('full')}>
                      <T>rf_tuning.tab_full_frequency_scan</T>
                    </button>
                  </li>
                  <li className={currentTab === 'custom' ? 'is-active' : ''}>
                    <button className="button-link" onClick={() => this.toggleTab('custom')}>
                      <T>rf_tuning.tab_custom_frequency_scan</T>
                    </button>
                  </li>
                  <li className={currentTab === 'dvbc' ? 'is-active' : ''}>
                    <button className="button-link" onClick={() => this.toggleTab('dvbc')}>
                      <T>rf_tuning.tab_dvbc_monitoring</T>
                    </button>
                  </li>
                </ul>
              </div>
              {currentTab === 'full' && <RfTuningFullFrequencyComponent id={id} />}
              {currentTab === 'custom' && <RfTuningScanComponent id={id} hhid={hhid} />}
              {currentTab === 'dvbc' && <RfTuningDvbcComponent />}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
