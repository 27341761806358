import moment, { Moment } from 'moment';

export default class DateUtils {
  static daysBetween(date1Ms: any, date2Ms: any) {
    // Calculate the difference in milliseconds
    let differenceMs = date2Ms - date1Ms;
    // take out milliseconds
    differenceMs /= 1000;
    const seconds = Math.floor(differenceMs % 60);
    differenceMs /= 60;
    const minutes = Math.floor(differenceMs % 60);
    differenceMs /= 60;
    const hours = Math.floor(differenceMs % 24);
    const days = Math.floor(differenceMs / 24);
    let formatedDate = '';
    formatedDate += days > 0 ? (days > 1 ? `${days} days ` : `${days} day `) : '';
    formatedDate += hours.toString().length === 1 ? `0${hours}h ` : `${hours}h `;
    formatedDate += minutes.toString().length === 1 ? `0${minutes}m ` : `${minutes}m `;
    formatedDate += seconds.toString().length === 1 ? `0${seconds}s ` : `${seconds}s `;
    return formatedDate;
  }

  static parseToIotTimestamp(date: Moment) {
    if (date) {
      return date
        .valueOf()
        .toString()
        .substring(0, date.valueOf().toString().length - 3);
    }
    return '';
  }

  static formatFromIotTimestamp(date: any, format?: string) {
    if (date) {
      const dateFormat = format || 'YYYY-MM-DD HH:mm:ss';
      return moment(date)
        .format(dateFormat)
        .toString();
    }
    return '';
  }

  static formatDate(date: any, format?: string) {
    if (date) {
      const dateFormat = format || 'YYYY-MM-DD HH:mm:ss';
      return moment
        .utc(date)
        .format(dateFormat)
        .toString();
    }
    return '';
  }

  static formatUnixDate(date: any, format?: string) {
    if (date) {
      const dateFormat = format || 'YYYY-MM-DD HH:mm:ss';
      return moment
        .unix(date)
        .utc(false)
        .format(dateFormat)
        .toString();
    }
    return '';
  }

  static stringToDate(_date: any, _format: any, _delimiter: any) {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex], 10);
    month -= 1;
    return new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  }

  static convertConnectedFor(epochDiff: any) {
    // take out milliseconds
    const epoch = epochDiff / 10000 > 1000 ? epochDiff / 1000 : epochDiff;
    const seconds = Math.floor(epoch % 60);
    let differenceSs = epochDiff / 60;
    const minutes = Math.floor(differenceSs % 60);
    differenceSs /= 60;
    const hours = Math.floor(differenceSs % 24);
    const days = Math.floor(differenceSs / 24);
    let formatedDate = '';
    formatedDate += days > 0 ? (days > 1 ? `${days} days ` : `${days} day `) : '';
    formatedDate += hours.toString().length === 1 ? `0${hours}h ` : `${hours}h `;
    formatedDate += minutes.toString().length === 1 ? `0${minutes}m ` : `${minutes}m `;
    formatedDate += seconds.toString().length === 1 ? `0${seconds}s ` : `${seconds}s `;
    return formatedDate;
  }
}
