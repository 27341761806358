import { combineReducers } from "redux";
import servicesReducers from './services'
import hashesReducers from './hashes'
import serviceReducers from './service'

export default combineReducers({
  services: servicesReducers,
  hash: hashesReducers,
  service: serviceReducers,
})
