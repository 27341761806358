import {
  SubscriptionsState,
  SubscriptionAction,
  SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_CLEAR_CACHE
} from './types';

const initialState: SubscriptionsState = {
  items: undefined,
  totalElements: undefined
};

export default function(state = initialState, action: SubscriptionAction) {
  switch (action.type) {
    case SUBSCRIPTIONS_SUCCESS:
      state.items = action.payload.items;
      state.totalElements = action.payload.totalElements;
      return {
        ...state
      };
    case SUBSCRIPTIONS_CLEAR_CACHE:
      return {
        ...state,
        items: undefined,
        totalElements: undefined
      };
    default:
      return state;
  }
}
