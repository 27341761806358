import apiClient from 'core/api';
import { add, remove } from 'core/spinner/redux/actions';
import AuthService from 'core/auth';
import queryString from 'core/utils/query-string';
import { addToaster } from 'core/toaster/redux/actions';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { ApplicationState } from 'application/application-redux';
import {
  PROV_DEVICES_FAILURE,
  PROV_DEVICES_SEARCH,
  PROV_DEVICES_SUCCESS,
  ProvisionedDevicesAction,
  ON_DELETE_PROV_DEVICE,
  PROV_DEVICES_CLEAR_CACHE,
  PROV_DEVICES_CHANGE_PAGE
} from './types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

function successProvDevices(payload: any): ProvisionedDevicesAction {
  return {
    type: PROV_DEVICES_SUCCESS,
    payload
  };
}

function successDeleteProvDevice(payload: any): ProvisionedDevicesAction {
  return {
    type: ON_DELETE_PROV_DEVICE,
    payload
  };
}

function failure(payload: any): ProvisionedDevicesAction {
  return {
    type: PROV_DEVICES_FAILURE,
    payload
  };
}

export function clearCacheProvDevices(): ProvisionedDevicesAction {
  return {
    type: PROV_DEVICES_CLEAR_CACHE
  };
}

export function fetchProvisionedDevices(hhid: string, filter?: PaginationConfig) {
  return async (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(add(PROV_DEVICES_SEARCH, {}));
    await AuthService.refreshToken();
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/device-hologram/hhid/${hhid}/account/provisioned-devices?${queryString(
          filter,
          getState().application.offset
        )}`
      )
      .then(
        (response) => {
          dispatch(remove(PROV_DEVICES_SEARCH));
          return dispatch(successProvDevices(response.data));
        },
        (rejection) => {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_account',
              message: 'error_messages.VIPOC_ERROR_015',
              type: 'danger'
            })
          );
          dispatch(remove(PROV_DEVICES_SEARCH));
          return dispatch(failure(rejection));
        }
      );
  };
}

export function onDelete(unmanagedDeviceId: string, udid: string, hhid: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(ON_DELETE_PROV_DEVICE, {}));
    await AuthService.refreshToken();

    const dataToSend: any = {
      deviceId: udid,
      hhid,
      unmanagedDeviceId
    };

    apiClient.post(`${envs.REACT_APP_API_URL}/integration/device/remove`, dataToSend).then(
      (response) => {
        if (response.data.httpCode === '400') {
          const { jobId } = response.data.responseJson;
          handleCommandError(
            dispatch,
            jobId,
            'account.provisioned_devices.delete.title',
            getState().i18n.t,
            'ON_DELETE_PROV_DEVICE'
          );
        } else {
          dispatch(remove(ON_DELETE_PROV_DEVICE));
          if (response.data.responseJson.result === 'true') {
            dispatch(
              addToaster({
                title: 'account.provisioned_devices.delete.title',
                message: 'account.provisioned_devices.delete.success',
                type: 'success'
              })
            );
            return dispatch(successDeleteProvDevice(response.data));
          } else {
            dispatch(
              addToaster({
                title: 'account.provisioned_devices.delete.title',
                message: 'error_messages.VIPOC_ERROR_034',
                type: 'danger'
              })
            );
            return dispatch(failure(response.data));
          }
        }
      },
      (rejection) => {
        const message: string =
          rejection === 'error_messages.permission_denied'
            ? rejection
            : 'error_messages.VIPOC_ERROR_034';
        dispatch(
          addToaster({
            title: 'account.provisioned_devices.delete.title',
            message,
            type: 'danger'
          })
        );
        dispatch(remove(ON_DELETE_PROV_DEVICE));
        return dispatch(failure(rejection));
      }
    );
  };
}

export function changePageProvDevices(startPage: Number, endPage: Number) {
  return {
    type: PROV_DEVICES_CHANGE_PAGE,
    payload: {
      startPage,
      endPage
    }
  };
}
