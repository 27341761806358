import { PaginationConfig } from 'core/pagination-optimized/redux/types';

export interface TableConfig {
  pagination: PaginationConfig;
}

export interface TableItems {
  totalElements: number;
  items: Array<any>;
}

export function filterMapper(
  filter: any,
  pagination?: PaginationConfig,
  paginationId?: string
): PaginationConfig {
  const paginationConfig: any = {};

  if (pagination) {
    paginationConfig.pageNumber = pagination.pageNumber;
    paginationConfig.pageSize = pagination.pageSize;
    paginationConfig.sortField =
      paginationId && translate[paginationId] && translate[paginationId][pagination.sortField || '']
        ? translate[paginationId][pagination.sortField || '']
        : pagination.sortField;
    paginationConfig.sortOrder = pagination.sortOrder;
  }

  if (filter && filter.type) {
    paginationConfig[filter.type.value] = filter.value;
  }
  return paginationConfig;
}

export const translate: any = {
  speed_test: {
    pingTimeMS: 'pingTimeMS',
    downloadBitrateAVG: 'downloadBitrateAVG',
    finishedAt: 'finishedAt'
  },
  'account.provisioned_devices': {
    udid: 'device_id',
    createDate: 'create_date',
    deviceType: 'device_type',
    lastTechnicalDataEventDate: 'event_ts',
    deviceFamily: 'ds_device_family'
  },
  'account.subscriptions': {
    id: 'id',
    transactionId: 'transaction_id',
    name: 'module_name',
    purchaseDate: 'purchase_date',
    renewalDate: 'renewal_date',
    price: 'price',
    deviceFamily: 'ds_device_family'
  },
  vod: {
    assetId: 'mediaid',
    transactionId: 'transactionid',
    assetTitle: 'title',
    assetType: 'asset_type',
    purchaseDate: 'purchasedate',
    expiryDate: 'enddate',
    duration: 'runtimemins',
    watched: 'maxviewts'
  }
};
