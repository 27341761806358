import { ApplicationState } from 'application/application-redux';
import {
  FILE_UPLOAD_CLOSE,
  SELECTED_FILE_SUCCESS,
  SELECTED_FILE_FAILURE,
  LAST_SELECTED_FILE
} from './types';
import AuthService from 'core/auth/auth-service';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { envs } from 'application/envHandler';

export function isFileNameValid(fileName: string, opco: string, module: string) {

  opco = opco.toUpperCase();
  const lastFileStart = fileName.split('_').slice(0, 2);
  lastFileStart[0] = lastFileStart[0].toUpperCase();
  const fileStart = lastFileStart.join('_') + '_';
  let fileEnd = fileName.split('_').slice(2).toString();
  const fileDate = fileEnd.split('.').slice(0, 1).toString();
  const fileFormat = fileEnd.split('.').slice(1)[0];

  if ( module === 'dvbManagement' &&
      (fileStart !== String(opco) + '_dvbUpdate_' ||
          !/\d{4}-\d{2}-\d{2}/.test(fileEnd) ||
          fileDate.length !== 10 ||
          fileFormat !== 'csv'))
    return false;
  else if ( module === 'regionalization' &&
      (fileStart !== String(opco) + '_regionalization_' ||
          !/\d{4}-\d{2}-\d{2}/.test(fileEnd) ||
          fileDate.length !== 10 ||
          fileFormat !== 'xlsx'))
    return false;
  else return true;
}

export function fileSelectedHandler(file: any, jsonFile?: {}, type?: string, pageType?: string) {
  return async (dispatch:any) => {
    let format = '';
    let errorMsg: any = [];

    if (type === 'CSV') {
      format = 'text/csv';
    }
    if (type === 'XLSX') {
      format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    if (jsonFile && Object.keys(jsonFile) && type) {
      const values: [] = Object.values(jsonFile)[0] as [];
      errorMsg = dataGrabberUploadHandler(file, jsonFile, format);
      if (errorMsg.length === 0) {
        return fileSuccess(file, values);
      }
      return fileFailure(file, errorMsg);
    } else {

      const isStructureValid: any = pageType ? await readFile(file, pageType) : 'Success';

      const isNameValid =
          pageType && file.name != undefined
              ? isFileNameValid(file.name, 'vfde', pageType)
              : true;

      errorMsg = [
        'There was an error while selecting the file to Spectrum.',
        `${!(file.size <= 5242880) ? `Please select a .${type && type.toLowerCase()} file with less than 5MB.` : ''}
        ${!isNameValid ?
            `File name is not in the correct format! Make sure the file name follows the following format:
          \nDVB-Management: {OpcoID}_dvbUpdate_{YYYY-MM-DD}.csv
          \nRegionalization: {OpcoID}_regionalization_{YYYY-MM-DD}.xlsx"`
            : isStructureValid != 'Success' ? isStructureValid.toString() : ''}`
      ];

      if (file && file.type == format && file.size <= 5242880 && isNameValid && isStructureValid == 'Success') {
        return dispatch(fileSuccess(file));
      }
      if (type && ((file && file.type != format) || file.size >= 5242880 || !isNameValid || isStructureValid != 'Success')) {
        return dispatch(fileFailure(file, errorMsg));
      } else {
        return dispatch(fileLastSelected(file));
      }
    }
  }
}

async function readFile(file: any, module?: string){

  return await new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = async function fileReadCompleted(e: any) {
      let data = e.target.result;
      let workbook = XLSX.read(data, {
        type: 'binary'
      });

      let errors: string[] = [];
      let expectedKeys: string[] = [];
      let expectedNumericKeys: string[] = [];
      let expectedNonZeroKeys: string[] = [];
      let expectedRequiredKeys: string[] = [];
      workbook.SheetNames.every(function (sheetName) {
        if(module === 'regionalization') {
          switch (sheetName) {
            case 'Regions & Nodes':
              expectedKeys = ['regionId', 'regionLabel', 'nodeId', 'nodeLabel', 'action'];
              break;
            case 'Regional Channels':
              expectedKeys = ['regionLabel', 'name', 'mediaId', 'regionalLCN', 'action'];
              break;
            case 'Local Channels':
              expectedKeys = ['nodeId', 'name', 'mediaId', 'regionalLCN', 'action'];
              break;
            default:
              errors.push('Error: Sheet name \''+ sheetName + '\' is not expected. Expected sheet names are: Regions & Nodes, Regional Channels and Local Channels');
              break;
          }

          if(errors.length != 0){ // retornar os erros caso estejam no nome das páginas
            resolve(errors);
            return false;
          }

        }else if(module === 'dvbManagement'){
          expectedKeys = ['regionLabel','nodeLabel','nodeId','onId','tsId','freq','mod','symRate','dvbType','serviceId','serviceName','serviceType','scramble','eitPresFlwFlag','mediaId','lcn','lcn2'];
          expectedRequiredKeys = ['regionLabel', 'nodeLabel', 'nodeId', 'onId', 'tsId', 'serviceId', 'mediaId'];
          expectedNumericKeys = ['onId','tsId','freq','mod','symRate','serviceId','eitPresFlwFlag','mediaId','lcn','lcn2'];
          expectedNonZeroKeys = ['onId', 'tsId', 'mod', 'serviceId', 'mediaId'];
        }

        let file_headers: any = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {raw: false, blankrows: true, header: 1})[0];
        let hasExpectedKeys = expectedKeys.filter(x => !file_headers.includes(x))
        if (hasExpectedKeys.length > 0) {  // if key doesn't match the ones that are expected it fails (for DVB Management and Regionalization).
          errors.push(String("\n Unexpected headers detected. The headers should be: " + expectedKeys.join(', ')));
          resolve('Error(s) in line 1'+(module === 'regionalization' ? '. On page: '+ sheetName + ' ' : '') + ': '+errors.join(', ')+'.');
          return false;
        }// in this case we should stop execution and return the error right away

        let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {raw: false, blankrows: true});

        let line: any;

        for (line of XL_row_object) {
          const row = XL_row_object.findIndex(object => {
            return object === line;
          }) + 2;

          if(module === 'dvbManagement'){
            let hasRequiredKeys = expectedRequiredKeys.filter(x => !Object.keys(line).includes(x))
            if (hasRequiredKeys.length > 0){ // the following fields cannot have empty values
              errors.push(String("\n The following headers can\'t have empty values: "+ hasRequiredKeys.join(', ')));
            }
          }

          for (let key of Object.keys(line)) {

            if (module === 'dvbManagement'){  // DVB Management validations
              if(expectedNumericKeys.includes(key) && Number(line[key]) < 0){ // with negative values
                errors.push(String('\n '+key + ' can not have negative numbers '));
              } else if (expectedNumericKeys.includes(key) && !(/^\d+$/.test(line[key]))){ // cannot have numeric fields with alphanumeric characters
                errors.push(String('\n '+key + ' only allows numbers not alphanumeric characters'));
              }
              if (expectedNonZeroKeys.includes(key) && Number(line[key]) === 0){  // cannot have zero values on values: onId, tsId, mod, serviceId, mediaId
                errors.push(String('\n '+key + ' can not be 0'));
              }
              if (key === 'mod' && !['1', '2', '4', '8', '16', '32', '64', '128', '256'].includes(line['mod'])){ // mod field does not allow values other than 1,2,4,8,16,32,64,128,256
                errors.push(String('\n '+key + ' can only be 1,2,4,8,16,32,64,128,256'));
              }
              if (key === 'dvbType' && !['DVB-S','DVB-C','DVB-T','DVB-IP','ABR'].includes(line['dvbType'])){ // dvbType must be one of these values
                errors.push(String('\n '+key + ' can only be DVB-S, DVB-C, DVB-T, DVB-IP, ABR'));
              }
              if (['scramble', 'eitPresFlwFlag'].includes(key) && !(Number(line[key]) >= 0 && Number(line[key]) <= 1)){ // scramble and eitPresFlwFlag fields cannot have value other than 0 or 1
                errors.push(String('\n '+key + ' can only be 0 or 1'));
              }
              if (key === 'serviceType' && !(Boolean((/^0x[0-9a-f]+$/i).test(line[key])) && line[key].length <= 4)){ // serviceType must start with 0X and have 2 characters after that are base16
                errors.push(String('\n '+key + ' must start with 0X and have 2 hexadecimal characters'));
              }
            }else if (module === 'regionalization'){

              if (key === 'action' && !['Add', 'Update', 'Delete'].includes(line['action'])){ // mod field does not allow values other than 1,2,4,8,16,32,64,128,256
                errors.push(String('\n '+key + ' can only be Add, Update or Delete'));
              }
            }
          }
          if(errors.length != 0){
            resolve('Error(s) in line '+row+ (module === 'regionalization' ? '. On page: '+ sheetName + ' ' : '') + ': '+errors.join(', ')+'.');
            return false;
          }
        }
        if (errors.length === 0){ // in order to continue the every() loop
          return true;
        }
      })

      if (errors.length === 0){
        resolve('Success');
        return true;
      }
    };
    reader.onerror = function (ex) {
      resolve('Unknown error')
    };

    reader.readAsBinaryString(file)
  });

}

function fileSuccess (file: any, fileContent?: any) {
  return {
    type: SELECTED_FILE_SUCCESS,
    payload: { file, fileContent }
  }
};

function fileFailure(file: any, errorMsg: []) {
  return {
    type: SELECTED_FILE_FAILURE,
    payload: { file, errorMsg }
  };
}

function fileLastSelected(file: any) {
  return {
    type: LAST_SELECTED_FILE,
    payload: file
  };
}

function dataGrabberUploadHandler(file: any, jsonFile: {}, type: string): [] {
  let errorMsg: any = [];
  const values: [] = Object.values(jsonFile)[0] as [];
  const hasCorrectLength = values.some((item: any) => item.length !== 12);
  const fileName = 'BSS_reProcess_' + moment(new Date()).format('DDMMYY') + '.csv';

  if (file && file.type != type) {
    errorMsg.push('datagrabber.upload_error_file_type');
    return errorMsg;
  }
  if (file.name !== fileName) errorMsg.push('datagrabber.upload_error_filename');
  if (Object.entries(jsonFile)[0][0] !== 'CAS_STB')
    errorMsg.push('datagrabber.upload_error_headline');
  if (hasCorrectLength) errorMsg.push('datagrabber.upload_error_length');
  if (values.length > 15000) errorMsg.push('datagrabber.upload_error_size');
  return errorMsg;
}

export const closeUpload = () => ({
  type: FILE_UPLOAD_CLOSE
});

export function fileUploadHandler(file?: any, pageType?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const formData = new FormData();
    formData.append('file', file.selectFile);
    formData.append('type', file.selectFile.type);
    const opcoId = 'vfde';
    const baseUrl = `${envs.REACT_APP_API_URL}/be/spectrum/upload/opcos/${opcoId}`;
    await AuthService.refreshToken();
    const currentUser = AuthService.getCurrentUser().username;
    apiClient
        .post(`${baseUrl}?userId=${currentUser}&type=${pageType}`, formData, {
          headers: { 'Content-Type': file.selectFile.type }
        })
        .then(
            (response) => {
              return dispatch(
                  addToaster({
                    title: 'Successful',
                    message: 'The file was successfully uploaded to Spectrum.',
                    type: 'success'
                  })
              );
            },
            (rejection) => {
              return dispatch(
                  addToaster({
                    title: 'Error',
                    message: rejection,
                    type: 'danger'
                  })
              );
            }
        );
  };
}
