import ErrorPage from "core/error-page";
import SpinnerComponent from "core/spinner/spinner-component";
import { useEffect, useState } from "react";
import ApplicationContainer from "./application-boostrapper";
import { configureStore } from "./application-redux";
import { preLoadRoutes } from "./application-routes";
import { initializeEnvs } from "./envHandler";

export default function ApplicationWrapper() {
  const [loading, setLoading] = useState(true)
  const [envsSet, setEnvsSet] = useState(false)

  useEffect(() => {
    const fetchEnvs = async () => {
      setLoading(true)

      const envsSuccessfullyInitialized = await initializeEnvs()

      setEnvsSet(envsSuccessfullyInitialized)
      setLoading(false)
    }

    fetchEnvs()
  }, [])

  if (!loading && !envsSet) return <ErrorPage />
  if (!loading && envsSet) return <ApplicationContainer />
  return <SpinnerComponent isActive />
}
