import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import TableComponent from 'core/table/table-container';
import { paginationVOD } from 'core/pagination-optimized/redux/config';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { VodState } from './redux/types';
import mapper, { getLabels } from './redux/mapper';
import Topbar from '../topbar/topbar-container';

interface VodComponentProps {
  fetch: (hhid: string, filter?: PaginationConfig, days?: number) => void;
  hhid: string;
  id: string;
  t: TFunction;
  vod: VodState;
}

export default class VodComponent extends Component<VodComponentProps> {
  componentDidMount() {
    const { fetch, hhid, vod } = this.props;
    if (vod.items === undefined) {
      fetch(hhid, paginationVOD);
    }
  }

  render() {
    const { fetch, id, t, vod, hhid } = this.props;
    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile is-parent is-vertical has-margin-bottom-lg">
          <TableComponent
            hasSlider
            id="vod"
            items={vod && vod.items && vod.items.map(item => mapper(item, t))}
            columns={getLabels(t)}
            onChangePageSize={(filter: any, days: number) => fetch(hhid, filter, days)}
            onSearch={(filter: any, days: number) => fetch(hhid, filter, days)}
            pagination={paginationVOD}
            title={t('all_device_pages.menu_vod')}
            totalElements={vod && vod.totalElements}
            sortableItems={[]}
          />
        </div>
      </Fragment>
    );
  }
}
