import {
  DeviceDetailOverviewAction,
  DeviceOverviewState,
  OVERVIEW_USER_CONVERSION_SUCCESS
} from './types';

const initialState: DeviceOverviewState = {};

export default function(state = initialState, action: DeviceDetailOverviewAction) {
  switch (action.type) {
    case OVERVIEW_USER_CONVERSION_SUCCESS:
      return {
        ...state,
        userConversion: {
          channels: {
            current:
              action.payload && action.payload.new_desc
                ? action.payload.new_desc
                : action.payload.notAvailable,
            previous:
              action.payload && action.payload.prev_desc
                ? action.payload.prev_desc
                : action.payload.notAvailable
          }
        }
      };
    default:
      return state;
  }
}

/*
import { CommandState, CommandAction } from './types';

const initialState: CommandState = {
  last: {}
};

export function(state = initialState, action: CommandAction) {
  switch (action.type) {
    case 'COMMAND_EXECUTION_REQUEST':
    case 'COMMAND_EXECUTION_FAILURE':
      return state;
    case 'COMMAND_EXECUTION_SUCCESS':
      return { last: action.payload };
    default:
      return state;
  }
}

*/
