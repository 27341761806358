export const SELECTED_FILE_SUCCESS = 'SELECTED_FILE_SUCCESS';
export const SELECTED_FILE_FAILURE = 'SELECTED_FILE_FAILURE';
export const FILE_UPLOAD_CLOSE = 'FILE_UPLOAD_CLOSE';
export const FILE_UPLOAD_POST = 'FILE_UPLOAD_POST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_CLEAN_CACHE = 'FILE_UPLOAD_CLEAN_CACHE';
export const LAST_SELECTED_FILE = 'LAST_SELECTED_FILE';

export interface FileUploadState {
  isActive: boolean;
  selectFile: any;
  selectedFileErrorMsg: any;
  selectedFileSuccessMsg: string;
  disabledUploadButton: boolean;
  fileContent?: any;
}

export interface FileUploadAction {
  type:
    | typeof FILE_UPLOAD_CLOSE
    | typeof SELECTED_FILE_SUCCESS
    | typeof SELECTED_FILE_FAILURE
    | typeof FILE_UPLOAD_POST
    | typeof FILE_UPLOAD_SUCCESS
    | typeof FILE_UPLOAD_FAILURE
    | typeof FILE_UPLOAD_CLEAN_CACHE
    | typeof LAST_SELECTED_FILE;
  payload: any;
}
