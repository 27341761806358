import TooltipComponent from 'core/tooltip';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import './input-styles.scss';

interface InputProps {
  t: TFunction;
  addSelectedFilters: (id: string, value: string, name: string, type?: string) => void;
  id: string;
  inputData: { name: string; currentValue: string; type: string; tooltipText: any, removeLabel?: boolean };
  updateInput: (id: string, value: string, name: string, type?: string) => void;
  i18nKey?: string;
  inputDisabled?: boolean;
}

export default class InputComponent extends Component<InputProps> {
  render() {
    const { t, addSelectedFilters, updateInput, id, inputData, i18nKey, inputDisabled } = this.props;

    return (
      <div className="input-wrap">
        <input
          type={inputData.type}
          className="input-text"
          placeholder=" "
          value={inputData.currentValue}
          onChange={(event: any) => updateInput(id, event.target.value, inputData.name)}
          onBlur={(event: any) => addSelectedFilters(id, event.target.value, inputData.name)}
          disabled={inputDisabled}
        />

        <span className="floating-label">
          {inputData && !inputData.removeLabel && (
            <span className="has-padding-right-xs">
              {t(`${i18nKey || 'datagrabber'}.field_${inputData.name}`)}
            </span>
          )}
          {inputData.tooltipText && (
            <span className="has-padding-top-xxs input-wrap-tooltip">
              <TooltipComponent data={inputData.tooltipText} t={t} />
            </span>
          )}
        </span>
      </div>
    );
  }
}
