import React, { Component } from 'react';
import { TFunction } from 'i18next';
import { AdvancedSearchHistory } from './redux/types';
import mapper from './redux/mapper';
import { Search, Edit, Delete, Tick } from 'assets/svg/index';
import './advanced-search-history-styles.scss';

interface AdvancedSearchHistoryComponentProps {
  currentTab: string;
  list: Array<AdvancedSearchHistory>;
  query: string;
  t: TFunction;
  changeTab: (tab: string) => void;
  fetch: (query?: string) => void;
  onChangeQueryValue: (query: string) => void;
  selectQueriesByHistory: any;
  update: (item: AdvancedSearchHistory) => void;
  editFavourite: (item: AdvancedSearchHistory) => void;
  onChangeFavouriteName: (item: AdvancedSearchHistory, query: string) => void;
}

export default class AdvancedSearchHistoryComponent extends Component<
  AdvancedSearchHistoryComponentProps
> {
  componentDidMount() {
    const { fetch } = this.props;
    fetch();
  }

  toggleFavourite(event: any, item: AdvancedSearchHistory) {
    const { update } = this.props;
    event.stopPropagation();
    item.isHighlight = item.isHighlight === 'true' ? 'false' : 'true';
    update(item);
  }

  toggleEditFavourite(event: any, item: AdvancedSearchHistory) {
    const { update, editFavourite } = this.props;
    event.stopPropagation();
    if (item.isEditing){
      editFavourite(item);
      update(item);
    } else {
      editFavourite(item);
    }
  }

  search(event: any, query: string) {
    const { fetch } = this.props;
    event.preventDefault();
    fetch(query);
  }

  changeTab(event: any, tab: string) {
    const { changeTab } = this.props;
    event.preventDefault();
    changeTab(tab);
  }

  mapperOlderQuery(query: string){
    let newerQuery = query.split(' AND ');
    newerQuery = newerQuery.map((item: string) =>
      `${mapper(item.split(':')[0])}:${item.split(':')[1]}`
    );
    return newerQuery.join(' AND ');
  }

  selectQueriesByHistory(item: AdvancedSearchHistory) {
    const { selectQueriesByHistory } = this.props;
    window.scrollTo(0, 0);
    item.query = this.mapperOlderQuery(item.query);
    selectQueriesByHistory(item);
  }

  render() {
    const { currentTab, list, query, t, onChangeQueryValue, onChangeFavouriteName } = this.props;
    return (
      <div className="card is-paddingless">
        <div className="tabs is-fullwidth is-medium is-marginless column-squared">
          <ul>
            <li className={currentTab === 'favourites' ? 'is-active' : ''}>
              <button
                className="button button-link is-fullwidth"
                onClick={event => this.changeTab(event, 'favourites')}
              >
                {t('advanced_search.tab_favourites')}
              </button>
            </li>
            <li className={currentTab !== 'favourites' ? 'is-active' : ''}>
              <button
                className="button button-link is-fullwidth"
                onClick={event => this.changeTab(event, 'history')}
              >
                {t('advanced_search.tab_search_history')}
              </button>
            </li>
          </ul>
        </div>

        <div className="section has-padding-md">
          <div className="field has-addons">
            <form
              onSubmit={event => this.search(event, query)}
              className="control is-expanded"
            >
              <input
                className="input has-icon"
                value={query}
                type="text"
                placeholder={t('general.search')}
                onChange={(event: any) => {
                  onChangeQueryValue(event.target.value);
                }}
              />
              <span
                className="adv-search-icon in-input"
                onClick={event => this.search(event, query)}
              >
                <Search className="icon icon-svg is-small icon-atlo" />
              </span>
            </form>
          </div>

          <ul className="favourites">
            {list &&
              list.map((item: AdvancedSearchHistory) => (
                <li
                  key={item.identifier}
                  className={`favourites-item
                    ${item.isEditing ? 'is-editing' : ''}
                    ${item.similarSearch ? 'similar-search' : ''}
                  `}
                >
                  <div
                    className="columns is-marginless is-vertical-center"
                    onClick={() => item.similarSearch ? '' : this.selectQueriesByHistory(item)}
                  >
                    <span className="column is-paddingless low-priority">
                      <button className="button button-link is-fullwidth is-justified-start">
                        { item.isEditing ?
                          <input
                            className="is-editing"
                            value={item.searchName}
                            onChange={(event: any) => {
                              onChangeFavouriteName(item, event.target.value);
                            }}
                            type="text"
                          />
                        :
                          <span> {item.searchName || item.identifier} </span>
                        }
                      </button>
                    </span>
                    {currentTab === 'favourites' ?
                    <span className="column is-paddingless has-padding-left-sm has-padding-top-sm">
                      <button className="icon-button" onClick={event => this.toggleEditFavourite(event, item)}>
                        <span className="icon">
                          { item.isEditing ?
                            <Tick className="icon-svg icon-button icon-green" />
                          :
                            <Edit className="icon-svg icon-button" />
                          }
                        </span>
                      </button>
                      <button className="icon-button" onClick={event => this.toggleFavourite(event, item)}>
                        <span className="icon">
                          <Delete className="icon-svg icon-button" />
                        </span>
                      </button>
                    </span>
                    :
                    <span className="column is-paddingless has-padding-top-sm">
                      <button className="icon-button" onClick={event => this.toggleFavourite(event, item)}>
                        {item.isHighlight === 'true' ? (
                          <i className="mdi mdi-24px mdi-star star" />
                        ) : (
                          <i className="mdi mdi-24px mdi-star-outline" />
                        )}
                      </button>
                    </span>
                    }
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}
