import React, { Component } from 'react';
import { CardSizes } from '../card/enum';
import CardComponent from 'core/card';
import './pop-up-styles.scss';
import { BagdeTypes } from 'core/badge/enum';

interface PopUpProps {
  badge?: {
    text: string;
    type: BagdeTypes;
  };
  children: any;
  isActive?: boolean;
  size?: CardSizes;
}

export default class PopUpComponent extends Component<PopUpProps> {
  render() {
    const { badge, children, isActive, size } = this.props;

    return (
      <div className={`modal ${isActive ? 'is-active' : ''}`}>
        <div className="modal-background">
          <CardComponent children={children} size={size} badge={badge} />
        </div>
      </div>
    );
  }
}
