import React, { Component } from 'react';

export interface PaginationInfo {
  last: boolean;
  totalElements: number;
  totalPages: number;
  currentPage: number;
  size: number;
  numberOfElements: number;
  first: boolean;
}

export interface PaginationRequest {
  page: number;
  size: number;
}

interface PaginationComponentProps {
  start: number;
  info: PaginationInfo;
  onChange: (request: PaginationRequest) => void;
}

export default class PaginationComponent extends Component<PaginationComponentProps> {
  goToFirst() {
    const { onChange, info, start } = this.props;
    onChange({ size: info.size, page: start });
  }

  goToPrevious() {
    const { onChange, info, start } = this.props;
    if (info.currentPage > start) onChange({ size: info.size, page: info.currentPage - 1 });
  }

  goToLast() {
    const { onChange, info, start } = this.props;
    if (info.totalPages > start) onChange({ size: info.size, page: info.totalPages - 1 });
  }

  goToNext() {
    const { onChange, info, start } = this.props;
    if (info.totalPages > start) onChange({ size: info.size, page: info.currentPage + 1 });
  }

  printLabel() {
    const { info } = this.props;
    return `${info.currentPage + 1} - ${info.totalPages}`;
  }

  render() {
    const { info } = this.props;
    return (
      <div>
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <li>
              <button
                disabled={info.first}
                className="button is-text is-rounded pagination-link"
                onClick={() => this.goToFirst()}
              >
                <span className="icon">
                  <i className="mdi mdi-24px mdi-chevron-double-left" />
                </span>
              </button>
            </li>
            <li>
              <button
                disabled={info.first}
                className="button is-text is-rounded pagination-link"
                onClick={() => this.goToPrevious()}
              >
                <span className="icon">
                  <i className="mdi mdi-24px mdi-chevron-left" />
                </span>
              </button>
            </li>

            <li>
              <input
                disabled
                className="input is-small"
                type="text"
                value={this.printLabel()}
                style={{ textAlign: 'center', width: '5em' }}
              />
            </li>
            <li>
              <button
                disabled={info.last}
                className="button is-text is-rounded pagination-link"
                onClick={() => this.goToNext()}
              >
                <span className="icon">
                  <i className="mdi mdi-24px mdi-chevron-right" />
                </span>
              </button>
            </li>
            <li>
              <button
                disabled={info.last}
                className="button is-text is-rounded pagination-link"
                onClick={() => this.goToLast()}
              >
                <span className="icon">
                  <i className="mdi mdi-24px mdi-chevron-double-right" />
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
