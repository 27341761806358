import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import { sidebarIsHidden } from './redux/actions';
import Sidebar from './sidebar-component';

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  sideStatus: state.sidebar
});
export default connect(mapStateToProps, {
  sidebarIsHidden
})(Sidebar);
