import {
  DVBManagementNodesAction,
  DVB_MANAGEMENT_NODE_SELECT,
  DVB_MANAGEMENT_NODES_SET,
  DVBManagementNode,
  DVBNode,
  DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION
} from './types';

const initialState: DVBManagementNode = {
  items: [],
  itemsChanged: undefined,
  itemsSearch: undefined
};

export default function(state = initialState, action: DVBManagementNodesAction) {
  switch (action.type) {
    case DVB_MANAGEMENT_NODE_SELECT: {
      if (!action.payload) {
        state.selected = undefined;
      }

      if (state.itemsChanged) {
        state.itemsChanged.forEach((node: DVBNode) => {
          if (node.rootId === action.payload) {
            state.selected = node;
          }
        });
      }

      return {
        ...state
      };
    }

    case DVB_MANAGEMENT_NODES_SET: {
      let nodes: Array<DVBNode> = [];
      const isToClear = action.payload && action.payload.isToClear;
      console.log("action.payload",action.payload)
      if (action.payload && action.payload.nodes && (action.payload.regionId || action.payload.isRegionalization || action.payload.isSearch)) {
        action.payload.nodes.forEach((item: any) => {
          nodes.push({
            label: item.nodeLabel || item.label,
            nodeLabel: item.nodeLabel || item.label,
            id: item.id,
            rootId: item.rootId || item.nodeId || item.id,
            nodeId: item.nodeId || item.id,
            childRegionId: item.childRegionId,
            children: item.networks || item.children,
            canEditChange: item.canEditChange === undefined ? true : item.canEditChange,
            changeLabel: item.changeLabel,
            changeId: item.changeId
          });
        });
      }

      return {
        ...state,
        items:
          isToClear || (state.items && state.items.length === 0) || action.payload.isRegionalization
            ? nodes
            : state.items,
        itemsSearch: action.payload && action.payload.isSearch ? nodes : undefined
      };
    }
    case DVB_MANAGEMENT_NODES_UPDATE_REGIONALIZATION: {
      return {
        ...state,
        isRegionalization: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
