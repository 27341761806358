import React, { Component, Fragment } from 'react';
import authService from 'core/auth';
import { Close, Delete, Dots, NoRecords, Tick, View } from 'assets/svg';
import { close as closeType, open as openType } from 'core/modal/redux/actions';
import DateUtils from 'core/utils/date-utils';
import apiClient from 'core/api';
import T from 'core/i18n/t';
import PaginationComponent from 'core/pagination-optimized/pagination-container';
import CardComponent from 'core/card';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { TFunction } from 'i18next';
import {
  Request,
  RequestReply,
  REQUESTS_SET_REQUEST,
  REQUESTS_SET_REQUEST_INITIAL,
  RequestsMainState
} from './redux/types';
import RequestsMapper from './redux/mapper';
import { CardPaddings } from 'core/card/enum';
import { DVB_COLUMNS } from '../dvb-management/redux/enums';
import SearchComponent from 'core/search/search-container';
import { SearchItem, SearchState } from 'core/search/redux/types';
import { addFilter, clearAllFilters } from 'core/search/redux/action';
import { AnyARecord } from 'dns';
import MultipleSearchComponent from 'core/multiple-search/multiple-search-container';
import { MultipleSearchState } from 'core/multiple-search/redux/types';
import { SpectrumRequestsParam } from './redux/action';
import { requestMultipleSearch } from 'core/multiple-search/redux/config';
import { envs } from 'application/envHandler';

interface RequestsProps {
  action: (type: any, payload?: any) => void;
  addFilter: (id: string, filter: SearchItem) => void;
  close: typeof closeType;
  getRequests: (params?: SpectrumRequestsParam) => void;
  changeMenu: (tab: string, id: string) => void;
  open: typeof openType;
  pagination: Map<string, PaginationConfig>;
  push: (uri: string) => void;
  replyRequest: (id: string, command: RequestReply) => void;
  requests: Request[];
  mainRequests?: RequestsMainState;
  t: TFunction;
  search: SearchState;
  onChangeTypeFilterValue: (id: string, value: any) => void;
  multipleSearch: MultipleSearchState;
}

export default class RequestsComponent extends Component<RequestsProps> {
  columns = ['id', 'user', 'insert_date', 'modified_date', 'schedule_date', 'status'];

  componentDidMount() {
    const { action, getRequests, addFilter } = this.props;
    const isSuperUser = authService.isSuperUser('spectrum');
    getRequests();
    action(REQUESTS_SET_REQUEST_INITIAL);
  }
  
  getUsersForSearch = () => {
    const opco = authService.getCurrentOpco();
    const user = authService.getCurrentUser();

    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/be/spectrum/users?opco=${opco}&user=${user.username}&&module=spectrum`
      )
      .then(
        (response : {data : {result : string[]}})=> {
          requestMultipleSearch.levels[0].categories[0].fields[1].listValues = response.data.result;
        },
        (rejection) => {
          return console.log(rejection);
        }
      );
  };
  componentWillMount(): void {
    this.getUsersForSearch();
  }

  executeAction(id: string, command: RequestReply) {
    const { close, open, replyRequest, t } = this.props;
    const execute = () => {
      close();
      replyRequest(id, command);
    };

    open({
      title: t('spectrum.confirmation.title').replace('{0}', command),
      active: false,
      content: t('spectrum.confirmation.message').replace('{0}', command),
      execute
    });
  }

  getNextScheduledDate() {
    const { requests } = this.props;
    return requests
      ? DateUtils.formatFromIotTimestamp(
          requests
            .filter(
              (request) =>
                request.schedulingDate !== undefined &&
                new Date(request.schedulingDate) > new Date() &&
                (request.requestStatus === 'approved' || request.requestStatus === 'pending')
            )
            .map((it) => new Date(it.schedulingDate))
            .sort((a, b) => b.getTime() - a.getTime())
            .pop()
        )
      : null;
  }

  isRequestOpen(status: string) {
    return this.isRequestPending(status) || status === 'draft';
  }

  isRequestPending(status: string) {
    return status === 'pending';
  }

  onDetailsClick(row: any, isRequestOpen: boolean, isRequestPending: boolean) {
    const { action, changeMenu, getRequests, push } = this.props;
    //if ((isRequestOpen && row.user !== authService.getCurrentUser().username) || !isRequestOpen) {
    getRequests({ requestId: row.requestId || '', column: DVB_COLUMNS.REGION });
    //}

    action(REQUESTS_SET_REQUEST, {
      data: {
        canEditOtherEntities:
          authService.isSuperUser('spectrum') || row.user === authService.getCurrentUser().username,
        requestId: row.requestId,
        requestStatus: row.requestStatus,
        isRequestOpen,
        isRequestPending
      }
    });

    changeMenu('dvb_management', 'spectrum');
    push(`${process.env.PUBLIC_URL}/management/spectrum/dvb_management`);
  }

  render() {
    const { getRequests, pagination, requests, t, mainRequests } = this.props;

    const isSuperUser = authService.isSuperUser('spectrum');
    const requestsPagination = pagination.get('spectrum.requests')!;
    const nextScheduledUpdate = this.getNextScheduledDate();
    

    return (
      <section className="section-spaced has-margin-bottom-md">
        <div className="columns is-justified-between is-vertical-center">
          <div className="column has-padding-y-none">
            {requests && requests.length > 0 && (
              <h4 className="text is-bold is-h4">
                <T>spectrum.menu_requests</T>
              </h4>
            )}

            <MultipleSearchComponent
              id={'spectrum.requests'}
              i18nKey={'spectrum.requests'}
              isSearchRequestStyle
              fieldsToHide={[!isSuperUser ? 'createdBy' : '']}
              onMultipleSearch={() => [getRequests({ isSearch: true})]}
              badgesOutside={true}
            />
          </div>
          {nextScheduledUpdate && (
            <div className="info column is-narrow has-margin-right-md">
              <p className="text is-size-sm has-margin-y-sm">
                <span className="text is-highlighted is-bold">
                  <T>spectrum.next_scheduled_update</T>
                </span>
                <span className="has-margin-left-sm">{nextScheduledUpdate}</span>
              </p>
            </div>
          )}
        </div>

        <div className="table-prettier" role="table">
          {mainRequests?.requests && mainRequests?.requests.length > 0 && (
            <Fragment>
              <div className="flex-table header has-action" role="rowgroup">
                {this.columns.map((key, index) =>
                  key === 'user' && !isSuperUser ? undefined : (
                    <div className="flex-cell" role="columnheader" key={index}>
                      {t(`spectrum.requests.${key}`)}
                    </div>
                  )
                )}
              </div>
              {requests.map((row: any, index: number) => {
                const isRequestPending = this.isRequestPending(row.requestStatus);
                const isRequestOpen = this.isRequestOpen(row.requestStatus);
                row = RequestsMapper.formatRequest(row, t);
                return (
                  <div className="flex-table row has-action" role="rowgroup" key={index}>
                    {Object.keys(row).map((value, key) =>
                      value === 'user' && !isSuperUser ? undefined : (
                        <div className="flex-cell" role="cell" key={key}>
                          {row[value]}
                        </div>
                      )
                    )}
                    <div className="flex-cell flex-cell-action" role="cell">
                      <div className="actions">
                        <Dots className="icon-svg" />
                        <div className="action-list has-margin-right-md">
                          <CardComponent hasShadow padding={CardPaddings.NONE}>
                            <ul>
                              <li>
                                <button
                                  className="button button-link is-vertical-center"
                                  onClick={() => {
                                    this.onDetailsClick(row, isRequestOpen, isRequestPending);
                                  }}
                                >
                                  <View className="icon-svg icon-small has-margin-right-sm" />
                                  <T>spectrum.actions.details</T>
                                </button>
                              </li>
                              {isSuperUser && isRequestPending && (
                                <Fragment>
                                  <li>
                                    <button
                                      className="button button-link is-vertical-center"
                                      onClick={() =>
                                        this.executeAction(row.requestId, RequestReply.APPROVE)
                                      }
                                    >
                                      <Tick className="icon-svg icon-small has-margin-right-sm" />
                                      <T>spectrum.actions.approve</T>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="button button-link is-vertical-center"
                                      onClick={() =>
                                        this.executeAction(row.requestId, RequestReply.REJECT)
                                      }
                                    >
                                      <Close className="icon-svg icon-small has-margin-right-sm" />
                                      <T>spectrum.actions.reject</T>
                                    </button>
                                  </li>
                                </Fragment>
                              )}
                              {(!isSuperUser ||
                                (row.user && row.user === authService.getCurrentUser().username)) &&
                                isRequestOpen && (
                                  <li>
                                    <button
                                      className="button button-link is-vertical-center"
                                      onClick={() =>
                                        this.executeAction(row.requestId, RequestReply.CANCEL)
                                      }
                                    >
                                      <Delete className="icon-svg icon-small has-margin-right-sm" />
                                      <T>spectrum.actions.cancel</T>
                                    </button>
                                  </li>
                                )}
                            </ul>
                          </CardComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="has-margin-top-lg has-margin-bottom-xl">
                <PaginationComponent
                  id="spectrum.requests"
                  pageSize={requestsPagination ? requestsPagination.pageSize : 10}
                  totalElements={this.props.mainRequests ? this.props.mainRequests.totalCount : 0}
                  index={mainRequests ? mainRequests.index : ''}
                  onChange={() =>
                    this.props.getRequests({
                      request: this.props.mainRequests,
                      pageNumber: requestsPagination.pageNumber
                    })
                  }
                  singleArrowOnly={true}
                />
              </div>
            </Fragment>
          )}

          {requests && requests.length === 0 && (
            <div className="flex-column is-vertical-center is-justified-center has-text-centered no-data">
              <NoRecords className="has-margin-bottom-lg" />
              <h5 className="text is-h5 is-bold has-margin-bottom-md">
                <T>spectrum.requests.no_requests.title</T>
              </h5>
              <h5 className="text is-h5">
                <T>spectrum.requests.no_requests.message</T>
              </h5>
            </div>
          )}
        </div>
      </section>
    );
  }
}
