import {
  SIDE_MENU_CLEAR_CACHE,
  SIDE_MENU_TOGGLE_CATEGORY,
  SIDE_MENU_TOGGLE_SUBCATEGORY
} from './types';

export function toggleCategory(id: string, category: string) {
  return {
    type: SIDE_MENU_TOGGLE_CATEGORY,
    payload: {
      id,
      category
    }
  };
}

export function toggleSubCategory(id: string, category: string, subCategory: string) {
  return {
    type: SIDE_MENU_TOGGLE_SUBCATEGORY,
    payload: {
      id,
      category,
      subCategory
    }
  };
}

export function sideMenuClearCache() {
  return {
    type: SIDE_MENU_CLEAR_CACHE
  };
}
