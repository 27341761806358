import React, { Component } from 'react';
import T from 'core/i18n/t';
import DateUtils from 'core/utils/date-utils';
import { formatCamelCaseToSnakeCase } from 'core/utils/string-utils';

interface JobPanelComponentProps {
  item: any;
  showProgressBar: boolean;
  cancel: any;
  t: any;
  openModal: any;
  closeModal: any;
  backgroundGrey?: boolean;
  hideCancelButton?: boolean
}

export default class JobPanelComponent extends Component<JobPanelComponentProps> {
  getStatus(status: string) {
    switch (status) {
      case 'COMPLETED':
      case 'SUCCEEDED':
        return 'is-success';
      case 'FAILED':
      case 'TIME_EXCEEDED':
      case 'CANCELED':
      case 'REJECTED':
        return 'is-danger';
      case 'IN_PROGRESS':
      case 'QUEUED':
      case 'RECEIVED':
      case 'STARTED':
        return 'is-info';
      default:
        return '';
    }
  }

  getLog(logRequest: string, logResponse: string) {
    if (
      (logRequest === '{}' || !logRequest) &&
      (logResponse === '{}' ||
        !logResponse ||
        (logResponse.constructor === Object && Object.keys(logResponse).length === 0))
    ) {
      return '';
    }
    if (logRequest) return logRequest;
    return logResponse.match(/(errorText=)/g)
      ? logResponse.split(/(errorText=)|,|(errorCode=)/g)[3]
      : logResponse.split(/(errorCondition=)|,/g)[2];
  }

  confirmCancelation() {
    this.props.openModal({
      title: 'modal.title.confirm_command_cancelation',
      active: false,
      content: 'modal.content.confirm_command_cancelation',
      execute: () => {
        this.props.closeModal();
        this.props.cancel(this.props.item.commandTraceId, this.props.item.udid, this.props.t);
      }
    });
  }

  render() {
    return (
      <li
        className={`${
          this.props.backgroundGrey && 'panel-item-grey'
        } panel-item panel-item-status ${this.getStatus(this.props.item.status)}`}
      >
        <div className="columns">
          <div className="column has-padding-md">
            <div className="columns is-justified-between has-margin-bottom-none">
              <div className="column is-narrow has-padding-bottom-none has-padding-top-sm">
                <h5 className="is-size-5">
                  <span className="title is-size-5">
                    <T>
                      {`command.label.${formatCamelCaseToSnakeCase(this.props.item.eventName)}`}
                    </T>
                  </span>
                  {` - ${DateUtils.formatDate(this.props.item.lastManaged)}`}
                </h5>
                <p className="has-margin-top-xs is-size-6">
                  <T>command.with_id</T>
                  <span className="text-bold">{` ${this.props.item.commandTraceId} `}</span>
                  <T>{`command.status.${this.props.item.status.toLowerCase()}`}</T>
                </p>
                {this.props.item.status === 'FAILED' && (
                  <p className="has-margin-top-none is-size-6">
                    {this.props.item.error ||
                      this.getLog(this.props.item.logRequest, this.props.item.logResponse)}
                  </p>
                )}
              </div>
              {this.getStatus(this.props.item.status) === 'is-info' && !this.props.hideCancelButton && (
                <div className="column is-narrow has-padding-bottom-none has-padding-top-sm">
                  <button
                    type="button"
                    className="button is-secondary has-margin-left-none"
                    onClick={() => this.confirmCancelation()}
                  >
                    <T>general.cancel</T>
                  </button>
                </div>
              )}
            </div>
            {this.props.showProgressBar && (
              <div className="columns is-vertical-center has-margin-top-xs ">
                <div className="column has-padding-right-none has-padding-bottom-none">
                  <progress
                    className="progress"
                    value={
                      this.getStatus(this.props.item.status) === 'is-success'
                        ? 100
                        : this.props.item.progress || 0.5
                    }
                    max="100"
                  />
                </div>
                <div className="column is-narrow has-padding-bottom-none">
                  <span>
                    {this.getStatus(this.props.item.status) === 'is-success'
                      ? 100
                      : this.props.item.progress || 0}
                    %
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    );
  }
}
