import { connect } from 'react-redux';
import OverlayModalComponent from './overlay-modal-component';
import { close } from './redux/actions';
import { ApplicationState } from 'application/application-redux';

const mapStateToProps = (state: ApplicationState) => ({
  isActive: state.modal.overlay.active,
  title: state.modal.overlay.title,
  content: state.modal.overlay.content,
  execute: state.modal.overlay.execute,
  size: state.modal.overlay.size,
  t: state.i18n.t,
  children: state.modal.overlay.children,
});

export default connect(mapStateToProps, { close })(OverlayModalComponent);
