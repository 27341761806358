import mapper from './mapper';
import { addToaster } from 'core/toaster/redux/actions';
import {
  ChannelsAction,
  Channels,
  ChannelsState,
  CHANNEL_GET_SUCCESS,
  KALTURA_GET,
  CHANNEL_GET_FAILURE,
  CHANNEL_SELECT
} from './type';

const initialState: ChannelsState = {
  isActive: false,
  isFetching: false,
  totalCount: 0,
  channels: []
};

export default function(state = initialState, action: ChannelsAction) {
  switch (action.type) {
    case CHANNEL_GET_SUCCESS: {
      const data = action.payload.data;
      const count = action.payload.totalCount;
      return {
        ...state,
        totalCount: count,
        channels: data.map((channel: Channels) => mapper(channel, action.payload.t))
      };
    }

    case KALTURA_GET: {
      const isActive = action.payload.isActive;
      return {
        ...state,
        isActive
      };
    }

    case CHANNEL_SELECT: {
      const channel = action.payload.data;
      return { ...state, channelActive: channel };
    }
    case CHANNEL_GET_FAILURE:
      {
        return {
          ...state,
          totalCount: 0,
          channels: []
        };
      }
    default:
      return { ...state };
  }
}
