export const TABLE_GET_SELECTED_ROWS = 'TABLE_GET_SELECTED_ROWS';
export const TABLE_ASSIGN_ALL_ROWS = 'TABLE_ASSIGN_ALL_ROWS';
export const TABLE_GET_SELECTED_ROW_INDEX = 'TABLE_GET_SELECTED_ROW_INDEX';
export const TABLE_CLEAR_CACHE = 'TABLE_CLEAR_CACHE';
export const TABLE_RESET_AFTER_DOWNLOAD = 'TABLE_RESET_AFTER_DOWNLOAD';
export const TABLE_EDIT_FIELD = 'TABLE_EDIT_FIELD';
export const TABLE_UPDATE_SORT = 'TABLE_UPDATE_SORT';
export const TABLE_ORDER_OWN_ITEMS = 'TABLE_ORDER_OWN_ITEMS';
export interface TableAction {
  type: string;
  payload?: any;
}

export interface TableObject {
  selectedRows: Selected[];
  selectedRow: string;
  selectAll?: boolean;
  selectedItemsNo?: number;
  hasSelectedItem?: boolean;
  sort?: {
    column: string;
    order: string;
  };
  orderDirection?: string;
  orderIndex?: number;
}

export interface Selected {
  rowId: string;
  field?: string;
  selected: boolean;
}

export interface TableState {
  items: Map<string, TableObject>;
}

export interface Sort {
  order: string;
  column: string;
  pageNumber?: number;
}

export enum Select {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  BOTH = 'both'
}
