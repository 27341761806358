export const PROGRESS_BAR_CHANGE = 'PROGRESS_BAR_CHANGE';
export const PROGRESS_BAR_DURATION = 'PROGRESS_BAR_DURATION';
export const PROGRESS_BAR_CLOSE = 'PROGRESS_BAR_CLOSE';
export const PROGRESS_BAR_ACTIVE = 'PROGRESS_BAR_ACTIVE';

 export interface ProgressBarState {
  isActive: boolean;
  progress: number;
  close: boolean; 
  duration: number;
} 

export interface ProgressBarAction {
  type:
    | typeof PROGRESS_BAR_CHANGE
    | typeof PROGRESS_BAR_CLOSE
    | typeof PROGRESS_BAR_DURATION
    | typeof PROGRESS_BAR_ACTIVE;
  payload: any;
}
